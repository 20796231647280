import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import Select from "react-select";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import AttachmentComponent from "../AttachmentComponent";
import { tableDataHandler } from "@store/Cms/cms.action";
import { dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import { indianStateList } from "../../../../utility/Utils";
import { useUpdateSupply } from "../../../../api/Supply/Mutation";

import {
  LeaseDeedTypeList,
  LicensesObtainanceOrRenewalResponsibilityTypeList,
  LicensesOfficialFeesResponsibilityTypeList,
  ValidTypeList,
} from "../selectOptionsList";
import ImageModal from "../ImageModal";

const DealRegistrationLicenses = ({ setRemainingCount }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userRole = localStorage.getItem("role");

  const [leaseDeedType, setLeaseDeedType] = useState("");

  const [leaseDeed, setLeaseDeed] = useState([]);
  const [legalJurisdictionCity, setLegalJurisdictionCity] = useState("");
  const [legalJurisdictionState, setLegalJurisdictionState] = useState("");
  const [leaseDeedBearer, setLeaseDeedBearer] = useState("");
  const [stampDutyPercent, setStampDutyPercent] = useState(null);
  const [stampDutyCharges, setStampDutyCharges] = useState(null);
  const [registeredLicensesEntity, setRegisteredLicensesEntity] = useState("");
  const [licensesResponsibility, setLicensesResponsibility] = useState("");
  const [licensesFeesResponsibility, setLicensesFeesResponsibility] =
    useState("");

  const [municipalityTradeValid, setMunicipalityTradeValid] = useState("");
  const [municipalityTradeValidUpto, setMunicipalityTradeValidUpto] =
    useState(null);
  const [municipalityTradeDoc, setMunicipalityTradeDoc] = useState([]);

  const [healthSanitaryValid, setHealthSanitaryValid] = useState("");
  const [healthSanitaryValidUpto, setHealthSanitaryValidUpto] = useState(null);
  const [healthSanitaryDoc, setHealthSanitaryDoc] = useState([]);

  const [labourValid, setLabourValidValid] = useState("");
  const [labourValidUpto, setLabourValidUpto] = useState(null);
  const [labourDoc, setLabourDoc] = useState([]);

  const [pcbCTOValid, setPCBCTOValid] = useState("");
  const [pcbCTOValidUpto, setPcbCTOValidUpto] = useState(null);
  const [pcbCTODoc, setPcbCTODoc] = useState([]);

  const [fireNOCValid, setFireNOCValid] = useState("");
  const [fireNOCValidUpto, setFireNOCValidUpto] = useState(null);
  const [fireNOCDoc, setFireNOCDoc] = useState([]);

  const [tourismValid, setTourismValid] = useState("");
  const [tourismValidUpto, setTourismValidUpto] = useState(null);
  const [tourismDoc, setTourismDoc] = useState([]);

  const [pccNOCValid, setPCCNOCValid] = useState("");
  const [pccNOCValidUpto, setPCCNOCValidUpto] = useState(null);
  const [pccNOCDoc, setPCCNOCDoc] = useState([]);

  const [exciseBarValid, setExciseBarValid] = useState("");
  const [exciseBarValidUpto, setExciseBarValidUpto] = useState(null);
  const [exciseBarDoc, setExciseBarDoc] = useState([]);

  const [shopEstabValid, setShopEstabValid] = useState("");
  const [shopEstabValidUpto, setShopEstabValidUpto] = useState(null);
  const [shopEstabDoc, setShopEstabDoc] = useState([]);

  const [fssaiValid, setFSSAIValid] = useState("");
  const [fssaiValidUpto, setFSSAIValidUpto] = useState(null);
  const [fssaiDoc, setFSSAIDoc] = useState([]);

  const [gstValid, setGSTValid] = useState("");
  const [gstValidUpto, setGSTValidUpto] = useState(null);
  const [gstDoc, setGSTDoc] = useState([]);

  const [liftClearValid, setLiftClearValid] = useState("");
  const [liftClearValidUpto, setLiftClearValidUpto] = useState(null);
  const [liftClearDoc, setLiftClearDoc] = useState([]);

  const [musicNOCValid, setMusicNOCValid] = useState("");
  const [musicNOCValidUpto, setMusicNOCValidUpto] = useState(null);
  const [musicNOCDoc, setMusicNOCDoc] = useState([]);

  const [sinageNOCValid, setSinageNOCValid] = useState("");
  const [sinageNOCValidUpto, setSinageNOCValidUpto] = useState(null);
  const [sinageNOCDoc, setSinageNOCDoc] = useState([]);

  //!Images state
  const [leaseDeedImageDummyState] = useState(null);
  const [municipalityTradeDocImageDummyState] = useState(null);
  const [healthSanitaryDocImageDummyState] = useState(null);
  const [labourDocImageDummyState] = useState(null);
  const [pcbCTODocImageDummyState] = useState(null);
  const [fireNOCDocImageDummyState] = useState(null);
  const [tourismDocImageDummyState] = useState(null);
  const [pccNOCDocImageDummyState] = useState(null);
  const [exciseBarDocImageDummyState] = useState(null);
  const [shopEstabDocImageDummyState] = useState(null);
  const [fssaiDocImageDummyState] = useState(null);
  const [gstDocImageDummyState] = useState(null);
  const [liftClearDocImageDummyState] = useState(null);
  const [musicNOCDocImageDummyState] = useState(null);
  const [sinageNOCDocImageDummyState] = useState(null);

  const uploadMunicipalityTradeDocImageType = [
    { label: "imageURL", value: "municipalityTradeDoc" },
  ];
  const uploadHealthSanitaryDocImageType = [
    { label: "imageURL", value: "healthSanitaryDoc" },
  ];
  const uploadLabourDocImageType = [{ label: "imageURL", value: "labourDoc" }];
  const uploadPcbCTODocImageType = [{ label: "imageURL", value: "pcbCTODoc" }];
  const uploadFireNOCDocImageType = [
    { label: "imageURL", value: "fireNOCDoc" },
  ];
  const uploadTourismDocImageType = [
    { label: "imageURL", value: "tourismDoc" },
  ];
  const uploadPccNOCDocImageType = [{ label: "imageURL", value: "pccNOCDoc" }];
  const uploadExciseBarDocImageType = [
    { label: "imageURL", value: "exciseBarDoc" },
  ];
  const uploadShopEstabDocImageType = [
    { label: "imageURL", value: "shopEstabDoc" },
  ];
  const uploadFssaiDocImageType = [{ label: "imageURL", value: "fssaiDoc" }];
  const uploadGstDocImageType = [{ label: "imageURL", value: "gstDoc" }];
  const uploadLiftClearDocImageType = [
    { label: "imageURL", value: "liftClearDoc" },
  ];
  const uploadMusicNOCDocImageType = [
    { label: "imageURL", value: "musicNOCDoc" },
  ];
  const uploadSinageNOCDocImageType = [
    { label: "imageURL", value: "sinageNOCDoc" },
  ];
  const uploadLeaseDeedImageType = [
    { label: "imageURL", value: "leaseDeedImage" },
  ];

  const [updateSupplyHandler, { data: updatedSupplyData }] = useUpdateSupply();

  const state = useSelector((state) => state?.cms);
  const dbData = state?.tableData;

  useEffect(() => {
    if (dbData?._id) {
      setLeaseDeedType(
        dbData?.leaseDeedType !== null
          ? {
            label: dbData?.leaseDeedType,
            value: dbData?.leaseDeedType,
          }
          : ""
      );
      setLeaseDeed(dbData?.leaseDeed);
      setLegalJurisdictionCity(dbData?.legalJurisdictionCity);
      setLegalJurisdictionState(
        dbData?.legalJurisdictionState !== null
          ? {
            label: dbData?.legalJurisdictionState,
            value: dbData?.legalJurisdictionState,
          }
          : ""
      );
      setLeaseDeedBearer(
        dbData?.leaseDeedBearer !== null
          ? {
            label: dbData?.leaseDeedBearer,
            value: dbData?.leaseDeedBearer,
          }
          : ""
      );
      setStampDutyPercent(dbData?.stampDutyPercent);
      setStampDutyCharges(dbData?.stampDutyCharges);
      setRegisteredLicensesEntity(dbData?.registeredLicensesEntity);
      setLicensesResponsibility(
        dbData?.licensesResponsibility !== null
          ? {
            label: dbData?.licensesResponsibility,
            value: dbData?.licensesResponsibility,
          }
          : ""
      );
      setLicensesFeesResponsibility(
        dbData?.licensesFeesResponsibility !== null
          ? {
            label: dbData?.licensesFeesResponsibility,
            value: dbData?.licensesFeesResponsibility,
          }
          : ""
      );
      setMunicipalityTradeValid(
        dbData?.municipalityTradeValid !== null
          ? {
            label: dbData?.municipalityTradeValid,
            value: dbData?.municipalityTradeValid,
          }
          : ""
      );
      setMunicipalityTradeValidUpto(dbData?.municipalityTradeValidUpto);
      setMunicipalityTradeDoc(dbData?.municipalityTradeDoc);

      setHealthSanitaryValid(
        dbData?.healthSanitaryValid !== null
          ? {
            label: dbData?.healthSanitaryValid,
            value: dbData?.healthSanitaryValid,
          }
          : ""
      );
      setHealthSanitaryValidUpto(dbData?.healthSanitaryValidUpto);
      setHealthSanitaryDoc(dbData?.healthSanitaryDoc);

      setLabourValidValid(
        dbData?.labourValid !== null
          ? {
            label: dbData?.labourValid,
            value: dbData?.labourValid,
          }
          : ""
      );
      setLabourValidUpto(dbData?.labourValidUpto);
      setLabourDoc(dbData?.labourDoc);

      setPCBCTOValid(
        dbData?.pcbCTOValid !== null
          ? {
            label: dbData?.pcbCTOValid,
            value: dbData?.pcbCTOValid,
          }
          : ""
      );
      setPcbCTOValidUpto(dbData?.pcbCTOValidUpto);
      setPcbCTODoc(dbData?.pcbCTODoc);

      setFireNOCValid(
        dbData?.fireNOCValid !== null
          ? {
            label: dbData?.fireNOCValid,
            value: dbData?.fireNOCValid,
          }
          : ""
      );
      setFireNOCValidUpto(dbData?.fireNOCValidUpto);
      setFireNOCDoc(dbData?.fireNOCDoc);

      setTourismValid(
        dbData?.tourismValid !== null
          ? {
            label: dbData?.tourismValid,
            value: dbData?.tourismValid,
          }
          : ""
      );
      setTourismValidUpto(dbData?.tourismValidUpto);
      setTourismDoc(dbData?.tourismDoc);

      setPCCNOCValid(
        dbData?.pccNOCValid !== null
          ? {
            label: dbData?.pccNOCValid,
            value: dbData?.pccNOCValid,
          }
          : ""
      );
      setPCCNOCValidUpto(dbData?.pccNOCValidUpto);
      setPCCNOCDoc(dbData?.pccNOCDoc);

      setExciseBarValid(
        dbData?.exciseBarValid !== null
          ? {
            label: dbData?.exciseBarValid,
            value: dbData?.exciseBarValid,
          }
          : ""
      );
      setExciseBarValidUpto(dbData?.exciseBarValidUpto);
      setExciseBarDoc(dbData?.exciseBarDoc);

      setShopEstabValid(
        dbData?.shopEstabValid !== null
          ? {
            label: dbData?.shopEstabValid,
            value: dbData?.shopEstabValid,
          }
          : ""
      );
      setShopEstabValidUpto(dbData?.shopEstabValidUpto);
      setShopEstabDoc(dbData?.shopEstabDoc);

      setFSSAIValid(
        dbData?.fssaiValid !== null
          ? {
            label: dbData?.fssaiValid,
            value: dbData?.fssaiValid,
          }
          : ""
      );
      setFSSAIValidUpto(dbData?.fssaiValidUpto);
      setFSSAIDoc(dbData?.fssaiDoc);

      setGSTValid(
        dbData?.gstValid !== null
          ? { label: dbData?.gstValid, value: dbData?.gstValid }
          : ""
      );
      setGSTValidUpto(dbData?.gstValidUpto);
      setGSTDoc(dbData?.gstDoc);

      setLiftClearValid(
        dbData?.liftClearValid !== null
          ? {
            label: dbData?.liftClearValid,
            value: dbData?.liftClearValid,
          }
          : ""
      );
      setLiftClearValidUpto(dbData?.liftClearValidUpto);
      setLiftClearDoc(dbData?.liftClearDoc);

      setMusicNOCValid(
        dbData?.musicNOCValid !== null
          ? {
            label: dbData?.musicNOCValid,
            value: dbData?.musicNOCValid,
          }
          : ""
      );
      setMusicNOCValidUpto(dbData?.musicNOCValidUpto);
      setMusicNOCDoc(dbData?.musicNOCDoc);

      setSinageNOCValid(
        dbData?.sinageNOCValid !== null
          ? {
            label: dbData?.sinageNOCValid,
            value: dbData?.sinageNOCValid,
          }
          : ""
      );
      setSinageNOCValidUpto(dbData?.sinageNOCValidUpto);
      setSinageNOCDoc(dbData?.sinageNOCDoc);
    } else {
      setLeaseDeedType("");
      setLeaseDeed([]);
      setLegalJurisdictionCity("");
      setLegalJurisdictionState("");
      setLeaseDeedBearer("");
      setStampDutyPercent("");
      setStampDutyCharges("");
      setRegisteredLicensesEntity("");
      setLicensesResponsibility("");
      setLicensesFeesResponsibility("");
      setMunicipalityTradeValid("");
      setMunicipalityTradeValidUpto(null);
      setMunicipalityTradeDoc([]);
      setHealthSanitaryValid("");
      setHealthSanitaryValidUpto(null);
      setHealthSanitaryDoc([]);
      setLabourValidValid("");
      setLabourValidUpto(null);
      setLabourDoc([]);
      setPCBCTOValid("");
      setPcbCTOValidUpto(null);
      setPcbCTODoc([]);
      setFireNOCValid("");
      setFireNOCValidUpto(null);
      setFireNOCDoc([]);
      setTourismValid("");
      setTourismValidUpto(null);
      setTourismDoc([]);
      setPCCNOCValid("");
      setPCCNOCValidUpto(null);
      setPCCNOCDoc([]);
      setExciseBarValid("");
      setExciseBarValidUpto(null);
      setExciseBarDoc([]);
      setShopEstabValid("");
      setShopEstabValidUpto(null);
      setShopEstabDoc([]);
      setFSSAIValid("");
      setFSSAIValidUpto(null);
      setFSSAIDoc([]);
      setGSTValid("");
      setGSTValidUpto(null);
      setGSTDoc([]);
      setLiftClearValid("");
      setLiftClearValidUpto(null);
      setLiftClearDoc([]);
      setMusicNOCValid("");
      setMusicNOCValidUpto(null);
      setMusicNOCDoc([]);
      setSinageNOCValid("");
      setSinageNOCValidUpto(null);
      setSinageNOCDoc([]);
    }
  }, [dbData]);

  useEffect(() => {
    if (updatedSupplyData?.updateSupply?.status === true) {
      toaster("success", "Hostel details updated successfully");
    } else if (updatedSupplyData?.updateSupply?.status === false) {
      toaster("error", "Error: Hostel details update failed");
    }
  }, [updatedSupplyData]);
  const submitHandler = (e) => {
    e.preventDefault();
    let data = {
      leaseDeedType: leaseDeedType?.value,
      leaseDeed,
      legalJurisdictionCity,
      legalJurisdictionState: legalJurisdictionState?.value,
      leaseDeedBearer: leaseDeedBearer?.value,
      stampDutyPercent: stampDutyPercent !== null ? Number(stampDutyPercent) : null,
      stampDutyCharges: stampDutyCharges !== null ? Number(stampDutyCharges) : null,
      registeredLicensesEntity,
      licensesResponsibility: licensesResponsibility?.value,
      licensesFeesResponsibility: licensesFeesResponsibility?.value,
    };

    if (municipalityTradeValid?.value === "Yes") {
      data.municipalityTradeValid = municipalityTradeValid?.value;
      data.municipalityTradeValidUpto = municipalityTradeValidUpto;
      data.municipalityTradeDoc = municipalityTradeDoc;
    } else {
      data.municipalityTradeValid = municipalityTradeValid?.value;
    }

    if (healthSanitaryValid?.value === "Yes") {
      data.healthSanitaryValid = healthSanitaryValid?.value;
      data.healthSanitaryValidUpto = healthSanitaryValidUpto;
      data.healthSanitaryDoc = healthSanitaryDoc;
    } else {
      data.healthSanitaryValid = healthSanitaryValid?.value;
    }

    if (labourValid?.value === "Yes") {
      data.labourValid = labourValid?.value;
      data.labourValidUpto = labourValidUpto;
      data.labourDoc = labourDoc;
    } else {
      data.labourValid = labourValid?.value;
    }

    if (pcbCTOValid?.value === "Yes") {
      data.pcbCTOValid = pcbCTOValid?.value;
      data.pcbCTOValidUpto = pcbCTOValidUpto;
      data.pcbCTODoc = pcbCTODoc;
    } else {
      data.pcbCTOValid = pcbCTOValid?.value;
    }

    if (fireNOCValid?.value === "Yes") {
      data.fireNOCValid = fireNOCValid?.value;
      data.fireNOCValidUpto = fireNOCValidUpto;
      data.fireNOCDoc = fireNOCDoc;
    } else {
      data.fireNOCValid = fireNOCValid?.value;
    }

    if (tourismValid?.value === "Yes") {
      data.tourismValid = tourismValid?.value;
      data.tourismValidUpto = tourismValidUpto;
      data.tourismDoc = tourismDoc;
    } else {
      data.tourismValid = tourismValid?.value;
    }

    if (pccNOCValid?.value === "Yes") {
      data.pccNOCValid = pccNOCValid?.value;
      data.pccNOCValidUpto = pccNOCValidUpto;
      data.pccNOCDoc = pccNOCDoc;
    } else {
      data.pccNOCValid = pccNOCValid?.value;
    }

    if (exciseBarValid?.value === "Yes") {
      data.exciseBarValid = exciseBarValid?.value;
      data.exciseBarValidUpto = exciseBarValidUpto;
      data.exciseBarDoc = exciseBarDoc;
    } else {
      data.exciseBarValid = exciseBarValid?.value;
    }

    if (shopEstabValid?.value === "Yes") {
      data.shopEstabValid = shopEstabValid?.value;
      data.shopEstabValidUpto = shopEstabValidUpto;
      data.shopEstabDoc = shopEstabDoc;
    } else {
      data.shopEstabValid = shopEstabValid?.value;
    }

    if (fssaiValid?.value === "Yes") {
      data.fssaiValid = fssaiValid?.value;
      data.fssaiValidUpto = fssaiValidUpto;
      data.fssaiDoc = fssaiDoc;
    } else {
      data.fssaiValid = fssaiValid?.value;
    }

    if (gstValid?.value === "Yes") {
      data.gstValid = gstValid?.value;
      data.gstValidUpto = gstValidUpto;
      data.gstDoc = gstDoc;
    } else {
      data.gstValid = gstValid?.value;
    }

    if (liftClearValid?.value === "Yes") {
      data.liftClearValid = liftClearValid?.value;
      data.liftClearValidUpto = liftClearValidUpto;
      data.liftClearDoc = liftClearDoc;
    } else {
      data.liftClearValid = liftClearValid?.value;
    }

    if (musicNOCValid?.value === "Yes") {
      data.musicNOCValid = musicNOCValid?.value;
      data.musicNOCValidUpto = musicNOCValidUpto;
      data.musicNOCDoc = musicNOCDoc;
    } else {
      data.musicNOCValid = musicNOCValid?.value;
    }

    if (sinageNOCValid?.value === "Yes") {
      data.sinageNOCValid = sinageNOCValid?.value;
      data.sinageNOCValidUpto = sinageNOCValidUpto;
      data.sinageNOCDoc = sinageNOCDoc;
    } else {
      data.sinageNOCValid = sinageNOCValid?.value;
    }

    updateSupplyHandler(id, data);

    dispatch(
      tableDataHandler({
        ...state?.tableData,
        ...data,
      })
    );
  };
  const userDepartment = localStorage.getItem("department")
  const isEditableByuser = (e, name) => {
    if (userRole === "CEO" || userDepartment === "Supply" || userDepartment === "Management") {
      return true;
    }
    const editableFields = {
      Operations: [
        "municipalityTradeValid", "municipalityTradeValidUpto", "municipalityTradeDoc",
        "healthSanitaryValid", "healthSanitaryValidUpto", "healthSanitaryDocUpload",
        "labourValid", "labourValidUpto", "labourDocUpload",
        "pcbCTOValid", "pcbCTOValidUpto", "pcbctoDocUpload",
        "fireNOCValid", "fireNOCValidUpto", "fireNocDocUpload",
        "tourismValid", "tourismValidUpto", "tourishDocUpload",
        "pccNOCValid", "pccNOCValidUpto", "pccNocDocUpload",
        "exciseBarValid", "exciseBarValidUpto", "exciseBarDocUpload",
        "shopEstabValid", "shopEstabValidUpto", "shopEstabDocUpload",
        "fssaiValid", "fssaiValidUpto", "fssaiDocUpload",
        "gstValid", "gstValidUpto", "gstValidDocUpload",
        "liftClearValid", "liftClearValidUpto", "liftClearDocUpload",
        "musicNOCValid", "musicNOC", "musicNocDocUpload",
        "sinageNOCValid", "sinageNOCValidUpto", "sinageDocUpload"
      ]
    };

    const fieldName = e;

    if (editableFields[userDepartment] && editableFields[userDepartment].includes(fieldName)) {
      return true;
    }
    return false
  }

  const calculateRemainingFields = () => {
    let remainingCount = 0;
    let fields = [
      leaseDeedType,
      leaseDeed,
      legalJurisdictionCity,
      legalJurisdictionState,
      leaseDeedBearer,
      stampDutyPercent,
      stampDutyCharges,
      registeredLicensesEntity,
      licensesResponsibility,
      licensesFeesResponsibility,
      municipalityTradeValid,
      healthSanitaryValid,
      labourValid,
      pcbCTOValid,
      fireNOCValid,
      tourismValid,
      pccNOCValid,
      exciseBarValid,
      shopEstabValid,
      fssaiValid,
      gstValid,
      liftClearValid,
      musicNOCValid,
      sinageNOCValid,
    ];
    if (municipalityTradeValid?.value === "Yes") fields.push(municipalityTradeValidUpto, municipalityTradeDoc)
    if (healthSanitaryValid?.value === "Yes") fields.push(healthSanitaryValidUpto, healthSanitaryDoc)
    if (labourValid?.value === "Yes") fields.push(labourValidUpto, labourDoc)
    if (pcbCTOValid?.value === "Yes") fields.push(pcbCTOValidUpto, pcbCTODoc)
    if (fireNOCValid?.value === "Yes") fields.push(fireNOCValidUpto, fireNOCDoc)
    if (tourismValid?.value === "Yes") fields.push(tourismValidUpto, tourismDoc)
    if (pccNOCValid?.value === "Yes") fields.push(pccNOCValidUpto, pccNOCDoc)
    if (exciseBarValid?.value === "Yes") fields.push(exciseBarValidUpto, exciseBarDoc)
    if (shopEstabValid?.value === "Yes") fields.push(shopEstabValidUpto, shopEstabDoc)
    if (fssaiValid?.value === "Yes") fields.push(fssaiValidUpto, fssaiDoc)
    if (gstValid?.value === "Yes") fields.push(gstValidUpto, gstDoc)
    if (liftClearValid?.value === "Yes") fields.push(liftClearValidUpto, liftClearDoc)
    if (musicNOCValid?.value === "Yes") fields.push(musicNOCValidUpto, musicNOCDoc)
    if (sinageNOCValid?.value === "Yes") fields.push(setExciseBarValidUpto, sinageNOCDoc)
    fields.forEach(field => {
      if (typeof field === "object") {
        if (field?.[0]?.imageURL === null) {
          remainingCount++;
        } else if (field?.label === undefined && !field?.[0]?.imageURL) {
          if (!(field instanceof Date)) {
            remainingCount++;
          }
        }
      } else {
        if (field === null || field === "" || field === undefined || field.length === 0) {
          remainingCount++;
        }
      }
    });

    setRemainingCount(`(${remainingCount} fields pending / ${fields.length} )`)
    return `${remainingCount}/${fields.length}`;
  };
  calculateRemainingFields()
  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        {/* //! Lease deed type */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="leaseDeedType">
                Lease deed type
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="leaseDeedType"
              name="leaseDeedType"
              options={LeaseDeedTypeList}
              value={leaseDeedType}
              onChange={(value) => { !isEditableByuser("leaseDeedType") && setLeaseDeedType(value) }}
              classNamePrefix="select"
              placeholder=""
              isDisabled={!isEditableByuser("leaseDeedType")}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !leaseDeedType ? 'red' : provided.borderColor,
                }),
              }}
            />
            <Label className="form-label" for="leaseDeedType">
              {
                ["Notarised lease deed", "Notarised LL", "Notarised OMC"].includes(leaseDeedType.value) && "Registration of the lease deed is pending"
              }
            </Label>
          </FormGroup>
        </Col>

        {/*//! Upload lease deed */}
        <Col sm="3">
          <strong>
            {" "}
            <Label className="form-label" for="llpRepresentativeAadharCard">
              Upload lease deed
            </Label>
          </strong>
          <ImageModal
            imageList={leaseDeed}
            setImageList={setLeaseDeed}
            editData={leaseDeedImageDummyState}
            uploadType={uploadLeaseDeedImageType}
            canBeEdited={isEditableByuser("leaseDeedUpload")}
            fieldName={"Lease deed"}
          />
        </Col>

        {/* //! Legal jurisdiction city */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Legal jurisdiction city</Label>
            </strong>
            <Input
              type="text"
              value={legalJurisdictionCity}
              id="legalJurisdictionCity"
              name="legalJurisdictionCity"
              //placeholder="Add legal jurisdiction city"
              onChange={(e) => setLegalJurisdictionCity(e.target.value)}
              disabled={!isEditableByuser("legalJurisdictionCity")}
              style={{
                borderColor: !legalJurisdictionCity && 'red',
              }}
            />
          </FormGroup>
        </Col>

        {/* //! Legal jurisdiction state */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Legal jurisdiction state</Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="legalJurisdictionState"
              name="legalJurisdictionState"
              options={indianStateList}
              value={legalJurisdictionState}
              onChange={(value) => setLegalJurisdictionState(value)}
              isDisabled={!isEditableByuser("legalJurisdictionState")}
              classNamePrefix="select"
              placeholder=""
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !legalJurisdictionState ? 'red' : provided.borderColor,
                }),
              }}
            />
          </FormGroup>
        </Col>

        {/* //! Original lease deed bearer */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Original lease deed bearer</Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="leaseDeedBearer"
              name="leaseDeedBearer"
              options={LicensesOfficialFeesResponsibilityTypeList}
              value={leaseDeedBearer}
              onChange={(value) => setLeaseDeedBearer(value)}
              isDisabled={!isEditableByuser("leaseDeedBearer")}
              classNamePrefix="select"
              placeholder=""
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !leaseDeedBearer ? 'red' : provided.borderColor,
                }),
              }}
            />
          </FormGroup>
        </Col>

        {/* //! Lessee's stamp duty & reg. charges % */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Lessee's stamp duty & reg. charges %
              </Label>
            </strong>
            <Input
              type="text"
              value={stampDutyPercent}
              id="stampDutyPercent"
              name="stampDutyPercent"
              //placeholder="Add Lessee's stamp duty & reg. charges %"
              onChange={(e) => setStampDutyPercent(e.target.value)}
              disabled={!isEditableByuser("stampDutyPercent")}
              style={{
                borderColor: stampDutyPercent == null && 'red',
              }}
            />
          </FormGroup>
        </Col>

        {/* //! Lessee's stamp duty & reg. charges amount */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Lessee's stamp duty & reg. charges amount
              </Label>
            </strong>
            <Input
              type="number"
              value={stampDutyCharges}
              id="stampDutyCharges"
              name="stampDutyCharges"
              //placeholder="Add lessee's stamp duty & reg. charges amount"
              onChange={(e) => setStampDutyCharges(e.target.value)}
              disabled={!isEditableByuser("stampDutyCharges")}
              onWheel={(e) => e.target.blur()}
              style={{
                borderColor: stampDutyCharges == null && 'red',
              }}
            />
          </FormGroup>
        </Col>

        {/* //!  Registered licenses entity */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="registeredLicensesEntity">
                Registered licenses entity
              </Label>
            </strong>
            <Input
              type="text"
              value={registeredLicensesEntity}
              id="registeredLicensesEntity"
              name="registeredLicensesEntity"
              onChange={(e) => setRegisteredLicensesEntity(e.target.value)}
              disabled={!isEditableByuser("registeredLicensesEntity")}
              style={{
                borderColor: !registeredLicensesEntity && 'red',
              }}
            />
          </FormGroup>
        </Col>

        {/* //! Licenses obtainance/renewal responsibility */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesResponsibility">
                Licenses obtainance/renewal responsibility
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="licensesResponsibility"
              name="licensesResponsibility"
              options={LicensesObtainanceOrRenewalResponsibilityTypeList}
              value={licensesResponsibility}
              onChange={(value) => setLicensesResponsibility(value)}
              isDisabled={!isEditableByuser("licensesResponsibility")}
              classNamePrefix="select"
              placeholder=""
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !licensesResponsibility ? 'red' : provided.borderColor,
                }),
              }}
            />
          </FormGroup>
        </Col>

        {/* //! Licenses official fees responsibility */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                Licenses official fees responsibility
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="licensesFeesResponsibility"
              name="licensesFeesResponsibility"
              options={LicensesOfficialFeesResponsibilityTypeList}
              value={licensesFeesResponsibility}
              onChange={(value) => setLicensesFeesResponsibility(value)}
              isDisabled={!isEditableByuser("licensesFeesResponsibility")}
              classNamePrefix="select"
              placeholder=""
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !licensesFeesResponsibility ? 'red' : provided.borderColor,
                }),
              }}
            />
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="municipalityTradeValid ">
                Municipality trade license
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="municipalityTradeValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="municipalityTradeValid "
                    name="municipalityTradeValid "
                    options={ValidTypeList}
                    value={municipalityTradeValid}
                    onChange={(value) => { isEditableByuser("municipalityTradeValid") && setMunicipalityTradeValid(value) }}
                    isDisabled={!isEditableByuser("municipalityTradeValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !municipalityTradeValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {municipalityTradeValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={municipalityTradeValidUpto}
                        onChange={(date) => {
                          isEditableByuser("municipalityTradeValidUpto") && setMunicipalityTradeValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        style={{
                          borderColor: municipalityTradeValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                        id="municipalityTradeValidUpto"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="floorPlans">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={municipalityTradeDoc}
                      setImageList={setMunicipalityTradeDoc}
                      editData={municipalityTradeDocImageDummyState}
                      uploadType={uploadMunicipalityTradeDocImageType}
                      canBeEdited={isEditableByuser("municipalityTradeDoc")}
                      fieldName={"muncipality trade Doc"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="healthSanitary ">
                Health / Sanitory license
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="healthSanitaryValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="healthSanitaryValid "
                    name="healthSanitaryValid "
                    options={ValidTypeList}
                    value={healthSanitaryValid}
                    onChange={(value) => { isEditableByuser("healthSanitaryValid") && setHealthSanitaryValid(value) }}
                    isDisabled={!isEditableByuser("healthSanitaryValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !healthSanitaryValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {healthSanitaryValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={healthSanitaryValidUpto}
                        onChange={(date) => {
                          isEditableByuser("healthSanitaryValidUpto") && setHealthSanitaryValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="healthSanitaryValidUpto"
                        required
                        style={{
                          borderColor: healthSanitaryValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="floorPlans">
                      Upload
                    </Label>
                    <ImageModal
                      imageList={healthSanitaryDoc}
                      setImageList={setHealthSanitaryDoc}
                      editData={healthSanitaryDocImageDummyState}
                      uploadType={uploadHealthSanitaryDocImageType}
                      canBeEdited={isEditableByuser("healthSanitaryDocUpload")}
                      fieldName={"health sanitary Doc"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="labourValid ">
                Labour License
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="labourValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="labourValid "
                    name="labourValid "
                    options={ValidTypeList}
                    value={labourValid}
                    onChange={(value) => { isEditableByuser("labourValid") && setLabourValidValid(value) }}
                    isDisabled={!isEditableByuser("labourValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !labourValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {labourValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={labourValidUpto}

                        onChange={(date) => {
                          isEditableByuser("labourValidUpto") && setLabourValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="labourValidUpto"
                        required
                        style={{
                          borderColor: labourValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="labourDoc">
                      Upload
                    </Label>
                    {/* </strong> */}

                    <ImageModal
                      imageList={labourDoc}
                      setImageList={setLabourDoc}
                      editData={labourDocImageDummyState}
                      uploadType={uploadLabourDocImageType}
                      canBeEdited={isEditableByuser("labourDocUpload")}
                      fieldName={"Labour licence"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="pcbCTOValid ">
                PCB CTO certificate
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="pcbCTOValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="pcbCTOValid "
                    name="pcbCTOValid "
                    options={ValidTypeList}
                    value={pcbCTOValid}
                    onChange={(value) => { isEditableByuser("pcbCTOValid") && setPCBCTOValid(value) }}
                    isDisabled={!isEditableByuser("pcbCTOValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !pcbCTOValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {pcbCTOValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={pcbCTOValidUpto}
                        onChange={(date) => {
                          isEditableByuser("pcbCTOValidUpto") && setPcbCTOValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="pcbCTOValidUpto"
                        required
                        style={{
                          borderColor: pcbCTOValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="pcbCTO">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={pcbCTODoc}
                      setImageList={setPcbCTODoc}
                      editData={pcbCTODocImageDummyState}
                      uploadType={uploadPcbCTODocImageType}
                      canBeEdited={isEditableByuser("pcbctoDocUpload")}
                      fieldName={"PCB CTO certificate"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="fireNOCValid ">
                Fire NOC
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="fireNOCValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="fireNOCValid "
                    name="fireNOCValid "
                    options={ValidTypeList}
                    value={fireNOCValid}
                    onChange={(value) => { isEditableByuser("fireNOCValid") && setFireNOCValid(value) }}
                    isDisabled={!isEditableByuser("fireNOCValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !fireNOCValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {fireNOCValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="fireNOC">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={fireNOCValidUpto}
                        onChange={(date) => {
                          isEditableByuser("fireNOCValidUpto") && setFireNOCValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="fireNOCValidUpto"
                        required
                        style={{
                          borderColor: fireNOCValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="fireNOC">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={fireNOCDoc}
                      setImageList={setFireNOCDoc}
                      editData={fireNOCDocImageDummyState}
                      uploadType={uploadFireNOCDocImageType}
                      canBeEdited={isEditableByuser("fireNocDocUpload")}
                      fieldName={"Fire NOC"}

                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="tourismValid ">
                Tourism
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="tourismValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="tourismValid "
                    name="tourismValid "
                    options={ValidTypeList}
                    value={tourismValid}
                    onChange={(value) => { isEditableByuser("tourismValid") && setTourismValid(value) }}
                    isDisabled={!isEditableByuser("tourismValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !tourismValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {tourismValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={tourismValidUpto}
                        onChange={(date) => {
                          isEditableByuser("tourismValidUpto") && setTourismValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="tourismValidUpto"
                        required
                        style={{
                          borderColor: tourismValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="floorPlans">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={tourismDoc}
                      setImageList={setTourismDoc}
                      editData={tourismDocImageDummyState}
                      uploadType={uploadTourismDocImageType}
                      canBeEdited={isEditableByuser("tourishDocUpload")}
                      fieldName={"Tourism"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="pccNOCValid ">
                PCC NOC
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="pccNOCValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="pccNOCValid "
                    name="pccNOCValid "
                    options={ValidTypeList}
                    value={pccNOCValid}
                    onChange={(value) => { isEditableByuser("pccNOCValid") && setPCCNOCValid(value) }}
                    isDisabled={!isEditableByuser("pccNOCValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !pccNOCValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {pccNOCValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={pccNOCValidUpto}
                        onChange={(date) => {
                          isEditableByuser("pccNOCValidUpto") && setPCCNOCValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="pccNOCValidUpto"
                        required
                        style={{
                          borderColor: pccNOCValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="floorPlans">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={pccNOCDoc}
                      setImageList={setPCCNOCDoc}
                      editData={pccNOCDocImageDummyState}
                      uploadType={uploadPccNOCDocImageType}
                      canBeEdited={isEditableByuser("pccNocDocUpload")}
                      fieldName={"PCC NOC"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="exciseBarValid ">
                Excise & bar
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="exciseBarValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="exciseBarValid "
                    name="exciseBarValid "
                    options={ValidTypeList}
                    value={exciseBarValid}
                    onChange={(value) => { isEditableByuser("exciseBarValid") && setExciseBarValid(value) }}
                    isDisabled={!isEditableByuser("exciseBarValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !exciseBarValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {exciseBarValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={exciseBarValidUpto}
                        onChange={(date) => {
                          isEditableByuser("exciseBarValidUpto") && setExciseBarValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="exciseBarValidUpto"
                        required
                        style={{
                          borderColor: exciseBarValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="floorPlans">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={exciseBarDoc}
                      setImageList={setExciseBarDoc}
                      editData={exciseBarDocImageDummyState}
                      uploadType={uploadExciseBarDocImageType}
                      canBeEdited={isEditableByuser("exciseBarDocUpload")}
                      fieldName={"Excise & bar"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="shopEstabValid ">
                Shop & establishment
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="shopEstabValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="shopEstabValid "
                    name="shopEstabValid "
                    options={ValidTypeList}
                    value={shopEstabValid}
                    onChange={(value) => { isEditableByuser("shopEstabValid") && setShopEstabValid(value) }}
                    isDisabled={!isEditableByuser("shopEstabValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !shopEstabValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {shopEstabValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={shopEstabValidUpto}

                        onChange={(date) => {
                          isEditableByuser("shopEstabValidUpto") && setShopEstabValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="shopEstabValidUpto"
                        required
                        style={{
                          borderColor: shopEstabValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="floorPlans">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={shopEstabDoc}
                      setImageList={setShopEstabDoc}
                      editData={shopEstabDocImageDummyState}
                      uploadType={uploadShopEstabDocImageType}
                      canBeEdited={isEditableByuser("shopEstabDocUpload")}
                      fieldName={"Shop & establishment"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="fssaiValid ">
                FSSAI
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="fssaiValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="fssaiValid"
                    name="fssaiValid"
                    options={ValidTypeList}
                    value={fssaiValid}
                    onChange={(value) => { isEditableByuser("fssaiValid") && setFSSAIValid(value) }}
                    isDisabled={!isEditableByuser("fssaiValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !fssaiValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {fssaiValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={fssaiValidUpto}
                        onChange={(date) => {
                          isEditableByuser("fssaiValidUpto") && setFSSAIValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="fssaiValidUpto"
                        required
                        style={{
                          borderColor: fssaiValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="floorPlans">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={fssaiDoc}
                      setImageList={setFSSAIDoc}
                      editData={fssaiDocImageDummyState}
                      uploadType={uploadFssaiDocImageType}
                      canBeEdited={isEditableByuser("fssaiDocUpload")}
                      fieldName={"FSSAI"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="gstValid ">
                Company's GST certificate
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="gstValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="gstValid "
                    name="gstValid "
                    options={ValidTypeList}
                    value={gstValid}
                    onChange={(value) => { isEditableByuser("gstValid") && setGSTValid(value) }}
                    isDisabled={!isEditableByuser("gstValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !gstValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {gstValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={gstValidUpto}
                        onChange={(date) => {
                          isEditableByuser("gstValidUpto") && setGSTValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="gstValidUpto"
                        required
                        style={{
                          borderColor: gstValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="floorPlans">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={gstDoc}
                      setImageList={setGSTDoc}
                      editData={gstDocImageDummyState}
                      uploadType={uploadGstDocImageType}
                      canBeEdited={isEditableByuser("gstValidDocUpload")}
                      fieldName={"GST certificate"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="liftClearValid ">
                Lift clearance
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="liftClearValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="liftClearValid "
                    name="liftClearValid "
                    options={ValidTypeList}
                    value={liftClearValid}
                    onChange={(value) => { isEditableByuser("liftClearValid") && setLiftClearValid(value) }}
                    isDisabled={!isEditableByuser("liftClearValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !liftClearValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {liftClearValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={liftClearValidUpto}
                        onChange={(date) => {
                          isEditableByuser("liftClearValidUpto") && setLiftClearValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="liftClearValidUpto"
                        required
                        style={{
                          borderColor: liftClearValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="liftClearDoc">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={liftClearDoc}
                      setImageList={setLiftClearDoc}
                      editData={liftClearDocImageDummyState}
                      uploadType={uploadLiftClearDocImageType}
                      canBeEdited={isEditableByuser("liftClearDocUpload")}
                      fieldName={"Lift clearance"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="musicNOCValid ">
                Music NOC
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="musicNOCValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="musicNOCValid "
                    name="musicNOCValid "
                    options={ValidTypeList}
                    value={musicNOCValid}
                    onChange={(value) => { isEditableByuser("musicNOCValid") && setMusicNOCValid(value) }}
                    isDisabled={!isEditableByuser("musicNOCValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !musicNOCValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {musicNOCValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={musicNOCValidUpto}
                        onChange={(date) => {
                          isEditableByuser("musicNOC") && setMusicNOCValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="musicNOC"
                        required
                        style={{
                          borderColor: musicNOCValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="floorPlans">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={musicNOCDoc}
                      setImageList={setMusicNOCDoc}
                      editData={musicNOCDocImageDummyState}
                      uploadType={uploadMusicNOCDocImageType}
                      canBeEdited={isEditableByuser("musicNocDocUpload")}
                      fieldName={"Music NOC"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>

        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="sinageNOCValid ">
                Signage NOC
              </Label>
            </strong>

            <Row>
              <Col sm="3">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="sinageNOCValid ">
                    Valid
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="sinageNOCValid "
                    name="sinageNOCValid "
                    options={ValidTypeList}
                    value={sinageNOCValid}
                    onChange={(value) => { isEditableByuser("sinageNOCValid") && setSinageNOCValid(value) }}
                    isDisabled={!isEditableByuser("sinageNOCValid")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !sinageNOCValid ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </FormGroup>
              </Col>

              {sinageNOCValid?.value === "Yes" && (
                <>
                  {" "}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Valid upto
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control text-dark"
                        value={sinageNOCValidUpto}

                        onChange={(date) => {
                          isEditableByuser("sinageNOCValidUpto") && setSinageNOCValidUpto(
                            new Date(dateFormatter(new Date(date)))
                          )
                        }}
                        id="sinageNOCValidUpto"
                        required
                        style={{
                          borderColor: sinageNOCValidUpto ? "lightblue" : "red",
                          opacity: 1,
                          backgroundColor: "white"
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="floorPlans">
                      Upload
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={sinageNOCDoc}
                      setImageList={setSinageNOCDoc}
                      editData={sinageNOCDocImageDummyState}
                      uploadType={uploadSinageNOCDocImageType}
                      canBeEdited={isEditableByuser("sinageDocUpload")}
                      fieldName={"Signage NOC"}
                    />
                  </Col>
                </>
              )}
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default DealRegistrationLicenses;
