import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, CardHeader, CardTitle, Table, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useCreateLaundryTrackerData, useUpdateLaundryTracker } from '../../../api/LaundryTracker/Mutation';
import { useCreateLaundryMonthlyData, useUpdateLaundryMonthlyData } from '../../../api/LaundryMonthlyData/Mutation';
import LogsModal from "./LogsModal";
import { FileMinus, Edit2 } from "react-feather";
import toaster from "@components/common/Toaster";
import { useGetLaundryMonthlyDataByHostelId } from '../../../api/LaundryMonthlyData/Queries';
import ActualLogsModal from './ActualLogsModal';
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const LaundryTrackerTable = (props) => {
  const { masterData, tableHeaderData, setId, data, dateArray, refetch, selectedMonth, selectedYear, dormsData, privateData } = props;
  const [openLogs, setOpenLogs] = useState(false);
  const [actualOpenLogs, setActualOpenLogs] = useState(false);
  const [inputData, setInputData] = useState({});
  const [actualTotalData, setActualTotalData] = useState({});
  const [selectedLogs, setSelectedLogs] = useState(null);
  const [actualLogs, setActualLogs] = useState(null);
  const [monthlyData, setMonthlyData] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [notes, setNotes] = useState({});
  const [createLaundryTrackerDataHandler] = useCreateLaundryTrackerData();
  const [updateLaundryTrackerHandler] = useUpdateLaundryTracker();
  const [createLaundryMonthlyDataHandler] = useCreateLaundryMonthlyData();
  const [updateLaundryMonthlyDataHandler] = useUpdateLaundryMonthlyData();
  const id = masterData?.hostelId?._id
  const { data: laundryMonthlyData, refetch: refetchLaundryMonthlyData } = useGetLaundryMonthlyDataByHostelId(id, selectedYear, selectedMonth);

  useEffect(() => {
      if (laundryMonthlyData?.getLaundryMonthlyDataByHostelId) {
        setMonthlyData(laundryMonthlyData.getLaundryMonthlyDataByHostelId);
      } else if (id) {
        setMonthlyData(null);
      }
    }, [laundryMonthlyData, id]);

  const headerStyle = {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  };

  const tableStartFrom = 0;

  const formatTimestampToDate = (timestampString) => {
    const timestamp = Number(timestampString);
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0];
  };

  const getInitialValue = (itemName, date) => {
    if (!Array.isArray(data)) return '';
  
    const matchedData = data.find((item) => {
      const isItemNameMatched = item.itemName === itemName;
  
      const formattedDate = formatTimestampToDate(item.date);
      const isDateMatched = formattedDate === date;
  
      return (
        item.hostelId?._id === masterData?.hostelId?._id &&
        item.laundryVendorId === masterData?._id &&
        isItemNameMatched &&
        isDateMatched
      );
    });
  
    return matchedData ? matchedData?.quantity : '';
  };

  const getInitialNotes = (itemName, date) => {
    if (!Array.isArray(data)) return '';
  
    const matchedData = data.find((item) => {
      const isItemNameMatched = item.itemName === itemName;
  
      const formattedDate = formatTimestampToDate(item.date);
      const isDateMatched = formattedDate === date;
  
      return (
        item.hostelId?._id === masterData?.hostelId?._id &&
        item.laundryVendorId === masterData?._id &&
        isItemNameMatched &&
        isDateMatched
      );
    });
  
    return matchedData ? matchedData?.notes : '';
  };

  const handleSave = async (masterData, date) => {
    try {
      let createArray =[]
      let updateArray =[]
      masterData.itemRates.forEach((rate) => {
        const notesData = notes[`${rate?.itemName}-${date}`];
        let condition =true
        const quantity = inputData[`${rate?.itemName}-${date}`] ? inputData[`${rate?.itemName}-${date}`] : notesData  ? getInitialValue(rate?.itemName,date)  : "";
        if (!quantity) return;
        const existingQuantity = getInitialValue(rate?.itemName, date);
        const existingNotes = getInitialNotes(rate?.itemName, date);
        const laundryData = {
          hostelId: masterData?.hostelId?._id,
          laundryVendorId: masterData?._id,
          itemName:rate?.itemName,
          date,
          quantity: Number(quantity),
          notes:notesData
        };

        if (existingQuantity || existingNotes) {
          const matchedData = data.find(
            (item) =>
              item.itemName === rate?.itemName &&
              formatTimestampToDate(item.date) === date
          );
          if (matchedData && matchedData._id) {
            updateArray.push({...laundryData, _id: matchedData._id})
            condition=false
          }
        } else {
          createArray.push(laundryData)
        }
        
        setInputData((prevData) => {
          const newData = { ...prevData };
          delete newData[`${rate?.itemName}-${date}`];
          return newData;
        });

        setNotes((prevData) => {
          const newData = { ...prevData };
          delete newData[`${rate?.itemName}-${date}`];
          return newData;
        });
      });
      
      if(updateArray?.length > 0){
       await updateLaundryTrackerHandler(updateArray)
        toaster("success", "Quantity updated successfully.");
      }
      if(createArray?.length > 0){
        await createLaundryTrackerDataHandler(createArray)
        toaster("success", "Quantity created successfully.");
      }
      refetch(); 
    } catch (error) {
      console.error(error);
    }
  };
  
  const convertToFirstDayOfMonth = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const getDataOfLogs = (date) => {
    if (!Array.isArray(data)) return [];
  
    const matchedDataLogs = data.filter((item) => {
      const formattedDate = formatTimestampToDate(item.date);
      return formattedDate === date;
    });
  
    return matchedDataLogs;
  };

  const calculateMonthlyTotal = (itemName) => {
    return dateArray.reduce((total, date) => {
      const savedValue = Number(getInitialValue(itemName, date.label) || 0);
      return total + savedValue;
    }, 0);
  };
  
  const calculateGrandTotal  = () => {
    return masterData?.itemRates?.reduce((grandTotal, item) => {
      return grandTotal + calculateMonthlyTotal(item.itemName)
    }, 0)
  }

  const calculateActualTotal = () => {
    return laundryMonthlyData?.getLaundryMonthlyDataByHostelId?.reduce(
      (grandTotal, item) => grandTotal + (item.actualTotalQuantity || 0),
      0);
  };

  const handleMonthlyDataSave = async () => {
    if (!masterData?.itemRates?.length) {
      console.error("No item rates available to save.");
      return;
    }
  
    try {
      await refetchLaundryMonthlyData(); 
  
      const savePromises = masterData.itemRates.map((item) => {
          const monthlyTotal = calculateMonthlyTotal(item.itemName);
          const actualTotal = actualTotalData[item.itemName];
  
          if (actualTotal == null || isNaN(actualTotal)) return null; 
  
          const difference = actualTotal - monthlyTotal;
  
          return {
            hostelId: masterData?.hostelId?._id,
            laundryVendorId: masterData?._id,
            itemName: item.itemName,
            year: selectedYear,
            month: selectedMonth,
            monthlyTotalQuantity: monthlyTotal || 0, 
            actualTotalQuantity: actualTotal, 
            difference,
          };
        })?.filter((ele) => ele !== null && ele?.actualTotalQuantity > 0);

      if (savePromises.length > 0) {
        await updateLaundryMonthlyDataHandler(savePromises);
      }
  
      toaster("success", "All actual total data saved successfully.");
      await refetchLaundryMonthlyData();
      refetch();
    } catch (error) {
      console.error("Error saving monthly data:", error);
      toaster("error", "Error saving monthly data. Please try again.");
    }
  };

  const calculateDifference = (itemName) => {
    const monthlyTotal = calculateMonthlyTotal(itemName);
    const actualTotal =
      actualTotalData[itemName] !== undefined
        ? actualTotalData[itemName]
        : (monthlyData?.find((data) => data.itemName === itemName)?.actualTotalQuantity || 0);
    
    return actualTotal ? actualTotal - monthlyTotal : "-";
  };

  const totalDormsSold = dateArray.reduce((total, date) => {
    if(!Array.isArray(dormsData) || dormsData.length === 0) return total;
    const matchedDorm = dormsData.find(d => convertToFirstDayOfMonth(d._id) === date.label);
    return total + (matchedDorm ? matchedDorm.roomSold : 0);
  }, 0);

  const totalPrivateSold = dateArray.reduce((total, date) => {
    if(!Array.isArray(privateData) || privateData.length === 0) return total;
    const matchedPrivate = privateData.find(p => convertToFirstDayOfMonth(p._id) === date.label);
    return total + (matchedPrivate ? matchedPrivate.roomSold : 0);
  }, 0)

  const totalTargetedLaundry = dateArray.reduce((total, date) => {
    const matchedPrivate = privateData?.find(p => convertToFirstDayOfMonth(p._id) === date.label);
    const matchedDorm = dormsData?.find(d => convertToFirstDayOfMonth(d._id) === date.label);
    const laundryAmountPerPrivateRoom = masterData?.laundryAmountPerPrivateRoom || 0;
    const laundryAmountPerDorm = masterData?.laundryAmountPerDorm || 0;
    const targetedLaundryAmount = 
      ((matchedPrivate ? matchedPrivate.roomSold : 0) * laundryAmountPerPrivateRoom) +
      ((matchedDorm ? matchedDorm.roomSold : 0) * laundryAmountPerDorm);

    return total + targetedLaundryAmount;
  }, 0);

  const calculateTotalAmount = () => {
    if (!Array.isArray(masterData?.itemRates)) return 0;
  
    return masterData.itemRates.reduce((total, rate) => {
      const actualTotalQuantity =
        actualTotalData?.[rate.itemName] ??
        monthlyData?.find((data) => data.itemName === rate.itemName)
          ?.actualTotalQuantity ??
        0;
  
      const itemTotal = rate.itemRate * actualTotalQuantity;
      return total + itemTotal;
    }, 0);
  };

  const handleEditClick = (itemName, dateLabel) => {
    setSelectedItem(itemName);
    setSelectedDate(dateLabel);
    setIsModalOpen(true);
  };
  
  const handleNotesChange = (e) => {
    const value = e.target.value;
    setNotes((prevData) => ({
      ...prevData,
      [`${selectedItem}-${selectedDate}`]: value
    }));
  };

  const handleNotesSave = () => {
    setNotes(notes); 
    setIsModalOpen(false); 
  };

  const calculateQuantityTotalForDate = (date) => {
    if (!Array.isArray(data)) {
      return 0;
    }
  
    const totalQuantity = data.reduce((total, item) => {
      const formattedDate = formatTimestampToDate(item.date);
      const isDateMatched = formattedDate === date;
  
      if (
        item.hostelId?._id === masterData?.hostelId?._id &&
        item.laundryVendorId === masterData?._id &&
        isDateMatched
      ) { return total + (item.quantity || 0); }
  
      return total;
    }, 0);
    return totalQuantity;
  };

  return (
    <Row>
      {setId && (
        <Col>
          <Card  style={{marginBottom: "100px"}}>
            <CardHeader>
              <CardTitle>Laundry Tracker Data</CardTitle>
            </CardHeader>

          {masterData && 
            <div>
              <Table size="sm" responsive hover>
                <thead>
                  <tr>
                    <th key={uuidv4()} style={{ position: "sticky", zIndex: "2", left: "0", fontSize: '12px', whiteSpace: 'nowrap', textAlign: 'center' }}>
                      Item
                    </th>
                    <th key={uuidv4()} style={{ position: "sticky", zIndex: "2", left: "250px", fontSize: '12px', whiteSpace: 'nowrap', textAlign: 'center' }}>
                      Unit rate
                    </th>
                    {tableHeaderData?.map((item, index) => (
                      <th key={uuidv4()} style={headerStyle}>
                        {index < tableStartFrom ? (
                          item.label
                        ) : (
                          <>
                            {new Date(item.label)?.toLocaleDateString('en-IN')}
                            <br />
                            {new Date(item.label).toLocaleString('en-US', {
                              weekday: 'short',
                            })}
                          </>
                        )}
                      </th>
                    ))}
                    {tableHeaderData?.length > 0 && (
                      <>
                        <th key={uuidv4()} style={headerStyle}>
                          Monthly qty. total
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          Actual total (as per bill)
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          Difference
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {masterData?.itemRates?.map((rate, index) => (
                    <tr key={index}>
                      <td style={{ backdropFilter:"blur(500px)", position: "sticky", zIndex: "2", left: "0", fontSize: '12px', whiteSpace: 'nowrap' }}>{rate.itemName}</td>
                      <td style={{ backdropFilter:"blur(500px)", position: "sticky", zIndex: "2", left: "250px", fontSize: '12px', whiteSpace: 'nowrap', textAlign: 'center' }}>{rate.itemRate}</td>
                      {dateArray.map((date) => {
                        const today = new Date();
                        const yesterday = new Date();
                        yesterday.setDate(today.getDate() - 1);
                      
                        const formatDate = (dateObj) => {
                          const year = dateObj.getFullYear();
                          const month = String(dateObj.getMonth() + 1).padStart(2, "0"); 
                          const day = String(dateObj.getDate()).padStart(2, "0"); 
                          return `${year}-${month}-${day}`;
                        };
                        
                        const todayLabel = formatDate(today);
                        const yesterdayLabel = formatDate(yesterday);
                        const isDisabled = date.label !== todayLabel && date.label !== yesterdayLabel;
                      
                        return (
                          <td style={headerStyle}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", gap: "5px", padding: "0 10px"}}>
                            {isDisabled ? (
                              <label
                                style={{
                                  width: '60px',
                                  fontSize: '12px',
                                  padding: '2px',
                                  // backgroundColor: "#f0f0f0",
                                  textAlign: 'center',
                                  display: 'inline-block',
                                  border: '1px solid #dcdcdc',
                                  cursor: 'not-allowed',
                                }}
                                title={`${getInitialNotes(rate.itemName, date.label) === null ? " " : getInitialNotes(rate.itemName, date.label)}`}
                              >
                                {inputData[`${rate.itemName}-${date.label}`] ?? getInitialValue(rate.itemName, date.label)}
                              </label>
                            ) : (
                              <input
                                type="text"
                                style={{
                                  width: '60px',
                                  fontSize: '12px',
                                  // textAlign: 'center',
                                  padding: '2px',
                                  backgroundColor: isDisabled ? "#f0f0f0" : "white",
                                  cursor: isDisabled ? "not-allowed" : "auto",
                                }}
                                maxLength={5}
                                value={
                                  (inputData[`${rate.itemName}-${date.label}`] ??
                                    getInitialValue(rate.itemName, date.label)) === 0
                                    ? ""
                                    : inputData[`${rate.itemName}-${date.label}`] ??
                                      getInitialValue(rate.itemName, date.label)
                                }
                                title= {`${getInitialNotes(rate.itemName, date.label) === null ? " " : getInitialNotes(rate.itemName, date.label)}`}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (!isDisabled && value >= 0) {
                                    setInputData((prevData) => ({
                                      ...prevData,
                                      [`${rate.itemName}-${date.label}`]: e.target.value === "" ? 0 : Number(e.target.value),
                                    }))
                                  }
                                } 
                              }
                                disabled={isDisabled}
                              />
                            )}
                            <Edit2
                              color={getInitialNotes(rate.itemName, date.label) ? "red" : reactFeatherIcon.iconColor}
                              style={{ height: "12px", width: "12px", cursor: isDisabled ? "not-allowed" : "pointer" }}
                              onClick={() => {
                                if (!isDisabled) {
                                  handleEditClick(rate.itemName, date.label);
                                }
                              }}
                            />
                            </div>
                          </td>
                        )
                      })}
                      <td style={headerStyle}>{calculateMonthlyTotal(rate.itemName)}</td>
                      <td style={headerStyle}>
                        <input
                          type="number"
                          style={{
                            width: '60px',
                            fontSize: '12px',
                            padding: '2px',
                          }}
                          value={
                            actualTotalData[rate.itemName] !== undefined
                              ? actualTotalData[rate.itemName] === 0
                                ? ""
                                : actualTotalData[rate.itemName]
                              : (monthlyData?.find((data) => data.itemName === rate.itemName)?.actualTotalQuantity === 0
                                ? ""
                                : monthlyData?.find((data) => data.itemName === rate.itemName)?.actualTotalQuantity ?? "")
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                              if (value >= 0) {
                                setActualTotalData((prevData) => ({
                                  ...prevData,
                                  [rate.itemName]: e.target.value === "" ? 0 : Number(e.target.value),
                                }))
                              }
                            }
                          }
                        />
                      </td>
                      <td style={headerStyle}>{calculateDifference(rate.itemName)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td style={{ backdropFilter:"blur(500px)", position: "sticky", zIndex: "2", left: "0", fontSize: '13px', whiteSpace: 'nowrap' }}>Save</td>
                    <td style={{ backdropFilter:"blur(500px)", position: "sticky", zIndex: "2", left: "250px", fontSize: '12px', whiteSpace: 'nowrap', textAlign: 'center' }}></td>
                    {dateArray.map((date) => (
                      <td key={uuidv4()} style={{ textAlign: 'center' }}>
                        <Button.Ripple
                          type="button"
                          color="success"
                          onClick={() => {
                            if (masterData?.itemRates?.length) {
                              handleSave(masterData,date.label)
                            } else {
                              console.error('No item rates available to save.');
                            }
                          }}
                        >
                          Save
                        </Button.Ripple>
                      </td>
                    ))}
                    <td></td>
                    <td key={uuidv4()} style={{ textAlign: 'center' }}>
                      <Button.Ripple
                        type="button"
                        color="success"
                        onClick={handleMonthlyDataSave}
                      >
                        Save
                      </Button.Ripple>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td style={{ backdropFilter:"blur(500px)", position: "sticky", zIndex: "2", left: "0", fontSize: '13px', whiteSpace: 'nowrap' }}>Total quantity</td>
                    <td style={{ backdropFilter:"blur(500px)", position: "sticky", zIndex: "2", left: "250px", fontSize: '12px', whiteSpace: 'nowrap', textAlign: 'center' }}></td>
                    {dateArray.map((date) => (
                      <td style={headerStyle}>
                        {calculateQuantityTotalForDate(date.label)}
                      </td>
                    ))}
                    <td style={headerStyle}>{calculateGrandTotal()}</td>
                    <td style={headerStyle}>{calculateActualTotal()}</td>
                    <td style={headerStyle}></td>
                  </tr>

                  <tr>
                    <td style={{ backdropFilter:"blur(500px)", position: "sticky", zIndex: "2", left: "0", fontSize: '13px', whiteSpace: 'nowrap' }}>Logs</td>
                    <td style={{ backdropFilter:"blur(500px)", position: "sticky", zIndex: "2", left: "100px", fontSize: '12px', whiteSpace: 'nowrap', textAlign: 'center' }}></td>
                    {dateArray?.map((date, i) => {
                      return (
                        <td key={i} style={{ textAlign: "center" }}>
                          <FileMinus
                            type="button"
                            onClick={() => {
                              const logs = getDataOfLogs(date.label); 
                              setSelectedLogs({logs, date: date.label}); 
                              setOpenLogs(true); 
                            }}
                            size={14}
                          />
                        </td>
                      );
                    })}
                    <td></td>
                    <td style={{ textAlign: "center" }}>
                      <FileMinus
                        type="button"
                        onClick={() => {
                          const actualLogs = laundryMonthlyData.getLaundryMonthlyDataByHostelId; 
                          setActualLogs(actualLogs); 
                          setActualOpenLogs(true); 
                        }}
                        size={14}
                      />
                    </td>
                    <td></td>
                  </tr>
                </tbody>
                
                <tfoot>
                  <tr>
                    <td colSpan={dateArray.length + 6}></td>
                  </tr>
                  {[{ label: 'Actual laundry amount', key: 'actualLaundryAmount' },
                    { label: 'Number of dorms sold (on the previous day)', key: 'dormsSold' },
                    { label: 'Number of privates sold (on the previous day)', key: 'privatesSold' },
                    { label: 'Laundry amount/Dorm night', key: 'laundryAmountPerDorm' },
                    { label: 'Laundry amount/Per Room night', key: 'laundryAmountPerPrivateRoom' },
                    { label: 'Targeted laundry amount', key: 'targetedLaundryAmount' },
                    { label: 'Status', key: 'status' }]
                    .map(({ label, key }) => (
                      <tr key={uuidv4()}>
                        <td style={{ backdropFilter:"blur(500px)", position: "sticky", zIndex: "2", left: "0", fontSize: '13px', whiteSpace: 'nowrap' }}>
                          {label}
                        </td>
                        <td style={{ backdropFilter:"blur(500px)", position: "sticky", zIndex: "1", left: "100px", fontSize: '12px', whiteSpace: 'nowrap', textAlign: 'center' }}></td>
                        {dateArray.map((date, index) => {
                          if (label === 'Actual laundry amount') {
                            let totalActualAmount = 0;

                            masterData?.itemRates?.forEach((rate) => {
                              const quantity = getInitialValue(rate.itemName, date.label);
                              totalActualAmount += quantity * rate.itemRate;  
                            });

                            return (
                              <td key={`date-${index}`} style={{ textAlign: 'center' }}>
                                {totalActualAmount > 0 ? totalActualAmount : '-'}
                              </td>
                            );
                          }else if (key === 'dormsSold') {
                            if (!Array.isArray(dormsData)) {
                                return <td key={`date-${index}`} style={{ textAlign: 'center' }}>-</td>;
                            }
                        
                            dormsData.forEach(d => {
                                const formattedDate = convertToFirstDayOfMonth(d._id); 
                            });
                            const matchedDorm = dormsData.find(d => convertToFirstDayOfMonth(d._id) === date.label);
                            return (
                                <td key={`date-${index}`} style={{ textAlign: 'center' }}>
                                    {matchedDorm ? matchedDorm.roomSold : '-'}
                                </td>
                            );
                          }else if (key === 'privatesSold') {
                            if (!Array.isArray(privateData)) {
                                return <td key={`date-${index}`} style={{ textAlign: 'center' }}>-</td>;
                            }
                        
                            privateData.forEach(p => {
                                const formattedDate = convertToFirstDayOfMonth(p._id); 
                            });
                            const matchedPrivate = privateData.find(p => convertToFirstDayOfMonth(p._id) === date.label);
                            return (
                                <td key={`date-${index}`} style={{ textAlign: 'center' }}>
                                    {matchedPrivate ? matchedPrivate.roomSold : '-'}
                                </td>
                            );
                          }else if (label === 'Targeted laundry amount') {
                            if (!Array.isArray(dormsData) || !Array.isArray(privateData)) {
                              return <td key={`date-${index}`} style={{ textAlign: 'center' }}>-</td>;
                            }
                            const matchedPrivate = privateData.find(p => convertToFirstDayOfMonth(p._id) === date.label)
                            const matchedDorm = dormsData.find(d => convertToFirstDayOfMonth(d._id) === date.label)

                            const laundryAmountPerPrivateRoom = masterData?.laundryAmountPerPrivateRoom || 0;
                            const laundryAmountPerDorm = masterData?.laundryAmountPerDorm || 0;

                            const targetedLaundryAmount = 
                              ((matchedPrivate ? matchedPrivate.roomSold : 0) * laundryAmountPerPrivateRoom) +
                              ((matchedDorm ? matchedDorm.roomSold : 0) * laundryAmountPerDorm);

                            return (
                              <td key={`date-${index}`} style={{ textAlign: 'center' }}>
                                {targetedLaundryAmount > 0 ? targetedLaundryAmount : '-'}
                              </td>
                            );
                          }else if (label === 'Status') {
                            if (!Array.isArray(dormsData) || !Array.isArray(privateData)) {
                                return <td key={`date-${index}`} style={{ textAlign: 'center' }}>-</td>;
                            }
                            const matchedPrivate = privateData.find(p => convertToFirstDayOfMonth(p._id) === date.label);
                            const matchedDorm = dormsData.find(d => convertToFirstDayOfMonth(d._id) === date.label);
                            const laundryAmountPerPrivateRoom = masterData?.laundryAmountPerPrivateRoom || 0;
                            const laundryAmountPerDorm = masterData?.laundryAmountPerDorm || 0;
                            const targetedLaundryAmount = 
                                ((matchedPrivate ? matchedPrivate.roomSold : 0) * laundryAmountPerPrivateRoom) +
                                ((matchedDorm ? matchedDorm.roomSold : 0) * laundryAmountPerDorm);
                            let actualLaundryAmount = 0;
                            masterData?.itemRates?.forEach((rate) => {
                                const quantity = getInitialValue(rate.itemName, date.label);
                                actualLaundryAmount += quantity * rate.itemRate;  
                            });
                            const isOverpaid = actualLaundryAmount > targetedLaundryAmount;
                            const statusText = isOverpaid ? "Overpaid" : "Controlled";
                            const statusColor = isOverpaid ? "red" : "green";

                            if(!actualLaundryAmount || !targetedLaundryAmount){
                              return (
                                <td key={`date-${index}`} style={{ textAlign: 'center', color: statusColor }}>
                                    {"-"}
                                </td>
                              );
                            }
                            return (
                                <td key={`date-${index}`} style={{ textAlign: 'center', color: statusColor }}>
                                    {statusText}
                                </td>
                            );
                          }else{
                              return (
                                <td key={`date-${index}`} style={{ textAlign: 'center' }}>
                                  {masterData?.[key] ?? '-'}
                                </td>
                              );
                            }
                        })}
                        {label === 'Actual laundry amount' && dateArray.length > 0 && (
                          <>
                            <td style={{ textAlign: 'center' }}>
                              {dateArray.reduce((total, date) => {
                                let sumForDate = 0;
                                masterData?.itemRates?.forEach((rate) => {
                                  const quantity = getInitialValue(rate.itemName, date.label);
                                  sumForDate += quantity * rate.itemRate;
                                });
                                return total + sumForDate;
                              }, 0)}
                            </td>

                            <td style={headerStyle}>{calculateTotalAmount()}</td>
                          </>
                        )}
                        {label === 'Number of dorms sold (on the previous day)' && dateArray.length > 0 && (
                          <td style={{ textAlign: 'center' }}>
                            {totalDormsSold > 0 ? totalDormsSold : "-"}
                          </td>
                        )}
                        {label === 'Number of privates sold (on the previous day)' && dateArray.length > 0 && (
                          <td style={{ textAlign: 'center' }}>
                            {totalPrivateSold > 0 ? totalPrivateSold : "-"}
                          </td>
                        )}
                        {label === 'Targeted laundry amount' && dateArray.length > 0 && (
                          <td style={{ textAlign: 'center' }}>
                            {totalTargetedLaundry > 0 ? totalTargetedLaundry : "-"}
                          </td>
                        )}
                        <td></td>
                      </tr>
                    ))}
                </tfoot>

                <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} centered>
                  <ModalHeader toggle={() => setIsModalOpen(false)}>{`${selectedItem} ${selectedDate} Notes`}</ModalHeader>
                  <ModalBody>
                    <Input
                      type="textarea"
                      rows={7}
                      placeholder="Add a note..."
                      value={
                        notes[`${selectedItem}-${selectedDate}`] ?? getInitialNotes(selectedItem, selectedDate)
                      }
                      onChange={handleNotesChange}
                      style={{ margin: "20px 0" }}
                    />
                    <Button color="primary" onClick={handleNotesSave} style={{ margin: "20px 0" }}>
                      Save
                    </Button>
                  </ModalBody>
                </Modal>
              </Table>
            </div>
          }
          </Card>
        </Col>
      )}

      <LogsModal
        showModal={openLogs}
        data={selectedLogs?.logs}
        actualData={actualLogs}
        date={selectedLogs?.date}
        onClose={setOpenLogs}
      />

      <ActualLogsModal
        showModal={actualOpenLogs}
        actualData={actualLogs}
        onClose={setActualOpenLogs}
      />
    </Row>
  );
};

export default LaundryTrackerTable;
