import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import CmsTabs from "@components/Cms/CmsTabs";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import DealBasics from "./DealBasics";
import DealOwnership from "./DealOwnership";
import DealRegistrationLicenses from "./DealRegistrationLicenses";
import WarrantyAMCDocs from "./WarrantyAMCDocs";
import LogsDetails from "./LogsDetails";
// import DealRentSecurityDeposit from "./DealRentSecurityDeposit";
import { useGetSupplyById } from "../../../../api/Supply/Queries";
import Timeline from "./Timeline";



const AddHRTabs = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [active, setActive] = useState(0);
  const { data, refetch } = useGetSupplyById(id);
  const [remainingCount, setRemainingCount] = useState(0)

  const handleRenderChildFormComponent = () => {
    const foundActiveTabWithRenderChildFormComp = tabOptions.find(
      (item) => item?.childFormComponents?.length && item.id === active
    );
    if (foundActiveTabWithRenderChildFormComp) {
      return foundActiveTabWithRenderChildFormComp.childFormComponents;
    }
    return null;
  };

  useEffect(() => {
    if (data?.getSupplyById) {
      dispatch(tableDataHandler(data?.getSupplyById));
    }
    // refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  const [filteredTabs, setFilteredTabs] = useState([]);
  const userDepartment = localStorage.getItem("department")
  useEffect(() => {
    const departmentsWithAllTabs = ["Supply", "Transformation", "Legal", "Operations", "Finance", "Management"];
    if (departmentsWithAllTabs.includes(userDepartment)) {
      setFilteredTabs(tabOptions);
    } else {
      const basicAndTimelineTabs = tabOptions.filter(item => item.title === "Deal basics" || item.title === "Timeline");
      setFilteredTabs(basicAndTimelineTabs);
    }


  }, [userDepartment]);

  const tabOptions = [
    {
      id: 0,
      title: "Timeline",
      formComponent: <Timeline setRemainingCount={setRemainingCount} timelineDates={data?.getSupplyById?.timelineDates} />,
    },
    {
      id: 1,
      title: "Deal basics",
      formComponent: <DealBasics setRemainingCount={setRemainingCount} />,
    },
    {
      id: 2,
      title: "Deal ownership",
      formComponent: <DealOwnership setRemainingCount={setRemainingCount} />,
    },
    {
      id: 3,
      title: "Deal registration & licenses",
      formComponent: <DealRegistrationLicenses setRemainingCount={setRemainingCount} />,
    },
    {
      id: 4,
      title: "Warranty & AMC documents",
      formComponent: <WarrantyAMCDocs setRemainingCount={setRemainingCount} />,
    },
    {
      id: 5,
      title: "Logs",
      formComponent: <LogsDetails setRemainingCount={setRemainingCount} />,
    },
    // {
    //   id: 3,
    //   title: "Deal rent & security deposit",
    //   formComponent: <DealRentSecurityDeposit />,
    // },
  ];

  useEffect(() => {
    setRemainingCount(0)
  }, [active])

  return (
    <div>
      <div className="mt-1 mb-2">
        <CmsTabs
          tabOptions={filteredTabs}
          active={active}
          handleSelectActiveTab={(val) => setActive(val)}
        />
      </div>
      {!handleRenderChildFormComponent()?.length ? (
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>{tabOptions.find((item) => item.id === active).title}</span>
                  <span>{remainingCount}</span>
                </CardTitle>

              </CardHeader>
              <CardBody>
                {tabOptions.find((item) => item.id === active).formComponent}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        handleRenderChildFormComponent().map(({ title, formComponent, id }) => (
          <Row key={id}>
            <Col>
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>{formComponent} hello</CardBody>
              </Card>
            </Col>
          </Row>
        ))
      )}
    </div >
  );
};

export default AddHRTabs;
