import React, { useEffect, useState } from "react";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import EmployeeRosterTable from "./EmployeeRosterTable";
import { useGetHostelsNameandId } from "../../../api/dataSource";
import { useGetEmployeeProfileForRoster } from "../../../api/EmployeeRoster/Queries";

const YearList = [
  { label: "2025", value: "2025" },
  { label: "2024", value: "2024" },
  { label: "2023", value: "2023" },
];
const MonthsList = [
  { label: "January", value: "1" },
  { label: "February ", value: "2" },
  { label: "March", value: "3" },
  { label: "April ", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

const EmployeeRoster = () => {
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  // const [hostel, setHostel] = useState({
  //   label: "The Hosteller Property",
  //   value: "633bd0cd95c45eddb6a42f77",
  // });
  const [year, setYear] = useState({ label: "2025", value: "2025" });
  const [month, setMonth] = useState({
    label: MonthsList.find((i) => Number(i.value) === new Date().getMonth() + 1)
      .label,
    value: new Date().getMonth() + 1,
  });

  const [selectedHostel, setSelectedHostel] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [tableHeaderData, setTableHeaderData] = useState([]);

  const { data: employeeProfileData, refetch: refetchEmployeeProfile } =
    useGetEmployeeProfileForRoster(selectedHostel, selectedMonth, selectedYear);

  const { data: allHostelsData } = useGetHostelsNameandId();

  const [dateArray, setDateArray] = useState([]);

  const getDatesOfMonth = (year, month) => {
    // Create the start date of the month
    const startDate = new Date(year, month - 1, 1);

    // Create the end date of the month
    const endDate = new Date(year, month, 0);

    // Initialize a variable to track the current date
    const currentDateOfMonth = new Date(startDate);

    // Initialize an array to store date objects
    var datesArray = [];

    // Iterate through each day of the month
    while (currentDateOfMonth <= endDate) {
      // Push a date object to the datesArray with label and key properties
      datesArray.push({
        label: currentDateOfMonth.toLocaleDateString("en-CA"),
        key: currentDateOfMonth.toLocaleDateString("en-CA"),
      });

      // Move to the next day
      currentDateOfMonth.setDate(currentDateOfMonth.getDate() + 1);
    }

    // Set the tableHeaderData state with an array containing date labels and keys
    setTableHeaderData([
      { label: "Emp Code", key: "empCode" },
      { label: "Emp Name", key: "empName" },
      { label: "DOJ", key: "doj" },
      { label: "Sub Dpmt", key: "subDepartment" },
      { label: "Designation", key: "designation" },
      { label: "Live Status", key: "empStatus" },
      // ...datesArray, // Include datesArray in the table header
    ]);

    // Set the dateArray state with the generated datesArray
    setDateArray(datesArray);
  };

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelsNameandId?.map((hostel) => ({
      label: hostel?.name,
      value: hostel?._id,
    }));

    if (HostelsList?.length) {
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));

      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return HostelsList?.find((hostel) => hostel?.value === hostelID);
            })
          : HostelsList;

      setHostelsList(filteredHostels);
    }
  }, [allHostelsData]);

  const submitHandler = (year, month) => {
    getDatesOfMonth(year, month);
    setSelectedHostel(hostel?.value);
    setSelectedMonth(month);
    setSelectedYear(year);
    // setShouldRefetch(true);
  };

  const dropdownStyles = {
    control: (base) => ({
      ...base,
      minHeight: "40px", // Adjust the height as needed
      fontSize: "14px", // Adjust the font size as needed
    }),
    menu: (base) => ({
      ...base,
      zIndex: 999, // Ensure the dropdown is above other elements
    }),
  };

  return (
    localStorage.getItem("modules")?.includes("Employee Attendance") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={dropdownStyles}
              />
            </FormGroup>
          </Col>

          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="year">
                Year
              </Label>
              <Select
                isSearchable={true}
                id="year"
                name="year"
                options={YearList}
                required
                value={year}
                onChange={(value) => setYear(value)}
                classNamePrefix="select"
                styles={dropdownStyles}
              />
            </FormGroup>
          </Col>

          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="month">
                Months
              </Label>
              <Select
                isSearchable={true}
                id="month"
                name="month"
                options={MonthsList}
                required
                value={month}
                onChange={(value) => setMonth(value)}
                classNamePrefix="select"
                styles={dropdownStyles}
              />
            </FormGroup>
          </Col>

          <Col sm="2 p-2">
            <Button.Ripple
              type="submit"
              color={`${hostel ? `light` : `secondary`}`}
              appearance="success"
              onClick={() => {
                submitHandler(Number(year?.value), Number(month?.value));
              }}
            >
              Search
            </Button.Ripple>
          </Col>

          {year && month && hostel && (
            <EmployeeRosterTable
              tableHeaderData={tableHeaderData}
              employeeProfileData={employeeProfileData}
              selectedHostel={selectedHostel}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              dateArray={dateArray}
              allHostelsData={allHostelsData}
              refetchEmployeeProfile={refetchEmployeeProfile}
            />
          )}
        </Row>
      </div>
    )
  );
};

export default EmployeeRoster;
