import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";


export const GET_BY_REFERENCE_ID = gql`
   query getAllInventoryBasedOnReferenceId($id:ID){
    getAllInventoryBasedOnReferenceId(id:$id){
        _id
    referenceId
     hostelId
    category
    itemName
    opening
    change
    closing
    changeDate
    remark
    addedBy
    createdAt
    }
   }
`


export const useGetInventoryByReferenceId = (id) => {
    const { loading, data, error, refetch } = useQuery(GET_BY_REFERENCE_ID, {
        variables: {
            id
        }
        , skip: !id
    })

    useLoadingHandler(loading)

    return { data, error, refetch }
}