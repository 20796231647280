import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_LAUNDRY_TRACKER_DATA = gql`
    mutation createLaundryTrackerData($LaundryTrackerDataInput:[LaundryTrackerDataInput]) {
        createLaundryTrackerData(LaundryTrackerDataInput:$LaundryTrackerDataInput) {
          acknowledge
        }
    }
`;

export const useCreateLaundryTrackerData = () => {
    const [createLaundryTrackerData, { loading, error, data, refetch }] =
        useMutation(CREATE_LAUNDRY_TRACKER_DATA);

    useLoadingHandler(loading);

    const createLaundryTrackerDataHandler = async (LaundryTrackerDataInput) => {
        const response =  await createLaundryTrackerData({
            variables: { LaundryTrackerDataInput },
        });
        return response;
    };
    return [createLaundryTrackerDataHandler, { loading, error, data, refetch }];
};

export const UPDATE_LAUNDRY_TRACKER_MASTER = gql`
  mutation updateLaundryTrackerData($LaundryTrackerDataUpdate: [LaundryTrackerDataInput]) {
    updateLaundryTrackerData(LaundryTrackerDataUpdate: $LaundryTrackerDataUpdate) {
      acknowledge
  }}
`;

export const useUpdateLaundryTracker = () => {
  const [updateLaundryTrackerData, { loading, error, data, refetch }] = useMutation(
    UPDATE_LAUNDRY_TRACKER_MASTER
  );
  useLoadingHandler(loading);
  const updateLaundryTrackerHandler = async (LaundryTrackerDataUpdate) => {
    return await updateLaundryTrackerData({
      variables: { LaundryTrackerDataUpdate },
    });
  };

  return [updateLaundryTrackerHandler, { loading, error, data, refetch }];
};