import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";


export const CREATE_INVENTORY = gql`
   mutation createInventory($InventoryInput:InventoryInput){
     createInventory(InventoryInput:$InventoryInput){
        closing
     }

   }

`

export const useCreateInventory = () => {

    const [createInventory, { data, loading, error, refetch }] = useMutation(CREATE_INVENTORY)

    useLoadingHandler(loading)

    const createHandler = (InventoryInput) => {
        createInventory({
            variables: {
                InventoryInput
            }
        })
    }

    return [createHandler, { data, error, refetch }]
}