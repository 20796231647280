import React, { useEffect, useState } from "react";
import { Col, Row, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import { useHostelList } from "../../../api/dataSource";
import { useCreateLaundryData, useUpdateLaundryMaster } from "../../../api/LaundryMaster/Mutation";
import toaster from "@components/common/Toaster";
import SlideModal from "./slide-modal/index";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { XCircle } from "react-feather";

const AddEditModalLaundry = ({ open, handleToggle, editData, refetch }) => {
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [laundryVendorName, setLaundryVendorName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [billPaymentDueDay, setBillPaymentDueDay] = useState("");
  const [laundryCycle, setLaundryCycle] = useState("");
  const [laundryAmountPerDorm, setLaundryAmountPerDorm] = useState("");
  const [laundryAmountPerPrivateRoom, setLaundryAmountPerPrivateRoom] = useState("");
  const [itemsList, setItemsList] = useState([
    { itemName: "Single bedsheet", itemRate: "" },
    { itemName: "Double bedsheet", itemRate: "" },
    { itemName: "Pillow cover", itemRate: "" },
    { itemName: "Cushion cover", itemRate: "" },
    { itemName: "Single duvet cover", itemRate: "" },
    { itemName: "Double duvet cover", itemRate: "" },
    { itemName: "Bath towel", itemRate: "" },
    { itemName: "Hand towel", itemRate: "" },
    { itemName: "Face towel", itemRate: "" },
    { itemName: "Pool towel", itemRate: "" },
    { itemName: "Doormat", itemRate: "" },
    { itemName: "Bathmat", itemRate: "" },
    { itemName: "Bed runner", itemRate: "" },
    { itemName: "Room curtain", itemRate: "" },
    { itemName: "Dorm curtain", itemRate: "" },
    { itemName: "Dryclean", itemRate: "" },
  ]);

  const { data: allHostelsData } = useHostelList();
  const [updateLaundryMasterHandler, { data: updateLaundryMasterData }] = useUpdateLaundryMaster();

  const LaundryCycleList = [
    { label: "24Hrs", value: "24Hrs" },
    { label: "48Hrs", value: "48Hrs" },
    { label: "72Hrs", value: "72Hrs" },
  ];

  useEffect(() => {
    let hostels = allHostelsData?.getHostelList?.map((hostel) => ({
      label: hostel?.name,
      value: hostel?._id,
    }));
    setHostelsList(hostels);
  }, [allHostelsData]);

  const [createLaundryDataHandler, { data: createLaundryMasterData }] =
    useCreateLaundryData();

  useEffect(() => {
    if (editData?._id) {
      const { hostelId } = editData;
      setHostel(
        hostelId?._id
          ? { label: hostelId?.name, value: hostelId?._id }
          : null
      );
    } else {
      setHostel(null);
    }
  }, [editData]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    if (editData?._id) {
      const {
        hostelId,
        laundryVendorName,
        mobileNumber,
        address,
        billPaymentDueDay,
        laundryCycle,
        laundryAmountPerDorm,
        laundryAmountPerPrivateRoom,
        // itemRates,
      } = editData;
  
      setHostel(
        hostelId
          ? { label: hostelId.name, value: hostelId._id }
          : null
      );
      setLaundryVendorName(laundryVendorName || "");
      setMobileNumber(mobileNumber || "");
      setAddress(address || "");
      setBillPaymentDueDay(billPaymentDueDay || "");
      setLaundryCycle(
        laundryCycle
          ? { label: laundryCycle, value: laundryCycle }
          : null
      );
      setLaundryAmountPerDorm(laundryAmountPerDorm);
      setLaundryAmountPerPrivateRoom(laundryAmountPerPrivateRoom)
      if (Array.isArray(editData.itemRates)) {
        setItemsList(editData.itemRates);
      }
    } else {
      setHostel(null);
      setLaundryVendorName("");
      setMobileNumber("");
      setAddress("");
      setBillPaymentDueDay("");
      setLaundryCycle(null);
      setLaundryAmountPerDorm("");
      setLaundryAmountPerPrivateRoom("")
      setItemsList(
        itemsList.map((item) => ({ ...item, itemRate: "" }))
      );
    }
  }, [editData]);


  useEffect(() => {
    if (createLaundryMasterData || updateLaundryMasterData) {
      refetch();
      handleToggle();
    }
  }, [createLaundryMasterData, updateLaundryMasterData]);

  const addNewItem = (e) => {
    e.preventDefault();

    const newItem = { itemName: "", itemRate: "" };
    setItemsList((prev) => [...prev, newItem]);
  };

  const handleItemNameChange = (e, index) => {
    setItemsList((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, itemName: e.target.value } : item
      )
    );
  };

  const handleItemRateChange = (e, index) => {
    const value = e.target.value;
    if (value >= 0) {
      setItemsList((prevItems) =>
        prevItems.map((item, i) =>
          i === index ? { ...item, itemRate: value === "" ? 0 : Number(value) } : item
        )
      );
    }
  };

  const removeItemRates = (index) => {
    const updatedItemsList = itemsList.filter((_, i) => i !== index);
    setItemsList(updatedItemsList); 
  };

  const submitDataHandler = async () => {
    if (!hostel || !laundryVendorName || !mobileNumber || !address || !laundryCycle ||  laundryAmountPerDorm === "" || laundryAmountPerDorm == null ||  laundryAmountPerPrivateRoom === "" || laundryAmountPerPrivateRoom == null || itemsList.some((item) => !item.itemName || !item.itemRate)) {
      toaster("error", "Please fill all the required fields.");
      return;
    }

    const formattedItems = itemsList.map((item) => ({
      itemName: item.itemName,
      itemRate: parseFloat(item.itemRate || 0),
    }));
  
    const data = {
      hostelId: hostel.value,
      laundryVendorName,
      mobileNumber,
      address,
      billPaymentDueDay: parseInt(billPaymentDueDay || 0),
      laundryCycle: laundryCycle.value,
      itemRates: formattedItems,
      laundryAmountPerDorm: parseFloat(laundryAmountPerDorm),
      laundryAmountPerPrivateRoom: parseFloat(laundryAmountPerPrivateRoom)
    };
  
    try {
      if (editData?._id) {
        await updateLaundryMasterHandler(editData._id, data);
        toaster("success", "Laundry data updated successfully.");
      } else {
        const response = await createLaundryDataHandler(data);
        if(response.data.createLaundryData){
          toaster("success", "Laundry data created successfully.");
        }
      }
  
      refetch();
      setHostel(null);
      setLaundryVendorName("");
      setMobileNumber("");
      setAddress("");
      setBillPaymentDueDay("");
      setLaundryCycle(null);
      setLaundryAmountPerDorm("");
      setLaundryAmountPerPrivateRoom("")
      setItemsList(itemsList.map((item) => ({ ...item, itemRate: "" })));
    } catch (error) {
      throw error
    }
  };
  
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/* Hostel Selection */}
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostel">
                  Hostel*
                </Label>
              </strong>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostel"
                name="hostel"
                options={HostelsList}
                isDisabled={editData?.hostelId?.name}
                required
                value={hostel}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* Laundry Vendor Name */}
          <Col sm="12">
            <FormGroup className="w-full">
              <strong>
                <Label className="form-label" for="laundryVendorName">
                  Laundry Vendor Name*
                </Label>
              </strong>
              <Input
                type="text"
                value={laundryVendorName}
                id="laundryVendorName"
                name="laundryVendorName"
                placeholder="Enter vendor name"
                onChange={(e) => setLaundryVendorName(e.target.value)}
                required
              />
            </FormGroup>
          </Col>

          {/* Mobile Number */}
          <Col sm="12">
            <FormGroup className="w-full">
              <strong>
                <Label className="form-label" for="mobileNumber">
                  Mobile Number*
                </Label>
              </strong>
              <Input
                type="text"
                maxLength={10} 
                value={mobileNumber === 0 ? "" : mobileNumber}
                id="mobileNumber"
                name="mobileNumber"
                placeholder="Enter mobile number"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setMobileNumber(value === "" ? 0 : value)
                  }
                }}
                required
              />
            </FormGroup>
          </Col>

          {/* Address */}
          <Col sm="12">
            <FormGroup className="w-full">
              <strong>
                <Label className="form-label" for="address">
                  Address*
                </Label>
              </strong>
              <Input
                type="text"
                value={address}
                id="address"
                name="address"
                placeholder="Enter address"
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </FormGroup>
          </Col>

          {/* Bill Payment Due Day */}
          <Col sm="12">
            <FormGroup className="w-full">
              <strong>
                <Label className="form-label" for="billPaymentDueDay">
                  Bill Payment Due Date*
                </Label>
              </strong>
              <Input
                type="text"
                maxLength={2}
                value={billPaymentDueDay === 0 ? "" : billPaymentDueDay}
                id="billPaymentDueDay"
                name="billPaymentDueDay"
                placeholder="Enter bill payment due date"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setBillPaymentDueDay(value === "" ? 0 : Number(value))
                  }
                }}
                required
              />
            </FormGroup>
          </Col>

          {/* Laundry Cycle */}
          <Col sm="12">
            <FormGroup className="w-full">
              <strong>
                <Label className="form-label" for="laundryCycle">
                  Laundry Cycle*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="laundryCycle"
                name="laundryCycle"
                value={laundryCycle}
                options={LaundryCycleList}
                onChange={(value) => setLaundryCycle(value)}
                classNamePrefix="select"
                required
              />
            </FormGroup>
          </Col>

          {/* Item Rates */}
          <Col sm="12">
            <FormGroup className="w-full">
              {/* <strong>
                <Label className="form-label" for="itemRates">
                  Item Rates*
                </Label>
              </strong> */}

              <div style={{ display: "flex", flexDirection: "row", width: "full" }}>
                <strong style={{ width: "70%" }}>
                  <Label className="form-label" for="itemName">
                    Item Name*
                  </Label>
                </strong>

                <strong style={{ width: "30%" }}>
                  <Label className="form-label" for="itemRate">
                    Item Rate*
                  </Label>
                </strong>
              </div>

              <div style={{ width: "100%" }}>
                {itemsList.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "10px",
                      gap: "10px"
                    }}
                  >
                    <div style={{ width: "70%" }}>
                      <Input
                        type="text"
                        value={item.itemName || ""}
                        id={`itemName-${index}`}
                        name={`itemName-${index}`}
                        placeholder="Enter item name"
                        onChange={(e) => handleItemNameChange(e, index)}
                        required
                      />
                    </div>

                    <div style={{ width: "30%" }}>
                      <Input
                        type="text"
                        maxLength={5}
                        value={item.itemRate || ""}
                        id={`itemRate-${index}`}
                        name={`itemRate-${index}`}
                        onChange={(e) =>
                            handleItemRateChange(e, index)
                          }
                        required
                      />
                    </div>

                    <a href="#" onClick={() => removeItemRates(index)} title="Remove">
                      <XCircle />
                    </a>
                  </div>
                ))}
              </div>

              <a href="#" onClick={addNewItem}>
                Add New Item
              </a>
            </FormGroup>
          </Col>

          {/* Laundry Amount Per Bed Night */}
          <Col sm="12">
            <FormGroup className="w-full">
              <strong>
                <Label className="form-label" for="laundryAmountPerDorm">
                  Laundry Amount Per Dorm Night*
                </Label>
              </strong>
              <Input
                type="text"
                maxLength={5}
                value={laundryAmountPerDorm}
                id="laundryAmountPerDorm"
                name="laundryAmountPerDorm"
                placeholder="Enter laundry amount per dorm night"
                onChange={(e) => setLaundryAmountPerDorm(e.target.value)}
                required
              />
            </FormGroup>
          </Col>

          {/* Laundry Amount Per Private Room Night */}
          <Col sm="12">
            <FormGroup className="w-full">
              <strong>
                <Label className="form-label" for="laundryAmountPerPrivateRoom">
                  Laundry Amount Per Private Room Night*
                </Label>
              </strong>
              <Input
                type="text"
                maxLength={5}
                value={laundryAmountPerPrivateRoom}
                id="laundryAmountPerPrivateRoom"
                name="laundryAmountPerPrivateRoom"
                placeholder="Enter laundry amount per private room night"
                onChange={(e) => setLaundryAmountPerPrivateRoom(e.target.value)}
                required
              />
            </FormGroup>
          </Col>

          {editData?.logs?.length > 0 && (
            <Col>
              <div className="font-weight-bold text-left py-1">Logs:</div>
              {[...editData.logs]?.reverse()?.map((el, i) => {
                return (
                  <Label key={i}>
                    <li>{el}</li>
                  </Label>
                );
              })}
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModalLaundry;
