import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Button, InputGroup } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Plus, Trash } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useParams } from "react-router-dom";
import { useUpdateHostel } from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const HostelPolicy = () => {
  const petType = "Pet Policy";
  const generalType = "General Policy";
  const GeneralInfoType = "General Information";

  const state = useSelector((state) => state?.cms);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [updateHostelHandler, { data }] = useUpdateHostel();

  const [petPolicy, setPetPolicy] = useState([{ id: uuidv4(), text: "" }]);
  const [generalPolicy, setGeneralPolicy] = useState([
    { id: uuidv4(), text: "" },
  ]);
  const [covidPolicy, setCovidPolicy] = useState([{ id: uuidv4(), text: "" }]);

  const handleChange = (val, id, type) => {
    if (type === petType) {
      setPetPolicy(
        petPolicy?.map((item) =>
          item.id === id ? { ...item, text: val } : item
        )
      );
    } else if (type === generalType) {
      setGeneralPolicy(
        generalPolicy?.map((item) =>
          item.id === id ? { ...item, text: val } : item
        )
      );
    } else if (type === GeneralInfoType) {
      setCovidPolicy(
        covidPolicy?.map((item) =>
          item.id === id ? { ...item, text: val } : item
        )
      );
    }
  };

  // useEffect(() => {
  //   if (data?.updateHostel?.name) {
  //     toaster("success", "Landing policy updated successfully");
  //   }
  // }, [data?.updateHostel?.name]);

  const addListHandler = (type) => {
    if (type === generalType) {
      setGeneralPolicy((prev) => [...prev, { id: uuidv4(), text: "" }]);
    } else if (type === petType) {
      setPetPolicy((prev) => [...prev, { id: uuidv4(), text: "" }]);
    } else if (type === GeneralInfoType) {
      setCovidPolicy((prev) => [...prev, { id: uuidv4(), text: "" }]);
    }
  };

  const removeListHandler = (id, type) => {
    if (type === generalType) {
      confirmDelete(() =>
        setGeneralPolicy(generalPolicy.filter((item) => item.id !== id))
      );
    } else if (type === petType) {
      confirmDelete(() =>
        setPetPolicy(petPolicy.filter((item) => item.id !== id))
      );
    } else if (type === GeneralInfoType) {
      confirmDelete(() =>
        setCovidPolicy(covidPolicy.filter((item) => item.id !== id))
      );
    }
  };

  useEffect(() => {
    const policy = state?.tableData?.hostelPolicy;

    if (policy) {
      if (policy?.pet) {
        setPetPolicy(
          policy?.pet?.map((text) => ({
            id: uuidv4(),
            text: text,
          }))
        );
      }

      if (policy?.general) {
        setGeneralPolicy(
          policy?.general?.map((text) => ({ id: uuidv4(), text: text }))
        );
      }

      if (policy?.covid) {
        setCovidPolicy(
          policy?.covid?.map((text) => ({ id: uuidv4(), text: text }))
        );
      }
    }
  }, [state?.tableData?.hostelPolicy]);

  const submitDataHandler = (e) => {
    e.preventDefault();

    let isValid = true;

    petPolicy?.forEach((data) => {
      isValid && data?.text ? (isValid = true) : (isValid = false);
    });

    generalPolicy?.forEach((data) => {
      isValid && data?.text ? (isValid = true) : (isValid = false);
    });

    covidPolicy?.forEach((data) => {
      isValid && data?.text ? (isValid = true) : (isValid = false);
    });

    if (isValid) {
      const hostelPolicy = {
        pet: petPolicy?.map((item) => item.text),
        general: generalPolicy?.map((item) => item.text),
        covid: covidPolicy?.map((item) => item.text),
      };

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          hostelPolicy,
        })
      );

      updateHostelHandler(id, { hostelPolicy, sectionName: "Landing Policy" });

      toaster("success", "Landing policy updated successfully");
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form onSubmit={submitDataHandler}>
      <Row className="my-5 d-flex justify-content-between">
        <Col className="mb-5" md="12">
          <h2>
            {generalType}{" "}
            {
              <Button
                className="btn-icon ml-2"
                color="light"
                size="sm"
                onClick={() => addListHandler(generalType)}
              >
                <Plus size={14} /> Add
              </Button>
            }
          </h2>

          {generalPolicy?.map(({ id, text }, index) => (
            <InputGroup className="mt-1" key={id}>
              <Input
                onChange={(e) => handleChange(e.target.value, id, generalType)}
                value={text}
                type="textarea"
                placeholder="add policy"
              />

              {(index > 0 || generalPolicy.length > 1) && (
                <Button
                  className="btn-icon rounded-0"
                  color="danger"
                  type="button"
                  onClick={() => removeListHandler(id, generalType)}
                >
                  <Trash size={14} />
                </Button>
              )}
            </InputGroup>
          ))}
        </Col>
        <Col className="mb-5" md="12">
          <h2>
            {petType}{" "}
            {
              <Button
                className="btn-icon ml-2"
                color="light"
                size="sm"
                onClick={() => addListHandler(petType)}
              >
                <Plus size={14} /> Add
              </Button>
            }
          </h2>

          {petPolicy?.map(({ id, text }, index) => (
            <InputGroup className="mt-1" key={id}>
              <Input
                onChange={(e) => handleChange(e.target.value, id, petType)}
                value={text}
                type="textarea"
                placeholder="add policy"
              />

              {(index > 0 || petPolicy.length > 1) && (
                <Button
                  className="btn-icon rounded-0"
                  color="danger"
                  type="button"
                  onClick={() => removeListHandler(id, petType)}
                >
                  <Trash size={14} />
                </Button>
              )}
            </InputGroup>
          ))}
        </Col>
        <Col className="mb-5" md="12">
          <h2>
            {GeneralInfoType}{" "}
            {
              <Button
                className="btn-icon ml-2"
                color="light"
                size="sm"
                onClick={() => addListHandler(GeneralInfoType)}
              >
                <Plus size={14} /> Add
              </Button>
            }
          </h2>

          {covidPolicy?.map(({ id, text }, index) => (
            <InputGroup className="mt-1" key={id}>
              <Input
                onChange={(e) =>
                  handleChange(e.target.value, id, GeneralInfoType)
                }
                value={text}
                type="textarea"
                placeholder="add policy"
              />

              {(index > 0 || covidPolicy.length > 1) && (
                <Button
                  className="btn-icon rounded-0"
                  color="danger"
                  type="button"
                  onClick={() => removeListHandler(id, GeneralInfoType)}
                >
                  <Trash size={14} />
                </Button>
              )}
            </InputGroup>
          ))}
        </Col>
      </Row>

      <SaveDiscardBtnGroup />
    </form>
  );
};

export default HostelPolicy;
