import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateHostel } from "../../../../api/dataSource";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

const GSTForm = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [stateCode, setStateCode] = useState("");
  const [gstNumber, setGstNumber] = useState("");

  const [updateHostelHandler] = useUpdateHostel();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.tableData?._id) {
      setStateCode(state?.tableData?.stateCode);
      setGstNumber(state?.tableData?.gstNumber);
    } else {
      setStateCode("");
      setGstNumber("");
    }
  }, [state]);

  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      stateCode,
      gstNumber,
      sectionName: "GST",
    };

    if (id) {
      updateHostelHandler(id, data);

      toaster("success", "GST details updated successfully");
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          stateCode,
          gstNumber,
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="stateCode">
              State code
            </Label>

            <Input
              type="text"
              value={stateCode}
              id="stateCode"
              name="stateCode"
              placeholder="State Code"
              onChange={(e) => setStateCode(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="gstNumber">
              GST Number
            </Label>

            <Input
              type="text"
              value={gstNumber}
              id="gstNumber"
              name="gstNumber"
              placeholder="GST Number"
              onChange={(e) => setGstNumber(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default GSTForm;
