import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import Select from "react-select";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import AttachmentComponent from "../AttachmentComponent";
import { tableDataHandler } from "@store/Cms/cms.action";
import { dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import { v4 as uuidv4 } from "uuid";
import AddButton from "../addButton";
import { useUpdateSupply } from "../../../../api/Supply/Mutation";

import { indianStateList } from "../../../../utility/Utils";
import {
  HostelPrefixList,
  HostelRegionList,
  DealStageList,
  FurnishingTypeList,
  CafeList,
  CafeScaleList,
  ElectricalConnectionTypeList,
  WaterConnectionTypeList,
  PropertyTypeList,
  DealSourceTypeList,
  OperationalModelTypeList,
  LicensesOfficialFeesResponsibilityTypeList,
  MonthlyRentTypeList,
  RentalIncrementFrequencyTypeList,
  RentalPaymentFrequencyTypeList,
  SublettingRightsTypeList,
  YesNoDropdownOptions,
  HostelStageOptions,
} from "../selectOptionsList";
import ImageModal from "../ImageModal";
import { ArrowUpRight, PlusCircle } from "react-feather";

const DealBasics = ({ setRemainingCount }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [hostelStage, setHostelStage] = useState(null);
  const [hostelPrefix, setHostelPrefix] = useState(null);
  const [localityNamePresent, setLocalityNamePresent] = useState(null)
  const [localityName, setLocalityName] = useState("");
  const [hostelName, setHostelName] = useState(null);
  const [hostelCity, setHostelCity] = useState(null);
  const [hostelState, setHostelState] = useState(null);
  const [hostelRegion, setHostelRegion] = useState(null);
  const [hostelCompleteAddress, setHostelCompleteAddress] = useState(null);
  const [googleMapsPin, setGoogleMapsPin] = useState(null);
  const [numOfDormRooms, setNumOfDormRooms] = useState(null);
  const [numOfPrivateRooms, setNumOfPrivateRooms] = useState(null);
  const [dormBeds, setDormBeds] = useState(null);
  const [numOfTotalRooms, setNumOfTotalRooms] = useState(null);
  const [numOfTotalBeds, setNumOfTotalBeds] = useState(null);
  const [numOfStaffRooms, setNumOfStaffRooms] = useState(null);
  const [numOfInventoryRooms, setNumOfInventoryRooms] = useState(null);
  const [dealStage, setDealStage] = useState(null);
  const [furnishingType, setFurnishingType] = useState(null);
  const [hostelTotalArea, setHostelTotalArea] = useState(null);
  const [constructionAge, setConstructionAge] = useState(null);
  const [cafe, setCafe] = useState(null);
  const [cafeScale, setCafeScale] = useState(null);
  // const [electricalConnectionType, setElectricalConnectionType] = useState("");
  // const [sanctionedElectricalLoad, setSanctionedElectricalLoad] = useState("");
  // const [waterConnectionType, setWaterConnectionType] = useState("");
  // const [sanctionedWaterSupply, setSanctionedWaterSupply] = useState("");
  const [propertyType, setPropertyType] = useState(null);
  const [floorPlans, setFloorPlans] = useState([]);
  const [cadDrawings, setCADDrawings] = useState([]);
  const [picturesVideosDriveLink, setPicturesVideosDriveLink] = useState(null);
  // const [pictures, setPictures] = useState([]);
  // const [videos, setVideos] = useState([]);
  const [titleDocuments, setTitleDocuments] = useState([]);
  const [ocCCCertificate, setOCCCCertificate] = useState([]);

  const [titleSearchReport, setTitleSearchReport] = useState([]);
  const [propertyPreSigningAuditReport, setPropertyPreSigningAuditReport] =
    useState([]);
  const [agreementExecutionDate, setAgreementExecutionDate] = useState(null);
  const [lessorHandoverDate, setLessorHandoverDate] = useState(null);
  const [transformationHandoverDate, setTransformationHandoverDate] =
    useState(null);
  const [staffJoiningDate, setStaffJoiningDate] = useState(null);
  const [photoshootDate, setPhotoshootDate] = useState(null);
  const [goLiveDate, setGoLiveDate] = useState(null);
  const [rentStartDate, setRentStartDate] = useState(null);
  const [rentEndDate, setRentEndDate] = useState(null);

  const [fitOutPeriod, setFitOutPeriod] = useState(null);
  const [lessorLockInPeriod, setLessorLockInPeriod] = useState(null);
  const [lesseeLockInPeriod, setLesseeLockInPeriod] = useState(null);
  const [zeroPenaltyGracePeriod, setZeroPenaltyGracePeriod] = useState(null);
  const [penaltyPerDay, setPenaltyPerDay] = useState(null);
  const [terminationNotice, setTerminationNotice] = useState(null);
  const [exitingDuration, setExitingDuration] = useState(null);
  const [ageOfHostel, setAgeOfHostel] = useState(null);
  const [advanceRent, setAdvanceRent] = useState(null);
  const [advanceRentRepaymentTimeline, setAdvanceRentRepaymentTimeline] =
    useState(null);
  const [
    transformationPreLiquidatedAmount,
    setTransformationPreLiquidatedAmount,
  ] = useState(null);
  const [sublettingRights, setSublettingRights] = useState(null);

  const [majorRMResponsibility, setMajorRMResponsibility] = useState(null);
  const [minorRMResponsibility, setMinorRMResponsibility] = useState(null);
  const [majorRMCurePeriod, setMajorRMCurePeriod] = useState(null);
  const [
    majorRMCurePeriodPenaltyMultiplier,
    setMajorRMCurePeriodPenaltyMultiplier,
  ] = useState(null);
  const [forceMajeure1to3, setForceMajeure1to3] = useState(null);
  const [forceMajeure4to6, setForceMajeure4to6] = useState(null);
  const [forceMajeure6plus, setForceMajeure6plus] = useState(null);
  const [lesseeEODPeriod, setLesseeEODPeriod] = useState(null);
  const [lessorEODPeriod, setLessorEODPeriod] = useState(null);
  const [lesseeEODCure, setLesseeEODCure] = useState(null);
  const [lessorEODCure, setLessorEODCure] = useState(null);
  const [monthlyRentType, setMonthlyRentType] = useState(null);
  const [monthlyFixedRent, setMonthlyFixedRent] = useState(null);
  const [monthlyRevenueShare, setMonthlyRevenueShare] = useState(null);
  const [monthlyFNBRevenueShare, setMontlyFNBRevenueShare] = useState(null)
  const [monthlyAncillaryRevenueShare, setMonthlyAncillaryRevenueShare] = useState(null);
  const [rentPayableDate, setRentPayableDate] = useState(null);
  const [rentalIncrementFreq, setRentalIncrementFreq] = useState(null);
  const [rentalPaymentFreq, setRentalPaymentFreq] = useState(null);
  const [rentalIncrementPercent, setRentalIncrementPercent] = useState(null);
  const [rentPerRoom, setRentPerRoom] = useState(null);
  const [rentPerBed, setRentPerBed] = useState(null);

  const [dealSource, setDealSource] = useState(null);
  const [brokerName, setBrokerName] = useState(null);
  const [brokerageAmount, setBrokerageAmount] = useState(null);
  const [operationalModel, setOperationalModel] = useState(null);
  const [omcBrandFees, setOMCBrandFees] = useState(null);
  const [omcPhotographyfees, setOMCPhotographyfees] = useState(null);
  const [omcDesignFees, setOMCDesignFees] = useState(null);
  const [omcPlatformFees, setOMCPlatformFees] = useState(null);
  const [lessorEntityType, setLessorEntityType] = useState(null);
  const [lessorEntityFullName, setLessorEntityFullName] = useState(null);
  // const [lessorAge, setLessorAge] = useState("");
  const [lessorFatherName, setLessorFatherName] = useState(null);
  const [lessorCompleteAddress, setLessorCompleteAddress] = useState(null);
  const [lessorAadharNumber, setLessorAadharNumber] = useState(null);
  const [lessorAadharCard, setLessorAadharCard] = useState([]);
  const [lessorPANNumber, setLessorPANNumber] = useState(null);
  const [lessorPANCard, setLessorPANCard] = useState([]);
  const [lessorPartnershipPercent, setLessorPartnershipPercent] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [bankAccountType, setBankAccountType] = useState(null);
  const [bankBeneficiaryName, setBankBeneficiaryName] = useState(null);
  const [bankAccountNumber, setBankAccountNumber] = useState(null);
  const [bankIfscCode, setBankIfscCode] = useState(null);
  const [cancelledCheque, setCancelledCheque] = useState([]);
  const [llpRegistrationNumber, setLlpRegistrationNumber] = useState(null);
  const [llpRegistrationCertificate, setLlpRegistrationCertificate] = useState(
    []
  );
  const [llpRegisteredAddress, setLlpRegisteredAddress] = useState(null);
  const [llpRepresentativeName, setLlpRepresentativeName] = useState(null);
  const [llpRepresentativeAddress, setLlpRepresentativeAddress] = useState(null);
  const [llpRepresentativeAadharNumber, setLlpRepresentativeAadharNumber] =
    useState(null);
  const [llpRepresentativeAadharCard, setLlpRepresentativeAadharCard] =
    useState([]);

  const [llpRepresentativePANNumber, setLlpRepresentativePANNumber] =
    useState(null);
  const [llpRepresentativePANCard, setLlpRepresentativePANCard] = useState([]);
  const [llpRepresentativeDIN, setLlpRepresentativeDIN] = useState(null);

  const [pocFullName, setPOCFullName] = useState(null);
  const [pocMobileNumber, setPOCMobileNumber] = useState(null);
  const [pocEmail, setPOCEmail] = useState(null);
  const [pocAadharNumber, setPOCAadharNumber] = useState(null);
  const [pocAadharCard, setPOCAadharCard] = useState([]);
  const [leaseDeedType, setLeaseDeedType] = useState(null);
  const [registeredLicensesEntity, setRegisteredLicensesEntity] = useState(null);
  const [licensesResponsibility, setLicensesResponsibility] = useState(null);
  const [licensesFeesResponsibility, setLicensesFeesResponsibility] =
    useState(null);
  const [monthlyRent, setMonthlyRent] = useState(null);
  const [leaseDuration, setLeaseDuration] = useState(null);
  const [securityDepositToken, setSecurityDepositToken] = useState(null);
  const [securityDepositRegistration, setSecurityDepositRegistration] =
    useState(null);
  const [securityDepositHandover, setSecurityDepositHandover] = useState(null);
  const [securityDepositRentStart, setSecurityDepositRentStart] = useState(null);
  const [totalSecurityDeposit, setTotalSecurityDeposit] = useState(null);
  const [ddReport, setDDReport] = useState([]);
  const [auditReport, setAuditReport] = useState([]);
  const [signedMOU, setSignedMOU] = useState([]);
  const [llpAuthorizedAttorney, setLlpAuthorizedAttorney] = useState([]);
  const [lessorGstinNumber, setLessorGstinNumber] = useState(null);
  const [lessorGstinCertificate, setLessorGstinCertificate] = useState([]);
  const [electricalService, setElectricalService] = useState([
    {
      id: uuidv4(),
      electricalConnectionType: "",
      sanctionedElectricalLoad: "",
    },
  ]);
  const [totalSanctionedElectricalLoad, setTotalSanctionedElectricalLoad] =
    useState("");
  const [waterService, setWaterService] = useState([
    {
      id: uuidv4(),
      waterConnectionType: "",
      sanctionedWaterSupply: "",
    },
  ]);
  const [
    totalSanctionedWaterSupplyPerDay,
    setTotalSanctionedWaterSupplyPerDay,
  ] = useState("");

  const [lessorIndividualMultiple, setLessorIndividualMultiple] = useState([
    {
      id: uuidv4(),
      lessorName: "",
      lessorAge: "",
      lessorFatherName: "",
      lessorCompleteAddress: "",
      lessorAadharNumber: "",
      lessorAadharCard: [],
      lessorPANNumber: "",
      lessorPANCard: [],
      lessorPartnershipPercent: "",
      bankName: "",
      bankAccountType: "",
      bankBeneficiaryName: "",
      bankAccountNumber: "",
      bankIfscCode: "",
      cancelledCheque: [],
    },
  ]);

  const [lessorLLPPvtLtd, setLessorLLPPvtLtd] = useState([
    {
      id: uuidv4(),
      lessorEntityFullName: "",
      lessorName: "",
      lessorCompleteAddress: "",
      lessorAadharNumber: "",
      lessorAadharCard: [],
      lessorDINNumber: "",
      bankName: "",
      bankAccountType: "",
      bankBeneficiaryName: "",
      bankAccountNumber: "",
      bankIfscCode: "",
      cancelledCheque: [],
    },
  ]);

  const [lessorPartnership, setLessorPartnership] = useState([
    {
      id: uuidv4(),
      lessorEntityFullName: "",
      lessorName: "",
      lessorAge: "",
      lessorFatherName: "",
      lessorCompleteAddress: "",
      lessorAadharNumber: "",
      lessorAadharCard: [],
      lessorPANNumber: "",
      lessorPANCard: [],
      lessorPartnershipPercent: "",
      bankName: "",
      bankAccountType: "",
      bankBeneficiaryName: "",
      bankAccountNumber: "",
      bankIfscCode: "",
      cancelledCheque: [],
    },
  ]);
  const [zeroRentPeriodInDays, setZeroRentPeriodInDays] = useState(null)

  //!Images state
  const [floorPlansImageDummyState] = useState(null);
  const [CADDrawingsImageDummyState] = useState(null);
  const [picturesImageDummyState] = useState(null);
  const [videosImageDummyState] = useState(null);
  const [titleDocumentsImageDummyState] = useState(null);
  const [OCCCCertificateImageDummyState] = useState(null);

  const [TitleSearchReportImageDummyState] = useState(null);
  const [PropertyPreSigningAuditReportImageDummyState] = useState(null);
  const [lessorAadharCardImageDummyState] = useState(null);
  const [lessorPANCardImageDummyState] = useState(null);
  const [cancelledChequeImageDummyState] = useState(null);
  const [llpRegistrationCertificateImageDummyState] = useState(null);
  const [llpRepresentativeAadharCardImageDummyState] = useState(null);
  const [llpRepresentativePANCardImageDummyState] = useState(null);
  const [POCAadharCardImageDummyState] = useState(null);
  const [ddReportImageDummyState] = useState(null);
  const [auditReportImageDummyState] = useState(null);
  const [signedMOUImageDummyState] = useState(null);

  const [llpAuthorizedAttorneyImageDummyState] = useState(null);
  const [lessorGstinCertificateImageDummyState] = useState(null);

  const uploadFloorPlansImageType = [
    { label: "imageURL", value: "floorPlansImage" },
  ];
  const uploadCADDrawingsImageType = [
    { label: "imageURL", value: "CADDrawingsImage" },
  ];
  const uploadPicturesImageType = [
    { label: "imageURL", value: "picturesImage" },
  ];
  const uploadVideosImageType = [{ label: "imageURL", value: "videosImage" }];
  const uploadTitleDocumentsImageType = [
    { label: "imageURL", value: "titleDocumentsImage" },
  ];
  const uploadOCCCCertificateImageType = [
    { label: "imageURL", value: "OCCCCertificateImage" },
  ];

  const uploadTitleSearchReportImageType = [
    { label: "imageURL", value: "TitleSearchReportImage" },
  ];
  const uploadPropertyPreSigningAuditReportImageType = [
    { label: "imageURL", value: "PropertyPreSigningAuditReportImage" },
  ];
  const uploadLessorAadharCardImageType = [
    { label: "imageURL", value: "lessorAadharCardImage" },
  ];
  const uploadLessorPANCardImageType = [
    { label: "imageURL", value: "lessorPANCardImage" },
  ];
  const uploadCancelledChequeImageType = [
    { label: "imageURL", value: "cancelledChequeImage" },
  ];
  const uploadLlpRegistrationCertificateImageType = [
    { label: "imageURL", value: "llpRegistrationCertificateImage" },
  ];
  const uploadLlpRepresentativeAadharCardImageType = [
    { label: "imageURL", value: "LlpRepresentativeAadharCardImage" },
  ];
  const uploadLlpRepresentativePANCardImageType = [
    { label: "imageURL", value: "LlpRepresentativePANCardImage" },
  ];
  const uploadPOCAadharCardImageType = [
    { label: "imageURL", value: "pocAadharCardImage" },
  ];
  const uploadDDReportImageType = [
    { label: "imageURL", value: "ddReportImage" },
  ];
  const uploadAuditReportImageType = [
    { label: "imageURL", value: "auditReportImage" },
  ];
  const uploadSignedMOUImageType = [
    { label: "imageURL", value: "signedMOUImage" },
  ];

  const uploadLlpAuthorizedAttorneyImageType = [
    { label: "imageURL", value: "llpAuthorizedAttorney" },
  ];
  const uploadLessorGstinCertificateImageType = [
    { label: "imageURL", value: "lessorGstinCertificate" },
  ];

  const userRole = localStorage.getItem("role");
  const userDepartment = localStorage.getItem("department")
  const state = useSelector((state) => state?.cms);
  const dbData = state?.tableData;

  const [updateSupplyHandler, { data: updatedSupplyData }] = useUpdateSupply();

  useEffect(() => {
    if (hostelPrefix?.value && hostelCity) {
      let hostelName = hostelPrefix?.value + " " + hostelCity;
      if (localityName && localityNamePresent?.value === "Yes") {
        hostelName = hostelName + (`, ${localityName}`)
      }
      setHostelName(hostelName);
    }
  }, [hostelPrefix?.value, localityName, hostelCity, localityNamePresent]);

  useEffect(() => {
    if (numOfPrivateRooms && numOfDormRooms) {
      const numOfTotalRooms =
        Number(numOfPrivateRooms) + Number(numOfDormRooms);
      setNumOfTotalRooms(numOfTotalRooms.toFixed(2));
    }
  }, [numOfPrivateRooms, numOfDormRooms]);

  useEffect(() => {
    if (numOfPrivateRooms && dormBeds) {
      const numOfTotalRooms = Number(numOfPrivateRooms) + Number(dormBeds);
      setNumOfTotalBeds(numOfTotalRooms);
    }
  }, [numOfPrivateRooms, dormBeds]);

  useEffect(() => {
    const currentMonth = new Date();
    const goLiveMonth = new Date(goLiveDate);

    function monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months >= 0 ? 0 : Math.abs(months);
    }
    setAgeOfHostel(monthDiff(currentMonth, goLiveMonth));
  }, [goLiveDate]);

  useEffect(() => {
    // const leaseDuration =
    //   new Date(rentStartDate).getFullYear() -
    //   new Date(rentEndDate).getFullYear();
    const leaseDuration =
      new Date(rentEndDate).getFullYear() -
      new Date(rentStartDate).getFullYear();
    setLeaseDuration(leaseDuration);
  }, [rentStartDate, rentEndDate]);

  useEffect(() => {
    if (
      securityDepositToken &&
      securityDepositRegistration &&
      securityDepositHandover &&
      securityDepositRentStart
    ) {
      const totalSecurityDeposit =
        Number(securityDepositToken) +
        Number(securityDepositRegistration) +
        Number(securityDepositHandover) +
        Number(securityDepositRentStart);
      setTotalSecurityDeposit(totalSecurityDeposit);
    }
  }, [
    securityDepositToken,
    securityDepositRegistration,
    securityDepositHandover,
    securityDepositRentStart,
  ]);

  useEffect(() => {
    const totalLoad = electricalService?.map(
      (item) => item?.sanctionedElectricalLoad
    );
    setTotalSanctionedElectricalLoad(
      totalLoad?.reduce((curr, acc) => Number(curr) + Number(acc), 0)
    );
  }, [electricalService]);

  useEffect(() => {
    const totalSupply = waterService?.map(
      (item) => item?.sanctionedWaterSupply
    );
    setTotalSanctionedWaterSupplyPerDay(
      totalSupply?.reduce((curr, acc) => Number(curr) + Number(acc), 0)
    );
  }, [waterService]);
  // console.log(totalSanctionedElectricalLoad, "TOTAL LOAD");

  useEffect(() => {
    if (!lessorHandoverDate || !fitOutPeriod) return;

    const fitOutPeriodNum = Number(fitOutPeriod);

    const goLiveDate = new Date(lessorHandoverDate)
    goLiveDate.setDate(goLiveDate.getDate() + fitOutPeriodNum);
    setGoLiveDate(goLiveDate)

    const transformationHandoverDate = new Date(lessorHandoverDate);
    transformationHandoverDate.setDate(transformationHandoverDate.getDate() + fitOutPeriodNum - 10);
    setTransformationHandoverDate(transformationHandoverDate);

    const staffJoiningDate = new Date(transformationHandoverDate);
    staffJoiningDate.setDate(staffJoiningDate.getDate() + 1);
    setStaffJoiningDate(staffJoiningDate);

    const photoshootDate = new Date(transformationHandoverDate);
    photoshootDate.setDate(photoshootDate.getDate() + 3);
    setPhotoshootDate(photoshootDate);


  }, [lessorHandoverDate, fitOutPeriod]);

  const handleAddElectricalService = () =>
    setElectricalService([
      ...electricalService,
      {
        id: uuidv4(),
        electricalConnectionType: "",
        sanctionedElectricalLoad: "",
      },
    ]);

  const handleAddWaterService = () =>
    setWaterService([
      ...waterService,
      {
        id: uuidv4(),
        waterConnectionType: "",
        sanctionedWaterSupply: "",
      },
    ]);

  const handleAddLessorIndividualMultiple = () => {
    setLessorIndividualMultiple([
      ...lessorIndividualMultiple,
      {
        id: uuidv4(),
        lessorName: "",
        lessorAge: "",
        lessorFatherName: "",
        lessorCompleteAddress: "",
        lessorAadharNumber: "",
        lessorAadharCard: [],
        lessorPANNumber: "",
        lessorPANCard: [],
        lessorPartnershipPercent: "",
        bankName: "",
        bankAccountType: "",
        bankBeneficiaryName: "",
        bankAccountNumber: "",
        bankIfscCode: "",
        cancelledCheque: [],
      },
    ]);
  };

  const handleAddLessorLLPPvtLtd = () => {
    setLessorLLPPvtLtd([
      ...lessorLLPPvtLtd,
      {
        id: uuidv4(),
        lessorEntityFullName: "",
        lessorName: "",
        lessorCompleteAddress: "",
        lessorAadharNumber: "",
        lessorAadharCard: [],
        lessorDINNumber: "",
        bankName: "",
        bankAccountType: "",
        bankBeneficiaryName: "",
        bankAccountNumber: "",
        bankIfscCode: "",
        cancelledCheque: [],
      },
    ]);
  };

  const handleAddLessorPartnership = () => {
    setLessorPartnership([
      ...lessorPartnership,
      {
        id: uuidv4(),
        lessorEntityFullName: "",
        lessorName: "",
        lessorAge: "",
        lessorFatherName: "",
        lessorCompleteAddress: "",
        lessorAadharNumber: "",
        lessorAadharCard: [],
        lessorPANNumber: "",
        lessorPANCard: [],
        lessorPartnershipPercent: "",
        bankName: "",
        bankAccountType: "",
        bankBeneficiaryName: "",
        bankAccountNumber: "",
        bankIfscCode: "",
        cancelledCheque: [],
      },
    ]);
  };

  const handleElectricalConnectionType = (val, id, key) => {
    setElectricalService(
      electricalService.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };
  const handleSanctionedElectricalLoad = (val, id, key) => {
    setElectricalService(
      electricalService.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };
  const handleWaterConnectionType = (val, id, key) => {
    setWaterService(
      waterService.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };
  const handleSanctionedWaterLoad = (val, id, key) => {
    setWaterService(
      waterService.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };
  const handleLessorName = (val, id, key) => {
    setLessorIndividualMultiple(
      lessorIndividualMultiple.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };
  const handleLessorAge = (val, id, key) => {
    setLessorIndividualMultiple(
      lessorIndividualMultiple.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };

  useEffect(() => {
    if (updatedSupplyData?.updateSupply?.status === true) {
      toaster("success", "Hostel details updated successfully");
    } else if (updatedSupplyData?.updateSupply?.status === false) {
      toaster("error", "Error: Hostel details update failed");
    }
  }, [updatedSupplyData]);

  useEffect(() => {
    if (dbData) {
      setHostelStage(
        dbData?.hostelStage !== null ? { label: dbData?.hostelStage, value: dbData?.hostelStage } : null
      )
      setHostelPrefix(
        dbData?.hostelPrefix !== null
          ? { label: dbData?.hostelPrefix, value: dbData?.hostelPrefix }
          : ""
      );
      setLocalityNamePresent(
        dbData?.localityNamePresent !== null
          ? { label: dbData?.localityNamePresent, value: dbData?.localityNamePresent }
          : ""
      );
      setHostelCity(dbData?.hostelCity);
      setLocalityName(dbData?.localityName);
      setHostelName(dbData?.hostelName);
      setHostelState(
        dbData?.hostelState !== null
          ? { label: dbData?.hostelState, value: dbData?.hostelState }
          : ""
      );
      setHostelRegion(
        dbData?.hostelRegion !== null
          ? { label: dbData?.hostelRegion, value: dbData?.hostelRegion }
          : ""
      );
      setHostelCompleteAddress(dbData?.hostelCompleteAddress);
      setGoogleMapsPin(dbData?.googleMapsPin);
      setNumOfDormRooms(dbData?.numOfDormRooms);
      setNumOfPrivateRooms(dbData?.numOfPrivateRooms);
      setDormBeds(dbData?.dormBeds);
      setNumOfTotalRooms(dbData?.numOfTotalRooms);
      setNumOfTotalBeds(dbData?.numOfTotalBeds);
      setNumOfStaffRooms(dbData?.numOfStaffRooms);
      setNumOfInventoryRooms(dbData?.numOfInventoryRooms);
      setDealStage(
        dbData?.dealStage !== null
          ? { label: dbData?.dealStage, value: dbData?.dealStage }
          : ""
      );
      setFurnishingType(
        dbData?.furnishingType !== null
          ? { label: dbData?.furnishingType, value: dbData?.furnishingType }
          : ""
      );
      setHostelTotalArea(dbData?.hostelTotalArea);
      setConstructionAge(dbData?.constructionAge);
      setCafe(
        dbData?.cafe !== null
          ? { label: dbData?.cafe, value: dbData?.cafe }
          : ""
      );
      setCafeScale(
        dbData?.cafeScale !== null
          ? { label: dbData?.cafeScale, value: dbData?.cafeScale }
          : ""
      );
      setElectricalService(
        dbData?.electricalService?.map((item) => {
          return {
            id: uuidv4(),
            electricalConnectionType: {
              label: item?.electricalConnectionType,
              value: item?.electricalConnectionType,
            },
            sanctionedElectricalLoad: item?.sanctionedElectricalLoad,
          };
        })
      );
      setTotalSanctionedElectricalLoad(dbData?.totalSanctionedElectricalLoad);
      setWaterService(
        dbData?.waterService?.map((item) => {
          return {
            id: uuidv4(),

            waterConnectionType: {
              label: item?.waterConnectionType,
              value: item?.waterConnectionType,
            },
            sanctionedWaterSupply: item?.sanctionedWaterSupply,
          };
        })
      );
      setTotalSanctionedWaterSupplyPerDay(
        dbData?.totalSanctionedWaterSupplyPerDay
      );
      setDealSource(
        dbData?.dealSource !== null
          ? { label: dbData?.dealSource, value: dbData?.dealSource }
          : ""
      );
      setBrokerName(dbData?.brokerName);
      setBrokerageAmount(dbData?.brokerageAmount);
      setPropertyType(
        dbData?.propertyType !== null
          ? { label: dbData?.propertyType, value: dbData?.propertyType }
          : ""
      );
      setOperationalModel(
        dbData?.operationalModel !== null
          ? { label: dbData?.operationalModel, value: dbData?.operationalModel }
          : ""
      );
      setOMCBrandFees(dbData?.omcBrandFees);
      setOMCPlatformFees(dbData?.omcPlatformFees);
      setOMCPhotographyfees(dbData?.omcDesignFees);
      setOMCDesignFees(dbData?.omcDesignFees);
      setFloorPlans(dbData?.floorPlans !== null ? dbData?.floorPlans : []);
      setCADDrawings(dbData?.cadDrawings !== null ? dbData?.cadDrawings : []);
      setPicturesVideosDriveLink(dbData?.picturesVideosDriveLink);
      setTitleDocuments(
        dbData?.titleDocuments !== null ? dbData?.titleDocuments : []
      );
      setOCCCCertificate(
        dbData?.ocCCCertificate !== null ? dbData?.ocCCCertificate : []
      );
      setTitleSearchReport(
        dbData?.titleSearchReport !== null ? dbData?.titleSearchReport : []
      );
      setPropertyPreSigningAuditReport(
        dbData?.propertyPreSigningAuditReport !== null
          ? dbData?.propertyPreSigningAuditReport
          : []
      );
      setSignedMOU(dbData?.signedMOU !== null ? dbData?.signedMOU : []);
      setAgreementExecutionDate(dbData?.agreementExecutionDate);
      setLessorHandoverDate(dbData?.lessorHandoverDate);
      setTransformationHandoverDate(dbData?.transformationHandoverDate);
      setStaffJoiningDate(dbData?.staffJoiningDate);
      setPhotoshootDate(dbData?.photoshootDate);
      setGoLiveDate(dbData?.goLiveDate);
      setZeroRentPeriodInDays(dbData?.zeroRentPeriodInDays)
      setRentStartDate(dbData?.rentStartDate);
      setRentStartDate(dbData?.rentStartDate);
      setRentEndDate(dbData?.rentEndDate);
      setFitOutPeriod(dbData?.fitOutPeriod);
      setLessorLockInPeriod(dbData?.lessorLockInPeriod);
      setLesseeLockInPeriod(dbData?.lesseeLockInPeriod);
      setZeroPenaltyGracePeriod(dbData?.zeroPenaltyGracePeriod);
      setPenaltyPerDay(dbData?.penaltyPerDay);
      setTerminationNotice(dbData?.terminationNotice);
      setExitingDuration(dbData?.exitingDuration);
      setAgeOfHostel(dbData?.ageOfHostel);
      setAdvanceRent(dbData?.advanceRent);
      setAdvanceRentRepaymentTimeline(dbData?.advanceRentRepaymentTimeline);
      setTransformationPreLiquidatedAmount(
        dbData?.transformationPreLiquidatedAmount
      );
      setSublettingRights(
        dbData?.sublettingRights !== null
          ? { label: dbData?.sublettingRights, value: dbData?.sublettingRights }
          : ""
      );
      setMajorRMResponsibility(
        dbData?.majorRMResponsibility !== null
          ? {
            label: dbData?.majorRMResponsibility,
            value: dbData?.majorRMResponsibility,
          }
          : ""
      );
      setMinorRMResponsibility(
        dbData?.minorRMResponsibility !== null
          ? {
            label: dbData?.minorRMResponsibility,
            value: dbData?.minorRMResponsibility,
          }
          : ""
      );
      setMajorRMCurePeriod(dbData?.majorRMCurePeriod);
      setMajorRMCurePeriodPenaltyMultiplier(
        dbData?.majorRMCurePeriodPenaltyMultiplier
      );
      setForceMajeure1to3(dbData?.forceMajeure1to3);
      setForceMajeure4to6(dbData?.forceMajeure4to6);
      setForceMajeure6plus(dbData?.forceMajeure6plus);
      setLesseeEODPeriod(dbData?.lesseeEODPeriod);
      setLessorEODPeriod(dbData?.lessorEODPeriod);
      setMonthlyRentType(
        dbData?.monthlyRentType !== null
          ? {
            label: dbData?.monthlyRentType,
            value: dbData?.monthlyRentType,
          }
          : ""
      );
      setMonthlyFixedRent(dbData?.monthlyFixedRent);
      setMonthlyRevenueShare(dbData?.monthlyRevenueShare);
      setMontlyFNBRevenueShare(dbData?.monthlyFNBRevenueShare);
      setMonthlyAncillaryRevenueShare(dbData?.monthlyAncillaryRevenueShare);
      setRentPayableDate(dbData?.rentPayableDate);
      setRentalIncrementFreq(
        dbData?.rentalIncrementFreq !== null
          ? {
            label: dbData?.rentalIncrementFreq,
            value: dbData?.rentalIncrementFreq,
          }
          : ""
      );
      setRentalPaymentFreq(
        dbData?.rentalPaymentFreq !== null
          ? {
            label: dbData?.rentalPaymentFreq,
            value: dbData?.rentalPaymentFreq,
          }
          : ""
      );
      setRentalIncrementPercent(dbData?.rentalIncrementPercent);
      setRentPerRoom(dbData?.rentPerRoom);
      setRentPerBed(dbData?.rentPerBed);
      setLeaseDuration(dbData?.leaseDuration);
      setSecurityDepositToken(dbData?.securityDepositToken);
      setSecurityDepositRegistration(dbData?.securityDepositRegistration);
      setSecurityDepositHandover(dbData?.securityDepositHandover);
      setSecurityDepositRentStart(dbData?.securityDepositRentStart);
      setTotalSecurityDeposit(dbData?.totalSecurityDeposit);
    } else {
      setHostelStage(null)
      setHostelPrefix(null);
      setHostelCity(null);
      setLocalityName(null);
      setHostelName(null);
      setHostelState(null);
      setHostelRegion(null);
      setHostelCompleteAddress(null);
      setGoogleMapsPin(null);
      setNumOfDormRooms(null);
      setNumOfPrivateRooms(null);
      setDormBeds(null);
      setNumOfTotalRooms(null);
      setNumOfTotalBeds(null);
      setNumOfStaffRooms(null);
      setNumOfInventoryRooms(null);
      setDealStage(null);
      setFurnishingType(null);
      setHostelTotalArea(null);
      setConstructionAge(null);
      setCafe(null);
      setCafeScale(null);
      setElectricalService([
        {
          id: uuidv4(),
          electricalConnectionType: "",
          sanctionedElectricalLoad: "",
        },
      ]);
      setTotalSanctionedElectricalLoad("");
      setWaterService([
        {
          id: uuidv4(),
          waterConnectionType: "",
          sanctionedWaterSupply: "",
        },
      ]);
      setTotalSanctionedWaterSupplyPerDay(null);

      setDealSource(null);
      setBrokerName(null);
      setBrokerageAmount(null);
      setPropertyType(null);
      setOperationalModel(null);
      setOMCBrandFees(null);
      setOMCPhotographyfees(null);
      setOMCDesignFees(null);
      setOMCPlatformFees(null);
      setFloorPlans([]);
      setCADDrawings([]);
      setPicturesVideosDriveLink(null);
      setTitleDocuments([]);
      setOCCCCertificate([]);
      setTitleSearchReport([]);
      setPropertyPreSigningAuditReport([]);
      setSignedMOU([]);
      setAgreementExecutionDate(null);
      setLessorHandoverDate(null);
      setSignedMOU(null);
      setAgreementExecutionDate(null);
      setLessorHandoverDate(null);

      setTransformationHandoverDate(null);
      setStaffJoiningDate(null);
      setPhotoshootDate(null);
      setGoLiveDate(null);
      setZeroRentPeriodInDays(null);
      setRentStartDate(null);
      setRentEndDate(null);
      setFitOutPeriod(null);
      setLessorLockInPeriod(null);
      setLesseeLockInPeriod(null);
      setZeroPenaltyGracePeriod(null);
      setPenaltyPerDay(null);
      setTerminationNotice(null);
      setExitingDuration(null);
      setAgeOfHostel(null);
      setAdvanceRent(null);
      setAdvanceRentRepaymentTimeline(null);
      setTransformationPreLiquidatedAmount(null);
      setSublettingRights(null);
      setMajorRMResponsibility(null);
      setMinorRMResponsibility(null);
      setMajorRMCurePeriod(null);
      setMajorRMCurePeriodPenaltyMultiplier(null);
      setForceMajeure1to3(null);
      setForceMajeure4to6(null);
      setForceMajeure6plus(null);
      setLesseeEODPeriod(null);
      setLessorEODPeriod(null);
      setMonthlyRentType(null);
      setMonthlyFixedRent(null);
      setMonthlyRevenueShare(null);
      setRentPayableDate(null);
      setRentalIncrementFreq(null);
      setRentalPaymentFreq(null);
      setRentalIncrementPercent(null);
      setRentPerRoom(null);
      setRentPerBed(null);
      setLeaseDuration(null);
      setSecurityDepositToken(null);
      setSecurityDepositRegistration(null);
      setSecurityDepositHandover(null);
      setSecurityDepositRentStart(null);
      setTotalSecurityDeposit(null);
    }
  }, [dbData]);


  const SubmitHandler = (e) => {
    e.preventDefault();
    const electricalServiceData = electricalService?.map((item) => ({
      electricalConnectionType: item?.electricalConnectionType?.value,
      sanctionedElectricalLoad: Number(item?.sanctionedElectricalLoad),
    }));
    const waterServiceData = waterService?.map((item) => ({
      waterConnectionType: item?.waterConnectionType?.value,
      sanctionedWaterSupply: Number(item?.sanctionedWaterSupply),
    }));

    const data = {
      hostelStage: hostelStage?.value,
      hostelPrefix: hostelPrefix?.value,
      localityNamePresent: localityNamePresent?.value,
      localityName,
      hostelCity,
      hostelName,
      hostelState: hostelState?.value,
      hostelRegion: hostelRegion?.value,
      hostelCompleteAddress,
      googleMapsPin,
      numOfDormRooms: numOfDormRooms !== null ? Number(numOfDormRooms) : undefined,
      numOfPrivateRooms: numOfPrivateRooms !== null ? Number(numOfPrivateRooms) : undefined,
      dormBeds: dormBeds ? Number(dormBeds) : undefined,
      numOfTotalRooms: numOfTotalRooms !== null ? Number(numOfTotalRooms) : undefined,
      numOfTotalBeds: numOfTotalBeds !== null ? Number(numOfTotalBeds) : undefined,
      numOfStaffRooms: numOfStaffRooms !== null ? Number(numOfStaffRooms) : undefined,
      numOfInventoryRooms: numOfInventoryRooms !== null ? Number(numOfInventoryRooms) : undefined,
      dealStage: dealStage?.value,
      furnishingType: furnishingType?.value,
      propertyType: propertyType?.value,
      hostelTotalArea: hostelTotalArea !== null ? Number(hostelTotalArea) : undefined,
      constructionAge: constructionAge !== null ? Number(constructionAge) : undefined,
      cafe: cafe?.value,
      cafeScale: cafeScale?.value,
      electricalService: electricalServiceData?.length > 0 ? electricalServiceData : undefined,
      totalSanctionedElectricalLoad: totalSanctionedElectricalLoad !== null ? Number(totalSanctionedElectricalLoad) : undefined,
      waterService: waterServiceData?.length > 0 ? waterServiceData : undefined,
      totalSanctionedWaterSupplyPerDay: totalSanctionedWaterSupplyPerDay !== null ? Number(totalSanctionedWaterSupplyPerDay) : undefined,
      dealSource: dealSource?.value,
      brokerName,
      brokerageAmount: brokerageAmount !== null ? Number(brokerageAmount) : undefined,
      operationalModel: operationalModel?.value,
      omcBrandFees: omcBrandFees !== null ? Number(omcBrandFees) : undefined,
      omcPhotographyfees: omcPhotographyfees !== null ? Number(omcPhotographyfees) : undefined,
      omcDesignFees: omcDesignFees !== null ? Number(omcDesignFees) : undefined,
      omcPlatformFees: omcPlatformFees !== null ? Number(omcPlatformFees) : undefined,
      floorPlans,
      cadDrawings,
      picturesVideosDriveLink,
      titleDocuments,
      ocCCCertificate,
      titleSearchReport,
      propertyPreSigningAuditReport,
      signedMOU,
      agreementExecutionDate,
      lessorHandoverDate,
      transformationHandoverDate,
      staffJoiningDate,
      photoshootDate,
      goLiveDate,
      zeroRentPeriodInDays: zeroRentPeriodInDays !== null ? Number(zeroRentPeriodInDays) : null,
      rentStartDate,
      rentEndDate,
      fitOutPeriod: fitOutPeriod !== null ? Number(fitOutPeriod) : null,
      lessorLockInPeriod: lessorLockInPeriod !== null ? Number(lessorLockInPeriod) : null,
      lesseeLockInPeriod: lesseeLockInPeriod !== null ? Number(lesseeLockInPeriod) : null,
      zeroPenaltyGracePeriod: zeroPenaltyGracePeriod !== null ? Number(zeroPenaltyGracePeriod) : null,
      penaltyPerDay: penaltyPerDay !== null ? Number(penaltyPerDay) : null,
      terminationNotice: terminationNotice !== null ? Number(terminationNotice) : null,
      exitingDuration: exitingDuration !== null ? Number(exitingDuration) : null,
      ageOfHostel: ageOfHostel !== null ? Number(ageOfHostel) : null,
      advanceRent: advanceRent !== null ? Number(advanceRent) : null,
      advanceRentRepaymentTimeline: advanceRentRepaymentTimeline !== null ? Number(advanceRentRepaymentTimeline) : null,
      transformationPreLiquidatedAmount: transformationPreLiquidatedAmount !== null ? Number(transformationPreLiquidatedAmount) : null,
      sublettingRights: sublettingRights?.value,
      majorRMResponsibility: majorRMResponsibility?.value,
      minorRMResponsibility: minorRMResponsibility?.value,
      majorRMCurePeriod: majorRMCurePeriod !== null ? Number(majorRMCurePeriod) : null,
      majorRMCurePeriodPenaltyMultiplier: majorRMCurePeriodPenaltyMultiplier !== null ? Number(majorRMCurePeriodPenaltyMultiplier) : null,
      forceMajeure1to3: forceMajeure1to3 !== null ? Number(forceMajeure1to3) : null,
      forceMajeure4to6: forceMajeure4to6 !== null ? Number(forceMajeure4to6) : null,
      forceMajeure6plus: forceMajeure6plus !== null ? Number(forceMajeure6plus) : null,
      lesseeEODPeriod: lesseeEODPeriod !== null ? Number(lesseeEODPeriod) : null,
      lessorEODPeriod: lessorEODPeriod !== null ? Number(lessorEODPeriod) : null,
      monthlyRentType: monthlyRentType?.value,
      monthlyFixedRent: monthlyFixedRent !== null ? Number(monthlyFixedRent) : null,
      monthlyRevenueShare: monthlyRevenueShare !== null ? Number(monthlyRevenueShare) : null,
      monthlyFNBRevenueShare: monthlyFNBRevenueShare !== null ? Number(monthlyFNBRevenueShare) : null,
      monthlyAncillaryRevenueShare: monthlyAncillaryRevenueShare !== null ? Number(monthlyAncillaryRevenueShare) : null,
      rentPayableDate: rentPayableDate !== null ? Number(rentPayableDate) : null,
      rentalIncrementFreq: rentalIncrementFreq?.value,
      rentalPaymentFreq: rentalPaymentFreq?.value,
      rentalIncrementPercent: rentalIncrementPercent !== null ? Number(rentalIncrementPercent) : null,
      rentPerRoom: rentPerRoom !== null ? Number(rentPerRoom) : null,
      rentPerBed: rentPerBed !== null ? Number(rentPerBed) : null,
      leaseDuration: leaseDuration !== null ? Number(leaseDuration) : null,
      securityDepositToken: securityDepositToken !== null ? Number(securityDepositToken) : null,
      securityDepositRegistration: securityDepositRegistration !== null ? Number(securityDepositRegistration) : null,
      securityDepositHandover: securityDepositHandover !== null ? Number(securityDepositHandover) : null,
      securityDepositRentStart: securityDepositRentStart !== null ? Number(securityDepositRentStart) : null,
      totalSecurityDeposit: totalSecurityDeposit !== null ? Number(totalSecurityDeposit) : null,
      timelineDates: dbData?.timelineDates,
    };

    updateSupplyHandler(id, data);
    dispatch(
      tableDataHandler({
        ...state?.tableData,
        ...data,
      })
    );
  };
  const isEditableByuser = (e, name) => {
    if (userRole === "CEO" || userDepartment === "Supply") {
      return true
    }
    const editableFields = {
      Management: ["hostelPrefix", "locality", "localityName", "hostelRegion", "hostelCompleteAddress", "googleMapsPin", "numOfDormRooms", "numOfPrivateRooms", "dormBeds", "numOfStaffRooms", "numOfInventoryRooms", "propertyType", "hostelTotalArea", "cafe", "cafeScale", "electricalConnection", "electricalConnectionType", "sanctionedElectricalLoad", "waterConnection", "waterConnectionType", "sanctionedWaterSupply", "brokerName", "brokerageAmount", "picturesVideosDriveLink", "agreementExecutionDate", "lessorHandoverDate", "goLiveDate", "rentStartDate", "rentEndDate", "fitOutPeriod", "monthlyFixedRent", "monthlyRevenueShare", "floorPlan", "CADDrawing", "titleDocument", "ocCCCertificate", "propertyPreSigningAuditReport", "titleSearchReportDocument"],
      // Supply: ["hostelPrefix", "localityName", "hostelCity", "hostelState", "hostelCompleteAddress", "hostelRegion", "googleMapsPin", "numOfDormRooms", "numOfPrivateRooms", "dormBeds", "numOfStaffRooms", "numOfInventoryRooms", "propertyType", "hostelTotalArea", "cafe", "cafeScale", "electricalConnection", "electricalConnectionType", "sanctionedElectricalLoad", "waterConnection", "waterConnectionType", "sanctionedWaterSupply", "brokerName", "brokerageAmount", "picturesVideosDriveLink", "agreementExecutionDate", "lessorHandoverDate", "goLiveDate", "rentStartDate", "rentEndDate", "fitOutPeriod", "monthlyFixedRent", "monthlyRevenueShare", "floorPlan", "CADDrawing", "titleDocument", "ocCCCertificate", "propertyPreSigningAuditReport"],



      Listing: ["googleMapsPin", "numOfDormRooms", "numOfPrivateRooms", "dormBeds",],

      Transformation: ["numOfDormRooms", "numOfPrivateRooms", "dormBeds", "numOfStaffRooms", "numOfInventoryRooms", "hostelTotalArea", "cafe", "cafeScale", "electricalConnection", "electricalConnectionType", "sanctionedElectricalLoad", "waterConnection", "waterConnectionType", "sanctionedWaterSupply", "fitOutPeriod", "lessorHandoverDate", "floorPlan", "CADDrawing", "picturesVideosDriveLink", "propertyPreSigningAuditReport"],

      Operations: ["numOfStaffRooms", "numOfInventoryRooms", "cafe", "cafeScale", "sanctionedElectricalLoad", "electricalConnection", "electricalConnectionType", "waterConnection", "waterConnectionType", "sanctionedWaterSupply",]
    };

    const fieldName = e;

    if (editableFields[userDepartment] && editableFields[userDepartment].includes(fieldName)) {
      return true;
    }
    return false
  }

  const isVisible = (fieldName) => {
    if (userRole === "CEO" || userDepartment === "Management") { return true }
    const visibleFields = {
      Supply: ["omcBrandFees", "omcPhotographyfees", "omcDesignFees", "omcPlatformFees", "FurnishingType", "propertyType", "electricalConnectionType", "sanctionedElectricalLoad", "totalSanctionedElectricalLoad", "waterConnectionType", "sanctionedWaterSupply", "totalSanctionedWaterSupplyPerDay", "waterConnection", "electricalConnection", "dealSource", "brokerName", "brokerageAmount", "agreementExecutionDate", "fitOutPeriod", "zeroRentPeriodInDays", "rentStartDate", "rentEndDate", "lessorLockInPeriod", "lesseeLockInPeriod", "zeroPenaltyGracePeriod", "penaltyPerDay", "terminationNotice", "exitingDuration", "advanceRent", "advanceRentRepaymentTimeline", "transformationPreLiquidatedAmount", "sublettingRights", "majorRMResponsibility", "minorRMResponsibility", "majorRMCurePeriod", "majorRMCurePeriodPenaltyMultiplier", "forceMajeure1to3", "forceMajeure4to6", "forceMajeure6plus", "lesseeEODPeriod", "lessorEODPeriod", "monthlyRentType", "monthlyAncillaryRevenueShare", "monthlyFNBRevenueShare", "monthlyFixedRent", "monthlyRevenueShare", "rentPayableDate", "rentalIncrementFreq", "rentalPaymentFreq", "rentalIncrementPercent", "rentPerRoom", "rentPerBed", "securityDepositToken", "securityDepositRegistration", "securityDepositHandover", "securityDepositRentStart", "totalSecurityDeposit", "floorPlan", "CADDrawing", "titleDocument", "ocCCCertificate", "titleSearchReport", "propertyPreSigningAuditReport",],

      Transformation: ["FurnishingType", "propertyType", "electricalConnectionType", "sanctionedElectricalLoad", "totalSanctionedElectricalLoad", "waterConnectionType", "sanctionedWaterSupply", "totalSanctionedWaterSupplyPerDay", "dealSource", "brokerName", "brokerageAmount", "omcPlatformFees", "omcDesignFees", "floorPlan", "CADDrawing", "ocCCCertificate", "propertyPreSigningAuditReport", "signedMouImage", "agreementExecutionDate", "rentStartDate", "rentEndDate", "fitOutPeriod", "lessorLockInPeriod", "lesseeLockInPeriod", "zeroPenaltyGracePeriod", "penaltyPerDay", "terminationNotice", "exitingDuration", "advanceRent", "advanceRentRepaymentTimeline", "transformationPreLiquidatedAmount", "sublettingRights", "majorRMResponsibility", "minorRMResponsibility", "majorRMCurePeriod", "majorRMCurePeriodPenaltyMultiplier", "forceMajeure1to3", "forceMajeure4to6", "forceMajeure6plus", "lesseeEODPeriod", "lessorEODPeriod", "monthlyRentType", "monthlyFixedRent", "monthlyRevenueShare", "rentPayableDate", "rentalIncrementFreq", "rentalPaymentFreq", "rentalIncrementPercent", "rentPerRoom", "rentPerBed", "securityDepositToken", "securityDepositRegistration", "securityDepositHandover", "securityDepositRentStart", "totalSecurityDeposit", "waterConnection", "electricalConnection"],

      Finance: ["FurnishingType", "propertyType", "electricalConnectionType", "sanctionedElectricalLoad", "totalSanctionedElectricalLoad", "waterConnectionType", "sanctionedWaterSupply", "totalSanctionedWaterSupplyPerDay", "dealSource", "brokerName", "brokerageAmount", "omcBrandFees", "omcPlatformFees", "omcPhotoGraphy", "omcDesignFees", "floorPlan", "CADDrawing", "titleDocument", "ocCCCertificate", "titleSearchReport", "propertyPreSigningAuditReport", "signedMouImage", "agreementExecutionDate", "rentStartDate", "rentEndDate", "fitOutPeriod", "lessorLockInPeriod", "lesseeLockInPeriod", "zeroPenaltyGracePeriod", "penaltyPerDay", "terminationNotice", "exitingDuration", "advanceRent", "advanceRentRepaymentTimeline", "transformationPreLiquidatedAmount", "sublettingRights", "majorRMResponsibility", "minorRMResponsibility", "majorRMCurePeriod", "majorRMCurePeriodPenaltyMultiplier", "forceMajeure1to3", "forceMajeure4to6", "forceMajeure6plus", "lesseeEODPeriod", "lessorEODPeriod", "monthlyRentType", "monthlyFixedRent", "monthlyAncillaryRevenueShare", "monthlyFNBRevenueShare", "monthlyRevenueShare", "rentPayableDate", "rentalIncrementFreq", "rentalPaymentFreq", "rentalIncrementPercent", "rentPerRoom", "rentPerBed", "securityDepositToken", "securityDepositRegistration", "securityDepositHandover", "securityDepositRentStart", "totalSecurityDeposit", "waterConnection", "electricalConnection"],


      Legal: ["FurnishingType", "propertyType", "electricalConnectionType", "sanctionedElectricalLoad", "totalSanctionedElectricalLoad", "waterConnectionType", "sanctionedWaterSupply", "totalSanctionedWaterSupplyPerDay", "dealSource", "brokerName", "brokerageAmount", "omcBrandFees", "omcPlatformFees", "omcPlatformFees", "omcDesignFees", "floorPlan", "CADDrawing", "titleDocument", "ocCCCertificate", "titleSearchReport", "propertyPreSigningAuditReport", "signedMouImage", "agreementExecutionDate", "rentStartDate", "rentEndDate", "fitOutPeriod", "lessorLockInPeriod", "lesseeLockInPeriod", "zeroPenaltyGracePeriod", "penaltyPerDay", "terminationNotice", "exitingDuration", "advanceRent", "advanceRentRepaymentTimeline", "transformationPreLiquidatedAmount", "sublettingRights", "majorRMResponsibility", "minorRMResponsibility", "majorRMCurePeriod", "majorRMCurePeriodPenaltyMultiplier", "forceMajeure1to3", "forceMajeure4to6", "forceMajeure6plus", "lesseeEODPeriod", "lessorEODPeriod", "monthlyRentType", "monthlyFixedRent", "monthlyAncillaryRevenueShare", "monthlyFNBRevenueShare", "monthlyRevenueShare", "rentPayableDate", "rentalIncrementFreq", "rentalPaymentFreq", "rentalIncrementPercent", "rentPerRoom", "rentPerBed", "securityDepositToken", "securityDepositRegistration", "securityDepositHandover", "securityDepositRentStart", "totalSecurityDeposit", "waterConnection", "electricalConnection"],

      Operations: ["FurnishingType", "propertyType", "electricalConnectionType", "sanctionedElectricalLoad", "totalSanctionedElectricalLoad", "waterConnectionType", "sanctionedWaterSupply", "totalSanctionedWaterSupplyPerDay", "dealSource", "brokerName", "brokerageAmount", "omcPlatformFees", "floorPlan", "CADDrawing", "ocCCCertificate", "propertyPreSigningAuditReport", "signedMouImage", "agreementExecutionDate", "rentStartDate", "rentEndDate", "fitOutPeriod", "lessorLockInPeriod", "lesseeLockInPeriod", "zeroPenaltyGracePeriod", "penaltyPerDay", "terminationNotice", "exitingDuration", "advanceRent", "advanceRentRepaymentTimeline", "transformationPreLiquidatedAmount", "sublettingRights", "majorRMResponsibility", "minorRMResponsibility", "majorRMCurePeriod", "majorRMCurePeriodPenaltyMultiplier", "forceMajeure1to3", "forceMajeure4to6", "forceMajeure6plus", "lesseeEODPeriod", "lessorEODPeriod", "monthlyRentType", "monthlyFixedRent", "monthlyAncillaryRevenueShare", "monthlyFNBRevenueShare", "monthlyRevenueShare", "rentPayableDate", "rentalIncrementFreq", "rentalPaymentFreq", "rentalIncrementPercent", "rentPerRoom", "rentPerBed", "securityDepositToken", "securityDepositRegistration", "securityDepositHandover", "securityDepositRentStart", "totalSecurityDeposit", "waterConnection", "electricalConnection"],
    };

    if (visibleFields[userDepartment] && visibleFields[userDepartment].includes(fieldName)) {
      return true;
    }
    return false
  }


  useEffect(() => {
    if (numOfTotalRooms && monthlyFixedRent) {
      setRentPerRoom((monthlyFixedRent / numOfTotalRooms).toFixed(2))
    }
    if (numOfTotalBeds && monthlyFixedRent) {
      setRentPerBed((monthlyFixedRent / numOfTotalBeds).toFixed(2))
    }
  }, [monthlyFixedRent])

  const calculateRemainingFields = () => {
    let remainingCount = 0;
    let fields = [hostelPrefix, localityNamePresent, hostelCity, hostelState, hostelRegion, hostelCompleteAddress, googleMapsPin, operationalModel, numOfDormRooms,
      numOfPrivateRooms, dormBeds, numOfStaffRooms, numOfInventoryRooms, dealStage, furnishingType, propertyType, hostelTotalArea, constructionAge, cafe,
      floorPlans, cadDrawings, picturesVideosDriveLink, titleDocuments, ocCCCertificate, titleSearchReport, propertyPreSigningAuditReport,
      agreementExecutionDate, fitOutPeriod, lessorHandoverDate, transformationHandoverDate, staffJoiningDate, photoshootDate, goLiveDate,
      rentStartDate, rentEndDate, lessorLockInPeriod, lesseeLockInPeriod, terminationNotice, exitingDuration, ageOfHostel,
      transformationPreLiquidatedAmount, monthlyRentType, monthlyRevenueShare, monthlyAncillaryRevenueShare, monthlyFNBRevenueShare,
      rentPayableDate, rentalPaymentFreq, leaseDuration
    ];

    if (localityNamePresent?.value === "Yes") fields.push(localityName)
    if (operationalModel?.value === "OMC") fields.push(omcBrandFees, omcPlatformFees, omcPhotographyfees, omcDesignFees);
    if (operationalModel?.value === "FOCO") fields.push(omcBrandFees, omcPlatformFees, omcPhotographyfees, omcDesignFees);
    if (operationalModel?.value === "COCO") fields.push(dealSource, zeroRentPeriodInDays, zeroPenaltyGracePeriod, penaltyPerDay, advanceRent, advanceRentRepaymentTimeline, sublettingRights, majorRMResponsibility, minorRMResponsibility, majorRMCurePeriod,
      majorRMCurePeriodPenaltyMultiplier, forceMajeure1to3, forceMajeure4to6, forceMajeure6plus, lesseeEODPeriod, lessorEODPeriod, monthlyFixedRent, rentalIncrementFreq, rentalIncrementPercent, securityDepositToken, securityDepositRegistration, securityDepositHandover, securityDepositRentStart, totalSecurityDeposit)
    if (cafe?.value === "Yes") fields.push(cafeScale)
    if (dealSource?.value === "Broker") fields.push(brokerName, brokerageAmount);
    waterService?.map((item) => {
      fields.push(item.sanctionedWaterSupply, item.waterConnectionType);
    })

    electricalService?.map((item) => {
      fields.push(item?.electricalConnectionType, item?.sanctionedElectricalLoad);
    })

    fields.forEach(field => {
      if (typeof field === "object") {
        if (field?.[0]?.imageURL === null) {
          remainingCount++;
        } else if (field?.label === undefined && !field?.[0]?.imageURL) {
          if (!(field instanceof Date)) {
            remainingCount++;
          }
        }
      } else {
        if (field === null || field === "" || field === undefined || field.length === 0) {
          remainingCount++;
        }
      }
      console.log(remainingCount)
    });
    setRemainingCount(`(${remainingCount} fields pending / ${fields.length} )`)
    return `${remainingCount}/${fields.length}`;
  };
  calculateRemainingFields()
  return (
    <form className="mt-2" style={{ marginBottom: "150px" }} onSubmit={SubmitHandler}>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelPrefix" >
                Hostel prefix
              </Label>
            </strong>
            <Select
              isClearable={false}
              isSearchable={true}
              id="hostelPrefix"
              name="hostelPrefix"
              options={HostelPrefixList}
              value={hostelPrefix}
              onChange={(value) => { setHostelPrefix(value) }}
              classNamePrefix="select"
              placeholder=""
              isDisabled={!isEditableByuser("hostelPrefix")}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !hostelPrefix ? 'red' : provided.borderColor,
                }),
              }}
            />

          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="locality" >
                Locality name present
              </Label>
            </strong>
            <Select
              isClearable={false}
              isSearchable={true}
              id="locality"
              name="locality"
              options={YesNoDropdownOptions}
              value={localityNamePresent}
              onChange={(value) => { setLocalityNamePresent(value) }}
              classNamePrefix="select"
              placeholder=""
              isDisabled={!isEditableByuser("locality")}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !hostelPrefix ? 'red' : provided.borderColor,
                }),
              }}
            />

          </FormGroup>
        </Col>
        {/* //! Locality name */}
        {
          localityNamePresent?.value === "Yes" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="localityName">
                  Locality name
                </Label>
              </strong>
              <Input
                type="text"
                value={localityName}
                id="localityName"
                name="localityName"
                onChange={(e) => { setLocalityName(e.target.value) }}
                disabled={!isEditableByuser("localityName")}
                style={{
                  borderColor: !localityName && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Hostel city */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelCity">
                Hostel city
              </Label>
            </strong>
            <Input
              type="text"
              value={hostelCity}
              id="hostelCity"
              name="hostelCity"
              onChange={(e) => { setHostelCity(e.target.value) }}
              disabled={!isEditableByuser("hostelCity")}
              style={{
                borderColor: !hostelCity && 'red',
              }}
            />
          </FormGroup>
        </Col>
        {/* //! Hostel name */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelName" >
                Hostel name
              </Label>
            </strong>
            <Input
              type="text"
              value={hostelName}
              id="hostelName"
              name="hostelName"
              disabled
              // onChange={(e) => handleHostelName()}
              title={hostelName}
            />
          </FormGroup>
        </Col>
        {/* //! Hostel state */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelState">
                Hostel state
              </Label>
            </strong>
            <Select
              isClearable={false}
              isSearchable={true}
              id="hostelState"
              name="hostelState"
              options={indianStateList}
              value={hostelState}
              onChange={(value) => { setHostelState(value) }}
              classNamePrefix="select"
              placeholder=""
              isDisabled={!isEditableByuser("hostelState")}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !hostelState ? 'red' : provided.borderColor,
                }),
              }}
            />
          </FormGroup>
        </Col>
        {/* //! Hostel region */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelRegion">
                Hostel region
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="hostelRegion"
              name="hostelRegion"
              options={HostelRegionList}
              value={hostelRegion}
              onChange={(value) => { setHostelRegion(value) }}
              classNamePrefix="select"
              placeholder=""
              isDisabled={!isEditableByuser("hostelRegion")}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !hostelRegion ? 'red' : provided.borderColor,
                }),
              }}
            />
          </FormGroup>
        </Col>
        {/* //! Hostel complete address */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelCompleteAddress">
                Hostel complete address
              </Label>
            </strong>
            <Input
              type="text"
              value={hostelCompleteAddress}
              id="hostelCompleteAddress"
              name="hostelCompleteAddress"
              onChange={(e) => { setHostelCompleteAddress(e.target.value) }}
              disabled={!isEditableByuser("hostelCompleteAddress")}
              title={hostelCompleteAddress}
              style={{
                borderColor: !hostelCompleteAddress && 'red',
              }}
            />
          </FormGroup>
        </Col>
        {/* //! Google Maps pin */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="googleMapsPin">
                Google maps pin
              </Label>
            </strong>
            <Input
              type="text"
              value={googleMapsPin}
              id="googleMapsPin"
              name="googleMapsPin"
              onChange={(e) => { setGoogleMapsPin(e.target.value) }}
              disabled={!isEditableByuser("googleMapsPin")}
              style={{
                borderColor: !googleMapsPin && 'red',
              }}
            />
          </FormGroup>
        </Col>
        {/* //! Operational model */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="operationalModel">
                Operational model
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="operationalModel"
              name="operationalModel"
              options={OperationalModelTypeList}
              value={operationalModel}
              onChange={(value) => setOperationalModel(value)}
              classNamePrefix="select"
              placeholder=""
              isDisabled={!isEditableByuser("operationalModel")}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !operationalModel ? 'red' : provided.borderColor,
                }),
              }}
            />
          </FormGroup>
        </Col>
        {/* //! OMC brand fees (one-time) */}
        {["OMC", "FOCO"].includes(operationalModel?.value) && (
          <>
            {
              isVisible("omcBrandFees") &&
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="omcBrandFees">
                      OMC brand fees (one-time)
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={omcBrandFees}
                    id="omcBrandFees"
                    name="omcBrandFees"
                    //placeholder="Add OMC brand fees (one-time)"
                    onChange={(e) => setOMCBrandFees(e.target.value)}
                    disabled={!isEditableByuser("omcBrandFees")}
                    style={{
                      borderColor: omcBrandFees == null && 'red',
                    }}
                  />
                  {
                    omcBrandFees !== null &&
                    <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                      {
                        (omcBrandFees < 250000) && "OMC brand fees is less than the company mandated OMC guidelines."
                      }
                    </Label>
                  }
                </FormGroup>
              </Col>
            }
          </>
        )}
        {["OMC", "FOCO"].includes(operationalModel?.value) && (
          <>
            {
              isVisible("omcPhotographyfees") &&
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="omcPhotographyfees">
                      OMC photography fees (one-time)
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={omcPhotographyfees}
                    id="omcPhotographyfees"
                    name="omcPhotographyfees"
                    //placeholder="Add OMC brand fees (one-time)"
                    onChange={(e) => setOMCPhotographyfees(e.target.value)}
                    disabled={!isEditableByuser("omcPhotographyfees")}
                    style={{
                      borderColor: omcPhotographyfees == null && 'red',
                    }}
                  />
                  {
                    omcPhotographyfees !== null &&
                    <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                      {
                        (omcPhotographyfees < 100000) && "OMC photography fees is less than the company mandated OMC guidelines."
                      }
                    </Label>
                  }
                </FormGroup>
              </Col>
            }
          </>
        )}
        {["OMC", "FOCO"].includes(operationalModel?.value) && (
          <>
            {
              isVisible("omcDesignFees") &&
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="omcDesignFees">
                      OMC design fees (one-time)
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={omcDesignFees}
                    id="omcDesignFees"
                    name="omcDesignFees"
                    onChange={(e) => setOMCDesignFees(e.target.value)}
                    disabled={!isEditableByuser("omcDesignFees")}
                    style={{
                      borderColor: omcDesignFees == null && 'red',
                    }}
                  />
                  {
                    omcDesignFees !== null &&
                    <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                      {
                        (omcDesignFees < 150000) && "OMC brand fees is less than the company mandated OMC guidelines."
                      }
                    </Label>
                  }
                </FormGroup>
              </Col>
            }
          </>
        )}
        {/* //! OMC platform fees (monthly) */}
        {["OMC", "FOCO"].includes(operationalModel?.value) && (
          <>
            {
              isVisible("omcPlatformFees") &&
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="omcPlatformFees">
                      OMC platform fees (monthly)
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={omcPlatformFees}
                    id="omcPlatformFees"
                    name="omcPlatformFees"
                    //placeholder="Add OMC platform fees (monthly)"
                    onChange={(e) => setOMCPlatformFees(e.target.value)}
                    disabled={!isEditableByuser("omcPlatformFees")}
                    style={{
                      borderColor: omcPlatformFees == null && 'red',
                    }}
                  />
                  {
                    omcPlatformFees &&
                    <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                      {
                        (omcPlatformFees < 12500) && " OMC platform monthly fees is less than the company mandated OMC guidelines."
                      }
                    </Label>
                  }

                </FormGroup>
              </Col>
            }
          </>
        )}
        {/* //! No. of dormitory rooms */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfDormRooms">
                No. of dormitory rooms
              </Label>
            </strong>
            <Input
              type="number"
              value={numOfDormRooms}
              id="numOfDormRooms"
              name="numOfDormRooms"
              //placeholder="Add No. of dormitory rooms"
              onWheel={(e) => e.target.blur()}
              onChange={(e) => { setNumOfDormRooms(e.target.value) }}
              disabled={!isEditableByuser("numOfDormRooms")}
              style={{
                borderColor: numOfDormRooms == null && 'red',
              }}
            />
          </FormGroup>
        </Col>
        {/* //! No. of private rooms */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfPrivateRooms">
                No. of private rooms
              </Label>
            </strong>
            <Input
              type="number"
              value={numOfPrivateRooms}
              id="numOfPrivateRooms"
              name="numOfPrivateRooms"
              //placeholder="Add No. of private rooms"
              // onChange={(e) => setNumOfPrivateRooms(e.target.value)}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => { setNumOfPrivateRooms(e.target.value) }}
              disabled={!isEditableByuser("numOfPrivateRooms")}
              style={{
                borderColor: numOfPrivateRooms == null && 'red',
              }}
            />
          </FormGroup>
        </Col>
        {/* //! No. of dorm beds */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="dormBeds">
                No. of dorm beds
              </Label>
            </strong>
            <Input
              type="number"
              value={dormBeds}
              id="dormBeds"
              name="dormBeds"
              //placeholder="No. of dorm beds"
              // onChange={(e) => setDormBeds(e.target.value)}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => { setDormBeds(e.target.value) }}
              disabled={!isEditableByuser("dormBeds")}
              style={{
                borderColor: dormBeds == null && 'red',
              }}
            />
            {
              (dormBeds && numOfDormRooms) &&
              <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                Avg dorm beds/room = {(dormBeds / numOfDormRooms).toFixed(2)}
              </Label>
            }
          </FormGroup>
        </Col>
        {/* //! No. of total rooms */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfTotalRooms">
                No. of total rooms
              </Label>
            </strong>
            <Input
              type="text"
              value={numOfTotalRooms}
              id="numOfTotalRooms"
              name="numOfTotalRooms"
              disabled
            //placeholder="Add No. of total rooms"
            // onChange={(e) => setNumOfTotalRooms(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! No. of total beds */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfTotalBeds">
                No. of total beds
              </Label>
            </strong>
            <Input
              type="text"
              value={numOfTotalBeds}
              id="numOfTotalBeds"
              name="numOfTotalBeds"
              disabled
            //placeholder="Add No. of total beds"
            // onChange={(e) => setNumOfTotalBeds(e.target.value)}
            />
            {
              (numOfTotalBeds && numOfTotalRooms) &&
              <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                Avg number of beds/room = {(numOfTotalBeds / numOfTotalRooms).toFixed(2)}
              </Label>
            }
          </FormGroup>
        </Col>
        {/* //! No. of staff rooms */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfStaffRooms">
                No. of staff rooms
              </Label>
            </strong>
            <Input
              type="number"
              value={numOfStaffRooms}
              id="numOfStaffRooms"
              name="numOfStaffRooms"
              //placeholder="Add No. of staff rooms"
              // onChange={(e) => setNumOfStaffRooms(e.target.value)}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => { setNumOfStaffRooms(e.target.value) }}
              disabled={!isEditableByuser("numOfStaffRooms")}
              style={{
                borderColor: numOfStaffRooms == null && 'red',
              }}
            />
            {
              (numOfStaffRooms && numOfTotalRooms) &&
              <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                {
                  numOfStaffRooms < ((numOfTotalRooms * .65) / 4) ? "There are fewer staff rooms than required" : " There are more staff rooms than required"
                }
              </Label>
            }
          </FormGroup>
        </Col>
        {/* //! No. of inventory rooms */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfInventoryRooms">
                No. of inventory rooms
              </Label>
            </strong>
            <Input
              type="number"
              value={numOfInventoryRooms}
              id="numOfInventoryRooms"
              name="numOfInventoryRooms"
              //placeholder="Add No. of inventory rooms"
              // onChange={(e) => setNumOfInventoryRooms(e.target.value)}
              onChange={(e) => { setNumOfInventoryRooms(e.target.value) }}
              onWheel={(e) => e.target.blur()}
              disabled={!isEditableByuser("numOfInventoryRooms")}
              style={{
                borderColor: numOfInventoryRooms == null && 'red',
              }}
            />
          </FormGroup>
        </Col>
        {/* //! Deal stage */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="dealStage">
                Deal stage
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="dealStage"
              name="dealStage"
              options={DealStageList}
              value={dealStage}
              onChange={(value) => setDealStage(value)}
              classNamePrefix="select"
              placeholder=""
              isDisabled={!isEditableByuser("dealStage")}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !dealStage ? 'red' : provided.borderColor,
                }),
              }}
            />
          </FormGroup>
        </Col>
        {/* //! Furnishing type */}
        {
          isVisible("FurnishingType") && <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="furnishingType">
                  Furnishing type
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="furnishingType"
                name="furnishingType"
                options={FurnishingTypeList}
                value={furnishingType}
                onChange={(value) => setFurnishingType(value)}
                classNamePrefix="select"
                placeholder=""
                isDisabled={!isEditableByuser("furnishingType")}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: !furnishingType ? 'red' : provided.borderColor,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        }

        {/* //! Property usage type */}
        {
          isVisible("propertyType") && <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="propertyType">
                  Property usage type
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="propertyType"
                name="propertyType"
                options={PropertyTypeList}
                value={propertyType}
                onChange={(value) => { setPropertyType(value) }}
                classNamePrefix="select"
                placeholder=""
                isDisabled={!isEditableByuser("propertyType")}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: !propertyType ? 'red' : provided.borderColor,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        }

        {/* //! Hostel total area (in sq.ft.) */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelTotalArea">
                Hostel total area (in sq.ft.)
              </Label>
            </strong>
            <Input
              type="number"
              value={hostelTotalArea}
              id="hostelTotalArea"
              name="hostelTotalArea"
              //placeholder="Add hostel total area (in sq.ft.)"
              onChange={(e) => setHostelTotalArea(e.target.value)}
              onWheel={(e) => e.target.blur()}
              disabled={!isEditableByuser("hostelTotalArea")}
              style={{
                borderColor: hostelTotalArea == null && 'red',
              }}
            />
          </FormGroup>
        </Col>
        {/* //! Construction age (in years) */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="constructionAge">
                Construction age (in years)
              </Label>
            </strong>
            <Input
              type="number"
              value={constructionAge}
              id="constructionAge"
              name="constructionAge"
              //placeholder="Add Construction age (in years)"
              onChange={(e) => setConstructionAge(e.target.value)}
              disabled={!isEditableByuser("constructionAge")}
              style={{
                borderColor: constructionAge == null && 'red',
              }}
            />
            <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
              {constructionAge > 5 && "Higher R&M costs shall be expected"}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {/* //! Cafe */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="cafe">
                Cafe
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="cafe"
              name="cafe"
              options={CafeList}
              value={cafe}
              // onChange={(value) => setCafe(value)}
              onChange={(value) => { setCafe(value) }}
              classNamePrefix="select"
              placeholder=""
              isDisabled={!isEditableByuser("cafe")}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !cafe ? 'red' : provided.borderColor,
                }),
              }}
            />
          </FormGroup>
        </Col>
        {/* //! Cafe scale */}
        {cafe?.value === "Yes" && (
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="cafeScale">
                  Cafe scale
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="cafeScale"
                name="cafeScale"
                options={CafeScaleList}
                value={cafeScale}
                // onChange={(value) => setCafeScale(value)}
                onChange={(value) => { setCafeScale(value) }}
                isDisabled={!isEditableByuser("cafeScale")}
                classNamePrefix="select"
                placeholder=""
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: !cafeScale ? 'red' : provided.borderColor,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        )}
        {/* //! Electrical connection type */}
        <Col sm="12" className={"d-flex flex-column"}>
          {electricalService?.map(
            (
              { id, electricalConnectionType, sanctionedElectricalLoad },
              index
            ) => (
              <Row key={id}>
                {/* //! Electrical connection type */}
                {
                  isVisible("electricalConnectionType") &&
                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="electricalConnectionType"
                        >
                          Electrical connection type {index + 1}
                        </Label>
                      </strong>
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="electricalConnectionType"
                        name="electricalConnectionType"
                        options={ElectricalConnectionTypeList}
                        value={electricalConnectionType}
                        onChange={(value) => {
                          handleElectricalConnectionType(
                            value,
                            id,
                            "electricalConnectionType"
                          )
                        }}
                        isDisabled={!isEditableByuser("electricalConnectionType")}
                        classNamePrefix="select"
                        placeholder=""
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !electricalConnectionType || electricalConnectionType?.value === null ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                      <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                        {
                          electricalConnectionType?.value === "Residential" && " Unforseen penalties shall be expected due to commercial usage"
                        }
                      </Label>
                    </FormGroup>
                  </Col>
                }

                {/* //! Sanctioned electrical load (in KWH) */}
                {
                  isVisible("sanctionedElectricalLoad") && <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="sanctionedElectricalLoad"
                        >
                          Sanctioned electrical load (in KWH) {index + 1}
                        </Label>
                      </strong>
                      <Input
                        type="number"
                        value={sanctionedElectricalLoad}
                        id="sanctionedElectricalLoad"
                        name="sanctionedElectricalLoad"
                        onChange={(e) => {
                          handleSanctionedElectricalLoad(
                            e.target.value,
                            id,
                            "sanctionedElectricalLoad"
                          )
                        }}
                        disabled={!isEditableByuser("sanctionedElectricalLoad")}
                        onWheel={(e) => e.target.blur()}
                        style={{
                          borderColor: !sanctionedElectricalLoad && 'red',
                        }}
                      />
                    </FormGroup>
                  </Col>
                }

              </Row>
            )
          )}
          <Row>
            {
              isVisible("totalSanctionedElectricalLoad") &&
              <Col sm="3">
                <Row>
                  <Col >
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="totalSanctionedElectricalLoad"
                        >
                          Total sanctioned electrical load (in KWH)
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={totalSanctionedElectricalLoad}
                        id="totalSanctionedElectricalLoad"
                        name="totalSanctionedElectricalLoad"
                        disabled
                      />
                      <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                        {totalSanctionedElectricalLoad < (2.5 * numOfTotalRooms) && "There's a need to increase the total sanctioned electrical load"}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            }
            {
              isEditableByuser("electricalConnection") &&
              <Col sm="3" style={{ marginTop: "26px" }}>
                <PlusCircle size={30} onClick={() => { handleAddElectricalService() }} style={{ cursor: "pointer" }} />

              </Col>
            }
          </Row>
        </Col>
        <Col sm="12" className={"d-flex flex-column"}>
          {waterService?.map(
            ({ id, waterConnectionType, sanctionedWaterSupply }, index) => (
              <Row key={id}>
                {/* //! Water connection type */}
                {isVisible("waterConnectionType") &&
                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="waterConnectionType">
                          Water connection type {index + 1}
                        </Label>
                      </strong>

                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="waterConnectionType"
                        name="waterConnectionType"
                        options={WaterConnectionTypeList}
                        value={waterConnectionType}
                        onChange={(value) => {
                          handleWaterConnectionType(
                            value,
                            id,
                            "waterConnectionType"
                          )
                        }}
                        isDisabled={!isEditableByuser("waterConnectionType")}
                        classNamePrefix="select"
                        placeholder=""
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !waterConnectionType || waterConnectionType?.value === null ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                      <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                        {
                          waterConnectionType?.value === "Residential" && "Unforseen penalties shall be expected due to commercial usage"
                        }
                      </Label>
                    </FormGroup>
                  </Col>
                }

                {/* //! Sanctioned water supply/day (in ltr) */}
                {
                  isVisible("sanctionedWaterSupply") &&
                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="sanctionedWaterSupply"
                        >
                          Sanctioned water supply/day (in ltr) {index + 1}
                        </Label>
                      </strong>
                      <Input
                        type="number"
                        value={sanctionedWaterSupply}
                        id="sanctionedWaterSupply"
                        name="sanctionedWaterSupply"
                        onChange={(e) => {
                          handleSanctionedWaterLoad(
                            e.target.value,
                            id,
                            "sanctionedWaterSupply"
                          )
                        }}
                        disabled={!isEditableByuser("sanctionedWaterSupply")}
                        onWheel={(e) => e.target.blur()}
                        style={{
                          borderColor: !sanctionedWaterSupply && 'red',
                        }}
                      />
                    </FormGroup>
                  </Col>
                }
              </Row>
            )
          )}
          <Row>

            {
              isVisible("totalSanctionedWaterSupplyPerDay") &&
              <Col sm="3">
                <Row>
                  <Col >
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="totalSanctionedWaterSupplyPerDay"
                        >
                          Total sanctioned water supply/day (in ltr)
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={totalSanctionedWaterSupplyPerDay}
                        id="totalSanctionedWaterSupplyPerDay"
                        name="totalSanctionedWaterSupplyPerDay"
                        disabled
                      />
                      <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                        {totalSanctionedWaterSupplyPerDay < (250 * numOfTotalBeds) && "There's a need to increase the total sanctioned water supply/day"}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            }
            {
              isEditableByuser("waterConnection") &&
              <Col sm="3" style={{ marginTop: "26px" }}>
                <PlusCircle size={30} onClick={() => { handleAddWaterService() }} style={{ cursor: "pointer" }} />
              </Col>
            }
          </Row>

        </Col>


      </Row >
      <Row>
        {/* //! Deal source */}
        {
          isVisible("dealSource") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="dealSource">
                  Deal source
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="dealSource"
                name="dealSource"
                options={DealSourceTypeList}
                value={dealSource}
                onChange={(value) => setDealSource(value)}
                classNamePrefix="select"
                placeholder=""
                isDisabled={((["Management", "Supply"].includes(userDepartment) || userRole == "CEO")) ? false : true}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: !dealSource ? 'red' : provided.borderColor,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Broker name */}
        {dealSource?.value === "Broker" && operationalModel?.value === "COCO" && (
          <>
            {
              isVisible("brokerName") &&
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="brokerName">
                      Broker name
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={brokerName}
                    id="brokerName"
                    name="brokerName"
                    //placeholder="Add broker name"
                    // onChange={(e) => setBrokerName(e.target.value)}
                    onChange={(e) => { setBrokerName(e.target.value) }}

                    disabled={((["Management", "Supply"].includes(userDepartment) || userRole == "CEO")) ? false : true}
                    style={{
                      borderColor: !brokerName && 'red',
                    }}
                  />
                </FormGroup>
              </Col>
            }
          </>
        )}
        {/* //! Brokerage amount (exc. GST) */}
        {dealSource?.value === "Broker" && operationalModel?.value === "COCO" && (
          <>
            {
              isVisible("brokerageAmount") &&
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="brokerageAmount">
                      Brokerage amount (exc. GST)
                    </Label>
                  </strong>
                  <Input
                    type="number"
                    value={brokerageAmount}
                    id="brokerageAmount"
                    name="brokerageAmount"
                    //placeholder="Add brokerage amount"
                    // onChange={(e) => setBrokerageAmount(e.target.value)}
                    onChange={(e) => { setBrokerageAmount(e.target.value) }}
                    onWheel={(e) => e.target.blur()}
                    disabled={!isEditableByuser("brokerageAmount")}
                    style={{
                      borderColor: brokerageAmount == null && 'red',
                    }}
                  />
                  {
                    (brokerageAmount && monthlyFixedRent) &&
                    <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                      {((brokerageAmount / monthlyFixedRent) * 30).toFixed(2)} days brokerage was given in this deal.
                    </Label>
                  }
                </FormGroup>
              </Col>
            }
          </>
        )}

      </Row>
      <Row>

        {/* //! Agreement execution date */}
        {
          isVisible("agreementExecutionDate") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="agreementExecutionDate">
                  Agreement execution date
                </Label>
              </strong>
              <Flatpickr
                className="form-control text-dark"
                value={agreementExecutionDate}
                // onChange={(date) =>
                //   setAgreementExecutionDate(
                //     new Date(dateFormatter(new Date(date)))
                //   )
                // }
                onChange={(date) => {
                  setAgreementExecutionDate(
                    new Date(dateFormatter(new Date(date)))
                  )
                }}
                disabled={!isEditableByuser("agreementExecutionDate")}

                id="agreementExecutionDate"
                // placeholder="Add agreement execution date"
                required
                style={{
                  borderColor: agreementExecutionDate ? "lightblue" : "red",
                  opacity: 1,
                  backgroundColor: "white"
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Fit out period (in days) */}
        {
          isVisible("fitOutPeriod") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Fit out period (in days)</Label>
              </strong>
              <Input
                type="number"
                value={fitOutPeriod}
                id="fitOutPeriod"
                name="fitOutPeriod"
                //placeholder="Add fit out period"
                // onChange={(e) => setFitOutPeriod(e.target.value)}
                onChange={(e) => { setFitOutPeriod(e.target.value) }}
                disabled={!isEditableByuser("fitOutPeriod")}
                onWheel={(e) => e.target.blur()}
                style={{
                  borderColor: !fitOutPeriod && 'red',
                }}
              />
              {
                (dealStage && fitOutPeriod) &&
                <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                  {
                    (dealStage == "brownfield" && fitOutPeriod < 45) && "Fit out period for this deal is less than the company mandated guidelines."
                  }
                </Label>
              }
            </FormGroup>
          </Col>
        }
        {/* //! Lessor's handover date */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="lessorHandoverDate">
                Lessor's handover date
              </Label>
            </strong>
            <Flatpickr
              className="form-control text-dark"
              value={lessorHandoverDate}
              onChange={(date) => {
                setLessorHandoverDate(new Date(dateFormatter(new Date(date))))
              }}
              disabled={!isEditableByuser("lessorHandoverDate")}
              id="lessorHandoverDate"
              placeholder="Add lessor's handover date"
              required
              style={{
                borderColor: lessorHandoverDate ? "lightblue" : "red",
                opacity: 1,
                backgroundColor: "white"
              }}
            />
          </FormGroup>
        </Col>
        {/* //! Transformation handover date */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="transformationHandoverDate">
                Transformation handover date
              </Label>
            </strong>
            <Flatpickr
              className="form-control text-dark"
              value={transformationHandoverDate}
              // onChange={(date) =>
              //   setLessorHandoverDate(new Date(dateFormatter(new Date(date))))
              // }
              id="transformationHandoverDate"
              // placeholder="Add transformation handover date"
              required
              disabled
            />
          </FormGroup>
        </Col>
        {/* //! Staff joining date */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="staffJoiningDate">
                Staff joining date
              </Label>
            </strong>
            <Flatpickr
              className="form-control text-dark"
              value={staffJoiningDate}
              // onChange={(date) =>
              //   setLessorHandoverDate(new Date(dateFormatter(new Date(date))))
              // }
              id="staffJoiningDate"
              // placeholder="Add staff joining date"
              required
              disabled
            />
          </FormGroup>
        </Col>
        {/* //! Photoshoot date */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="photoshootDate">
                Photoshoot date
              </Label>
            </strong>
            <Flatpickr
              className="form-control text-dark"
              value={photoshootDate}
              // onChange={(date) =>
              //   setLessorHandoverDate(new Date(dateFormatter(new Date(date))))
              // }
              id="photoshootDate"
              // placeholder="Add photoshoot date"
              required
              disabled
            />
          </FormGroup>
        </Col>
        {/* //! Go live date */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="goLiveDate">
                Go live date
              </Label>
            </strong>
            <Flatpickr
              className="form-control text-dark"
              value={goLiveDate}
              onChange={(date) => {
                setGoLiveDate(new Date(dateFormatter(new Date(date))))
              }}
              // disabled={!isEditableByuser("goLiveDate")}
              id="goLiveDate"
              // placeholder="Add go live date"
              disabled
              required

            />
          </FormGroup>
        </Col>
        {
          isVisible("zeroRentPeriodInDays") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Zero rent period (in days)</Label>
              </strong>
              <Input
                type="number"
                value={zeroRentPeriodInDays}
                id="fitOutPeriod"
                name="fitOutPeriod"
                //placeholder="Add fit out period"
                // onChange={(e) => setFitOutPeriod(e.target.value)}
                onChange={(e) => { setZeroRentPeriodInDays(e.target.value) }}
                disabled={!isEditableByuser("fitOutPeriod")}
                onWheel={(e) => e.target.blur()}
                style={{
                  borderColor: zeroRentPeriodInDays == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/*//! Rent start date */}
        {
          isVisible("rentStartDate") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="rentStartDate">
                  Rent start date
                </Label>
              </strong>
              <Flatpickr
                className="form-control text-dark"
                value={rentStartDate}
                onChange={(date) => {
                  setRentStartDate(new Date(dateFormatter(new Date(date))))
                }}
                disabled={!isEditableByuser("rentStartDate")}
                id="rentStartDate"
                options={{
                  minDate: goLiveDate
                }}
                required
                style={{
                  borderColor: rentStartDate ? "lightblue" : "red",
                  opacity: 1,
                  backgroundColor: "white"
                }}
              />
            </FormGroup>
          </Col>
        }
        {/*//! Rent end date */}
        {
          isVisible("rentEndDate") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="rentEndDate">
                  Rent end date
                </Label>
              </strong>
              <Flatpickr
                className="form-control text-dark"
                value={rentEndDate}
                onChange={(date) => {
                  setRentEndDate(new Date(dateFormatter(new Date(date))))
                }}
                disabled={!isEditableByuser("rentEndDate")}
                id="rentEndDate"
                required
                style={{
                  borderColor: rentEndDate ? "lightblue" : "red",
                  opacity: 1,
                  backgroundColor: "white"
                }}
                options={{
                  minDate: goLiveDate
                }}
              />
            </FormGroup>
          </Col>
        }

        {/* //! Lessor's lock-in period (in years) */}
        {
          isVisible("lessorLockInPeriod") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessor's lock-in period (in years)
                </Label>
              </strong>
              <Input
                type="number"
                value={lessorLockInPeriod}
                id="lessorLockInPeriod"
                name="lessorLockInPeriod"
                //placeholder="Add lessor's lock-in period"
                onChange={(e) => setLessorLockInPeriod(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("lessorLockInPeriod")}
                style={{
                  borderColor: lessorLockInPeriod == null && 'red',
                }}
              />
              <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                {
                  lessorLockInPeriod < leaseDuration && "Lessor isn't locked-in this deal for 100% term"
                }
              </Label>
            </FormGroup>
          </Col>
        }
        {/* //! Lessee's lock-in period (in years) */}
        {
          isVisible("lesseeLockInPeriod") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessee's lock-in period (in years)
                </Label>
              </strong>
              <Input
                type="number"
                value={lesseeLockInPeriod}
                id="lesseeLockInPeriod"
                name="lesseeLockInPeriod"
                //placeholder="Add lessee's lock-in period"
                onChange={(e) => setLesseeLockInPeriod(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("lesseeLockInPeriod")}
                style={{
                  borderColor: lesseeLockInPeriod == null && 'red',
                }}
              />
              <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                {
                  (lesseeLockInPeriod > (0.33 * leaseDuration)) && " Lessee is locked-in this deal for greater than 33% of the term"
                }
              </Label>
            </FormGroup>
          </Col>
        }
      </Row>
      <Row>
        {/* //! "Zero penalty grace period for Lessor's handover (in days)" */}
        {
          isVisible("zeroPenaltyGracePeriod") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Zero penalty grace period for Lessor's handover (in days)
                </Label>
              </strong>
              <Input
                type="number"
                value={zeroPenaltyGracePeriod}
                id="zeroPenaltyGracePeriod"
                name="zeroPenaltyGracePeriod"
                //placeholder="Add zero penalty grace period for Lessor's handover"
                onChange={(e) => setZeroPenaltyGracePeriod(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("zeroPenaltyGracePeriod")}
                style={{
                  borderColor: zeroPenaltyGracePeriod == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Penalty/day on Lessor for handover delay */}
        {
          isVisible("penaltyPerDay") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Penalty/day on Lessor for handover delay
                </Label>
              </strong>
              <Input
                type="number"
                value={penaltyPerDay}
                id="penaltyPerDay"
                name="penaltyPerDay"
                //placeholder="Add penalty/day on Lessor for handover delay"
                onChange={(e) => setPenaltyPerDay(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("penaltyPerDay")}
                style={{
                  borderColor: penaltyPerDay == null && 'red',
                }}
              />
              <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                {
                  (penaltyPerDay < (monthlyFixedRent / 30)) && "Penalty agreed is lesser than per day rent"
                }
              </Label>
            </FormGroup>
          </Col>
        }
        {/* //! Termination notice period (in days) */}
        {
          isVisible("terminationNotice") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Termination notice period (in days)
                </Label>
              </strong>
              <Input
                type="number"
                value={terminationNotice}
                id="terminationNotice"
                name="terminationNotice"
                //placeholder="Add termination notice period"
                onChange={(e) => setTerminationNotice(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("terminationNotice")}
                style={{
                  borderColor: terminationNotice == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* // ! States NA */}
        {/* //! Exiting duration (in days) */}
        {
          isVisible("exitingDuration") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Exiting duration (in days)</Label>
              </strong>
              <Input
                type="number"
                value={exitingDuration}
                id="exitingDuration"
                name="exitingDuration"
                //placeholder="Add exiting duration"
                onChange={(e) => setExitingDuration(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("exitingDuration")}
                style={{
                  borderColor: exitingDuration == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Age of the hostel (in months)  */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Age of the hostel (in months)
              </Label>
            </strong>
            <Input
              type="number"
              value={ageOfHostel}
              id="ageOfHostel"
              name="ageOfHostel"
              //placeholder="Age of the hostel"
              // disabled
              // onChange={(e) => setAgeOfHostel(e.target.value)}
              disabled={!isEditableByuser("ageOfHostel")}
              style={{
                borderColor: (ageOfHostel == null) && 'red',
              }}
            />
          </FormGroup>
        </Col>
        {/* //! Advance rent  */}
        {
          isVisible("advanceRent") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Advance rent</Label>
              </strong>
              <Input
                type="number"
                value={advanceRent}
                id="advanceRent"
                name="advanceRent"
                //placeholder="Advance rent"
                onChange={(e) => setAdvanceRent(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("advanceRent")}
                style={{
                  borderColor: advanceRent == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Advance rent repayment timeline (in months)  */}
        {
          isVisible("advanceRentRepaymentTimeline") && Number(advanceRent) !== 0 && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Advance rent repayment timeline (in months)
                </Label>
              </strong>
              <Input
                type="number"
                value={advanceRentRepaymentTimeline}
                id="advanceRentRepaymentTimeline"
                name="advanceRentRepaymentTimeline"
                //placeholder="Advance rent"
                onChange={(e) => setAdvanceRentRepaymentTimeline(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("advanceRentRepaymentTimeline")}
                style={{
                  borderColor: advanceRentRepaymentTimeline == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Transformation/pre-liquidated amount  */}
        {
          isVisible("transformationPreLiquidatedAmount") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Transformation/pre-liquidated amount
                </Label>
              </strong>
              <Input
                type="number"
                value={transformationPreLiquidatedAmount}
                id="transformationPreLiquidatedAmount"
                name="transformationPreLiquidatedAmount"
                //placeholder="Advance rent"
                onChange={(e) =>
                  setTransformationPreLiquidatedAmount(e.target.value)
                }
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("transformationPreLiquidatedAmount")}
                style={{
                  borderColor: transformationPreLiquidatedAmount == null && 'red',
                }}
              />
              {/* <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                  {
                    (transformationPreLiquidatedAmount < totalcap) && "Lessor isn't locked-in this deal for 100% term"
                  }
                </Label> */}
            </FormGroup>
          </Col>
        }
        {/* //! Subletting rights? */}
        {
          isVisible("sublettingRights") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Subletting rights?</Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="sublettingRights"
                name="sublettingRights"
                options={SublettingRightsTypeList}
                value={sublettingRights}
                onChange={(value) => setSublettingRights(value)}
                classNamePrefix="select"
                placeholder=""
                isDisabled={!isEditableByuser("sublettingRights")}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: !sublettingRights ? 'red' : provided.borderColor,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        }

        {/* //! Major R&M responsibility*/}
        {
          isVisible("majorRMResponsibility") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Major R&M responsibility</Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="majorRMResponsibility"
                name="majorRMResponsibility"
                options={LicensesOfficialFeesResponsibilityTypeList}
                value={majorRMResponsibility}
                onChange={(value) => setMajorRMResponsibility(value)}
                classNamePrefix="select"
                placeholder=""
                isDisabled={!isEditableByuser("majorRMResponsibility")}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: !majorRMResponsibility ? 'red' : provided.borderColor,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Minor R&M responsibility*/}
        {
          isVisible("minorRMResponsibility") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Minor R&M responsibility</Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="minorRMResponsibility"
                name="minorRMResponsibility"
                options={LicensesOfficialFeesResponsibilityTypeList}
                value={minorRMResponsibility}
                onChange={(value) => setMinorRMResponsibility(value)}
                classNamePrefix="select"
                placeholder=""
                isDisabled={!isEditableByuser("minorRMResponsibility")}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: !minorRMResponsibility ? 'red' : provided.borderColor,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Major R&M cure period (in days) */}
        {
          isVisible("majorRMCurePeriod") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Major R&M cure period (in days)
                </Label>
              </strong>
              <Input
                type="number"
                value={majorRMCurePeriod}
                id="majorRMCurePeriod"
                name="majorRMCurePeriod"
                //placeholder="Major R&M cure period"
                onChange={(e) => setMajorRMCurePeriod(e.target.value)}
                disabled={!isEditableByuser("majorRMCurePeriod")}
                style={{
                  borderColor: majorRMCurePeriod == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Major R&M post cure period penalty multiplier */}
        {
          isVisible("majorRMCurePeriodPenaltyMultiplier") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Major R&M post cure period penalty multiplier
                </Label>
              </strong>
              <Input
                type="number"
                value={majorRMCurePeriodPenaltyMultiplier}
                id="majorRMCurePeriodPenaltyMultiplier"
                name="majorRMCurePeriodPenaltyMultiplier"
                //placeholder="Major R&M post cure period penalty multiplier"
                onChange={(e) =>
                  setMajorRMCurePeriodPenaltyMultiplier(e.target.value)
                }
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("majorRMCurePeriodPenaltyMultiplier")}
                style={{
                  borderColor: majorRMCurePeriodPenaltyMultiplier == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Force Majeure rent (1st - 3rd month) */}
        {
          isVisible("forceMajeure1to3") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Force majeure monthly rent (1st - 3rd month)
                </Label>
              </strong>
              <Input
                type="number"
                value={forceMajeure1to3}
                id="forceMajeure1to3"
                name="forceMajeure1to3"
                //placeholder="Force Majeure rent (1st - 3rd month)"
                onChange={(e) => setForceMajeure1to3(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("forceMajeure1to3")}
                style={{
                  borderColor: forceMajeure1to3 == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Force Majeure rent (4th - 6th month) */}
        {
          isVisible("forceMajeure4to6") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Force majeure monthly rent (4th - 6th month)
                </Label>
              </strong>
              <Input
                type="number"
                value={forceMajeure4to6}
                id="forceMajeure4to6"
                name="forceMajeure4to6"
                //placeholder="Force Majeure rent (4th - 6th month)"
                onChange={(e) => setForceMajeure4to6(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("forceMajeure4to6")}
                style={{
                  borderColor: forceMajeure4to6 == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Force Majeure rent (6+ months) */}
        {
          isVisible("forceMajeure6plus") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Force majeure monthly rent (6+ months)
                </Label>
              </strong>

              <Input
                type="number"
                value={forceMajeure6plus}
                id="forceMajeure6plus"
                name="forceMajeure6plus"
                //placeholder="Force Majeure rent (6+ months)"
                onChange={(e) => setForceMajeure6plus(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("forceMajeure6plus")}
                style={{
                  borderColor: forceMajeure6plus == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Lessee's default of rent period (in days) */}
        {
          isVisible("lesseeEODPeriod") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessee's default of rent period (in days)
                </Label>
              </strong>
              <Input
                type="number"
                value={lesseeEODPeriod}
                id="lesseeEODPeriod"
                name="lesseeEODPeriod"
                //placeholder="Lessee's event of default period"
                onChange={(e) => setLesseeEODPeriod(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("lesseeEODPeriod")}
                style={{
                  borderColor: lesseeEODPeriod == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Lessor's default period for license renewal (in days) */}
        {
          isVisible("lessorEODPeriod") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessor's default period for license renewal (in days)
                </Label>
              </strong>
              <Input
                type="number"
                value={lessorEODPeriod}
                id="lessorEODPeriod"
                name="lessorEODPeriod"
                //placeholder="Lessor's default period for license renewal (in days)"
                onChange={(e) => setLessorEODPeriod(e.target.value)}
                onWheel={(e) => e.target.blur()}
                disabled={!isEditableByuser("lessorEODPeriod")}
                style={{
                  borderColor: lessorEODPeriod == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Lessee's EOD cure period (in days) */}
        {/* //! Monthly rent type*/}
        {
          isVisible("monthlyRentType") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly rent type</Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="monthlyRentType"
                name="monthlyRentType"
                options={MonthlyRentTypeList}
                value={monthlyRentType}
                onChange={(value) => setMonthlyRentType(value)}
                isDisabled={!isEditableByuser("monthlyRentType")}
                classNamePrefix="select"
                placeholder=""
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: !monthlyRentType ? 'red' : provided.borderColor,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Monthly fixed rent */}
        {
          isVisible("monthlyFixedRent") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly fixed rent</Label>
              </strong>

              <Input
                type="number"
                value={monthlyFixedRent}
                id="monthlyFixedRent"
                name="monthlyFixedRent"
                // placeholder="Monthly fixed rent "
                // onChange={(e) => setMonthlyFixedRent(e.target.value)}
                onChange={(e) => { isEditableByuser(e.target.name) && setMonthlyFixedRent(e.target.value) }}
                disabled={!isEditableByuser("monthlyFixedRent")}
                onWheel={(e) => e.target.blur()}
                style={{
                  borderColor: monthlyFixedRent == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Monthly revenue share  % */}
        {
          isVisible("monthlyRevenueShare") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly bed revenue share %</Label>
              </strong>

              <Input
                type="number"
                value={monthlyRevenueShare}
                id="monthlyRevenueShare"
                name="monthlyRevenueShare"
                // placeholder="Monthly fixed rent  %"
                // onChange={(e) => setMonthlyRevenueShare(e.target.value)}
                onChange={(e) => { isEditableByuser(e.target.name) && setMonthlyRevenueShare(e.target.value) }}
                disabled={!isEditableByuser("monthlyRevenueShare")}
                onWheel={(e) => e.target.blur()}
                style={{
                  borderColor: monthlyRevenueShare == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {
          isVisible("monthlyFNBRevenueShare") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly F&B revenue share %</Label>
              </strong>

              <Input
                type="number"
                value={monthlyFNBRevenueShare}
                id="monthlyFNBRevenueShare"
                name="monthlyFNBRevenueShare"
                onChange={(e) => { isEditableByuser(e.target.name) && setMontlyFNBRevenueShare(e.target.value) }}
                disabled={!isEditableByuser("monthlyFNBRevenueShare")}
                onWheel={(e) => e.target.blur()}
                style={{
                  borderColor: monthlyFNBRevenueShare == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {
          isVisible("monthlyAncillaryRevenueShare") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly ancillary revenue share %</Label>
              </strong>

              <Input
                type="number"
                value={monthlyAncillaryRevenueShare}
                id="monthlyAncillaryRevenueShare"
                name="monthlyAncillaryRevenueShare"
                onChange={(e) => { isEditableByuser(e.target.name) && setMonthlyAncillaryRevenueShare(e.target.value) }}
                disabled={!isEditableByuser("monthlyAncillaryRevenueShare")}
                onWheel={(e) => e.target.blur()}
                style={{
                  borderColor: monthlyAncillaryRevenueShare == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Rent payable day*/}
        {
          isVisible("rentPayableDate") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Rent payable day</Label>
              </strong>

              <Input
                type="number"
                value={rentPayableDate}
                id="rentPayableDate"
                name="rentPayableDate"
                // placeholder="Rent payable day"
                onChange={(e) => setRentPayableDate(e.target.value)}
                disabled={!isEditableByuser("rentPayableDate")}
                style={{
                  borderColor: rentPayableDate == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Rental increment frequency*/}
        {
          isVisible("rentalIncrementFreq") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Rental increment frequency</Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="rentalIncrementFreq"
                name="  "
                options={RentalIncrementFrequencyTypeList}
                value={rentalIncrementFreq}
                onChange={(value) => setRentalIncrementFreq(value)}
                isDisabled={!isEditableByuser("rentalIncrementFreq")}
                classNamePrefix="select"
                placeholder=""
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: !rentalIncrementFreq ? 'red' : provided.borderColor,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Rental payment frequency*/}
        {
          isVisible("rentalPaymentFreq") &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Rental payment frequency</Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="rentalPaymentFreq"
                name="rentalPaymentFreq"
                options={RentalPaymentFrequencyTypeList}
                value={rentalPaymentFreq}
                onChange={(value) => setRentalPaymentFreq(value)}
                classNamePrefix="select"
                placeholder=""
                isDisabled={!isEditableByuser("rentalPaymentFreq")}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: !rentalPaymentFreq ? 'red' : provided.borderColor,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Rental increment %*/}
        {
          isVisible("rentalIncrementPercent") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Rental increment %</Label>
              </strong>
              <Input
                type="text"
                value={rentalIncrementPercent}
                id="rentalIncrementPercent"
                name="rentalIncrementPercent"
                // placeholder="Rental increment %"
                onChange={(e) => setRentalIncrementPercent(e.target.value)}
                disabled={!isEditableByuser("rentalIncrementPercent")}
                style={{
                  borderColor: rentalIncrementPercent == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //! Rent per room*/}
        {
          isVisible("rentPerRoom") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly fixed rent/room</Label>
              </strong>

              <Input
                type="text"
                value={rentPerRoom}
                id="rentPerRoom"
                name="rentPerRoom"
                // placeholder="Rent per room"
                // onChange={(e) => setRentPerRoom(e.target.value)}
                // disabled={!isEditableByuser("rentPerRoom")}
                disabled
              />
            </FormGroup>
          </Col>
        }
        {/* //! Rent per bed*/}
        {
          isVisible("rentPerBed") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly fixed rent/bed</Label>
              </strong>

              <Input
                type="text"
                value={rentPerBed}
                id="rentPerBed"
                name="rentPerBed"
                // placeholder="Rent per bed"
                // onChange={(e) => setRentPerBed(e.target.value)}
                // disabled={!isEditableByuser("rentPerBed")}
                disabled
              />
            </FormGroup>
          </Col>
        }
        {/* //!  Lease duration (in years)*/}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="leaseDuration">
                Lease duration (in years)
              </Label>
            </strong>
            <Input
              type="number"
              value={leaseDuration}
              id="leaseDuration"
              name="leaseDuration"
              onChange={(e) => setLeaseDuration(e.target.value)}
              disabled
              style={{
                borderColor: leaseDuration == null && 'red',
              }}
            />
          </FormGroup>
        </Col>
        {/* //!  Security deposit payable (during token)*/}
        {
          isVisible("securityDepositToken") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositToken">
                  Security deposit payable (during token)
                </Label>
              </strong>
              <Input
                type="number"
                value={securityDepositToken}
                id="securityDepositToken"
                name="securityDepositToken"
                //placeholder="Add security deposit payable (during token)"
                onChange={(e) => setSecurityDepositToken(e.target.value)}
                disabled={!isEditableByuser("securityDepositToken")}
                style={{
                  borderColor: securityDepositToken == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //!  Security deposit payable (during registration)*/}
        {
          isVisible("securityDepositRegistration") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositRegistration">
                  Security deposit payable (during registration)
                </Label>
              </strong>
              <Input
                type="number"
                value={securityDepositRegistration}
                id="securityDepositRegistration"
                name="securityDepositRegistration"
                //placeholder="Add security deposit payable (during registration)"
                onChange={(e) => setSecurityDepositRegistration(e.target.value)}
                disabled={!isEditableByuser("securityDepositRegistration")}
                style={{
                  borderColor: securityDepositRegistration == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //!  Security deposit payable (during handover)*/}
        {
          isVisible("securityDepositHandover") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositHandover">
                  Security deposit payable (during handover)
                </Label>
              </strong>
              <Input
                type="number"
                value={securityDepositHandover}
                id="securityDepositHandover"
                name="securityDepositHandover"
                //placeholder="Add Security deposit payable (during handover)"
                onChange={(e) => setSecurityDepositHandover(e.target.value)}
                disabled={!isEditableByuser("securityDepositHandover")}
                style={{
                  borderColor: securityDepositHandover == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //!  Security deposit payable (at rent start date)*/}
        {
          isVisible("securityDepositRentStart") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositRentStart">
                  Security deposit payable (at rent start date)
                </Label>
              </strong>
              <Input
                type="number"
                value={securityDepositRentStart}
                id="securityDepositRentStart"
                name="securityDepositRentStart"
                //placeholder="Add Security deposit payable (at rent start date)"
                onChange={(e) => setSecurityDepositRentStart(e.target.value)}
                disabled={!isEditableByuser("securityDepositRentStart")}
                style={{
                  borderColor: securityDepositRentStart == null && 'red',
                }}
              />
            </FormGroup>
          </Col>
        }
        {/* //!  Total security deposit*/}
        {
          isVisible("totalSecurityDeposit") && operationalModel?.value === "COCO" &&
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="totalSecurityDeposit">
                  Total security deposit
                </Label>
              </strong>
              <Input
                type="number"
                value={totalSecurityDeposit}
                id="totalSecurityDeposit"
                name="totalSecurityDeposit"
                // disabled
                //placeholder="Add total security deposit"
                // onChange={(e) => setTotalSecurityDeposit(e.target.value)}
                // disabled={!isEditableByuser("totalSecurityDeposit")}
                disabled
              />
            </FormGroup>
          </Col>
        }
      </Row>
      <Row>
        {/* //! Pictures/videos drive link */}
        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="picturesVideosDriveLink">
                Pictures/videos drive link {picturesVideosDriveLink && <ArrowUpRight style={{ cursor: "pointer" }} size={16} onClick={() => window.open(picturesVideosDriveLink)} />}
              </Label>
            </strong>
            <Input
              type="text"
              value={picturesVideosDriveLink}
              id="picturesVideosDriveLink"
              name="picturesVideosDriveLink"
              //placeholder="Add pictures/videos drive link"
              // onChange={(e) => setPicturesVideosDriveLink(e.target.value)}
              onChange={(e) => { isEditableByuser(e.target.name) && setPicturesVideosDriveLink(e.target.value) }}
              disabled={!isEditableByuser("picturesVideosDriveLink")}
              style={{
                borderColor: !picturesVideosDriveLink && 'red',
              }}
            />
          </FormGroup>
        </Col>
        {/*//! Floor plans */}
        {
          isVisible("floorPlan") &&
          <Col sm="6">
            <strong>
              <Label className="form-label" for="floorPlans">
                Floor plans
              </Label>
            </strong>
            <ImageModal
              imageList={floorPlans}
              setImageList={setFloorPlans}
              editData={floorPlansImageDummyState}
              uploadType={uploadFloorPlansImageType}
              canBeEdited={isEditableByuser("floorPlan")}
              fieldName={"Floor plan"}
            />
          </Col>
        }
        {/*//! CAD Drawings */}
        {
          isVisible("CADDrawing") &&
          <Col sm="6">
            <strong>
              <Label className="form-label" for="cadDrawings">
                CAD Drawings
              </Label>
            </strong>
            <ImageModal
              imageList={cadDrawings}
              setImageList={setCADDrawings}
              editData={CADDrawingsImageDummyState}
              uploadType={uploadCADDrawingsImageType}
              canBeEdited={isEditableByuser("CADDrawing")}
              fieldName={"CAD drawing"}
            />
          </Col>
        }

        {/*//! Title documents */}
        {
          isVisible("titleDocument") &&
          <Col sm="6">
            <strong>
              <Label className="form-label" for="titleDocuments">
                Title documents
              </Label>
            </strong>
            {/* <AttachmentComponent
              imageList={titleDocuments}
              setImageList={setTitleDocuments}
              editData={titleDocumentsImageDummyState}
              uploadType={uploadTitleDocumentsImageType}
              canBeEdited={isEditableByuser("titleDocument")}
            /> */}
            <ImageModal
              imageList={titleDocuments}
              setImageList={setTitleDocuments}
              editData={titleDocumentsImageDummyState}
              uploadType={uploadTitleDocumentsImageType}
              canBeEdited={isEditableByuser("titleDocument")}
              fieldName={"Title document"}
            />
          </Col>
        }
        {/*//! OC/CC certificate */}
        {
          isVisible("ocCCCertificate") &&
          <Col sm="6">
            <strong>
              <Label className="form-label" for="ocCCCertificate">
                OC/CC certificate
              </Label>
            </strong>
            {/* <AttachmentComponent
              imageList={ocCCCertificate}
              setImageList={setOCCCCertificate}
              editData={OCCCCertificateImageDummyState}
              uploadType={uploadOCCCCertificateImageType}
              canBeEdited={isEditableByuser("ocCCCertificate")}
            /> */}
            <ImageModal
              imageList={ocCCCertificate}
              setImageList={setOCCCCertificate}
              editData={OCCCCertificateImageDummyState}
              uploadType={uploadOCCCCertificateImageType}
              canBeEdited={isEditableByuser("ocCCCertificate")}
              fieldName={"OC/CC certificate"}
            />
          </Col>
        }
        {/*//! Title search report  */}
        {
          isVisible("titleSearchReport") &&
          <Col sm="6">
            <strong>
              <Label className="form-label" for="titleSearchReport">
                Title search report
              </Label>
            </strong>
            {/* <AttachmentComponent
              imageList={titleSearchReport}
              setImageList={setTitleSearchReport}
              editData={TitleSearchReportImageDummyState}
              uploadType={uploadTitleSearchReportImageType}
              canBeEdited={isEditableByuser("titleSearchReportDocument")}
            /> */}
            <ImageModal
              imageList={titleSearchReport}
              setImageList={setTitleSearchReport}
              editData={TitleSearchReportImageDummyState}
              uploadType={uploadTitleSearchReportImageType}
              canBeEdited={isEditableByuser("titleSearchReportDocument")}
              fieldName={"Title search report"}
            />
          </Col>
        }
        {/*//! Property pre-signing audit report  */}
        {
          isVisible("propertyPreSigningAuditReport") &&
          <Col sm="6">
            <strong>
              <Label className="form-label" for="propertyPreSigningAuditReport">
                Property pre-signing audit report
              </Label>
            </strong>
            {/* <AttachmentComponent
              imageList={propertyPreSigningAuditReport}
              setImageList={setPropertyPreSigningAuditReport}
              editData={PropertyPreSigningAuditReportImageDummyState}
              uploadType={uploadPropertyPreSigningAuditReportImageType}
              canBeEdited={isEditableByuser("propertyPreSigningAuditReport")}
            /> */}
            <ImageModal
              imageList={propertyPreSigningAuditReport}
              setImageList={setPropertyPreSigningAuditReport}
              editData={PropertyPreSigningAuditReportImageDummyState}
              uploadType={uploadPropertyPreSigningAuditReportImageType}
              canBeEdited={isEditableByuser("propertyPreSigningAuditReport")}
              fieldName={"Property pre-signing audit report"}
            />
          </Col>
        }

        {/*//! DD Report*/}
        {/* <Col sm="3">
            <strong>
              <Label className="form-label" for="ddReport">
                DD Report*
              </Label>
            </strong>
            <AttachmentComponent
              imageList={ddReport}
              setImageList={setDDReport}
              editData={ddReportImageDummyState}
              uploadType={uploadDDReportImageType}
            />
          </Col> */}
        {/*//! Audit Report*/}
        {/* <Col sm="3">
            <strong>
              <Label className="form-label" for="auditReport">
                Audit Report*
              </Label>
            </strong>
            <AttachmentComponent
              imageList={auditReport}
              setImageList={setAuditReport}
              editData={auditReportImageDummyState}
              uploadType={uploadAuditReportImageType}
            />
          </Col> */}
      </Row>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelStage" >
                Hostel stage
              </Label>
            </strong>
            <Select
              isClearable={false}
              isSearchable={true}
              id="hostelStage"
              name="hostelStage"
              options={HostelStageOptions}
              value={hostelStage}
              onChange={(value) => { setHostelStage(value) }}
              classNamePrefix="select"
              placeholder=""
              isDisabled={!isEditableByuser("hostelStage")}
              styles={{
                menu: (base) => ({
                  ...base,
                  zIndex: 1050,
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: !hostelStage ? 'red' : provided.borderColor,
                }),
              }}
            />

          </FormGroup>
        </Col>
        <Col>
          <SaveDiscardBtnGroup />
        </Col>
      </Row>

    </form >
  );
};

export default DealBasics;
