import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
} from "reactstrap";
import Select from "react-select";
import { useUploadPLStatements } from "../../api/P&LStatement/Mutation";
import { useGetPaginatedPLStatements } from "../../api/P&LStatement/Queries";
import { useHostelList } from "../../api/dataSource";
import { selectThemeColors } from "@utils";
import toaster from "@components/common/Toaster";
import TablePagination from "../../@core/components/common/TablePagination";
import PLStatementTable from "./PLStatamentTable";
import PLStatementOverviewModal from "./PLStatementOverviewModal";
import { useExportPLStatement } from "../../api/P&LStatement/Mutation";
import DynamicFilterModal from "./DynamicFilterModal";
import { FaFilter } from "react-icons/fa";
import { Tooltip } from "reactstrap";

const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

const years = Array.from({ length: 10 }, (_, i) => {
  const year = new Date().getFullYear() - i;
  return { value: year, label: year.toString() };
});

const operationalModelDropdown = [
  { label: "FOFO", value: "FOFO" },
  { label: "FOCO & OMC", value: "FOCO & OMC" },
  { label: "COCO", value: "COCO" },
];

const opMarginOptions = [
  { label: "Positive", value: "positive" },
  { label: "Negative", value: "negative" },
  { label: "Zero", value: "zero" },
];

const PLStatementComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadPLStatementsHandler, { loading, error, data: uploadData }] =
    useUploadPLStatements();

  const [page, setPage] = useState(1);
  const limit = 10;
  const [id, setId] = useState("");
  const [editData, setEditData] = useState(null);
  const [openTableModal, setOpenTableModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [hostelIdArray, setHostelIdArray] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const { data: allHostelsData } = useHostelList();
  const [monthFilter, setMonthFilter] = useState(null);
  const [yearFilter, setYearFilter] = useState(null);

  const [filterFields, setFilterFields] = useState({
    hostelIds: JSON.parse(localStorage.getItem("hostels")),
  });

  const [operationalModel, setOperationalModel] = useState([]);
  const [overviewModalOpen, setOverviewModalOpen] = useState(false);
  const handleOverviewOpen = (id) => {
    const dataItem = tableData.find((item) => item._id === id);
    setEditData(dataItem || null);
    setOverviewModalOpen(true);
  };
  const [opMarginFilter, setOpMarginFilter] = useState(null);
  const [exportPLStatementHandler, { data: exportedPLData }] =
    useExportPLStatement();

  const exportInputFilter = {
    filterFields,
  };

  const [resetModalState, setResetModalState] = useState(false);
  const [appliedDynamicFilters, setAppliedDynamicFilters] = useState(0);
  const [isDynamicFilterModalOpen, setIsDynamicFilterModalOpen] =
    useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [dynamicFilters, setDynamicFilters] = useState([]);
  const handleApplyDynamicFilters = (filter) => {
    setDynamicFilters([...dynamicFilters, filter]);
    setAppliedDynamicFilters(appliedDynamicFilters + 1);
  };
  const [userDepartment, setUserDepartment] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [assignedHostels, setAssignedHostels] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [areaManager, setAreaManager] = useState("");
  const [financialYearFilter, setFinancialYearFilter] = useState([]);

  const financialYearOptions = [
    // { value: "2025-2026", label: "FY 2025-26" },
    { value: "2024-2025", label: "FY 2024-25" },
    { value: "2023-2024", label: "FY 2023-24" },
    { value: "2022-2023", label: "FY 2022-23" },
    { value: "2021-2022", label: "FY 2021-22" },
  ];

  useEffect(() => {
    const storedDepartment = localStorage.getItem("department");
    const storedRole = localStorage.getItem("role");
    const storedHostels = JSON.parse(localStorage.getItem("hostels"));

    setUserDepartment(storedDepartment);
    setUserRole(storedRole);
    setAssignedHostels(storedHostels || []);
  }, []);

  useEffect(() => {
    if (exportedPLData?.exportPLStatement?.data) {
      if (exportedPLData?.exportPLStatement?.data.length > 0) {
        const byteArray = new Uint8Array(
          exportedPLData?.exportPLStatement?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `P&L_Export.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [exportedPLData]);

  const { data, refetch } = useGetPaginatedPLStatements(
    limit,
    page,
    filterFields
  );

  const handleSearch = () => {
    setPage(1);
    setFilterFields({
      ...filterFields,
      hostelIds:
        hostelIdArray?.length > 0
          ? hostelIdArray.map((hostel) => hostel.value)
          : assignedHostels,
      month: monthFilter?.map((month) => month.value),
      year: yearFilter?.map((year) => year.value),
      areaManager: areaManager?.trim(),
      operationalModel: operationalModel?.map((model) => model.value),
      opMarginFilter: opMarginFilter?.value,
      dynamicFilters,
      sortField,
      sortOrder,
      financialYear: financialYearFilter?.map((fy) => fy.value),
    });
  };

  const handleReset = () => {
    setFilterFields({
      hostelIds: JSON.parse(localStorage.getItem("hostels")),
    });
    setHostelIdArray([]);

    setMonthFilter([]);
    setYearFilter([]);
    setAreaManager("");
    setOperationalModel([]);
    setOpMarginFilter(null);
    setDynamicFilters([]);
    setAppliedDynamicFilters(0);
    setResetModalState((prev) => !prev);
    setSortField(null);
    setSortOrder(null);
    setFinancialYearFilter([]);
  };

  const sortingOptions = [
    { label: "Number of Beds", value: "No. of beds" },
    { label: "No. of rooms", value: "No. of rooms" },
    { label: "ADR", value: "ADR" },
    { label: "Total Revenue", value: "Total Revenue" },
    { label: "F&B Revenue", value: "F&B Revenue" },
    { label: "Ancillary Revenue", value: "Ancillary Revenue" },
    { label: "Total OPEX", value: "Total OPEX" },
    {
      label: "Overall Op. Margin (in INR)",
      value: "Overall Op. Margin (in INR)",
    },

    {
      label: "Analysis (Operational Margin %)",
      value: "Analysis (Operational Margin %)",
    },

    {
      label: "Hostel staff salary/(bed sales)",
      value: "Hostel staff salary/(bed sales)",
    },
    { label: "Rev/Rental multiple", value: "Rev/Rental multiple" },
    { label: "Occupancy %", value: "Occ%" },
    { label: "Hostel Margin %", value: "Hostel margin (in %)" },
  ];

  const sortOrderOptions = [
    { label: "Ascending", value: "asc" },
    { label: "Descending", value: "desc" },
  ];

  useEffect(() => {
    if (allHostelsData?.getHostelList?.length) {
      let filteredHostels = allHostelsData.getHostelList;

      if (assignedHostels && assignedHostels.length > 0) {
        filteredHostels = filteredHostels.filter((hostel) =>
          assignedHostels.includes(hostel._id)
        );
      }

      const HostelsList = filteredHostels.map((item) => ({
        label: item?.name,
        value: item?._id,
      }));

      setHostelsList(HostelsList);
      console.log(HostelsList, "Filtered HostelsList");
    }
  }, [allHostelsData?.getHostelList, assignedHostels]);

  useEffect(() => {
    if (data?.getPaginatedPLStatement?.data) {
      setTableData(data?.getPaginatedPLStatement?.data);
    }
  }, [data]);

  const totalPages = Math.ceil(
    (data?.getPaginatedPLStatement?.count || 0) / limit
  );

  useEffect(() => {
    refetch();
  }, [page, filterFields, refetch]);

  const clearForm = () => {
    setSelectedMonth(null);
    setSelectedYear(null);
    setFile(null);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (modalOpen) clearForm();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (uploadData?.uploadPLStatements?.success) {
      toaster("success", `${uploadData.uploadPLStatements.message}`);
      refetch();
      clearForm();
      toggleModal();
    } else if (uploadData?.uploadPLStatements?.success === false) {
      toaster("error", "Upload failed: No success in response.");
    }
  }, [uploadData, refetch]);

  const handleUpload = async () => {
    if (!selectedMonth || !selectedYear || !file) {
      toaster("error", "All fields are required. Please fill them.");
      return;
    }

    const plInput = {
      month: selectedMonth.value,
      year: selectedYear.value,
      file,
    };

    try {
      await uploadPLStatementsHandler(plInput);
    } catch (err) {
      toaster("error", `Upload failed: ${err.message}`);
    }
  };

  return (
    <div>
      {/* Upload Button */}
      <Row>
        <DynamicFilterModal
          isOpen={isDynamicFilterModalOpen}
          toggle={() => setIsDynamicFilterModalOpen(!isDynamicFilterModalOpen)}
          applyFilters={handleApplyDynamicFilters}
          resetModalState={resetModalState}
        />

        <Col sm="3">
          <FormGroup>
            <Label for="hostel">
              <strong>Hostel Name</strong>
            </Label>
            <Select
              id="hostel"
              // isClearable
              // isSearchable
              isMulti
              theme={selectThemeColors}
              options={HostelsList}
              value={hostelIdArray}
              onChange={setHostelIdArray}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="monthFilter">
              <strong>Month</strong>
            </Label>
            <Select
              id="monthFilter"
              isMulti
              theme={selectThemeColors}
              options={months}
              value={monthFilter}
              onChange={setMonthFilter}
              placeholder="Select Month"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="yearFilter">
              <strong>Year</strong>
            </Label>
            <Select
              id="yearFilter"
              isMulti
              theme={selectThemeColors}
              options={years}
              value={yearFilter}
              onChange={setYearFilter}
              placeholder="Select Year"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="operationalModel">
              <strong>Operational Model</strong>
            </Label>
            <Select
              id="operationalModel"
              isMulti
              theme={selectThemeColors}
              value={operationalModel}
              options={operationalModelDropdown}
              onChange={setOperationalModel}
              placeholder="Operational Model"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="yearFilter">
              <strong>Area Manager</strong>
            </Label>
            <Input
              id="areaManager"
              type="text"
              value={areaManager}
              onChange={(e) => setAreaManager(e.target.value)}
              className="w-full border rounded px-2 py-1"
              placeholder="Enter Area Manager Name"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="operationalModel">
              <strong>Operational Margin</strong>
            </Label>
            <Select
              options={opMarginOptions}
              value={opMarginFilter}
              onChange={setOpMarginFilter}
              placeholder="Filter by Op. Margin"
              isClearable
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="financialYearFilter">
              <strong>Financial Year</strong>
            </Label>
            <Select
              id="financialYearFilter"
              isMulti
              theme={selectThemeColors}
              options={financialYearOptions}
              value={financialYearFilter}
              onChange={setFinancialYearFilter}
              placeholder="Select Financial Year"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="sortField">
              <strong>Sort By</strong>
            </Label>
            <Select
              key={sortField}
              id="sortField"
              isClearable
              isSearchable
              theme={selectThemeColors}
              options={sortingOptions}
              value={
                sortingOptions.find((option) => option.value === sortField) ||
                null
              }
              onChange={(selectedOption) => {
                setSortField(selectedOption?.value || null);
                setSortOrder(null);
              }}
              placeholder="Select Field"
            />
          </FormGroup>
        </Col>

        {sortField && (
          <Col sm="3">
            <FormGroup>
              <Label for="sortOrder">
                <strong>Sort Order</strong>
              </Label>
              <Select
                id="sortOrder"
                isClearable
                isSearchable
                theme={selectThemeColors}
                options={sortOrderOptions}
                value={sortOrderOptions.find(
                  (option) => option.value === sortOrder
                )}
                onChange={(selectedOption) =>
                  setSortOrder(selectedOption?.value)
                }
                placeholder="Select Order"
                isDisabled={!sortField}
              />
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row className="d-flex justify-content-end mt-2 mb-2">
        <Col sm="auto" className="d-flex align-items-center">
          <div
            id="dynamicFilterTooltip"
            style={{ cursor: "pointer", marginLeft: "10px" }}
            onClick={() => setIsDynamicFilterModalOpen(true)}
          >
            <FaFilter size={30} color="#6c757d" />
            {appliedDynamicFilters > 0 && (
              <span
                style={{
                  backgroundColor: "#28a745",
                  color: "white",
                  borderRadius: "50%",
                  padding: "2px 6px",
                  fontSize: "12px",
                  marginLeft: "5px",
                }}
              >
                {appliedDynamicFilters}
              </span>
            )}
          </div>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target="dynamicFilterTooltip"
            toggle={toggleTooltip}
          >
            Add Dynamic Filter
          </Tooltip>
        </Col>

        <Col sm="auto">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
              marginLeft: "10px",
            }}
            color="white"
            onClick={handleSearch}
          >
            Search
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginLeft: "10px",
              borderRadius: "5px",
              padding: "10px 40px",
            }}
            color="danger"
            onClick={handleReset}
          >
            Reset
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginLeft: "10px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="success"
            appearance="success"
            type="button"
            onClick={() => exportPLStatementHandler(exportInputFilter)}
          >
            Export
          </Button>

          {userDepartment === "Finance" && (
            <Button
              color="info"
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "10px 40px",
              }}
              onClick={toggleModal}
            >
              Upload P&L Statement
            </Button>
          )}
        </Col>
      </Row>

      {/* Card with Header */}
      <Card>
        <CardHeader>
          <h4>P&L Statement</h4>
        </CardHeader>
      </Card>

      {/* Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Upload P&L Statement</ModalHeader>
        <ModalBody>
          <Form>
            {/* Month Dropdown */}
            <FormGroup>
              <Label for="month">Select Month</Label>
              <Select
                id="month"
                theme={selectThemeColors}
                options={months}
                value={selectedMonth}
                onChange={setSelectedMonth}
                placeholder="Choose a month"
              />
            </FormGroup>

            {/* Year Dropdown */}
            <FormGroup>
              <Label for="year">Select Year</Label>
              <Select
                id="year"
                theme={selectThemeColors}
                options={years}
                value={selectedYear}
                onChange={setSelectedYear}
                placeholder="Choose a year"
              />
            </FormGroup>

            {/* File Upload */}
            <FormGroup>
              <Label for="file">Upload File</Label>
              <Input type="file" id="file" onChange={handleFileChange} />
            </FormGroup>

            {/* Submit Button */}
            <FormGroup>
              <Button color="primary" onClick={handleUpload} disabled={loading}>
                {loading ? "Uploading..." : "Upload"}
              </Button>
            </FormGroup>

            {/* Error Handling */}
            {error && <div className="text-danger mt-2">{error.message}</div>}
          </Form>
        </ModalBody>
      </Modal>

      <PLStatementTable
        openTableModal={() => setOpenTableModal(!openTableModal)}
        setId={setId}
        data={tableData}
        openOverviewModal={handleOverviewOpen}
        editData={editData}
        refetch={refetch}
        filterFields={filterFields}
        userDepartment={userDepartment}
        userRole={userRole}
      />

      {tableData.length > 0 && (
        <TablePagination setPage={setPage} page={page} totalPage={totalPages} />
      )}

      <PLStatementOverviewModal
        isOpen={overviewModalOpen}
        toggle={() => setOverviewModalOpen(!overviewModalOpen)}
        editData={editData}
        refetch={refetch}
        userDepartment={userDepartment}
        userRole={userRole}
      />
    </div>
  );
};

export default PLStatementComponent;
