import { DollarSign, Circle } from "react-feather";
const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Finance Reports")
  ? [
      {
        id: "financeReport",
        title: "Finance Reports",
        icon: <DollarSign size={20} />,
        badge: "light-warning",
        children: [
          {
            id: "plStatement",
            title: "P&L Statement Finance",
            icon: <Circle />,
            navLink: "/financeReport/plStatement",
          },
        ],
      },
    ]
  : [];
