import { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle } from "reactstrap";
import { Table } from "reactstrap";
import { FaArrowUp, FaArrowDown, FaDotCircle } from "react-icons/fa";

const OccupancyTrackerTable = ({ data, dates, refetch }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const timeSlots = Array.from({ length: 19 }, (_, i) => i + 6);

  const getOccupancyCell = (item, currentTime) => {
    const current = item?.Occupancy.find((occup) => occup?.time === currentTime);
    const past = item?.Occupancy.find((occup) => occup?.time === currentTime - 1);

    return (
      <td>
        <span style={{ display: "flex", gap: "10px" }}>
          {current?.percentOccupancy}
          {past && current?.percentOccupancy > past?.percentOccupancy ? (
            <FaArrowUp color="green" />
          ) : past && current?.percentOccupancy < past?.percentOccupancy ? (
            <FaArrowDown color="red" />
          ) 
          : past && current?.percentOccupancy === past?.percentOccupancy ? (
            <FaDotCircle color="grey" />
          ) : null}
        </span>
      </td>
    );
  };

  const getValueCell = (item, currentTime, key) => (
    <td>{item?.Occupancy.find((occup) => occup?.time === currentTime)?.[key]?.toFixed()}</td>
  );

  const hours = [
    "6 am", "7 am", "8 am", "9 am", "10 am", "11 am", "12 pm",
    "01 pm", "02 pm", "03 pm", "04 pm", "05 pm", "06 pm",
    "07 pm", "08 pm", "09 pm", "10 pm", "11 pm", "Night Audit"
  ];
  
  const columns = ["occup", "adr", "revenue"];

  return (
    <div>
      <div style={{ overflowX: "auto", overflowY: "auto" }}>
        <Card>
          <CardHeader>
            <CardTitle>Daily Occupancy Tracker ( Percentage )</CardTitle>
          </CardHeader>

        <div
          style={{
            overflowX: "auto",
            maxHeight: "800px",
            marginBottom: "20px",
          }}
        >
          <Table stripped bordered>
            <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 2 }}>
              <tr>
                <th>Hostel Name</th>
                <th>Date</th>
                {hours.map((hour) => (
                  <th key={hour} colSpan={columns.length}>{hour}</th>
                ))}
              </tr>

              <tr>
                <th></th>
                <th></th>
                {hours.flatMap((hour) =>
                  columns.map((col) => <th key={`${hour}-${col}`}>{col}</th>)
                )}
              </tr>
            </thead>
            <tbody>
              {dates.length === 1 ? (
                tableData?.map((item) => {
                  const date = dates[0]; 
                  return (
                    <tr>
                      <td style={{ position: "sticky", minWidth: "200px", left: 0, zIndex: 1, backdropFilter: "blur(500px)" }}>
                        <h5>{item?.hostelName}</h5>
                      </td>
                      <td style={{ position: "sticky", minWidth: "150px", left: '200px', zIndex: 1, backdropFilter: "blur(500px)" }}>
                        <h5>{date}</h5>
                      </td>
                      {timeSlots.map((time) => (
                      <>
                        {getOccupancyCell(item, time)}
                        {getValueCell(item, time, "adr")}
                        {getValueCell(item, time, "revenue")}
                      </>
                      ))}
                    </tr>
                  );
                })
              ) : (
                tableData?.map((item) =>
                  dates.map((date) => {
                    const filteredData = item.Occupancy.filter(
                      (occup) => occup.dateRetrieved && occup.dateRetrieved.split("T")[0] === date
                    );
                    return (
                      <tr>
                        <td style={{ position: "sticky", minWidth: "200px", left: 0, zIndex: 1, backdropFilter: "blur(500px)" }}>
                          <h5>{item?.hostelName}</h5>
                        </td>
                        <td style={{ position: "sticky", minWidth: "150px", left: '200px', zIndex: 1, backdropFilter: "blur(500px)" }}>
                          <h5>{date}</h5>
                        </td>
                        {timeSlots.map((time) => {
                          const currentOccupancy = filteredData.find((occup) => occup.time === time);
                          return (
                            <>
                              {getOccupancyCell({ Occupancy: filteredData }, time)}
                              <td>{currentOccupancy ? currentOccupancy.adr.toFixed(2) : ""}</td>
                              <td>{currentOccupancy ? currentOccupancy.revenue.toFixed(2) : ""}</td>
                            </>
                          );
                        })}
                      </tr>
                    );
                  })
                )
              )}
            </tbody>
          </Table>
        </div>
        </Card>
      </div>
    </div>
  );
};

export default OccupancyTrackerTable;
