import React, { useEffect, useState } from "react";
import AddEditModal from "./AddEditModal";
import EmployeeProfileTable from "./EmployeeProfileTable";
import TablePagination from "@components/common/TablePagination";
import { useHistory } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, Input, FormGroup, Label, Button, Form } from "reactstrap";
import Select from "react-select";
import {
  useGetPaginatedEmployeeProfileList,
  useHostelList,
  useEmployeeProfileList,
  useUploadXlsxFileforEmployeeProfile,
} from "../../../api/dataSource";
import { useGetEmployeeProfileReport } from "../../../api/HR/Mutation";
import { dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import { CSVLink } from "react-csv";
import { selectThemeColors } from "@utils";
import toaster from "@components/common/Toaster";

const tableHeaderDataObject = [
  { label: "Hostel name", key: "hostel.name" },
  { label: "Department", key: "department" },
  { label: "Sub department", key: "subDepartment" },
  { label: "Designation", key: "designation" },
  { label: "Prefix", key: "prefix" },
  { label: "Full name", key: "fullName" },
  { label: "Spouse full name", key: "spouseFullName" },
  { label: "Father full name", key: "fathersFullName" },
  { label: "Employee code", key: "empCode" },
  { label: "Date of birth", key: "dob" },
  { label: "Gender", key: "gender" },
  { label: "Blood group", key: "bloodGroup" },
  { label: "Marital status", key: "maritalStatus" },
  { label: "Personal email", key: "personalEmail" },
  { label: "Present address line 1", key: "presentAddress.addressLine1" },
  { label: "Present address line 2", key: "presentAddress.addressLine2" },
  { label: "Present city", key: "presentAddress.city" },
  { label: "Present state", key: "presentAddress.state" },
  { label: "Present postal code", key: "presentAddress.postalCode" },
  {
    label: "Present country",
    key: "presentAddress.country",
  },
  { label: "Permanent address line 1", key: "permanentAddress.addressLine1" },
  { label: "Permanent address line 2", key: "permanentAddress.addressLine2" },
  { label: "Permanent city", key: "permanentAddress.city" },
  { label: "Permanent state", key: "permanentAddress.state" },
  { label: "Permanent postal code", key: "permanentAddress.postalCode" },
  {
    label: "Permanent country",
    key: "permanentAddress.country",
  },
  { label: "Mobile number", key: "mobileNo" },
  { label: "Skilled type", key: "skilledType" },
  { label: "Date of joining", key: "doj" },
  { label: "Employee type", key: "empType" },
  { label: "Agency name", key: "agencyName" },
  { label: "Employee status", key: "empStatus" },
  { label: "UAN", key: "uan" },
  { label: "ESIC", key: "esic" },
  { label: "Aadhaar", key: "aadhaar" },
  { label: "Pan", key: "pan" },
  { label: "Bank name", key: "bankDetails.bankName" },
  { label: "Branch name", key: "bankDetails.branchName" },
  { label: "Account number", key: "bankDetails.accountNumber" },
  { label: "IFSC code", key: "bankDetails.ifscCode" },
  { label: "Emergency name", key: "emergencyName" },
  { label: "Emergency number", key: "emergencyNo" },
  { label: "Date of exit", key: "dateOfExit" },
  { label: "Exit Reason", key: "exitReason" },
  {
    label: "Resigned Status",
    key: "resignedStatus",
  },
  { label: "Transfer date", key: "transferDate" },
  { label: "Confirmation date", key: "confirmationDate" },
  { label: "Current CTC", key: "currentCTC" },
  { label: "New CTC", key: "newCTC" },
  { label: "New designation", key: "newDesignation" },
  { label: "Appraised date", key: "appraisedDate" },
  { label: "Appraisal date", key: "appraisalDate" },
  { label: "Next appraisal date", key: "nextAppraisalDate" },
  { label: "Performance grade", key: "performanceGrade" },
  { label: "Remarks", key: "remarks" },
];

const EmployeeProfile = () => {
  const [hostel, setHostel] = useState(null);
  const [subDepartment, setSubDepartment] = useState(null);
  const [fullName, setFullName] = useState("");
  const [empCode, setEmpCode] = useState("");
  const [empType, setEmpType] = useState(null);
  const [empStatus, setEmpStatus] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [startDateOfExit, setStartDateOfExit] = useState(null);
  const [endDateOfExit, setEndDateOfExit] = useState(null);
  const [startDateOfJoining, setStartDateOfJoining] = useState(null);
  const [endDateOfJoining, setEndDateOfJoining] = useState(null);
  const [HostelsList, setHostelsList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [trainingStatus, setTrainingStatus] = useState(null);
  const userRole = localStorage.getItem("role");

  const [filteredData, setFilteredData] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });
  const [exportTableData, setExportTableData] = useState([]);

  const { data, refetch } = useGetPaginatedEmployeeProfileList(
    limit,
    page,
    filteredData
  );
  const { data: HostelsData } = useHostelList();
  // const { data: exportData } = useEmployeeProfileList();
  const [getEmployeeProfileReportDataHandler, { data: exportData }] =
    useGetEmployeeProfileReport();

  const [uploadXlsxFileHandler, { data: uploadXlsxData }] =
    useUploadXlsxFileforEmployeeProfile();
  const history = useHistory();

  const exportInputFilter = {
    filteredData,
  };

  const dateFormat = "d/m/Y";
  const options = {
    dateFormat: dateFormat,
  };

  const SubDepartmentList = [
    { label: "Front Office", value: "Front Office" },
    { label: "Housekeeping", value: "Housekeeping" },
    { label: "Kitchen", value: "Kitchen" },
    { label: "Security", value: "Security" },
    { label: "Community", value: "Community" },
    { label: "Repair & Maintenance", value: "Repair & Maintenance" },
    { label: "Other", value: "Other" },
  ];

  const EmploymentStatusList = [
    { label: "Probation", value: "Probation" },
    { label: "Confirmed", value: "Confirmed" },
    { label: "Absconding", value: "Absconding" },
    { label: "Resigned", value: "Resigned" },
    { label: "Termination", value: "Termination" },
  ];

  const EmploymentTypeList = [
    { label: "Agency", value: "Agency" },
    { label: "Company", value: "Company" },
  ];

  const DesignationList = [
    { label: "Senior General Manager", value: "Senior General Manager" },
    { label: "General Manager", value: "General Manager" },
    {
      label: "Assistant General Manager",
      value: "Assistant General Manager",
    },
    { label: "Front Desk Manager", value: "Front Desk Manager" },
    { label: "Front Desk Executive", value: "Front Desk Executive" },
    { label: "Front Desk Associate", value: "Front Desk Associate" },
    { label: "Cafe Manager", value: "Cafe Manager" },
    { label: "Assistant Cafe Manager", value: "Assistant Cafe Manager" },
    { label: "Cafe Executive", value: "Cafe Executive" },
    { label: "Head Chef", value: "Head Chef" },
    { label: "Chef", value: "Chef" },
    { label: "Bartender", value: "Bartender" },
    { label: "Helper", value: "Helper" },
    { label: "Server", value: "Server" },
    { label: "Utility (UT)", value: "Utility (UT)" },
    { label: "HK Supervisor", value: "HK Supervisor" },
    { label: "HK Executive", value: "HK Executive" },
    { label: "HK Associate", value: "HK Associate" },
    { label: "Multi Skilled Technician", value: "Multi Skilled Technician" },
    {
      label: "Multi Skilled Technician Supervisor",
      value: "Multi Skilled Technician Supervisor",
    },
    { label: "Security Guard", value: "Security Guard" },
    { label: "Intern", value: "Intern" },
    { label: "Others", value: "Others" },
    { label: "Community Manager", value: "Community Manager" },
  ];

  const traningStatusOptions = [
    { label: "Pending", value: "Pending" },
    { label: "Completed", value: "Completed" },
  ];
  const count = data?.getPaginatedEmployeeProfileList?.count;

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    const allowedHostel = JSON.parse(localStorage.getItem("hostels"));

    if (HostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostel !== null && allowedHostel?.length !== 0
          ? allowedHostel?.map((hostelId) => {
            return HostelsData?.getHostelList?.find(
              (item) => item?._id === hostelId
            );
          })
          : HostelsData?.getHostelList;

      let hostelList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(hostelList);
    }
  }, [HostelsData]);

  useEffect(() => {
    if (data?.getPaginatedEmployeeProfileList?.data.length > 0) {
      setTableData(data?.getPaginatedEmployeeProfileList?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(
      Math.ceil(data?.getPaginatedEmployeeProfileList?.count / limit)
    );
  }, [
    data?.getPaginatedEmployeeProfileList?.count,
    data?.getPaginatedEmployeeProfileList?.data,
    limit,
  ]);

  useEffect(() => {
    if (id && data?.getPaginatedEmployeeProfileList?.data.length) {
      let editData = data?.getPaginatedEmployeeProfileList?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedEmployeeProfileList?.data, id]);

  // useEffect(() => {
  //   if (exportData?.getEmployeeProfileList?.length > 0) {
  //     setExportTableData(exportData?.getEmployeeProfileList);
  //   } else {
  //     setExportTableData([]);
  //   }
  // }, [exportData]);

  useEffect(() => {
    if (exportData?.getEmployeeProfileReport?.data) {
      if (exportData?.getEmployeeProfileReport?.data.length > 0) {
        const byteArray = new Uint8Array(
          exportData?.getEmployeeProfileReport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Employee_Profile_Export.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [exportData]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/hr/edit/employeeProfile/${id}`);
    } else {
      setId(id);
    }
  };

  const handleCreateUpdateEmployeeProfile = (id) => {
    // history.push(`/hr/edit/employeeProfile/${id}`, "_blank");
    window.open(`/hr/edit/employeeProfile/${id}`, '_blank');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      hostel: hostel?.map((item) => item.value),
      subDepartment: subDepartment?.map((item) => item.value).toString(),
      fullName,
      empCode,
      empStatus: empStatus?.map((item) => item.value).toString(),
      empType: empType?.map((item) => item.value).toString(),
      designation: designation?.map((item) => item.value).toString(),
      startDateOfExit,
      endDateOfExit,
      startDateOfJoining,
      endDateOfJoining,
      trainingStatus: trainingStatus?.value,
    };
    setFilteredData(data);
    setPage(1);
  };

  const handleReset = () => {
    try {
      setHostel(null);
      setSubDepartment(null);
      setFullName("");
      setEmpCode("");
      setEmpStatus(null);
      setEmpType(null);
      setDesignation(null);
      setEndDateOfExit(null);
      setEndDateOfJoining(null);
      setFilteredData(null);
      setFilteredData({
        hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
      });
      setStartDateOfExit(null);
      setEndDateOfExit(null);
      setStartDateOfJoining(null);
      setEndDateOfJoining(null);
      setTrainingStatus(null);
      setPage(1);
    } catch (error) {
      console.error(error);
    }
  };

  const employeeXlsxFile = (e) => {
    uploadXlsxFileHandler(e[0]);
  };
  useEffect(() => {
    if (uploadXlsxData) {
      refetch();
      if (uploadXlsxData?.uploadXlsxFileForEmployeeProfile?.status) {
        toaster(
          "success",
          `${uploadXlsxData?.uploadXlsxFileForEmployeeProfile?.message}`
        );
      }
      // else {
      //   toaster(
      //     "error",
      //     `${uploadXlsxData?.uploadXlsxFileForEmployeeProfile?.message}`
      //   );
      // }
    }
  }, [uploadXlsxData]);

  return (
    localStorage.getItem("modules")?.includes("Employee Profile") && (
      <div>
        <Form onSubmit={handleSubmit}>
          <Row>
            {/* // ! Hostel */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="hostel">
                    Hostel
                  </Label>
                </strong>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  theme={selectThemeColors}
                  isMulti
                  id="hostel"
                  name="hostel"
                  value={hostel}
                  options={HostelsList}
                  onChange={(value) => {
                    setHostel(value);
                  }}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            {/*// ! subDepartment */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="subDepartment">
                    Sub department
                  </Label>
                </strong>
                <Select
                  isSearchable={true}
                  isClearable={false}
                  theme={selectThemeColors}
                  isMulti
                  id="subDepartment"
                  name="subDepartment"
                  value={subDepartment}
                  options={SubDepartmentList}
                  onChange={(value) => setSubDepartment(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            {/*// ! fullName */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="fullName">
                    Full name
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={fullName}
                  id="fullName"
                  name="fullName"
                  placeholder="Add full name"
                  onChange={(e) => setFullName(e.target.value)}
                />
              </FormGroup>
            </Col>

            {/*// ! empCode */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="empCode">
                    Employee code
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={empCode}
                  id="empCode"
                  name="empCode"
                  placeholder="Add emp. code"
                  onChange={(e) => setEmpCode(e.target.value)}
                />
              </FormGroup>
            </Col>

            {/*// ! empStatus */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="empStatus">
                    Employment status
                  </Label>
                </strong>
                <Select
                  isSearchable={true}
                  isClearable={false}
                  theme={selectThemeColors}
                  isMulti
                  id="empStatus"
                  name="empStatus"
                  value={empStatus}
                  options={EmploymentStatusList}
                  onChange={(value) => setEmpStatus(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            {/*// ! empType */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="empType">
                    Employment type
                  </Label>
                </strong>
                <Select
                  isSearchable={true}
                  isClearable={false}
                  theme={selectThemeColors}
                  isMulti
                  id="empType"
                  name="empType"
                  value={empType}
                  options={EmploymentTypeList}
                  onChange={(value) => setEmpType(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            {/*// ! designation */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="designation">
                    Designation
                  </Label>
                </strong>
                <Select
                  isSearchable={true}
                  isClearable={false}
                  theme={selectThemeColors}
                  isMulti
                  id="designation"
                  name="designation"
                  value={designation}
                  options={DesignationList}
                  onChange={(value) => setDesignation(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>

            {/* // ! doj */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="startDateOfJoining">
                    Date of joining range
                  </Label>
                </strong>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  id="doj"
                  placeholder="Select joining date range"
                  options={{
                    mode: "range",
                    // ...options,
                  }}
                  value={[startDateOfJoining, endDateOfJoining]}
                  onChange={(date) => {
                    setStartDateOfJoining(dateFormatter(date[0]));
                    setEndDateOfJoining(dateFormatter(date[1]));
                  }}
                />
              </FormGroup>
            </Col>

            {/* // ! dateOfExit */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="startDateOfExit">
                    Date of exit range
                  </Label>
                </strong>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  id="dateOfExit"
                  placeholder="Select exit date range"
                  options={{
                    mode: "range",
                    // ...options,
                  }}
                  value={[startDateOfExit, endDateOfExit]}
                  onChange={(date) => {
                    setStartDateOfExit(dateFormatter(date[0]));
                    setEndDateOfExit(dateFormatter(date[1]));
                  }}
                />
              </FormGroup>
            </Col>

            <Col sm="3">
              <strong>
                {" "}
                <label htmlFor="traningStatus">Training status</label>
              </strong>
              <Select
                isClearable={true}
                id="traningStatus"
                name="traningStatus"
                value={trainingStatus}
                options={traningStatusOptions}
                onChange={(selectedOption) => {
                  setTrainingStatus(selectedOption);
                }}
                classNamePrefix="select"
              />
            </Col>
            <Col sm="1">
              <Button.Ripple
                color="light"
                type="submit"
                style={{ marginTop: "21px" }}
              >
                Search
              </Button.Ripple>
            </Col>

            <Col sm="1">
              <Button.Ripple
                color="danger"
                type="button"
                style={{ marginTop: "21px" }}
                onClick={() => handleReset()}
              >
                Reset
              </Button.Ripple>
            </Col>

            {userRole !== "Area Manager" && (
              <Col sm="1">
                {/* <CSVLink
                  headers={tableHeaderDataObject}
                  data={exportTableData}
                  filename={`employeeProfile_pg${page}.csv`}
                >  */}
                <Button.Ripple
                  style={{
                    marginTop: "21px",
                  }}
                  color="success"
                  appearance="success"
                  type="button"
                  onClick={() =>
                    getEmployeeProfileReportDataHandler(exportInputFilter)
                  }
                >
                  Export
                </Button.Ripple>
                {/* </CSVLink> */}
              </Col>
            )}
            {/* <Col sm="1">
              <>
                <label
                  htmlFor={`addEmployee`}
                  className="border border-2 rounded w-100 text-center"
                  style={{
                    fontSize: "15px",
                    minWidth: "100px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    fontWeight: "bold",
                    marginTop: "21px",
                    color: "#fff",
                    cursor: "pointer",
                    backgroundColor: "#0099cc",
                  }}
                >
                  Upload
                </label>
                <input
                  type="file"
                  className="d-none"
                  id={`addEmployee`}
                  name="addEmployee"
                  onChange={async (e) => {
                    employeeXlsxFile(e.target.files);
                  }}
                />
              </>
            </Col> */}

            {userRole !== "Area Manager" && (
              <Col sm="2">
                <>
                  <button
                    htmlFor={`addEmployee`}
                    className="border border-2 rounded w-100 text-center"
                    style={{
                      fontSize: "15px",
                      minWidth: "100px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      fontWeight: "bold",
                      marginTop: "21px",
                      color: "#fff",
                      cursor: "pointer",
                      backgroundColor: "#0099cc",
                    }}
                    onClick={() => {
                      history.push("/hr/importFiles/");
                    }}
                  >
                    Import xlsx file
                  </button>
                </>
              </Col>
            )}
          </Row>
        </Form>
        <EmployeeProfileTable
          setId={setId}
          id={id}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          count={count}
          handleEdit={handleEdit}
          handleCreateUpdateEmployeeProfile={handleCreateUpdateEmployeeProfile}
          refetch={refetch}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
          setId={setId}
        />
      </div>
    )
  );
};

export default EmployeeProfile;
