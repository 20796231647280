import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Plus, X, Camera } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateDestination, useUploadImage } from "@api";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import Avatar from "@components/common/avatar";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const FamousPlaces = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [updateDestinationHandler, { data: updatedDestinationData }] =
    useUpdateDestination();
  const [famousPlaces, setFamousPlaces] = useState([
    {
      id: uuidv4(),
      imageTitle: "",
      displayOrder: "",
    },
  ]);
  const [index, setIndex] = useState("");

  const [uploadImageHandler, { data: imageData }] = useUploadImage();

  useEffect(() => {
    if (state?.tableData?.famousPlaces?.length) {
      let updatedFamousPlaces = state?.tableData?.famousPlaces?.map((item) => {
        return {
          id: uuidv4(),
          imageTitle: item?.imageTitle,
          displayOrder: Number(item?.displayOrder),
          image: item?.image,
        };
      });
      setFamousPlaces(updatedFamousPlaces);
    }
  }, [state]);

  useEffect(() => {
    if (updatedDestinationData?.updateDestination?.destination) {
      toaster("success", "Famous places updated successfully");
    }
  }, [updatedDestinationData]);

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setFamousPlaces(
        famousPlaces.map((item) => {
          if (item.id === index) {
            return { ...item, image: imageData?.uploadImage?.url };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);

  const handleRemoveFamousPlaces = (id) =>
    confirmDelete(() =>
      setFamousPlaces(famousPlaces.filter((item) => item.id !== id))
    );

  const handleAddFamousPlaces = () =>
    setFamousPlaces([
      ...famousPlaces,
      { id: uuidv4(), imageTitle: "", displayOrder: "" },
    ]);

  const handleChangeImageTitle = (title, id) => {
    setFamousPlaces(
      famousPlaces.map((item) =>
        item.id === id ? { ...item, imageTitle: title } : item
      )
    );
  };

  const handleChangeDisplayOrder = (displayOrder, id) => {
    if (!isNaN(displayOrder)) {
      setFamousPlaces(
        famousPlaces.map((item) =>
          item.id === id
            ? { ...item, displayOrder: Number(displayOrder) }
            : item
        )
      );
    } else {
      toaster("error", "Please enter number only");
    }
  };

  // const ImageRenderPreview = () => {
  //   if (image.length) {
  //     return image.map((src, index) => (
  //       <img
  //         key={index}
  //         className="img-thumbnail rounded mt-2 mr-1"
  //         src={src}
  //         alt="avatar"
  //       />
  //     ));
  //   } else {
  //     return null;
  //   }
  // };

  const submitDatahandler = () => {
    let isValid = false;

    let data = {
      famousPlaces: famousPlaces,
      sectionName: "Famous Places",
    };

    famousPlaces?.forEach((item) => {
      if (item?.imageTitle && item?.displayOrder) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (
      isValid &&
      id &&
      data?.famousPlaces?.every((famousPlace) => famousPlace !== undefined)
    ) {
      famousPlaces?.forEach((item) => delete item.id);

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          famousPlaces: data?.famousPlaces,
        })
      );
      updateDestinationHandler(id, data);
    } else {
      toaster("error", "Please fill the details");
    }
  };

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);

    // const fileName = e.target.files[0]?.name;

    // const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    // if (specialCharsRegex.test(fileName)) {
    //   console.error("File name:", fileName);
    //   toaster(
    //     "error",
    //     "File name contains special characters. Please provide a valid file name."
    //   );

    //   return;
    // }

    uploadImageHandler(
      e.target.files[0],
      `${state.tableData?.destination?.toLowerCase()?.replaceAll(" ", "-")}` +
        `/DESTINATION`
    );

    toaster("success", "Famous places image uploaded successfully");
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDatahandler();
      }}
    >
      <Row className="mt-2">
        {famousPlaces.map(({ imageTitle, displayOrder, id, image }, index) => (
          <Col className="d-flex align-items-center" sm="12" key={index}>
            <FormGroup className="w-100">
              <Label className="form-label" htmlFor={"reviews" + id}>
                Famous Place {index + 1}
              </Label>

              <div className="mt-2 d-flex align-items-center">
                <div className="mb-1 mr-2 flex flex-column">
                  <div className="text-center">
                    {
                      <Avatar
                        img={image}
                        alt={""}
                        imgHeight={100}
                        imgWidth={100}
                        size="xl"
                      />
                    }
                  </div>
                  <p className="text-center">1080 X 1350</p>
                </div>

                <label htmlFor={`myFile${id}`}>
                  <p className="btn btn-sm btn-light">
                    <Camera size={15} />
                  </p>
                  <input
                    name={`myFile${id}`}
                    id={`myFile${id}`}
                    className="d-none"
                    type="file"
                    onChange={(e) => fileUploader(e, id)}
                  />
                </label>
              </div>

              {/* <Col sm="6">
                <Label className="form-label" htmlFor="location">
                  Image
                </Label>
                <DragDrop
                  id={id}
                  // className="d-none"
                  // type="file"
                  note="Image size should be less than 500KB"
                  uppy={uppyImage}
                  // onChange={(e) => fileUploader(e, id)}
                />
                {ImageRenderPreview()}
              </Col> */}

              <Input
                type="text"
                value={imageTitle}
                id={"imageTitle" + id}
                name={"imageTitle"}
                placeholder="Title"
                onChange={(e) => handleChangeImageTitle(e.target.value, id)}
              />
              <br />

              <Input
                type="text"
                value={displayOrder}
                id={"displayOrder" + id}
                name={"displayOrder" + id}
                placeholder="Display Order"
                onChange={(e) => handleChangeDisplayOrder(e.target.value, id)}
              />
            </FormGroup>
            {famousPlaces.length > 1 ? (
              <>
                <Button.Ripple
                  className="btn-icon rounded-circle ml-1"
                  color="light"
                  type="button"
                  size="sm"
                  onClick={() => handleRemoveFamousPlaces(id)}
                >
                  <X size={14} />
                </Button.Ripple>
              </>
            ) : null}
          </Col>
        ))}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddFamousPlaces} />
    </form>
  );
};

export default FamousPlaces;
