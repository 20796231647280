import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import Select from "react-select";

const DynamicFilterModal = ({
  isOpen,
  toggle,
  applyFilters,
  resetModalState,
}) => {
  const [filterType, setFilterType] = useState(null);
  const [field, setField] = useState(null);
  const [operator, setOperator] = useState(null);
  const [value, setValue] = useState("");

  const filterTypes = [
    { label: "Value-Based", value: "value" },
    { label: "% Based", value: "percentage" },
  ];

  const valueBasedFields = [
    { label: "Number of Beds", value: "No. of beds" },
    { label: "ADR", value: "ADR" },
    { label: "Total Revenue", value: "Total Revenue" },
    { label: "F&B Revenue", value: "F&B Revenue" },
    { label: "Ancillary Revenue", value: "Ancillary Revenue" },
    { label: "Total OPEX", value: "Total OPEX" },
  ];

  const percentageBasedFields = [
    {
      label: "Hostel staff salary/(bed sales)",
      value: "Hostel staff salary/(bed sales)",
    },
    { label: "Rev/Rental multiple", value: "Rev/Rental multiple" },
    { label: "Occupancy %", value: "Occ%" },
    { label: "Hostel Margin %", value: "Hostel margin (in %)" },
  ];

  const operators = [
    { label: "Greater Than", value: "$gt" },
    { label: "Less Than", value: "$lt" },
    { label: "Equal To", value: "$eq" },
  ];

  const handleApply = () => {
    if (!filterType || !field || !operator || value === "") return;

    const filter = {
      filterType: filterType.value,
      field: field.value,
      operator: operator.value,
      value: parseFloat(value),
    };
    applyFilters(filter);
    toggle();
  };

  useEffect(() => {
    if (resetModalState) {
      setFilterType(null);
      setField(null);
      setOperator(null);
      setValue("");
    }
  }, [resetModalState]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Dynamic Filter</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="filterType">Filter Type</Label>
            <Select
              id="filterType"
              options={filterTypes}
              value={filterType}
              onChange={(selected) => {
                setFilterType(selected);
                setField(null);
              }}
              placeholder="Select Filter Type"
            />
          </FormGroup>
          <FormGroup>
            <Label for="field">Field</Label>
            <Select
              id="field"
              options={
                filterType?.value === "value"
                  ? valueBasedFields
                  : percentageBasedFields
              }
              value={field}
              onChange={setField}
              placeholder="Select Field"
              isDisabled={!filterType}
            />
          </FormGroup>
          <FormGroup>
            <Label for="operator">Operator</Label>
            <Select
              id="operator"
              options={operators}
              value={operator}
              onChange={setOperator}
              placeholder="Select Operator"
            />
          </FormGroup>
          <FormGroup>
            <Label for="value">Value</Label>
            <Input
              id="value"
              type="number"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Enter Value"
            />
          </FormGroup>
          <Button
            color="primary"
            onClick={handleApply}
            disabled={!filterType || !field || !operator || value === ""}
          >
            Apply Filter
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default DynamicFilterModal;
