// fieldConfig.js

export const hostelInfoFields = [
  {
    name: "hostelName",
    label: "Hostel Name",
    type: "text",
    readOnly: false,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "operationalModel",
    label: "Operational Model",
    type: "text",
    readOnly: false,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "areaManager",
    label: "Area Manager",
    type: "text",
    readOnly: false,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "month",
    label: "Month",
    type: "number",
    readOnly: false,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "year",
    label: "Year",
    type: "number",
    readOnly: false,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "hostelCode",
    label: "Hostel Code",
    type: "number",
    readOnly: false,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
];

export const inventoryFields = [
  {
    name: "Total Inventory",
    label: "Total Inventory",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "No. of rooms",
    label: "No. of rooms",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "No. of beds",
    label: "No. of beds",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },

  {
    name: "Blocked Inventory",
    label: "Blocked Inventory",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
];

export const revenueFields = [
  {
    name: "Total Revenue",
    label: "Total Revenue",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "T A BN",
    label: "T A BN",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "T S BN",
    label: "T S BN",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Occ%",
    label: "Occ%",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "ADR",
    label: "ADR",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Bed Revenue",
    label: "Bed Revenue",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "F&B AOV (in INR)",
    label: "F&B AOV (in INR)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "F&B Orders",
    label: "F&B Orders",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "F&B Order Per Bed Night Sold",
    label: "F&B Order Per Bed Night Sold",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "F&B Revenue",
    label: "F&B Revenue",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Ancillary Revenue",
    label: "Ancillary Revenue",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
];

export const operationalExpenseFields = [
  {
    name: "Total OPEX",
    label: "Total OPEX",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },

  {
    name: "Rent",
    label: "Rent",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Rental Cost Per Bed",
    label: "Rental Cost Per Bed",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "U1: Electricity + Fuel + Genset",
    label: "U1: Electricity + Fuel + Genset",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "U2: Water + Tanker",
    label: "U2: Water + Tanker",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "U3: TV & Internet + Telephone + SW subscriptions",
    label: "U3: TV & Internet + Telephone + SW subscriptions",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "U4: Toiletry",
    label: "U4: Toiletry",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "U5: Laundry",
    label: "U5: Laundry",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "R&M",
    label: "R&M",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Misc. (local travel General liasing etc.)(Courier charges + Freight Charges + General Exp +Print & Stationery +Staff Travel Exp)",
    label:
      "Misc. (local travel General liasing etc.)(Courier charges + Freight Charges + General Exp +Print & Stationery +Staff Travel Exp)",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "OTA",
    label: "OTA",
    type: "number",
    // readOnly: true,
    readOnly: false,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Total salary",
    label: "Total salary",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Hostel Mandays",
    label: "Hostel Mandays",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "# of Hostel Staff",
    label: "# of Hostel Staff",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Hostel salary (Own)",
    label: "Hostel salary (Own)",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Hostel salary (Agency)",
    label: "Hostel salary (Agency)",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Hostel Salary Per Manday",
    label: "Hostel Salary Per Manday",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Hostel welfare (Staff food room rent & other)",
    label: "Hostel welfare (Staff food room rent & other)",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Cafe Mandays",
    label: "Cafe Mandays",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "# of Cafe Staff",
    label: "# of Cafe Staff",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "F&B salary + F&B welfare",
    label: "F&B salary + F&B welfare",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Cafe Salary Per Manday",
    label: "Cafe Salary Per Manday",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "F&B material cost (= OS + P - CS - HSW - CSW)",
    label: "F&B material cost (= OS + P - CS - HSW - CSW)",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Ancillary cost",
    label: "Ancillary cost",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Other Payouts",
    label: "Other Payouts",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "I&P payout",
    label: "I&P payout",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Franchise payout",
    label: "Franchise payout",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
];

export const marginAnalysisFields = [
  {
    name: "Overall Op. Margin (in INR)",
    label: "Overall Op. Margin (in INR)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Analysis (Operational Margin %)",
    label: "Analysis (Operational Margin %)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Hostel margin (in INR)",
    label: "Hostel margin (in INR)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Hostel margin (in %)",
    label: "Hostel margin (in %)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "F&B margin (in INR)",
    label: "F&B margin (in INR)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "F&B margin (in %)",
    label: "F&B margin (in %)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Ancillary margin (in INR)",
    label: "Ancillary margin (in INR)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Ancillary margin (in %)",
    label: "Ancillary margin (in %)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },

  {
    name: "Rev/Rental multiple",
    label: "Rev/Rental multiple",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "OTA %",
    label: "OTA %",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Utilities/Total Rev",
    label: "Utilities/Total Rev",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "R&M/Total Rev",
    label: "R&M/Total Rev",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Misc/Total Rev",
    label: "Misc/Total Rev",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Hostel staff salary/(bed sales)",
    label: "Hostel staff salary/(bed sales)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "F&B staff salary/(F&B sales)",
    label: "F&B staff salary/(F&B sales)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "F&B food cost/(F&B sales)",
    label: "F&B food cost/(F&B sales)",
    type: "number",
    readOnly: true,
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
];

export const reviewsFeedbackFields = [
  {
    name: "ORM",
    label: "ORM",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "# of positive Reviews",
    label: "# of positive Reviews",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "# of neutral Reviews",
    label: "# of neutral Reviews",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "# of negative Reviews",
    label: "# of negative Reviews",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "Total # of reviews",
    label: "Total # of reviews",
    type: "number",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
];

export const remarksActionFields = [
  {
    name: "overallRemarks",
    label: "Overall Remarks",
    type: "text",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "remarksOnOps",
    label: "Remarks on Ops",
    type: "text",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "remarksOnCX",
    label: "Remarks on CX",
    type: "text",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },

  {
    name: "remarksOnPosSales",
    label: "Remarks on POS sales",
    type: "text",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
  {
    name: "actionsFollowups",
    label: "Actions/Follow-ups",
    type: "text",
    isCritical: false,
    isProvisional: false,
    remark: "",
    breakup: [],
  },
];

export const fieldConfig = {
  hostelInfo: hostelInfoFields,
  inventory: inventoryFields,
  revenue: revenueFields,
  operationalExpense: operationalExpenseFields,
  marginAnalysis: marginAnalysisFields,
  reviewsFeedback: reviewsFeedbackFields,
  remarksAction: remarksActionFields,
};
