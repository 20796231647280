import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ActualLogsModal = (props) => {
    const { showModal, onClose, actualData } = props;
  
    const handleClose = () => {
      try {
        onClose(false);
      } catch (error) {
        console.error(error);
      }
    };
  
    return (
      <Modal
        isOpen={showModal}
        toggle={handleClose}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader className="bg-transparent" toggle={handleClose}>
          <h3>Logs:</h3>
        </ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <ul>
            {actualData?.slice().reverse().map((actualData) => {
              return actualData?.logs?.map((log, index) => {
                return <li key={index}>{log}</li>;
              });
            })}
          </ul>
        </ModalBody>
      </Modal>
    );
}

export default ActualLogsModal
