import React, { useState, useEffect, useMemo } from "react";
import {
  Col,
  FormGroup,
  Label,
  Row,
  Card,
  CardBody,
  CardHeader,
  Table,
  Spinner,
  Badge,
  Button,
  Collapse,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import Chart from "react-apexcharts";
import {
  useGetCategoryClassifyReport,
  useGetWorstReviews,
  useGetCategoryLeaderBoard,
  useHostelList,
  useGetCatgoryWiseCompliments,
  useGetCatgoryWiseComplaints,
  useGetBestReviews,
} from "../../api/dataSource";
import "./CategoryClassification.css";

// Booking source icons
const bookingSourceImages = {
  "Booking.com":
    "https://static.thehosteller.com/Hostel/Media/bookingcom%201-1694686256522.svg",
  Goibibo:
    "https://static.thehosteller.com/Hostel/Media/go%20ibibo-1694686239581.svg",
  HostelWorld:
    "https://static.thehosteller.com/Hostel/Media/hostelworld%202%20(1)-1695295577721.svg",
  Google:
    "https://static.thehosteller.com/Hostel/Media/Layer_x0020_1-1694686214140.svg",
  MakeMyTrip:
    "https://static.thehosteller.com/Hostel/Media/Make%20mytrip]-1694686267981.svg",
  TripAdvisor:
    "https://static.thehosteller.com/Hostel/Media/Tripadvisor%20(1)-1695295571996.svg",
  Agoda: "https://static.thehosteller.com/Hostel/Media/image-1739873542173.svg",
  Airbnb:
    "https://static.thehosteller.com/Hostel/Media/image-1725440828248.svg",
};

const CategoryClassification = () => {
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(),
  ]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const { data: allHostelsData } = useHostelList();
  const [openComplimentCategory, setOpenComplimentCategory] = useState(null);
  const [openComplaintCategory, setOpenComplaintCategory] = useState(null);
  const [openPhrases, setOpenPhrases] = useState({});
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  const { data: classifyData, isLoading: classifyLoading } =
    useGetCategoryClassifyReport(hostel?.value || "", fromDate, toDate);
  const { data: reviewsData, isLoading: reviewsLoading } = useGetWorstReviews(
    hostel?.value || "",
    fromDate,
    toDate
  );
  const { data: bestReviewsData, isLoading: bestReviewsLoading } =
    useGetBestReviews(hostel?.value || "", fromDate, toDate);
  const { data: leaderboardData, isLoading: leaderboardLoading } =
    useGetCategoryLeaderBoard(hostel?.value || "", fromDate, toDate);
  const { data: complimentsData, isLoading: isComplimentsLoading } =
    useGetCatgoryWiseCompliments(hostel?.value, fromDate, toDate);
  const { data: complaintsData, isLoading: isComplaintsLoading } =
    useGetCatgoryWiseComplaints(hostel?.value, fromDate, toDate);

  useEffect(() => {
    if (dateRange.length === 2) {
      setFromDate(dateRange[0].toISOString().split("T")[0]);
      setToDate(dateRange[1].toISOString().split("T")[0]);
    }
  }, [dateRange]);

  useEffect(() => {
    if (allHostelsData?.getHostelList?.length) {
      const hostelsList = allHostelsData.getHostelList.map((item) => ({
        label: item?.name,
        value: item?._id,
      }));
      setHostelsList(hostelsList);
    }
  }, [allHostelsData]);

  const handleDateChange = (dates) => {
    if (!hostel && dates.length === 2) {
      const diffInDays = Math.floor(
        (dates[1] - dates[0]) / (1000 * 60 * 60 * 24)
      );
      if (diffInDays > 30) {
        alert("Date range cannot exceed 30 days when no hostel is selected");
        return;
      }
    }
    setDateRange(dates);
  };

  const handleHostelChange = (selectedOption) => setHostel(selectedOption);

  const toggleComplimentCategory = (category) =>
    setOpenComplimentCategory(
      openComplimentCategory === category ? null : category
    );

  const toggleComplaintCategory = (category) =>
    setOpenComplaintCategory(
      openComplaintCategory === category ? null : category
    );

  const togglePhrase = (phraseId) => {
    setOpenPhrases((prev) => ({
      ...prev,
      [phraseId]: !prev[phraseId],
    }));
  };

  const getBookingSourceIcon = (source) => {
    const normalizedSource = Object.keys(bookingSourceImages).find(
      (key) => key.toLowerCase() === source.toLowerCase()
    );
    return normalizedSource ? bookingSourceImages[normalizedSource] : null;
  };

  const renderPhrases = (phrases) => {
    return phrases?.map((phrase, index) => {
      const phraseId = `${phrase.text}-${phrase.review.reviewId}`;
      return (
        <div
          key={phraseId}
          style={{
            padding: "4px",
            borderBottom: "1px solid #ddd",
            transition: "all 0.3s ease",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p style={{ margin: 0, fontSize: "14px", fontWeight: "bold" }}>
              {phrase.text}
            </p>
            <Button
              color="link"
              onClick={() => togglePhrase(phraseId)}
              style={{
                fontSize: "12px",
                textDecoration: openPhrases[phraseId] ? "underline" : "none",
                color: "#007bff",
              }}
            >
              {openPhrases[phraseId] ? "Hide details" : "Show details"}
            </Button>
          </div>
          <Collapse isOpen={openPhrases[phraseId]}>
            <div style={{ marginTop: "10px", fontSize: "14px" }}>
              <Row style={{ marginBottom: "10px" }}>
                <Col
                  md={6}
                  style={{
                    backgroundColor: "#f9f9f9",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <h5 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Guest details
                  </h5>
                  <p>
                    <strong>Guest name:</strong> {phrase.review.guestName}
                  </p>
                  <p>
                    <strong>Room details:</strong> {phrase.review.roomDetails}
                  </p>
                  <p>
                    <strong>Review ID:</strong> {phrase.review.reviewId}
                  </p>
                </Col>
                <Col
                  md={6}
                  style={{
                    backgroundColor: "#f9f9f9",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <h5 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Review details
                  </h5>
                  <p>
                    <strong>Rating:</strong> {phrase.review.rating}
                  </p>
                  <p>
                    <strong>Review date:</strong>{" "}
                    {new Date(phrase.review.reviewDate).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Booking source:</strong>{" "}
                    <img
                      src={getBookingSourceIcon(phrase.review.bookingSource)}
                      alt={phrase.review.bookingSource}
                      style={{
                        width: "50px",
                        height: "50px",
                        marginLeft: "8px",
                      }}
                    />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  style={{
                    backgroundColor: "#f9f9f9",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <h5 style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Review summary
                  </h5>
                  {phrase.review.positiveReview && (
                    <p>
                      <strong>Positive review:</strong>{" "}
                      {phrase.review.positiveReview}
                    </p>
                  )}
                  {phrase.review.negativeReview && (
                    <p>
                      <strong>Negative review:</strong>{" "}
                      {phrase.review.negativeReview}
                    </p>
                  )}
                  {phrase.review.review && (
                    <p>
                      <strong>Review:</strong> {phrase.review.review}
                    </p>
                  )}
                  {phrase.review.teamTags && (
                    <p>
                      <strong>Team tags:</strong>{" "}
                      {phrase.review.teamTags?.map((tag, idx) => (
                        <Badge
                          key={idx}
                          color="info"
                          style={{ marginRight: "5px" }}
                        >
                          {tag}
                        </Badge>
                      ))}
                    </p>
                  )}
                </Col>
              </Row>
            </div>
          </Collapse>
        </div>
      );
    });
  };

  const isLoading =
    classifyLoading ||
    reviewsLoading ||
    leaderboardLoading ||
    bestReviewsLoading ||
    isComplimentsLoading ||
    isComplaintsLoading;

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <div
      className="container-fluid p-3"
      style={{
        backgroundColor: isDarkTheme ? "#161d31" : "inherit",
        color: isDarkTheme ? "#b4b7bd" : "inherit",
        minHeight: "100vh",
      }}
    >
      {" "}
      <Row className="mb-1">
        <Col>
          <h2 className="mb-0">Category scorecard</h2>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4}>
          <FormGroup>
            <Label for="hostel">Select hostel</Label>
            <Select
              id="hostel"
              name="hostel"
              value={hostel}
              options={hostelsList}
              onChange={handleHostelChange}
              isClearable={true}
              isSearchable={true}
              placeholder="Select a hostel"
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="dateRange">Select date range</Label>
            <Flatpickr
              id="dateRange"
              value={dateRange}
              onChange={handleDateChange}
              options={{
                mode: "range",
                dateFormat: "Y-m-d",
                maxDate: new Date(),
                defaultDate: [
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                  new Date(),
                ],
              }}
              className="form-control"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card
            className="shadow-sm"
            style={{
              backgroundColor: isDarkTheme ? "#283046" : "inherit",
            }}
          >
            <CardHeader
              className="bg-primary text-white"
              style={{
                backgroundColor: isDarkTheme ? "#343d55" : "#007bff",
              }}
            >
              Category classification
            </CardHeader>
            <CardBody
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                backgroundColor: isDarkTheme ? "#283046" : "inherit",
              }}
            >
              <Table
                striped
                hover
                responsive
                style={{
                  backgroundColor: isDarkTheme ? "#283046" : "inherit",
                  color: isDarkTheme ? "#b4b7bd" : "inherit",
                }}
              >
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Positive mentions</th>
                    <th>Negative mentions</th>
                    <th>Total mentions</th>
                    <th>Review count</th>
                    <th>Category score</th>
                    <th>Mentions rate</th>
                    <th>Positive rate</th>
                    <th>Negative rate</th>
                  </tr>
                </thead>
                <tbody>
                  {classifyData?.getCategoryClassifyReport?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td>{item._id}</td>
                        <td>{item.positiveMentions}</td>
                        <td>{item.negativeMentions}</td>
                        <td>{item.totalMentions}</td>
                        <td>{item.reviewCount}</td>
                        <td style={{ minWidth: "150px", position: "relative" }}>
                          <div
                            className="progress-wrapper"
                            style={{
                              width: "100%",
                              height: "22px",
                              position: "relative",
                              borderRadius: "12px",
                              backgroundColor: "#e0e0e0",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: `${Math.abs(item.categoryScore)}%`,
                                height: "100%",
                                borderRadius: "12px",
                                background:
                                  item.categoryScore >= 0
                                    ? `linear-gradient(90deg, #28a745 0%, #218838 100%)`
                                    : `linear-gradient(90deg, #dc3545 0%, #c82333 100%)`,
                                position: "absolute",
                                left: "0",
                                top: "0",
                              }}
                            ></div>
                            <span
                              style={{
                                position: "absolute",
                                left: "50%",
                                transform: "translateX(-50%)",
                                zIndex: 2,
                                color: "#fff",
                                fontWeight: "bold",
                                fontSize: "12px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.categoryScore.toFixed(0)}
                            </span>
                          </div>
                        </td>
                        <td>{item.mentionsRate.toFixed(2)}</td>
                        <td>{item.positiveRate.toFixed(2)}</td>
                        <td>{item.negativeRate.toFixed(2)}</td>
                      </tr>
                    )
                  )}
                </tbody>{" "}
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4}>
          <Card
            className="shadow-sm"
            style={{
              backgroundColor: isDarkTheme ? "#283046" : "inherit",
              minHeight: "400px",
            }}
          >
            <CardHeader
              className="bg-danger text-white"
              style={{
                backgroundColor: isDarkTheme ? "#343d55" : "#dc3545",
              }}
            >
              Worst reviews of this period
            </CardHeader>
            <CardBody
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                backgroundColor: isDarkTheme ? "#283046" : "inherit",
              }}
            >
              {reviewsData?.worstReviews?.filter(
                (review) => review.newRating <= 6 && review.review?.trim()
              ).length > 0 ? (
                reviewsData.worstReviews
                  .filter(
                    (review) => review.newRating <= 6 && review.review?.trim()
                  )
                  .map((review, index) => (
                    <div
                      key={index}
                      className="mb-3 p-3 border-bottom"
                      style={{
                        borderColor: isDarkTheme ? "#3b4253" : "#dee2e6",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={getBookingSourceIcon(review.bookingSource)}
                          alt={review.bookingSource}
                          style={{
                            width: "60px",
                            height: "60px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                      <p style={{ color: isDarkTheme ? "#b4b7bd" : "inherit" }}>
                        {review.review}
                      </p>
                    </div>
                  ))
              ) : (
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    height: "300px",
                    color: isDarkTheme ? "#b4b7bd" : "#6c757d",
                  }}
                >
                  <i className="fas fa-frown fa-3x mb-3"></i>
                  <h5>No worst reviews available</h5>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col md={4}>
          <Card
            className="shadow-sm"
            style={{
              backgroundColor: isDarkTheme ? "#283046" : "inherit",
              minHeight: "400px",
            }}
          >
            <CardHeader
              className="bg-success text-white"
              style={{
                backgroundColor: isDarkTheme ? "#343d55" : "#28a745",
              }}
            >
              Top reviews of this period
            </CardHeader>
            <CardBody
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                backgroundColor: isDarkTheme ? "#283046" : "inherit",
              }}
            >
              {bestReviewsData?.bestReviews?.filter(
                (review) => review.newRating >= 8 && review.review?.trim()
              ).length > 0 ? (
                bestReviewsData.bestReviews
                  .filter(
                    (review) => review.newRating >= 8 && review.review?.trim()
                  )
                  .map((review, index) => (
                    <div
                      key={index}
                      className="mb-2 p-3 border-bottom"
                      style={{
                        borderColor: isDarkTheme ? "#3b4253" : "#dee2e6",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={getBookingSourceIcon(review.bookingSource)}
                          alt={review.bookingSource}
                          style={{
                            width: "60px",
                            height: "60px",
                            marginRight: "8px",
                          }}
                        />
                      </div>
                      <p style={{ color: isDarkTheme ? "#b4b7bd" : "inherit" }}>
                        {review.review}
                      </p>
                    </div>
                  ))
              ) : (
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    height: "300px",
                    color: isDarkTheme ? "#b4b7bd" : "#6c757d",
                  }}
                >
                  <i className="fas fa-smile fa-3x mb-3"></i>
                  <h5>No top reviews available</h5>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="shadow-sm">
            <CardHeader className="bg-warning text-white">
              Category leaderboard
            </CardHeader>
            <CardBody style={{ maxHeight: "400px", overflowY: "auto" }}>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {leaderboardData?.getCategoryLeaderBoard?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td>{item._id}</td>
                        <td style={{ minWidth: "200px", position: "relative" }}>
                          <div
                            className="progress-wrapper"
                            style={{
                              width: "100%",
                              height: "22px",
                              position: "relative",
                              borderRadius: "12px",
                              backgroundColor: "#e0e0e0",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: `${Math.abs(item.categoryScore)}%`,
                                height: "100%",
                                borderRadius: "12px",
                                background:
                                  item.categoryScore >= 0
                                    ? `linear-gradient(90deg, #28a745 0%, #218838 100%)`
                                    : `linear-gradient(90deg, #dc3545 0%, #c82333 100%)`,
                                position: "absolute",
                                left: "0",
                                top: "0",
                              }}
                            ></div>
                            <span
                              style={{
                                position: "absolute",
                                left: "50%",
                                transform: "translateX(-50%)",
                                zIndex: 2,
                                color: "#fff",
                                fontWeight: "bold",
                                fontSize: "12px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.categoryScore.toFixed(0)}
                            </span>
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col md={6}>
          <Card
            className="shadow-sm"
            style={{
              backgroundColor: isDarkTheme ? "#283046" : "inherit",
              minHeight: "400px",
            }}
          >
            <CardHeader
              className="bg-danger text-white"
              style={{
                backgroundColor: isDarkTheme ? "#343d55" : "#dc3545",
              }}
            >
              Complaints by category
            </CardHeader>
            <CardBody
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                backgroundColor: isDarkTheme ? "#283046" : "inherit",
              }}
            >
              {complaintsData?.getCatgoryWiseComplaints?.length > 0 ? (
                complaintsData.getCatgoryWiseComplaints.map(
                  (category, index) => (
                    <div key={index} className="mb-3 mt-2">
                      <div
                        className="d-flex justify-content-between align-items-center p-2 rounded"
                        style={{
                          backgroundColor: isDarkTheme ? "#343d55" : "#f8f9fa",
                          color: isDarkTheme ? "#d0d2d6" : "inherit",
                          cursor: "pointer",
                          borderLeft: "4px solid #dc3545",
                          transition: "all 0.2s ease",
                          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        }}
                        onClick={() =>
                          toggleComplaintCategory(category.Category)
                        }
                      >
                        <h5
                          className="mb-0"
                          style={{
                            fontWeight: 500,
                            color: isDarkTheme ? "#d0d2d6" : "inherit",
                          }}
                        >
                          {category.Category}
                        </h5>
                        <Badge
                          color={isDarkTheme ? "light" : "danger"}
                          pill
                          style={{
                            fontSize: "0.9rem",
                            padding: "0.35em 0.65em",
                            minWidth: "2.5em",
                            backgroundColor: isDarkTheme
                              ? "#44405e"
                              : undefined,
                          }}
                        >
                          {category.Phrases.length}
                        </Badge>
                      </div>
                      <Collapse
                        isOpen={openComplaintCategory === category.Category}
                      >
                        <div
                          className="mt-2"
                          style={{
                            backgroundColor: isDarkTheme
                              ? "#242b3d"
                              : "inherit",
                            color: isDarkTheme ? "#b4b7bd" : "inherit",
                          }}
                        >
                          {renderPhrases(category.Phrases)}
                        </div>
                      </Collapse>
                    </div>
                  )
                )
              ) : (
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    height: "300px",
                    color: isDarkTheme ? "#b4b7bd" : "#6c757d",
                  }}
                >
                  <i className="fas fa-exclamation-circle fa-3x mb-3"></i>
                  <h5>No Complaints Data Available</h5>
                  <p className="text-center">
                    There are no complaints to display for the selected criteria
                  </p>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <Card
            className="shadow-sm"
            style={{
              backgroundColor: isDarkTheme ? "#283046" : "inherit",
              minHeight: "400px",
            }}
          >
            <CardHeader
              className="bg-success text-white"
              style={{
                backgroundColor: isDarkTheme ? "#343d55" : "#28a745",
              }}
            >
              Compliments by category
            </CardHeader>
            <CardBody
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                backgroundColor: isDarkTheme ? "#283046" : "inherit",
              }}
            >
              {complimentsData?.getCatgoryWiseCompliments?.length > 0 ? (
                complimentsData.getCatgoryWiseCompliments.map(
                  (category, index) => (
                    <div key={index} className="mb-3 mt-2">
                      <div
                        className="d-flex justify-content-between align-items-center p-2 rounded"
                        style={{
                          backgroundColor: isDarkTheme ? "#343d55" : "#f8f9fa",
                          color: isDarkTheme ? "#d0d2d6" : "inherit",
                          cursor: "pointer",
                          borderLeft: "4px solid rgb(135, 202, 64)",
                          transition: "all 0.2s ease",
                          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        }}
                        onClick={() =>
                          toggleComplimentCategory(category.Category)
                        }
                      >
                        <h5
                          className="mb-0"
                          style={{
                            fontWeight: 500,
                            color: isDarkTheme ? "#d0d2d6" : "inherit",
                          }}
                        >
                          {category.Category}
                        </h5>
                        <Badge
                          color={isDarkTheme ? "light" : "success"}
                          pill
                          style={{
                            fontSize: "0.9rem",
                            padding: "0.35em 0.65em",
                            minWidth: "2.5em",
                            backgroundColor: isDarkTheme
                              ? "#44405e"
                              : undefined,
                          }}
                        >
                          {category.Phrases.length}
                        </Badge>
                      </div>
                      <Collapse
                        isOpen={openComplimentCategory === category.Category}
                      >
                        <div
                          className="mt-2"
                          style={{
                            backgroundColor: isDarkTheme
                              ? "#242b3d"
                              : "inherit",
                            color: isDarkTheme ? "#b4b7bd" : "inherit",
                          }}
                        >
                          {renderPhrases(category.Phrases)}
                        </div>
                      </Collapse>
                    </div>
                  )
                )
              ) : (
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    height: "300px",
                    color: isDarkTheme ? "#b4b7bd" : "#6c757d",
                  }}
                >
                  <i className="fas fa-check-circle fa-3x mb-3"></i>
                  <h5>No Compliments Data Available</h5>
                  <p className="text-center">
                    There are no compliments to display for the selected
                    criteria
                  </p>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card
            className="shadow-sm mb-4"
            style={{
              backgroundColor: isDarkTheme ? "#283046" : "inherit",
              borderColor: isDarkTheme ? "#3b4253" : "#dee2e6",
            }}
          >
            <CardHeader
              className="d-flex justify-content-between align-items-center"
              style={{
                backgroundColor: isDarkTheme ? "#343d55" : "#17a2b8",
                color: isDarkTheme ? "#d0d2d6" : "white",
              }}
            >
              <span>Compliments vs Complaints (Sorted by Total Mentions)</span>
              <Badge
                pill
                style={{
                  color: isDarkTheme ? "#d0d2d6" : "#17a2b8",
                  backgroundColor: isDarkTheme ? "#44405e" : "white",
                  fontSize: "0.875rem",
                  fontWeight: 600,
                }}
              >
                {complimentsData?.getCatgoryWiseCompliments &&
                complaintsData?.getCatgoryWiseComplaints
                  ? [
                      ...new Set([
                        ...complimentsData.getCatgoryWiseCompliments.map(
                          (item) => item.Category
                        ),
                        ...complaintsData.getCatgoryWiseComplaints.map(
                          (item) => item.Category
                        ),
                      ]),
                    ].length
                  : 0}{" "}
                Categories
              </Badge>
            </CardHeader>
            <CardBody
              style={{
                backgroundColor: isDarkTheme ? "#283046" : "inherit",
                padding: isDarkTheme ? "1.25rem" : "inherit",
              }}
            >
              {complimentsData?.getCatgoryWiseCompliments &&
              complaintsData?.getCatgoryWiseComplaints ? (
                <div className="category-comparison-container">
                  <Chart
                    options={{
                      chart: {
                        type: "bar",
                        stacked: true,
                        toolbar: {
                          show: true,
                          tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true,
                          },
                        },
                        background: isDarkTheme ? "#283046" : "white",
                        foreColor: isDarkTheme ? "#b4b7bd" : "#373d3f",
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true,
                          barHeight: "70%",
                          endingShape: "rounded",
                          dataLabels: {
                            position: "center",
                          },
                        },
                      },
                      dataLabels: {
                        enabled: false,
                        style: {
                          colors: [isDarkTheme ? "#fff" : "#000"],
                        },
                      },
                      xaxis: {
                        categories: [
                          ...new Set([
                            ...complimentsData.getCatgoryWiseCompliments.map(
                              (item) => item.Category
                            ),
                            ...complaintsData.getCatgoryWiseComplaints.map(
                              (item) => item.Category
                            ),
                          ]),
                        ]
                          .map((category) => {
                            const compliment =
                              complimentsData.getCatgoryWiseCompliments.find(
                                (c) => c.Category === category
                              );
                            const complaint =
                              complaintsData.getCatgoryWiseComplaints.find(
                                (c) => c.Category === category
                              );
                            return {
                              category,
                              total:
                                (compliment?.Phrases.length || 0) +
                                (complaint?.Phrases.length || 0),
                            };
                          })
                          .sort((a, b) => a.total - b.total)
                          .map((item) => item.category),
                        labels: {
                          trim: true,
                          hideOverlappingLabels: true,
                          style: {
                            fontSize: "10px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 600,
                            colors: isDarkTheme ? "#b4b7bd" : "#373d3f",
                          },
                        },
                        axisTicks: {
                          show: false,
                        },
                        axisBorder: {
                          show: false,
                          color: isDarkTheme ? "#3b4253" : "#e0e0e0",
                        },
                      },
                      yaxis: {
                        labels: {
                          show: true,
                          style: {
                            fontSize: "10px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 600,
                            colors: isDarkTheme ? "#b4b7bd" : "#373d3f",
                          },
                          maxWidth: 200,
                        },
                        title: {
                          text: "Categories (sorted by total mentions)",
                          style: {
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: isDarkTheme ? "#b4b7bd" : "#373d3f",
                          },
                        },
                      },
                      grid: {
                        borderColor: isDarkTheme ? "#3b4253" : "#f1f1f1",
                        strokeDashArray: 3,
                        xaxis: {
                          lines: {
                            show: false,
                          },
                        },
                        yaxis: {
                          lines: {
                            show: true,
                          },
                        },
                      },
                      colors: ["#28a745", "#dc3545"],
                      legend: {
                        position: "top",
                        horizontalAlign: "right",
                        fontSize: "12px",
                        markers: {
                          radius: 12,
                        },
                        labels: {
                          colors: isDarkTheme ? "#b4b7bd" : "#373d3f",
                        },
                        itemMargin: {
                          horizontal: 10,
                          vertical: 5,
                        },
                      },
                      tooltip: {
                        shared: true,
                        intersect: false,
                        style: {
                          fontSize: "12px",
                        },
                        theme: isDarkTheme ? "dark" : "light",
                        x: {
                          show: true,
                          format: "dd MMM yyyy",
                        },
                      },
                      theme: {
                        mode: isDarkTheme ? "dark" : "light",
                      },
                    }}
                    series={[
                      {
                        name: "Compliments",
                        data: [
                          ...new Set([
                            ...complimentsData.getCatgoryWiseCompliments.map(
                              (item) => item.Category
                            ),
                            ...complaintsData.getCatgoryWiseComplaints.map(
                              (item) => item.Category
                            ),
                          ]),
                        ]
                          .map((category) => {
                            const compliment =
                              complimentsData.getCatgoryWiseCompliments.find(
                                (c) => c.Category === category
                              );
                            const complaint =
                              complaintsData.getCatgoryWiseComplaints.find(
                                (c) => c.Category === category
                              );
                            return {
                              category,
                              total:
                                (compliment?.Phrases.length || 0) +
                                (complaint?.Phrases.length || 0),
                              complimentCount: compliment?.Phrases.length || 0,
                            };
                          })
                          .sort((a, b) => a.total - b.total)
                          .map((item) => item.complimentCount),
                      },
                      {
                        name: "Complaints",
                        data: [
                          ...new Set([
                            ...complimentsData.getCatgoryWiseCompliments.map(
                              (item) => item.Category
                            ),
                            ...complaintsData.getCatgoryWiseComplaints.map(
                              (item) => item.Category
                            ),
                          ]),
                        ]
                          .map((category) => {
                            const compliment =
                              complimentsData.getCatgoryWiseCompliments.find(
                                (c) => c.Category === category
                              );
                            const complaint =
                              complaintsData.getCatgoryWiseComplaints.find(
                                (c) => c.Category === category
                              );
                            return {
                              category,
                              total:
                                (compliment?.Phrases.length || 0) +
                                (complaint?.Phrases.length || 0),
                              complaintCount: complaint?.Phrases.length || 0,
                            };
                          })
                          .sort((a, b) => a.total - b.total)
                          .map((item) => item.complaintCount),
                      },
                    ]}
                    type="bar"
                    height={Math.max(
                      400,
                      [
                        ...new Set([
                          ...complimentsData.getCatgoryWiseCompliments.map(
                            (item) => item.Category
                          ),
                          ...complaintsData.getCatgoryWiseComplaints.map(
                            (item) => item.Category
                          ),
                        ]),
                      ].length * 20
                    )}
                  />
                </div>
              ) : (
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    height: "300px",
                    color: isDarkTheme ? "#b4b7bd" : "#6c757d",
                  }}
                >
                  <i className="fas fa-chart-bar fa-3x mb-3"></i>
                  <h5>No comparison data available</h5>
                  <p className="text-center">
                    There are no compliments or complaints to display
                  </p>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col md={12}>
          <Card
            className="shadow-sm mb-4"
            style={{
              backgroundColor: isDarkTheme ? "#283046" : "inherit",
            }}
          >
            <CardHeader
              className="bg-secondary text-white"
              style={{
                backgroundColor: isDarkTheme ? "#343d55" : "#6c757d",
              }}
            >
              Review categories
            </CardHeader>
            <CardBody
              style={{
                backgroundColor: isDarkTheme ? "#283046" : "inherit",
              }}
            >
              {complimentsData?.getCatgoryWiseCompliments &&
              complaintsData?.getCatgoryWiseComplaints ? (
                <Chart
                  options={{
                    chart: {
                      type: "treemap",
                      toolbar: {
                        show: true,
                        tools: {
                          download: true,
                          selection: false,
                          zoom: false,
                          zoomin: false,
                          zoomout: false,
                          pan: false,
                          reset: false,
                        },
                      },
                      animations: {
                        enabled: false, // Improves rendering for long labels
                      },
                    },
                    colors: ["#28a745", "#dc3545"],
                    legend: {
                      show: true,
                      position: "top",
                      labels: {
                        colors: isDarkTheme ? "#b4b7bd" : undefined,
                      },
                    },
                    plotOptions: {
                      treemap: {
                        distributed: true,
                        enableShades: false,
                        dataLabels: {
                          style: {
                            fontSize: "10px",
                            fontWeight: "bold",
                            colors: ["#ffffff"],
                          },
                          formatter: function ({ value, seriesIndex, w }) {
                            // Truncate long labels and add ellipsis
                            const maxLength = 15;
                            return value.length > maxLength
                              ? value.substring(0, maxLength) + "..."
                              : value;
                          },
                        },
                      },
                    },
                    tooltip: {
                      enabled: true,
                      style: {
                        fontSize: "12px",
                      },
                      y: {
                        formatter: function (
                          value,
                          { series, seriesIndex, dataPointIndex, w }
                        ) {
                          return value + " mentions";
                        },
                      },
                    },
                    theme: {
                      mode: isDarkTheme ? "dark" : "light",
                    },
                  }}
                  series={[
                    {
                      data: [
                        ...complimentsData.getCatgoryWiseCompliments.map(
                          (item) => ({
                            x:
                              item.Category.length > 15
                                ? item.Category.substring(0, 12) + "..."
                                : item.Category,
                            y: item.Phrases.length,
                            fillColor: "#28a745",
                            realName: item.Category, // Store original name for tooltip
                          })
                        ),
                        ...complaintsData.getCatgoryWiseComplaints.map(
                          (item) => ({
                            x:
                              item.Category.length > 15
                                ? item.Category.substring(0, 12) + "..."
                                : item.Category,
                            y: item.Phrases.length,
                            fillColor: "#dc3545",
                            realName: item.Category, // Store original name for tooltip
                          })
                        ),
                      ].sort((a, b) => b.y - a.y), // Sort by size to help with layout
                    },
                  ]}
                  type="treemap"
                  height={350}
                />
              ) : (
                <p
                  style={{
                    color: isDarkTheme ? "#b4b7bd" : "#6c757d",
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  No data available for the chart.
                </p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CategoryClassification;

// import React, { useState, useEffect } from "react";
// import {
//   Col,
//   FormGroup,
//   Label,
//   Row,
//   Card,
//   CardBody,
//   CardHeader,
//   Table,
//   Spinner,
//   Badge,
//   Button,
//   Collapse,
// } from "reactstrap";
// import Select from "react-select";
// import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_green.css";
// import Chart from "react-apexcharts";
// import {
//   useGetCategoryClassifyReport,
//   useGetWorstReviews,
//   useGetCategoryLeaderBoard,
//   useHostelList,
//   useGetCatgoryWiseCompliments,
//   useGetCatgoryWiseComplaints,
//   useGetBestReviews,
// } from "../../api/dataSource";

// import "./CategoryClassification.css";

// // Booking source icons
// const bookingSourceImages = {
//   "Booking.com":
//     "https://static.thehosteller.com/Hostel/Media/bookingcom%201-1694686256522.svg",
//   Goibibo:
//     "https://static.thehosteller.com/Hostel/Media/go%20ibibo-1694686239581.svg",
//   HostelWorld:
//     "https://static.thehosteller.com/Hostel/Media/hostelworld%202%20(1)-1695295577721.svg",
//   Google:
//     "https://static.thehosteller.com/Hostel/Media/Layer_x0020_1-1694686214140.svg",
//   MakeMyTrip:
//     "https://static.thehosteller.com/Hostel/Media/Make%20mytrip]-1694686267981.svg",
//   TripAdvisor:
//     "https://static.thehosteller.com/Hostel/Media/Tripadvisor%20(1)-1695295571996.svg",
//   Agoda: "https://static.thehosteller.com/Hostel/Media/image-1739873542173.svg",
//   Airbnb:
//     "https://static.thehosteller.com/Hostel/Media/image-1725440828248.svg",
// };

// const CategoryClassification = () => {
//   const [dateRange, setDateRange] = useState([
//     new Date(new Date().getFullYear(), new Date().getMonth(), 1),
//     new Date(),
//   ]);
//   const [fromDate, setFromDate] = useState("");
//   const [toDate, setToDate] = useState("");
//   const [hostelsList, setHostelsList] = useState([]);
//   const [hostel, setHostel] = useState(null);
//   const { data: allHostelsData } = useHostelList();
//   const [openComplimentCategory, setOpenComplimentCategory] = useState(null);
//   const [openComplaintCategory, setOpenComplaintCategory] = useState(null);
//   const [openPhrase, setOpenPhrase] = useState(null);

//   const {
//     loading: classifyLoading,
//     error: classifyError,
//     data: classifyData,
//   } = useGetCategoryClassifyReport(hostel?.value || "", fromDate, toDate);

//   const {
//     loading: reviewsLoading,
//     error: reviewsError,
//     data: reviewsData,
//   } = useGetWorstReviews(hostel?.value || "", fromDate, toDate);

//   const {
//     loading: bestReviewsLoading,
//     error: bestReviewsError,
//     data: bestReviewsData,
//   } = useGetBestReviews(hostel?.value || "", fromDate, toDate);

//   const {
//     loading: leaderboardLoading,
//     error: leaderboardError,
//     data: leaderboardData,
//   } = useGetCategoryLeaderBoard(hostel?.value || "", fromDate, toDate);

//   const { data: complimentsData, isLoading: isComplimentsLoading } =
//     useGetCatgoryWiseCompliments(hostel?.value, fromDate, toDate);

//   const { data: complaintsData, isLoading: isComplaintsLoading } =
//     useGetCatgoryWiseComplaints(hostel?.value, fromDate, toDate);

//   useEffect(() => {
//     if (dateRange.length === 2) {
//       setFromDate(dateRange[0].toISOString().split("T")[0]);
//       setToDate(dateRange[1].toISOString().split("T")[0]);
//     }
//   }, [dateRange]);

//   useEffect(() => {
//     if (allHostelsData?.getHostelList?.length) {
//       const hostelsList = allHostelsData.getHostelList.map((item) => ({
//         label: item?.name,
//         value: item?._id,
//       }));
//       setHostelsList(hostelsList);
//     }
//   }, [allHostelsData]);

//   const handleDateChange = (dates) => setDateRange(dates);
//   const handleHostelChange = (selectedOption) => setHostel(selectedOption);
//   const toggleComplimentCategory = (category) =>
//     setOpenComplimentCategory(
//       openComplimentCategory === category ? null : category
//     );
//   const toggleComplaintCategory = (category) =>
//     setOpenComplaintCategory(
//       openComplaintCategory === category ? null : category
//     );
//   const togglePhrase = (phrase) =>
//     setOpenPhrase(openPhrase === phrase ? null : phrase);

//   const getBookingSourceIcon = (source) => {
//     const normalizedSource = Object.keys(bookingSourceImages).find(
//       (key) => key.toLowerCase() === source.toLowerCase()
//     );
//     return normalizedSource ? bookingSourceImages[normalizedSource] : null;
//   };

//   const renderPhrases = (phrases) => {
//     return phrases?.map((phrase, index) => (
//       <div
//         key={index}
//         style={{
//           padding: "4px",
//           borderBottom: "1px solid #ddd",
//           transition: "all 0.3s ease",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           <p style={{ margin: 0, fontSize: "14px", fontWeight: "bold" }}>
//             {phrase.text}
//           </p>
//           <Button
//             color="link"
//             onClick={() => togglePhrase(phrase.text)}
//             style={{
//               fontSize: "12px",
//               textDecoration: openPhrase === phrase.text ? "underline" : "none",
//               color: "#007bff",
//             }}
//           >
//             {openPhrase === phrase.text ? "Hide details" : "Show details"}
//           </Button>
//         </div>
//         <Collapse isOpen={openPhrase === phrase.text}>
//           <div style={{ marginTop: "10px", fontSize: "14px" }}>
//             <Row style={{ marginBottom: "10px" }}>
//               <Col
//                 md={6}
//                 style={{
//                   backgroundColor: "#f9f9f9",
//                   padding: "10px",
//                   borderRadius: "5px",
//                 }}
//               >
//                 <h5 style={{ fontSize: "16px", fontWeight: "bold" }}>
//                   Guest details
//                 </h5>
//                 <p>
//                   <strong>Guest name:</strong> {phrase.review.guestName}
//                 </p>
//                 <p>
//                   <strong>Room details:</strong> {phrase.review.roomDetails}
//                 </p>
//                 <p>
//                   <strong>Review ID:</strong> {phrase.review.reviewId}
//                 </p>
//               </Col>
//               <Col
//                 md={6}
//                 style={{
//                   backgroundColor: "#f9f9f9",
//                   padding: "10px",
//                   borderRadius: "5px",
//                 }}
//               >
//                 <h5 style={{ fontSize: "16px", fontWeight: "bold" }}>
//                   Review details
//                 </h5>
//                 <p>
//                   <strong>Rating:</strong> {phrase.review.rating}
//                 </p>
//                 <p>
//                   <strong>Review date:</strong>{" "}
//                   {new Date(phrase.review.reviewDate).toLocaleDateString()}
//                 </p>
//                 <p>
//                   <strong>Booking source:</strong>{" "}
//                   <img
//                     src={getBookingSourceIcon(phrase.review.bookingSource)}
//                     alt={phrase.review.bookingSource}
//                     style={{ width: "50px", height: "50px", marginLeft: "8px" }}
//                   />
//                 </p>
//               </Col>
//             </Row>
//             <Row>
//               <Col
//                 md={12}
//                 style={{
//                   backgroundColor: "#f9f9f9",
//                   padding: "10px",
//                   borderRadius: "5px",
//                 }}
//               >
//                 <h5 style={{ fontSize: "16px", fontWeight: "bold" }}>
//                   Review summary
//                 </h5>
//                 {phrase.review.positiveReview && (
//                   <p>
//                     <strong>Positive review:</strong>{" "}
//                     {phrase.review.positiveReview}
//                   </p>
//                 )}
//                 {phrase.review.negativeReview && (
//                   <p>
//                     <strong>Negative review:</strong>{" "}
//                     {phrase.review.negativeReview}
//                   </p>
//                 )}
//                 {phrase.review.review && (
//                   <p>
//                     <strong>Review:</strong> {phrase.review.review}
//                   </p>
//                 )}
//                 {phrase.review.teamTags && (
//                   <p>
//                     <strong>Team tags:</strong>{" "}
//                     {phrase.review.teamTags?.map((tag, idx) => (
//                       <Badge
//                         key={idx}
//                         color="info"
//                         style={{ marginRight: "5px" }}
//                       >
//                         {tag}
//                       </Badge>
//                     ))}
//                   </p>
//                 )}
//               </Col>
//             </Row>
//           </div>
//         </Collapse>
//       </div>
//     ));
//   };

//   if (
//     classifyLoading ||
//     reviewsLoading ||
//     leaderboardLoading | bestReviewsLoading
//   ) {
//     return (
//       <div className="d-flex justify-content-center align-items-center vh-100">
//         <Spinner color="primary" />
//       </div>
//     );
//   }

//   if (classifyError || reviewsError || leaderboardError || bestReviewsError) {
//     return (
//       <div className="d-flex justify-content-center align-items-center vh-100">
//         <p className="text-danger">
//           Error loading data. Please try again later.
//         </p>
//       </div>
//     );
//   }

//   return (
//     <div className="container-fluid p-3">
//       {/* Header Section */}
//       <Row className="mb-1">
//         <Col>
//           <h2 className="mb-0">Category scorecard</h2>
//         </Col>
//       </Row>

//       {/* Filters Section */}
//       <Row className="mb-4">
//         <Col md={4}>
//           <FormGroup>
//             <Label for="hostel">Select hostel</Label>
//             <Select
//               id="hostel"
//               name="hostel"
//               value={hostel}
//               options={hostelsList}
//               onChange={handleHostelChange}
//               isClearable={true}
//               isSearchable={true}
//               placeholder="Select a hostel"
//             />
//           </FormGroup>
//         </Col>
//         <Col md={4}>
//           <FormGroup>
//             <Label for="dateRange">Select date range</Label>
//             <Flatpickr
//               id="dateRange"
//               value={dateRange}
//               onChange={handleDateChange}
//               options={{ mode: "range", dateFormat: "Y-m-d" }}
//               className="form-control"
//             />
//           </FormGroup>
//         </Col>
//       </Row>

//       {/* Category Classification Table */}
//       <Row className="mb-4">
//         <Col>
//           <Card className="shadow-sm">
//             <CardHeader className="bg-primary text-white">
//               Category classification
//             </CardHeader>
//             <CardBody style={{ maxHeight: "400px", overflowY: "auto" }}>
//               <Table striped hover responsive>
//                 <thead>
//                   <tr>
//                     <th>Category</th>
//                     <th>Positive mentions</th>
//                     <th>Negative mentions</th>
//                     <th>Total mentions</th>
//                     <th>Review count</th>
//                     <th>Category score</th>
//                     <th>Mentions rate</th>
//                     <th>Positive rate</th>
//                     <th>Negative rate</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {classifyData?.getCategoryClassifyReport?.map(
//                     (item, index) => (
//                       <tr key={index}>
//                         <td>{item._id}</td>
//                         <td>{item.positiveMentions}</td>
//                         <td>{item.negativeMentions}</td>
//                         <td>{item.totalMentions}</td>
//                         <td>{item.reviewCount}</td>
//                         <td style={{ minWidth: "150px", position: "relative" }}>
//                           <div
//                             className="progress-wrapper"
//                             style={{
//                               width: "100%",
//                               height: "22px",
//                               position: "relative",
//                               borderRadius: "12px",
//                               backgroundColor: "#e0e0e0",
//                               overflow: "hidden",
//                               display: "flex",
//                               alignItems: "center",
//                               justifyContent: "center",
//                             }}
//                           >
//                             <div
//                               style={{
//                                 width: `${Math.abs(item.categoryScore)}%`,
//                                 height: "100%",
//                                 borderRadius: "12px",
//                                 background:
//                                   item.categoryScore >= 0
//                                     ? `linear-gradient(90deg, #28a745 0%, #218838 100%)`
//                                     : `linear-gradient(90deg, #dc3545 0%, #c82333 100%)`,
//                                 position: "absolute",
//                                 left: "0",
//                                 top: "0",
//                               }}
//                             ></div>
//                             <span
//                               style={{
//                                 position: "absolute",
//                                 left: "50%",
//                                 transform: "translateX(-50%)",
//                                 zIndex: 2,
//                                 color: "#fff",
//                                 fontWeight: "bold",
//                                 fontSize: "12px",
//                                 whiteSpace: "nowrap",
//                               }}
//                             >
//                               {item.categoryScore.toFixed(0)}%
//                             </span>
//                           </div>
//                         </td>
//                         <td>{item.mentionsRate.toFixed(2)}%</td>
//                         <td>{item.positiveRate.toFixed(2)}%</td>
//                         <td>{item.negativeRate.toFixed(2)}%</td>
//                       </tr>
//                     )
//                   )}
//                 </tbody>
//               </Table>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>

//       {/* Top and Worst Reviews and Leaderboard Section */}
//       <Row className="mb-4">
//         <Col md={4}>
//           <Card className="shadow-sm">
//             <CardHeader className="bg-success text-white">
//               Top reviews of this period
//             </CardHeader>
//             <CardBody style={{ maxHeight: "400px", overflowY: "auto" }}>
//               {bestReviewsData?.bestReviews
//                 ?.filter((review) => review.newRating >= 8)
//                 .map((review, index) => (
//                   <div key={index} className="mb-2 p-3 border-bottom">
//                     <div className="d-flex align-items-center">
//                       <img
//                         src={getBookingSourceIcon(review.bookingSource)}
//                         alt={review.bookingSource}
//                         style={{
//                           width: "60px",
//                           height: "60px",
//                           marginRight: "8px",
//                         }}
//                       />
//                       {/* <h6>{review.bookingSource}</h6> */}
//                     </div>
//                     <p>{review.review}</p>
//                     {/* <Badge color="success">Rating: {review.newRating}</Badge> */}
//                   </div>
//                 ))}
//             </CardBody>
//           </Card>
//         </Col>
//         <Col md={4}>
//           <Card className="shadow-sm">
//             <CardHeader className="bg-danger text-white">
//               Worst reviews of this period
//             </CardHeader>
//             <CardBody style={{ maxHeight: "400px", overflowY: "auto" }}>
//               {reviewsData?.worstReviews
//                 ?.filter((review) => review.newRating <= 5)
//                 .map((review, index) => (
//                   <div key={index} className="mb-3 p-3 border-bottom">
//                     <div className="d-flex align-items-center">
//                       <img
//                         src={getBookingSourceIcon(review.bookingSource)}
//                         alt={review.bookingSource}
//                         style={{
//                           width: "60px",
//                           height: "60px",
//                           marginRight: "8px",
//                         }}
//                       />
//                       {/* <h6>{review.bookingSource}</h6> */}
//                     </div>
//                     <p>{review.review}</p>
//                     {/* <Badge color="danger">Rating: {review.newRating}</Badge> */}
//                   </div>
//                 ))}
//             </CardBody>
//           </Card>
//         </Col>
//         <Col md={4}>
//           <Card className="shadow-sm">
//             <CardHeader className="bg-warning text-white">
//               Category leaderboard
//             </CardHeader>
//             <CardBody style={{ maxHeight: "400px", overflowY: "auto" }}>
//               <Table striped hover responsive>
//                 <thead>
//                   <tr>
//                     <th>Category</th>
//                     <th>Score</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {leaderboardData?.getCategoryLeaderBoard?.map(
//                     (item, index) => (
//                       <tr key={index}>
//                         <td>{item._id}</td>
//                         <td style={{ minWidth: "200px", position: "relative" }}>
//                           <div
//                             className="progress-wrapper"
//                             style={{
//                               width: "100%",
//                               height: "22px",
//                               position: "relative",
//                               borderRadius: "12px",
//                               backgroundColor: "#e0e0e0",
//                               overflow: "hidden",
//                               display: "flex",
//                               alignItems: "center",
//                               justifyContent: "center",
//                             }}
//                           >
//                             <div
//                               style={{
//                                 width: `${Math.abs(item.categoryScore)}%`,
//                                 height: "100%",
//                                 borderRadius: "12px",
//                                 background:
//                                   item.categoryScore >= 0
//                                     ? `linear-gradient(90deg, #28a745 0%, #218838 100%)`
//                                     : `linear-gradient(90deg, #dc3545 0%, #c82333 100%)`,
//                                 position: "absolute",
//                                 left: "0",
//                                 top: "0",
//                               }}
//                             ></div>
//                             <span
//                               style={{
//                                 position: "absolute",
//                                 left: "50%",
//                                 transform: "translateX(-50%)",
//                                 zIndex: 2,
//                                 color: "#fff",
//                                 fontWeight: "bold",
//                                 fontSize: "12px",
//                                 whiteSpace: "nowrap",
//                               }}
//                             >
//                               {item.categoryScore.toFixed(0)}%
//                             </span>
//                           </div>
//                         </td>
//                       </tr>
//                     )
//                   )}
//                 </tbody>
//               </Table>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>

//       {/* Compliments and Complaints Section */}
//       {isComplimentsLoading || isComplaintsLoading ? (
//         <div style={{ textAlign: "center", marginTop: "20px" }}>
//           <Spinner color="primary" />
//         </div>
//       ) : (
//         <>
//           <Row style={{ marginBottom: "20px" }}>
//             {/* Compliments Table */}
//             <Col md={6}>
//               <Card
//                 style={{
//                   boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
//                   borderRadius: "8px",
//                 }}
//               >
//                 <CardHeader
//                   style={{
//                     backgroundColor: "#559e83",
//                     color: "#fff",
//                     fontWeight: "bold",
//                     padding: "10px",
//                     borderRadius: "8px 8px 0 0",
//                   }}
//                 >
//                   Compliments by category
//                 </CardHeader>
//                 <CardBody
//                   style={{
//                     maxHeight: "400px",
//                     overflowY: "auto",
//                     padding: "15px",
//                   }}
//                 >
//                   {complimentsData?.getCatgoryWiseCompliments?.map(
//                     (category, index) => (
//                       <div key={index} style={{ marginBottom: "15px" }}>
//                         <Button
//                           color="link"
//                           onClick={() =>
//                             toggleComplimentCategory(category.Category)
//                           }
//                           style={{
//                             width: "100%",
//                             textAlign: "left",
//                             fontWeight:
//                               openComplimentCategory === category.Category
//                                 ? "bold"
//                                 : "normal",
//                             fontSize:
//                               openComplimentCategory === category.Category
//                                 ? "16px"
//                                 : "14px",
//                             backgroundColor:
//                               index % 2 === 0 ? "#f2f2f2" : "#e6e6e6",
//                             padding: "10px",
//                             borderRadius: "5px",
//                           }}
//                         >
//                           <h5>
//                             {category.Category}{" "}
//                             <Badge
//                               color="light"
//                               style={{
//                                 float: "right",
//                                 backgroundColor: "#ddd",
//                                 color: "#333",
//                                 padding: "5px 10px",
//                                 borderRadius: "5px",
//                               }}
//                             >
//                               {category.Phrases.length}
//                             </Badge>
//                           </h5>
//                         </Button>
//                         <Collapse
//                           isOpen={openComplimentCategory === category.Category}
//                         >
//                           {renderPhrases(category.Phrases)}
//                         </Collapse>
//                       </div>
//                     )
//                   )}
//                 </CardBody>
//               </Card>
//             </Col>

//             {/* Complaints Table */}
//             <Col md={6}>
//               <Card
//                 style={{
//                   boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
//                   borderRadius: "8px",
//                 }}
//               >
//                 <CardHeader
//                   style={{
//                     backgroundColor: "#ae5a41",
//                     color: "#fff",
//                     fontWeight: "bold",
//                     padding: "10px",
//                     borderRadius: "8px 8px 0 0",
//                   }}
//                 >
//                   Complaints by category
//                 </CardHeader>
//                 <CardBody
//                   style={{
//                     maxHeight: "400px",
//                     overflowY: "auto",
//                     padding: "15px",
//                   }}
//                 >
//                   {complaintsData?.getCatgoryWiseComplaints?.map(
//                     (category, index) => (
//                       <div key={index} style={{ marginBottom: "15px" }}>
//                         <Button
//                           color="link"
//                           onClick={() =>
//                             toggleComplaintCategory(category.Category)
//                           }
//                           style={{
//                             width: "100%",
//                             textAlign: "left",
//                             fontWeight:
//                               openComplaintCategory === category.Category
//                                 ? "bold"
//                                 : "normal",
//                             fontSize:
//                               openComplaintCategory === category.Category
//                                 ? "16px"
//                                 : "14px",
//                             backgroundColor:
//                               index % 2 === 0 ? "#f2f2f2" : "#e6e6e6",
//                             padding: "10px",
//                             borderRadius: "5px",
//                           }}
//                         >
//                           <h5>
//                             {category.Category}{" "}
//                             <Badge
//                               color="light"
//                               style={{
//                                 float: "right",
//                                 backgroundColor: "#ddd",
//                                 color: "#333",
//                                 padding: "5px 10px",
//                                 borderRadius: "5px",
//                               }}
//                             >
//                               {category.Phrases.length}
//                             </Badge>
//                           </h5>
//                         </Button>
//                         <Collapse
//                           isOpen={openComplaintCategory === category.Category}
//                         >
//                           {renderPhrases(category.Phrases)}
//                         </Collapse>
//                       </div>
//                     )
//                   )}
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>

//           {/* Grouped Bar Chart Section */}
//           <Row>
//             <Col md={12}>
//               <Card style={{ marginBottom: "20px" }}>
//                 <CardHeader
//                   style={{
//                     backgroundColor: "#c3cb71",
//                     color: "#fff",
//                     fontWeight: "bold",
//                     padding: "10px",
//                     borderRadius: "8px 8px 0 0",
//                   }}
//                 >
//                   Compliments vs Complaints
//                 </CardHeader>
//                 <CardBody>
//                   {complimentsData?.getCatgoryWiseCompliments &&
//                   complaintsData?.getCatgoryWiseComplaints ? (
//                     <Chart
//                       options={{
//                         chart: {
//                           type: "bar",
//                           stacked: true,
//                         },
//                         plotOptions: {
//                           bar: {
//                             horizontal: false,
//                             columnWidth: "55%",
//                             endingShape: "flat",
//                           },
//                         },
//                         dataLabels: {
//                           enabled: false,
//                         },
//                         xaxis: {
//                           categories: [
//                             ...new Set([
//                               ...complimentsData.getCatgoryWiseCompliments.map(
//                                 (item) => item.Category
//                               ),
//                               ...complaintsData.getCatgoryWiseComplaints.map(
//                                 (item) => item.Category
//                               ),
//                             ]),
//                           ],
//                         },
//                         yaxis: {
//                           title: {
//                             text: "Number of Mentions",
//                           },
//                         },
//                         colors: ["#28a745", "#dc3545"],
//                         legend: {
//                           position: "top",
//                         },
//                         tooltip: {
//                           shared: true,
//                           intersect: false,
//                         },
//                       }}
//                       series={[
//                         {
//                           name: "Compliments",
//                           data: [
//                             ...new Set([
//                               ...complimentsData.getCatgoryWiseCompliments.map(
//                                 (item) => item.Category
//                               ),
//                               ...complaintsData.getCatgoryWiseComplaints.map(
//                                 (item) => item.Category
//                               ),
//                             ]),
//                           ].map((category) => {
//                             const complimentCategory =
//                               complimentsData.getCatgoryWiseCompliments.find(
//                                 (item) => item.Category === category
//                               );
//                             return complimentCategory
//                               ? complimentCategory.Phrases.length
//                               : 0;
//                           }),
//                         },
//                         {
//                           name: "Complaints",
//                           data: [
//                             ...new Set([
//                               ...complimentsData.getCatgoryWiseCompliments.map(
//                                 (item) => item.Category
//                               ),
//                               ...complaintsData.getCatgoryWiseComplaints.map(
//                                 (item) => item.Category
//                               ),
//                             ]),
//                           ].map((category) => {
//                             const complaintCategory =
//                               complaintsData.getCatgoryWiseComplaints.find(
//                                 (item) => item.Category === category
//                               );
//                             return complaintCategory
//                               ? complaintCategory.Phrases.length
//                               : 0;
//                           }),
//                         },
//                       ]}
//                       type="bar"
//                       height={350}
//                     />
//                   ) : (
//                     <p>No data available for the chart.</p>
//                   )}
//                 </CardBody>
//               </Card>
//             </Col>

//             {/* Treemap Chart */}
//             <Col md={12}>
//               <Card style={{ marginBottom: "20px" }}>
//                 <CardHeader
//                   style={{
//                     backgroundColor: "#6c757d",
//                     color: "#fff",
//                     fontWeight: "bold",
//                     padding: "10px",
//                     borderRadius: "8px 8px 0 0",
//                   }}
//                 >
//                   Review categories
//                 </CardHeader>
//                 <CardBody>
//                   {complimentsData?.getCatgoryWiseCompliments &&
//                   complaintsData?.getCatgoryWiseComplaints ? (
//                     <Chart
//                       options={{
//                         chart: {
//                           type: "treemap",
//                         },
//                         colors: ["#28a745", "#dc3545"],
//                         legend: {
//                           show: true,
//                           position: "top",
//                         },
//                         plotOptions: {
//                           treemap: {
//                             distributed: true,
//                             enableShades: false,
//                           },
//                         },
//                         tooltip: {
//                           enabled: true,
//                         },
//                       }}
//                       series={[
//                         {
//                           data: [
//                             ...complimentsData.getCatgoryWiseCompliments.map(
//                               (item) => ({
//                                 x: item.Category,
//                                 y: item.Phrases.length,
//                                 fillColor: "#28a745",
//                               })
//                             ),
//                             ...complaintsData.getCatgoryWiseComplaints.map(
//                               (item) => ({
//                                 x: item.Category,
//                                 y: item.Phrases.length,
//                                 fillColor: "#dc3545",
//                               })
//                             ),
//                           ],
//                         },
//                       ]}
//                       type="treemap"
//                       height={350}
//                     />
//                   ) : (
//                     <p>No data available for the chart.</p>
//                   )}
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>

//           <Row>
//             <Col md={6}>
//               <Card style={{ marginBottom: "20px" }}>
//                 <CardHeader
//                   style={{
//                     backgroundColor: "#d9534f",
//                     color: "#fff",
//                     fontWeight: "bold",
//                     padding: "10px",
//                     borderRadius: "8px 8px 0 0",
//                   }}
//                 >
//                   Review rating distribution
//                 </CardHeader>
//                 <CardBody>
//                   {reviewsData?.worstReviews ? (
//                     (() => {
//                       const ratingRanges = [
//                         {
//                           label: "Rating: 2",
//                           min: 2,
//                           max: 2,
//                           color: "#dc3545",
//                         },
//                         {
//                           label: "Rating: 2 to less than 4",
//                           min: 2.1,
//                           max: 3.9,
//                           color: "#e55347",
//                         },
//                         {
//                           label: "Rating: 4 to less than 6",
//                           min: 4,
//                           max: 5.9,
//                           color: "#ff7f0e",
//                         },
//                         {
//                           label: "Rating: 6 to less than 8",
//                           min: 6,
//                           max: 7.9,
//                           color: "#ffc107",
//                         },
//                         {
//                           label: "Rating: 8 to less than 10",
//                           min: 8,
//                           max: 9.9,
//                           color: "#28a745",
//                         },
//                       ];

//                       const ratingCounts = ratingRanges.map(
//                         ({ min, max, label, color }) => ({
//                           label,
//                           count: reviewsData.worstReviews.filter(
//                             (review) =>
//                               review.newRating >= min && review.newRating <= max
//                           ).length,
//                           color,
//                         })
//                       );

//                       return (
//                         <Chart
//                           options={{
//                             chart: {
//                               type: "donut",
//                             },
//                             labels: ratingCounts.map(
//                               ({ label, count }) =>
//                                 `${label} - ${count} Reviews`
//                             ),
//                             colors: ratingCounts.map(({ color }) => color),
//                             legend: {
//                               position: "bottom",
//                             },
//                             responsive: [
//                               {
//                                 breakpoint: 480,
//                                 options: {
//                                   chart: {
//                                     width: 200,
//                                   },
//                                   legend: {
//                                     position: "bottom",
//                                   },
//                                 },
//                               },
//                             ],
//                           }}
//                           series={ratingCounts.map(({ count }) => count)}
//                           type="donut"
//                           height={350}
//                         />
//                       );
//                     })()
//                   ) : (
//                     <p>No data available for the chart.</p>
//                   )}
//                 </CardBody>
//               </Card>
//             </Col>

//             <Col md={12}>
//               <Card style={{ marginBottom: "20px" }}>
//                 <CardHeader
//                   style={{
//                     backgroundColor: "#5bc0de",
//                     color: "#fff",
//                     fontWeight: "bold",
//                     padding: "10px",
//                     borderRadius: "8px 8px 0 0",
//                   }}
//                 >
//                   Mentions over time
//                 </CardHeader>
//                 <CardBody>
//                   {classifyData?.getCategoryClassifyReport ? (
//                     <Chart
//                       options={{
//                         chart: {
//                           type: "line",
//                         },
//                         xaxis: {
//                           categories:
//                             classifyData.getCategoryClassifyReport.map(
//                               (item) => item._id
//                             ),
//                         },
//                         yaxis: {
//                           title: {
//                             text: "Number of Mentions",
//                           },
//                         },
//                         colors: ["#28a745"],
//                         legend: {
//                           position: "top",
//                         },
//                         tooltip: {
//                           shared: true,
//                           intersect: false,
//                         },
//                       }}
//                       series={[
//                         {
//                           name: "Total Mentions",
//                           data: classifyData.getCategoryClassifyReport.map(
//                             (item) => item.totalMentions
//                           ),
//                         },
//                       ]}
//                       type="line"
//                       height={350}
//                     />
//                   ) : (
//                     <p>No data available for the chart.</p>
//                   )}
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>
//         </>
//       )}
//     </div>
//   );
// };

// export default CategoryClassification;
