import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { dateFormatterDDMMYYYY } from "../../../utility/Utils";
import { Minus, Plus } from "react-feather";

const LogModal = ({ meterTrackerArray, date, showModal, onClose }) => {
  const [filteredDate, setFilteredDate] = useState([]);
  const [openCollapse, setOpenCollapse] = useState(null);
  const handleClose = () => {
    try {
      onClose(false);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleCollapse = (meterNumber) => {
    setOpenCollapse(openCollapse === meterNumber ? null : meterNumber);
  };

  useEffect(() => {
    if (meterTrackerArray?.length > 0) {
      const filterData = meterTrackerArray
        ?.filter(
          (data) => dateFormatterDDMMYYYY(data?.readingDate) === date?.date
        )
        ?.map((el) => {
          return { meterNumber: el?.meter_CA_Number, logs: el?.logs };
        });
      // ?.flat()
      // ?.sort((a, b) => b - a);

      setFilteredDate(filterData);
    }
  }, []);
  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader
        className="bg-transparent"
        toggle={handleClose}
        style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)" }}
      >
        Logs
      </ModalHeader>
      {/* {filteredDate?.map((el, i) => {
          return <p key={i}>{el}</p>;
        })} */}

      <ModalBody style={{ padding: "20px 40px" }}>
        {filteredDate?.length > 0 ? (
          filteredDate?.map((el, i) => (
            <Card key={el?.meterNumber}>
              <CardHeader>
                <Button
                  onClick={() => toggleCollapse(el?.meterNumber)} // Toggle collapse on click
                  style={{
                    // textAlign: "left",
                    display: "flex",
                    width: "100%",
                    padding: "10px",
                    fontSize: "16px",
                    justifyContent: "space-between",
                  }}
                  color="light"
                >
                  {/* {el?.meterNumber} */}
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginTop: "4px",
                      padding: "0px",
                    }}
                  >
                    {el?.meterNumber}
                  </span>
                  <span
                    style={{
                      padding: "0px",
                      cursor: "pointer",
                      fontSize: "20px",
                      // marginBottom: "px",
                      transform: !openCollapse
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                      transition: "transform 0.2s ease-in-out",
                    }}
                    onClick={() => toggleCollapse(el?.meterNumber)}
                    color="light"
                    size="md"
                  >
                    {/* &#x2304; */}
                    {openCollapse !== el?.meterNumber ? (
                      <Plus size={15} />
                    ) : (
                      <Minus size={15} />
                    )}
                  </span>
                </Button>
              </CardHeader>
              <Collapse
                isOpen={openCollapse === el?.meterNumber ? true : false}
              >
                <CardBody
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                    paddingRight: "10px",
                  }}
                >
                  <ul>
                    {[...el?.logs]?.reverse()?.map((log, idx) => (
                      <li key={idx}>{log}</li>
                    ))}
                  </ul>
                </CardBody>
              </Collapse>
            </Card>
          ))
        ) : (
          <h2 style={{ textAlign: "center" }}>No logs found</h2>
        )}
      </ModalBody>
    </Modal>
  );
};

export default LogModal;
