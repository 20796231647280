import { ColivingRoomRates } from "../../views/Coliving"
import BookingReport from "../../views/Report/BookingReport"
import ColivingBooking from "../../views/ColivingBooking/index"

const ColivingRoutes = [
    {
        path: "/coliving/colivingroomrates",
        component: ColivingRoomRates,
        exact: true,
    },
    {
        path: "/report/bookingReport?coLiving=true",
        exact: true,
        component: BookingReport,
    },
    {
        path: "/coliving/colivingBooking",
        exact: true,
        component: ColivingBooking,
    },
]


export default ColivingRoutes