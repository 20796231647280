import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_METER_MASTER = gql`
  mutation CreateMeterMaster($meterMasterInput:meterMasterInput){
    createMeterMaster(meterMasterInput:$meterMasterInput){
        meter_CA_Number
    }
  }
`

export const useCreateMeterMaster = () => {
    const [createMaster, { loading, data, error, refetch }] = useMutation(CREATE_METER_MASTER)

    useLoadingHandler(loading)

    const createHandler = (meterMasterInput) => {
        createMaster({
            variables: {
                meterMasterInput
            }
        })
    }

    return [createHandler, { data, error, refetch }]
}

export const UPDATE_METER_MASTER = gql`
 mutation updateMeterMaster($id:ID,$meterMasterInput:meterMasterInput){
    updateMeterMaster(id:$id,meterMasterInput:$meterMasterInput){
      meter_CA_Number
    }
 }
`

export const useUpdateMeterMaster = () => {
    const [updateMeterMaster, { loading, data, error, refetch }] = useMutation(UPDATE_METER_MASTER)

    useLoadingHandler(loading)

    const updateHandler = (id, meterMasterInput) => {
        updateMeterMaster({
            variables: {
                id, meterMasterInput
            }
        })
    }

    return [updateHandler, { data, error, refetch }]
}