import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";


export const UPDATE_CO_LIVING_ROOM_RATES = gql`
    mutation updateColivingHostel($updateArrayParams:[ColivingInputType]!){
        updateColivingHostel(updateArrayParams:$updateArrayParams){
             count
             success
             error
        }
    }
`

export const useGetUpdateColivingRoomRates = () => {
    const [updateColiving, { loading, data, error, refetch }] = useMutation(UPDATE_CO_LIVING_ROOM_RATES)

    useLoadingHandler(loading)

    const updateColivingHandler = (updateArrayParams) => {

        updateColiving({
            variables: {
                updateArrayParams
            }

        })
    }

    return [updateColivingHandler, { data, error, refetch }]
}

export const GET_COLIVING_BOOKING_EXPORT = gql`
    mutation getColivingBookingReportForExport($filter: filter) {
        getColivingBookingReportForExport(filter: $filter)
    }
`;

export const useGetColivingBookingForExport = () => {
  const [getColivingBookingForExportData, { loading, error, data, refetch }] =
    useMutation(GET_COLIVING_BOOKING_EXPORT);
    useLoadingHandler(loading);
    const getColivingBookingExportDataHandler = (filter) => {
        getColivingBookingForExportData({
            variables: {
                filter,
            },
        });
    };
    return [
        getColivingBookingExportDataHandler,
        { data, refetch, loading, error },
    ];
};