import React, { useState } from 'react'
import AttachmentComponent from './AttachmentComponent'
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    Label,
    Input,
    Button,
    ModalFooter,
} from "reactstrap";
import { Edit, Eye, File, Trash, UploadCloud } from 'react-feather';
const ImageModal = ({
    imageList,
    setImageList,
    editData,
    uploadType,
    userDepartment,
    data,
    canBeEdited,
    fieldName,
    isDynamic
}) => {
    const [showModal, setShowModal] = useState(false)
    const [showError, setShowError] = useState("")
    const handleClose = () => {
        const namesPresent = imageList?.some((item) => item?.imageName === "");
        if (!namesPresent) {
            setShowError(false)
            closeModal();
        } else {
            setShowError(true)
        }
    };
    const closeModal = () => {
        setShowModal(false);
    };

    const toggleModal = () => {
        setShowModal(true)
    }

    return (
        <div >

            <div style={{ display: "flex" }}>
                <div>
                    <AttachmentComponent
                        imageList={imageList}
                        setImageList={setImageList}
                        editData={editData}
                        uploadType={uploadType}
                        canBeEdited={false}
                        showInput={false}
                        isDynamic={isDynamic}
                    />
                </div>
                {
                    canBeEdited &&
                    <p style={{ height: "40px", width: "40px", marginLeft: "20px", cursor: "pointer" }} onClick={() => { toggleModal() }}>
                        <UploadCloud size={20} onClick={() => { toggleModal() }} color={`${imageList?.length > 0 ? "grey" : "red"}`} />
                    </p>
                }
            </div>
            <Modal
                isOpen={showModal}
                toggle={handleClose}
                className="modal-dialog-centered modal-md"
                backdrop="static"
                keyboard={false}
            >
                <ModalHeader
                    className="bg-transparent"
                    toggle={handleClose}
                >

                    <h5 style={{ display: "flex", justifyContent: "center" }}>{fieldName}</h5>
                    {
                        showError && <p style={{ display: "flex", justifyContent: "center", fontSize: "12px", color: "red" }}>please provide file name</p>
                    }
                </ModalHeader>
                <ModalBody className="px-sm-2 mx-50 pb-5">
                    <AttachmentComponent
                        imageList={imageList}
                        setImageList={setImageList}
                        editData={editData}
                        uploadType={uploadType}
                        canBeEdited={canBeEdited}
                        showInput={true}
                        isDynamic={isDynamic}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={handleClose}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    )
}

export default ImageModal