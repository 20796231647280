// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import {
  useUploadImage,
  useUpdateDestination,
} from "../../../../api/dataSource";
import { useParams } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailPortraitGenerator from "@uppy/thumbnail-generator";
import thumbnailLandscapeGenerator from "@uppy/thumbnail-generator";

import { DragDrop } from "@uppy/react";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

const Media = () => {
  const { id } = useParams();
  const [landscapeImage, setLandscapeImage] = useState([]);
  const [portraitImage, setPortraitImage] = useState([]);
  const state = useSelector((state) => state?.cms);
  const [videoUrl, setVideoUrl] = useState("");
  const [locationUrl, setLocationUrl] = useState("");
  const [altitude, setAltitude] = useState("");
  const [population, setPopulation] = useState("");
  const [portraitUploadImageHandler, { data: portraitImageData }] =
    useUploadImage();

  const [landscapeUploadImageHandler, { data: landscapeImageData }] =
    useUploadImage();

  const dispatch = useDispatch();

  const uppyPortraitImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });
  const uppyLandscapeImage = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  const [updateDestinationHandler, { data: updatedDestinationData }] =
    useUpdateDestination();

  useEffect(() => {
    if (state.tableData) {
      setPortraitImage([state.tableData?.portraitImage]);
      setLandscapeImage([state.tableData?.landscapeImage]);
      setVideoUrl(state.tableData?.videoUrl);
      setLocationUrl(state.tableData?.locationUrl);
      setAltitude(state.tableData?.altitude);
      setPopulation(state.tableData?.population);
    } else {
      setPortraitImage([]);
      setLandscapeImage([]);
      setVideoUrl("");
      setLocationUrl("");
      setAltitude("");
      setPopulation("");
    }
  }, [state.tableData]);

  uppyPortraitImage.use(thumbnailPortraitGenerator);

  uppyPortraitImage.on("thumbnail:generated", (file, preview) => {
    setPortraitImage([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppyPortraitImage.removeFile(file.id);
      return;
    }

    portraitUploadImageHandler(file.data);
    toaster("success", "Portrait image uploaded successfully");
  });

  uppyPortraitImage.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading portrait image: ${error.message}`);
  });

  uppyLandscapeImage.use(thumbnailLandscapeGenerator);

  uppyLandscapeImage.on("thumbnail:generated", (file, preview) => {
    setLandscapeImage([preview]);

    const fileName = file.name;

    console.log(file, "FILE");

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppyLandscapeImage.removeFile(file.id);
      return;
    }

    landscapeUploadImageHandler(file.data);
    toaster("success", "Landscape image uploaded successfully");
  });

  uppyLandscapeImage.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading landscape image: ${error.message}`);
  });

  const PortraitImageRenderPreview = () => {
    if (portraitImage.length) {
      return portraitImage.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };

  const LandscapeImageRenderPreview = () => {
    if (landscapeImage.length) {
      return landscapeImage.map((src, index) => (
        <img
          key={index}
          className="img-thumbnail rounded mt-2 mr-1"
          src={src}
          alt="avatar"
        />
      ));
    } else {
      return null;
    }
  };

  const [portraitImageURL, setPortraitImageURL] = useState(
    state?.tableData?.portraitImage
  );
  const [landscapeImageURL, setLandscapeImageURL] = useState(
    state?.tableData?.landscapeImage
  );

  useEffect(() => {
    if (landscapeImageData) {
      setLandscapeImageURL(landscapeImageData?.uploadImage?.url);
    }
    if (portraitImageData) {
      setPortraitImageURL(portraitImageData?.uploadImage?.url);
    }
  }, [landscapeImageData, portraitImageData]);

  useEffect(() => {
    if (updatedDestinationData?.updateDestination?.destination) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          portraitImage: portraitImageURL,
          landscapeImage: landscapeImageURL,
          videoUrl,
          locationUrl,
          altitude,
          population,
        })
      );
      toaster("success", "Important details updated successfully");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, landscapeImageURL, portraitImageURL, updatedDestinationData]);

  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      portraitImage:
        portraitImageURL === ""
          ? state?.tableData?.portraitImage
          : portraitImageURL,
      landscapeImage:
        landscapeImageURL === ""
          ? state?.tableData?.landscapeImage
          : landscapeImageURL,
      videoUrl,
      locationUrl,
      altitude,
      population,
      sectionName: "Important Details",
    };
    if (id) {
      updateDestinationHandler(id, data);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="6">
          <Label className="form-label" htmlFor="location">
            Portrait Image (Dimension - 1080 X 1350)
          </Label>
          <DragDrop
            id={1}
            note="Image size should be less than 500KB"
            uppy={uppyPortraitImage}
          />
          {PortraitImageRenderPreview()}
        </Col>
        <Col sm="6">
          <Label className="form-label" htmlFor="location">
            Landscape Image (Dimension - 1200 X 1200)
          </Label>
          <DragDrop
            id={2}
            note="Image size should be less than 500KB"
            uppy={uppyLandscapeImage}
          />
          {LandscapeImageRenderPreview()}
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="videoUrl">
              Video URL
            </Label>
            <Input
              type="text"
              value={videoUrl}
              id="videoUrl"
              name="videoUrl"
              placeholder="Video URL"
              onChange={(e) => setVideoUrl(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="locationUrl">
              Location URL
            </Label>
            <Input
              type="text"
              value={locationUrl}
              id="locationUrl"
              name="locationUrl"
              placeholder="Location URL"
              onChange={(e) => setLocationUrl(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="altitude">
              Altitude
            </Label>
            <Input
              type="text"
              value={altitude}
              id="altitude"
              name="altitude"
              placeholder="Altitude"
              onChange={(e) => setAltitude(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="population">
              Population
            </Label>
            <Input
              type="text"
              value={population}
              id="population"
              name="population"
              placeholder="Population"
              onChange={(e) => setPopulation(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Media;
