import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import Flatpickr from "react-flatpickr";
import SwitchIcon from "@components/common/switch-icon";
import Select from "react-select";
import { dateFormatter } from "@utils";
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";
import {
  useUpdateEventsAndActivities,
  useHostelList,
  useUploadImage,
} from "../../../api/dataSource";
import MyEditor from "@components/common/Editor";
import { EditorState } from "draft-js";
import { htmlToDraftConvert, inputDataConvertor } from "@utils";
import { Camera } from "react-feather";

const Overview = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const animatedComponents = makeAnimated();

  const state = useSelector((state) => state?.cms);
  const [title, setTitle] = useState(null);
  const [category, setCategory] = useState(null);
  const [schedulingFrequency, setSchedulingFrequency] = useState(null);
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [eventDate, setEventDate] = useState(new Date());
  const [eventStartDate, setEventStartDate] = useState(new Date());
  const [eventEndDate, setEventEndDate] = useState(new Date());
  const [weekDays, setWeekDays] = useState(null);
  const [months, setMonths] = useState(null);
  const [time, setTime] = useState(new Date());
  const [duration, setDuration] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [organiser, setOrganiser] = useState(null);
  const [description, setDescription] = useState(() =>
    EditorState.createEmpty()
  );
  const [price, setPrice] = useState(null);
  const [tax, setTax] = useState(null);
  const [totalInventory, setTotalInventory] = useState(null);
  const [published, setPublished] = useState(false);
  const [cutOffTime, setCutOffTime] = useState(null);
  const [fromTime, setFromTime] = useState(new Date());
  const [toTime, setToTime] = useState(new Date());
  const [hsnCode, setHsnCode] = useState(null);
  const [organiserIcon, setOrgainserIcon] = useState();
  const { data: allHostelsData } = useHostelList();
  const [
    updateEventsAndActivitiesHandler,
    { data: updateEventsAndActivitiesData },
  ] = useUpdateEventsAndActivities();

  const CategoryList = [
    { label: "Event", value: "Event" },
    { label: "Activity", value: "Activity" },
  ];
  const SchedulingFrequencyList = [
    { label: "once", value: "once" },
    { label: "weekly", value: "weekly" },
    // { label: "monthly", value: "monthly" },
  ];

  const WeekDaysList = [
    { label: "Sunday", value: 1 },
    { label: "Monday", value: 2 },
    { label: "Tuesday", value: 3 },
    { label: "Wednesday", value: 4 },
    { label: "Thursday", value: 5 },
    { label: "Friday", value: 6 },
    { label: "Saturday", value: 7 },
  ];

  const MonthsList = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const taxList = [
    { label: "0", value: "0" },
    { label: "5", value: "5" },
    { label: "12", value: "12" },
    { label: "18", value: "18" },
    { label: "28", value: "28" },
  ];

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelList?.map((hostel) => {
      return { label: hostel?.name, value: hostel?._id };
    });
    setHostelsList(HostelsList);
  }, [allHostelsData]);

  useEffect(() => {
    if (state.tableData) {
      setTitle(state.tableData?.title);
      setHostel(
        state.tableData?.hostel?.name
          ? {
            label: state.tableData?.hostel?.name,
            value: state.tableData?.hostel?._id,
          }
          : null
      );
      setCategory(
        state.tableData?.category
          ? {
            label: state.tableData?.category,
            value: state.tableData?.category,
          }
          : null
      );
      setSchedulingFrequency(
        state.tableData?.schedulingFrequency
          ? {
            label: state.tableData?.schedulingFrequency,
            value: state.tableData?.schedulingFrequency,
          }
          : null
      );
      setEventDate(state.tableData?.eventDate);
      setEventStartDate(state.tableData?.eventStartDate);
      setEventEndDate(state.tableData?.eventEndDate);

      let weekdaysDB = state.tableData?.weekDays?.map((weekday) => {
        return WeekDaysList.find((item) => item.value === weekday);
      });
      setWeekDays(weekdaysDB);

      let monthsDB = state.tableData?.months?.map((month) => {
        return MonthsList.find((item) => item.value === month);
      });
      setMonths(monthsDB);
      setTime(state.tableData?.time);
      setDuration(state.tableData?.duration);
      setEventType(state.tableData?.eventType);
      setOrganiser(state.tableData?.organiser);
      setDescription(htmlToDraftConvert(state.tableData?.description));
      setPrice(state.tableData?.price);
      // setTax(state.tableData?.tax);
      setTax({ label: state.tableData?.tax, value: state.tableData?.tax });
      setTotalInventory(state.tableData?.totalInventory);
      setPublished(state.tableData?.published);

      setCutOffTime(
        state.tableData?.cutOffTime === null ? "" : state.tableData?.cutOffTime
      );
      setFromTime(state.tableData?.fromTime);
      setToTime(state.tableData?.toTime);
      setHsnCode(state.tableData?.hsnCode);
      setOrgainserIcon(state.tableData?.organiserIcon)
    } else {
      setTitle(null);
      setHostel(null);
      setCategory(null);
      setSchedulingFrequency(null);
      setEventDate(new Date());
      setEventStartDate(new Date());
      setEventEndDate(new Date());
      setWeekDays(null);
      setMonths(null);
      setTime(new Date());
      setDuration(null);
      setEventType(null);
      setOrganiser(null);
      setDescription("");
      setPrice(null);
      setTax(null);
      setTotalInventory(null);
      setPublished(false);
      setCutOffTime(null);
      setFromTime(null);
      setToTime(null);
      setHsnCode(null);
      setOrgainserIcon(null)
    }
  }, [state.tableData]);

  const submitHandler = (e) => {
    e.preventDefault()
    let data = {
      title,
      hostel: hostel?.value,
      category: category?.value,
      schedulingFrequency: schedulingFrequency?.value,
      eventDate,
      eventStartDate,
      eventEndDate,
      weekDays: weekDays?.map((weekDay) => weekDay?.value),
      months: months?.map((month) => month?.value),
      time,
      duration,
      eventType,
      organiser,
      description: inputDataConvertor(description),
      price: parseFloat(price),
      // tax: parseFloat(tax),
      tax: parseFloat(tax?.value),
      totalInventory: parseInt(totalInventory),
      published,
      cutOffTime: parseInt(cutOffTime),
      fromTime,
      toTime,
      hsnCode,
      organiserIcon,
    };
    if (id) {
      updateEventsAndActivitiesHandler(id, data);
      toaster("success", "Overview updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };

  const [uploadImageHandler, { data: imageData, reset: imageReset }] =
    useUploadImage();

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    // setIndex(id);

    const fileName = e.target.files[0]?.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );

      return;
    }

    uploadImageHandler(
      e.target.files[0],
      `${fileName}/organiserImage`
    );

    toaster("success", "Item image uploaded successfully");
  };

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setOrgainserIcon(imageData?.uploadImage?.url);

    }
  }, [imageData?.uploadImage?.url]);

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        {/* // ! Category */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="category">
              Category*
            </Label>
            <Select
              isClearable={false}
              id="category"
              name="category"
              options={CategoryList}
              required
              value={category}
              onChange={(value) => setCategory(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        {/* // ! Hostel */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="hostel">
              Hostel*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="hostel"
              name="hostel"
              options={HostelsList}
              required
              value={hostel}
              onChange={(value) => setHostel(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        {/* // ! Title */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="title">
              Title*
            </Label>
            <Input
              type="text"
              id="title"
              name="title"
              value={title}
              required
              placeholder="Add title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Scheduling frequency */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="schedulingFrequency">
              Scheduling frequency*
            </Label>
            <Select
              isClearable={false}
              id="schedulingFrequency"
              name="schedulingFrequency"
              options={SchedulingFrequencyList}
              required
              value={schedulingFrequency}
              onChange={(value) => setSchedulingFrequency(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        {/* // ! Week days */}
        {schedulingFrequency?.value === "weekly" ? (
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="weekDays">
                Week Days
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                isMulti
                id="weekDays"
                name="weekDays"
                theme={selectThemeColors}
                components={animatedComponents}
                options={WeekDaysList}
                // required
                value={weekDays}
                onChange={(value) => setWeekDays(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        ) : null}
        {/* // ! Months */}
        {/* <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="months">
                Months
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                isMulti
                id="months"
                name="months"
                theme={selectThemeColors}
                components={animatedComponents}
                options={MonthsList}
                // isDisabled={true}
                required
                value={months}
                onChange={(value) => setMonths(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col> */}

        {/* // ! Event Date */}
        {schedulingFrequency?.value === "once" ? (
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="event date">
                Event Date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={eventDate}
                onChange={(date) =>
                  setEventDate(new Date(dateFormatter(new Date(date))))
                }
                id="eventDate"
                placeholder="Add Event Date"
              // required
              />
            </FormGroup>
          </Col>
        ) : null}

        {/* // ! Event Start & End Date */}
        {schedulingFrequency?.value === "weekly" ? (
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="event start end date">
                Event Start & End Date (YYYY-MM-DD)
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={[eventStartDate, eventEndDate]}
                onChange={(date) => {
                  setEventStartDate(new Date(dateFormatter(new Date(date[0]))));
                  setEventEndDate(new Date(dateFormatter(new Date(date[1]))));
                }}
                id="eventDate"
                options={{ mode: "range" }}
                placeholder="Add Event Date"
              // required
              />
            </FormGroup>
          </Col>
        ) : null}

        {/* // ! Time */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="time">
              Event Time (24 hrs format)*
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={time}
              onChange={(value) => setTime(value)}
              id="time"
              placeholder="Event Time"
              required
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
              }}
            />
          </FormGroup>
        </Col>
        {/* // ! Duration */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="duration">
              Duration (in hours)
            </Label>
            <Input
              type="text"
              id="duration"
              value={duration}
              name="duration"
              placeholder="Add duration"
              onChange={(e) => setDuration(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* // ! Event type */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="eventType">
              Event Type
            </Label>
            <Input
              type="text"
              value={eventType}
              id="eventType"
              name="eventType"
              placeholder="Add event type"
              onChange={(e) => setEventType(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* // ! Organiser */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="organiser">
              Organiser
            </Label>
            <Input
              type="text"
              value={organiser}
              id="organiser"
              name="organiser"
              placeholder="Add Organiser"
              onChange={(e) => setOrganiser(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* // ! Description */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="description">
              Description
            </Label>
            {/* <Input
              
              type="textarea"
              id="description"
              value={description}
              name="description"
              placeholder="Add Description"
              onChange={(e) => setDescription(e.target.value)}
            /> */}
            <div className="mt-1 ">
              <MyEditor
                id="description"
                editorState={description}
                setEditorState={(val) => setDescription(val)}
              />
            </div>
          </FormGroup>
        </Col>

        {/* // ! HSN Code */}
        <Col sm="6" style={{ display: "flex", flexDirection: "column" }}>
          Upload organiser image*
          <div>
            {organiserIcon && (
              <img
                key={1}
                className="img-thumbnail rounded mt-2 mr-1"
                src={organiserIcon}
                alt="avatar"
                style={{ height: "200px", width: "auto" }}
              />
            )}

            <label htmlFor={`myFile${id}`}>
              <p className="btn btn-sm btn-light">
                <Camera size={15} />
              </p>
              <input
                name={`myFile${id}`}
                id={`myFile${id}`}
                className="d-none"
                type="file"
                onChange={(e) => {
                  fileUploader(e, id);
                }}
              />
            </label>
          </div>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="hsnCode">
              HSN Code
            </Label>
            <Input
              type="text"
              id="hsnCode"
              name="hsnCode"
              value={hsnCode}
              placeholder="Add HSN Code"
              onChange={(e) => setHsnCode(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* // ! Price */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="price">
              Price*
            </Label>
            <Input
              type="text"
              id="price"
              value={price}
              name="price"
              placeholder="Add Price"
              required
              onChange={(e) => setPrice(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* // ! Tax */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="tax">
              Tax (%)*
            </Label>
            {/* <Input
              
              type="text"
              id="tax"
              value={tax}
              name="tax"
              placeholder="Add Tax"
              onChange={(e) => setTax(e.target.value)}
            /> */}
            <Select
              id="tax"
              name="tax"
              value={tax}
              options={taxList}
              required
              onChange={(value) => setTax(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        {/* // ! Total inventory */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="totalInventory">
              Total Inventory (per day)*
            </Label>
            <Input
              type="text"
              id="totalInventory"
              value={totalInventory}
              name="totalInventory"
              placeholder="Add total inventory"
              required
              onChange={(e) => setTotalInventory(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* // ! Cutoff Time*/}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="cutOffTime">
              Booking Cutoff Time (hours)
            </Label>
            <Input
              type="text"
              id="cutOffTime"
              value={cutOffTime}
              name="cutOffTime"
              placeholder="Add cutoff time"
              onChange={(e) => setCutOffTime(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* // ! From Time */}
        {/* <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromTime">
              Booking Active From Time (24 hrs format)
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={fromTime}
              onChange={(value) => setFromTime(value)}
              id="fromTime"
              placeholder="Time (24 hrs format)"
              // required
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
              }}
            />
          </FormGroup>
        </Col> */}

        {/* // ! To Time */}
        {/* <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="toTime">
              Booking Active To Time (24 hrs format)
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={toTime}
              onChange={(value) => setToTime(value)}
              id="toTime"
              placeholder="Time (24 hrs format)"
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
              }}
            />
          </FormGroup>
        </Col> */}

        {/* // ! Published */}
        <Col sm="6">
          <SwitchIcon
            checked={published}
            id="status"
            name="status"
            label="Status"
            handleChecked={(value) => setPublished(value)}
          />
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Overview;
