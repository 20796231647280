import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetHostelsNameandId } from "../../../api/dataSource";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import LaundryTrackerTable from './LaundryTrackerTable'
import { useGetLaundryMasterDataById } from "../../../api/LaundryMaster/Queries";
import { useGetLaundryTrackerByHostelId, useGetDormsSoldFromEzeeBooking, useGetPrivateSoldFromEzeeBooking } from "../../../api/LaundryTracker/Queries";

const YearList = [
  { label: "2025", value: "2025" },
  { label: "2024", value: "2024" },
  { label: "2023", value: "2023" },
];
const MonthsList = [
  { label: "January", value: "1" },
  { label: "February ", value: "2" },
  { label: "March", value: "3" },
  { label: "April ", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

const LaundryTracker = () => {
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [year, setYear] = useState({ label: "2025", value: "2025" });
  const [month, setMonth] = useState({
    label: MonthsList.find((i) => Number(i.value) === new Date().getMonth() + 1)
      .label,
    value: new Date().getMonth() + 1,
  });
  const [selectedHostel, setSelectedHostel] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [tableHeaderData, setTableHeaderData] = useState([]);
  const { data: allHostelsData } = useGetHostelsNameandId();
  const [dateArray, setDateArray] = useState([]);
  const [id, setId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [trackerData, setTrackerData] = useState(null);
  const [dormsData, setDormsData] = useState(null)
  const [privateData, setPrivateData] = useState(null)
  const { data: singleLaundryData, refetch: refetchSingleLaundryData } = useGetLaundryMasterDataById(id);
  const { data: laundryTrackerData, refetch: refetchLaundryTrackerData } = useGetLaundryTrackerByHostelId(id, selectedYear, selectedMonth);
  const { data: laundryMonthlyDormsData, refetch: refetchLaundryMonthlyDormsData } = useGetDormsSoldFromEzeeBooking(id, selectedYear, selectedMonth)
  const { data: laundryMonthlyPrivateData, refetch: refetchLaundryMonthlyPrivateData } = useGetPrivateSoldFromEzeeBooking(id, selectedYear, selectedMonth)

  useEffect(() => {
    if (id) {
      refetchSingleLaundryData();
      refetchLaundryTrackerData(); 
      refetchLaundryMonthlyDormsData();
      refetchLaundryMonthlyPrivateData();
    }
  }, [id, refetchSingleLaundryData, refetchLaundryTrackerData, refetchLaundryMonthlyDormsData, refetchLaundryMonthlyPrivateData]);
  
  useEffect(() => {
    if (singleLaundryData?.getLaundryMasterDataById) {
      setEditData(singleLaundryData.getLaundryMasterDataById);
    } else if (id) {
      setEditData(null);
    }
  }, [singleLaundryData, id]);
  
  useEffect(() => {
    if (laundryTrackerData?.getLaundryTrackerDataByHostelId) {
      setTrackerData(laundryTrackerData.getLaundryTrackerDataByHostelId);
    } else if (id) {
      setTrackerData(null);
    }
  }, [laundryTrackerData, id]);

  useEffect(() => {
    if (laundryMonthlyDormsData?.getDormsSoldFromEzeeBooking?.
      previousDayReadingArray) {
      setDormsData(laundryMonthlyDormsData.getDormsSoldFromEzeeBooking?.
        previousDayReadingArray);
    } else if (id) {
      setDormsData(null);
    }
  }, [laundryMonthlyDormsData, id, selectedYear, selectedMonth])

  useEffect(() => {
    if (laundryMonthlyPrivateData?.getPrivateSoldFromEzeeBooking?.previousDayReadingArray
    ) {
      setPrivateData(laundryMonthlyPrivateData.getPrivateSoldFromEzeeBooking?.previousDayReadingArray
      );
    } else if (id) {
      setPrivateData(null);
    }
  }, [laundryMonthlyPrivateData, id, selectedYear, selectedMonth])

  const getDatesOfMonth = (year, month) => {
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0);
    const currentDateOfMonth = new Date(startDate);
    var datesArray = [];
    while (currentDateOfMonth <= endDate) {
      datesArray.push({
        label: currentDateOfMonth.toLocaleDateString("en-CA"),
        key: currentDateOfMonth.toLocaleDateString("en-CA"),
      });
      currentDateOfMonth.setDate(currentDateOfMonth.getDate() + 1);
    }

    setTableHeaderData([
      // { label: "Item", key: "item" },
      // { label: "Unit Rate", key: "unitRate" },
      ...datesArray, 
    ]);
    setDateArray(datesArray);
  };

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelsNameandId?.map((hostel) => ({
      label: hostel?.name,
      value: hostel?._id,
    }));
    if (HostelsList?.length) {
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
      const filteredHostel =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return HostelsList?.find((hostel) => hostel?.value === hostelID);
            })
          : HostelsList;
      setHostelsList(filteredHostel);
    }
  }, [allHostelsData]);

  const submitHandler = (year, month) => {
    getDatesOfMonth(year, month);
    setSelectedHostel(hostel?.value); 
    setSelectedMonth(month);
    setSelectedYear(year);
    setId(hostel?.value);
  };

  const handleReset = () => {
    setHostel(null); 
    setYear({ label: "2025", value: "2025" }); 
    setMonth({
      label: MonthsList.find((i) => Number(i.value) === new Date().getMonth() + 1)
        .label,
      value: new Date().getMonth() + 1,
    });
    setTableHeaderData([]); 
    setSelectedHostel(null); 
    setSelectedMonth(null); 
    setSelectedYear(null);
    setDateArray([]); 
    setId(null); 
    setEditData(null);
    setTrackerData(null);
  };
  
  return (
    <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100 position-relative" style={{ zIndex: 9999 }}>
            <Label className="form-label" for="product">
              Hostel
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              id="hostel"
              name="hostel"
              value={hostel}
              options={HostelsList}
              onChange={(value) => {
                setHostel(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="year">
              Year
            </Label>
            <Select
              isSearchable={true}
              id="year"
              name="year"
              options={YearList}
              required
              value={year}
              onChange={(value) => setYear(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="month">
              Months
            </Label>
            <Select
              isSearchable={true}
              id="month"
              name="month"
              options={MonthsList}
              required
              value={month}
              onChange={(value) => setMonth(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col className="" sm="3" style={{ display: "flex", gap: "20px" }}>
          <Button
              style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginTop: "22px",
                  borderRadius: "5px",
                  padding: "10px 30px",
                  outline: "none",
                  border: "0",
              }}
              color="white"
              appearance="primary"
              type="submit"
              onClick={() => {
                submitHandler(Number(year?.value), Number(month?.value));
              }}
          >
              Search
          </Button>

          <Button
              style={{
                  backgroundColor: "#ff9999",
                  height: "40px",
                  marginTop: "22px",
                  //marginLeft: "40px",
                  borderRadius: "5px",
                  padding: "10px 30px",
                  outline: "none",
                  border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={handleReset}
          >
              Reset
          </Button>
        </Col>
      </Row>

        <LaundryTrackerTable
          tableHeaderData={tableHeaderData}
          dateArray={dateArray}
          allHostelsData={allHostelsData}
          setId={setId}
          masterData={editData}
          data={trackerData}
          refetch={refetchLaundryTrackerData}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          dormsData={dormsData}
          privateData={privateData}
        />
    </div>
  )
};

export default LaundryTracker;
