import { Modal, ModalHeader, ModalBody } from "reactstrap";

const LogsModal = ({ showModal, onClose, data = [], date, allRoomsNo = [] }) => {

  const handleClose = () => onClose(false);

  const logsByRoom = allRoomsNo
  .map((roomNo) => ({
    roomNo,
    logs: (data || [])
      .flatMap((item) => item?.logs || [])
      .filter((log) => log.includes(`room no. ${roomNo}`)),
  }))
  .filter((room) => room.logs.length > 0); 


  return (
    <Modal isOpen={showModal} toggle={handleClose} className="modal-dialog-centered modal-lg">
      <ModalHeader className="bg-transparent" toggle={handleClose}>
        Date: {date}
      </ModalHeader>
      <ModalBody className="px-sm-5 mx-50 pb-5">
        {logsByRoom.length > 0 ? (
          logsByRoom.map(({ roomNo, logs }) => (
            <div key={roomNo}>
              <h4>Room No: {roomNo}</h4>
              <ul>
                {logs.map((log, index) => (
                  <li key={index}>{log}</li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <p>No logs available</p>
        )}
      </ModalBody>
    </Modal>
  );
};

export default LogsModal;
