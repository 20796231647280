import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler } from "@utils";
import { CSVLink } from "react-csv";
import {
  useGetMemberShips,
  useGetAllMembershipList,
} from "../../../api/dataSource";
import { useGetHostelBookingOfGuest } from "../../../api/HostelBooking/Queries";
import { useUpdateMemberships } from "../../../api/Memberships/Mutation";
import SwitchIcon from "@components/common/switch-icon";

const tableHeaderData = [
  "Id",
  "Type",
  "Reservation No",
  "Name",
  "Mobile",
  "Email",
  "Start Date - End Date",
  "Membership Amount",
  "Discount Percent",
  "Cashback Percent",
  "Membership Tax",
  "Total Amount",
  "Travel Kit Send",
];

const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  // second: "numeric",
  hour12: true,
  timeZone: "Asia/Kolkata",
};

const MemberShipsTable = ({ openModal, data, headers, strainer, refetch }) => {
  const [tableData, setTableData] = useState(data);
  const [exportMembership, setExportMembership] = useState([]);
  const [userId, setUserId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewData, setViewData] = useState(null);
  const { data: membershipData } = useGetMemberShips(strainer);
  const { data: exportMembershipData } = useGetAllMembershipList(strainer);

  const { data: GuestHostelBookingData } = useGetHostelBookingOfGuest(userId);

  const [updateMembershipHandler, { data: updatdedMembershipData }] =
    useUpdateMemberships();

  const membershipTypeCount =
    membershipData?.getMemberships?.membershipTypeCount;
  const totalMembershipAmount =
    membershipData?.getMemberships?.totalMembershipAmount;

  let totalMembershipCount = 0;
  let explorerCount;
  let voyagerCount;
  let free_explorerCount;
  let wandererCount;

  for (let i = 0; i < membershipTypeCount?.length; i++) {
    totalMembershipCount += membershipTypeCount[i]?.count;

    if (membershipTypeCount[i]?._id === "EXPLORER") {
      explorerCount = membershipTypeCount[i]?.count;
    }
    if (membershipTypeCount[i]?._id === "FREE_EXPLORER") {
      free_explorerCount = membershipTypeCount[i]?.count;
    }
    if (membershipTypeCount[i]?._id === "WANDERER") {
      wandererCount = membershipTypeCount[i]?.count;
    }
    if (membershipTypeCount[i]?._id === "VOYAGER") {
      voyagerCount = membershipTypeCount[i]?.count;
    }
  }

  useEffect(() => {
    if (GuestHostelBookingData?.hostelBookingOfGuest?.length) {
      setViewData(GuestHostelBookingData?.hostelBookingOfGuest);
    } else {
      setViewData(null);
    }
  }, [GuestHostelBookingData?.hostelBookingOfGuest]);

  useEffect(() => {
    if (exportMembershipData?.getAllMemberships?.length > 0) {
      setExportMembership(exportMembershipData?.getAllMemberships);
    } else {
      setExportMembership([]);
    }
  }, [exportMembershipData?.getAllMemberships]);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const handleRowClick = (row) => {
    setUserId(row?.guestId);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setUserId(null);
    setModalOpen(false);
  };

  const createObject = (value, row, e) => {
    let data = {
      travelKitSend: value,
    };
    if (row?._id) {
      updateMembershipHandler(row?._id, data);
      refetch();
    }
  };

  return (
    <Row>
      <Col>
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CardHeader>
              <CardTitle>
                <div>
                  <h2>
                    Membership ( {totalMembershipCount} Counts ) | Total Revenue
                    ( &#x20B9;{totalMembershipAmount} )
                  </h2>
                </div>
              </CardTitle>
              &nbsp;&nbsp;
              <div>
                <h4>
                  <Badge color="light-primary">
                    EXPLORER ({explorerCount ? explorerCount : 0})
                  </Badge>
                  |
                  <Badge color="light-danger">
                    FREE_EXPLORER ({free_explorerCount ? free_explorerCount : 0}
                    )
                  </Badge>
                  |
                  <Badge color="light-warning">
                    VOYAGER ({voyagerCount ? voyagerCount : 0})
                  </Badge>
                  |
                  <Badge color="light-info">
                    WANDERER ({wandererCount ? wandererCount : 0})
                  </Badge>
                </h4>
              </div>
            </CardHeader>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
                gap: "1rem",
              }}
            >
              <Button.Ripple
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              >
                Add Member
              </Button.Ripple>

              <CSVLink
                headers={headers}
                data={exportMembership}
                filename={`membership_list.csv`}
              >
                <Button.Ripple
                  className="btn-icon"
                  color="success"
                  appearance="success"
                  type="button"
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            </div>
          </div>
          <Table responsive sm="small" hover>
            <thead>
              <tr>
                {tableHeaderData.map((header) => {
                  return <th key={uuidv4()}>{header}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((row) => (
                <tr
                // key={uuidv4()}
                // onClick={() => {
                //   handleRowClick(row);
                // }}
                // style={{ cursor: "pointer" }}
                >
                  {/* <div
                    key={uuidv4()}
                    onClick={() => {
                      handleRowClick(row);
                    }}
                    style={{ cursor: "pointer" }}
                  > */}
                  <td
                    key={uuidv4()}
                    onClick={() => {
                      handleRowClick(row);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Badge color="light-success">{row?.membershipId}</Badge>
                  </td>
                  <td>
                    <Badge
                      color={
                        row?.membershipType === "WANDERER"
                          ? "light-info"
                          : row?.membershipType === "EXPLORER"
                          ? "light-primary"
                          : row?.membershipType === "VOYAGER"
                          ? "light-warning"
                          : row?.membershipType === "FREE_EXPLORER"
                          ? "light-danger"
                          : null
                      }
                    >
                      {row?.membershipType}
                    </Badge>
                  </td>
                  {/* </div> */}

                  <td>
                    <h6>{row?.reservationNo}</h6>
                  </td>
                  <td>{row?.fullName}</td>
                  <td>
                    <Badge color="light-secondary">{row?.mobile}</Badge>
                  </td>
                  <td>{row?.email}</td>
                  <td>
                    {row?.startDate ? dateFormatHandler(row?.startDate) : ""}-
                    {row?.endDate ? dateFormatHandler(row?.endDate) : ""}
                  </td>
                  <td>
                    <h6>{row?.membershipAmount}/-</h6>
                  </td>
                  <td>
                    <h6>{row?.discountPercentage}%</h6>
                  </td>
                  <td>
                    <h6>{row?.cashbackPercentage}%</h6>
                  </td>
                  <td>
                    <h6>{row?.membershipTax}</h6>
                  </td>
                  <td>
                    <h6>{row?.totalAmount}</h6>
                  </td>
                  <td>
                    <SwitchIcon
                      checked={row?.travelKitSend}
                      id={row?._id}
                      name="travelKitSend"
                      handleChecked={(value) => createObject(value)}
                      onClick={(e) => createObject(!row.travelKitSend, row, e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Modal isOpen={modalOpen} toggle={handleModalClose} size="xl">
            <ModalHeader toggle={handleModalClose}>Booking Details</ModalHeader>

            <ModalBody style={{ maxHeight: "85vh", overflowY: "auto" }}>
              {userId && viewData?.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Res No</th>
                      <th>Status</th>
                      <th>Booking Date/Time</th>
                      <th>Coupon</th>
                      <th>Guest Name</th>
                      <th>Mobile</th>
                      <th>Hostel Name</th>
                      <th>Checkin - Checkout</th>
                      <th>Razorpay</th>
                      <th>Wallet</th>
                      <th>Refund Type/Id/Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewData?.map((guest) => (
                      <tr>
                        <td>
                          <Badge color="light-info">
                            {guest?.providerRefId}
                          </Badge>
                        </td>
                        <td>
                          <Badge
                            color={
                              guest?.status === "confirm"
                                ? "light-success"
                                : guest?.status === "pending"
                                ? "light-info"
                                : guest?.status === "failure"
                                ? "light-danger"
                                : "light-warning"
                            }
                          >
                            {guest?.status}
                          </Badge>
                        </td>
                        <td>
                          {new Intl.DateTimeFormat("en-IN", options).format(
                            new Date(guest?.createdAt)
                          )}
                        </td>
                        <td>
                          <Badge className="text-dark" color="light-secondary">
                            {guest?.coupon?.promoCode}
                          </Badge>
                        </td>
                        <td>{guest?.contactDetails?.name}</td>
                        <td>{guest?.contactDetails?.mobile}</td>
                        <td>{guest?.hostelId?.name}</td>
                        <td>
                          {guest?.checkinDate
                            ? dateFormatHandler(guest?.checkinDate)
                            : ""}
                          -
                          {guest?.checkinDate
                            ? dateFormatHandler(guest?.checkoutDate)
                            : ""}
                        </td>
                        <td>{guest?.payableAmount}</td>
                        <td>{guest?.walletDebit?.toFixed(2)}</td>
                        <td>
                          <Badge
                            className="text-dark"
                            color={
                              guest?.refundType === "Wallet"
                                ? "primary"
                                : "info"
                            }
                          >
                            {guest?.refundType}
                          </Badge>
                          {guest?.refundType ? " / " : ""}
                          {guest?.providerRefundRefId}
                          <br />
                          {guest?.refundType && "Razorpay: "}
                          {guest?.razorpayRefund?.toFixed(2)}
                          <br />
                          {guest?.refundType && "Wallet: "}
                          {guest?.walletRefund?.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p></p>
              )}
            </ModalBody>
          </Modal>
        </Card>
      </Col>
    </Row>
  );
};

export default MemberShipsTable;
