import React, { useEffect, useState } from "react";
import OccupancyTrackerTable from "./OccupancyTrackerTable";
import { useGetOccupancyTrackerReport, useAllGetOccupancyTrackerReport } from "../../../api/dataSource";
import { useSyncDailyOccupancyTracker } from '../../../api/OccupancyTracker/Mutations'
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { dateFormatHandler, dateFormatter } from "@utils";
// import { CSVLink } from "react-csv";
import { useHostelList, useGenerateSingleOccupancyDataForExport, useGenerateMultipleOccupancyDataForExport } from "../../../api/dataSource";
import { selectThemeColors } from "@utils";
import toaster from "@components/common/Toaster";

const OccupancyTracker = () => {
  const [tableData, setTableData] = useState([]);
  const [date, setDate] = useState(dateFormatter(new Date()));
  const [multiDates, setMultiDates] = useState(null);
  const [hostelIds, setHostelIds] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const { data: allHostelsData } = useHostelList();
  const [searchDate, setSearchDate] = useState(dateFormatter(new Date()));
  const [searchMultipleDates, setSearchMultipleDates] = useState([]);
  const [searchHostelIds, setSearchHostelIds] = useState(null);
  const [dateType, setDateType] = useState({ label: "Single", value: "Single" });

  const { data, refetch } = useGetOccupancyTrackerReport(
    searchDate,
    searchHostelIds?.length > 0
      ? searchHostelIds?.map((hostelId) => hostelId?.value)
      : []
  );

  const { data: allDatesData, refetch: refetchAllDates } = useAllGetOccupancyTrackerReport(
    searchMultipleDates,
    searchHostelIds?.length > 0
      ? searchHostelIds?.map((hostelId) => hostelId?.value)
      : []
  );

  const [syncDailyOccupancyTrackerHandler, { data: syncData, refetch: syncRefetch }] = useSyncDailyOccupancyTracker();

  const [getSingleOccupancyExportDataHandler, { data: singleDateOccupancyForExport }] =
      useGenerateSingleOccupancyDataForExport();

  const [getMultipleOccupancyExportDataHandler, { data: multipleDateOccupancyForExport }] =
      useGenerateMultipleOccupancyDataForExport();
  // const { data, refetch } = useGetOccupancyTrackerReport(
  //   searchDate,
  //   hostelIds.length > 0 ? hostelIds.map((hostelId) => hostelId?.value) : [],
  //   shouldSkip
  // );

  // useEffect(() => {
  //   refetch();
  // }, [hostelIds]);

  // useEffect(() => {
  //   refetch();
  // }, [date]);

  useEffect(() => {
    if (dateType.value === "Single" && data?.getOccupancyTrackerReport) {
      setTableData(data.getOccupancyTrackerReport);
    } else if (dateType.value === "Multiple" && allDatesData?.getMultiDateOccupancyTrackerReport) {
      setTableData(allDatesData.getMultiDateOccupancyTrackerReport);
    } else {
      setTableData([]);
    }
  }, [data, allDatesData, dateType]);

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  const DateTypeList = [
    { label: "Single", value: 'Single' },
    { label: "Multiple", value: 'Multiple' },
  ];

  const getOccupancyTrakerData = () => {
    setSearchHostelIds(hostelIds);
    if (dateType.value === "Single") {
      setSearchDate(date);
      refetch();
    } else {
      setSearchMultipleDates(multiDates);
      refetchAllDates();
    }
  };

  const handleResetData = () => {
    setTableData([]);
    setSearchHostelIds(null);
    setDate(dateFormatter(new Date()));
    setMultiDates([]);
    setSearchDate(dateFormatter(new Date()));
    setSearchMultipleDates([]);
    setDateType({ label: "Single", value: "Single" })
    setHostelIds([]);
  };

  useEffect(() => {
    if (syncData?.syncDailyOccupancyTracker) {
      const { status, message } = syncData.syncDailyOccupancyTracker;
      if (status) {
          toaster("success", "Sync started. Please wait for approximately 10 minutes to complete.");
      } else {
          toaster("error", message || "Failed to sync data");
      }
    }
}, [syncData]);

  const handleSync = () => {
    syncDailyOccupancyTrackerHandler(date);
  };

  useEffect(() => {
    if (singleDateOccupancyForExport?.generateSingleOccupancyExport?.data) {
      if (singleDateOccupancyForExport?.generateSingleOccupancyExport?.data.length > 0) {
        const byteArray = new Uint8Array(
          singleDateOccupancyForExport?.generateSingleOccupancyExport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `singleDateOccupancyData_pg.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [singleDateOccupancyForExport]);

  useEffect(() => {
    if (multipleDateOccupancyForExport?.generateMultipleOccupancyExport?.data) {
      if (multipleDateOccupancyForExport?.generateMultipleOccupancyExport?.data.length > 0) {
        const byteArray = new Uint8Array(
          multipleDateOccupancyForExport?.generateMultipleOccupancyExport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `multipleDateOccupancyData_pg.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [multipleDateOccupancyForExport]);

  const handleExport = () => {
    const hostelIdArray = searchHostelIds?.map((h) => h?.value) || [];
    if (dateType.value === "Single") {
      getSingleOccupancyExportDataHandler(searchDate, hostelIdArray);
    } else {
      getMultipleOccupancyExportDataHandler(searchMultipleDates, hostelIdArray);
    }
  };

  return (
    (localStorage.getItem("modules")?.includes("Reports") ||
      localStorage.getItem("modules")?.includes("Owner")) && (
      <div>
        <Row>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="dateType">
                Date Type *
              </Label>
              <Select
                isSearchable={true}
                isClearable={false}
                id="dateType"
                name="dateType"
                value={dateType}
                options={DateTypeList}
                onChange={(value) => setDateType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {dateType.value === "Single" ? (
            <Col sm="2">
              <FormGroup className="w-100">
                <Label className="form-label" for="date">Select Date *</Label>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  value={date}
                  onChange={(date) => setDate(dateFormatter(new Date(date)))}
                  id="date"
                  placeholder="Select Date"
                />
              </FormGroup>
            </Col>
          ) : (
            <Col sm="2">
              <FormGroup className="w-100">
                <Label className="form-label" for="multiDates">Select Dates *</Label>
                <Flatpickr
                  options={{ mode: "multiple" }}
                  className="form-control bg-white border-dark text-dark"
                  value={multiDates}
                  onChange={(selectedDates) => {
                    const formattedDates = selectedDates.map((d) => dateFormatter(d)); 
                    setMultiDates(formattedDates); 
                  }}
                  id="multiDates"
                  placeholder="Select Dates"
                />
              </FormGroup>
            </Col>
          )}

          <Col sm="4" style={{ zIndex: "5" }}>
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Hostel*
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostelIds}
                options={HostelsList}
                onChange={(value) => {
                  setHostelIds(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
                style={{ zIndex: "4" }}
                // style={{ position: "sticky", top: 0 }}
              />
            </FormGroup>
          </Col>
          <Col sm="2" style={{ display: "flex" }}>
            <Button
              onClick={getOccupancyTrakerData}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                // marginRight: "10px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
            >
              Search
            </Button>

            <Button
              onClick={handleResetData}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft: "10px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="danger"
              type="button"
            >
              Reset
            </Button>

            <Button
            onClick= {handleSync}
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginLeft:"10px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
            >
              Sync
            </Button>

            <Button
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="success"
              appearance="success"
              type="button"
              onClick={handleExport}
            >
              Export
            </Button>
          </Col>
        </Row>
        {tableData.length > 0 && <OccupancyTrackerTable data={tableData} dates={dateType.value === "Single" ? [searchDate] : searchMultipleDates}  />}
        {tableData.length == 0 && (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            No Record Found
          </h2>
        )}
      </div>
    )
  );
};

export default OccupancyTracker;
