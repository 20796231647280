import Supply from "../../views/KYH/Supply";
import AddSupplyTabs from "../../views/KYH/Supply/AddSupplyTabs/index";

const KYHRoutes = [
  {
    path: "/kyh/supply",
    component: Supply,
    exact: true,
  },
  {
    path: "/kyh/edit/supply/:id",
    component: AddSupplyTabs,
    exact: true,
    meta: {
      navLink: "/kyh",
    },
  },
];

export default KYHRoutes;
