import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export default function LogsModal(props) {
  const { showModal, empData, empRosterData, onClose } = props;
  const [employee, setEmployee] = useState(null);
  const [expandedRosterDate, setExpandedRosterDate] = useState(null);

  const handleClose = () => {
    try {
      onClose(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (empData) {
      setEmployee(
        empRosterData?.getEmployeeRosterByHostelId
          ?.filter((emp) => emp?.empId?._id === empData?._id)
          ?.map((emp) => ({
            rosterDate: emp?.rosterDate,
            logs: emp?.logs,
          }))
      );
    }
  }, [empData, empRosterData]);

  const groupedLogs = {};
  let result = [];

  if (employee?.length > 0) {
    employee.forEach(({ rosterDate, logs }) => {
      groupedLogs[rosterDate] = (groupedLogs[rosterDate] || []).concat(logs);
    });

    result = Object.entries(groupedLogs).map(([rosterDate, logs]) => ({
      rosterDate: rosterDate,
      logs: logs,
    }));
  }

  const toggleAccordion = (rosterDate) => {
    setExpandedRosterDate((prev) => (prev === rosterDate ? null : rosterDate));
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      {result?.length > 0 ? (
        <>
          <ModalHeader
            className="bg-transparent"
            toggle={handleClose}
            style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)" }}
          >
            <h3
              style={{
                fontSize: "22px",
                // marginBottom: "20px",
                fontWeight: "500",

                // color: "#f3f4f6",
              }}
            >
              Employee Name : {empData?.fullName} ({empData?.empCode})
            </h3>
          </ModalHeader>
          <ModalBody style={{ padding: "20px 40px" }}>
            <h3
              style={{
                fontSize: "20px",
                marginBottom: "20px",
                fontWeight: "400",
                // color: "#f3f4f6",
              }}
            >
              Roster Logs
            </h3>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "12px" }}
            >
              {result.map((data, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: "12px",
                    padding: "16px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                    transition: "all 0.3s ease-in-out",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      // color: "#f3f4f6",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => toggleAccordion(data?.rosterDate)}
                  >
                    {new Date(data?.rosterDate).toLocaleDateString("en-IN", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                    <span
                      style={{
                        transform:
                          expandedRosterDate === data?.rosterDate
                            ? "rotate(180deg)"
                            : "rotate(0)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      ▼
                    </span>
                  </h2>
                  {expandedRosterDate === data?.rosterDate && (
                    <ul
                      style={{
                        marginTop: "10px",
                        maxHeight: "200px",
                        overflowY: "auto",
                        paddingLeft: "12px",
                        listStyleType: "none",
                      }}
                    >
                      {data?.logs?.map((log, logIndex) => (
                        <li
                          key={logIndex}
                          style={{
                            fontSize: "14px",
                            // color: "#e5e7eb",
                            borderLeft: "4px solid #3b82f6",
                            paddingLeft: "12px",
                            marginBottom: "6px",
                          }}
                        >
                          {log}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader className="bg-transparent" toggle={handleClose} />
          <ModalBody
            style={{
              textAlign: "center",
              // padding: "20px",
              // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
              // backgroundColor: "#2d3748",
            }}
          >
            <h3
              style={{
                fontSize: "18px",
                fontWeight: "500",
                //  color: "#9ca3af"
                paddingBottom: "20px",
              }}
            >
              Logs not available
            </h3>
          </ModalBody>
        </>
      )}
    </Modal>
  );
}
// import { useEffect, useState } from "react";
// import { Modal, ModalHeader, ModalBody } from "reactstrap";

// export default function LogsModal(props) {
//   const { showModal, empData, empRosterData, onClose } = props;
//   const [employee, setEmployee] = useState(null);
//   const [expandedRosterDate, setExpandedRosterDate] = useState(null); // Track which date is expanded

//   const handleClose = () => {
//     try {
//       onClose(false);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     if (empData) {
//       setEmployee(
//         empRosterData?.getEmployeeRosterByHostelId
//           ?.filter((emp) => emp?.empId?._id === empData?._id)
//           ?.map((emp) => ({
//             rosterDate: emp?.rosterDate,
//             logs: emp?.logs,
//           }))
//       );
//     }
//   }, [empData, empRosterData]);

//   const groupedLogs = {};
//   let result = [];

//   if (employee?.length > 0) {
//     employee.forEach(({ rosterDate, logs }) => {
//       groupedLogs[rosterDate] = (groupedLogs[rosterDate] || []).concat(logs);
//     });

//     result = Object.entries(groupedLogs).map(([rosterDate, logs]) => ({
//       rosterDate: rosterDate,
//       logs: logs,
//     }));
//   }

//   // Handle accordion toggle
//   const toggleAccordion = (rosterDate) => {
//     if (expandedRosterDate === rosterDate) {
//       setExpandedRosterDate(null); // Collapse if the same rosterDate is clicked again
//     } else {
//       setExpandedRosterDate(rosterDate); // Expand the clicked rosterDate
//     }
//   };

//   return (
//     <Modal
//       isOpen={showModal}
//       toggle={handleClose}
//       className="modal-dialog-centered modal-lg"
//     >
//       {result?.length > 0 ? (
//         <>
//           <ModalHeader className="bg-transparent" toggle={handleClose}>
//             Employee Name : {empData?.fullName} ({empData?.empCode})
//           </ModalHeader>
//           <ModalBody className="px-sm-5 mx-50 pb-5">
//             <h3>Roster Logs</h3>

//             <div className="p-4 space-y-4">
//               {result?.map((data, index) => (
//                 <div
//                   key={index}
//                   className=" dark:bg-gray-800 rounded-2xl shadow-md p-6"
//                 >
//                   <h2
//                     className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 cursor-pointer"
//                     onClick={() => toggleAccordion(data?.rosterDate)}
//                   >
//                     {new Date(data?.rosterDate).toLocaleDateString("en-IN", {
//                       day: "numeric",
//                       month: "long",
//                       year: "numeric",
//                     })}
//                   </h2>
//                   {expandedRosterDate === data?.rosterDate && (
//                     <ul
//                       className="space-y-2 max-h-40 overflow-y-auto pr-2"
//                       style={{
//                         scrollbarWidth: "thin",
//                         scrollbarColor: "#888 transparent",
//                       }}
//                     >
//                       {data?.logs?.map((log, logIndex) => (
//                         <li
//                           key={logIndex}
//                           className="text-sm text-gray-700 dark:text-gray-300 border-l-4 border-blue-500 pl-3"
//                         >
//                           {log}
//                         </li>
//                       ))}
//                     </ul>
//                   )}
//                 </div>
//               ))}
//             </div>
//           </ModalBody>
//         </>
//       ) : (
//         <>
//           <ModalHeader
//             className="bg-transparent"
//             toggle={handleClose}
//           ></ModalHeader>
//           <ModalBody className="px-sm-5 mx-50 pb-5">
//             <h3>Logs not available</h3>
//           </ModalBody>
//         </>
//       )}
//     </Modal>
//   );
// }
