//added by nitish

import gql from "graphql-tag";

export const UPDATE_BASIC_GUEST_DETAILS = gql`
  mutation updateBasicGuestDetails(
    $_id: ID
    $guestDetailsInput: guestDetailsInput
  ) {
    updateBasicGuestDetails(_id: $_id, guestDetailsInput: $guestDetailsInput) {
      reservationId
      phoneNumber
      emailId
      roomNo
    }
  }
`;

export const UPDATE_GUEST_FEEDBACK_DETAILS = gql`
  mutation updateGuestFeedbackDetails(
    $_id: ID
    $guestFeedbackDetailsInput: guestFeedbackDetailsInput
  ) {
    updateGuestFeedbackDetails(
      _id: $_id
      guestFeedbackDetailsInput: $guestFeedbackDetailsInput
    ) {
      guestFeedbackDetails {
        wasCommunicationStablished
        didTheyInform
        wereTheySatisfied
      }
    }
  }
`;

export const UPDATE_HOSTEL_FEEDBACK_DETAILS = gql`
  mutation updateHostelFeedbackDetails(
    $_id: ID
    $hostelFeedbackDetailsInput: hostelFeedbackDetailsInput
  ) {
    updateHostelFeedbackDetails(
      _id: $_id
      hostelFeedbackDetailsInput: $hostelFeedbackDetailsInput
    ) {
      hostelFeedbackDetails {
        knowAboutThisInstance
        resolveInTime
        description
      }
    }
  }
`;

export const UPDATE_CX_DETAILS = gql`
  mutation updateCXDetails($_id: ID, $cxDetailsInput: cxDetailsInput) {
    updateCXDetails(_id: $_id, cxDetailsInput: $cxDetailsInput) {
      cxDetails {
        cxSummary
        Justified
        resolution
        coupon
        couponValidity
      }
    }
  }
`;

export const UPDATE_CX_TICKET = gql`
  mutation updateCXTicket($_id: ID, $ticketInput: ticketInput) {
    updateCXTicket(_id: $_id, ticketInput: $ticketInput) {
      _id
      ticketDetails {
        selectedUser {
          label
          value
        }
        selectedCC {
          label
          value
        }
      }
    }
  }
`;

export const DELETE_ORM_REVIEW = gql`
  mutation deleteOrmReview($id: ID!) {
    deleteOrmReview(id: $id) {
      _id
    }
  }
`;

export const UPDATE_FAKE_REVIEW = gql`
  mutation updateFakeReview(
    $_id: ID
    $isReviewFake: String
    $sentForRemoval: Boolean
  ) {
    updateFakeReview(
      _id: $_id
      isReviewFake: $isReviewFake
      sentForRemoval: $sentForRemoval
    ) {
      _id
      isReviewFake
      sentForRemoval
    }
  }
`;

export const UPDATE_AREA_MANAGER_INPUT = gql`
  mutation updateAreaManagerInput($_id: ID, $areaManagerRemark: String) {
    updateAreaManagerInput(_id: $_id, areaManagerRemark: $areaManagerRemark) {
      _id
      areaManagerRemark
    }
  }
`;

export const CREATE_NEW_ORM_REVIEW = gql`
  mutation createNewORMReview($reviewInput: reviewInput) {
    createNewORMReview(reviewInput: $reviewInput) {
      hostel {
        name
        _id
      }
      bookingSource
      guestName
      rating
      outOfRating
      review
      reviewDate
      sentiment
    }
  }
`;

export const UPDATE_FINAL_CX_REMARK = gql`
  mutation updateFinalCXRemark(
    $_id: ID
    $finalCXRemark: String
    $caseMedia: [attachmentImageInput]
  ) {
    updateFinalCXRemark(
      _id: $_id
      finalCXRemark: $finalCXRemark
      caseMedia: $caseMedia
    ) {
      _id
      finalCXRemark
    }
  }
`;

export const SHIFT_REVIEW_TO_CANT_REPLY = gql`
  mutation shiftReviewToCantReply($_id: ID!) {
    shiftReviewToCantReply(_id: $_id) {
      _id
      positiveReview
      negativeReview
    }
  }
`;

export const LOG_GUEST_ACTION = gql`
  mutation logGuestAction($_id: ID!) {
    logGuestAction(_id: $_id) {
      _id
      logs
    }
  }
`;

export const GENERATE_ORM_EXPORT_DATA = gql`
  mutation generatexlsx($filter: filter) {
    generateORMExport(filter: $filter)
  }
`;

export const UPDATE_SENTIMENT_TEAM_TAGS = gql`
  mutation updateSentimentTeamTags($_id: ID!, $teamTagsInput: [String]!) {
    updateSentimentTeamTags(_id: $_id, teamTagsInput: $teamTagsInput) {
      _id
      teamTags
    }
  }
`;

export const UPDATE_HOSTEL_ID_FOR_AIRBNB = gql`
  mutation updateHostelIdForAirbnb($_id: ID!, $hostelId: ID) {
    updateHostelIdForAirbnb(_id: $_id, hostelId: $hostelId) {
      _id
      hostel {
        _id
        name
      }
    }
  }
`;
