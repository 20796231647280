/* eslint-disable import/no-anonymous-default-export */
import cms from "./cms";
import packages from "./packages";
// import amenities from "./amenities";
// import seo from "./seo";
// import rules from "./rules";
// import offers from "./offers";
import feedback from "./feedback";

// ! Coded by Ashwin
import coupons from "./coupons";
// import staffs from "./staffs";
// import unpaidReservation from "./unpaidReservation";
// import partnerNew from "./partnerNew"
// import chatbotBooking from "./chatbotBooking"; //?Added By Gaurav
// import WhatsappMessages from "./whatsappMessages"; //?Added By Gaurav
import Reports from "./reports";
import AdminUsers from "./adminUsers"; // ! Added by Ashwin
import couponsGenerator from "./couponsGenerator"; // ! Added by Ashwin
import blogs from "./blogs";
// import webCheckin from "./webCheckin";
import communications from "./communications";
// import guestdatabase from "./guestdatabase"; //added by Nitish
import webApp from "./webApp";
// import hr from "./hr";
import FileUpload from "./fileUpload";
import orm from "./orm";
import stepOut from "./stepOut.js";
import loyalty from "./loyalty.js";
import user from "./user.js";
import wallet from "./wallet.js";
import operations from "./operations.js";
import connect from "./connect.js";
import unbox from "./unbox.js";
import ezee from "./ezee.js";
import hostelComparisons from "./hostelComparisons.js";
import merchandiseStore from "./merchandiseStore.js";
import competitorRates from "./competitorRates.js"; //added by Ashish
import BookingDashboard from "./BookingDashboard.js";
import refundRequestPostStay from "./refundRequestPostStay.js";
import busBookings from "./busBookings.js";
import owner from "./owner.js";
import plStatement from "./p&lStatement.js";
import Coliving from "./coliving.js";
import Finance from "./finance.js";
import KYH from "./kyh.js"
export default [
  ...packages,
  ...cms,
  ...blogs,
  ...communications,
  // ...seo,
  // ...rules,
  // ...amenities,
  // ...offers,
  // ...feedback,
  ...coupons,
  ...couponsGenerator,
  // ...staffs,
  // ...unpaidReservation,
  // ...partnerNew,
  // ...chatbotBooking,
  // ...WhatsappMessages,
  ...webApp,
  ...Reports,
  // ...webCheckin,
  // ...guestdatabase, //added by Nitish,
  // ...hr,
  ...orm,
  ...stepOut,
  ...AdminUsers,
  ...FileUpload,
  ...loyalty,
  ...user,
  ...wallet,
  ...operations,
  //...connect,
  ...unbox,
  ...ezee,
  // ...hostelComparisons,
  // ...merchandiseStore
  ...merchandiseStore,
  ...competitorRates, //added by Ashish
  ...BookingDashboard, //added by Ashish
  ...refundRequestPostStay,
  ...busBookings,
  ...owner,
  ...plStatement,
  ...Coliving,
  ...Finance,
  ...KYH
];
