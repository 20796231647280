import React from 'react'
import { Edit, Eye } from 'react-feather';
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { reactFeatherIcon } from '../../styles/reactFeatherIcon';


const tableHeaderData = [
    "view",
    "Status",
    "Booking PNR",
    "Source",
    "Destination",
    "Name",
    "Total amount"
];
const BusBookingsTable = ({ tableData, openModal, setId }) => {
    console.log(tableData)
    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                <div>
                                    <h2>Bus Bookings</h2>
                                </div>
                            </CardTitle>
                        </CardHeader>

                        <Table responsive>
                            <thead>
                                <tr>
                                    {tableHeaderData.map((item) => {
                                        return <th key={uuidv4()}>{item}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData?.map((item) => (
                                    <tr key={uuidv4()}>
                                        <td>
                                            <Button
                                                className="rounded-circle btn-icon"
                                                color="none"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setId(item._id);
                                                    openModal();
                                                }}
                                            >
                                                {/* <Edit
                                                    color={reactFeatherIcon.iconColor}
                                                    size={reactFeatherIcon.iconSize}
                                                /> */}
                                                <Eye
                                                    color={reactFeatherIcon.iconColor}
                                                    size={reactFeatherIcon.iconSize}
                                                />
                                            </Button>
                                        </td>
                                        <td><Badge color={item?.bookingStatus == "success" ? "light-success" : "light-danger"}
                                        >{item?.bookingStatus}</Badge></td>
                                        <td>{item?.bookingPNR}</td>
                                        {/* <td>{item?.razorpayPaymentId}</td> */}
                                        <td>{item?.sourceCity}</td>
                                        <td>{item?.destinationCity}</td>
                                        <td>
                                            {item?.inventoryItems.map((passenger, index) => (
                                                passenger?.passenger[0]?.primary === 'true' ? (
                                                    <span key={index}>{passenger?.passenger[0]?.name}</span>
                                                ) : null
                                            ))}
                                        </td>
                                        {/* <td>{item?.inventoryItems.map((passenger, index) => (
                                            passenger?.passenger[0]?.primary === 'true' ? (
                                                <span key={index}>{passenger?.passenger[0]?.mobile}</span>
                                            ) : null
                                        ))}</td> */}
                                        <td>{item?.totalAmount}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </>)
}

export default BusBookingsTable