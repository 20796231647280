import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { useGetBookingFromReservationID } from '../../api/Ezee/EzeeMeals/Queries'
import toaster from "@components/common/Toaster";
import { useUpdateEzeeBookingEmail } from '../../api/Ezee/EzeeMeals/Mutation';

const GluSendLink = () => {
    const [reservationID, setReservationID] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [isError, setIsError] = useState(false)
    const [bookingId, setBookingId] = useState(null)
    const [diableButton, setIsDisableButton] = useState(false)
    const [name, setName] = useState("")
    const [bookingSource, setBookingSource] = useState("")

    const [fetchHandler, { data, error, refetch }] = useGetBookingFromReservationID()
    const [updateHandler, { data: updateData }] = useUpdateEzeeBookingEmail()

    console.log(data)
    useEffect(() => {
        if (data?.getEzeeBookingFromReservationId?.error && data?.getEzeeBookingFromReservationId?._id) {
            setIsError(true)
            setBookingId(data?.getEzeeBookingFromReservationId?._id)
            setName(data?.getEzeeBookingFromReservationId?.name)
            setBookingSource(data?.getEzeeBookingFromReservationId?.source)
            toaster("error", data?.getEzeeBookingFromReservationId?.message)

        } else if (data?.getEzeeBookingFromReservationId?.status === true) {
            toaster("success", "Glu link send successfully")
            setIsError(false)
        } else if (data?.getEzeeBookingFromReservationId?.error && !data?.getEzeeBookingFromReservationId?._id) {
            setIsError(true)
            toaster("error", data?.getEzeeBookingFromReservationId?.message)
        }

    }, [data])

    useEffect(() => {
        if (updateData?.updateEzeeBooking?.Email) {
            toaster("success", "Glu link send successfully")
        }
    }, [updateData?.updateEzeeBooking?.Email])

    const handleSendGluLink = () => {
        if (reservationID) {
            fetchHandler(reservationID)
        } else {
            toaster("error", "Please enter reservation Id")
        }
    }

    const handleUpdateEzee = () => {
        if (email || phoneNumber) {
            updateHandler(bookingId, { Email: email, Mobile: phoneNumber })
            setIsDisableButton(true)
        } else {
            toaster("error", "Please fill details")
            setIsDisableButton(false)
        }
    }

    return (
        <Row>
            <Col>
                <Card>
                    <CardHeader>
                        <CardTitle>
                            <strong>Send Glu Link</strong>
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        {
                            bookingId && isError ?
                                (
                                    <Row>
                                        {/* <h1>Email and mobile not exist</h1> */}
                                        <Col sm="10">
                                            <div style={{ display: "flex", gap: "20px" }}>
                                                <Label className="form-label">
                                                    Reservation Id: <span style={{ fontWeight: "bold", fontSize: "14px" }}>{bookingId}</span>
                                                </Label>
                                                {/* </div>
                                            <div> */}
                                                <Label className="form-label">
                                                    Guest Name: <span style={{ fontWeight: "bold", fontSize: "14px" }}>{name}</span>
                                                </Label>
                                                <Label className="form-label">
                                                    Source : <span style={{ fontWeight: "bold", fontSize: "14px" }}>{bookingSource}</span>
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <Label className='form-label' for='reservationId'>
                                                Enter Guest Email
                                            </Label>

                                            <Input
                                                type='email'
                                                value={email}
                                                id='email'
                                                name='email'
                                                placeholder='Enter Guest Email'
                                                onChange={(e) => setEmail(e.target.value)}

                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Label className='form-label' for='reservationId'>
                                                Enter Guest Phone Number
                                            </Label>

                                            <Input
                                                type='text'
                                                value={phoneNumber}
                                                id='phoneNumber'
                                                name='phoneNumber'
                                                placeholder='Enter Guest Phone Number'
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                maxLength={12}

                                            />
                                        </Col>
                                        <Col className="mt-2" sm="4">
                                            <Button type="button"
                                                className=" rounded"
                                                color="light"
                                                size="md" onClick={handleUpdateEzee} style={{ fontWeight: "bold" }} disabled={!email && !phoneNumber}>
                                                Send link
                                            </Button>
                                        </Col>
                                    </Row>) :

                                (<Row >
                                    <Col sm="4">
                                        <Label className='form-label' for='reservationId'>
                                            Enter Guest Reservation ID
                                        </Label>

                                        <Input
                                            type='text'
                                            value={reservationID}
                                            id='reservationId'
                                            name='reservationId'
                                            placeholder='Enter reservation id'
                                            onChange={(e) => setReservationID(e.target.value)}

                                        />
                                    </Col>
                                    <Col className="mt-2" sm="2">
                                        <Button type="button"
                                            className=" rounded"
                                            color="light"
                                            size="md" onClick={handleSendGluLink} style={{ fontWeight: "bold" }} disabled={!reservationID}>
                                            Send link
                                        </Button>
                                    </Col>
                                </Row>)
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default GluSendLink