import React, { useEffect, useState } from "react";
import ChatbotBookingTable from "./ChatbotBookingTable";
import {
  useGenerateChatbotBookingsForExport,
  useGetChatbotBooking,
  useGetChatbotBookingsForExport,
} from "../../../api/dataSource";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Col, Row, FormGroup, Label, Button, Input } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { CSVLink } from "react-csv";
import { dateFormatter } from "@utils";
import { dateValidator } from "../../../utility/Utils";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import toaster from "@components/common/Toaster";

const tableHeaderData = [
  { label: "Reservation No", key: "providerRefId" },
  { label: "Booking Date", key: "createdAt" },
  { label: "Status", key: "status" },
  { label: "Booking Source", key: "bookingSource" },
  { label: "Booking Amount", key: "payableAmount" },
  { label: "Hostel Name", key: "hostelId.name" },
  { label: "Checkin Date", key: "checkinDate" },
  { label: "Checkout Date", key: "checkoutDate" },
];

const ChatbotBooking = () => {
  const [fromDate, setFromDate] = useState(dateFormatter(new Date()));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [searchFromDate, setSearchFromDate] = useState(
    dateFormatter(new Date())
  );
  const [searchToDate, setSearchToDate] = useState(dateFormatter(new Date()));
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [bookingSource, setBookingSource] = useState(null);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [tempEmail, setTempEmail] = useState(""); 
  const [tempMobile, setTempMobile] = useState("");
  const [chatbotBookingSource, setChatbotBookingSource] = useState(null);
  const [status, setStatus] = useState(null);
  const [chatbotStatus, setChatbotStatus] = useState(null);
  const { data, refetch } = useGetChatbotBooking(
    limit,
    page,
    searchFromDate,
    searchToDate,
    bookingSource,
    email,
    mobile,
    status?.value,
  );
  const [dateMessage, setDateMessage] = useState(false);
  // const [exportTableData, setExportTableData] = useState([]);
  // const { data: exportData, refetch: exportRefetch } =
  //   useGetChatbotBookingsForExport(searchFromDate, searchToDate);

  const [getExportDataHandler, { data: exportData }] =
    useGenerateChatbotBookingsForExport();
  const [totalPage, setTotalPage] = useState(1);

  const bookingSourceList = [
    { label: "Whatsapp Chatbot", value: "Whatsapp Chatbot" },
    { label: "Instagram Chatbot", value: "Instagram Chatbot" },
    { label: "Website Chatbot", value: "Website Chatbot" },
  ];

  const statusList = [
    { label: "confirm", value: "confirm" },
    { label: "init", value: "init" },
    { label: "failure", value: "failure" },
    { label: "cancel", value: "cancel" },
    { label: "pending", value: "pending" },
  ];

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data?.getChatbotBookings?.data) {
      setTableData(data?.getChatbotBookings?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getChatbotBookings?.count / limit));
  }, [data]);

  //to get all data for export
  // useEffect(() => {
  //   if (exportData?.getChatbotBookingsForExport?.data) {
  //     setExportTableData(exportData?.getChatbotBookingsForExport?.data);
  //   } else {
  //     setExportTableData([]);
  //   }
  // }, [exportData]);

  const handleSearch = () => {
    if (dateValidator(fromDate, toDate)) {
      setSearchFromDate(fromDate);
      setSearchToDate(toDate);
      setDateMessage(false);
      setBookingSource(chatbotBookingSource?.map((item) => item?.value));
      setEmail(tempEmail);
      setMobile(tempMobile);
      setStatus(chatbotStatus);
    } else {
      toaster("error", "Invalid dates");
    }
  };
  const handleResetData = () => {
    setTableData([]);
    setFromDate(dateFormatter(new Date()));
    setToDate(dateFormatter(new Date()));
    setSearchFromDate(dateFormatter(new Date()));
    setSearchToDate(dateFormatter(new Date()));
    setChatbotBookingSource(null);
    setBookingSource(null);
    setTempEmail("");
    setTempMobile("");
    setChatbotStatus(null);
    setStatus(null)
  };

  useEffect(() => {
    if (exportData?.generateChatBotBookingForExport?.data) {
      // setExportTableData(exportData?.generateChatBotBookingForExport?.data);
      if (exportData?.generateChatBotBookingForExport?.data.length > 0) {
        const byteArray = new Uint8Array(
          exportData?.generateChatBotBookingForExport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `chatbot_bookings_pg.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [exportData]);

  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        {/* <Row>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Total Bookings: <b>{data?.getChatbotBookings?.count}</b>
              </Label>
            </FormGroup>
          </Col>
        </Row> */}
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                From Date *
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                To Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="toDate"
                placeholder="To Date"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="chatbotBookingSource">
                Booking Source
              </Label>
              <Select
                isClearable={true}
                id="chatbotBookingSource"
                name="chatbotBookingSource"
                isMulti
                theme={selectThemeColors}
                options={bookingSourceList}
                value={chatbotBookingSource}
                onChange={(value) => setChatbotBookingSource(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="email">
                  Email
                </Label>
              </strong>
              <Input
                type="text"
                value={tempEmail}
                id="email"
                name="email"
                placeholder="Add Email"
                onChange={(e) => setTempEmail(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="mobile">
                  Mobile Number
                </Label>
              </strong>
              <Input
                type="text"
                value={tempMobile}
                id="mobile"
                name="mobile"
                placeholder="Add Mobile Number"
                onChange={(e) => setTempMobile(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="chatbotStatus">
                  Status
                </Label>
              </strong>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                id="chatbotStatus"
                name="chatbotStatus"
                value={chatbotStatus}
                options={statusList}
                onChange={(value) => setChatbotStatus(value)}
              />
            </FormGroup>
          </Col>

          <Col style={{ marginTop: "22px" }}>
            <Button.Ripple
              onClick={handleSearch}
              className="ml-2"
              color="primary"
            >
              Search
            </Button.Ripple>
            <Button.Ripple
              onClick={handleResetData}
              className="ml-2"
              color="danger"
            >
              Reset
            </Button.Ripple>

            {/* <CSVLink
              headers={tableHeaderData}
              data={exportTableData}
              filename={`chatbot_bookings_pg${page}.csv`}
            >
              <Button.Ripple
                className="btn-icon ml-1"
                color="success"
                appearance="success"
                type="button"
              >
                Export
              </Button.Ripple>
            </CSVLink> */}

            <Button.Ripple
              className="btn-icon ml-1"
              color="success"
              appearance="success"
              type="button"
              onClick={() => {
                getExportDataHandler(searchFromDate, searchToDate, bookingSource);
              }}
            >
              Export
            </Button.Ripple>
          </Col>
        </Row>
        {tableData.length > 0 ? (
          <ChatbotBookingTable
            data={tableData}
            refetch={refetch}
            totalCount={data?.getChatbotBookings?.count}
          />
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            No record Found
          </h2>
        )}
        {tableData.length > 0 && (
          <TablePagination
            setPage={setPage}
            page={page}
            totalPage={totalPage}
          />
        )}
      </div>
    )
  );
};

export default ChatbotBooking;
