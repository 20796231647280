import React, { useEffect, useState } from "react";
import SlideModal from "../../../@core/components/common/slide-modal";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import toaster from "@components/common/Toaster";
import {
  useCreateMeterMaster,
  useUpdateMeterMaster,
} from "../../../api/MeterMaster/Mutation";
import { useHostelList } from "../../../api/dataSource";
const { dateFormatter } = require("../../../utility/Utils");

const AddEditModal = ({
  open,
  handleToggle,
  editData,
  refetch,
  allHostelList,
}) => {
  const [hostel, setHostel] = useState(null);
  const [hostelList, setHostelList] = useState([]);
  const [providerName, setProviderName] = useState("");
  const [entityName, setEntityName] = useState("");
  const [meterNo, setMeterNo] = useState("");
  const [sanctionedLoad, setSanctionedLoad] = useState("");
  const [perUnitRate, setPerUnitRate] = useState(null);
  const [billingStartDate, setBillingStartDate] = useState(null);
  const [billingEndDate, setBillingEndDate] = useState(null);
  const [billingDueDate, setBillingDueDate] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [avgUnitPerRoomPerDay, setAvgUnitPerRoomPerDay] = useState(null);
  const [multipliedFactor, setMultipliedFactor] = useState(1);
  const [createHandler, { data }] = useCreateMeterMaster();
  // const { data: allHostelList } = useHostelList();
  const [updateHandler, { data: updatedData }] = useUpdateMeterMaster();

  const validatedDay = (billingStartDate, billingEndDate, billingDueDate) => {
    const billingStart = parseInt(billingStartDate);
    const billingEnd = parseInt(billingEndDate);
    const billingDue = parseInt(billingDueDate);

    if (
      billingStart >= 1 &&
      billingStart <= 31 &&
      billingEnd >= 1 &&
      billingEnd <= 31 &&
      billingDue >= 1 &&
      billingDue <= 31
    ) {
      return true;
    } else {
      return false;
    }
  };

  //   const enterOnlyNumberAsInput = (val, name) => {
  //     if (!isNaN(val) && !val.includes("-")) {
  //       if (name === "perUnitRate") {
  //         setPerUnitRate(val);
  //       } else {
  //         setAvgUnitPerRoomPerDay(val);
  //       }
  //     } else {
  //       toaster("error", "Please Enter Number Only");
  //     }
  //   };

  //   const enterOnlyAlphanumericAsInput = (val, name) => {
  //     if (name === "title") {
  //       setTitle(val);
  //     } else if (name === "promocode") {
  //       setPromoCode(val);
  //     } else {
  //       setDescription(val);
  //     }
  //   };

  // useEffect(() => {
  //   if (allHostelList?.getHostelList?.length) {
  //     let hostel = allHostelList?.getHostelList?.map((ele) => ({
  //       label: ele?.name,
  //       value: ele?._id,
  //     }));
  //     setHostelList(hostel);
  //   }
  // }, [allHostelList?.getHostelList]);

  useEffect(() => {
    let HostelsList = allHostelList?.getHostelList?.map((hostel) => ({
      label: hostel?.name,
      value: hostel?._id,
    }));

    if (HostelsList?.length) {
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));

      const filteredHostel =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return HostelsList?.find((hostel) => hostel?.value === hostelID);
            })
          : HostelsList;

      setHostelList(filteredHostel);
    }
  }, [allHostelList]);

  useEffect(() => {
    if (editData?._id) {
      setHostel(
        editData?.hostel?.name !== null
          ? { label: editData?.hostel?.name, value: editData?.hostel?._id }
          : null
      );
      setProviderName(editData?.electricityProviderName);
      setEntityName(editData?.entityName);
      setMeterNo(editData?.meter_CA_Number);
      setSanctionedLoad(editData?.sanctionedLoad);
      setPerUnitRate(editData?.perUnitRate);
      setBillingStartDate(editData?.billingPeriodStartDate);
      setBillingEndDate(editData?.billingPeriodEndDate);
      setBillingDueDate(editData?.billPaymentDueDate);
      setMobileNumber(editData?.mobileNumber);
      setEmail(editData?.email);
      setAvgUnitPerRoomPerDay(editData?.avgUnitPerRoomPerDay);
      setMultipliedFactor(editData?.multipliedFactor || 1);
    } else {
      setHostel(null);
      setProviderName("");
      setEntityName("");
      setMeterNo("");
      setSanctionedLoad("");
      setPerUnitRate(null);
      setBillingStartDate(null);
      setBillingEndDate(null);
      setBillingDueDate(null);
      setMobileNumber("");
      setEmail("");
      setAvgUnitPerRoomPerDay(null);
      setMultipliedFactor(1);
    }
  }, [editData]);

  useEffect(() => {
    if (data || updatedData) {
      refetch();
    }
  }, [data, updatedData]);

  const submitHandler = async () => {
    const submitData = {
      hostel: hostel?.value,
      electricityProviderName: providerName,
      entityName,
      meter_CA_Number: meterNo,
      sanctionedLoad,
      multipliedFactor: Number(multipliedFactor) || 1,
      perUnitRate: Number(perUnitRate),
      billingPeriodStartDate: Number(billingStartDate),
      billingPeriodEndDate: Number(billingEndDate),
      billPaymentDueDate: Number(billingDueDate),
      mobileNumber: mobileNumber,
      email: email,
      avgUnitPerRoomPerDay: Number(avgUnitPerRoomPerDay),
    };
    //console.log(submitData);
    if (
      submitData?.hostel &&
      submitData?.electricityProviderName &&
      submitData?.meter_CA_Number &&
      submitData?.entityName &&
      submitData?.perUnitRate &&
      submitData?.multipliedFactor &&
      submitData?.billingPeriodStartDate &&
      submitData?.billingPeriodEndDate &&
      submitData?.billPaymentDueDate &&
      submitData?.avgUnitPerRoomPerDay
    ) {
      if (
        validatedDay(
          submitData?.billingPeriodStartDate,
          submitData?.billingPeriodEndDate,
          submitData?.billPaymentDueDate
        )
      ) {
        if (editData?._id) {
          await updateHandler(editData?._id, submitData);
          toaster("success", "Meter Master updated successfully");
          handleToggle();
        } else {
          await createHandler(submitData);
          toaster("success", "Meter Master added successfully");
          handleToggle();
        }
        setHostel(null);
        setProviderName("");
        setEntityName("");
        setMeterNo("");
        setSanctionedLoad("");
        setPerUnitRate(null);
        setBillingStartDate(null);
        setBillingEndDate(null);
        setBillingDueDate(null);
        setMobileNumber("");
        setEmail("");
        setAvgUnitPerRoomPerDay(null);
        setMultipliedFactor(1);
        handleToggle();
      } else {
        toaster("error", "Billing days should be in between 1 to 31");
      }
    } else {
      toaster("error", "Please fill the Required Details mark with *");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitHandler}
    >
      <form>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostel*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostel"
                name="hostel"
                options={hostelList}
                isDisabled={editData?.hostel?.name}
                value={hostel}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="providerName">
                Provider Name*
              </Label>
              <Input
                type="text"
                value={providerName}
                id="providerName"
                name="providerName"
                placeholder="Provider name"
                onChange={(e) => setProviderName(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="entityName">
                Entity Name*
              </Label>
              <Input
                type="text"
                value={entityName}
                id="entityName"
                name="entityName"
                placeholder="Entity Name"
                onChange={(e) => setEntityName(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="meterNo">
                Meter CA Number*
              </Label>
              <Input
                type="text"
                value={meterNo}
                id="meterNo"
                name="meterNo"
                placeholder="Meter Number"
                onChange={(e) => setMeterNo(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="sanctionedLoad">
                Sanctioned Load(KVA)
              </Label>
              <Input
                type="text"
                value={sanctionedLoad}
                id="sanctionedLoad"
                name="sanctionedLoad"
                placeholder="Sanctioned Load"
                onChange={(e) => setSanctionedLoad(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="perUnitRate">
                Per Unit Rate*
              </Label>
              <Input
                type="number"
                value={perUnitRate}
                id="perUnitRate"
                name="perUnitRate"
                placeholder="Per Unit Rate"
                onChange={(e) => setPerUnitRate(e.target.value)}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                minLength={10}
                maxLength={12}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="multipliedFactor">
                Multiplied Factor(MF)*
              </Label>
              <Input
                type="number"
                value={multipliedFactor}
                id="multipliedFactor"
                name="multipliedFactor"
                placeholder="Multiplied Factor"
                onChange={(e) => setMultipliedFactor(e.target.value)}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                min={10}
                max={12}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="startDate">
                Billing Start Day*
              </Label>
              <Input
                type="number"
                value={billingStartDate}
                id="billingStartdate"
                name="billingStartdate"
                placeholder="Billing Start Date"
                onChange={(e) => setBillingStartDate(e.target.value)}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                minLength={10}
                maxLength={12}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="endDate">
                Billing End Day*
              </Label>
              <Input
                type="number"
                value={billingEndDate}
                id="billingEndDate"
                name="billingEndDate"
                placeholder="Billing End Date"
                onChange={(e) => setBillingEndDate(e.target.value)}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                minLength={10}
                maxLength={12}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="dueDate">
                Billing Due Day*
              </Label>
              <Input
                type="number"
                value={billingDueDate}
                id="billingDueDate"
                name="billingDueDate"
                placeholder="Billing Due Date"
                onChange={(e) => setBillingDueDate(e.target.value)}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                minLength={10}
                maxLength={12}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="mobileNo">
                Mobile No.
              </Label>
              <Input
                type="number"
                value={mobileNumber}
                id="mobileNo"
                name="mobileNo"
                placeholder="Mobile Number"
                onChange={(e) => setMobileNumber(e.target.value)}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="email">
                Email
              </Label>
              <Input
                type="text"
                value={email}
                id="email"
                name="email"
                placeholder="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="avgUnitPerRoomPerDay">
                Avg Unit Per Room Per Day*
              </Label>
              <Input
                type="number"
                value={avgUnitPerRoomPerDay}
                id="avgUnitPerRoomPerDay"
                name="avgUnitPerRoomPerDay"
                placeholder="Avg Unit Per Room PerDay"
                onChange={(e) => setAvgUnitPerRoomPerDay(e.target.value)}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
              />
            </FormGroup>
          </Col>

          {editData?.logs && (
            <Col>
              <Label className="form-label bold" for="">
                Logs:
              </Label>
              <Col>
                {[...editData.logs]?.reverse()?.map((el) => {
                  return <Label>{el}</Label>;
                })}
              </Col>
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
