import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const UPDATE_MEMBERSHIPS = gql`
  mutation updateMemberships($id: ID!, $membershipInput: membershipInput) {
    updateMemberships(id: $id, membershipInput: $membershipInput) {
      _id
      travelKitSend
    }
  }
`;

export const useUpdateMemberships = () => {
  const [updateMemberships, { loading, error, data, reset }] =
    useMutation(UPDATE_MEMBERSHIPS);

  useLoadingHandler(loading);

  const updateMembershipHandler = (id, membershipInput) => {
    updateMemberships({
      variables: { id, membershipInput },
    });
  };

  return [updateMembershipHandler, { loading, error, data, reset }];
};

export const GET_MEMBERSHIPS_REPORT = gql`
  mutation getMembershipsReport($filter: filter) {
    getMembershipsReport(filter: $filter)
  }
`;

export const useGetMembershipsReport = () => {
  const [getMembershipsReportData, { loading, error, data, refetch }] =
    useMutation(GET_MEMBERSHIPS_REPORT);
  useLoadingHandler(loading);
  const getMembershipsReportDataHandler = (filter) => {
    getMembershipsReportData({
      variables: {
        filter,
      },
    });
  };
  return [getMembershipsReportDataHandler, { data, refetch, loading, error }];
};

export const CREATE_MEMBERSHIPS = gql`
  mutation createMembership($membershipInput: membershipInput) {
    createMembership(membershipInput: $membershipInput) {
      email
      fullName
      mobile
      startDate
      endDate
      membershipType
      membershipAmount
      membershipTax
      totalAmount
      discountPercentage
      cashbackPercentage
      travelKitSend
    }
  }
`;

export const useCreateMembership = () => {
  const [createMembership, { loading, error, data, reset }] =
    useMutation(CREATE_MEMBERSHIPS);

  useLoadingHandler(loading);

  const createMembershipHandler = (membershipInput) => {
    createMembership({
      variables: { membershipInput },
    });
  };

  return [createMembershipHandler, { loading, error, data, reset }];
};
