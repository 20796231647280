import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const UPDATE_HOUSE_KEEPING = gql`
    mutation saveHouseKeepingData($HouseKeepingUpdate:[HouseKeepingInput]!) {
        saveHouseKeepingData(HouseKeepingUpdate:$HouseKeepingUpdate) {
            acknowledged
        }
    }
`;

export const useSaveHouseKeepingData = () => {
    const [saveHouseKeepingData, { loading, error, data, refetch }] =
        useMutation(UPDATE_HOUSE_KEEPING);
    useLoadingHandler(loading);

    const saveHouseKeepingDataHandler = async (HouseKeepingUpdate) => {
        const response =  await saveHouseKeepingData({
            variables: { HouseKeepingUpdate },
        });
        return response;
    };
    return [saveHouseKeepingDataHandler, { loading, error, data, refetch }];
};