import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { Trash } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateDestination } from "@api";
import { useParams } from "react-router";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const FaqForm = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [updateDestinationHandler, { data }] = useUpdateDestination();

  const [howToReach, setHowToReach] = useState([
    {
      id: uuidv4(),
      howToReachTitle: "",
      howToReachDesc: "",
    },
  ]);

  const waysToReach = state?.tableData?.howToReach;

  useEffect(() => {
    if (waysToReach && waysToReach.length > 0) {
      let upadtedHighlight = waysToReach.map((item) => {
        return {
          id: uuidv4(),
          howToReachTitle: item?.howToReachTitle,
          howToReachDesc: item?.howToReachDesc,
        };
      });

      setHowToReach(upadtedHighlight);
    }
  }, [waysToReach]);

  useEffect(() => {
    if (data?.updateDestination?.destination) {
      toaster("success", "How to reach updated successfully");
    }
  }, [data?.updateDestination?.destination, waysToReach]);

  const handleTextChangeHowToReachTitle = (val, id, key) =>
    setHowToReach(
      howToReach.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );

  const handleTextChangeHowToReachDescription = (val, id, key) =>
    setHowToReach(
      howToReach.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );

  const handleAddHowToReach = () =>
    setHowToReach([
      ...howToReach,
      { id: uuidv4(), howToReachTitle: "", howToReachDesc: "" },
    ]);

  const handleRemoveHowToReach = (id) => {
    // eslint-disable-next-line array-callback-return
    let newHowToReachData = howToReach.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    confirmDelete(() => setHowToReach(newHowToReachData));
  };

  const handleSubmitHandler = () => {
    let isValid = true;
    let howToReachClone = JSON.parse(JSON.stringify(howToReach));
    howToReachClone.forEach((item) => {
      delete item.id;
    });

    let howToReachData = {
      howToReach: howToReachClone,
      sectionName: "How To Reach",
    };

    howToReachClone?.forEach((item) => {
      isValid &&
        (item?.howToReachTitle && item?.howToReachDesc
          ? (isValid = true)
          : (isValid = false));
    });

    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...howToReachData,
        })
      );
      updateDestinationHandler(id, howToReachData);
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form
      className="mt-2"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitHandler();
      }}
    >
      {howToReach.map(({ id, howToReachTitle, howToReachDesc }, index) => (
        <div key={id}>
          <Row className="mt-2">
            <Col sm="12" md="11">
              <FormGroup>
                <FormGroup className="w-100">
                  <Label className="form-label" for="cancel">
                    Title
                  </Label>

                  <Input
                    type="text"
                    value={howToReachTitle}
                    id="cancel"
                    name="cancel"
                    placeholder={`Title`}
                    onChange={(e) =>
                      handleTextChangeHowToReachTitle(
                        e.target.value,
                        id,
                        "howToReachTitle"
                      )
                    }
                  />
                </FormGroup>

                <FormGroup className="w-100">
                  <Label className="form-label" for="cancel">
                    Description
                  </Label>

                  <Input
                    rows={4}
                    type="textarea"
                    value={howToReachDesc}
                    id="cancel"
                    name="cancel"
                    placeholder="Description"
                    onChange={(e) =>
                      handleTextChangeHowToReachDescription(
                        e.target.value,
                        id,
                        "howToReachDesc"
                      )
                    }
                  />
                </FormGroup>
              </FormGroup>
            </Col>

            <Col sm="12" md="1">
              {howToReach.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle mb-1 mr-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveHowToReach(id)}
                  >
                    <Trash size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          </Row>
        </div>
      ))}

      <SaveDiscardBtnGroup addAction={handleAddHowToReach} />
    </form>
  );
};

export default FaqForm;
