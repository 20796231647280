// ! Added by Ashwin

import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import BannerImageSection from "./BannerImageSection";
import { useUpdateEventsAndActivities, useUploadImage } from "../../../api/dataSource";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Camera } from "react-feather";

const UploadType = [{ value: "bannerImages ", label: "Carousel" }];

const BannerImages = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const [updateEventsAndActivitiesHandler, { data, reset }] =
    useUpdateEventsAndActivities();
  const dispatch = useDispatch();
  const [cardImage, setCardImage] = useState("")
  useEffect(() => {
    state?.tableData &&
      state.tableData.bannerImages?.length &&
      setImageList(state.tableData.bannerImages);
    state?.tableData &&
      state.tableData.cardImage &&
      setCardImage(state.tableData.cardImage);

  }, [state?.tableData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const bannerImages = [];

    imageList.map((image) => {
      image.image &&
        bannerImages.push({
          type: image.type,
          image: image.image,
          key: image.key,
        });
    });

    updateEventsAndActivitiesHandler(id, { bannerImages: bannerImages, cardImage: cardImage }); // "hosteller" is used to get landing page doc
    toaster("success", "Banner image updated successfully");
    dispatch(
      tableDataHandler({
        ...state?.tableData,
        bannerImages,
      })
    );
  };
  const [uploadImageHandler, { data: imageData, reset: imageReset }] =
    useUploadImage();

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    // setIndex(id);

    const fileName = e.target.files[0]?.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );

      return;
    }

    uploadImageHandler(
      e.target.files[0],
      `${fileName}/EventCardImage}`
    );

    toaster("success", "Item image uploaded successfully");
  };

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setCardImage(imageData?.uploadImage?.url);

    }
  }, [imageData?.uploadImage?.url]);

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Col sm="6" style={{ display: "flex", flexDirection: "column" }}>
          <h3 className="imageSection__header mt-2">Upload card image</h3>
          <p>
            Recommended dimension -770 × 475
          </p>          <div>
            {cardImage && (
              <img
                key={1}
                className="img-thumbnail rounded mt-2 mr-1"
                src={cardImage}
                alt="avatar"
                style={{ height: "200px", width: "auto" }}
              />
            )}

            <label htmlFor={`myFile${id}`}>
              <p className="btn btn-sm btn-light">
                <Camera size={15} />
              </p>
              <input
                name={`myFile${id}`}
                id={`myFile${id}`}
                className="d-none"
                type="file"
                onChange={(e) => {
                  fileUploader(e, id);
                }}
              />
            </label>
          </div>
        </Col>
        <Row>
          <BannerImageSection
            imageList={imageList}
            setImageList={setImageList}
            uploadType={UploadType}
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default BannerImages;
