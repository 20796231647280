import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import Select from "react-select";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import AttachmentComponent from "../AttachmentComponent";
import { tableDataHandler } from "@store/Cms/cms.action";
// import { dateFormatter } from "@utils";
// import Flatpickr from "react-flatpickr";

import { Image, Upload } from "react-feather";
import { v4 as uuidv4 } from "uuid";

import { indianStateList } from "../../../../utility/Utils";
import AddButton from "../addButton";
import {
  LessorEntityTypeList,
  BankAccountTypeList,
} from "../selectOptionsList";
import { useUploadImage } from "../../../../api/dataSource";
import { useUpdateSupply } from "../../../../api/Supply/Mutation";
import ImageModal from "../ImageModal";

const DealOwnership = ({ setRemainingCount }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [lessorEntityType, setLessorEntityType] = useState("");
  const [lessorEntityFullName, setLessorEntityFullName] = useState("");
  // const [lessorAge, setLessorAge] = useState("");
  const [lessorFatherName, setLessorFatherName] = useState("");
  const [lessorCompleteAddress, setLessorCompleteAddress] = useState("");
  const [lessorAadharNumber, setLessorAadharNumber] = useState("");
  const [lessorAadharCard, setLessorAadharCard] = useState([]);
  const [lessorPANNumber, setLessorPANNumber] = useState("");
  const [lessorPANCard, setLessorPANCard] = useState([]);
  const [lessorPartnershipPercent, setLessorPartnershipPercent] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountType, setBankAccountType] = useState("");
  const [bankBeneficiaryName, setBankBeneficiaryName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankIfscCode, setBankIfscCode] = useState("");
  const [cancelledCheque, setCancelledCheque] = useState([]);
  const [llpRegistrationNumber, setLlpRegistrationNumber] = useState("");
  const [llpRegistrationCertificate, setLlpRegistrationCertificate] = useState(
    []
  );
  const [llpRegisteredAddress, setLlpRegisteredAddress] = useState("");
  const [llpRepresentativeName, setLlpRepresentativeName] = useState("");
  const [llpRepresentativeAddress, setLlpRepresentativeAddress] = useState("");
  const [llpRepresentativeAadharNumber, setLlpRepresentativeAadharNumber] =
    useState("");
  const [llpRepresentativeAadharCard, setLlpRepresentativeAadharCard] =
    useState([]);

  const [llpRepresentativePANNumber, setLlpRepresentativePANNumber] =
    useState("");
  const [llpRepresentativePANCard, setLlpRepresentativePANCard] = useState([]);
  const [llpRepresentativeDIN, setLlpRepresentativeDIN] = useState("");

  const [pocFullName, setPOCFullName] = useState("");
  const [pocMobileNumber, setPOCMobileNumber] = useState("");
  const [pocEmail, setPOCEmail] = useState("");
  const [pocAadharNumber, setPOCAadharNumber] = useState("");
  const [pocAadharCard, setPOCAadharCard] = useState([]);
  const [leaseDeedType, setLeaseDeedType] = useState("");
  const [registeredLicensesEntity, setRegisteredLicensesEntity] = useState("");
  const [licensesResponsibility, setLicensesResponsibility] = useState("");
  const [licensesFeesResponsibility, setLicensesFeesResponsibility] =
    useState("");
  const [monthlyRent, setMonthlyRent] = useState("");
  const [leaseDuration, setLeaseDuration] = useState("");
  const [securityDepositToken, setSecurityDepositToken] = useState("");
  const [securityDepositRegistration, setSecurityDepositRegistration] =
    useState("");
  const [securityDepositHandover, setSecurityDepositHandover] = useState("");
  const [securityDepositRentStart, setSecurityDepositRentStart] = useState("");
  const [totalSecurityDeposit, setTotalSecurityDeposit] = useState("");
  const [ddReport, setDDReport] = useState([]);
  const [auditReport, setAuditReport] = useState([]);
  const [signedMOU, setSignedMOU] = useState([]);
  const [llpAuthorizedAttorney, setLlpAuthorizedAttorney] = useState([]);
  const [lessorGstinNumber, setLessorGstinNumber] = useState("");
  const [lessorGstinCertificate, setLessorGstinCertificate] = useState([]);

  const [lessorIndividualMultiple, setLessorIndividualMultiple] = useState([
    {
      id: uuidv4(),
      lessorName: "",
      lessorAge: "",
      lessorFatherName: "",
      lessorCompleteAddress: "",
      lessorAadharNumber: "",
      // lessorAadharCard: [],
      lessorAadharCard: null,
      lessorPANNumber: "",
      // lessorPANCard: [],
      lessorPANCard: null,
      lessorGstinNumber: "",
      lessorGstinCertificate: null,
      lessorPartnershipPercent: "",
      bankName: "",
      bankAccountType: "",
      bankBeneficiaryName: "",
      bankAccountNumber: "",
      bankIfscCode: "",
      // cancelledCheque: [],
      cancelledCheque: null,
    },
  ]);

  const [lessorLLPPvtLtd, setLessorLLPPvtLtd] = useState([
    {
      id: uuidv4(),
      lessorEntityFullName: "",
      lessorName: "",
      lessorCompleteAddress: "",
      lessorAadharNumber: "",
      lessorAadharCard: [],
      lessorDINNumber: "",
      bankName: "",
      bankAccountType: "",
      bankBeneficiaryName: "",
      bankAccountNumber: "",
      bankIfscCode: "",
      cancelledCheque: [],
    },
  ]);

  const [lessorPartnership, setLessorPartnership] = useState([
    {
      id: uuidv4(),
      // lessorEntityFullName: "",
      lessorName: "",
      lessorAge: "",
      lessorFatherName: "",
      lessorCompleteAddress: "",
      lessorAadharNumber: "",
      // lessorAadharCard: [],
      lessorAadharCard: null,
      lessorPANNumber: "",
      // lessorPANCard: [],
      lessorPANCard: null,
      lessorGstinNumber: "",
      lessorGstinCertificate: null,
      lessorPartnershipPercent: "",
    },
  ]);

  //!Images state

  const [TitleSearchReportImageDummyState] = useState(null);
  const [PropertyPreSigningAuditReportImageDummyState] = useState(null);
  const [lessorAadharCardImageDummyState] = useState(null);
  const [lessorPANCardImageDummyState] = useState(null);
  const [cancelledChequeImageDummyState] = useState(null);
  const [llpRegistrationCertificateImageDummyState] = useState(null);
  const [llpRepresentativeAadharCardImageDummyState] = useState(null);
  const [llpRepresentativePANCardImageDummyState] = useState(null);
  const [POCAadharCardImageDummyState] = useState(null);
  const [ddReportImageDummyState] = useState(null);
  const [auditReportImageDummyState] = useState(null);
  const [signedMOUImageDummyState] = useState(null);

  const [llpAuthorizedAttorneyImageDummyState] = useState(null);
  const [lessorGstinCertificateImageDummyState] = useState(null);

  const [uploadingImageType, setUploadingImageType] = useState(null);

  const uploadTitleSearchReportImageType = [
    { label: "imageURL", value: "TitleSearchReportImage" },
  ];
  const uploadPropertyPreSigningAuditReportImageType = [
    { label: "imageURL", value: "PropertyPreSigningAuditReportImage" },
  ];
  const uploadLessorAadharCardImageType = [
    { label: "imageURL", value: "lessorAadharCardImage" },
  ];
  const uploadLessorPANCardImageType = [
    { label: "imageURL", value: "lessorPANCardImage" },
  ];
  const uploadCancelledChequeImageType = [
    { label: "imageURL", value: "cancelledChequeImage" },
  ];
  const uploadLlpRegistrationCertificateImageType = [
    { label: "imageURL", value: "llpRegistrationCertificateImage" },
  ];
  const uploadLlpRepresentativeAadharCardImageType = [
    { label: "imageURL", value: "LlpRepresentativeAadharCardImage" },
  ];
  const uploadLlpRepresentativePANCardImageType = [
    { label: "imageURL", value: "LlpRepresentativePANCardImage" },
  ];
  const uploadPOCAadharCardImageType = [
    { label: "imageURL", value: "pocAadharCardImage" },
  ];
  const uploadDDReportImageType = [
    { label: "imageURL", value: "ddReportImage" },
  ];
  const uploadAuditReportImageType = [
    { label: "imageURL", value: "auditReportImage" },
  ];
  const uploadSignedMOUImageType = [
    { label: "imageURL", value: "signedMOUImage" },
  ];

  const uploadLlpAuthorizedAttorneyImageType = [
    { label: "imageURL", value: "llpAuthorizedAttorney" },
  ];
  const uploadLessorGstinCertificateImageType = [
    { label: "imageURL", value: "lessorGstinCertificate" },
  ];

  const userRole = localStorage.getItem("role");

  // const [createSupplyHandler, data] = useCreateSupply();
  const [uploadImageHandler, { data: imageData }] = useUploadImage();
  const [updateSupplyHandler, { data: updatedSupplyData }] = useUpdateSupply();

  const state = useSelector((state) => state?.cms);
  const dbData = state?.tableData;

  useEffect(() => {
    if (updatedSupplyData?.updateSupply?.status === true) {
      toaster("success", "Hostel details updated successfully");
    } else if (updatedSupplyData?.updateSupply?.status === false) {
      toaster("error", "Error: Hostel details update failed");
    }
  }, [updatedSupplyData]);

  useEffect(() => {
    if (dbData?._id) {
      if (
        dbData?.lessorEntityType === "Individual" ||
        dbData?.lessorEntityType === "Multiple individuals"
      ) {
        setLessorEntityType(
          lessorEntityType !== null
            ? {
              label: dbData?.lessorEntityType,
              value: dbData?.lessorEntityType,
            }
            : ""
        );
        setPOCFullName(dbData?.pocFullName);
        setPOCMobileNumber(dbData?.pocMobileNumber);
        setPOCEmail(dbData?.pocEmail);
        setLessorIndividualMultiple(
          dbData?.lessorIndividualMultiple?.map((item) => {
            return {
              id: uuidv4(),
              lessorName: item?.lessorName,
              lessorAge: Number(item?.lessorAge),
              lessorFatherName: item?.lessorFatherName,
              lessorCompleteAddress: item?.lessorCompleteAddress,
              lessorAadharNumber: item?.lessorAadharNumber,
              lessorAadharCard: item?.lessorAadharCard,
              lessorPANNumber: item?.lessorPANNumber,
              lessorPANCard: item?.lessorPANCard,
              lessorGstinNumber: item?.lessorGstinNumber,
              lessorGstinCertificate: item?.lessorGstinCertificate,
              lessorPartnershipPercent: Number(item?.lessorPartnershipPercent),
              bankName: item?.bankName,
              bankAccountType:
                item?.bankAccountType !== null
                  ? {
                    label: item?.bankAccountType,
                    value: item?.bankAccountType,
                  }
                  : "",
              bankBeneficiaryName: item?.bankBeneficiaryName,
              bankAccountNumber: item?.bankAccountNumber,
              bankIfscCode: item?.bankIfscCode,
              cancelledCheque: item?.cancelledCheque,
            };
          })
        );

      }
      if (
        dbData?.lessorEntityType === "LLP" ||
        dbData?.lessorEntityType === "Pvt. ltd." ||
        dbData?.lessorEntityType === "Partnership" // because gstimage registered was not visible after saving
      ) {
        setLessorEntityType(
          lessorEntityType !== null
            ? {
              label: dbData?.lessorEntityType,
              value: dbData?.lessorEntityType,
            }
            : ""
        );
        setLlpRegistrationNumber(dbData?.llpRegistrationNumber);
        setPOCFullName(dbData?.pocFullName);
        setPOCMobileNumber(dbData?.pocMobileNumber);
        setPOCEmail(dbData?.pocEmail);

        setLessorEntityFullName(dbData?.lessorEntityFullName);
        setLlpRegistrationCertificate(dbData?.llpRegistrationCertificate);
        setLlpRegisteredAddress(dbData?.llpRegisteredAddress);
        setLlpRepresentativeName(dbData?.llpRepresentativeName);
        setLlpRepresentativeAddress(dbData?.llpRepresentativeAddress);
        setLlpRepresentativeAadharNumber(dbData?.llpRepresentativeAadharNumber);
        setLlpRepresentativeAadharCard(dbData?.llpRepresentativeAadharCard);
        setLlpRepresentativePANNumber(dbData?.llpRepresentativePANNumber);
        setLlpRepresentativePANCard(dbData?.llpRepresentativePANCard);
        setLlpRepresentativeDIN(dbData?.llpRepresentativeDIN);
        setLlpAuthorizedAttorney(dbData?.llpAuthorizedAttorney);
        setLessorGstinNumber(dbData?.lessorGstinNumber);
        setLessorGstinCertificate(dbData?.lessorGstinCertificate);
        setBankName(dbData?.bankName);
        setBankAccountType(
          bankAccountType !== null
            ? {
              label: dbData?.bankAccountType,
              value: dbData?.bankAccountType,
            }
            : ""
        );
        setBankBeneficiaryName(dbData?.bankBeneficiaryName);
        setBankAccountNumber(dbData?.bankAccountNumber);
        setBankIfscCode(dbData?.bankIfscCode);
        setCancelledCheque(dbData?.cancelledCheque);
      }

      if (dbData?.lessorEntityType === "Partnership") {
        setLessorEntityType(
          lessorEntityType !== null
            ? {
              label: dbData?.lessorEntityType,
              value: dbData?.lessorEntityType,
            }
            : ""
        );
        setLessorEntityFullName(dbData?.lessorEntityFullName);
        setPOCFullName(dbData?.pocFullName);
        setPOCMobileNumber(dbData?.pocMobileNumber);
        setPOCEmail(dbData?.pocEmail);
        setLessorGstinNumber(dbData?.lessorGstinNumber);
        setLessorGstinCertificate(dbData?.lessorGstinCertificate);
        setBankName(dbData?.bankName);
        setBankAccountType(
          bankAccountType !== null
            ? {
              label: dbData?.bankAccountType,
              value: dbData?.bankAccountType,
            }
            : ""
        );
        setBankBeneficiaryName(dbData?.bankBeneficiaryName);
        setBankAccountNumber(dbData?.bankAccountNumber);
        setBankIfscCode(dbData?.bankIfscCode);
        setCancelledCheque(dbData?.cancelledCheque);
        setLessorPartnership(
          dbData?.lessorPartnership?.map((item) => ({
            id: uuidv4(),
            // lessorEntityFullName: "",
            lessorName: item?.lessorName,
            lessorAge: item?.lessorAge,
            lessorFatherName: item?.lessorFatherName,
            lessorCompleteAddress: item?.lessorCompleteAddress,
            lessorAadharNumber: item?.lessorAadharNumber,
            lessorAadharCard: item?.lessorAadharCard,
            lessorPANNumber: item?.lessorPANNumber,
            lessorPANCard: item?.lessorPANCard,
            lessorGstinNumber: item?.lessorGstinNumber,
            lessorGstinCertificate: item?.lessorGstinCertificate,
            lessorPartnershipPercent: item?.lessorPartnershipPercent,
          }))
        );
      }
    } else {
      setLessorEntityType("");
      setPOCFullName("");
      setPOCMobileNumber("");
      setPOCEmail("");
      setLessorIndividualMultiple([
        {
          id: uuidv4(),
          lessorName: "",
          lessorAge: "",
          lessorFatherName: "",
          lessorCompleteAddress: "",
          lessorAadharNumber: "",
          lessorAadharCard: null,
          lessorPANNumber: "",
          lessorPANCard: null,
          lessorGstinNumber: "",
          lessorGstinCertificate: null,
          lessorPartnershipPercent: "",
          bankName: "",
          bankAccountType: "",
          bankBeneficiaryName: "",
          bankAccountNumber: "",
          bankIfscCode: "",
          cancelledCheque: null,
        },
      ]);
      setLlpRegistrationNumber("");
      setLessorEntityFullName("");
      setLlpRegistrationCertificate("");
      setLlpRegisteredAddress("");
      setLlpRepresentativeName("");
      setLlpRepresentativeAddress("");
      setLlpRepresentativeAadharNumber("");
      setLlpRepresentativeAadharCard([]);
      setLlpRepresentativePANNumber("");
      setLlpRepresentativePANCard([]);
      setLlpRepresentativeDIN("");
      setLlpAuthorizedAttorney("");
      setLessorGstinNumber("");
      setLessorGstinCertificate("");
      setBankName("");
      setBankAccountType("");
      setBankBeneficiaryName("");
      setBankAccountNumber("");
      setBankIfscCode("");
      setCancelledCheque([]);
      setLessorPartnership([
        {
          id: uuidv4(),
          lessorName: "",
          lessorAge: "",
          lessorFatherName: "",
          lessorCompleteAddress: "",
          lessorAadharNumber: "",
          lessorAadharCard: null,
          lessorPANNumber: "",
          lessorPANCard: null,
          lessorGstinNumber: "",
          lessorGstinCertificate: null,
          lessorPartnershipPercent: "",
        },
      ]);
    }
  }, [dbData]);

  useEffect(() => {
    if (
      lessorEntityType?.value === "Individual" ||
      lessorEntityType?.value === "Multiple individuals"
    ) {
      if (imageData?.uploadImage?.url && uploadingImageType?.lessorId) {
        setLessorIndividualMultiple((prevLessorList) =>
          prevLessorList.map((lessor) =>
            lessor.id === uploadingImageType.lessorId
              ? {
                ...lessor,
                lessorAadharCard:
                  uploadingImageType.type === "lessorAadharCardImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorAadharCard,
                lessorPANCard:
                  uploadingImageType.type === "lessorPANCardImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorPANCard,
                cancelledCheque:
                  uploadingImageType.type === "cancelledChequeImage"
                    ? imageData?.uploadImage?.url
                    : lessor.cancelledCheque,
                lessorGstinCertificate:
                  uploadingImageType.type === "lessorGstinCertificateImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorGstinCertificate,
              }
              : lessor
          )
        );

        // Reset state after processing the upload
        setUploadingImageType(null);
      }
    }

    if (lessorEntityType?.value === "Partnership") {
      if (imageData?.uploadImage?.url && uploadingImageType?.lessorId) {
        setLessorPartnership((prevLessorList) =>
          prevLessorList.map((lessor) =>
            lessor.id === uploadingImageType.lessorId
              ? {
                ...lessor,
                lessorAadharCard:
                  uploadingImageType.type === "lessorAadharCardImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorAadharCard,
                lessorPANCard:
                  uploadingImageType.type === "lessorPANCardImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorPANCard,
                lessorGstinCertificate:
                  uploadingImageType.type === "lessorGstinCertificateImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorGstinCertificate,
              }
              : lessor
          )
        );

        // Reset state after processing the upload
        setUploadingImageType(null);
      }
    }
  }, [imageData?.uploadImage?.url, uploadingImageType]);

  const fileUploader = (e, lessorId, type) => {
    const file = e.target.files[0];
    if (!file) return;

    // const maxFileSize = 2097152;
    // if (file.size > maxFileSize) {
    //   toaster("error", "File size exceeds the maximum limit of 2 MB.");
    //   return;
    // }

    // Upload Image
    uploadImageHandler(
      file,
      `${file?.name
        ?.toLowerCase()
        ?.replaceAll(" ", "-")}/Operations/Supply/${type}/Image`
    );

    // Store the lessorId & image type in state to update the correct lessor
    setUploadingImageType({ lessorId, type });

    toaster("success", "Image uploaded successfully");
  };

  const handleAddLessorIndividualMultiple = () => {
    setLessorIndividualMultiple([
      ...lessorIndividualMultiple,
      {
        id: uuidv4(),
        lessorName: "",
        lessorAge: "",
        lessorFatherName: "",
        lessorCompleteAddress: "",
        lessorAadharNumber: "",
        // lessorAadharCard: [],
        lessorAadharCard: null,
        lessorPANNumber: "",
        // lessorPANCard: [],
        lessorPANCard: null,
        lessorGstinNumber: "",
        lessorGstinCertificate: null,
        lessorPartnershipPercent: "",
        bankName: "",
        bankAccountType: "",
        bankBeneficiaryName: "",
        bankAccountNumber: "",
        bankIfscCode: "",
        // cancelledCheque: [],
        cancelledCheque: null,
      },
    ]);
  };

  const handleAddLessorLLPPvtLtd = () => {
    setLessorLLPPvtLtd([
      ...lessorLLPPvtLtd,
      {
        id: uuidv4(),
        lessorEntityFullName: "",
        lessorName: "",
        lessorCompleteAddress: "",
        lessorAadharNumber: "",
        lessorAadharCard: [],
        lessorDINNumber: "",
        bankName: "",
        bankAccountType: "",
        bankBeneficiaryName: "",
        bankAccountNumber: "",
        bankIfscCode: "",
        cancelledCheque: [],
      },
    ]);
  };

  const handleAddLessorPartnership = () => {
    setLessorPartnership([
      ...lessorPartnership,
      {
        id: uuidv4(),
        // lessorEntityFullName: "",
        lessorName: "",
        lessorAge: "",
        lessorFatherName: "",
        lessorCompleteAddress: "",
        lessorAadharNumber: "",
        // lessorAadharCard: [],
        lessorAadharCard: null,
        lessorPANNumber: "",
        // lessorPANCard: [],
        lessorPANCard: null,
        lessorGstinNumber: "",
        lessorGstinCertificate: null,
        lessorPartnershipPercent: "",
      },
    ]);
  };

  const handleText = (val, id, key) => {
    setLessorIndividualMultiple(
      lessorIndividualMultiple.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };

  const handleNumber = (val, id, key) => {
    setLessorIndividualMultiple(
      lessorIndividualMultiple.map((item) =>
        item.id === id ? { ...item, [key]: Number(val) } : item
      )
    );
  };
  const handlePartnershipText = (val, id, key) => {
    setLessorPartnership(
      lessorPartnership.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };

  const handlePartnershipNumber = (val, id, key) => {
    setLessorPartnership(
      lessorPartnership.map((item) =>
        item.id === id ? { ...item, [key]: Number(val) } : item
      )
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const lessorIndividualMultipleData = lessorIndividualMultiple?.map(
      (item) => ({
        lessorName: item?.lessorName,
        lessorAge: Number(item?.lessorAge),
        lessorFatherName: item?.lessorFatherName,
        lessorCompleteAddress: item?.lessorCompleteAddress,
        lessorAadharNumber: item?.lessorAadharNumber,
        lessorAadharCard: item?.lessorAadharCard,
        lessorPANNumber: item?.lessorPANNumber,
        lessorPANCard: item?.lessorPANCard,
        lessorGstinNumber: item?.lessorGstinNumber,
        lessorGstinCertificate: item?.lessorGstinCertificate,
        lessorPartnershipPercent: Number(item?.lessorPartnershipPercent),
        bankName: item?.bankName,
        bankAccountType: item?.bankAccountType?.value,
        bankBeneficiaryName: item?.bankBeneficiaryName,
        bankAccountNumber: item?.bankAccountNumber,
        bankIfscCode: item?.bankIfscCode,
        cancelledCheque: item?.cancelledCheque,
      })
    );

    const lessorPartnershipData = lessorPartnership?.map((item) => ({
      lessorName: item?.lessorName,
      lessorAge: Number(item?.lessorAge),
      lessorFatherName: item?.lessorFatherName,
      lessorCompleteAddress: item?.lessorCompleteAddress,
      lessorAadharNumber: item?.lessorAadharNumber,
      lessorAadharCard: item?.lessorAadharCard,
      lessorPANNumber: item?.lessorPANNumber,
      lessorPANCard: item?.lessorPANCard,
      lessorGstinNumber: item?.lessorGstinNumber,
      lessorGstinCertificate: item?.lessorGstinCertificate,
      lessorPartnershipPercent: Number(item?.lessorPartnershipPercent),
    }));

    let data = {
      lessorEntityType: lessorEntityType?.value,
      pocFullName,
      pocMobileNumber,
      pocEmail,
    };
    if (
      lessorEntityType?.value === "Individual" ||
      lessorEntityType?.value === "Multiple individuals"
    ) {
      data.lessorIndividualMultiple = lessorIndividualMultipleData;
    }

    if (
      dbData?.lessorEntityType === "LLP" ||
      dbData?.lessorEntityType === "Pvt. ltd."
    ) {
      data.lessorEntityFullName = lessorEntityFullName;
      data.llpRegistrationCertificate = llpRegistrationCertificate;
      data.llpRegistrationNumber = llpRegistrationNumber;
      data.llpRegisteredAddress = llpRegisteredAddress;
      data.llpRepresentativeName = llpRepresentativeName;
      data.llpRepresentativeAddress = llpRepresentativeAddress;
      data.llpRepresentativeAadharNumber = llpRepresentativeAadharNumber;
      data.llpRepresentativeAadharCard = llpRepresentativeAadharCard;
      data.llpRepresentativePANNumber = llpRepresentativePANNumber;
      data.llpRepresentativePANCard = llpRepresentativePANCard;
      data.llpRepresentativeDIN = llpRepresentativeDIN;
      data.llpAuthorizedAttorney = llpAuthorizedAttorney;
      data.lessorGstinNumber = lessorGstinNumber;
      data.lessorGstinCertificate = lessorGstinCertificate;
      data.bankName = bankName;
      data.bankAccountType = bankAccountType?.value;
      data.bankBeneficiaryName = bankBeneficiaryName;
      data.bankAccountNumber = bankAccountNumber;
      data.bankIfscCode = bankIfscCode;
      data.cancelledCheque = cancelledCheque;
    }

    if (dbData?.lessorEntityType === "Partnership") {
      data.lessorEntityFullName = lessorEntityFullName;
      data.llpRegisteredAddress = llpRegisteredAddress;
      data.llpAuthorizedAttorney = llpAuthorizedAttorney;
      data.lessorGstinNumber = lessorGstinNumber;
      data.lessorGstinCertificate = lessorGstinCertificate;
      data.bankName = bankName;
      data.bankAccountType = bankAccountType?.value;
      data.bankBeneficiaryName = bankBeneficiaryName;
      data.bankAccountNumber = bankAccountNumber;
      data.bankIfscCode = bankIfscCode;
      data.cancelledCheque = cancelledCheque;
      data.lessorPartnership = lessorPartnershipData;
    }
    updateSupplyHandler(id, data);

    dispatch(
      tableDataHandler({
        ...state?.tableData,
        ...data,
      })
    );
  };
  const userDepartment = localStorage.getItem("department")
  const isEditableByuser = (e) => {
    if (userRole === "CEO" || userDepartment === "Supply" || userDepartment === "Management") {
      return true;
    }
    const editableFields = {
      Management: ["pocFullName", "pocMobileNumber", "pocEmail"],
    };

    const fieldName = e;

    if (editableFields[userDepartment] && editableFields[userDepartment].includes(fieldName)) {
      return true;
    }
    return false
  }
  const calculateRemainingFields = () => {
    let remainingCount = 0;
    let fields = [lessorEntityType];
    if (lessorEntityType) fields.push(pocFullName, pocMobileNumber, pocEmail)

    if (lessorEntityType?.value === "Individual") lessorIndividualMultiple.map((item, index) => {
      fields.push(lessorIndividualMultiple[index].lessorName, lessorIndividualMultiple[index].lessorAge, lessorIndividualMultiple[index].lessorFatherName, lessorIndividualMultiple[index].lessorCompleteAddress, lessorIndividualMultiple[index].lessorAadharNumber, lessorIndividualMultiple[index].lessorAadharCard, lessorIndividualMultiple[index].lessorPANNumber, lessorIndividualMultiple[index].lessorPANCard, lessorIndividualMultiple[index].lessorGstinNumber, lessorIndividualMultiple[index].lessorGstinCertificate, lessorIndividualMultiple[index].lessorPartnershipPercent, lessorIndividualMultiple[index].bankName, lessorIndividualMultiple[index].bankAccountType, lessorIndividualMultiple[index].bankBeneficiaryName, lessorIndividualMultiple[index].bankAccountNumber, lessorIndividualMultiple[index].bankIfscCode, lessorIndividualMultiple[index].cancelledCheque)
    })
    if (lessorEntityType?.value === "Multiple individuals") lessorIndividualMultiple.map((item, index) => {
      fields.push(lessorIndividualMultiple[index].lessorName, lessorIndividualMultiple[index].lessorAge, lessorIndividualMultiple[index].lessorFatherName, lessorIndividualMultiple[index].lessorCompleteAddress, lessorIndividualMultiple[index].lessorAadharNumber, lessorIndividualMultiple[index].lessorAadharCard, lessorIndividualMultiple[index].lessorPANNumber, lessorIndividualMultiple[index].lessorPANCard, lessorIndividualMultiple[index].lessorGstinNumber, lessorIndividualMultiple[index].lessorGstinCertificate, lessorIndividualMultiple[index].lessorPartnershipPercent, lessorIndividualMultiple[index].bankName, lessorIndividualMultiple[index].bankAccountType, lessorIndividualMultiple[index].bankBeneficiaryName, lessorIndividualMultiple[index].bankAccountNumber, lessorIndividualMultiple[index].bankIfscCode, lessorIndividualMultiple[index].cancelledCheque)
    });
    if (lessorEntityType?.value === "LLP") fields.push(lessorEntityFullName, llpRegistrationNumber, llpRegistrationCertificate, llpRegisteredAddress, llpRepresentativeName, llpRepresentativeAddress, llpRepresentativeAadharNumber, llpRepresentativeAadharCard, llpRepresentativePANNumber, llpRepresentativePANCard, llpAuthorizedAttorney, lessorGstinNumber, lessorGstinCertificate, bankName, bankAccountType, bankBeneficiaryName, bankAccountNumber, bankIfscCode, cancelledCheque);

    if (lessorEntityType?.value === "Partnership") {
      fields.push(lessorEntityFullName, llpRegisteredAddress, llpAuthorizedAttorney, lessorGstinNumber, lessorGstinCertificate, bankName, bankAccountType, bankBeneficiaryName, bankAccountNumber, bankIfscCode, cancelledCheque);

      lessorPartnership?.map((item, index) => {
        fields.push(
          lessorPartnership[index].lessorName,
          lessorPartnership[index].lessorAge,
          lessorPartnership[index].lessorFatherName,
          lessorPartnership[index].lessorCompleteAddress,
          lessorPartnership[index].lessorAadharNumber,
          lessorPartnership[index].lessorAadharCard,
          lessorPartnership[index].lessorPANNumber,
          lessorPartnership[index].lessorPANCard,
          lessorPartnership[index].lessorGstinNumber,
          lessorPartnership[index].lessorGstinCertificate,
          lessorPartnership[index].lessorPartnershipPercent,
        )
      })

    }
    if (lessorEntityType?.value === "Pvt. ltd.") fields.push
      (lessorEntityFullName, llpRegistrationNumber, llpRegistrationCertificate, llpRegisteredAddress, llpRepresentativeName, llpRepresentativeAddress, llpRepresentativeAadharNumber, llpRepresentativeAadharCard, llpRepresentativePANNumber, llpRepresentativePANCard, llpAuthorizedAttorney, lessorGstinNumber, lessorGstinCertificate, bankName, bankAccountType, bankBeneficiaryName, bankAccountNumber, bankIfscCode, cancelledCheque
      );



    fields.forEach(field => {
      if (typeof field === "object") {
        if (field?.[0]?.imageURL === null) {
          remainingCount++;
        } else if (field?.label === undefined && !field?.[0]?.imageURL) {
          if (!(field instanceof Date)) {
            remainingCount++;
          }
        }
      } else {
        if (field === null || field === "" || field === undefined || field.length === 0) {
          remainingCount++;
        }
      }
    });
    setRemainingCount(`(${remainingCount} fields pending / ${fields.length} )`)
    return `${remainingCount}/${fields.length}`;
  };
  calculateRemainingFields()
  return (
    <form className="mt-2" onSubmit={submitHandler} style={{ marginBottom: "120px" }}>
      <Row>
        {/* //! Lessor's entity type*/}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="lessorEntityType">
                Lessor's entity type
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="lessorEntityType"
              name="lessorEntityType"
              options={LessorEntityTypeList}
              value={lessorEntityType}
              onChange={(value) => setLessorEntityType(value)}
              classNamePrefix="select"
              placeholder=""
              // menuShouldScrollIntoView={false}
              menuPlacement="auto"
              styles={{
                menu: (base) => ({
                  ...base,
                  zIndex: 1050,
                  // position: "absolute",
                  // width: "100%",
                  // maxWidth: "300px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: !lessorEntityType ? 'red' : provided.borderColor,
                }),
              }}
              isDisabled={!isEditableByuser("lessorEntityType")}
            />
          </FormGroup>
        </Col>

        {/* //! Lessor's entity full name*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd." ||
          lessorEntityType?.value === "Partnership") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorEntityFullName">
                    Lessor's entity full name
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorEntityFullName}
                  id="lessorEntityFullName"
                  name="lessorEntityFullName"
                  //placeholder="Add lessor's entity full name"
                  onChange={(e) => setLessorEntityFullName(e.target.value)}
                  disabled={!isEditableByuser("lessorEntityFullName")}
                  style={{
                    borderColor: !lessorEntityFullName && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}

        {/* //!  Lessor's LLP registration number*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd.") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="llpRegistrationNumber">
                    Lessor's LLP registration number
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRegistrationNumber}
                  id="llpRegistrationNumber"
                  name="llpRegistrationNumber"
                  //placeholder="Add lessor's LLP registration number"
                  onChange={(e) => setLlpRegistrationNumber(e.target.value)}
                  disabled={!isEditableByuser("llpRegistrationNumber")}
                  style={{
                    borderColor: !llpRegistrationNumber && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}
        {/*//! Lessor's LLP registration certificate*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd.") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="llpRegistrationCertificate">
                  Lessor's LLP registration certificate
                </Label>
              </strong>
              {/* <AttachmentComponent
                imageList={llpRegistrationCertificate}
                setImageList={setLlpRegistrationCertificate}
                editData={llpRegistrationCertificateImageDummyState}
                uploadType={uploadLlpRegistrationCertificateImageType}
              /> */}
              <ImageModal
                imageList={llpRegistrationCertificate}
                setImageList={setLlpRegistrationCertificate}
                editData={llpRegistrationCertificateImageDummyState}
                uploadType={uploadLlpRegistrationCertificateImageType}
                canBeEdited={isEditableByuser("lessorLLPRegistrationCertificate")}
                fieldName={"Lessor's LLP registration certificate"}
              />
            </Col>
          )}
        {/* //!  Lessor's LLP registered address*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd." ||
          lessorEntityType?.value === "Partnership") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="llpRegisteredAddress">
                    Lessor's LLP registered address
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRegisteredAddress}
                  id="llpRegisteredAddress"
                  name="llpRegisteredAddress"
                  //placeholder="Add lessor's LLP registered address"
                  onChange={(e) => setLlpRegisteredAddress(e.target.value)}
                  disabled={!isEditableByuser("llpRegisteredAddress")}
                  style={{
                    borderColor: !llpRegisteredAddress && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}

        {/* //!  Lessor's LLP authorised representative's full name*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd.") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="llpRepresentativeName">
                    Lessor's LLP authorised representative's full name
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativeName}
                  id="llpRepresentativeName"
                  name="llpRepresentativeName"
                  //placeholder="Add lessor's LLP authorised representative's full name"
                  onChange={(e) => setLlpRepresentativeName(e.target.value)}
                  disabled={!isEditableByuser("llpRepresentativeName")}
                  style={{
                    borderColor: !llpRepresentativeName && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}
        {/* //!  Lessor's LLP authorised representative's complete address*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd.") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="llpRepresentativeAddress">
                    Lessor's LLP authorised representative's complete address
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativeAddress}
                  id="llpRepresentativeAddress"
                  name="llpRepresentativeAddress"
                  //placeholder="Add lessor's LLP authorised representative's complete address"
                  onChange={(e) => setLlpRepresentativeAddress(e.target.value)}
                  disabled={!isEditableByuser("llpRepresentativeAddress")}
                  style={{
                    borderColor: !llpRepresentativeAddress && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}
        {/* //!  Lessor's LLP authorised representative's aadhar number*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd.") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label
                    className="form-label"
                    for="llpRepresentativeAadharNumber"
                  >
                    Lessor's LLP authorised representative's aadhar number
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativeAadharNumber}
                  id="llpRepresentativeAadharNumber"
                  name="llpRepresentativeAadharNumber"
                  //placeholder="Add lessor's LLP authorised representative's aadhar number"
                  onChange={(e) =>
                    setLlpRepresentativeAadharNumber(e.target.value)
                  }
                  disabled={!isEditableByuser("llpRepresentativeAadharNumber")}
                  style={{
                    borderColor: !llpRepresentativeAadharNumber && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}
        {/*//! Lessor's LLP authorised representative's aadhar card*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd.") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="llpRepresentativeAadharCard">
                  Lessor's LLP authorised representative's aadhar card
                </Label>
              </strong>
              {/* <AttachmentComponent
                imageList={llpRepresentativeAadharCard}
                setImageList={setLlpRepresentativeAadharCard}
                editData={llpRepresentativeAadharCardImageDummyState}
                uploadType={uploadLlpRepresentativeAadharCardImageType}
              /> */}
              <ImageModal
                imageList={llpRepresentativeAadharCard}
                setImageList={setLlpRepresentativeAadharCard}
                editData={llpRepresentativeAadharCardImageDummyState}
                uploadType={uploadLlpRepresentativeAadharCardImageType}
                canBeEdited={isEditableByuser("titleSearchReportDocument")}
                fieldName={"Lessor's LLP authorised representative's aadhar card"}
              />
            </Col>
          )}

        {/* //!  Lessor's LLP authorised representative's PAN Number*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd.") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="llpRepresentativePANNumber">
                    Lessor's LLP authorised representative's PAN Number
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativePANNumber}
                  id="llpRepresentativePANNumber"
                  name="llpRepresentativePANNumber"
                  //placeholder="Add Lessor's LLP authorised representative's PAN Number"
                  onChange={(e) => setLlpRepresentativePANNumber(e.target.value)}
                  disabled={!isEditableByuser("llpRepresentativePANNumber")}
                  style={{
                    borderColor: !llpRepresentativePANNumber && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}
        {/*//! Lessor's LLP authorised representative's PAN Card*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd.") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="llpRepresentativePANCard">
                  Lessor's LLP authorised representative's PAN Card
                </Label>
              </strong>
              {/* <AttachmentComponent
                imageList={llpRepresentativePANCard}
                setImageList={setLlpRepresentativePANCard}
                editData={llpRepresentativePANCardImageDummyState}
                uploadType={uploadLlpRepresentativePANCardImageType}
              /> */}
              <ImageModal
                imageList={llpRepresentativePANCard}
                setImageList={setLlpRepresentativePANCard}
                editData={llpRepresentativePANCardImageDummyState}
                uploadType={uploadLlpRepresentativePANCardImageType}
                canBeEdited={isEditableByuser("lessorsLLPauthorisedrepresentativesPANcard")}
                fieldName={"Lessor's LLP authorised representative's PAN Card"}
              />
            </Col>
          )}
        {/* //!  Lessor's LLP authorised representative's DIN number*/}
        {/* {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd.") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="llpRepresentativeDIN">
                    Lessor's LLP authorised representative's DIN number*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativeDIN}
                  id="llpRepresentativeDIN"
                  name="llpRepresentativeDIN"
                  //placeholder="Add lessor's LLP authorised representative's DIN number"
                  onChange={(e) => setLlpRepresentativeDIN(e.target.value)}
                />
              </FormGroup>
            </Col>
          )} */}

        {/*//! Lessor's LLP authorised representative BR or Power of Attorney for signing */}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd." ||
          lessorEntityType?.value === "Partnership") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="floorPlans">
                  Lessor's LLP authorised representative BR or Power of Attorney
                  for signing
                </Label>
              </strong>
              {/* <AttachmentComponent
                imageList={llpAuthorizedAttorney}
                setImageList={setLlpAuthorizedAttorney}
                editData={llpAuthorizedAttorneyImageDummyState}
                uploadType={uploadLlpAuthorizedAttorneyImageType}
              /> */}
              <ImageModal
                imageList={llpAuthorizedAttorney}
                setImageList={setLlpAuthorizedAttorney}
                editData={llpAuthorizedAttorneyImageDummyState}
                uploadType={uploadLlpAuthorizedAttorneyImageType}
                canBeEdited={isEditableByuser("titleSearchReportDocument")}
                fieldName={"Lessor's LLP authorised representative BR or Power of Attorney for signing"}
              />
            </Col>
          )}
        {/* //!  Lessor's GSTIN number*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd." ||
          lessorEntityType?.value === "Partnership") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorGstinNumber">
                    Lessor's GSTIN number
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorGstinNumber}
                  id="lessorGstinNumber"
                  name="lessorGstinNumber"
                  //placeholder="Add lessor's GSTIN number"
                  onChange={(e) => setLessorGstinNumber(e.target.value)}
                  disabled={!isEditableByuser("lessorGstinNumber")}
                  style={{
                    borderColor: !lessorGstinNumber && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}
        {/*//! Lessor's GSTIN certificate*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd." ||
          lessorEntityType?.value === "Partnership") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="lessorGstinCertificate">
                  Lessor's GSTIN certificate
                </Label>
              </strong>
              <ImageModal
                imageList={lessorGstinCertificate}
                setImageList={setLessorGstinCertificate}
                editData={lessorGstinCertificateImageDummyState}
                uploadType={uploadLessorGstinCertificateImageType}
                canBeEdited={isEditableByuser("titleSearchReportDocument")}
                fieldName={"Lessor's GSTIN certificate"}
              />
            </Col>
          )}

        {/* //! Bank name*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd." ||
          lessorEntityType?.value === "Partnership") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankName">
                    Bank name
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={bankName}
                  id="bankName"
                  name="bankName"
                  //placeholder="Add bank name"
                  onChange={(e) => setBankName(e.target.value)}
                  disabled={!isEditableByuser("bankName")}
                  style={{
                    borderColor: !bankName && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}
        {/* //! Bank account type*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd." ||
          lessorEntityType?.value === "Partnership") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankAccountType">
                    Bank account type
                  </Label>
                </strong>

                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="bankAccountType"
                  name="bankAccountType"
                  options={BankAccountTypeList}
                  value={bankAccountType}
                  onChange={(value) => setBankAccountType(value)}
                  classNamePrefix="select"
                  placeholder=""
                  isDisabled={!isEditableByuser("bankAccountType")}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: !bankAccountType ? 'red' : provided.borderColor,
                    }),
                  }}
                />
              </FormGroup>
            </Col>
          )}
        {/* //! Bank account beneficiary name*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd." ||
          lessorEntityType?.value === "Partnership") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankBeneficiaryName">
                    Bank account beneficiary name
                  </Label>
                </strong>

                {/* <Select
                  isClearable={false}
                  isSearchable={true}
                  id="bankBeneficiaryName"
                  name="bankBeneficiaryName"
                  options={BankAccountTypeList}
                  value={bankBeneficiaryName}
                  onChange={(value) => setBankBeneficiaryName(value)}
                  classNamePrefix="select"
                  placeholder=""
                /> */}

                <Input
                  type="text"
                  value={bankBeneficiaryName}
                  id="bankBeneficiaryName"
                  name="bankBeneficiaryName"
                  //placeholder="Add bank account beneficiary name"
                  onChange={(e) => setBankBeneficiaryName(e.target.value)}
                  disabled={!isEditableByuser("bankBeneficiaryName")}
                  style={{
                    borderColor: !bankBeneficiaryName && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}
        {/* //!  Bank account number*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd." ||
          lessorEntityType?.value === "Partnership") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankAccountNumber">
                    Bank account number
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={bankAccountNumber}
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  //placeholder="Add bank account number"
                  onChange={(e) => setBankAccountNumber(e.target.value)}
                  disabled={!isEditableByuser("bankAccountNumber")}
                  style={{
                    borderColor: !bankAccountNumber && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}
        {/* //!  Bank IFSC code*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd." ||
          lessorEntityType?.value === "Partnership") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankIfscCode">
                    Bank IFSC code
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={bankIfscCode}
                  id="bankIfscCode"
                  name="bankIfscCode"
                  //placeholder="Add bank IFSC code"
                  onChange={(e) => setBankIfscCode(e.target.value)}
                  disabled={!isEditableByuser("bankIfscCode")}
                  style={{
                    borderColor: !bankIfscCode && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          )}
        {/*//! Cancelled cheque*/}
        {(lessorEntityType?.value === "LLP" ||
          lessorEntityType?.value === "Pvt. ltd." ||
          lessorEntityType?.value === "Partnership") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="cancelledCheque">
                  Cancelled cheque
                </Label>
              </strong>
              <ImageModal
                imageList={cancelledCheque}
                setImageList={setCancelledCheque}
                editData={cancelledChequeImageDummyState}
                uploadType={uploadCancelledChequeImageType}
                canBeEdited={isEditableByuser("titleSearchReportDocument")}
                fieldName={"Cancelled cheque"}
              />
            </Col>
          )}

        {/* //! Lessor's age*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorAge">
                    Lessor's age*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorAge}
                  id="lessorAge"
                  name="lessorAge"
                  //placeholder="Add lessor's age"
                  onChange={(e) => setLessorAge(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
        {/* //! Lessor's father name*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorFatherName">
                    Lessor's father name*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorFatherName}
                  id="lessorFatherName"
                  name="lessorFatherName"
                  //placeholder="Add lessor's father name"
                  onChange={(e) => setLessorFatherName(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
        {/* //! Lessor's complete address*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorCompleteAddress">
                    Lessor's complete address*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorCompleteAddress}
                  id="lessorCompleteAddress"
                  name="lessorCompleteAddress"
                  //placeholder="Add lessor's complete address"
                  onChange={(e) => setLessorCompleteAddress(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
        {/* //! Lessor's aadhar number*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorAadharNumber">
                    Lessor's aadhar number*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorAadharNumber}
                  id="lessorAadharNumber"
                  name="lessorAadharNumber"
                  //placeholder="Add lessor's aadhar number"
                  onChange={(e) => setLessorAadharNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
        {/*//! Lessor's aadhar card*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="lessorAadharCard">
                  Lessor's aadhar card*
                </Label>
              </strong>
              <AttachmentComponent
                imageList={lessorAadharCard}
                setImageList={setLessorAadharCard}
                editData={lessorAadharCardImageDummyState}
                uploadType={uploadLessorAadharCardImageType}
              />
            </Col>
          )}*/}
        {/* //! Lessor's PAN number*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorPANNumber">
                    Lessor's PAN number*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorPANNumber}
                  id="lessorPANNumber"
                  name="lessorPANNumber"
                  //placeholder="Add lessor's PAN number"
                  onChange={(e) => setLessorPANNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
        {/*//! Lessor's PAN card*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="lessorPANCard">
                  Lessor's PAN card*
                </Label>
              </strong>
              <AttachmentComponent
                imageList={lessorPANCard}
                setImageList={setLessorPANCard}
                editData={lessorPANCardImageDummyState}
                uploadType={uploadLessorPANCardImageType}
              />
            </Col>
          )}*/}
        {/* //! Lessor's partnership %*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorPartnershipPercent">
                    Lessor's partnership %*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorPartnershipPercent}
                  id="lessorPartnershipPercent"
                  name="lessorPartnershipPercent"
                  //placeholder="Add lessor's partnership %"
                  onChange={(e) => setLessorPartnershipPercent(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
        {/* //! Bank name*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankName">
                    Bank name*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={bankName}
                  id="bankName"
                  name="bankName"
                  //placeholder="Add bank name"
                  onChange={(e) => setBankName(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
        {/* //! Bank account type*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankAccountType">
                    Bank account type*
                  </Label>
                </strong>

                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="bankAccountType"
                  name="bankAccountType"
                  options={BankAccountTypeList}
                  value={bankAccountType}
                  onChange={(value) => setBankAccountType(value)}
                  classNamePrefix="select"
                  placeholder=""
                />
              </FormGroup>
            </Col>
          )}*/}
        {/* //! Bank account beneficiary name*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankBeneficiaryName">
                    Bank account beneficiary name*
                  </Label>
                </strong>

                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="bankBeneficiaryName"
                  name="bankBeneficiaryName"
                  options={BankAccountTypeList}
                  value={bankBeneficiaryName}
                  onChange={(value) => setBankBeneficiaryName(value)}
                  classNamePrefix="select"
                  placeholder=""
                />
              </FormGroup>
            </Col>
          )}*/}
        {/* //!  Bank account number*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankAccountNumber">
                    Bank account number*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={bankAccountNumber}
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  //placeholder="Add bank account number"
                  onChange={(e) => setBankAccountNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
        {/* //!  Bank IFSC code*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankIfscCode">
                    Bank IFSC code*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={bankIfscCode}
                  id="bankIfscCode"
                  name="bankIfscCode"
                  //placeholder="Add bank IFSC code"
                  onChange={(e) => setBankIfscCode(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
        {/*//! Cancelled cheque*/}
        {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="cancelledCheque">
                  Cancelled cheque*
                </Label>
              </strong>
              <AttachmentComponent
                imageList={cancelledCheque}
                setImageList={setCancelledCheque}
                editData={cancelledChequeImageDummyState}
                uploadType={uploadCancelledChequeImageType}
              />
            </Col>
          )}*/}

        {lessorIndividualMultiple?.map(
          (
            {
              id,
              lessorName,
              lessorAge,
              lessorFatherName,
              lessorCompleteAddress,
              lessorAadharNumber,
              lessorAadharCard,
              lessorPANNumber,
              lessorPANCard,
              lessorGstinNumber,
              lessorGstinCertificate,
              lessorPartnershipPercent,
              bankName,
              bankAccountType,
              bankBeneficiaryName,
              bankAccountNumber,
              bankIfscCode,
              cancelledCheque,
            },
            index
          ) => (
            <React.Fragment key={id}>
              {(lessorEntityType?.value === "Individual" ||
                lessorEntityType?.value === "Multiple individuals") && (
                  <hr
                    style={{
                      borderTop: "2px solid #ffe700",
                      width: "100%",
                    }}
                  />
                )}
              {(lessorEntityType?.value === "Individual" ||
                lessorEntityType?.value === "Multiple individuals") && (
                  <h5>
                    <Col sm="12">Lessor's {index + 1} details</Col>
                  </h5>
                )}
              <Col sm="12">
                <Row>
                  {/* //! Lessor's Name*/}
                  {(lessorEntityType?.value === "Individual" ||
                    lessorEntityType?.value === "Multiple individuals") && (
                      <>
                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label className="form-label" for="lessorName">
                                Lessor's name
                              </Label>
                            </strong>
                            <Input
                              type="text"
                              value={lessorName}
                              id="lessorName"
                              name="lessorName"
                              onChange={(e) =>
                                handleText(e.target.value, id, "lessorName")
                              }
                              disabled={!isEditableByuser("lessorName")}
                              style={{
                                borderColor: !lessorName && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/* //! Lessor's age*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label className="form-label" for="lessorAge">
                                Lessor's age
                              </Label>
                            </strong>
                            <Input
                              type="number"
                              value={lessorAge}
                              id="lessorAge"
                              name="lessorAge"
                              //placeholder="Add lessor's age"
                              // onChange={(e) => setLessorAge(e.target.value)}
                              onChange={(e) =>
                                handleNumber(e.target.value, id, "lessorAge")
                              }
                              onWheel={(e) => e.target.blur()}
                              disabled={!isEditableByuser("lessorAge")}
                              style={{
                                borderColor: !lessorAge && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/* //! Lessor's father name*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label
                                className="form-label"
                                for="lessorFatherName"
                              >
                                Lessor's father name
                              </Label>
                            </strong>
                            <Input
                              type="text"
                              value={lessorFatherName}
                              id="lessorFatherName"
                              name="lessorFatherName"
                              //placeholder="Add lessor's father name"
                              // onChange={(e) =>
                              //   setLessorFatherName(e.target.value)
                              // }
                              onChange={(e) =>
                                handleText(e.target.value, id, "lessorFatherName")
                              }
                              disabled={!isEditableByuser("lessorFatherName")}
                              style={{
                                borderColor: !lessorFatherName && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/* //! Lessor's complete address*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label
                                className="form-label"
                                for="lessorCompleteAddress"
                              >
                                Lessor's complete address
                              </Label>
                            </strong>
                            <Input
                              type="text"
                              value={lessorCompleteAddress}
                              id="lessorCompleteAddress"
                              name="lessorCompleteAddress"
                              //placeholder="Add lessor's complete address"
                              // onChange={(e) =>
                              //   setLessorCompleteAddress(e.target.value)
                              // }
                              onChange={(e) =>
                                handleText(
                                  e.target.value,
                                  id,
                                  "lessorCompleteAddress"
                                )
                              }
                              disabled={!isEditableByuser("lessorCompleteAddress")}
                              style={{
                                borderColor: !lessorCompleteAddress && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/* //! Lessor's aadhar number*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label
                                className="form-label"
                                for="lessorAadharNumber"
                              >
                                Lessor's aadhar number
                              </Label>
                            </strong>
                            <Input
                              type="text"
                              value={lessorAadharNumber}
                              id="lessorAadharNumber"
                              name="lessorAadharNumber"
                              //placeholder="Add lessor's aadhar number"
                              // onChange={(e) =>
                              //   setLessorAadharNumber(e.target.value)
                              // }
                              onChange={(e) =>
                                handleText(
                                  e.target.value,
                                  id,
                                  "lessorAadharNumber"
                                )
                              }
                              onWheel={(e) => e.target.blur()}
                              disabled={!isEditableByuser("lessorAadharNumber")}
                              style={{
                                borderColor: !lessorAadharNumber && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/*//! Lessor's aadhar card*/}

                        <Col sm="3">
                          <strong>
                            <Label className="form-label" for="lessorAadharCard">
                              Lessor's Aadhar Card
                            </Label>
                          </strong>
                          <div className="mt-2 d-flex align-items-center gap-2">
                            {lessorAadharCard !== null ? (
                              lessorAadharCard?.includes(".pdf") ? (
                                <Image
                                  onClick={() =>
                                    window.open(
                                      lessorAadharCard,
                                      "_blank"
                                    )
                                  }
                                  size={45}
                                  style={{
                                    marginBottom: "20px",
                                    cursor: "pointer"
                                  }}
                                />
                              ) : (
                                <img
                                  className="imageSection__image"
                                  src={lessorAadharCard}
                                  alt="Attachment"
                                  style={{
                                    width: "6rem",
                                    height: "6rem",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                    marginLeft: "1.7rem",
                                  }}
                                  onClick={() =>
                                    window.open(lessorAadharCard, "_blank")
                                  }
                                />
                              )
                            ) : null}

                            {
                              isEditableByuser("aadharFile") &&
                              <label htmlFor={`aadharFile${id}`}>
                                <p className="btn btn-sm">
                                  <Upload size={30} color={`${lessorAadharCard ? "grey" : "red"}`} />
                                </p>
                                <input
                                  name={`aadharFile${id}`}
                                  id={`aadharFile${id}`}
                                  className="d-none"
                                  type="file"
                                  onChange={(e) =>
                                    fileUploader(e, id, "lessorAadharCardImage")
                                  }
                                />
                              </label>
                            }
                          </div>
                        </Col>
                        {/* //! Lessor's PAN number*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label className="form-label" for="lessorPANNumber">
                                Lessor's PAN number
                              </Label>
                            </strong>
                            <Input
                              type="text"
                              value={lessorPANNumber}
                              id="lessorPANNumber"
                              name="lessorPANNumber"
                              //placeholder="Add lessor's PAN number"
                              // onChange={(e) =>
                              //   setLessorPANNumber(e.target.value)
                              // }
                              onChange={(e) =>
                                handleText(e.target.value, id, "lessorPANNumber")
                              }
                              disabled={!isEditableByuser("lessorPANNumber")}
                              style={{
                                borderColor: !lessorPANNumber && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/*//! Lessor's PAN card*/}

                        {/* <Col sm="3">
                          <strong>
                            <Label className="form-label" for="lessorPANCard">
                              Lessor's PAN card*
                            </Label>
                          </strong>
                          <AttachmentComponent
                            imageList={lessorPANCard}
                            setImageList={setLessorPANCard}
                            editData={lessorPANCardImageDummyState}
                            uploadType={uploadLessorPANCardImageType}
                          />
                        </Col> */}

                        <Col sm="3">
                          <strong>
                            <Label className="form-label" for="lessorPANCard">
                              Lessor's PAN card
                            </Label>
                          </strong>
                          <div className="mt-2 d-flex align-items-center gap-2">
                            {lessorPANCard !== null ? (
                              lessorPANCard?.includes(".pdf") ? (
                                <Image
                                  onClick={() =>
                                    window.open(
                                      lessorPANCard,
                                      "_blank"
                                    )
                                  }
                                  size={45}
                                  style={{
                                    marginBottom: "20px",
                                    cursor: "pointer"
                                  }}
                                />
                              ) : (
                                <img
                                  className="imageSection__image"
                                  src={lessorPANCard}
                                  alt="Attachment"
                                  style={{
                                    width: "6rem",
                                    height: "6rem",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                    marginLeft: "1.7rem",
                                  }}
                                  onClick={() =>
                                    window.open(lessorPANCard, "_blank")
                                  }
                                />
                              )
                            ) : null}

                            {
                              isEditableByuser("panFile1") &&
                              <label htmlFor={`panFile${id}`}>
                                <p className="btn btn-sm">
                                  <Upload size={30} color={`${lessorPANCard ? "grey" : "red"}`} />
                                </p>
                                <input
                                  name={`panFile${id}`}
                                  id={`panFile${id}`}
                                  className="d-none"
                                  type="file"
                                  onChange={(e) =>
                                    fileUploader(e, id, "lessorPANCardImage")
                                  }
                                />
                              </label>
                            }
                          </div>
                        </Col>

                        {/* //! Lessor's GSTIN number*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label
                                className="form-label"
                                for="lessorGstinNumber"
                              >
                                Lessor's GSTIN number
                              </Label>
                            </strong>
                            <Input
                              type="text"
                              value={lessorGstinNumber}
                              id="lessorGstinNumber"
                              name="lessorGstinNumber"
                              //placeholder="Add lessor's GSTIN number"
                              // onChange={(e) =>
                              //   setLessorAadharNumber(e.target.value)
                              // }
                              onChange={(e) =>
                                handleText(
                                  e.target.value,
                                  id,
                                  "lessorGstinNumber"
                                )
                              }
                              disabled={!isEditableByuser("lessorGstinNumber")}
                              style={{
                                borderColor: !lessorGstinNumber && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/*//! Lessor's GSTIN certificate*/}

                        <Col sm="3">
                          <strong>
                            <Label
                              className="form-label"
                              for="lessorGstinCertificate"
                            >
                              Lessor's GSTIN certificate
                            </Label>
                          </strong>
                          <div className="mt-2 d-flex align-items-center gap-2">
                            {lessorGstinCertificate !== null ? (
                              lessorGstinCertificate?.includes(".pdf") ? (
                                <Image
                                  onClick={() =>
                                    window.open(
                                      lessorGstinCertificate,
                                      "_blank"
                                    )
                                  }
                                  size={45}
                                  style={{
                                    marginBottom: "20px",
                                    cursor: "pointer"
                                  }}
                                />
                              ) : (
                                <img
                                  className="imageSection__image"
                                  src={lessorGstinCertificate}
                                  alt="Attachment"
                                  style={{
                                    width: "6rem",
                                    height: "6rem",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                    marginLeft: "1.7rem",
                                  }}
                                  onClick={() =>
                                    window.open(lessorGstinCertificate, "_blank")
                                  }
                                />
                              )
                            ) : null}
                            {
                              isEditableByuser("gstFile1") &&
                              <label htmlFor={`gstFile${id}`}>
                                <p className="btn btn-sm">
                                  <Upload size={30} color={`${lessorGstinCertificate ? "grey" : "red"}`} />
                                </p>
                                <input
                                  name={`gstFile${id}`}
                                  id={`gstFile${id}`}
                                  className="d-none"
                                  type="file"
                                  onChange={(e) =>
                                    fileUploader(
                                      e,
                                      id,
                                      "lessorGstinCertificateImage"
                                    )
                                  }
                                />
                              </label>
                            }
                          </div>
                        </Col>

                        {/* //! Lessor's partnership %*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label
                                className="form-label"
                                for="lessorPartnershipPercent"
                              >
                                Lessor's partnership %
                              </Label>
                            </strong>
                            <Input
                              type="text"
                              value={lessorPartnershipPercent}
                              id="lessorPartnershipPercent"
                              name="lessorPartnershipPercent"
                              //placeholder="Add lessor's partnership %"
                              // onChange={(e) =>
                              //   setLessorPartnershipPercent(e.target.value)
                              // }
                              onChange={(e) =>
                                handleNumber(
                                  e.target.value,
                                  id,
                                  "lessorPartnershipPercent"
                                )
                              }
                              disabled={!isEditableByuser("lessorPartnershipPercent")}
                              style={{
                                borderColor: !lessorPartnershipPercent && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/* //! Bank name*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label className="form-label" for="bankName">
                                Bank name
                              </Label>
                            </strong>
                            <Input
                              type="text"
                              value={bankName}
                              id="bankName"
                              name="bankName"
                              //placeholder="Add bank name"
                              // onChange={(e) => setBankName(e.target.value)}
                              onChange={(e) =>
                                handleText(e.target.value, id, "bankName")
                              }
                              disabled={!isEditableByuser("bankName")}
                              style={{
                                borderColor: !bankName && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/* //! Bank account type*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label className="form-label" for="bankAccountType">
                                Bank account type
                              </Label>
                            </strong>

                            <Select
                              isClearable={false}
                              isSearchable={true}
                              id="bankAccountType"
                              name="bankAccountType"
                              options={BankAccountTypeList}
                              value={bankAccountType}
                              // onChange={(value) => setBankAccountType(value)}
                              onChange={(value) =>
                                handleText(value, id, "bankAccountType")
                              }
                              classNamePrefix="select"
                              placeholder=""
                              isDisabled={!isEditableByuser("bankAccountType")}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderColor: !bankAccountType ? 'red' : provided.borderColor,
                                }),
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/* //! Bank account beneficiary name*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label
                                className="form-label"
                                for="bankBeneficiaryName"
                              >
                                Bank account beneficiary name
                              </Label>
                            </strong>
                            {/* 
                            <Select
                              isClearable={false}
                              isSearchable={true}
                              id="bankBeneficiaryName"
                              name="bankBeneficiaryName"
                              options={BankAccountTypeList}
                              value={bankBeneficiaryName}
                              onChange={(value) =>
                                setBankBeneficiaryName(value)
                              }
                              classNamePrefix="select"
                              placeholder=""
                            /> */}
                            <Input
                              type="text"
                              value={bankBeneficiaryName}
                              id="bankBeneficiaryName"
                              name="bankBeneficiaryName"
                              //placeholder="Add bank account beneficiary name"
                              // onChange={(e) =>
                              //   setBankBeneficiaryName(e.target.value)
                              // }
                              onChange={(e) =>
                                handleText(
                                  e.target.value,
                                  id,
                                  "bankBeneficiaryName"
                                )
                              }
                              disabled={!isEditableByuser("bankBeneficiaryName")}
                              style={{
                                borderColor: !bankBeneficiaryName && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/* //!  Bank account number*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label
                                className="form-label"
                                for="bankAccountNumber"
                              >
                                Bank account number
                              </Label>
                            </strong>
                            <Input
                              type="text"
                              value={bankAccountNumber}
                              id="bankAccountNumber"
                              name="bankAccountNumber"
                              //placeholder="Add bank account number"
                              // onChange={(e) =>
                              //   setBankAccountNumber(e.target.value)
                              // }
                              onChange={(e) =>
                                handleText(
                                  e.target.value,
                                  id,
                                  "bankAccountNumber"
                                )
                              }
                              disabled={!isEditableByuser("bankAccountNumber")}
                              style={{
                                borderColor: !bankAccountNumber && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/* //!  Bank IFSC code*/}

                        <Col sm="3">
                          <FormGroup className="w-100">
                            <strong>
                              <Label className="form-label" for="bankIfscCode">
                                Bank IFSC code
                              </Label>
                            </strong>
                            <Input
                              type="text"
                              value={bankIfscCode}
                              id="bankIfscCode"
                              name="bankIfscCode"
                              //placeholder="Add bank IFSC code"
                              // onChange={(e) => setBankIfscCode(e.target.value)}
                              onChange={(e) =>
                                handleText(e.target.value, id, "bankIfscCode")
                              }
                              disabled={!isEditableByuser("bankIfscCode")}
                              style={{
                                borderColor: !bankIfscCode && 'red',
                              }}
                            />
                          </FormGroup>
                        </Col>

                        {/*//! Cancelled cheque*/}

                        {/* <Col sm="3">
                          <strong>
                            <Label className="form-label" for="cancelledCheque">
                              Cancelled cheque*
                            </Label>
                          </strong>
                          <AttachmentComponent
                            imageList={cancelledCheque}
                            setImageList={setCancelledCheque}
                            editData={cancelledChequeImageDummyState}
                            uploadType={uploadCancelledChequeImageType}
                          />
                        </Col> */}

                        <Col sm="3">
                          <strong>
                            <Label className="form-label" for="cancelledCheque">
                              Cancelled cheque
                            </Label>
                          </strong>
                          <div className="mt-2 d-flex align-items-center gap-2">
                            {cancelledCheque !== null ? (
                              cancelledCheque?.includes(".pdf") ? (
                                <Image
                                  onClick={() =>
                                    window.open(
                                      cancelledCheque,
                                      "_blank"
                                    )
                                  }
                                  size={45}
                                  style={{
                                    marginBottom: "20px",
                                    cursor: "pointer"
                                  }}
                                />
                              ) : (
                                <img
                                  className="imageSection__image"
                                  src={cancelledCheque}
                                  alt="Attachment"
                                  style={{
                                    width: "6rem",
                                    height: "6rem",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                    marginLeft: "1.7rem",
                                  }}
                                  onClick={() =>
                                    window.open(cancelledCheque, "_blank")
                                  }
                                />
                              )
                            ) : null}
                            {
                              isEditableByuser("cancelCheque") &&
                              <label htmlFor={`chequeFile${id}`}>
                                <p className="btn btn-sm">
                                  <Upload size={30} color={`${cancelledCheque ? "grey" : "red"}`} />
                                </p>
                                <input
                                  name={`chequeFile${id}`}
                                  id={`chequeFile${id}`}
                                  className="d-none"
                                  type="file"
                                  onChange={(e) =>
                                    fileUploader(e, id, "cancelledChequeImage")
                                  }
                                />
                              </label>
                            }
                          </div>
                        </Col>
                      </>
                    )}
                </Row>
              </Col>
            </React.Fragment>
          )
        )}
        {
          isEditableByuser("AddLessorsInfo") &&
          (lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="12" className="mb-2">
              <AddButton
                addAction={handleAddLessorIndividualMultiple}
                buttonName={"lessor's info"}
              />
            </Col>
          )
        }


        {/* //!  Lessor's LLP authorised representative's full name*/}
        {/* {lessorEntityType?.value === "LLP" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="llpRepresentativeName">
                    Lessor's LLP authorised representative's full name*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativeName}
                  id="llpRepresentativeName"
                  name="llpRepresentativeName"
                  //placeholder="Add lessor's LLP authorised representative's full name"
                  onChange={(e) => setLlpRepresentativeName(e.target.value)}
                />
              </FormGroup>
            </Col>
          )} */}
        {/* //!  Lessor's LLP authorised representative's complete address*/}
        {/* {lessorEntityType?.value === "LLP" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="llpRepresentativeAddress">
                    Lessor's LLP authorised representative's complete address*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativeAddress}
                  id="llpRepresentativeAddress"
                  name="llpRepresentativeAddress"
                  //placeholder="Add lessor's LLP authorised representative's complete address"
                  onChange={(e) => setLlpRepresentativeAddress(e.target.value)}
                />
              </FormGroup>
            </Col>
          )} */}
        {/* //!  Lessor's LLP authorised representative's aadhar number*/}
        {/* {lessorEntityType?.value === "LLP" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label
                    className="form-label"
                    for="llpRepresentativeAadharNumber"
                  >
                    Lessor's LLP authorised representative's aadhar number*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativeAadharNumber}
                  id="llpRepresentativeAadharNumber"
                  name="llpRepresentativeAadharNumber"
                  //placeholder="Add lessor's LLP authorised representative's aadhar number"
                  onChange={(e) =>
                    setLlpRepresentativeAadharNumber(e.target.value)
                  }
                />
              </FormGroup>
            </Col>
          )} */}
        {/*//! Lessor's LLP authorised representative's aadhar card*/}
        {/* {lessorEntityType?.value === "LLP" && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="llpRepresentativeAadharCard">
                  Lessor's LLP authorised representative's aadhar card*
                </Label>
              </strong>
              <AttachmentComponent
                imageList={llpRepresentativeAadharCard}
                setImageList={setLlpRepresentativeAadharCard}
                editData={llpRepresentativeAadharCardImageDummyState}
                uploadType={uploadLlpRepresentativeAadharCardImageType}
              />
            </Col>
          )} */}
        {/* //!  Lessor's LLP authorised representative's DIN number*/}
        {/* {lessorEntityType?.value === "LLP" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="llpRepresentativeDIN">
                    Lessor's LLP authorised representative's DIN number*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativeDIN}
                  id="llpRepresentativeDIN"
                  name="llpRepresentativeDIN"
                  //placeholder="Add lessor's LLP authorised representative's DIN number"
                  onChange={(e) => setLlpRepresentativeDIN(e.target.value)}
                />
              </FormGroup>
            </Col>
          )} */}
        {/* //! Lessor's POC's full name*/}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Lessor's POC's full name*</Label>
              </strong>
              <Input
                type="text"
                value={pocFullName}
                id="pocFullName"
                name="pocFullName"
                //placeholder="Add lessor's LLP authorised representative's DIN number"
                onChange={(e) => setPOCFullName(e.target.value)}
              />
            </FormGroup>
          </Col> */}
        {/* //! Lessor's POC's mobile number*/}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessor's POC's mobile number*
                </Label>
              </strong>
              <Input
                type="text"
                value={pocMobileNumber}
                id="pocMobileNumber"
                name="pocMobileNumber"
                //placeholder="Add lessor's POC's mobile number"
                onChange={(e) => setPOCMobileNumber(e.target.value)}
              />
            </FormGroup>
          </Col> */}
        {/* //! Lessor's POC's email*/}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Lessor's POC's email*</Label>
              </strong>
              <Input
                type="text"
                value={pocEmail}
                id="pocEmail"
                name="pocEmail"
                //placeholder="Add lessor's POC's email"
                onChange={(e) => setPOCEmail(e.target.value)}
              />
            </FormGroup>
          </Col> */}
        {/* //! Lessor's POC's aadhar number*/}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessor's POC's aadhar number*
                </Label>
              </strong>
              <Input
                type="text"
                value={pocAadharNumber}
                id="pocAadharNumber"
                name="pocAadharNumber"
                //placeholder="Add lessor's POC's aadhar number"
                onChange={(e) => setPOCAadharNumber(e.target.value)}
              />
            </FormGroup>
          </Col> */}
        {/*//! Lessor's POC's aadhar card*/}
        {/* <Col sm="3">
            <strong>
              <Label className="form-label" for="llpRepresentativeAadharCard">
                Lessor's POC's aadhar card*
              </Label>
            </strong>
            <AttachmentComponent
              imageList={pocAadharCard}
              setImageList={setPOCAadharCard}
              editData={POCAadharCardImageDummyState}
              uploadType={uploadPOCAadharCardImageType}
            />
          </Col> */}
        {/* {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") &&
            lessorLLPPvtLtd?.map(
              (
                {
                  id,
                  lessorEntityFullName,
                  lessorName,
                  lessorCompleteAddress,
                  lessorAadharNumber,
                  lessorAadharCard,
                  lessorDINNumber,
                  bankName,
                  bankAccountType,
                  bankBeneficiaryName,
                  bankAccountNumber,
                  bankIfscCode,
                  cancelledCheque,
                },
                index
              ) => (
                <React.Fragment key={id}>
                  <hr
                    style={{
                      borderTop: "2px solid #ffe700",
                      width: "100%",
                    }}
                  />

                  <Col sm="12">
                    <Row> */}
        {/* //! Lessor's entity full name*/}
        {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label
                              className="form-label"
                              for="lessorEntityFullName"
                            >
                              Lessor's entity full name {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={lessorEntityFullName}
                            id="lessorEntityFullName"
                            name="lessorEntityFullName"
                            //placeholder="Add lessor's entity full name"
                            onChange={(e) =>
                              setLessorEntityFullName(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col> */}
        {/* //!  Lessor's LLP authorised representative's full name**/}
        {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label className="form-label" for="lessorName">
                              Lessor's LLP authorised representative's full name{" "}
                              {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={lessorName}
                            id="lessorName"
                            name="lessorName"
                            //placeholder="Add  Lessor's LLP authorised representative's full name*"
                            // onChange={(e) => setLessorName(e.target.value)}
                            onChange={(e) =>
                              handleLessorName(e.target.value, id, "lessorName")
                            }
                          />
                        </FormGroup>
                      </Col> */}
        {/* //! Lessor's LLP authorised representative's complete address*/}
        {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label
                              className="form-label"
                              for="lessorCompleteAddress"
                            >
                              Lessor's LLP authorised representative's complete
                              address {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={lessorCompleteAddress}
                            id="lessorCompleteAddress"
                            name="lessorCompleteAddress"
                            //placeholder="Add lessor's LLP authorised representative's complete address"
                            onChange={(e) =>
                              setLessorCompleteAddress(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col> */}
        {/* //! Lessor's LLP authorised representative's aadhar number*/}
        {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label
                              className="form-label"
                              for="lessorAadharNumber"
                            >
                              Lessor's LLP authorised representative's aadhar
                              number {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={lessorAadharNumber}
                            id="lessorAadharNumber"
                            name="lessorAadharNumber"
                            //placeholder="Add lessor's LLP authorised representative's aadhar number"
                            onChange={(e) =>
                              setLessorAadharNumber(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col> */}
        {/*//! Lessor's LLP authorised representative's aadhar card*/}
        {/* <Col sm="3">
                        <strong>
                          <Label className="form-label" for="lessorAadharCard">
                            Lessor's LLP authorised representative's aadhar card{" "}
                            {index + 1}*
                          </Label>
                        </strong>
                        <AttachmentComponent
                          imageList={lessorAadharCard}
                          setImageList={setLessorAadharCard}
                          editData={lessorAadharCardImageDummyState}
                          uploadType={uploadLessorAadharCardImageType}
                        />
                      </Col> */}
        {/* //! Lessor's LLP authorised representative's DIN number*/}
        {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label className="form-label" for="lessorDINNumber">
                              Lessor's LLP authorised representative's DIN
                              number {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={lessorDINNumber}
                            id="lessorDINNumber"
                            name="lessorDINNumber"
                            //placeholder="Add lessor's LLP authorised representative's DIN number"
                            // onChange={(e) => setLessorName(e.target.value)}
                            onChange={(e) =>
                              handleLessorName(
                                e.target.value,
                                id,
                                "lessorDINNumber"
                              )
                            }
                          />
                        </FormGroup>
                      </Col> */}
        {/* //! Bank name*/}
        {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label className="form-label" for="bankName">
                              Bank name {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={bankName}
                            id="bankName"
                            name="bankName"
                            //placeholder="Add bank name"
                            onChange={(e) => setBankName(e.target.value)}
                          />
                        </FormGroup>
                      </Col> */}
        {/* //! Bank account type*/}
        {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label className="form-label" for="bankAccountType">
                              Bank account type {index + 1}*
                            </Label>
                          </strong>

                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id="bankAccountType"
                            name="bankAccountType"
                            options={BankAccountTypeList}
                            value={bankAccountType}
                            onChange={(value) => setBankAccountType(value)}
                            classNamePrefix="select"
                            placeholder=""
                          />
                        </FormGroup>
                      </Col> */}
        {/* //! Bank account beneficiary name*/}
        {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label
                              className="form-label"
                              for="bankBeneficiaryName"
                            >
                              Bank account beneficiary name {index + 1}*
                            </Label>
                          </strong>

                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id="bankBeneficiaryName"
                            name="bankBeneficiaryName"
                            options={BankAccountTypeList}
                            value={bankBeneficiaryName}
                            onChange={(value) => setBankBeneficiaryName(value)}
                            classNamePrefix="select"
                            placeholder=""
                          />
                        </FormGroup>
                      </Col> */}
        {/* //!  Bank account number*/}
        {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label
                              className="form-label"
                              for="bankAccountNumber"
                            >
                              Bank account number {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={bankAccountNumber}
                            id="bankAccountNumber"
                            name="bankAccountNumber"
                            //placeholder="Add bank account number"
                            onChange={(e) =>
                              setBankAccountNumber(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col> */}
        {/* //!  Bank IFSC code*/}
        {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label className="form-label" for="bankIfscCode">
                              Bank IFSC code {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={bankIfscCode}
                            id="bankIfscCode"
                            name="bankIfscCode"
                            //placeholder="Add bank IFSC code"
                            onChange={(e) => setBankIfscCode(e.target.value)}
                          />
                        </FormGroup>
                      </Col> */}
        {/*//! Cancelled cheque*/}
        {/* <Col sm="3">
                        <strong>
                          <Label className="form-label" for="cancelledCheque">
                            Cancelled cheque {index + 1}*
                          </Label>
                        </strong>
                        <AttachmentComponent
                          imageList={cancelledCheque}
                          setImageList={setCancelledCheque}
                          editData={cancelledChequeImageDummyState}
                          uploadType={uploadCancelledChequeImageType}
                        />
                      </Col>
                    </Row>
                  </Col>
                </React.Fragment>
              )
            )} */}
        {/* {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") && (
            <Col sm="12" className="mb-2">
              <AddButton
                addAction={handleAddLessorLLPPvtLtd}
                buttonName={"lessor's info"}
              />
            </Col>
          )} */}
      </Row>

      {lessorEntityType?.value === "Partnership" &&
        lessorPartnership?.map(
          (
            {
              id,
              lessorEntityFullName,
              lessorName,
              lessorAge,
              lessorFatherName,
              lessorCompleteAddress,
              lessorAadharNumber,
              lessorAadharCard,
              lessorPANNumber,
              lessorPANCard,
              lessorGstinNumber,
              lessorGstinCertificate,
              lessorPartnershipPercent,
            },
            index
          ) => (
            <React.Fragment key={id}>
              <hr
                style={{
                  borderTop: "2px solid #ffe700",
                  width: "100%",
                }}
              />
              {
                <h5>
                  <Col sm="12">Lessor's {index + 1} details</Col>
                </h5>
              }
              <Col sm="12">
                <Row>
                  {/* //! Lessor's entity full name*/}
                  {/* <Col sm="3">
                      <FormGroup className="w-100">
                        <strong>
                          <Label
                            className="form-label"
                            for="lessorEntityFullName"
                          >
                            Lessor's entity full name {index + 1}*
                          </Label>
                        </strong>
                        <Input
                          type="text"
                          value={lessorEntityFullName}
                          id="lessorEntityFullName"
                          name="lessorEntityFullName"
                          //placeholder="Add lessor's entity full name"
                          onChange={(e) =>
                            setLessorEntityFullName(e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col> */}

                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="lessorName">
                          Lessor's name
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={lessorName}
                        id="lessorName"
                        name="lessorName"
                        //placeholder="Add lessor's name"
                        // onChange={(e) => setLessorName(e.target.value)}
                        onChange={(e) =>
                          handlePartnershipText(
                            e.target.value,
                            id,
                            "lessorName"
                          )
                        }
                        disabled={!isEditableByuser("lessorName")}
                        style={{
                          borderColor: !lessorName && 'red',
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/* //! Lessor's age*/}

                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="lessorAge">
                          Lessor's age
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={lessorAge}
                        id="lessorAge"
                        name="lessorAge"
                        //placeholder="Add lessor's age"
                        // onChange={(e) => setLessorAge(e.target.value)}
                        onChange={(e) =>
                          handlePartnershipNumber(
                            e.target.value,
                            id,
                            "lessorAge"
                          )
                        }
                        disabled={!isEditableByuser("lessorAge")}
                        style={{
                          borderColor: !lessorAge && 'red',
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/* //! Lessor's father name*/}

                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="lessorFatherName">
                          Lessor's father name
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={lessorFatherName}
                        id="lessorFatherName"
                        name="lessorFatherName"
                        //placeholder="Add lessor's father name"
                        // onChange={(e) => setLessorFatherName(e.target.value)}
                        onChange={(e) =>
                          handlePartnershipText(
                            e.target.value,
                            id,
                            "lessorFatherName"
                          )
                        }
                        disabled={!isEditableByuser("lessorFatherName")}
                        style={{
                          borderColor: !lessorFatherName && 'red',
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/* //! Lessor's complete address*/}

                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="lessorCompleteAddress"
                        >
                          Lessor's complete address
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={lessorCompleteAddress}
                        id="lessorCompleteAddress"
                        name="lessorCompleteAddress"
                        //placeholder="Add lessor's complete address"
                        // onChange={(e) =>
                        //   setLessorCompleteAddress(e.target.value)
                        // }
                        onChange={(e) =>
                          handlePartnershipText(
                            e.target.value,
                            id,
                            "lessorCompleteAddress"
                          )
                        }
                        disabled={!isEditableByuser("lessorCompleteAddress")}
                        style={{
                          borderColor: !lessorCompleteAddress && 'red',
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/* //! Lessor's aadhar number*/}

                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="lessorAadharNumber">
                          Lessor's aadhar number
                        </Label>
                      </strong>
                      <Input
                        type="number"
                        value={lessorAadharNumber}
                        id="lessorAadharNumber"
                        name="lessorAadharNumber"
                        //placeholder="Add lessor's aadhar number"
                        // onChange={(e) =>
                        //   setLessorAadharNumber(e.target.value)
                        // }
                        onChange={(e) =>
                          handlePartnershipText(
                            e.target.value,
                            id,
                            "lessorAadharNumber"
                          )
                        }
                        onWheel={(e) => e.target.blur()}
                        disabled={!isEditableByuser("lessorAadharNumber")}
                        style={{
                          borderColor: !lessorAadharNumber && 'red',
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/*//! Lessor's aadhar card*/}

                  {/* <Col sm="3">
                      <strong>
                        <Label className="form-label" for="lessorAadharCard">
                          Lessor's aadhar card*
                        </Label>
                      </strong>
                      <AttachmentComponent
                        imageList={lessorAadharCard}
                        setImageList={setLessorAadharCard}
                        editData={lessorAadharCardImageDummyState}
                        uploadType={uploadLessorAadharCardImageType}
                      />
                    </Col> */}

                  <Col sm="3">
                    <strong>
                      <Label className="form-label" for="lessorAadharCard">
                        Lessor's Aadhar Card
                      </Label>
                    </strong>
                    <div className="mt-2 d-flex align-items-center gap-2">
                      {lessorAadharCard !== null ? (
                        lessorAadharCard?.includes(".pdf") ? (
                          <Image
                            onClick={() =>
                              window.open(
                                lessorAadharCard,
                                "_blank"
                              )
                            }
                            size={45}
                            style={{
                              marginBottom: "20px",
                              cursor: "pointer"
                            }}
                          />
                        ) : (
                          <img
                            className="imageSection__image"
                            src={lessorAadharCard}
                            alt="Attachment"
                            style={{
                              width: "6rem",
                              height: "6rem",
                              objectFit: "cover",
                              cursor: "pointer",
                              marginLeft: "1.7rem",
                            }}
                            onClick={() =>
                              window.open(lessorAadharCard, "_blank")
                            }
                          />
                        )
                      ) : null}
                      {
                        isEditableByuser("aadharFile") &&
                        <label htmlFor={`aadharFile${id}`}>
                          <p className="btn btn-sm">
                            <Upload size={30} />
                          </p>
                          <input
                            name={`aadharFile${id}`}
                            id={`aadharFile${id}`}
                            className="d-none"
                            type="file"
                            onChange={(e) =>
                              fileUploader(e, id, "lessorAadharCardImage")
                            }
                          />
                        </label>
                      }
                    </div>
                  </Col>

                  {/* //! Lessor's PAN number*/}

                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="lessorPANNumber">
                          Lessor's PAN number
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={lessorPANNumber}
                        id="lessorPANNumber"
                        name="lessorPANNumber"
                        //placeholder="Add lessor's PAN number"
                        // onChange={(e) => setLessorPANNumber(e.target.value)}
                        onChange={(e) =>
                          handlePartnershipText(
                            e.target.value,
                            id,
                            "lessorPANNumber"
                          )
                        }
                        disabled={!isEditableByuser("lessorPANNumber")}
                        style={{
                          borderColor: !lessorPANNumber && 'red',
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/*//! Lessor's PAN card*/}

                  {/* <Col sm="3">
                      <strong>
                        <Label className="form-label" for="lessorPANCard">
                          Lessor's PAN card*
                        </Label>
                      </strong>
                      <AttachmentComponent
                        imageList={lessorPANCard}
                        setImageList={setLessorPANCard}
                        editData={lessorPANCardImageDummyState}
                        uploadType={uploadLessorPANCardImageType}
                      />
                    </Col> */}

                  <Col sm="3">
                    <strong>
                      <Label className="form-label" for="lessorPANCard">
                        Lessor's PAN card
                      </Label>
                    </strong>
                    <div className="mt-2 d-flex align-items-center gap-2">
                      {lessorPANCard !== null ? (
                        lessorPANCard?.includes(".pdf") ? (
                          <Image
                            onClick={() =>
                              window.open(
                                lessorPANCard,
                                "_blank"
                              )
                            }
                            size={45}
                            style={{
                              marginBottom: "20px",
                              cursor: "pointer"
                            }}
                          />
                        ) : (
                          <img
                            className="imageSection__image"
                            src={lessorPANCard}
                            alt="Attachment"
                            style={{
                              width: "6rem",
                              height: "6rem",
                              objectFit: "cover",
                              cursor: "pointer",
                              marginLeft: "1.7rem",
                            }}
                            onClick={() => window.open(lessorPANCard, "_blank")}
                          />
                        )
                      ) : null}
                      {
                        isEditableByuser("panFile2") &&
                        <label htmlFor={`panFile${id}`}>
                          <p className="btn btn-sm">
                            <Upload size={30} />
                          </p>
                          <input
                            name={`panFile${id}`}
                            id={`panFile${id}`}
                            className="d-none"
                            type="file"
                            onChange={(e) =>
                              fileUploader(e, id, "lessorPANCardImage")
                            }
                          />
                        </label>
                      }
                    </div>
                  </Col>

                  {/* //! Lessor's GSTIN number*/}

                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="lessorGstinNumber">
                          Lessor's GSTIN number
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={lessorGstinNumber}
                        id="lessorGstinNumber"
                        name="lessorGstinNumber"
                        //placeholder="Add lessor's GSTIN number"
                        // onChange={(e) =>
                        //   setLessorAadharNumber(e.target.value)
                        // }
                        onChange={(e) =>
                          handlePartnershipText(
                            e.target.value,
                            id,
                            "lessorGstinNumber"
                          )
                        }
                        disabled={!isEditableByuser("lessorGstinNumber")}
                        style={{
                          borderColor: !lessorGstinNumber && 'red',
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/*//! Lessor's GSTIN certificate*/}

                  <Col sm="3">
                    <strong>
                      <Label
                        className="form-label"
                        for="lessorGstinCertificate"
                      >
                        Lessor's GSTIN certificate
                      </Label>
                    </strong>
                    <div className="mt-2 d-flex align-items-center gap-2">
                      {lessorGstinCertificate !== null ? (
                        lessorGstinCertificate?.includes(".pdf") ? (
                          <Image
                            onClick={() =>
                              window.open(
                                lessorGstinCertificate,
                                "_blank"
                              )
                            }
                            size={45}
                            style={{
                              marginBottom: "20px",
                              cursor: "pointer"
                            }}
                          />
                        ) : (
                          <img
                            className="imageSection__image"
                            src={lessorGstinCertificate}
                            alt="Attachment"
                            style={{
                              width: "6rem",
                              height: "6rem",
                              objectFit: "cover",
                              cursor: "pointer",
                              marginLeft: "1.7rem",
                            }}
                            onClick={() =>
                              window.open(lessorGstinCertificate, "_blank")
                            }
                          />
                        )
                      ) : null}
                      {
                        isEditableByuser("gstFile2") &&
                        <label htmlFor={`gstFile${id}`}>
                          <p className="btn btn-sm">
                            <Upload size={30} />
                          </p>
                          <input
                            name={`gstFile${id}`}
                            id={`gstFile${id}`}
                            className="d-none"
                            type="file"
                            onChange={(e) =>
                              fileUploader(e, id, "lessorGstinCertificateImage")
                            }
                          />
                        </label>
                      }
                    </div>
                  </Col>

                  {/* //! Lessor's partnership %*/}

                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="lessorPartnershipPercent"
                        >
                          Lessor's partnership %
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={lessorPartnershipPercent}
                        id="lessorPartnershipPercent"
                        name="lessorPartnershipPercent"
                        //placeholder="Add lessor's partnership %"
                        // onChange={(e) =>
                        //   setLessorPartnershipPercent(e.target.value)
                        // }
                        onChange={(e) =>
                          handlePartnershipNumber(
                            e.target.value,
                            id,
                            "lessorPartnershipPercent"
                          )
                        }
                        disabled={!isEditableByuser("lessorPartnershipPercent")}
                        style={{
                          borderColor: !lessorPartnershipPercent && 'red',
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </React.Fragment>
          )
        )}

      {lessorEntityType?.value === "Partnership" && (
        <Col sm="12" className="mb-2">
          <AddButton
            addAction={handleAddLessorPartnership}
            buttonName={"lessor's info"}
          />
        </Col>
      )}

      {lessorEntityType?.value?.length > 0 && (
        <>
          <hr
            style={{
              borderTop: "2px solid #ffe700",
              width: "100%",
            }}
          />
          <Row>
            {" "}
            {/* //! Lessor's POC's full name*/}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label">
                    Lessor's POC's full name
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={pocFullName}
                  id="pocFullName"
                  name="pocFullName"
                  //placeholder="Add lessor's LLP authorised representative's DIN number"
                  // onChange={(e) => setPOCFullName(e.target.value)}
                  onChange={(e) => { isEditableByuser(e.target.name) && setPOCFullName(e.target.value) }}
                  disabled={!isEditableByuser("pocFullName")}
                  style={{
                    borderColor: !pocFullName && 'red',
                  }}
                />
              </FormGroup>
            </Col>
            {/* //! Lessor's POC's mobile number*/}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label">
                    Lessor's POC's mobile number
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={pocMobileNumber}
                  id="pocMobileNumber"
                  name="pocMobileNumber"
                  //placeholder="Add lessor's POC's mobile number"
                  // onChange={(e) => setPOCMobileNumber(e.target.value)}
                  onChange={(e) => { isEditableByuser(e.target.name) && setPOCMobileNumber(e.target.value) }}
                  disabled={!isEditableByuser("pocMobileNumber")}
                  style={{
                    borderColor: !pocMobileNumber && 'red',
                  }}

                />
              </FormGroup>
            </Col>
            {/* //! Lessor's POC's email*/}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label">Lessor's POC's email</Label>
                </strong>
                <Input
                  type="text"
                  value={pocEmail}
                  id="pocEmail"
                  name="pocEmail"
                  //placeholder="Add lessor's POC's email"
                  // onChange={(e) => setPOCEmail(e.target.value)}
                  onChange={(e) => { isEditableByuser(e.target.name) && setPOCEmail(e.target.value) }}
                  disabled={!isEditableByuser("pocMobileNumber")}
                  style={{
                    borderColor: !pocEmail && 'red',
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      {
        isEditableByuser("saveBtn") &&
        <SaveDiscardBtnGroup />
      }
    </form>
  );
};

export default DealOwnership;
