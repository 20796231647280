import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Timeline = ({ setRemainingCount }) => {
    setRemainingCount(null);
    const [timelines, setTimelines] = useState([]);
    const state = useSelector((state) => state?.cms);
    const dbData = state?.tableData;

    const timelineImages = {
        "Agreement execution date": "https://static.thehosteller.com/Hostel/Media/image-1743158005098.svg",
        "Lessor handover date": "https://static.thehosteller.com/Hostel/Media/image-1743158086826.svg",
        "Transformation handover date": "https://static.thehosteller.com/Hostel/Media/image-1743158154289.svg",
        "Staff joining date": "https://static.thehosteller.com/Hostel/Media/image-1743158289745.svg",
        "Photoshoot date": "https://static.thehosteller.com/Hostel/Media/image-1743158356673.svg",
        "Go live date": "https://static.thehosteller.com/Hostel/Media/image-1743158378765.svg",
    }

    useEffect(() => {
        setTimelines(dbData?.timelineDates || []);
    }, [dbData]);
    const formatDate = (date) => {
        return new Intl.DateTimeFormat("en-GB", {
            day: "numeric",
            month: "short",  // This gives 3-letter month abbreviations
            year: "2-digit",
        }).format(new Date(date));
    };



    const renderDates = (date, variable, invert) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    padding: "1px",
                    position: "relative",
                    justifyContent: "center",
                }}
            >
                <>
                    <div style={{ position: "absolute", top: "-15px", fontSize: "12px", whiteSpace: "nowrap", fontWeight: "800" }}> {formatDate(date)}</div>
                    <div style={{ fontSize: "45px", marginBottom: "3px", }}>
                        <img
                            src={timelineImages[variable]}
                            alt='avatar'
                            style={{
                                width: "40px",
                                borderRadius: "100px",
                                margin: "0px 0px",
                            }}
                        />
                    </div>
                    <div style={{ position: "absolute", bottom: "-40px", fontSize: "10px", fontWeight: "800" }}>{variable}</div>
                </>
            </div >
        );
    };

    const renderLine = (width) => {
        return (
            <div
                style={{
                    width: `${(width * 3) + 50}px`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        borderTop: "3px solid black",
                        width: `${(width * 3) + 50}px`,
                        borderRadius: "100px"
                    }}
                ></div>
            </div>
        );
    };



    return (
        <div style={{ overflowX: "scroll" }}>
            {timelines?.slice().reverse().map((item, index) => {
                return (
                    <div>
                        {
                            item?.agreementExecutionDate !== null && item?.lessorHandoverDate !== null &&
                            <div key={index} style={{ padding: "40px 20px", marginTop: "30px" }}>
                                {/* <div style={{ marginBottom: "50px", display: "flex", justifyContent: "center" }}>
                                    <strong>
                                        Status as on {" "}
                                        {formatDate(item?.createdAt)}
                                    </strong>
                                </div> */}
                                <div
                                    style={{
                                        display: "inline-flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "0px",
                                    }}
                                >
                                    {renderDates(item?.agreementExecutionDate, "Agreement execution date",)}
                                    {renderLine(((new Date(item?.lessorHandoverDate) - new Date(item?.agreementExecutionDate)) / 86400000))}

                                    {renderDates(item?.lessorHandoverDate, "Lessor handover date")}
                                    {renderLine(((new Date(item?.transformationHandoverDate) - new Date(item?.lessorHandoverDate)) / 86400000))}

                                    {renderDates(item?.transformationHandoverDate, "Transformation handover date",)}
                                    {renderLine(((new Date(item?.staffJoiningDate) - new Date(item?.transformationHandoverDate)) / 86400000))}

                                    {renderDates(item?.staffJoiningDate, "Staff joining date")}
                                    {renderLine(((new Date(item?.photoshootDate) - new Date(item?.staffJoiningDate)) / 86400000))}

                                    {renderDates(item?.photoshootDate, "Photoshoot date",)}
                                    {renderLine(((new Date(item?.goLiveDate) - new Date(item?.photoshootDate)) / 86400000))}

                                    {renderDates(item?.goLiveDate, "Go live date")}
                                </div>
                            </div>
                        }
                    </div>
                )
            })}

        </div>
    );
};

export default Timeline;
