import Finance from "../../views/Opeartions/Finance/index";
import GOEntry from "../../views/Opeartions/GOPaymentRequest";
import GOPurchase from "../../views/Opeartions/GOPurchaseRequest";
import Vendor from "../../views/Opeartions/Vendor/index";
import HostelwiseClosingBalanceTable from "../../views/Opeartions/HostelwiseClosingBalance";
import AddGoPurchaseTabs from "../../views/Opeartions/GOPurchaseRequest/Tabs";
import LaundryMaster from "../../views/Opeartions/LaundryMaster/index";
import LaundryTracker from "../../views/Opeartions/LaundryTracker/index";
import MeterMaster from "../../views/Opeartions/MeterMaster";
import MeterTracker from "../../views/Opeartions/MeterTracker";
import HouseKeepingTracker from '../../views/Opeartions/HouseKeepingTracker/index'

const OperationRoutes = [
  {
    path: "/operations/finance",
    component: Finance,
    exact: true,
  },
  {
    path: "/operations/closingBalanceReport",
    component: HostelwiseClosingBalanceTable,
    exact: true,
  },
  {
    path: "/operations/vendor",
    component: Vendor,
    exact: true,
  },
  {
    path: "/operations/goentry",
    component: GOEntry,
    exact: true,
  },
  {
    path: "/operations/goPurchase",
    component: GOPurchase,
    exact: true,
  },
  {
    path: "/operations/edit/goPurchase/:id",
    component: AddGoPurchaseTabs,
    exact: true,
    meta: {
      navLink: "/cms",
    },
  },
  {
    path: "/operations/laundryMaster",
    component: LaundryMaster,
    exact: true,
  },
  {
    path: "/operations/laundryTracker",
    component: LaundryTracker,
    exact: true
  },
  {
    path: "/operations/metermaster",
    component: MeterMaster,
    exact: true,
  },
  {
    path: "/operations/metertracker",
    component: MeterTracker,
    exact: true,
  },
  {
    path: "/operations/houseKeepingTracker",
    component: HouseKeepingTracker,
    exact: true,
  },
];

export default OperationRoutes;
