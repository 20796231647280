import React, { useEffect, useState } from 'react'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { selectThemeColors } from "@utils";
import Select from "react-select";
import { useHostelList } from '../../../api/dataSource';
import { useGetMeterMasterByHostelId } from '../../../api/MeterMaster/Queries';
import MeterTrackerTable from './MeterTrackerTable';
import Flatpickr from "react-flatpickr";
import { dateFormatter } from '../../../utility/Utils';
import { useGetMeterTrackerFromHostelId, useGetRoomSoldFromHostelId } from '../../../api/MeterTracker/Queries';
import toaster from '../../../@core/components/common/Toaster';

const getFirstDateOfCurrentMonth = () => {
    const today = new Date();
    today.setDate(1);
    return today.toISOString().split('T')[0];
};


const MeterTracker = () => {

    const [hostel, setHostel] = useState("")
    const [fromDate, setFromDate] = useState(getFirstDateOfCurrentMonth())
    const [toDate, setToDate] = useState(dateFormatter(new Date()))
    const [searchFromDate, setSearchFromDate] = useState(null)
    const [searchToDate, setSearchToDate] = useState(null)
    const [hostelList, setHostelList] = useState([])
    const [tableData, setTableData] = useState([])
    const [trackerTableData, setTrackerTableData] = useState([])
    const [daysInMonth, setDaysInMonth] = useState(null)
    const [tableHeaderData, setTableHeaderData] = useState([])
    const [overallUnits, setOverallUnits] = useState([])
    const [roomSoldArray, setRoomSoldArray] = useState([])
    const [avgConsumption, setAvgConsumption] = useState("")
    const [totalOverallUnit, setTotalOverallUnit] = useState(0)
    const [loading, setLoading] = useState(false)

    const { data: allHostelsData } = useHostelList()

    const [fetchHandler, { data, }] = useGetMeterMasterByHostelId()
    const [fetchTrackerHandler, { data: trackerData, refetch }] = useGetMeterTrackerFromHostelId()
    const [fetchRoomSoldHandler, { data: roomSold, loading: rooSoldLoading }] =
        useGetRoomSoldFromHostelId()

    console.log(trackerData?.getMeterTrackerFromHostelId?.totalOverallUnits)
    useEffect(() => {

        let HostelsList = allHostelsData?.getHostelList?.map((hostel) => ({
            label: hostel?.name,
            value: hostel?._id,
        }));

        if (HostelsList?.length) {

            const allowedHostels = JSON.parse(localStorage.getItem("hostels"));


            const filteredHostel =
                allowedHostels !== null && allowedHostels?.length !== 0
                    ? allowedHostels?.map((hostelID) => {
                        return HostelsList?.find((hostel) => hostel?.value === hostelID);
                    })
                    : HostelsList;


            setHostelList(filteredHostel);
        }
    }, [allHostelsData]);


    useEffect(() => {
        if (data?.getMeterMasterFromHostelId?.data.length > 0) {
            setTableData(data?.getMeterMasterFromHostelId?.data)
        } else {
            setTableData([])
        }
        if (
            data?.getMeterMasterFromHostelId?.avgMeterUnitConsumption
        ) {
            setAvgConsumption(data?.getMeterMasterFromHostelId?.avgMeterUnitConsumption)
        } else {
            setAvgConsumption("")
        }
        if (trackerData?.getMeterTrackerFromHostelId?.meterTrackerArray?.length > 0) {
            setTrackerTableData(trackerData?.getMeterTrackerFromHostelId?.meterTrackerArray)
        } else {
            setTrackerTableData([])
        }
        if (trackerData?.getMeterTrackerFromHostelId?.previousDayReadingArray?.length > 0) {
            setOverallUnits(trackerData?.getMeterTrackerFromHostelId?.previousDayReadingArray)
        } else {
            setOverallUnits([])
        }
        if (roomSold?.getRoomSoldFromHostelId?.length > 0) {
            setRoomSoldArray(roomSold?.getRoomSoldFromHostelId)
        } else {
            setRoomSoldArray([])
        }
        if (trackerData?.getMeterTrackerFromHostelId?.totalOverallUnits > 0) {
            setTotalOverallUnit(trackerData?.getMeterTrackerFromHostelId?.totalOverallUnits)
        } else {
            setTotalOverallUnit(0)
        }
    }, [data?.getMeterMasterFromHostelId, trackerData?.getMeterTrackerFromHostelId, roomSold?.getRoomSoldFromHostelId])


    useEffect(() => {
        if (rooSoldLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [rooSoldLoading])


    const handleDateRange = (value, name) => {
        try {
            if (name === "fromDate") {
                setFromDate(dateFormatter(new Date(value)));
            } else {
                setToDate(dateFormatter(new Date(value)));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearch = () => {
        if (hostel?.value && fromDate && toDate && new Date(fromDate) <= new Date(toDate)) {
            if (new Date(toDate) <= new Date()) {
                setSearchFromDate(fromDate)
                setSearchToDate(toDate)
                fetchHandler(hostel?.value)
                fetchTrackerHandler(hostel?.value, fromDate, toDate)
                fetchRoomSoldHandler(hostel?.value, fromDate, toDate)
            }
            else {
                toaster("error", "future date are not allowed")
            }
        } else {
            toaster("error", "Please select appropriate dates")
        }
    }

    const handleReset = () => {
        setSearchFromDate(null)
        setSearchToDate(null)
        setFromDate(null)
        setToDate(null)
        setTableData([])
        setTrackerTableData([])
        setHostel(null)
        setRoomSoldArray([])
        setTotalOverallUnit(0)
    }
    return (
        <div>
            <Row>
                <Col sm="3">
                    <FormGroup className="w-100">
                        <Label className="form-label" for="hostel">
                            <strong>Hostel</strong>
                        </Label>
                        <Select
                            isClearable={true}
                            isSearchable={true}
                            theme={selectThemeColors}
                            id="hostel"
                            name="hostel"
                            value={hostel}
                            closeMenuOnSelect={true}
                            options={hostelList}
                            onChange={(value) => {
                                setHostel(value);
                            }}
                            classNamePrefix="select"
                        />
                    </FormGroup>
                </Col>
                <Col sm="2">
                    <FormGroup className="w-100">
                        <Label className="form-label" for="fromDate">
                            <strong>Start Date</strong>
                        </Label>
                        <Flatpickr
                            className="form-control bg-white border-dark text-dark"
                            value={fromDate}
                            onChange={(date) => {
                                handleDateRange(date, "fromDate");
                                //setFromDate(dateFormatter(new Date(date)));
                            }}
                            id="fromDate"
                            placeholder="From Date"
                        />
                    </FormGroup>
                </Col>
                <Col sm="2">
                    <FormGroup className="w-100">
                        <Label className="form-label" for="toDate">
                            <strong>End Date</strong>
                        </Label>
                        <Flatpickr
                            className="form-control bg-white border-dark text-dark"
                            value={toDate}
                            onChange={(date) => {
                                //setToDate(dateFormatter(new Date(date)));
                                handleDateRange(date, "toDate");
                            }}
                            id="toDate"
                            placeholder="To Date"
                        />
                    </FormGroup>
                </Col>
                <Col className="" sm="3" style={{ display: "flex", gap: "20px" }}>
                    <Button
                        style={{
                            backgroundColor: "#ffe700",
                            height: "40px",
                            marginTop: "22px",
                            borderRadius: "5px",
                            padding: "10px 30px",
                            outline: "none",
                            border: "0",
                        }}
                        color="white"
                        appearance="primary"
                        type="button"
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#ff9999",
                            height: "40px",
                            marginTop: "22px",
                            //marginLeft: "40px",
                            borderRadius: "5px",
                            padding: "10px 30px",
                            outline: "none",
                            border: "0",
                        }}
                        color="danger"
                        appearance="primary"
                        type="button"
                        onClick={handleReset}
                        disabled={loading}
                    >
                        Reset
                    </Button>
                </Col>
            </Row>
            {tableData?.length > 0 ?

                <MeterTrackerTable MasterData={tableData} trackerData={trackerTableData} fromDate={searchFromDate} toDate={searchToDate} overallUnits={overallUnits} roomSoldArray={roomSoldArray} refetch={refetch} avgConsumption={avgConsumption}
                    roomSoldLoading={loading}
                    totalOverallUnit={totalOverallUnit}
                />
                :

                <h2 style={{ textAlign: "center", marginTop: "100px" }}>No Record found</h2>
            }
        </div>
    )
}

export default MeterTracker