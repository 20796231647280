import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Plus, X, Camera } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateDestination, useUploadImage } from "@api";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import Avatar from "@components/common/avatar";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const ThingsToDo = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [updateDestinationHandler, { data: updatedDestinationData }] =
    useUpdateDestination();
  const [thingsTodo, setThingsTodo] = useState([
    {
      id: uuidv4(),
      categoryTitle: "",
      displayOrder: "",
    },
  ]);
  const [index, setIndex] = useState("");

  const [uploadCategoryImageHandler, { data: categoryImageData }] =
    useUploadImage();

  useEffect(() => {
    if (state?.tableData?.thingsTodo?.length) {
      let updatedThingsToDo = state?.tableData?.thingsTodo?.map((item) => {
        return {
          id: uuidv4(),
          categoryTitle: item?.categoryTitle,
          displayOrder: item?.displayOrder,
          categoryImage: item?.categoryImage,
        };
      });
      setThingsTodo(updatedThingsToDo);
    }
  }, [state]);

  useEffect(() => {
    if (updatedDestinationData?.updateDestination?.destination) {
      toaster("success", "Things to do details updated successfully");
    }
  }, [updatedDestinationData]);

  useEffect(() => {
    if (categoryImageData?.uploadImage?.url) {
      setThingsTodo(
        thingsTodo.map((item) => {
          if (item.id === index) {
            return {
              ...item,
              categoryImage: categoryImageData?.uploadImage?.url,
            };
          } else {
            return item;
          }
        })
      );
    }
  }, [categoryImageData?.uploadImage?.url]);

  const handleRemoveThingsToDo = (id) =>
    confirmDelete(() =>
      setThingsTodo(thingsTodo.filter((item) => item.id !== id))
    );

  const handleAddThingsToDo = () =>
    setThingsTodo([
      ...thingsTodo,
      { id: uuidv4(), categoryTitle: "", displayOrder: "" },
    ]);

  const handleChangeCategoryTitle = (title, id) => {
    setThingsTodo(
      thingsTodo.map((item) =>
        item.id === id ? { ...item, categoryTitle: title } : item
      )
    );
  };

  const handleChangeDisplayOrder = (displayOrder, id) => {
    if (!Number.isInteger(displayOrder)) {
      setThingsTodo(
        thingsTodo.map((item) =>
          item.id === id
            ? { ...item, displayOrder: parseInt(displayOrder) }
            : item
        )
      );
    }
  };

  // const ImageRenderPreview = () => {
  //   if (image.length) {
  //     return image.map((src, index) => (
  //       <img
  //         key={index}
  //         className="img-thumbnail rounded mt-2 mr-1"
  //         src={src}
  //         alt="avatar"
  //       />
  //     ));
  //   } else {
  //     return null;
  //   }
  // };

  const submitDatahandler = () => {
    let isValid = false;

    let data = {
      thingsTodo: thingsTodo,
      sectionName: "Things to do",
    };

    thingsTodo?.forEach((item) => {
      if (item?.categoryTitle && item?.displayOrder) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (isValid) {
      thingsTodo?.forEach((item) => delete item.id);

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          thingsTodo: data?.thingsTodo,
        })
      );
      updateDestinationHandler(id, data);
    } else {
      toaster("error", "Please fill the details");
    }
  };

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);

    // const fileName = e.target.files[0]?.name;

    // const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    // if (specialCharsRegex.test(fileName)) {
    //   console.error("File name:", fileName);
    //   toaster(
    //     "error",
    //     "File name contains special characters. Please provide a valid file name."
    //   );

    //   return;
    // }

    uploadCategoryImageHandler(
      e.target.files[0],
      `${state.tableData?.destination?.toLowerCase()?.replaceAll(" ", "-")}` +
        "/DESTINATION"
    );

    toaster("success", "Things to do image uploaded successfully");
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDatahandler();
      }}
    >
      <Row className="mt-2">
        {thingsTodo.map(
          ({ categoryTitle, displayOrder, id, categoryImage }, index) => (
            <Col className="d-flex align-items-center" sm="12" key={id}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"thingsTodo" + id}>
                  Things to do {index + 1}
                </Label>

                <div className="mt-2 d-flex align-items-center">
                  <div className="mb-1 mr-2">
                    {
                      <Avatar
                        img={categoryImage}
                        alt={categoryImage}
                        imgHeight={100}
                        imgWidth={100}
                      />
                    }
                  </div>

                  <label htmlFor={`myFile${id}`}>
                    <p className="btn btn-sm btn-light">
                      <Camera size={15} />
                    </p>
                    <input
                      name={`myFile${id}`}
                      id={`myFile${id}`}
                      className="d-none"
                      type="file"
                      onChange={(e) => fileUploader(e, id)}
                    />
                  </label>
                </div>

                {/* <Col sm="6">
                <Label className="form-label" htmlFor="location">
                  Image
                </Label>
                <DragDrop
                  id={id}
                  // className="d-none"
                  // type="file"
                  note="Image size should be less than 500KB"
                  uppy={uppyImage}
                  // onChange={(e) => fileUploader(e, id)}
                />
                {ImageRenderPreview()}
              </Col> */}

                <Input
                  type="text"
                  value={categoryTitle}
                  id={"categoryTitle" + id}
                  name={"categoryTitle"}
                  placeholder="Title"
                  onChange={(e) =>
                    handleChangeCategoryTitle(e.target.value, id)
                  }
                />
                <br />

                <Input
                  type="number"
                  value={displayOrder}
                  id={"displayOrder" + id}
                  name={"displayOrder"}
                  placeholder="Display Order"
                  min="1"
                  onChange={(e) => handleChangeDisplayOrder(e.target.value, id)}
                />
              </FormGroup>
              {thingsTodo.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveThingsToDo(id)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          )
        )}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddThingsToDo} />
    </form>
  );
};

export default ThingsToDo;
