import React from "react";
import { Button } from "reactstrap";
import { Plus, Check } from "react-feather";

const AddButton = ({ loading, addAction, disabled, userRole, buttonName }) => {
  return (
    <div className="d-flex flex-row-reverse ">
      {/* <Button.Ripple
        color={disabled ? "secondary" : "light"}
        disabled={disabled}
        className='mr-2'
        type='submit'
      >
        <Check size={15} className='mr-1' />
        Save Changes
      </Button.Ripple> */}

      {addAction && (
        <Button.Ripple
          type="button"
          className="btn-icon rounded mr-1"
          color="light"
          size="md"
          onClick={addAction}
          // disabled={userRole}
        >
          <Plus size={14} className="ml-1" /> Add {buttonName}
        </Button.Ripple>
      )}
    </div>
  );
};

export default AddButton;
