import React, { useEffect, useState } from 'react'
import BusBookingsTable from './BusBookingsTable'
import { useGetAllConfirmBusBookings } from '../../api/BusBooking/Queries'
import AddEditModal from './AddEditModal';
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import toaster from "@components/common/Toaster";
import TablePagination from '../../@core/components/common/TablePagination';
import { dateFormatter } from '../../utility/Utils';
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { useGetBusbookingExport } from '../../api/BusBooking/Mutation';


const Index = () => {
    const [tableData, setTableData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState(null);
    const [editData, setEditData] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1)
    const [redBusFilter, setredBusFilter] = useState({ status: "success", });
    const [totalPage, setTotalPage] = useState(1);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null)
    const [status, setStatus] = useState({ label: "Success", value: "success" });
    const [bookingPNR, setBookingPNR] = useState(null)
    const [passengerName, setPassengerName] = useState(null)
    const statusValueList = [
        { label: "Init", value: "init" },
        { label: "Success", value: "success" },
        { label: "Cancelled", value: "cancelled" },
    ];
    const { data: busBookings, refetch: busBookingRefetch } =
        useGetAllConfirmBusBookings({ limit, page, redBusFilter });

    const [getBusbookingXlsxExportHandler, { data }] = useGetBusbookingExport()
    useEffect(() => {
        if (busBookings?.getAllPaginatedBusBookings?.data) {
            setTableData(busBookings?.getAllPaginatedBusBookings?.data)
            setTotalPage(busBookings?.getAllPaginatedBusBookings?.count / limit)
        }
    }, [busBookings])

    const toggleModal = () => {
        setOpenModal(!openModal);
        setId(null);
        setEditData(null);
    };

    // const handleSearch = () => {
    // if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
    //     toaster("error", "Start date should be less than end date");
    // } else {
    // const filterFeild = {
    // fromDate: fromDate,
    // toDate: toDate,
    // status: status?.value,
    // orderId: orderId,
    // userName: userName,
    // };
    // setPage(1);
    // console.log(filterFeild)
    // setredBusFilter(filterFeild);
    // }
    // }

    useEffect(() => {
        if (busBookings?.getAllPaginatedBusBookings?.data) {
            let editData = busBookings?.getAllPaginatedBusBookings?.data?.find((item) => item._id === id)
            setEditData(editData);
        } else {
            setId(null);
            setEditData(null);
        }
    }, [id])

    const handleSearch = () => {
        if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
            toaster("error", "Start date should be less than end date");
        } else {

            const filterFeild = {
                fromDate: fromDate,
                toDate: toDate,
                status: status?.value,
                orderId: bookingPNR,
                userName: passengerName,
            };
            setPage(1);
            console.log(filterFeild)
            setredBusFilter(filterFeild);
        }
    }

    const handleReset = () => {
        setFromDate(null)
        setToDate(null)
        setredBusFilter({})
        setStatus(null)
        setBookingPNR("")
        setId(null)
        setPassengerName("")
    }

    const handleExport = async () => {
        const filterFeild = {
            fromDate: fromDate,
            toDate: toDate,
            status: status?.value,
            orderId: bookingPNR,
            userName: passengerName,
        };
        const data = await getBusbookingXlsxExportHandler(filterFeild)
    }

    useEffect(() => {
        if (data?.getBusBookingExport?.data) {
            if (data?.getBusBookingExport?.data.length > 0) {
                const byteArray = new Uint8Array(
                    data?.getBusBookingExport?.data
                );
                const blob = new Blob([byteArray], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `busBookingReport.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
    }, [data]);
    return (
        localStorage.getItem("modules")?.includes("Bus Booking") && (
            <div>
                <Row>
                    <Col sm="4">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="url">
                                <strong>Date range</strong>
                            </Label>
                            <Flatpickr
                                className="form-control bg-white border-dark text-dark"
                                value={fromDate}
                                options={{
                                    mode: "range",
                                }}
                                onChange={(date) => {
                                    setFromDate(dateFormatter(date[0]));
                                    setToDate(dateFormatter(date[1]));
                                }}
                                id="checkinDate"
                                isClearable={true}
                                placeholder="Date range"
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="status">
                                <strong>Status</strong>
                            </Label>
                            <Select
                                isClearable={true}
                                // isSearchable={true}
                                id="status"
                                name="status"
                                value={status}
                                // theme={selectThemeColors}
                                closeMenuOnSelect={true}
                                options={statusValueList}
                                onChange={(value) => {
                                    setStatus(value);
                                    console.log(value)
                                }}
                                classNamePrefix="select"
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="orderId">
                                <strong>Booking PNR</strong>
                            </Label>
                            <Input
                                id="bookingPNR"
                                name="bookingPNR"
                                value={bookingPNR}
                                placeholder="Booking PNR"
                                onChange={(e) => {
                                    setBookingPNR(e.target.value);
                                }}
                            />
                        </FormGroup>
                    </Col>
                    {/* <Col sm="4">
                        <FormGroup className="w-100">
                            <Label className="form-label" for="userName">
                                <strong>Name</strong>
                            </Label>
                            <Input
                                id="passengerName"
                                name="passengerName"
                                placeholder="Name"
                                value={passengerName}
                                onChange={(e) => setPassengerName(e.target.value)}
                            />
                        </FormGroup>
                    </Col> */}
                    <Col>
                        <Button
                            style={{
                                backgroundColor: "#ffe700",
                                height: "40px",
                                marginTop: "22px",
                                marginBottom: "22px",
                                borderRadius: "5px",
                                padding: "10px 30px",
                                outline: "none",
                                border: "0",
                                marginRight: "20px"
                            }}
                            color="white"
                            appearance="primary"
                            type="button"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                        <Button
                            style={{
                                backgroundColor: "#ff9999",
                                height: "40px",
                                marginTop: "22px",
                                //marginLeft: "40px",
                                marginBottom: "22px",
                                borderRadius: "5px",
                                padding: "10px 30px",
                                outline: "none",
                                border: "0",
                            }}
                            color="danger"
                            appearance="primary"
                            type="button"
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                        <Button
                            style={{
                                height: "40px",
                                marginTop: "0px",
                                marginLeft: "40px",
                                borderRadius: "5px",
                                padding: "10px 30px",
                                outline: "none",
                                border: "0",
                            }}
                            color="success"
                            appearance="success"
                            onClick={() => { handleExport() }}
                        >
                            Export
                        </Button>
                    </Col>
                </Row>

                {tableData.length > 0 ? (
                    <div>
                        <BusBookingsTable
                            tableData={tableData}
                            openModal={() => setOpenModal(!openModal)}
                            setId={setId}
                        />
                        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
                        <AddEditModal
                            open={openModal}
                            handleToggle={toggleModal}
                            refetch={busBookingRefetch}
                            editData={editData}
                        />
                    </div>
                ) : (
                    <h2 style={{ textAlign: "center", marginTop: "150px" }}>
                        No Record Found
                    </h2>
                )}

            </div>

        )
    )
}

export default Index