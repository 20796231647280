import React, { useState } from "react";
import {
  FileText,
  PlusCircle,
  MinusCircle,
  Maximize,
  Minimize,
  HelpCircle,
} from "react-feather";
import {
  Card,
  CardHeader,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useGetAggregatedPLStatement } from "./../../api/P&LStatement/Queries";

const sections = [
  {
    name: "Hostel Details",
    fields: ["Hostel Name", "Area Manager", "Op. Model"],
  },
  {
    name: "Revenue",
    fields: ["Occ%", "ADR", "Bed Revenue", "F&B Revenue", "Ancillary Revenue"],
  },
  {
    name: "Total Revenue",
    fields: ["Total Revenue"],
  },
  {
    name: "Expenses",
    fields: ["Total OPEX"],
  },
  {
    name: "Margin Analysis",
    fields: [
      "Overall Op. Margin (in INR)",
      "Analysis (Operational Margin %)",
      "Hostel margin (in INR)",
      "Hostel margin (in %)",
      "F&B margin (in INR)",
      "F&B margin (in %)",
      "Ancillary margin (in INR)",
      "Ancillary margin (in %)",
    ],
  },
];

const percentageFields = [
  "Occ%",
  "Hostel margin (in %)",
  "F&B margin (in %)",
  "Ancillary margin (in %)",
  "Analysis (Operational Margin %)",
];

const formatPercentage = (value) => {
  const numValue = parseFloat(value);
  if (!isNaN(numValue)) {
    if (numValue >= 1 || numValue <= -1) {
      return `${numValue.toFixed(2)}%`;
    } else {
      return `${(numValue * 100).toFixed(2)}%`;
    }
  }
  return "N/A";
};

const formatNumber = (value) => {
  const numValue = parseFloat(value);
  if (isNaN(numValue)) return "N/A";

  if (numValue > 100) {
    return Math.round(numValue).toLocaleString("en-IN");
  } else {
    return numValue.toFixed(2);
  }
};

const formatAggregatedValue = (value, fieldName) => {
  const numValue = parseFloat(value);
  if (isNaN(numValue)) return "N/A";

  // percentages are formatted correctly
  if (percentageFields.includes(fieldName)) {
    return formatPercentage(numValue);
  }

  // large numbers are formatted correctly
  return numValue > 100
    ? Math.round(numValue).toLocaleString("en-IN")
    : numValue.toFixed(2);
};

const extractFieldValue = (fields, fieldName) => {
  if (!fields || !Array.isArray(fields)) return "N/A";
  const field = fields.find((item) => item.fieldName === fieldName);
  if (!field) return "N/A";

  let value =
    field.fieldValue !== undefined && field.fieldValue !== null
      ? field.fieldValue
      : "N/A";

  if (value !== "N/A") {
    if (percentageFields.includes(fieldName)) {
      return formatPercentage(value);
    } else {
      return formatNumber(value);
    }
  }

  return value;
};

const PLStatementTable = ({
  data,
  openOverviewModal,
  filterFields,
  userDepartment,
  userRole,
}) => {
  const { data: aggregatedData } = useGetAggregatedPLStatement(filterFields);
  const [expandedSections, setExpandedSections] = useState({});
  const [expandAll, setExpandAll] = useState(false);
  const [sopModalOpen, setSopModalOpen] = useState(false);

  // Toggle SOP modal
  const toggleSopModal = () => {
    setSopModalOpen(!sopModalOpen);
  };

  // Extract all unique headers dynamically from the data
  const getAllHeaders = () => {
    const headers = new Set();
    data.forEach((item) => {
      if (item.fields && Array.isArray(item.fields)) {
        item.fields.forEach((field) => {
          headers.add(field.fieldName);
        });
      }
    });
    return Array.from(headers);
  };

  const headers = getAllHeaders();

  const toggleSection = (sectionName, fieldsCount) => {
    if (fieldsCount > 1) {
      setExpandedSections((prev) => ({
        ...prev,
        [sectionName]: !prev[sectionName],
      }));
    }
  };

  const toggleExpandAll = () => {
    setExpandAll((prev) => !prev);
  };

  const renderHeader = (section) => (
    <th
      colSpan={
        expandedSections[section.name] || section.fields.length === 1
          ? section.fields.length
          : 2
      }
      style={{ textAlign: "center", fontWeight: "bold" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 5px",
        }}
      >
        <span>{section.name}</span>
        {section.fields.length > 1 && (
          <Button
            color="link"
            size="sm"
            onClick={() => toggleSection(section.name, section.fields.length)}
            style={{ padding: 0, fontSize: "18px", cursor: "pointer" }}
          >
            {expandedSections[section.name] ? (
              <MinusCircle size={20} color="green" />
            ) : (
              <PlusCircle size={20} color="blue" />
            )}
          </Button>
        )}
      </div>
    </th>
  );

  const excludedFields = [
    "Hostel Name",
    "Area Manager",
    "Op. Model",
    "Month",
    "Year",
  ];

  const renderSubHeaders = (section) =>
    expandedSections?.[section.name] || section.fields.length === 1 ? (
      section.fields.map((subHeader, index) => (
        <th key={index}>
          {subHeader}
          {aggregatedData?.getAggregatedPLStatement &&
            !excludedFields.includes(subHeader) && (
              <div style={{ fontWeight: "bold", marginTop: "5px" }}>
                {formatAggregatedValue(
                  aggregatedData?.getAggregatedPLStatement?.calculatedValues?.[
                    subHeader
                  ] ??
                    aggregatedData?.getAggregatedPLStatement
                      ?.totalAggregatedValues?.[subHeader] ??
                    "N/A",
                  subHeader
                )}
              </div>
            )}
        </th>
      ))
    ) : (
      <>
        <th>
          {section.fields[0]}
          {aggregatedData?.getAggregatedPLStatement &&
            !excludedFields.includes(section.fields[0]) && (
              <div style={{ fontWeight: "bold", marginTop: "5px" }}>
                {formatAggregatedValue(
                  aggregatedData?.getAggregatedPLStatement?.calculatedValues?.[
                    section.fields[0]
                  ] ??
                    aggregatedData?.getAggregatedPLStatement
                      ?.totalAggregatedValues?.[section.fields[0]] ??
                    "N/A",
                  section.fields[0]
                )}
              </div>
            )}
        </th>
        {section.fields.length > 1 && (
          <th>
            {section.fields[1]}
            {aggregatedData?.getAggregatedPLStatement &&
              !excludedFields.includes(section.fields[1]) && (
                <div style={{ fontWeight: "bold", marginTop: "5px" }}>
                  {formatAggregatedValue(
                    aggregatedData?.getAggregatedPLStatement
                      ?.calculatedValues?.[section.fields[1]] ??
                      aggregatedData?.getAggregatedPLStatement
                        ?.totalAggregatedValues?.[section.fields[1]] ??
                      "N/A",
                    section.fields[1]
                  )}
                </div>
              )}
          </th>
        )}
      </>
    );

  const renderRowData = (item, section) =>
    expandedSections[section.name] || section.fields.length === 1 ? (
      section.fields.map((fieldName, index) => (
        <td key={index}>
          {fieldName === "Hostel Name"
            ? item.hostelName
            : fieldName === "Area Manager"
            ? item.areaManager
            : fieldName === "Op. Model"
            ? item.operationalModel
            : extractFieldValue(item?.fields ?? [], fieldName)}
        </td>
      ))
    ) : (
      <>
        <td>
          {section.fields[0] === "Hostel Name"
            ? item.hostelName
            : section.fields[0] === "Area Manager"
            ? item.areaManager
            : section.fields[0] === "Op. Model"
            ? item.operationalModel
            : extractFieldValue(item.fields, section.fields[0])}
        </td>
        {section.fields.length > 1 && (
          <td>
            {section.fields[1] === "Hostel Name"
              ? item.hostelName
              : section.fields[1] === "Area Manager"
              ? item.areaManager
              : section.fields[1] === "Op. Model"
              ? item.operationalModel
              : extractFieldValue(item.fields, section.fields[1])}
          </td>
        )}
      </>
    );

  const renderAllData = () => {
    const longHeader =
      "Misc. (local travel General liasing etc.)(Courier charges + Freight Charges + General Exp +Print & Stationery +Staff Travel Exp)";
    const shortHeader = "Misc. (local travel General liasing etc.)";

    const excludedAggregates = ["Hostel Name", "Hostel No.", "Area Manager"];

    return (
      <Table bordered responsive>
        <thead>
          <tr>
            <th style={{ textAlign: "center", fontWeight: "bold" }}>Action</th>
            <th style={{ textAlign: "center", fontWeight: "bold" }}>
              Hostel Name
            </th>
            <th style={{ textAlign: "center", fontWeight: "bold" }}>
              Area Manager
            </th>
            <th style={{ textAlign: "center", fontWeight: "bold" }}>
              Month/Year
            </th>
            {headers.map((header, index) => (
              <th
                key={index}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                {header === longHeader ? shortHeader : header}
              </th>
            ))}
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            {headers.map((header, index) => (
              <th key={index}>
                {aggregatedData?.getAggregatedPLStatement &&
                  !excludedAggregates.includes(header) && (
                    <div style={{ fontWeight: "bold", marginTop: "5px" }}>
                      {formatAggregatedValue(
                        aggregatedData?.getAggregatedPLStatement
                          ?.calculatedValues?.[header] ??
                          aggregatedData?.getAggregatedPLStatement
                            ?.totalAggregatedValues?.[header] ??
                          "N/A",
                        header
                      )}
                    </div>
                  )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item._id}>
              <td>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => openOverviewModal(item._id)}
                >
                  <FileText size={14} />
                </Button>
              </td>
              <td>{item.hostelName}</td>
              <td>{item.areaManager}</td>
              <td>{`${item.month}/${item.year}`}</td>
              {headers.map((header, index) => (
                <td key={index}>{extractFieldValue(item.fields, header)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <div>
      <Card>
        <CardHeader>
          {userDepartment === "Finance" && (
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openOverviewModal();
              }}
            >
              Create P&L Statement
            </Button.Ripple>
          )}
          <Button.Ripple
            color="light"
            onClick={toggleExpandAll}
            style={{ marginLeft: "10px" }}
          >
            {expandAll ? <Minimize size={20} /> : <Maximize size={20} />}
            {expandAll ? " Collapse All" : " Expand All"}
          </Button.Ripple>

          <Button.Ripple
            color="info"
            onClick={toggleSopModal}
            style={{
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#007bff",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "5px",
              border: "none",
            }}
          >
            <HelpCircle
              size={20}
              style={{ marginRight: "5px", color: "#fff" }}
            />{" "}
            <span style={{ fontWeight: "bold" }}>View SOP</span>{" "}
          </Button.Ripple>
        </CardHeader>
      </Card>

      <Modal isOpen={sopModalOpen} toggle={toggleSopModal} size="lg">
        <ModalHeader toggle={toggleSopModal}>
          Standard Operating Procedure (SOP)
        </ModalHeader>
        <ModalBody>
          <iframe
            src="https://docs.google.com/document/d/19-pKKJs6dnum_d3sUz_D9hw0c-KypoNmLROssxkH-EA/preview"
            width="100%"
            height="500px"
            style={{ border: "none" }}
            title="SOP Document"
          ></iframe>
        </ModalBody>
      </Modal>

      {expandAll ? (
        renderAllData()
      ) : (
        <Table bordered responsive>
          <thead>
            <tr>
              <th style={{ textAlign: "center", fontWeight: "bold" }}>
                Action
              </th>
              <th style={{ textAlign: "center", fontWeight: "bold" }}>
                Month/Year
              </th>
              {sections.map((section) => renderHeader(section))}
            </tr>
            <tr>
              <th></th>
              <th></th>
              {sections.map((section) => renderSubHeaders(section))}
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item._id}>
                <td>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => openOverviewModal(item._id)}
                  >
                    <FileText size={14} />
                  </Button>
                </td>
                <td>{`${item.month}/${item.year}`}</td>
                {sections.map((section) => renderRowData(item, section))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default PLStatementTable;
