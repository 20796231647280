import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetHostelsNameandId } from "../../../api/dataSource";
import Select from "react-select";
import { selectThemeColors, dateFormatter } from "@utils";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import HouseKeepingTrackerTable from "./HouseKeepingTrackerTable";
import Flatpickr from "react-flatpickr";
import { useGetHouseKeepingRooms } from "../../../api/HouseKeepingTracker/Queries";

const HouseKeepingTracker = () => {
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [selectedHostel, setSelectedHostel] = useState(null);
  const [id, setId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [startDateOfExit, setStartDateOfExit] = useState(dateFormatter(new Date()));
  const [selectedDate, setSelectedDate] = useState(dateFormatter(new Date()));
  const { data: allHostelsData } = useGetHostelsNameandId();
  const { data: allRoomsData, refetch: refetchAllRoomsData } = useGetHouseKeepingRooms(id);
  

  useEffect(() => {
    if (id) {
      refetchAllRoomsData();
    } else {
      setEditData(null);
    }
  }, [id, refetchAllRoomsData]);

  useEffect(() => {
    if (allRoomsData?.getHouseKeepingRooms) {
      setEditData(allRoomsData.getHouseKeepingRooms);
    }
  }, [allRoomsData]);

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelsNameandId?.map((hostel) => ({
      label: hostel?.name,
      value: hostel?._id,
    }));
    if (HostelsList?.length) {
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
      const filteredHostel =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return HostelsList?.find((hostel) => hostel?.value === hostelID);
            })
          : HostelsList;
      setHostelsList(filteredHostel);
    }
  }, [allHostelsData]);

  const submitHandler = (hostel, startDateOfExit) => {
    if (hostel && startDateOfExit) {
      if (hostel.value !== id) {
        setEditData(null); 
      }
      setId(hostel.value);
      setStartDateOfExit(selectedDate)
    }
  };

  const handleReset = () => {
    setHostel(null);
    setStartDateOfExit(dateFormatter(new Date()));
    setId(null);
    setSelectedHostel(null); 
    setEditData(null);
  };

  return (
    <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100 position-relative" style={{ zIndex: 9999 }}>
            <strong>
              <Label className="form-label" for="product">
                Hostel
              </Label>
            </strong>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              id="hostel"
              name="hostel"
              value={hostel}
              options={hostelsList}
              onChange={(value) => setHostel(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="fromDate">
              <strong>Date</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={selectedDate}
              onChange={(date) => {
                const today = new Date();
                if (new Date(date) > today) {
                  setSelectedDate(dateFormatter(today));
                } else {
                  setSelectedDate(dateFormatter(new Date(date)));
                }
              }}
              id="fromDate"
              placeholder="Date"
              options={{
                maxDate: 'today'
              }}
            />
          </FormGroup>
        </Col>

        <Col sm="3" style={{ display: "flex", gap: "20px" }}>
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="submit"
            onClick={() => submitHandler(hostel, startDateOfExit)}
          >
            Search
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 30px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Col>
      </Row>

      <HouseKeepingTrackerTable 
        id={id} 
        date={startDateOfExit} 
        roomsData={editData} 
      />
    </div>
  );
};

export default HouseKeepingTracker;
