import React, { useState, useMemo } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";

const InvestorDataTable = ({ investorData }) => {
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [expandedRows, setExpandedRows] = useState(() => {
    const initialExpandedState = {};
    const expandableMetrics = [
      "noOfHostels",
      // "noOfBeds",
      // "totalAvailableBedNights",
      // "totalOccupancy",
      // "totalSoldBedNights",
      // "adrOverall",
      // "totalPxOverall",
      // "totalGmv",
      // "totalGrossRevenue",
      // "totalOperationalExpenses",
      // "ebitda",
      // "ebitdaPercentage",
      // "pbt",
      // "pbtPercentage",
      // "totalMarginsPercentage",
    ];
    expandableMetrics.forEach((metric) => {
      initialExpandedState[metric] = true;
    });
    return initialExpandedState;
  });

  const fieldLabels = {
    month: "Month",
    year: "Year",
    // General Metrics
    noOfHostels: "No of Hostels",
    noOfCocoHotels: "# OF COCO Hotels",
    noOfFocoOmcHostels: "# OF FOCO/OMC Hostels",
    noOfFofoHostels: "# OF FOFO Hostels",
    noOfBeds: "No of Beds",
    noOfCocoHotelBeds: "# OF COCO Hostel Beds",
    noOfFocoOmcHostelBeds: "# FOCO/OMC Hostel Beds",
    noOfFofoHostelBeds: "# OF FOFO Hostel Beds",

    // Bed Nights & Occupancy
    totalAvailableBedNights: "T A Bed Nights (Total)",
    cocoBedNights: "T A Bed Nights (COCO)",
    focoOmcBedNights: "T A Bed Nights (Others (FOCO/OMC))",
    fofoBedNights: "T A Bed Nights (Others (FOFO))",
    totalOccupancy: "Total Occupancy (Overall)",
    cocoOccupancy: "Occupancy (Company Owned)",
    focoOmcOccupancy: "Occupancy (Others (FOCO/OMC))",
    fofoOccupancy: "Occupancy (Others (FOFO))",

    // Bed Sales
    totalSoldBedNights: "T S Bed Nights (Total)",
    cocoSoldBedNights: "T S Bed Nights (Company Owned)",
    focoOmcSoldBedNights: "T S Bed Nights (Others (FOCO/OMC))",
    fofoSoldBedNights: "T S Bed Nights (Others (FOFO))",

    // ADR (Average Daily Rate)
    adrOverall: "Bed ADR (Overall)",
    adrCoco: "Bed ADR (COCO)",
    adrFocoOmc: "Bed ADR (Others (FOCO/OMC))",
    adrFofo: "Bed ADR (Others (FOFO))",

    // Guests
    totalPxOverall: "Total guests (overall)",
    noOfPxCoco: "No. of guests (COCO)",
    noOfPxFocoOmc: "No. of guests (Others (FOCO/OMC))",
    noOfPxFofo: "No. of guests (Others (FOFO))",

    // Revenue (COCO)
    cocoGrossRevenue: "Gross COCO Revenue",

    cocoBedRevenue: "Bed Revenue (COCO)",
    cocoCafeRevenue: "Cafe Revenue (COCO)",
    cocoAncillaryRevenue: "Ancillary Revenue (COCO)",

    // Expenses (COCO)
    cocoGrossExpense: "Gross COCO Expense",

    cocoRentals: "Rentals (COCO)",
    cocoHostelSalary: "Hostel Salary (COCO)",
    cocoUtilities: "Utilities/R&M (COCO)",
    cocoCommissions: "Commissions (COCO)",
    cocoMiscellaneous: "Miscellaneous (COCO)",
    cocoCafe: "Cafe (COCO)",
    cocoAncillary: "Ancillary & Local (COCO)",

    // Margins (COCO)
    cocoOperationalMargin: "Operational Margin (COCO)",
    cocoOperationalMarginPercentage: "Operational Margin % (COCO)",

    // Revenue (FOCO & OMC)
    focoOmcGrossRevenue: "Gross FOCO & OMC Revenue",

    focoOmcBedRevenue: "Bed Revenue (FOCO & OMC)",
    focoOmcCafeRevenue: "Cafe Revenue (FOCO & OMC)",
    focoOmcAncillaryRevenue: "Ancillary & Local Revenue (FOCO & OMC)",

    // Expenses (FOCO & OMC)
    focoOmcGrossExpense: "Gross FOCO & OMC Expense",

    focoOmcRentals: "Rentals (FOCO & OMC)",
    focoOmcHostelSalary: "Hostel Salary (FOCO & OMC)",
    focoOmcUtilities: "Utilities/R&M (FOCO & OMC)",
    focoOmcCommissions: "Commissions (FOCO & OMC)",
    focoOmcMiscellaneous: "Miscellaneous (FOCO & OMC)",
    focoOmcCafe: "Cafe (FOCO & OMC)",
    focoOmcAncillary: "Ancillary & Local (FOCO & OMC)",
    focoOmcPartnerPayout: "Partner Payout (FOCO & OMC)",

    // Margins (FOCO & OMC)
    focoOmcOperationalMargin: "Operational Margin (FOCO & OMC)",
    focoOmcOperationalMarginPercentage: "Operational Margin % (FOCO & OMC)",

    // Revenue (FOFO)
    fofoGrossRevenue: "Gross FOFO Revenue",

    fofoBedRevenue: "Bed Revenue (FOFO)",
    fofoCafeRevenue: "Cafe Revenue (FOFO)",
    fofoAncillaryRevenue: "Ancillary & Local Revenue (FOFO)",

    // Expenses (FOFO)
    fofoGrossExpense: "Gross FOFO Expense",

    fofoRoyalty: "ROYALTY (FOFO)",
    fofoPlatformFees: "PLATFORM FEES (FOFO)",

    // Margins (FOFO)
    fofoOperationalMargin: "Operational Margin (FOFO)",
    fofoOperationalMarginPercentage: "Operational Margin % (FOFO)",

    // HO Revenue
    hoGrossRevenue: "Gross HO Revenue",

    hoMembership: "Memberships",
    hoSaleOfFurnitureLinens: "Sale of furniture/linens",
    hoOtherIncome: "Any other income (inc. platform fees)",

    // HO Expenses
    hoGrossExpense: "Gross HO Expense",

    hoEmployeeBenefit: "Employee Benefit",
    hoMarketing: "Marketing",
    hoTravelling: "Travelling",
    hoTechSubscriptions: "Tech & Subscriptions",
    hoOfficeRentals: "Office Rentals",
    hoUtilities: "Utilities/R&M",
    hoLegal: "Legal/Compliances",
    hoMiscellaneous: "Miscellaneous",

    // Financial Metrics
    totalGmv: "Total GMV",
    totalGrossRevenue: "Total Gross Revenue",
    totalOperationalExpenses: "Total Expenses",
    ebitda: "EBITDA & asset rental expenses",
    ebitdaPercentage: "EBITDA & asset rental margin %",
    financeCost: "Finance cost",
    oneTimeExpenses: "One time expenses (fund raising costs, etc.)",
    brokerageStampDuty: "Brokerage & Stamp Duty (for new locations)",
    reversalGST: "Reversal of GST related expenses",
    depreciation: "Depreciation",
    nonCashRSOPCost: "Non Cash ESOP Cost",
    assetRental: "Asset rentals",
    pbt: "PBT",
    pbtPercentage: "PBT %",

    // Performance Metrics
    bedRevenueCOCO: "Bed avg. revenue/bednight (COCO)",
    nonBedRevenueCOCO: "Non-bed avg. revenue/bednight (COCO)",
    totalRevenuePerBedNightCOCO: "Total revenue per bednight (COCO)",
    losCOCO: "Length of stay (LOS) (COCO)",
    customerAcquisitionCost: "Customer Acquisition Cost (CAC)",
    cacPercentage: "CAC %",
    commissionableBookingsPercentage: "Commissionable bookings %",
    nonCommissionableBookingsPercentage: "Non-Commissionable bookings %",
    brandScore: "Brand score",
    totalReviews: "Total reviews",

    // Booking Sources
    bookingSourceBookingCom: "Booking.com",
    bookingSourceGoMmt: "Go-MMT",
    bookingSourceHostelworld: "Hostelworld",
    bookingSourceAgoda: "Agoda",
    bookingSourceGoogle: "Book on google",
    bookingSourceWebsite: "Website",
    bookingSourceWalkin: "Walkin",
    bookingSourceExtension: "Extension",
    bookingSourceThHo: "TH HO",
    bookingSourceB2B: "B2B",
    bookingSourceTravelDesk: "Travel Desk",
    bookingSourceOthers: "Others",

    // margins
    totalMarginsPercentage: "Margins %",

    rentalsPercentage: "Rentals %",
    hostelSalaryPercentage: "Hostel salary %",
    utilitiesRMPercentage: "Utilities/R&M %",
    comissionsPercentage: "Commissions %",
    miscPercentage: "Miscellaneous %",
    cafeExpensesPercentage: "Cafe expenses %",
    ancillaryLocalPercentage: "Ancillary & local expenses %",
  };

  const isSubMetric = (metric) => {
    const subMetrics = [
      "noOfCocoHotels",
      "noOfFocoOmcHostels",
      "noOfFofoHostels",
      "noOfCocoHotelBeds",
      "noOfFocoOmcHostelBeds",
      "noOfFofoHostelBeds",
      "cocoBedNights",
      "focoOmcBedNights",
      "fofoBedNights",
      "cocoOccupancy",
      "focoOmcOccupancy",
      "fofoOccupancy",
      "cocoSoldBedNights",
      "focoOmcSoldBedNights",
      "fofoSoldBedNights",

      "adrCoco",
      "adrFocoOmc",
      "adrFofo",

      "noOfPxCoco",
      "noOfPxFocoOmc",
      "noOfPxFofo",

      "cocoBedRevenue",
      "cocoCafeRevenue",
      "cocoAncillaryRevenue",

      "cocoRentals",
      "cocoHostelSalary",
      "cocoUtilities",
      "cocoCommissions",
      "cocoMiscellaneous",
      "cocoCafe",
      "cocoAncillary",

      "focoOmcBedRevenue",
      "focoOmcCafeRevenue",
      "focoOmcAncillaryRevenue",

      "focoOmcRentals",
      "focoOmcHostelSalary",
      "focoOmcUtilities",
      "focoOmcCommissions",
      "focoOmcMiscellaneous",
      "focoOmcCafe",
      "focoOmcAncillary",
      "focoOmcPartnerPayout",

      "fofoBedRevenue",
      "fofoCafeRevenue",
      "fofoAncillaryRevenue",

      "fofoRoyalty",
      "fofoPlatformFees",

      "hoMembership",
      "hoSaleOfFurnitureLinens",
      "hoOtherIncome",

      "hoEmployeeBenefit",
      "hoMarketing",
      "hoTravelling",
      "hoTechSubscriptions",
      "hoOfficeRentals",
      "hoUtilities",
      "hoLegal",
      "hoMiscellaneous",

      "rentalsPercentage",
      "hostelSalaryPercentage",
      "utilitiesRMPercentage",
      "comissionsPercentage",
      "miscPercentage",
      "cafeExpensesPercentage",
      "ancillaryLocalPercentage",
    ];
    return subMetrics.includes(metric);
  };

  // Extract unique months and years from data
  const allMonths = useMemo(
    () =>
      [...new Set(investorData.map((item) => item.month))].map((month) => ({
        value: month,
        label: month,
      })),
    [investorData]
  );

  const allYears = useMemo(
    () =>
      [...new Set(investorData.map((item) => item.year))].map((year) => ({
        value: year,
        label: year,
      })),
    [investorData]
  );

  const getMetrics = () => {
    if (investorData && investorData.length > 0) {
      const firstItem = investorData[0];
      return Object.keys(firstItem).filter(
        (key) =>
          key !== "_id" &&
          key !== "month" &&
          key !== "year" &&
          !isSubMetric(key)
      );
    }
    return [];
  };

  const getMonthYearColumns = () => {
    if (!Array.isArray(investorData) || investorData.length === 0) {
      return [];
    }

    let filteredData = investorData;

    if (selectedMonths && selectedMonths.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedMonths.map((month) => month.value).includes(item.month)
      );
    }

    if (selectedYears && selectedYears.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedYears.map((year) => year.value).includes(item.year)
      );
    }

    const uniqueMonths = [
      ...new Set(
        filteredData
          .map((item) => `${item.month} ${item.year}`)
          .sort((a, b) => {
            const [monthA, yearA] = a.split(" ");
            const [monthB, yearB] = b.split(" ");
            return (
              yearA - yearB ||
              new Date(`${monthA} 1, ${yearA}`) -
                new Date(`${monthB} 1, ${yearB}`)
            );
          })
      ),
    ];

    return uniqueMonths;
  };

  const toggleRow = (metric) => {
    setExpandedRows((prev) => ({
      ...prev,
      [metric]: !prev[metric],
    }));
  };

  const isHighlighted = (metric) => {
    const highlightedMetrics = [
      "noOfHostels",
      "noOfBeds",
      "totalAvailableBedNights",
      "totalOccupancy",
      "totalSoldBedNights",
      "adrOverall",
      "totalPxOverall",
      "cocoGrossRevenue",
      "cocoGrossExpense",
      "focoOmcGrossRevenue",
      "focoOmcGrossExpense",
      "fofoGrossRevenue",
      "fofoGrossExpense",
      "hoGrossRevenue",
      "hoGrossExpense",

      "totalGmv",
      "totalGrossRevenue",
      "totalOperationalExpenses",
      "ebitda",
      "ebitdaPercentage",
      "pbt",
      "pbtPercentage",
      "totalMarginsPercentage",
    ];
    return highlightedMetrics.includes(metric);
  };

  const isExpandable = (metric) => {
    const expandableMetrics = [
      "noOfHostels",
      "noOfBeds",
      "totalAvailableBedNights",
      "totalOccupancy",
      "totalSoldBedNights",
      "adrOverall",
      "totalPxOverall",
      "cocoGrossRevenue",
      "cocoGrossExpense",
      "focoOmcGrossRevenue",
      "focoOmcGrossExpense",
      "fofoGrossRevenue",
      "fofoGrossExpense",
      "hoGrossRevenue",
      "hoGrossExpense",
      "totalMarginsPercentage",
    ];
    return expandableMetrics.includes(metric);
  };

  const getSubMetrics = (metric) => {
    const subMetricsMap = {
      noOfHostels: ["noOfCocoHotels", "noOfFocoOmcHostels", "noOfFofoHostels"],
      noOfBeds: [
        "noOfCocoHotelBeds",
        "noOfFocoOmcHostelBeds",
        "noOfFofoHostelBeds",
      ],
      totalAvailableBedNights: [
        "cocoBedNights",
        "focoOmcBedNights",
        "fofoBedNights",
      ],
      totalOccupancy: ["cocoOccupancy", "focoOmcOccupancy", "fofoOccupancy"],
      totalSoldBedNights: [
        "cocoSoldBedNights",
        "focoOmcSoldBedNights",
        "fofoSoldBedNights",
      ],
      adrOverall: ["adrCoco", "adrFocoOmc", "adrFofo"],
      totalPxOverall: ["noOfPxCoco", "noOfPxFocoOmc", "noOfPxFofo"],

      cocoGrossRevenue: [
        "cocoBedRevenue",
        "cocoCafeRevenue",
        "cocoAncillaryRevenue",
      ],

      cocoGrossExpense: [
        "cocoRentals",
        "cocoHostelSalary",
        "cocoUtilities",
        "cocoCommissions",
        "cocoMiscellaneous",
        "cocoCafe",
        "cocoAncillary",
      ],
      focoOmcGrossRevenue: [
        "focoOmcBedRevenue",
        "focoOmcCafeRevenue",
        "focoOmcAncillaryRevenue",
      ],
      focoOmcGrossExpense: [
        "focoOmcRentals",
        "focoOmcHostelSalary",
        "focoOmcUtilities",
        "focoOmcCommissions",
        "focoOmcMiscellaneous",
        "focoOmcCafe",
        "focoOmcAncillary",
        "focoOmcPartnerPayout",
      ],
      fofoGrossRevenue: [
        "fofoBedRevenue",
        "fofoCafeRevenue",
        "fofoAncillaryRevenue",
      ],
      fofoGrossExpense: ["fofoRoyalty", "fofoPlatformFees"],
      hoGrossRevenue: [
        "hoMembership",
        "hoSaleOfFurnitureLinens",
        "hoOtherIncome",
      ],
      hoGrossExpense: [
        "hoEmployeeBenefit",
        "hoMarketing",
        "hoTravelling",
        "hoTechSubscriptions",
        "hoOfficeRentals",
        "hoUtilities",
        "hoLegal",
        "hoMiscellaneous",
      ],
      totalMarginsPercentage: [
        "rentalsPercentage",
        "hostelSalaryPercentage",
        "utilitiesRMPercentage",
        "comissionsPercentage",
        "miscPercentage",
        "cafeExpensesPercentage",
        "ancillaryLocalPercentage",
      ],
    };
    return subMetricsMap[metric] || [];
  };

  const formatNumber = (num) => new Intl.NumberFormat("en-IN").format(num);

  const handleReset = () => {
    setSelectedMonths([]);
    setSelectedYears([]);
    setSearchText("");
  };

  const filteredMetrics = useMemo(
    () =>
      getMetrics().filter((metric) => {
        const label = fieldLabels[metric] || metric;
        return label.toLowerCase().includes(searchText.toLowerCase());
      }),
    [searchText, investorData]
  );

  const visibleColumns = getMonthYearColumns();
  return (
    <Card>
      <CardHeader>
        <Row className="d-flex align-items-center gap-3 flex-nowrap">
          <Col md="6">
            <FormGroup>
              <Label for="monthSelect">Select Months</Label>
              <Select
                isMulti
                id="monthSelect"
                options={allMonths}
                value={selectedMonths}
                onChange={setSelectedMonths}
                // placeholder="Select Months"
                styles={{ menu: (provided) => ({ ...provided, zIndex: 1050 }) }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="yearSelect">Select Years</Label>
              <Select
                isMulti
                id="yearSelect"
                options={allYears}
                value={selectedYears}
                onChange={setSelectedYears}
                placeholder="Select Years"
                styles={{ menu: (provided) => ({ ...provided, zIndex: 1050 }) }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="searchInput">Search Metrics</Label>
              <Input
                type="text"
                id="searchInput"
                placeholder="Search Metrics"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="auto" className="d-flex align-items-end">
            <Button color="danger" onClick={handleReset}>
              Reset
            </Button>
          </Col>
        </Row>
      </CardHeader>

      <CardBody
        style={{
          overflowX: "auto",
          overflowY: "auto",
          maxHeight: "500px",
          position: "relative",
        }}
      >
        <Table bordered striped responsive style={{ minWidth: "100%" }}>
          <thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#264653",
              zIndex: 100,
            }}
          >
            <tr>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  left: 0,
                  backgroundColor: "#264653",
                  zIndex: 101,
                  color: "white",
                }}
              >
                Metric
              </th>
              {visibleColumns.map((monthYear, index) => (
                <th
                  key={index}
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#264653",
                    color: "white",
                    zIndex: 100,
                  }}
                >
                  {monthYear}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredMetrics.map((metric) => (
              <React.Fragment key={metric}>
                <tr
                  style={{
                    backgroundColor: isHighlighted(metric)
                      ? "#e3f2fd"
                      : "inherit",
                    fontWeight: "normal",
                  }}
                >
                  <td
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: isHighlighted(metric)
                        ? "#e3f2fd"
                        : "#f9f9f9",
                      zIndex: 99,
                      cursor: isExpandable(metric) ? "pointer" : "default",
                    }}
                    onClick={() => isExpandable(metric) && toggleRow(metric)}
                  >
                    {fieldLabels[metric] || metric}
                    {isExpandable(metric) && (
                      <Button
                        color="link"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleRow(metric);
                        }}
                      >
                        {expandedRows[metric] ? "▲" : "▼"}
                      </Button>
                    )}
                  </td>
                  {investorData.map((item) => {
                    const monthYear = `${item.month} ${item.year}`;
                    return visibleColumns.includes(monthYear) ? (
                      <td key={item._id}>
                        {item[metric] !== null && item[metric] !== undefined
                          ? formatNumber(item[metric])
                          : "N/A"}
                      </td>
                    ) : null;
                  })}
                </tr>
                {expandedRows[metric] &&
                  getSubMetrics(metric).map((subMetric) => (
                    <tr key={subMetric}>
                      <td
                        style={{
                          position: "sticky",
                          left: 0,
                          paddingLeft: "40px",
                          backgroundColor: "#f9f9f9",
                          zIndex: 98,
                        }}
                      >
                        {fieldLabels[subMetric] || subMetric}
                      </td>
                      {investorData.map((item) => {
                        const monthYear = `${item.month} ${item.year}`;
                        return visibleColumns.includes(monthYear) ? (
                          <td key={item._id}>
                            {item[subMetric] !== null &&
                            item[subMetric] !== undefined
                              ? formatNumber(item[subMetric])
                              : "N/A"}
                          </td>
                        ) : null;
                      })}
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default InvestorDataTable;

// import React, { useState, useMemo } from "react";
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   Table,
//   Button,
//   FormGroup,
//   Label,
//   Input,
//   Row,
//   Col,
// } from "reactstrap";
// import Select from "react-select";

// const InvestorDataTable = ({ investorData }) => {
//   const [selectedMonths, setSelectedMonths] = useState([]);
//   const [selectedYears, setSelectedYears] = useState([]);
//   const [searchText, setSearchText] = useState("");
//   const [expandedRows, setExpandedRows] = useState(() => {
//     const initialExpandedState = {};
//     const expandableMetrics = [
//       "noOfHostels",
//       "noOfBeds",
//       "totalAvailableBedNights",
//       "totalOccupancy",
//       "totalSoldBedNights",
//       "adrOverall",
//       "totalPxOverall",
//       "totalGmv",
//       "totalGrossRevenue",
//       "totalOperationalExpenses",
//       "ebitda",
//       "ebitdaPercentage",
//       "pbt",
//       "pbtPercentage",
//       "totalMarginsPercentage",
//     ];
//     expandableMetrics.forEach((metric) => {
//       initialExpandedState[metric] = true;
//     });
//     return initialExpandedState;
//   });

//   const fieldLabels = {
//     month: "Month",
//     year: "Year",
//     // General Metrics
//     noOfHostels: "No of Hostels",
//     noOfCocoHotels: "# OF COCO Hotels",
//     noOfFocoOmcHostels: "# OF FOCO/OMC Hostels",
//     noOfFofoHostels: "# OF FOFO Hostels",
//     noOfBeds: "No of Beds",
//     noOfCocoHotelBeds: "# OF COCO Hostel Beds",
//     noOfFocoOmcHostelBeds: "# FOCO/OMC Hostel Beds",
//     noOfFofoHostelBeds: "# OF FOFO Hostel Beds",

//     // Bed Nights & Occupancy
//     totalAvailableBedNights: "T A Bed Nights (Total)",
//     cocoBedNights: "T A Bed Nights (COCO)",
//     focoOmcBedNights: "T A Bed Nights (Others (FOCO/OMC))",
//     fofoBedNights: "T A Bed Nights (Others (FOFO))",
//     totalOccupancy: "Total Occupancy (Overall)",
//     cocoOccupancy: "Occupancy (Company Owned)",
//     focoOmcOccupancy: "Occupancy (Others (FOCO/OMC))",
//     fofoOccupancy: "Occupancy (Others (FOFO))",

//     // Bed Sales
//     totalSoldBedNights: "T S Bed Nights (Total)",
//     cocoSoldBedNights: "T S Bed Nights (Company Owned)",
//     focoOmcSoldBedNights: "T S Bed Nights (Others (FOCO/OMC))",
//     fofoSoldBedNights: "T S Bed Nights (Others (FOFO))",

//     // ADR (Average Daily Rate)
//     adrOverall: "Bed ADR (Overall)",
//     adrCoco: "Bed ADR (COCO)",
//     adrFocoOmc: "Bed ADR (Others (FOCO/OMC))",
//     adrFofo: "Bed ADR (Others (FOFO))",

//     // Guests
//     totalPxOverall: "Total guests (overall)",
//     noOfPxCoco: "No. of guests (COCO)",
//     noOfPxFocoOmc: "No. of guests (Others (FOCO/OMC))",
//     noOfPxFofo: "No. of guests (Others (FOFO))",

//     // Revenue (COCO)
//     cocoBedRevenue: "Bed Revenue (COCO)",
//     cocoCafeRevenue: "Cafe Revenue (COCO)",
//     cocoAncillaryRevenue: "Ancillary Revenue (COCO)",
//     cocoGrossRevenue: "Gross COCO Revenue",

//     // Expenses (COCO)
//     cocoRentals: "Rentals (COCO)",
//     cocoHostelSalary: "Hostel Salary (COCO)",
//     cocoUtilities: "Utilities/R&M (COCO)",
//     cocoCommissions: "Commissions (COCO)",
//     cocoMiscellaneous: "Miscellaneous (COCO)",
//     cocoCafe: "Cafe (COCO)",
//     cocoAncillary: "Ancillary & Local (COCO)",
//     cocoGrossExpense: "Gross COCO Expense",

//     // Margins (COCO)
//     cocoOperationalMargin: "Operational Margin (COCO)",
//     cocoOperationalMarginPercentage: "Operational Margin % (COCO)",

//     // Revenue (FOCO & OMC)
//     focoOmcBedRevenue: "Bed Revenue (FOCO & OMC)",
//     focoOmcCafeRevenue: "Cafe Revenue (FOCO & OMC)",
//     focoOmcAncillaryRevenue: "Ancillary & Local Revenue (FOCO & OMC)",
//     focoOmcGrossRevenue: "Gross FOCO & OMC Revenue",

//     // Expenses (FOCO & OMC)
//     focoOmcRentals: "Rentals (FOCO & OMC)",
//     focoOmcHostelSalary: "Hostel Salary (FOCO & OMC)",
//     focoOmcUtilities: "Utilities/R&M (FOCO & OMC)",
//     focoOmcCommissions: "Commissions (FOCO & OMC)",
//     focoOmcMiscellaneous: "Miscellaneous (FOCO & OMC)",
//     focoOmcCafe: "Cafe (FOCO & OMC)",
//     focoOmcAncillary: "Ancillary & Local (FOCO & OMC)",
//     focoOmcPartnerPayout: "Partner Payout (FOCO & OMC)",
//     focoOmcGrossExpense: "Gross FOCO & OMC Expense",

//     // Margins (FOCO & OMC)
//     focoOmcOperationalMargin: "Operational Margin (FOCO & OMC)",
//     focoOmcOperationalMarginPercentage: "Operational Margin % (FOCO & OMC)",

//     // Revenue (FOFO)
//     fofoBedRevenue: "Bed Revenue (FOFO)",
//     fofoCafeRevenue: "Cafe Revenue (FOFO)",
//     fofoAncillaryRevenue: "Ancillary & Local Revenue (FOFO)",
//     fofoGrossRevenue: "Gross FOFO Revenue",

//     // Expenses (FOFO)
//     fofoRoyalty: "ROYALTY (FOFO)",
//     fofoPlatformFees: "PLATFORM FEES (FOFO)",
//     fofoGrossExpense: "Gross FOFO Expense",

//     // Margins (FOFO)
//     fofoOperationalMargin: "Operational Margin (FOFO)",
//     fofoOperationalMarginPercentage: "Operational Margin % (FOFO)",

//     // HO Revenue
//     hoMembership: "Memberships",
//     hoSaleOfFurnitureLinens: "Sale of furniture/linens",
//     hoOtherIncome: "Any other income (inc. platform fees)",
//     hoGrossRevenue: "Gross HO Revenue",

//     // HO Expenses
//     hoEmployeeBenefit: "Employee Benefit",
//     hoMarketing: "Marketing",
//     hoTravelling: "Travelling",
//     hoTechSubscriptions: "Tech & Subscriptions",
//     hoOfficeRentals: "Office Rentals",
//     hoUtilities: "Utilities/R&M",
//     hoLegal: "Legal/Compliances",
//     hoMiscellaneous: "Miscellaneous",
//     hoGrossExpense: "Gross HO Expense",

//     // Financial Metrics
//     totalGmv: "Total GMV",
//     totalGrossRevenue: "Total Gross Revenue",
//     totalOperationalExpenses: "Total Expenses",
//     ebitda: "EBITDA & asset rental expenses",
//     ebitdaPercentage: "EBITDA & asset rental margin %",
//     financeCost: "Finance cost",
//     oneTimeExpenses: "One time expenses (fund raising costs, etc.)",
//     brokerageStampDuty: "Brokerage & Stamp Duty (for new locations)",
//     reversalGST: "Reversal of GST related expenses",
//     depreciation: "Depreciation",
//     nonCashRSOPCost: "Non Cash ESOP Cost",
//     assetRental: "Asset rentals",
//     pbt: "PBT",
//     pbtPercentage: "PBT %",

//     // Performance Metrics
//     bedRevenueCOCO: "Bed avg. revenue/bednight (COCO)",
//     nonBedRevenueCOCO: "Non-bed avg. revenue/bednight (COCO)",
//     totalRevenuePerBedNightCOCO: "Total revenue per bednight (COCO)",
//     losCOCO: "Length of stay (LOS) (COCO)",
//     customerAcquisitionCost: "Customer Acquisition Cost (CAC)",
//     cacPercentage: "CAC %",
//     commissionableBookingsPercentage: "Commissionable bookings %",
//     nonCommissionableBookingsPercentage: "Non-Commissionable bookings %",
//     brandScore: "Brand score",
//     totalReviews: "Total reviews",

//     // Booking Sources
//     bookingSourceBookingCom: "Booking.com",
//     bookingSourceGoMmt: "Go-MMT",
//     bookingSourceHostelworld: "Hostelworld",
//     bookingSourceAgoda: "Agoda",
//     bookingSourceGoogle: "Book on google",
//     bookingSourceWebsite: "Website",
//     bookingSourceWalkin: "Walkin",
//     bookingSourceExtension: "Extension",
//     bookingSourceThHo: "TH HO",
//     bookingSourceB2B: "B2B",
//     bookingSourceTravelDesk: "Travel Desk",
//     bookingSourceOthers: "Others",

//     rentalsPercentage: "Rentals %",
//     hostelSalaryPercentage: "Hostel salary %",
//     utilitiesRMPercentage: "Utilities/R&M %",
//     comissionsPercentage: "Commissions %",
//     miscPercentage: "Miscellaneous %",
//     cafeExpensesPercentage: "Cafe expenses %",
//     ancillaryLocalPercentage: "Ancillary & local expenses %",
//     totalMarginsPercentage: "Margins %",
//   };

//   const isSubMetric = (metric) => {
//     const subMetrics = [
//       "noOfCocoHotels",
//       "noOfFocoOmcHostels",
//       "noOfFofoHostels",
//       "noOfCocoHotelBeds",
//       "noOfFocoOmcHostelBeds",
//       "noOfFofoHostelBeds",
//       "cocoBedNights",
//       "focoOmcBedNights",
//       "fofoBedNights",
//       "cocoOccupancy",
//       "focoOmcOccupancy",
//       "fofoOccupancy",
//       "cocoSoldBedNights",
//       "focoOmcSoldBedNights",
//       "fofoSoldBedNights",
//       "adrCoco",
//       "adrFocoOmc",
//       "adrFofo",
//       "noOfPxCoco",
//       "noOfPxFocoOmc",
//       "noOfPxFofo",
//     ];
//     return subMetrics.includes(metric);
//   };

//   // Extract unique months and years from data
//   const allMonths = useMemo(
//     () =>
//       [...new Set(investorData.map((item) => item.month))].map((month) => ({
//         value: month,
//         label: month,
//       })),
//     [investorData]
//   );

//   const allYears = useMemo(
//     () =>
//       [...new Set(investorData.map((item) => item.year))].map((year) => ({
//         value: year,
//         label: year,
//       })),
//     [investorData]
//   );

//   const getMetrics = () => {
//     if (investorData && investorData.length > 0) {
//       const firstItem = investorData[0];
//       return Object.keys(firstItem).filter(
//         (key) =>
//           key !== "_id" &&
//           key !== "month" &&
//           key !== "year" &&
//           !isSubMetric(key)
//       );
//     }
//     return [];
//   };

//   const getMonthYearColumns = () => {
//     if (!Array.isArray(investorData) || investorData.length === 0) {
//       return [];
//     }

//     let filteredData = investorData;

//     if (selectedMonths && selectedMonths.length > 0) {
//       filteredData = filteredData.filter((item) =>
//         selectedMonths.map((month) => month.value).includes(item.month)
//       );
//     }

//     if (selectedYears && selectedYears.length > 0) {
//       filteredData = filteredData.filter((item) =>
//         selectedYears.map((year) => year.value).includes(item.year)
//       );
//     }

//     const uniqueMonths = [
//       ...new Set(
//         filteredData
//           .map((item) => `${item.month} ${item.year}`)
//           .sort((a, b) => {
//             const [monthA, yearA] = a.split(" ");
//             const [monthB, yearB] = b.split(" ");
//             return (
//               yearA - yearB ||
//               new Date(`${monthA} 1, ${yearA}`) -
//                 new Date(`${monthB} 1, ${yearB}`)
//             );
//           })
//       ),
//     ];

//     return uniqueMonths;
//   };

//   const toggleRow = (metric) => {
//     setExpandedRows((prev) => ({
//       ...prev,
//       [metric]: !prev[metric],
//     }));
//   };

//   const isHighlighted = (metric) => {
//     const highlightedMetrics = [
//       "noOfHostels",
//       "noOfBeds",
//       "totalAvailableBedNights",
//       "totalOccupancy",
//       "totalSoldBedNights",
//       "adrOverall",
//       "totalPxOverall",
//       "totalGmv",
//       "totalGrossRevenue",
//       "totalOperationalExpenses",
//       "ebitda",
//       "ebitdaPercentage",
//       "pbt",
//       "pbtPercentage",
//       "totalMarginsPercentage",
//     ];
//     return highlightedMetrics.includes(metric);
//   };

//   const isExpandable = (metric) => {
//     const expandableMetrics = [
//       "noOfHostels",
//       "noOfBeds",
//       "totalAvailableBedNights",
//       "totalOccupancy",
//       "totalSoldBedNights",
//       "adrOverall",
//       "totalPxOverall",
//     ];
//     return expandableMetrics.includes(metric);
//   };

//   const getSubMetrics = (metric) => {
//     const subMetricsMap = {
//       noOfHostels: ["noOfCocoHotels", "noOfFocoOmcHostels", "noOfFofoHostels"],
//       noOfBeds: [
//         "noOfCocoHotelBeds",
//         "noOfFocoOmcHostelBeds",
//         "noOfFofoHostelBeds",
//       ],
//       totalAvailableBedNights: [
//         "cocoBedNights",
//         "focoOmcBedNights",
//         "fofoBedNights",
//       ],
//       totalOccupancy: ["cocoOccupancy", "focoOmcOccupancy", "fofoOccupancy"],
//       totalSoldBedNights: [
//         "cocoSoldBedNights",
//         "focoOmcSoldBedNights",
//         "fofoSoldBedNights",
//       ],
//       adrOverall: ["adrCoco", "adrFocoOmc", "adrFofo"],
//       totalPxOverall: ["noOfPxCoco", "noOfPxFocoOmc", "noOfPxFofo"],
//     };
//     return subMetricsMap[metric] || [];
//   };

//   const formatNumber = (num) => new Intl.NumberFormat("en-IN").format(num);

//   const handleReset = () => {
//     setSelectedMonths([]);
//     setSelectedYears([]);
//     setSearchText("");
//   };

//   const filteredMetrics = useMemo(
//     () =>
//       getMetrics().filter((metric) => {
//         const label = fieldLabels[metric] || metric;
//         return label.toLowerCase().includes(searchText.toLowerCase());
//       }),
//     [searchText, investorData]
//   );

//   const visibleColumns = getMonthYearColumns();
//   return (
//     <Card>
//       <CardHeader>
//         <Row className="d-flex align-items-center gap-3 flex-nowrap">
//           <Col md="6">
//             <FormGroup>
//               <Label for="monthSelect">Select Months</Label>
//               <Select
//                 isMulti
//                 id="monthSelect"
//                 options={allMonths}
//                 value={selectedMonths}
//                 onChange={setSelectedMonths}
//                 placeholder="Select Months"
//                 styles={{ menu: (provided) => ({ ...provided, zIndex: 1050 }) }}
//               />
//             </FormGroup>
//           </Col>
//           <Col md="6">
//             <FormGroup>
//               <Label for="yearSelect">Select Years</Label>
//               <Select
//                 isMulti
//                 id="yearSelect"
//                 options={allYears}
//                 value={selectedYears}
//                 onChange={setSelectedYears}
//                 placeholder="Select Years"
//                 styles={{ menu: (provided) => ({ ...provided, zIndex: 1050 }) }}
//               />
//             </FormGroup>
//           </Col>
//           <Col md="6">
//             <FormGroup>
//               <Label for="searchInput">Search Metrics</Label>
//               <Input
//                 type="text"
//                 id="searchInput"
//                 placeholder="Search Metrics"
//                 value={searchText}
//                 onChange={(e) => setSearchText(e.target.value)}
//               />
//             </FormGroup>
//           </Col>
//           <Col md="auto" className="d-flex align-items-end">
//             <Button color="danger" onClick={handleReset}>
//               Reset
//             </Button>
//           </Col>
//         </Row>
//       </CardHeader>

//       <CardBody
//         style={{
//           overflowX: "auto",
//           overflowY: "auto",
//           maxHeight: "500px",
//           position: "relative",
//         }}
//       >
//         <Table bordered striped responsive style={{ minWidth: "100%" }}>
//           <thead
//             style={{
//               position: "sticky",
//               top: 0,
//               backgroundColor: "#264653",
//               zIndex: 100,
//             }}
//           >
//             <tr>
//               <th
//                 style={{
//                   position: "sticky",
//                   top: 0,
//                   left: 0,
//                   backgroundColor: "#264653",
//                   zIndex: 101,
//                   color: "white",
//                 }}
//               >
//                 Metric
//               </th>
//               {visibleColumns.map((monthYear, index) => (
//                 <th
//                   key={index}
//                   style={{
//                     position: "sticky",
//                     top: 0,
//                     backgroundColor: "#264653",
//                     color: "white",
//                     zIndex: 100,
//                   }}
//                 >
//                   {monthYear}
//                 </th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {filteredMetrics.map((metric) => (
//               <React.Fragment key={metric}>
//                 <tr
//                   style={{
//                     backgroundColor: isHighlighted(metric)
//                       ? "#e3f2fd"
//                       : "inherit",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   <td
//                     style={{
//                       position: "sticky",
//                       left: 0,
//                       backgroundColor: isHighlighted(metric)
//                         ? "#e3f2fd"
//                         : "#f9f9f9",
//                       zIndex: 99,
//                       cursor: isExpandable(metric) ? "pointer" : "default",
//                     }}
//                     onClick={() => isExpandable(metric) && toggleRow(metric)}
//                   >
//                     {fieldLabels[metric] || metric}
//                     {isExpandable(metric) && (
//                       <Button
//                         color="link"
//                         size="sm"
//                         onClick={(e) => {
//                           e.stopPropagation();
//                           toggleRow(metric);
//                         }}
//                       >
//                         {expandedRows[metric] ? "▲" : "▼"}
//                       </Button>
//                     )}
//                   </td>
//                   {investorData.map((item) => {
//                     const monthYear = `${item.month} ${item.year}`;
//                     return visibleColumns.includes(monthYear) ? (
//                       <td key={item._id}>
//                         {item[metric] !== null && item[metric] !== undefined
//                           ? formatNumber(item[metric])
//                           : "N/A"}
//                       </td>
//                     ) : null;
//                   })}
//                 </tr>
//                 {expandedRows[metric] &&
//                   getSubMetrics(metric).map((subMetric) => (
//                     <tr key={subMetric}>
//                       <td
//                         style={{
//                           position: "sticky",
//                           left: 0,
//                           paddingLeft: "40px",
//                           backgroundColor: "#f9f9f9",
//                           zIndex: 98,
//                         }}
//                       >
//                         {fieldLabels[subMetric] || subMetric}
//                       </td>
//                       {investorData.map((item) => {
//                         const monthYear = `${item.month} ${item.year}`;
//                         return visibleColumns.includes(monthYear) ? (
//                           <td key={item._id}>
//                             {item[subMetric] !== null &&
//                             item[subMetric] !== undefined
//                               ? formatNumber(item[subMetric])
//                               : "N/A"}
//                           </td>
//                         ) : null;
//                       })}
//                     </tr>
//                   ))}
//               </React.Fragment>
//             ))}
//           </tbody>
//         </Table>
//       </CardBody>
//     </Card>
//   );
// };

// export default InvestorDataTable;
