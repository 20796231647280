import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_LAUNDRY_TRACKER_BY_HOSTELID = gql`
  query ($hostelId: ID!, $year: Int!, $month: Int!) {
    getLaundryTrackerDataByHostelId(hostelId: $hostelId, year: $year, month: $month) {
      _id
      hostelId{
        _id
        name
      }
      laundryVendorId
      itemName
      date
      quantity
      notes
      logs
    }
  }
`;

export const useGetLaundryTrackerByHostelId = (hostelId, year, month) => {
  const { loading, error, data, refetch } = useQuery(GET_LAUNDRY_TRACKER_BY_HOSTELID, 
    {
        variables: { hostelId, year, month },
        skip: !hostelId || !year || !month,
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const GET_DORMS_SOLD_FROM_EZEEBOOKING = gql`
  query ($hostelId: ID!, $year: Int!, $month: Int!) {
    getDormsSoldFromEzeeBooking(hostelId: $hostelId, year: $year, month: $month) 
  }
`;

export const useGetDormsSoldFromEzeeBooking = (hostelId, year, month) => {
  const { loading, error, data, refetch } = useQuery(GET_DORMS_SOLD_FROM_EZEEBOOKING, {
    variables: { hostelId, year, month },
    skip: !hostelId || !year || !month, 
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const GET_PRIVATE_SOLD_FROM_EZEEBOOKING = gql`
  query ($hostelId: ID!, $year: Int!, $month: Int!) {
    getPrivateSoldFromEzeeBooking(hostelId: $hostelId, year: $year, month: $month) 
  }
`;

export const useGetPrivateSoldFromEzeeBooking = (hostelId, year, month) => {
  const { loading, error, data, refetch } = useQuery(GET_PRIVATE_SOLD_FROM_EZEEBOOKING, {
    variables: { hostelId, year, month },
    skip: !hostelId || !year || !month, 
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};
