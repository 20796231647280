import React, { useEffect, useState } from "react";
import HostelRoomsTable from "./HostelRoomsTable";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useHostelList } from "../../../api/dataSource";
import {
  useHostelRoomsByHostelId,
  useSyncHostelRooms,
} from "../../../api/HostelRooms/index";
import { Col, Input, Row, Button, Spinner, FormGroup, Label } from "reactstrap";
import toaster from "../../../@core/components/common/Toaster";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import HostelRoomsDataTable from "./HostelRoomsDataTable";

const HostelRooms = () => {
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [syncHostelRoomsHandler, { data: syncResponse }] = useSyncHostelRooms();
  const [selectedHostelId, setSelectedHostelId] = useState(null);
  const { data: hostelListData, refetch } = useHostelList();

  const { data: roomListResponse, refetch: roomRefetch } =
    useHostelRoomsByHostelId(selectedHostelId?.value);

  useEffect(() => {
    if (hostelListData?.getHostelList) {
      const hostelList = hostelListData?.getHostelList?.map((hostel) => ({
        label: hostel?.name,
        value: hostel?._id,
      }));
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
      const filteredHostel =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return hostelList?.find((hostel) => hostel?.value === hostelID);
            })
          : hostelList;

      setTableData(filteredHostel);
    } else {
      setTableData([]);
    }
  }, [hostelListData]);
  // const filteredData = tableData.filter((hostel) =>
  //   hostel.name.toLowerCase().includes(filter.toLowerCase())
  // );

  // useEffect(() => {
  //   setIsLoading(false);
  //   if (syncResponse) {
  //     toaster("success", syncResponse?.syncHostelRooms?.message);
  //   }
  // }, [syncResponse]);

  const handleClick = () => {
    setIsLoading(true);
    syncHostelRoomsHandler(null);
    setTimeout(() => {
      setIsLoading(false);
      toaster("success", "Synced all Hostel Rooms");
    }, 30000);
  };

  return (
    localStorage.getItem("modules")?.includes("Glu Configuration") && (
      <div>
        <Row>
          <Col sm="4">
            {/* <Input
              type="text"
              placeholder="Filter Hostels"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            /> */}
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                <strong>Hostel</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                //theme={selectThemeColors}
                id="hostel"
                name="hostel"
                value={selectedHostelId}
                closeMenuOnSelect={true}
                options={tableData}
                onChange={(value) => {
                  setSelectedHostelId(value);
                  //roomRefetch();
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col className="">
            <div className="">
              <Button
                color="secondary"
                onClick={handleClick}
                disabled={isLoading}
                className="float-right"
              >
                {isLoading ? (
                  <>
                    <Spinner size="sm" color="light" className="mr-1" />{" "}
                    Loading...
                  </>
                ) : (
                  "Sync Rooms"
                )}
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        {/* <HostelRoomsTable hostelListData={filteredData} refetch={refetch} /> */}
        {roomListResponse?.getHostelRoomsByHostelId?.length === 0 ? (
          <Col sm="12">
            <div className="text-center">
              <h4>No rooms found</h4>
            </div>
          </Col>
        ) : roomListResponse?.getHostelRoomsByHostelId?.length > 0 ? (
          <Col sm="12">
            <HostelRoomsDataTable
              requestListData={roomListResponse}
              selectedHostelId={selectedHostelId?.value}
              refetch={refetch}
              roomRefetch={roomRefetch}
            />
          </Col>
        ) : (
          <Col sm="12">
            <div className="text-center">
              <h4>Please select a hostel</h4>
            </div>
          </Col>
        )}
      </div>
    )
  );
};

export default HostelRooms;
