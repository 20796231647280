import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_HOSTEL_LIST_CO_LIVING = gql`
    query getHostelListColiving {
        getHostelListColiving {
            _id
            name
        }
    }
`

export const useGetAllHostelListColiving = () => {
    const { loading, data, error, refetch } = useQuery(GET_HOSTEL_LIST_CO_LIVING)

    useLoadingHandler(loading)

    return { data, error, refetch }
}

export const GET_COLIVING_LIST = gql`
    query getColivingDetails($hostelId:ID!){
        getColivingDetails(hostelId:$hostelId){
             _id
            hostelId
            roomType
            RatePlanID
            RoomTypeID
            RateTypeID
            prices{
             amount
             tax
             period
        }
        }
        }
`

export const useGetAllColivingList = () => {
    const [fetchColiving, { loading, data, error, refetch }] = useLazyQuery(GET_COLIVING_LIST)

    useLoadingHandler(loading)

    const fetchColivingHandler = (hostelId) => {
        fetchColiving({
            variables: {
                hostelId
            },
            fetchPolicy: "network-only"
        })
    }

    return [fetchColivingHandler, { data, error, refetch }]
}

export const GET_COLIVING_BOOKING_PAGGINATION = gql`
  query ($filter: filter) {
    getColivingPagginated(filter: $filter) {
      data {
        _id
        payableAmount
        checkoutDate
        checkinDate
        providerRefId
        createdAt
        bookingDate
        hostelId {
          _id
          name
        }
        contactDetails {
          name
          mobile
          email
        }
        rooms {
          roomName
          quantity
        }
        status
        coupon
        refundType
        refundStatus
        providerRefundRefId
        walletDebit
        cancelDate
        walletRefund
        razorpayRefund
        userId {
          fullName
          email
        }
        bookingSource
        utm_source
        utm_medium
        utm_campaign
        isBlackListed
        UTM
        coliving
      }
      limit
      page
      count
    }
  }
`;

export const useGetColivingBooking = (
  limit,
  page,
  promoCode,
  status,
  hostelIds,
  fromDate,
  toDate,
  guestName,
  mobile,
  providerRefId,
  paymentMode,
  bookingSource,
  isBlackListed,
  UTM,
  Coliving
) => {
  const { loading, error, data, refetch } = useQuery(GET_COLIVING_BOOKING_PAGGINATION, {
    variables: {
      filter: {
        limit,
        page,
        promoCode,
        status,
        hostelIds,
        fromDate,
        toDate,
        guestName,
        mobile,
        providerRefId,
        paymentMode,
        bookingSource,
        isBlackListed,
        UTM,
        Coliving
      },
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};