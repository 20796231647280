import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { useCreateInventory } from "../../../api/Inventory/mutations";
import toaster from "@components/common/Toaster";
import { useGetInventoryByReferenceId } from "../../../api/Inventory/Queries";
import { dateFormatterDDMMYYYY } from "../../../utility/Utils";

const tableHeader = [
  "Date",
  "Opening Stock",
  "Inventory Change",
  "Closing Stock",
  "Remark",
  "Added By",
];

const LogModal = ({
  showModal,
  onClose,
  currentInventory,
  editData,
  updateServiceHandler,
  setInventoryObject,
  inventoryObject,
  setTotalInventoryPerDay,
}) => {
  const { data, error, refetch } = useGetInventoryByReferenceId(editData?._id);
  //const [currentCount, setCurrentCount] = useState(0);
  // const [addedCount, setAddedCount] = useState("");
  // const [remarks, setRemarks] = useState("");
  // const [closingCount, setClosingCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  //const [createHandler, { data, error, refetch }] = useCreateInventory();

  const handleClose = () => {
    try {
      onClose(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data?.getAllInventoryBasedOnReferenceId?.length > 0) {
      setTableData(data?.getAllInventoryBasedOnReferenceId);
    } else {
      setTableData([]);
    }
    if (showModal) {
      refetch();
    }
  }, [data, showModal]);

  useEffect(() => {});

  const handleSubmit = (e) => {
    e.preventDefault();
    // const addedCountNumber = Number(addedCount);
    //setClosingCount(currentInventory + addedCountNumber);
    // const Object = {
    //   referenceId: editData?._id,
    //   hostelId: editData?.hostelId,
    //   category: editData?.category,
    //   itemName: editData?.title,
    //   opening: currentInventory,
    //   change: addedCountNumber,
    //   closing: currentInventory + addedCountNumber,
    //   changeDate: new Date(),
    //   remark: remarks,
    // };

    setInventoryObject((prev) => ({
      ...prev,
      opening: Number(currentInventory),
      closing: Number(currentInventory) + prev?.change,
    }));

    setTotalInventoryPerDay(
      (prev) => Number(prev) + (inventoryObject?.change || 0)
    );
    handleClose();
  };

  return (
    <div>
      <Modal
        isOpen={showModal}
        toggle={handleClose}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={handleClose}
          style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)" }}
        >
          Add Inventory
        </ModalHeader>

        <ModalBody style={{ padding: "20px 40px" }}>
          <Row>
            <form>
              <Row>
                <Col sm="6">
                  <Label style={{ fontSize: "" }}>
                    Current Inventory : {currentInventory}
                  </Label>
                </Col>

                <Col sm="12">
                  <FormGroup>
                    <Label for="new">New Inventory</Label>
                    <Input
                      type="number"
                      name="new"
                      id="new"
                      //value={addedCount}

                      value={
                        inventoryObject?.change === 0
                          ? ""
                          : inventoryObject?.change
                      }
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        setInventoryObject((prev) => ({
                          ...prev,
                          change: value === "" ? 0 : value,
                        }));
                      }}
                      placeholder={"new inventory count"}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup>
                    <Label for="remarks">Remarks</Label>
                    <Input
                      type="text"
                      name="remarks"
                      id="remarks"
                      value={inventoryObject?.remark}
                      onChange={(e) =>
                        setInventoryObject((prev) => ({
                          ...prev,
                          remark: e.target.value,
                        }))
                      }
                      placeholder={"add remarks"}
                    />
                  </FormGroup>
                </Col>
                {inventoryObject?.closing > 0 && (
                  <Col
                    sm="6"
                    //style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <Label style={{ fontSize: "" }}>
                      Closing Count : {inventoryObject?.closing}
                    </Label>
                  </Col>
                )}
                <Col>
                  <Button
                    type="submit"
                    className=" rounded float-right mb-1"
                    color="light"
                    size="md"
                    //disabled={inventoryObject?.change !== 0}
                    //onClick={handleAddVariations}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>

            {/* <Row> */}
            <div
              style={{
                minWidth: "400px",
                overflowX: "auto",
                maxHeight: "400px",
                overflowY: "auto",
              }}
            >
              {tableData?.length > 0 && (
                <Table bordered>
                  <thead style={{ position: "sticky", top: 0, zIndex: 0 }}>
                    <tr>
                      <th colSpan={6}>
                        <h5 style={{ textAlign: "center" }}>
                          {editData?.title}
                        </h5>
                      </th>
                    </tr>
                    <tr>
                      {tableHeader?.map((data, i) => (
                        <th key={i}>
                          <span>{data}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((el, i) => {
                      return (
                        <tr key={i}>
                          <td>{dateFormatterDDMMYYYY(el?.createdAt)}</td>
                          <td>{el?.opening}</td>
                          <td>
                            <Badge
                              pill
                              color={
                                el?.change > 0
                                  ? "light-success"
                                  : "light-danger"
                              }
                            >
                              {el?.change}
                            </Badge>
                          </td>
                          <td>{el?.closing}</td>
                          <td>{el?.remark}</td>
                          <td>{el?.addedBy || ""}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>
            {/* </Row> */}
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LogModal;
