// ! Coded by Ashwin

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_LOGS_BY_ID = gql`
  query getLogsById($id: ID, $module: String) {
    getLogsById(id: $id, module: $module) {
      _id
      referenceId
      module
      sectionName
      log
    }
  }
`;

export const useGetLogsById = ({ id, module }) => {
  const { loading, error, data, refetch } = useQuery(GET_LOGS_BY_ID, {
    variables: {
      id,
      module,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
