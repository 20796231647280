import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { useAddReplyToComment, useDeleteConnectComment, useGetAllUserList, useDeleteConnectReply } from "../../api/dataSource";
import toaster from "@components/common/Toaster";
import { Trash } from "react-feather";
import ConnectTagUserName from "./ConnectTagUserName";
import ConfirmDeleteForConnect from "./ConfirmDeleteForConnect";
import confirmDelete from "@components/common/confirmDelete";

const Reply = ({ commentData, refetch }) => {
  const userName = localStorage.getItem("name");
  const userId = localStorage.getItem("userId")
  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  const commentRef = useRef(null);
  const replies = commentData?.replies;
  const [reply, setReply] = useState("");
  const [isExecuting, setIsExecuting] = useState(false)
  const [searchName, setSearchName] = useState("");
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [showComment, setShowComment] = useState(false);
  const { data: allUserList } = useGetAllUserList(searchName)
  const [deleteConnectCommentHandler , { data }] = useDeleteConnectComment();
  const [addReplyToCommentHandler, { data: addedReplyData }] = useAddReplyToComment();
  const [deleteConnectReplyHandler, { data: deleteReply }] = useDeleteConnectReply();
  
  const list = Array.isArray(allUserList?.getUserListToTag) ? allUserList.getUserListToTag : [];

  const DepartmentList = [
    { name: "Sales", value: "Sales" },
    { name: "Marketing", value: "Marketing" },
    { name: "Technology", value: "Technology" },
    { name: "Management", value: "Management" },
    { name: "Customer Experience", value: "Customer Experience" },
    { name: "Operations", value: "Operations" },
    { name: "Finance", value: "Finance" },
    { name: "HR", value: "HR" },
  ];

  const combinedList = [...list, ...DepartmentList];

  const modifiedList = combinedList.map(item => ({
    ...item, 
    name: item.name.replace(/\s+/g, "_")
  }));
  
  const filteredList = modifiedList.filter(item =>
    item.name.toLowerCase().includes(searchName.toLowerCase())
  );

  const handleReplyClick = () => {
    setShowComment(true);
  };

  const submitHandler = async () => {
    try {
      const replyToCommentInput = {
        reply: reply,
        replyDateTime: new Date(),
        replyBy: userName,
        replyAddedById: userId
      };

      if (reply.trim().length) {
        await addReplyToCommentHandler(commentData._id, replyToCommentInput);
        toaster("success", "Comment added");
        setReply("");
        setSearchName("")
        setTaggedUsers([])
        setShowComment(false);
        setIsExecuting(false)
      } else {
        toaster("error", " Add a comment")
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    if (addedReplyData) {
      refetch();
    }
  }, [addedReplyData]);

  const handleCancel = () => {
    setShowComment(false);
    setReply("")
  }

  const handleDeleteComment = () => {
    if (commentData?.comment) {
      ConfirmDeleteForConnect({
        callback: () => deleteConnectCommentHandler(userId, commentData?._id),
        text: "Deleting a comment will remove all associated replies.",
      });
    }
  };  

  useEffect(() => {
    if (deleteReply) {
      refetch();
    }
  }, [deleteReply]);

  const handleDeleteReply = (replyId) => {
    confirmDelete(() => {
      deleteConnectReplyHandler(userId, replyId);
    });
  };
  
  useEffect(() => {
    if (data) {
      if (data?.deleteConnectComment?.message === "deletedSuccessfully") {
        toaster("success", "Comment deleted");
        refetch();
      }
    }
    if (deleteReply) {
      if (deleteReply?.message === "deletedSuccessfully") {
        toaster("success", "Reply deleted");
        refetch();
      }
    }
  }, [data, deleteReply]);
  

  const handleCommentChange = (e) => {
    const inputValue = e.target.value;
    const lastChar = e.nativeEvent.data;
    const lastKeyCode = e.nativeEvent.inputType;

    setReply(inputValue);

    if (lastKeyCode === "deleteContentBackward") {
      const cursorPosition = e.target.selectionStart;
      const match = inputValue.slice(0, cursorPosition).match(/@\S+$/);

      if (match) {
        const tagStart = match.index;
        setReply((prevComment) => prevComment.slice(0, tagStart));
        setTaggedUsers((taggedUsers) =>
          taggedUsers.filter((tag) => !match[0].trim().startsWith(tag))
        );
        setSearchName("");
        setIsExecuting(false);
        return;
      }
    }

  if (lastChar === '@' && !isExecuting) {
      setIsExecuting(true);
      setSearchName("");
    } else if (lastChar === ' ' && isExecuting) {
      setTaggedUsers(taggedUsers => [...taggedUsers, searchName]);
      setIsExecuting(false);
    } else if (isExecuting && lastChar !== '@') {
      setSearchName(searchName + lastChar);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (replies.length > 0) {
        submitHandler(); 
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (commentRef.current && !commentRef.current.contains(event.target)) {
        setShowComment(false);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  return (
    <div className="card" style={{ width: "98%", margin: "20px", display:"flex", padding:"20px", paddingBottom: "20px" }}>
      <div style={{ display: 'flex' }}>
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7118_33940)">
              <circle cx="12.9999" cy="13.8268" r="12.35" fill="#595959" />
              <path d="M8.86548 10.2908C8.86548 8.00784 10.717 6.15894 13 6.15894C15.283 6.15894 17.1345 8.00784 17.1345 10.2908C17.1345 12.5739 15.283 14.4254 13 14.4254C10.717 14.4254 8.86548 12.5739 8.86548 10.2908Z" fill="#595959" />
              <path d="M20.4425 21.4158V22.943C18.4131 24.601 15.8215 25.5947 12.9998 25.5947C10.1781 25.5947 7.58646 24.601 5.55713 22.943V21.4158C5.55713 18.5443 7.88721 16.2195 10.756 16.2195H15.2436C18.1124 16.2195 20.4425 18.5443 20.4425 21.4158Z" fill="#F8F8F8" />
              <path d="M5.55713 22.943V21.4158C5.55713 18.5443 7.88721 16.2195 10.756 16.2195H15.2436C18.1124 16.2195 20.4425 18.5443 20.4425 21.4158V22.943" fill="#F8F8F8" />
              <path d="M17.1345 10.2908C17.1345 12.5739 15.283 14.4254 13 14.4254C10.717 14.4254 8.86548 12.5739 8.86548 10.2908C8.86548 8.00784 10.717 6.15894 13 6.15894C15.283 6.15894 17.1345 8.00784 17.1345 10.2908Z" fill="#F8F8F8" />
              <path d="M12.9999 26.1766C6.19008 26.1766 0.649902 20.6364 0.649902 13.8266C0.649902 7.01674 6.19008 1.47656 12.9999 1.47656C19.8097 1.47656 25.3499 7.01674 25.3499 13.8266C25.3499 20.6364 19.8097 26.1766 12.9999 26.1766ZM12.9999 2.64035C6.83173 2.64035 1.81369 7.65839 1.81369 13.8266C1.81369 19.9947 6.83173 25.0128 12.9999 25.0128C19.1681 25.0128 24.1861 19.9947 24.1861 13.8266C24.1861 7.65839 19.1681 2.64035 12.9999 2.64035Z" fill="#595959" />
            </g>
            <defs>
              <clipPath id="clip0_7118_33940">
                <rect width="26" height="26" fill="white" transform="translate(0 0.82666)" />
              </clipPath>
            </defs>
          </svg>        </svg>

        <div style={{ display: 'flex', flexDirection: "column", marginLeft: "10px"}}>
          <span style={{ fontWeight: "500", fontSize: "16px" }}>{commentData?.addedBy}</span>
          <span>{commentData?.addedDateTime ? new Date(commentData.addedDateTime).toLocaleDateString('en-GB',{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
          }, options) : ""}</span>
        </div>

        {
          commentData?.addedById === userId && <span style={{height:"30px", width:"30px",borderRadius:"20px", backgroundColor:"yellow", right:"20px", position:"absolute", color:"black", textAlign:"center", cursor:"pointer", paddingTop:"4px"}} onClick={handleDeleteComment} ><Trash size={19} /></span>
        }
      </div>

      <div style={{ textAlign: "justify", margin: "15px 30px 0 35px", alignItems: "end", display: "flex", flex: 1 }}>
        <p style={{ fontSize: "16px", fontWeight: "400", margin: 0, whiteSpace: "pre-line" }}>
          {
            commentData.comment
              ?.split(" ")
              .map((word, index) => {
                const tag = word.startsWith("@") ? word.slice(1) : null;
                const isTagInUserList = tag && filteredList.some((user) => user.name === tag)

                return isTagInUserList ? (
                <span key={index} style={{ fontSize: "17px", fontWeight: "900", color: "#ff4d4d" }}>
                    {word}{" "}
                  </span>
                ) : (
                  `${word} `
                );
              })
          }
        </p>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <Button.Ripple
          color="light"
          onClick={handleReplyClick}
          style={{
            cursor: "pointer",
            width: "120px",
            fontWeight: "600",
            marginRight: 0,
          }}
        >
          Reply
        </Button.Ripple>
      </div>

      {showComment &&
        <div ref={commentRef} style={{ display: 'flex', gap: "10px", position: "relative", marginTop: "10px", justifyContent: "center" }}>
          <Input
            type="textarea"
            value={reply}
            id="reply"
            name="reply"
            placeholder={`Add a reply...`}
            onChange={(e) => handleCommentChange(e)}
            onKeyDown={handleKeyDown}
            rows={2}
            style={{position: "relative"}}
          />
          {isExecuting &&
            <ConnectTagUserName comment={reply} userList={filteredList} setComment={setReply} setVisibility={setIsExecuting}
              setSearchName={setSearchName} setTaggedUsers={setTaggedUsers} taggedUsers={taggedUsers}
          />}
          <div style={{ display: "flex", justifyContent: "end", height: "40px" }}>
            <Button.Ripple
              color="light"
              onClick={submitHandler}
              style={{ alignItems: "right", width: "120px", fontWeight: "600" }}
            >
              Comment
            </Button.Ripple>
          </div>
          <div style={{ display: "flex", justifyContent: "end", height: "40px" }}>
            <Button.Ripple
              onClick={handleCancel}
              style={{ alignItems: "right", width: "120px", fontWeight: "600" }}
            >
              Cancel
            </Button.Ripple>
          </div>
        </div>
      }

      {replies && replies.map((reply, index) =>
        <div key={index} className="card" style={{ width: "90%", margin: "20px 0 0 10%", display:"flex", padding:"20px", paddingBottom: "20px", border:"1px solid #808080" }}>
          <div style={{ display: 'flex' }}>
            <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_7118_33940)">
                <circle cx="12.9999" cy="13.8268" r="12.35" fill="#595959" />
                <path d="M8.86548 10.2908C8.86548 8.00784 10.717 6.15894 13 6.15894C15.283 6.15894 17.1345 8.00784 17.1345 10.2908C17.1345 12.5739 15.283 14.4254 13 14.4254C10.717 14.4254 8.86548 12.5739 8.86548 10.2908Z" fill="#595959" />
                <path d="M20.4425 21.4158V22.943C18.4131 24.601 15.8215 25.5947 12.9998 25.5947C10.1781 25.5947 7.58646 24.601 5.55713 22.943V21.4158C5.55713 18.5443 7.88721 16.2195 10.756 16.2195H15.2436C18.1124 16.2195 20.4425 18.5443 20.4425 21.4158Z" fill="#F8F8F8" />
                <path d="M5.55713 22.943V21.4158C5.55713 18.5443 7.88721 16.2195 10.756 16.2195H15.2436C18.1124 16.2195 20.4425 18.5443 20.4425 21.4158V22.943" fill="#F8F8F8" />
                <path d="M17.1345 10.2908C17.1345 12.5739 15.283 14.4254 13 14.4254C10.717 14.4254 8.86548 12.5739 8.86548 10.2908C8.86548 8.00784 10.717 6.15894 13 6.15894C15.283 6.15894 17.1345 8.00784 17.1345 10.2908Z" fill="#F8F8F8" />
                <path d="M12.9999 26.1766C6.19008 26.1766 0.649902 20.6364 0.649902 13.8266C0.649902 7.01674 6.19008 1.47656 12.9999 1.47656C19.8097 1.47656 25.3499 7.01674 25.3499 13.8266C25.3499 20.6364 19.8097 26.1766 12.9999 26.1766ZM12.9999 2.64035C6.83173 2.64035 1.81369 7.65839 1.81369 13.8266C1.81369 19.9947 6.83173 25.0128 12.9999 25.0128C19.1681 25.0128 24.1861 19.9947 24.1861 13.8266C24.1861 7.65839 19.1681 2.64035 12.9999 2.64035Z" fill="#595959" />
              </g>
              <defs>
                <clipPath id="clip0_7118_33940">
                  <rect width="26" height="26" fill="white" transform="translate(0 0.82666)" />
                </clipPath>
              </defs>
            </svg></svg>
            <div style={{ display: 'flex', flexDirection: "column", marginLeft: "10px"}}>
              <span style={{ fontWeight: "500", fontSize: "16px" }}>{reply?.replyBy}</span>
              <span>{reply?.replyDateTime ? new Date(reply?.replyDateTime).toLocaleDateString('en-GB',{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
              }, options) : ""}
              </span>
            </div>
              {
                reply?.replyAddedById === userId && 
                <span style={{height:"30px", width:"30px",borderRadius:"20px", backgroundColor:"yellow", right:"20px", position:"absolute", color:"black", textAlign:"center", cursor:"pointer", paddingTop:"4px"}} onClick={() => handleDeleteReply(reply._id)} ><Trash size={19} /></span>
              }
          </div>

          <div style={{ textAlign: "justify", margin: "15px 0 0 35px", alignItems: "end", display: "flex", flex: 1 }}>
            <p style={{ fontSize: "16px", fontWeight: "400", margin: 0, whiteSpace: "pre-line" }}>
              {
                reply?.reply
                ?.split(" ")
                .map((word, index) => {
                  const tag = word.startsWith("@") ? word.slice(1) : null;
                  const isTagInUserList = tag && filteredList.some((user) => user.name === tag)
                  
                  return isTagInUserList ? (
                    <span key={index} style={{ fontSize: "17px", fontWeight: "900", color: "#ff4d4d" }}>
                        {word}{" "}
                      </span>
                    ) : (
                      `${word} `
                    );
                })
              }
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Reply;


