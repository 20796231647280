import React, { useEffect, useState } from "react";
import ColivingBookingTable from "./ColivingBookingTable";
import { useGetColivingBooking } from '../../api/Coliving/Queries'
import { useGetColivingBookingForExport } from "../../api/Coliving/Mutations";
import { useHistory } from "react-router";
import TablePagination from "@components/common/TablePagination";
import { Col, Row, FormGroup, Label, Button, Input, Spinner } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter, addDays } from "@utils";
import Select from "react-select";
import { useHostelList } from "../../api/dataSource";
import { selectThemeColors } from "@utils";
import AddEditModal from "./AddEditModal";
import { dateValidator } from "../../utility/Utils";
import toaster from "@components/common/Toaster";
import { useSyncWebsiteBokings } from "../../api/BookingReport/Queries";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ColivingBooking = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [promoCode, setPromoCode] = useState(null);
  const [guestName, setGuestName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [status, setStatus] = useState(null);
  const [providerRefId, setProviderRefId] = useState(null);
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const [paymentMode, setPaymentMode] = useState(null);
  const [filter, setFilter] = useState({});
  const [exportTableData, setExportTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [bookingSource, setBookingSource] = useState(null);
  const [isBlackListed, setIsBlackListed] = useState(null);
  const [UTM, setUTM] = useState("");
  const [id, setId] = useState("");
  const [coliving, setColiving] = useState(null)
  const [colivingCondition, setColivingCondition] = useState(null)
  const url = useLocation();

  const { data, refetch } = useGetColivingBooking(
    limit,
    page,
    filter?.promoCode,
    filter?.status,
    filter?.hostelIds,
    filter?.fromDate,
    filter?.toDate,
    filter?.guestName,
    filter?.mobile,
    filter?.providerRefId,
    filter?.paymentMode,
    filter?.bookingSource,
    filter?.isBlackListed,
    filter?.UTM,
    filter.coliving = url?.search === "?coLiving=true" ? true : colivingCondition
  );

  const [getColivingBookingExportDataHandler, { data: exportData }] =
    useGetColivingBookingForExport();
  const [totalPage, setTotalPage] = useState(1);
  const history = useHistory();

  const { data: allHostelsData } = useHostelList();
  const statusList = [
    { label: "confirm", value: "confirm" },
    { label: "init", value: "init" },
    { label: "failure", value: "failure" },
    { label: "cancel", value: "cancel" },
    { label: "pending", value: "pending" },
  ];

  const paymentModeList = [
    { label: "Wallet", value: "walletDebit" },
    { label: "Razor Pay", value: "paymentMode" },
  ];

  const bookingSourceList = [
    { label: "Website", value: "Website" },
    // { label: "Whatsapp Chatbot", value: "Whatsapp Chatbot" },
    // { label: "Instagram Chatbot", value: "Instagram Chatbot" },
    // { label: "Website Chatbot", value: "Website Chatbot" },
  ];

  const isBlackListedOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [syncWebsiteBookingsHandler, { data: syncData, loading, error }] =
    useSyncWebsiteBokings();

  const handleSync = () => {
    setIsLoading(true);
    syncWebsiteBookingsHandler();
    setTimeout(() => {
      setIsLoading(false);
      toaster("success", "Booking Sync is in progress wait for few minutes");
    }, 20000);

    return;
  };

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [status, hostel, promoCode]);

  useEffect(() => {
    if (data?.getColivingPagginated?.data) {
      setTableData(data?.getColivingPagginated?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getColivingPagginated?.count / limit));
  }, [data]);

  useEffect(() => {
    let HostelsList = [];
    if (allHostelsData?.getHostelList?.length) {
      allHostelsData?.getHostelList?.map((item) => {
        HostelsList.push({ label: item?.name, value: item?._id });
        setHostelsList(HostelsList);
      });
    }
  }, [allHostelsData?.getHostelList]);

  useEffect(() => {
    if (exportData?.getColivingBookingReportForExport?.data) {
      if (exportData?.getColivingBookingReportForExport?.data.length > 0) {
        const byteArray = new Uint8Array(
          exportData?.getColivingBookingReportForExport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `website_bookings_pg.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [exportData]);

  useEffect(() => {
    if (id && data?.getColivingPagginated?.data.length) {
      let editData = data?.getColivingPagginated?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getColivingPagginated?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/report/edit/websiteBooking/${id}`);
    } else {
      setId(id);
    }
  };

  const submitHandler = () => {
    if (dateValidator(fromDate, toDate)) {

      const colivingCondition = url?.search === "?coLiving=true" || coliving?.value === true ? true : false
      setColivingCondition(colivingCondition)
      const data = {
        promoCode: promoCode,
        status: status?.value,
        hostelIds: hostel?.length ? hostel?.map((item) => item?.value) : hostel,
        fromDate,
        toDate,
        guestName,
        mobile,
        providerRefId,
        paymentMode: paymentMode?.value,
        bookingSource: bookingSource?.map((item) => item?.value),
        isBlackListed: isBlackListed?.value,
        UTM,
        coliving: colivingCondition
      };
      setFilter(data);
      setPage(1);
    } else {
      toaster("error", "Invalid Dates");
    }
  };

  const refreshHandler = () => {
    // const data = {
    //     promoCode: promoCode,
    //     status: status?.value,
    //     hostelIds: hostel?.length ? hostel?.map((item)=>item?.value) : hostel
    // }
    setPromoCode("");
    setGuestName("");
    setMobile("");
    setStatus(null);
    setHostel([]);
    setFromDate(null);
    setToDate(null);
    setPaymentMode(null);
    setBookingSource(null);
    setIsBlackListed(null);
    setUTM("");
    setFilter({});
    setColiving(null)
    setColivingCondition(null)
    setPage(1);
  };

  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="providerRefId">
                Reservation Id.
              </Label>
              <Input
                type="text"
                value={providerRefId}
                onChange={(e) => setProviderRefId(e.target.value)}
                placeholder="Search..."
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                Status
              </Label>
              {/* <Label className="form-label" for="status">
              <h5>Check coupons status*</h5>
            </Label> */}
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                id="status"
                name="status"
                value={status}
                options={statusList}
                onChange={(value) => {
                  setStatus(value);
                }}
              />
            </FormGroup>
          </Col>
          {/* <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="coupon">
                Coupon
              </Label>
              <Input
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                placeholder="Search..."
              />
            </FormGroup>
          </Col> */}
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="guestName">
                Guest Name
              </Label>
              <Input
                type="text"
                value={guestName}
                onChange={(e) => setGuestName(e.target.value)}
                placeholder="Search..."
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="mobile">
                Mobile No.
              </Label>
              <Input
                type="text"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Search..."
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                From Date
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                To Date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="toDate"
                placeholder="To Date"
              />
            </FormGroup>
          </Col>

          {/* <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="paymentMode">
                Payment Mode
              </Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                // isClearable={true}
                isSearchable={true}
                id="paymentMode"
                name="paymentMode"
                value={paymentMode}
                options={paymentModeList}
                onChange={(value) => {
                  setPaymentMode(value);
                }}
              />
            </FormGroup>
          </Col> */}

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="bookingSource">
                Booking Source
              </Label>
              <Select
                isClearable={true}
                id="bookingSource"
                name="bookingSource"
                isMulti
                theme={selectThemeColors}
                options={bookingSourceList}
                value={bookingSource}
                onChange={(value) => setBookingSource(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="isBlackListed">
                Blacklisted
              </Label>
              <Select
                isClearable={true}
                id="isBlackListed"
                name="isBlackListed"
                value={isBlackListed}
                options={isBlackListedOptions}
                onChange={(value) => {
                  setIsBlackListed(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col> */}

          {/* <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="UTM">
                UTM
              </Label>
              <Input
                type="text"
                value={UTM}
                onChange={(e) => setUTM(e.target.value)}
                placeholder="Search..."
              />
            </FormGroup>
          </Col> */}
          {/* {url?.search !== "?coLiving=true" && <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="coliving">
                Coliving
              </Label>
              <Select
                isClearable={true}
                id="coliving"
                name="coliving"
                value={coliving}
                options={isBlackListedOptions}
                onChange={(value) => {
                  setColiving(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>} */}

          {/* <Row className="align-items-left"> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col sm="2">
              <Button
                style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginTop: "22px",
                  marginBottom: "22px",
                  borderRadius: "5px",
                  padding: "10px 30px",
                  outline: "none",
                  border: "0",
                }}
                color="white"
                appearance="primary"
                type="button"
                onClick={() => {
                  submitHandler();
                }}
              >
                Search
              </Button>
            </Col>
            <Col sm="2">
              <Button
                style={{
                  backgroundColor: "#ff9999",
                  height: "40px",
                  marginTop: "22px",
                  // marginLeft: "20px",
                  borderRadius: "5px",
                  padding: "10px 30px",
                  outline: "none",
                  border: "0",
                }}
                color="danger"
                appearance="primary"
                type="button"
                onClick={() => {
                  refreshHandler();
                }}
              >
                Reset
              </Button>
            </Col>
            <Col sm="2">
              <Button.Ripple
                className="btn-icon mb-2"
                color="success"
                appearance="success"
                type="button"
                style={{
                  height: "40px",
                  marginTop: "22px",
                  borderRadius: "5px",
                  padding: "10px 30px",
                  outline: "none",
                  border: "0",
                }}
                onClick={() => {
                  getColivingBookingExportDataHandler({
                    limit,
                    page,
                    promoCode,
                    status: status?.value,
                    hostelIds: hostel?.length
                      ? hostel?.map((item) => item?.value)
                      : hostel,
                    fromDate,
                    toDate,
                    guestName,
                    mobile,
                    bookingSource: bookingSource?.map((item) => item?.value),
                    isBlackListed: isBlackListed?.value,
                    UTM,
                  });
                }}
              >
                Export
              </Button.Ripple>
            </Col>
            {/* <Col sm="4">
              <Button.Ripple
                color="secondary"
                // className="btn-icon mb-2"
                appearance="success"
                type="button"
                onClick={handleSync}
                // className="ml-auto mt-2 cursor-pointer  btn-icon rounded float-right"
                style={{
                  height: "40px",
                  marginTop: "22px",
                  borderRadius: "5px",
                  padding: "10px 30px",
                  outline: "none",
                  border: "0",
                }}
              >
                {isLoading ? (
                  <>
                    <Spinner size="sm" color="light" className="mr-1" />{" "}
                    Syncing...
                  </>
                ) : (
                  "Sync Data"
                )}
              </Button.Ripple>
            </Col> */}
          </div>
        </Row>
        {/* </Row> */}
        <ColivingBookingTable
          setId={setId}
          id={id}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          refetch={refetch}
          count={data?.getColivingPagginated?.count}
          handleEdit={handleEdit}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
          setId={setId}
        />
      </div>
    )
  );
};

export default ColivingBooking;
