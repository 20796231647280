import React from "react";
import { useParams } from "react-router";
import { useGetDestinationLogsById } from "../../../../api/Packages/Queries";
import { useGetLogsById } from "../../../../api/Logs/Queries";

const Logs = () => {
  const { id } = useParams();

  const { data } = useGetDestinationLogsById(id);
  const { data: sectionLogs } = useGetLogsById({ id, module: "destination" });

  const logs = data?.getDestinationLogsById?.logs;

  const importantDetailsLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Important Details"
  );
  const photosLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Photos"
  );
  const seoLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "SEO"
  );
  const hostelsLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Hostels"
  );
  const nearByHostelsLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Near by Hostels"
  );
  const blogsLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Blogs"
  );
  const famousPlacesLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Famous Places"
  );
  const howToReachLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "How to Reach"
  );
  const itineraryLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Itinerary"
  );
  const thingsToDoLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Things to do"
  );
  const eventsToDoLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Events to do"
  );
  const newLogs = sectionLogs?.getLogsById?.filter(
    (item) => !item?.sectionName
  );

  return (
    <>
      {importantDetailsLogs?.length > 0 && (
        <>
          <p>
            <strong> Important details logs</strong>
          </p>

          <ul>
            {
              importantDetailsLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {photosLogs?.length > 0 && (
        <>
          <p>
            <strong> Photos logs</strong>
          </p>

          <ul>
            {
              photosLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {seoLogs?.length > 0 && (
        <>
          <p>
            <strong> SEO logs</strong>
          </p>

          <ul>
            {
              seoLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {hostelsLogs?.length > 0 && (
        <>
          <p>
            <strong> Hostel logs</strong>
          </p>

          <ul>
            {
              hostelsLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {nearByHostelsLogs?.length > 0 && (
        <>
          <p>
            <strong> Nearby hostel logs</strong>
          </p>

          <ul>
            {
              nearByHostelsLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {blogsLogs?.length > 0 && (
        <>
          <p>
            <strong> Blogs logs</strong>
          </p>

          <ul>
            {
              blogsLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {famousPlacesLogs?.length > 0 && (
        <>
          <p>
            <strong> Famous places logs</strong>
          </p>

          <ul>
            {
              famousPlacesLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {howToReachLogs?.length > 0 && (
        <>
          <p>
            <strong> How to reach logs</strong>
          </p>

          <ul>
            {
              howToReachLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {itineraryLogs?.length > 0 && (
        <>
          <p>
            <strong> Itinerary logs</strong>
          </p>

          <ul>
            {
              itineraryLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {thingsToDoLogs?.length > 0 && (
        <>
          <p>
            <strong> Things to do logs</strong>
          </p>

          <ul>
            {
              thingsToDoLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {eventsToDoLogs?.length > 0 && (
        <>
          <p>
            <strong> Events to do logs</strong>
          </p>

          <ul>
            {
              eventsToDoLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {newLogs?.length > 0 && (
        <>
          <p>
            <strong> Logs</strong>
          </p>

          <ul>
            {
              newLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              // .reverse()
            }
          </ul>
        </>
      )}
      {logs?.length > 0 && (
        <>
          <p>
            {" "}
            <strong>Past Logs</strong>
          </p>

          <ul>
            {
              logs.map((log, index) => (
                <li key={index}>{log}</li>
              ))
              // .reverse()
            }
          </ul>
        </>
      )}
    </>
  );
};

export default Logs;
