import React, { useState, useMemo } from "react";
import { useGetOtaRatingMonthlyReport } from "../../api/ORMComparativeRatings/Queries";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import Select from "react-select";
import { CSVLink } from "react-csv";
import toaster from "../../@core/components/common/Toaster";

const monthOptions = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

const yearOptions = [
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
  { value: 2025, label: "2025" },
];

const bookingSourceOptions = [
  { value: "Booking.com", label: "Booking.com" },
  { value: "Tripadvisor", label: "Tripadvisor" },
  { value: "MakeMyTrip", label: "MakeMyTrip" },
  { value: "Goibibo", label: "Goibibo" },
  { value: "HostelWorld", label: "HostelWorld" },
  { value: "Google", label: "Google" },
  { value: "Airbnb", label: "Airbnb" },
  { value: "Agoda", label: "Agoda" },
];

const ORMComparativeRatings = () => {
  const [startMonth, setStartMonth] = useState(null);
  const [startYear, setStartYear] = useState(null);
  const [endMonth, setEndMonth] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [bookingSource, setBookingSource] = useState("");

  const { loading, data, fetchReport } = useGetOtaRatingMonthlyReport(
    startMonth,
    startYear,
    endMonth,
    endYear,
    bookingSource
  );

  const handleSearch = () => {
    if (startMonth && startYear && endMonth && endYear && bookingSource) {
      fetchReport({
        variables: {
          monthStart: startMonth,
          yearStart: startYear,
          monthEnd: endMonth,
          yearEnd: endYear,
          bookingSource,
        },
      });
      toaster("success", "Report fetched successfully");
    } else {
      toaster(
        "error",
        "Please select month, year, and booking source for both From and To."
      );
    }
  };

  const handleReset = () => {
    setStartMonth(null);
    setStartYear(null);
    setEndMonth(null);
    setEndYear(null);
    setBookingSource("");
    toaster("success", "Filters reset successfully");
  };
  const getUniqueMonthYearPairs = () => {
    if (!startMonth || !startYear || !endMonth || !endYear) return [];

    const monthYearSet = new Set();
    let currentYear = startYear;
    let currentMonth = startMonth;

    while (
      currentYear < endYear ||
      (currentYear === endYear && currentMonth <= endMonth)
    ) {
      monthYearSet.add(`${currentMonth}-${currentYear}`);

      // Move to the next month
      currentMonth++;
      if (currentMonth > 12) {
        currentMonth = 1;
        currentYear++;
      }
    }

    return Array.from(monthYearSet);
  };

  const uniqueMonthYearPairs = getUniqueMonthYearPairs();

  const csvData = useMemo(() => {
    // Prepare headers for CSV
    const headers = [
      { label: "Hostel Name", key: "name" },
      ...uniqueMonthYearPairs.map((pair) => {
        const [month, year] = pair.split("-");
        const monthLabel = new Date(0, month - 1).toLocaleString("en-US", {
          month: "long",
        });
        return { label: `${monthLabel} ${year}`, key: `${month}-${year}` };
      }),
    ];

    // Prepare rows for CSV
    const rows =
      data?.getOtaRatingMonthlyReport.map((report) => {
        const row = { name: report.name };
        uniqueMonthYearPairs.forEach((pair) => {
          const [month, year] = pair.split("-");
          const entry = report.rating.find(
            (r) => r.month === Number(month) && r.year === Number(year)
          );
          row[`${month}-${year}`] =
            entry && entry.avgRating !== null && entry.avgRating !== undefined
              ? entry?.avgRating?.toFixed(1)
              : "N/A";
        });
        return row;
      }) || [];

    return { headers, rows };
  }, [data, uniqueMonthYearPairs]);

  return (
    <Card>
      <CardHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CardTitle>ORM Comparative Ratings</CardTitle>
        </div>
      </CardHeader>
      <Row form style={{ padding: "16px", gap: "16px" }}>
        <Col md={3}>
          <FormGroup>
            <Label for="fromDate">From</Label>
            <div style={{ display: "flex", gap: "8px" }}>
              <Select
                id="fromMonth"
                options={monthOptions}
                value={monthOptions.find(
                  (option) => option.value === startMonth
                )}
                onChange={(option) =>
                  setStartMonth(option ? option.value : null)
                }
                placeholder="Month"
                styles={{ container: (base) => ({ ...base, flex: 1 }) }}
              />
              <Select
                id="fromYear"
                options={yearOptions}
                value={yearOptions.find((option) => option.value === startYear)}
                onChange={(option) =>
                  setStartYear(option ? option.value : null)
                }
                placeholder="Year"
                styles={{ container: (base) => ({ ...base, flex: 1 }) }}
              />
            </div>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="toDate">To</Label>
            <div style={{ display: "flex", gap: "8px" }}>
              <Select
                id="toMonth"
                options={monthOptions}
                value={monthOptions.find((option) => option.value === endMonth)}
                onChange={(option) => setEndMonth(option ? option.value : null)}
                placeholder="Month"
                styles={{ container: (base) => ({ ...base, flex: 1 }) }}
              />
              <Select
                id="toYear"
                options={yearOptions}
                value={yearOptions.find((option) => option.value === endYear)}
                onChange={(option) => setEndYear(option ? option.value : null)}
                placeholder="Year"
                styles={{ container: (base) => ({ ...base, flex: 1 }) }}
              />
            </div>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="bookingSource">Booking Source</Label>
            <Select
              id="bookingSource"
              options={bookingSourceOptions}
              value={bookingSourceOptions.find(
                (option) => option.value === bookingSource
              )}
              onChange={(option) =>
                setBookingSource(option ? option.value : "")
              }
              placeholder="Booking Source"
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%",
                  maxWidth: "220px",
                }),
              }}
            />
          </FormGroup>
        </Col>

        <Col
          md={12}
          style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
        >
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              marginRight: "16px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginRight: "16px",
              borderRadius: "5px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button>

          <CSVLink
            data={csvData.rows}
            headers={csvData.headers}
            filename="ota_rating_report.csv"
          >
            <Button
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                outline: "none",
                border: "0",
              }}
              color="success"
              appearance="success"
              type="button"
            >
              Export
            </Button>
          </CSVLink>
        </Col>
      </Row>

      <CardBody>
        <Table hover responsive>
          <thead>
            <tr>
              <th>Hostel Name</th>
              {uniqueMonthYearPairs.map((pair) => {
                const [month, year] = pair.split("-");
                const monthLabel = new Date(0, month - 1).toLocaleString(
                  "en-US",
                  { month: "long" }
                );
                return <th key={pair}>{`${monthLabel} ${year}`}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data?.getOtaRatingMonthlyReport.map((report) => (
                <tr key={report.name}>
                  <td>{report.name}</td>
                  {uniqueMonthYearPairs.map((pair) => {
                    const [month, year] = pair.split("-");
                    const entry = report.rating.find(
                      (r) =>
                        r.month === Number(month) && r.year === Number(year)
                    );
                    return (
                      <td key={pair}>
                        {entry && entry.avgRating !== null
                          ? entry?.avgRating?.toFixed(1)
                          : "N/A"}
                      </td>
                    );
                  })}
                </tr>
              ))}
            {loading && (
              <tr>
                <td
                  colSpan={uniqueMonthYearPairs.length + 1}
                  style={{ textAlign: "center" }}
                >
                  Loading...
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default ORMComparativeRatings;
