import React from "react";
import { Table, Card, CardHeader, CardBody } from "reactstrap";

const SalesTable = ({ data }) => {
  const totals = data.reduce(
    (acc, item) => {
      acc.serviceCount += item.serviceCount;
      acc.serviceRev += parseFloat(item.serviceRev);
      acc.EACount += item.EACount;
      acc.EARev += parseFloat(item.EARev);
      acc.totalCount += item.totalCount;
      acc.totalRev += parseFloat(item.totalRev);
      acc.netRev += parseFloat(item.netRev);
      return acc;
    },
    {
      serviceCount: 0,
      serviceRev: 0,
      EACount: 0,
      EARev: 0,
      totalCount: 0,
      totalRev: 0,
      netRev: 0,
    }
  );

  if (data.length === 0) {
    return (
      <Card>
        <CardHeader style={{ textAlign: "left", fontSize: "1.5rem" }}>
          Hostelwise Total Revenue
        </CardHeader>
        <CardBody>
          <Table
            striped
            bordered
            hover
            responsive
            style={{ marginTop: "1rem" }}
          >
            <thead>
              <tr>
                <th colSpan="9" style={{ textAlign: "center" }}>
                  No data available. Please select a date range.
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader style={{ textAlign: "left", fontSize: "1.5rem" }}>
        Hostelwise Total Revenue
      </CardHeader>
      <CardBody>
        <Table striped bordered hover responsive style={{ marginTop: "1rem" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Hostel</th>
              <th style={{ textAlign: "center" }}>Service Count</th>
              <th style={{ textAlign: "center" }}>Service Revenue</th>
              <th style={{ textAlign: "center" }}>E&A Count</th>
              <th style={{ textAlign: "center" }}>E&A Revenue</th>
              <th style={{ textAlign: "center" }}>Total Count</th>
              <th style={{ textAlign: "center" }}>Total Revenue</th>
              <th style={{ textAlign: "center" }}>Net Revenue</th>
              <th style={{ textAlign: "center" }}>Aov</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item._id}>
                <td style={{ textAlign: "center" }}>{item.hostel}</td>
                <td style={{ textAlign: "center" }}>{item.serviceCount}</td>
                <td style={{ textAlign: "center" }}>
                  {Number(item.serviceRev).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </td>
                <td style={{ textAlign: "center" }}>{item.EACount}</td>
                <td style={{ textAlign: "center" }}>
                  {Number(item.EARev).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </td>
                <td style={{ textAlign: "center" }}>{item.totalCount}</td>
                <td style={{ textAlign: "center" }}>
                  {Number(item.totalRev).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </td>
                <td style={{ textAlign: "center" }}>
                  {Number(item.netRev).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </td>
                <td style={{ textAlign: "center" }}>{item.aov}</td>
              </tr>
            ))}
            <tr style={{ fontWeight: "bold" }}>
              <td style={{ textAlign: "center" }}>Total</td>
              <td style={{ textAlign: "center" }}>{totals.serviceCount}</td>
              <td style={{ textAlign: "center" }}>
                {totals.serviceRev.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
              </td>
              <td style={{ textAlign: "center" }}>{totals.EACount}</td>
              <td style={{ textAlign: "center" }}>
                {totals.EARev.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
              </td>
              <td style={{ textAlign: "center" }}>{totals.totalCount}</td>
              <td style={{ textAlign: "center" }}>
                {totals.totalRev.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
              </td>
              <td style={{ textAlign: "center" }}>
                {totals.netRev.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
              </td>
              <td style={{ textAlign: "center" }}>—</td>{" "}
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default SalesTable;
