//Added By Gaurav
import gql from "graphql-tag";

export const GET_OCCUPANCY_TRACKER_REPORT = gql`
query getOccupancyTrackerReport($date:String, $hostelIds: [ID]){
  getOccupancyTrackerReport(date: $date, hostelIds: $hostelIds){
        hostelName
        hostelId
        Occupancy
}
}
`;

export const GET_ALL_OCCUPANCY_TRACKER_REPORT = gql`
query getMultiDateOccupancyTrackerReport($dates:[String], $hostelIds: [ID]){
  getMultiDateOccupancyTrackerReport(dates: $dates, hostelIds: $hostelIds){
        hostelName
        hostelId
        Occupancy
}
}
`;

export const GENERATE_SINGLE_OCCUPANCY_EXCEL_DATA = gql`
mutation generateSingleOccupancyExport($date:String, $hostelIds: [ID]){
  generateSingleOccupancyExport(date: $date, hostelIds: $hostelIds)
}
`

export const GENERATE_MULTIPLE_OCCUPANCY_EXCEL_DATA = gql`
mutation generateMultipleOccupancyExport($dates:[String], $hostelIds: [ID]){
  generateMultipleOccupancyExport(dates: $dates, hostelIds: $hostelIds)
}
`