import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Plus, X, Camera } from "react-feather";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateDestination, useUploadImage } from "@api";
import { useParams } from "react-router-dom";
import { tableDataHandler } from "@store/Cms/cms.action";
import Avatar from "@components/common/avatar";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const Itinerary = () => {
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [updateDestinationHandler, { data: updatedDestinationData }] =
    useUpdateDestination();
  const [itinerary, setItinerary] = useState([
    {
      id: uuidv4(),
      time: "",
      title: "",
      description: "",
      displayOrder: "",
    },
  ]);
  const [index, setIndex] = useState("");

  const [uploadItineraryImageHandler, { data: itineraryImageData }] =
    useUploadImage();

  useEffect(() => {
    if (state?.tableData?.itinerary?.length) {
      let updatedItinerary = state?.tableData?.itinerary?.map((item) => {
        return {
          id: uuidv4(),
          time: item?.time,
          title: item?.title,
          description: item?.description,
          displayOrder: item?.displayOrder,
          itineraryImage: item?.itineraryImage,
        };
      });
      setItinerary(updatedItinerary);
    }
  }, [state]);

  useEffect(() => {
    if (updatedDestinationData?.updateDestination?.destination) {
      toaster("success", "Itinerary updated successfully");
    }
  }, [updatedDestinationData]);

  useEffect(() => {
    if (itineraryImageData?.uploadImage?.url) {
      setItinerary(
        itinerary.map((item) => {
          if (item.id === index) {
            return {
              ...item,
              itineraryImage: itineraryImageData?.uploadImage?.url,
            };
          } else {
            return item;
          }
        })
      );
    }
  }, [itineraryImageData?.uploadImage?.url]);

  const handleRemoveItinerary = (id) =>
    confirmDelete(() =>
      setItinerary(itinerary.filter((item) => item.id !== id))
    );

  const handleAddItinerary = () =>
    setItinerary([
      ...itinerary,
      { id: uuidv4(), time: "", title: "", description: "", displayOrder: "" },
    ]);

  const handleChangeItineraryTime = (time, id) => {
    setItinerary(
      itinerary.map((item) => (item.id === id ? { ...item, time: time } : item))
    );
  };

  const handleChangeItineraryTitle = (title, id) => {
    setItinerary(
      itinerary.map((item) =>
        item.id === id ? { ...item, title: title } : item
      )
    );
  };

  const handleChangeItineraryDescription = (description, id) => {
    setItinerary(
      itinerary.map((item) =>
        item.id === id ? { ...item, description: description } : item
      )
    );
  };

  const handleChangeDisplayOrder = (displayOrder, id) => {
    setItinerary(
      itinerary.map((item) =>
        item.id === id
          ? { ...item, displayOrder: parseInt(displayOrder) }
          : item
      )
    );
  };

  const submitDatahandler = () => {
    let isValid = false;

    let data = {
      itinerary: itinerary,
      sectionName: "Itinerary",   
    };

    itinerary?.forEach((item) => {
      if (item?.time && item?.description && item?.displayOrder) {
        isValid = true;
      } else {
        isValid = false;
      }
    });

    if (isValid) {
      itinerary?.forEach((item) => delete item.id);

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          itinerary: data?.itinerary,
        })
      );
      updateDestinationHandler(id, data);
    } else {
      toaster("error", "Please fill the details");
    }
  };

  const fileUploader = (e, id) => {
    if (!e.target.files[0]) return;
    setIndex(id);

    // const fileName = e.target.files[0]?.name;

    // const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    // if (specialCharsRegex.test(fileName)) {
    //   console.error("File name:", fileName);
    //   toaster(
    //     "error",
    //     "File name contains special characters. Please provide a valid file name."
    //   );

    //   return;
    // }

    uploadItineraryImageHandler(
      e.target.files[0],
      `${state.tableData?.name?.toLowerCase()?.replaceAll(" ", "-")}` +
        `/DESTINATION`
    );

    toaster("success", "Itinerary image uploaded successfully");
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDatahandler();
      }}
    >
      <Row className="mt-2">
        {itinerary.map(
          (
            { time, title, description, displayOrder, id, itineraryImage },
            index
          ) => (
            <Col className="d-flex align-items-center" sm="12" key={id}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"itinerary" + id}>
                  Itinerary {index + 1}
                </Label>

                <div className="mt-2 d-flex align-items-center">
                  <div className="mb-1 mr-2 flex flex-column">
                    <div>
                      <Avatar
                        img={itineraryImage}
                        alt={itineraryImage}
                        imgHeight={100}
                        imgWidth={100}
                        size="xl"
                      />
                    </div>
                    <span>1200X800</span>
                  </div>

                  <label htmlFor={`myFile${id}`}>
                    <p className="btn btn-sm btn-light">
                      <Camera size={15} />
                    </p>
                    <input
                      name={`myFile${id}`}
                      id={`myFile${id}`}
                      className="d-none"
                      type="file"
                      onChange={(e) => fileUploader(e, id)}
                    />
                  </label>
                </div>

                <Input
                  type="text"
                  value={time}
                  id={"time" + id}
                  name={"time"}
                  placeholder="Time"
                  onChange={(e) =>
                    handleChangeItineraryTime(e.target.value, id)
                  }
                />
                <br />

                <Input
                  type="text"
                  value={title}
                  id={"title" + id}
                  name={"title"}
                  placeholder="Title"
                  onChange={(e) =>
                    handleChangeItineraryTitle(e.target.value, id)
                  }
                />
                <br />

                <Input
                  type="textarea"
                  value={description}
                  id={"description" + id}
                  name={"description"}
                  placeholder="Description"
                  onChange={(e) =>
                    handleChangeItineraryDescription(e.target.value, id)
                  }
                />
                <br />

                <Input
                  type="number"
                  value={displayOrder}
                  id={"displayOrder" + id}
                  name={"displayOrder"}
                  placeholder="Display Order"
                  min="1"
                  onChange={(e) => handleChangeDisplayOrder(e.target.value, id)}
                />
              </FormGroup>
              {itinerary.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveItinerary(id)}
                  >
                    <X size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          )
        )}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddItinerary} />
    </form>
  );
};

export default Itinerary;
