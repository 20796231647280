//Added By Gaurav
import gql from "graphql-tag";

export const GET_BOOKING_BY_STAY_DATE = gql`
  query getDailyBookingsByStayDate(
    $date: String
    $hostelName: [String]
    $status: String
  ) {
    getDailyBookingsByStayDate(
      date: $date
      hostelName: $hostelName
      status: $status
    ) {
      GuestName
      ReservationNo
      FolioNo
      Status
      Source
      RoomNo
      TotalExclusivTax
      TotalTax
      TotalInclusiveTax
      ReservationDate
      ArrivalDate
      DepartureDate
      BaseRateInclusiveTax
      BaseRateExclusiveTax
      hostelName
      OtherRevenueExclusiveTax
      OtherRevenueInclusiveTax
      otherCharges
      BookingStatus
      VoucherNo
    }
  }
`;
