import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import Select from "react-select";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import AttachmentComponent from "../AttachmentComponent";
import { tableDataHandler } from "@store/Cms/cms.action";
import { dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import { v4 as uuidv4 } from "uuid";
import {
  WarrantyPeriodTypeList,
  CafeList,
  AMCTypeList,
  YesNoDropdownOptions,
} from "../selectOptionsList";
import { useUpdateSupply } from "../../../../api/Supply/Mutation";
import ImageModal from "../ImageModal";
import { XCircle } from "react-feather";

const WarrantyAMCDocs = ({ setRemainingCount }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const allowedDepartments = ["Management", "Supply", "Operations"];
  const userRole = allowedDepartments.includes(
    localStorage.getItem("department")
  );
  const [liftPresent, setLiftPresent] = useState(null)
  const [liftPurchaseInvoice, setLiftPurchaseInvoice] = useState([]);
  const [liftPurchaseDate, setLiftPurchaseDate] = useState(null);
  const [liftWarrantyPeriod, setLiftWarrantyPeriod] = useState("");
  const [liftLessorLiabilityAMCStatus, setLiftLessorLiabilityAMCStatus] = useState("");
  const [liftLessorLiabilityAMCMonths, setLiftLessorLiabilityAMCMonths] = useState(null);
  const [liftAMCStartDate, setLiftAMCStartDate] = useState(null)
  const [liftAMCEndDate, setLiftAMCEndDate] = useState(null)
  const [liftAMCType, setLiftAMCType] = useState("");
  const [liftAMCCopy, setLiftAMCCopy] = useState([]);
  const [liftLastServicedDate, setLiftLastServicedDate] = useState(null);
  const [liftLastServiceReport, setLiftLastServiceReport] = useState([]);

  const [dgPresent, setDgPresent] = useState(null)
  const [dgPurchaseInvoice, setDgPurchaseInvoice] = useState([]);
  const [dgPurchaseDate, setDgPurchaseDate] = useState(null);
  const [dgWarrantyPeriod, setDgWarrantyPeriod] = useState("");
  const [dgLessorLiabilityAMCStatus, setDgLessorLiabilityAMCStatus] = useState("");
  const [dgLessorLiabilityAMCMonths, setDgLessorLiabilityAMCMonths] = useState(null);
  const [dgAMCStartDate, setDgAMCStartDate] = useState(null)
  const [dgAMCEndDate, setDgAMCEndDate] = useState(null)
  const [dgAMCType, setDgAMCType] = useState("");
  const [dgAMCCopy, setDgAMCCopy] = useState([]);
  const [dgLastServicedDate, setDgLastServicedDate] = useState(null);
  const [dgLastServiceReport, setDgLastServiceReport] = useState([]);

  const [heatPumpPresent, setHeatPumpPresent] = useState(null)
  const [heatPumpGeysersPurchaseInvoice, setHeatPumpGeysersPurchaseInvoice] = useState([]);
  const [heatPumpGeysersPurchaseDate, setHeatPumpGeysersPurchaseDate] = useState(null);
  const [heatPumpGeysersWarrantyPeriod, setHeatPumpGeysersWarrantyPeriod] = useState("");
  const [heatPumpGeysersLessorLiabilityAMCStatus, setHeatPumpGeysersLessorLiabilityAMCStatus] = useState("");
  const [heatPumpGeysersLessorLiabilityAMCMonths, setHeatPumpGeysersLessorLiabilityAMCMonths] = useState(null);
  const [heatPumpAMCStartDate, setHeatPumpAMCStartDate] = useState(null)
  const [heatPumpAMCEndDate, setHeatPumpAMCEndDate] = useState(null)
  const [heatPumpGeysersAMCType, setHeatPumpGeysersAMCType] = useState("");
  const [heatPumpGeysersAMCCopy, setHeatPumpGeysersAMCCopy] = useState([]);
  const [heatPumpGeysersLastServicedDate, setHeatPumpGeysersLastServicedDate] = useState(null);
  const [heatPumpGeysersLastServiceReport, setHeatPumpGeysersLastServiceReport] = useState([]);

  const [airConditionerPresent, setAirConditionerPresent] = useState(null)
  const [AirConditionersPurchaseInvoice, setAirConditionersPurchaseInvoice] = useState([]);
  const [AirConditionersPurchaseDate, setAirConditionersPurchaseDate] = useState(null);
  const [AirConditionersWarrantyPeriod, setAirConditionersWarrantyPeriod] = useState("");
  const [AirConditionersLessorLiabilityAMCStatus, setAirConditionersLessorLiabilityAMCStatus] = useState("");
  const [AirConditionersLessorLiabilityAMCMonths, setAirConditionersLessorLiabilityAMCMonths,] = useState(null);
  const [airconditionerAMCStartDate, setAirConditionerAMCStartDate] = useState(null)
  const [airconditionerAMCEndDate, setAirConditionerAMCEndDate] = useState(null)
  const [AirConditionersAMCType, setAirConditionersAMCType] = useState("");
  const [AirConditionersAMCCopy, setAirConditionersAMCCopy] = useState([]);
  const [AirConditionersLastServicedDate, setAirConditionersLastServicedDate] = useState(null);
  const [AirConditionersLastServiceReport, setAirConditionersLastServiceReport,] = useState([]);

  const [kitchenEquipmentPresent, setKitchenEquipmentPresent] = useState(null)
  const [kitchenEquipPurchaseInvoice, setKitchenEquipPurchaseInvoice] = useState([]);
  const [kitchenEquipPurchaseDate, setKitchenEquipPurchaseDate] = useState(null);
  const [kitchenEquipWarrantyPeriod, setKitchenEquipWarrantyPeriod] = useState("");
  const [kitchenEquipLessorLiabilityAMCStatus, setKitchenEquipLessorLiabilityAMCStatus] = useState("");
  const [kitchenEquipLessorLiabilityAMCMonths, setKitchenEquipLessorLiabilityAMCMonths] = useState(null);
  const [kitchenEquipAMCStartDate, setKitchenEquipAMCStartDate] = useState(null)
  const [kitchenEquipAMCEndDate, setKitchenEquipAMCEndDate] = useState(null)
  const [kitchenEquipAMCType, setKitchenEquipAMCType] = useState("");
  const [kitchenEquipAMCCopy, setKitchenEquipAMCCopy] = useState([]);
  const [kitchenEquipLastServicedDate, setKitchenEquipLastServicedDate] = useState(null);
  const [kitchenEquipLastServiceReport, setKitchenEquipLastServiceReport] = useState([]);

  const [stpPresent, setStpPresent] = useState(null)
  const [stpPurchaseInvoice, setStpPurchaseInvoice] = useState([]);
  const [stpPurchaseDate, setStpPurchaseDate] = useState(null);
  const [stpWarrantyPeriod, setStpWarrantyPeriod] = useState("");
  const [stpLessorLiabilityAMCStatus, setStpLessorLiabilityAMCStatus] = useState("");
  const [stpLessorLiabilityAMCMonths, setStpLessorLiabilityAMCMonths] = useState(null);
  const [stpAMCStartDate, setStpAMCStartDate] = useState(null)
  const [stpAMCEndDate, setStpAMCEndDate] = useState(null)
  const [stpAMCType, setStpAMCType] = useState("");
  const [stpAMCCopy, setStpAMCCopy] = useState([]);
  const [stpLastServicedDate, setStpLastServicedDate] = useState(null);
  const [stpLastServiceReport, setStpLastServiceReport] = useState([]);

  const [toiletFittingPresent, setToiletFittingPresent] = useState(null)
  const [toiletFittingsPurchaseInvoice, setToiletFittingsPurchaseInvoice] = useState([]);
  const [toiletFittingsPurchaseDate, setToiletFittingsPurchaseDate] = useState(null);
  const [toiletFittingsWarrantyPeriod, setToiletFittingsWarrantyPeriod] = useState("");
  const [toiletFittingsLessorLiabilityAMCStatus, setToiletFittingsLessorLiabilityAMCStatus] = useState("");
  const [toiletFittingsLessorLiabilityAMCMonths, setToiletFittingsLessorLiabilityAMCMonths] = useState(null);
  const [toiletFittingsAMCStartDate, setToiletFittingAMCStartDate] = useState(null)
  const [toiletFittingsAMCEndDate, setToiletFittingAMCEndDate] = useState(null)
  const [toiletFittingsAMCType, setToiletFittingsAMCType] = useState("");
  const [toiletFittingsAMCCopy, setToiletFittingsAMCCopy] = useState([]);
  const [toiletFittingsLastServicedDate, setToiletFittingsLastServicedDate] = useState(null);
  const [toiletFittingsLastServiceReport, setToiletFittingsLastServiceReport] = useState([]);

  const [heavyEquipPurchaseInvoice, setHeavyEquipPurchaseInvoice] = useState([]);
  const [heavyEquipPurchaseDate, setHeavyEquipPurchaseDate] = useState(null);
  const [heavyEquipWarrantyPeriod, setHeavyEquipWarrantyPeriod] = useState("");
  const [heavyEquipLessorLiabilityAMCStatus, setHeavyEquipLessorLiabilityAMCStatus] = useState("");
  const [heavyEquipLessorLiabilityAMCMonths, setHeavyEquipLessorLiabilityAMCMonths] = useState(null);
  const [heavyEquipAMCType, setHeavyEquipAMCType] = useState("");
  const [heavyEquipAMCCopy, setHeavyEquipAMCCopy] = useState([]);
  const [heavyEquipLastServicedDate, setHeavyEquipLastServicedDate] = useState(null);
  const [heavyEquipLastServiceReport, setHeavyEquipLastServiceReport] = useState([]);

  //!Images state
  const [liftPurchaseInvoiceImageDummyState] = useState(null);
  const [liftAMCCopyImageDummyState] = useState(null);
  const [liftLastServiceReportImageDummyState] = useState(null);

  const [dgPurchaseInvoiceImageDummyState] = useState(null);
  const [dgAMCCopyImageDummyState] = useState(null);
  const [dgLastServiceReportImageDummyState] = useState(null);

  const [heatPumpGeysersPurchaseInvoiceImageDummyState] = useState(null);
  const [heatPumpGeysersAMCCopyImageDummyState] = useState(null);
  const [heatPumpGeysersLastServiceReportImageDummyState] = useState(null);

  const [AirConditionersPurchaseInvoiceImageDummyState] = useState(null);
  const [AirConditionersAMCCopyImageDummyState] = useState(null);
  const [AirConditionersLastServiceReportImageDummyState] = useState(null);

  const [kitchenEquipPurchaseInvoiceImageDummyState] = useState(null);
  const [kitchenEquipAMCCopyImageDummyState] = useState(null);
  const [kitchenEquipLastServiceReportImageDummyState] = useState(null);

  const [stpPurchaseInvoiceImageDummyState] = useState(null);
  const [stpAMCCopyImageDummyState] = useState(null);
  const [stpLastServiceReportImageDummyState] = useState(null);

  const [toiletFittingsPurchaseInvoiceImageDummyState] = useState(null);
  const [toiletFittingsAMCCopyImageDummyState] = useState(null);
  const [toiletFittingsLastServiceReportImageDummyState] = useState(null);

  const [heavyEquipPurchaseInvoiceImageDummyState] = useState(null);
  const [heavyEquipAMCCopyImageDummyState] = useState(null);
  const [heavyEquipLastServiceReportImageDummyState] = useState(null);


  const [addedAssets, setAddedAssets] = useState([])

  const uploadLiftPurchaseInvoiceImageType = [
    { label: "imageURL", value: "liftPurchaseInvoiceImage" },
  ];

  const uploadLiftAMCCopyImageType = [
    { label: "imageURL", value: "liftAMCCopyImage" },
  ];

  const uploadLiftLastServiceReportImageType = [
    { label: "imageURL", value: "liftLastServiceReportImage" },
  ];

  const uploadDgPurchaseInvoiceImageType = [
    { label: "imageURL", value: "dgPurchaseInvoiceImage" },
  ];

  const uploadDgAMCCopyImageType = [
    { label: "imageURL", value: "dgAMCCopyImage" },
  ];

  const uploadDgLastServiceReportImageType = [
    { label: "imageURL", value: "dgLastServiceReportImage" },
  ];

  const uploadHeatPumpGeysersPurchaseInvoiceImageType = [
    { label: "imageURL", value: "heatPumpGeysersPurchaseInvoiceImage" },
  ];

  const uploadHeatPumpGeysersAMCCopyImageType = [
    { label: "imageURL", value: "heatPumpGeysersAMCCopyImage" },
  ];

  const uploadHeatPumpGeysersLastServiceReportImageType = [
    { label: "imageURL", value: "heatPumpGeysersLastServiceReportImage" },
  ];

  const uploadAirConditionersPurchaseInvoiceImageType = [
    { label: "imageURL", value: "airConditionersPurchaseInvoiceImage" },
  ];

  const uploadAirConditionersAMCCopyImageType = [
    { label: "imageURL", value: "airConditionersAMCCopyImage" },
  ];

  const uploadAirConditionersLastServiceReportImageType = [
    { label: "imageURL", value: "airConditionersLastServiceReportImage" },
  ];

  const uploadKitchenEquipPurchaseInvoiceImageType = [
    { label: "imageURL", value: "kitchenEquipPurchaseInvoiceImage" },
  ];

  const uploadKitchenEquipAMCCopyImageType = [
    { label: "imageURL", value: "kitchenEquipAMCCopyImage" },
  ];

  const uploadKitchenEquipLastServiceReportImageType = [
    { label: "imageURL", value: "kitchenEquipLastServiceReportImage" },
  ];

  const uploadStpPurchaseInvoiceImageType = [
    { label: "imageURL", value: "stpPurchaseInvoiceImage" },
  ];

  const uploadStpAMCCopyImageType = [
    { label: "imageURL", value: "stpAMCCopyImage" },
  ];

  const uploadStpLastServiceReportImageType = [
    { label: "imageURL", value: "stpLastServiceReportImage" },
  ];

  const uploadToiletFittingsPurchaseInvoiceImageType = [
    { label: "imageURL", value: "toiletFittingsPurchaseInvoiceImage" },
  ];

  const uploadToiletFittingsAMCCopyImageType = [
    { label: "imageURL", value: "toiletFittingsAMCCopyImage" },
  ];

  const uploadToiletFittingsLastServiceReportImageType = [
    { label: "imageURL", value: "toiletFittingsLastServiceReportImage" },
  ];

  const uploadHeavyEquipPurchaseInvoiceImageType = [
    { label: "imageURL", value: "heavyEquipPurchaseInvoiceImage" },
  ];

  const uploadHeavyEquipAMCCopyImageType = [
    { label: "imageURL", value: "heavyEquipAMCCopyImage" },
  ];

  const uploadHeavyEquipLastServiceReportImageType = [
    { label: "imageURL", value: "heavyEquipLastServiceReportImage" },
  ];

  const state = useSelector((state) => state?.cms);
  const dbData = state?.tableData;

  const [updateSupplyHandler, { data: updatedSupplyData }] = useUpdateSupply();

  const editableFields = [
    "liftPresent",
    "liftPurchaseInvoice",
    "liftPurchaseDate",
    "liftWarrantyPeriod",
    "liftLessorLiabilityAMCStatus",
    "liftLessorLiabilityAMCMonths",
    "liftAMCStartDate",
    "liftAMCEndDate",
    "liftAMCType",
    "liftAMCCopy",
    "liftLastServicedDate",
    "liftLastServiceReport",
    "dgPresent",
    "dgPurchaseInvoice",
    "dgPurchaseDate",
    "dgWarrantyPeriod",
    "dgLessorLiabilityAMCStatus",
    "dgLessorLiabilityAMCMonths",
    "dgAMCStartDate",
    "dgAMCEndDate",
    "dgAMCType",
    "dgAMCCopy",
    "dgLastServicedDate",
    "dgLastServiceReport",
    "heatPumpPresent",
    "heatPumpGeysersPurchaseInvoice",
    "heatPumpGeysersPurchaseDate",
    "heatPumpGeysersWarrantyPeriod",
    "heatPumpGeysersLessorLiabilityAMCStatus",
    "heatPumpGeysersLessorLiabilityAMCMonths",
    "heatPumpAMCStartDate",
    "heatPumpAMCEndDate",
    "heatPumpGeysersAMCType",
    "heatPumpGeysersAMCCopy",
    "heatPumpGeysersLastServicedDate",
    "heatPumpGeysersLastServiceReport",
    "airConditionerPresent",
    "AirConditionersPurchaseInvoice",
    "AirConditionersPurchaseDate",
    "AirConditionersWarrantyPeriod",
    "AirConditionersLessorLiabilityAMCStatus",
    "AirConditionersLessorLiabilityAMCMonths",
    "airconditionerAMCStartDate",
    "airconditionerAMCEndDate",
    "AirConditionersAMCType",
    "AirConditionersAMCCopy",
    "AirConditionersLastServicedDate",
    "AirConditionersLastServiceReport",
    "kitchenEquipmentPresent",
    "kitchenEquipPurchaseInvoice",
    "kitchenEquipPurchaseDate",
    "kitchenEquipWarrantyPeriod",
    "kitchenEquipLessorLiabilityAMCStatus",
    "kitchenEquipLessorLiabilityAMCMonths",
    "kitchenEquipAMCStartDate",
    "kitchenEquipAMCEndDate",
    "kitchenEquipAMCType",
    "kitchenEquipAMCCopy",
    "kitchenEquipLastServicedDate",
    "kitchenEquipLastServiceReport",
    "stpPresent",
    "stpPurchaseInvoice",
    "stpPurchaseDate",
    "stpWarrantyPeriod",
    "stpLessorLiabilityAMCStatus",
    "stpLessorLiabilityAMCMonths",
    "stpAMCStartDate",
    "stpAMCEndDate",
    "stpAMCType",
    "stpAMCCopy",
    "stpLastServicedDate",
    "stpLastServiceReport",
    "toiletFittingPresent",
    "toiletFittingsPurchaseInvoice",
    "toiletFittingsPurchaseDate",
    "toiletFittingsWarrantyPeriod",
    "toiletFittingsLessorLiabilityAMCStatus",
    "toiletFittingsLessorLiabilityAMCMonths",
    "toiletFittingsAMCStartDate",
    "toiletFittingsAMCEndDate",
    "toiletFittingsAMCType",
    "toiletFittingsAMCCopy",
    "toiletFittingsLastServicedDate",
    "toiletFittingsLastServiceReport",
    "heavyEquipPurchaseInvoice",
    "heavyEquipPurchaseDate",
    "heavyEquipWarrantyPeriod",
    "heavyEquipLessorLiabilityAMCStatus",
    "heavyEquipLessorLiabilityAMCMonths",
    "heavyEquipAMCType",
    "heavyEquipAMCCopy",
    "heavyEquipLastServicedDate",
    "heavyEquipLastServiceReport",
  ];

  const isEditableByUser = (e) => {
    const fieldName = e;
    const userActualRole = localStorage.getItem("role")
    if (userActualRole === "CEO") {
      return true
    }
    if (userRole && editableFields.includes(fieldName)) {
      return true;
    }
    return false;
  };


  useEffect(() => {
    if (updatedSupplyData?.updateSupply?.status === true) {
      toaster("success", "Hostel details updated successfully");
    } else if (updatedSupplyData?.updateSupply?.status === false) {
      toaster("error", "Error: Hostel details update failed");
    }
  }, [updatedSupplyData]);

  useEffect(() => {
    if (dbData?._id) {
      dbData?.liftPresent !== null && setLiftPresent(
        {
          label: dbData?.liftPresent,
          value: dbData?.liftPresent,
        })

      setLiftLastServicedDate(dbData?.liftLastServicedDate);

      setLiftAMCCopy(dbData?.liftAMCCopy);

      setLiftWarrantyPeriod(
        dbData?.liftWarrantyPeriod !== null
          ? {
            label: dbData?.liftWarrantyPeriod,
            value: dbData?.liftWarrantyPeriod,
          }
          : ""
      );
      setLiftPurchaseInvoice(dbData?.liftPurchaseInvoice);
      setLiftPurchaseDate(dbData?.liftPurchaseDate);
      setLiftLessorLiabilityAMCStatus(
        dbData?.liftLessorLiabilityAMCStatus !== null
          ? {
            label: dbData?.liftLessorLiabilityAMCStatus,
            value: dbData?.liftLessorLiabilityAMCStatus,
          }
          : ""
      );
      setLiftAMCStartDate(dbData?.liftAMCStartDate)
      setLiftAMCEndDate(dbData?.liftAMCEndDate)
      // dbData?.liftLessorLiabilityAMCMonths &&
      setLiftLessorLiabilityAMCMonths(dbData?.liftLessorLiabilityAMCMonths);
      setLiftAMCType(
        dbData?.liftAMCType !== null
          ? {
            label: dbData?.liftAMCType,
            value: dbData?.liftAMCType,
          }
          : ""
      );
      setLiftLastServiceReport(dbData?.liftLastServiceReport);

      dbData?.dgPresent !== null && setDgPresent(
        {
          label: dbData?.dgPresent,
          value: dbData?.dgPresent,
        })
      setDgLastServicedDate(dbData?.dgLastServicedDate);

      setDgAMCCopy(dbData?.dgAMCCopy);

      setDgWarrantyPeriod(
        dbData?.dgWarrantyPeriod !== null
          ? {
            label: dbData?.dgWarrantyPeriod,
            value: dbData?.dgWarrantyPeriod,
          }
          : ""
      );
      setDgAMCStartDate(dbData?.dgAMCStartDate)
      setDgAMCEndDate(dbData?.dgAMCEndDate)
      setDgPurchaseInvoice(dbData?.dgPurchaseInvoice);
      setDgPurchaseDate(dbData?.dgPurchaseDate);
      setDgLessorLiabilityAMCStatus(
        dbData?.dgLessorLiabilityAMCStatus !== null
          ? {
            label: dbData?.dgLessorLiabilityAMCStatus,
            value: dbData?.dgLessorLiabilityAMCStatus,
          }
          : ""
      );

      setDgLessorLiabilityAMCMonths(dbData?.dgLessorLiabilityAMCMonths);
      setDgAMCType(
        dbData?.dgAMCType !== null
          ? {
            label: dbData?.dgAMCType,
            value: dbData?.dgAMCType,
          }
          : ""
      );
      setDgLastServiceReport(dbData?.dgLastServiceReport);

      dbData?.heatPumpPresent !== null && setHeatPumpPresent(
        {
          label: dbData?.heatPumpPresent,
          value: dbData?.heatPumpPresent,
        })
      setHeatPumpGeysersLastServicedDate(
        dbData?.heatPumpGeysersLastServicedDate
      );
      setHeatPumpAMCStartDate(dbData?.heatPumpAMCStartDate)
      setHeatPumpAMCEndDate(dbData?.heatPumpAMCEndDate)
      setHeatPumpGeysersAMCCopy(dbData?.heatPumpGeysersAMCCopy);

      setHeatPumpGeysersWarrantyPeriod(
        dbData?.heatPumpGeysersWarrantyPeriod !== null
          ? {
            label: dbData?.heatPumpGeysersWarrantyPeriod,
            value: dbData?.heatPumpGeysersWarrantyPeriod,
          }
          : ""
      );
      setHeatPumpGeysersPurchaseInvoice(dbData?.heatPumpGeysersPurchaseInvoice);
      setHeatPumpGeysersPurchaseDate(dbData?.heatPumpGeysersPurchaseDate);
      setHeatPumpGeysersLessorLiabilityAMCStatus(
        dbData?.heatPumpGeysersLessorLiabilityAMCStatus !== null
          ? {
            label: dbData?.heatPumpGeysersLessorLiabilityAMCStatus,
            value: dbData?.heatPumpGeysersLessorLiabilityAMCStatus,
          }
          : ""
      );

      setHeatPumpGeysersLessorLiabilityAMCMonths(
        dbData?.heatPumpGeysersLessorLiabilityAMCMonths
      );
      setHeatPumpGeysersAMCType(
        dbData?.heatPumpGeysersAMCType !== null
          ? {
            label: dbData?.heatPumpGeysersAMCType,
            value: dbData?.heatPumpGeysersAMCType,
          }
          : ""
      );
      setHeatPumpGeysersLastServiceReport(
        dbData?.heatPumpGeysersLastServiceReport
      );

      dbData?.airConditionerPresent !== null && setAirConditionerPresent(
        {
          label: dbData?.airConditionerPresent,
          value: dbData?.airConditionerPresent,
        })
      setAirConditionersLastServicedDate(
        dbData?.AirConditionersLastServicedDate
      );
      setAirConditionerAMCStartDate(dbData?.airconditionerAMCStartDate)
      setAirConditionerAMCEndDate(dbData?.airconditionerAMCEndDate)
      setAirConditionersAMCCopy(dbData?.AirConditionersAMCCopy);

      setAirConditionersWarrantyPeriod(
        dbData?.AirConditionersWarrantyPeriod !== null
          ? {
            label: dbData?.AirConditionersWarrantyPeriod,
            value: dbData?.AirConditionersWarrantyPeriod,
          }
          : ""
      );
      setAirConditionersPurchaseInvoice(dbData?.AirConditionersPurchaseInvoice);
      setAirConditionersPurchaseDate(dbData?.AirConditionersPurchaseDate);
      setAirConditionersLessorLiabilityAMCStatus(
        dbData?.AirConditionersLessorLiabilityAMCStatus !== null
          ? {
            label: dbData?.AirConditionersLessorLiabilityAMCStatus,
            value: dbData?.AirConditionersLessorLiabilityAMCStatus,
          }
          : ""
      );

      setAirConditionersLessorLiabilityAMCMonths(
        dbData?.AirConditionersLessorLiabilityAMCMonths
      );
      setAirConditionersAMCType(
        dbData?.AirConditionersAMCType !== null
          ? {
            label: dbData?.AirConditionersAMCType,
            value: dbData?.AirConditionersAMCType,
          }
          : ""
      );
      setAirConditionersLastServiceReport(
        dbData?.AirConditionersLastServiceReport
      );

      dbData?.kitchenEquipmentPresent !== null && setKitchenEquipmentPresent(
        {
          label: dbData?.kitchenEquipmentPresent,
          value: dbData?.kitchenEquipmentPresent,
        })
      setKitchenEquipAMCStartDate(dbData?.kitchenEquipAMCStartDate)
      setKitchenEquipAMCEndDate(dbData?.kitchenEquipAMCEndDate)
      setKitchenEquipLastServicedDate(dbData?.kitchenEquipLastServicedDate);

      setKitchenEquipAMCCopy(dbData?.kitchenEquipAMCCopy);

      setKitchenEquipWarrantyPeriod(
        dbData?.kitchenEquipWarrantyPeriod !== null
          ? {
            label: dbData?.kitchenEquipWarrantyPeriod,
            value: dbData?.kitchenEquipWarrantyPeriod,
          }
          : ""
      );
      setKitchenEquipPurchaseInvoice(dbData?.kitchenEquipPurchaseInvoice);
      setKitchenEquipPurchaseDate(dbData?.kitchenEquipPurchaseDate);
      setKitchenEquipLessorLiabilityAMCStatus(
        dbData?.kitchenEquipLessorLiabilityAMCStatus !== null
          ? {
            label: dbData?.kitchenEquipLessorLiabilityAMCStatus,
            value: dbData?.kitchenEquipLessorLiabilityAMCStatus,
          }
          : ""
      );

      setKitchenEquipLessorLiabilityAMCMonths(
        dbData?.kitchenEquipLessorLiabilityAMCMonths
      );
      setKitchenEquipAMCType(
        dbData?.kitchenEquipAMCType !== null
          ? {
            label: dbData?.kitchenEquipAMCType,
            value: dbData?.kitchenEquipAMCType,
          }
          : ""
      );
      setKitchenEquipLastServiceReport(dbData?.kitchenEquipLastServiceReport);

      dbData?.stpPresent !== null && setStpPresent(
        {
          label: dbData?.stpPresent,
          value: dbData?.stpPresent,
        })
      setStpAMCStartDate(dbData?.stpAMCStartDate)
      setStpAMCEndDate(dbData?.stpAMCEndDate)
      setStpAMCCopy(dbData?.stpPurchaseAMCCopy);

      setStpWarrantyPeriod(
        dbData?.stpWarrantyPeriod !== null
          ? {
            label: dbData?.stpWarrantyPeriod,
            value: dbData?.stpWarrantyPeriod,
          }
          : ""
      );
      setStpPurchaseInvoice(dbData?.stpPurchaseInvoice);
      setStpPurchaseDate(dbData?.stpPurchaseDate);
      setStpLessorLiabilityAMCStatus(
        dbData?.stpLessorLiabilityAMCStatus !== null
          ? {
            label: dbData?.stpLessorLiabilityAMCStatus,
            value: dbData?.stpLessorLiabilityAMCStatus,
          }
          : ""
      );

      setStpLessorLiabilityAMCMonths(dbData?.stpLessorLiabilityAMCMonths);
      setStpAMCType(
        dbData?.stpAMCType !== null
          ? {
            label: dbData?.stpAMCType,
            value: dbData?.stpAMCType,
          }
          : ""
      );
      setStpAMCCopy(dbData?.stpAMCCopy);
      setStpLastServicedDate(dbData?.stpLastServicedDate);
      setStpLastServiceReport(dbData?.stpLastServiceReport);

      dbData?.toiletFittingPresent !== null && setToiletFittingPresent(
        {
          label: dbData?.toiletFittingPresent,
          value: dbData?.toiletFittingPresent,
        })
      setToiletFittingAMCStartDate(dbData?.toiletFittingsAMCStartDate)
      setToiletFittingAMCEndDate(dbData?.toiletFittingsAMCEndDate)
      setToiletFittingsLastServicedDate(dbData?.toiletFittingsLastServicedDate);

      setToiletFittingsAMCCopy(dbData?.toiletFittingsAMCCopy);

      setToiletFittingsWarrantyPeriod(
        dbData?.toiletFittingsWarrantyPeriod !== null
          ? {
            label: dbData?.toiletFittingsWarrantyPeriod,
            value: dbData?.toiletFittingsWarrantyPeriod,
          }
          : ""
      );
      setToiletFittingsPurchaseInvoice(dbData?.toiletFittingsPurchaseInvoice);
      setToiletFittingsPurchaseDate(dbData?.toiletFittingsPurchaseDate);
      setToiletFittingsLessorLiabilityAMCStatus(
        dbData?.toiletFittingsLessorLiabilityAMCStatus !== null
          ? {
            label: dbData?.toiletFittingsLessorLiabilityAMCStatus,
            value: dbData?.toiletFittingsLessorLiabilityAMCStatus,
          }
          : ""
      );

      setToiletFittingsLessorLiabilityAMCMonths(
        dbData?.toiletFittingsLessorLiabilityAMCMonths
      );
      setToiletFittingsAMCType(
        dbData?.toiletFittingsAMCType !== null
          ? {
            label: dbData?.toiletFittingsAMCType,
            value: dbData?.toiletFittingsAMCType,
          }
          : ""
      );
      setToiletFittingsLastServiceReport(
        dbData?.toiletFittingsLastServiceReport
      );

      setHeavyEquipLastServicedDate(dbData?.heavyEquipLastServicedDate);

      setHeavyEquipAMCCopy(dbData?.heavyEquipAMCCopy);

      setHeavyEquipWarrantyPeriod(
        dbData?.heavyEquipWarrantyPeriod !== null
          ? {
            label: dbData?.heavyEquipWarrantyPeriod,
            value: dbData?.heavyEquipWarrantyPeriod,
          }
          : ""
      );
      setHeavyEquipPurchaseInvoice(dbData?.heavyEquipPurchaseInvoice);
      setHeavyEquipPurchaseDate(dbData?.heavyEquipPurchaseDate);
      setHeavyEquipLessorLiabilityAMCStatus(
        dbData?.heavyEquipLessorLiabilityAMCStatus !== null
          ? {
            label: dbData?.heavyEquipLessorLiabilityAMCStatus,
            value: dbData?.heavyEquipLessorLiabilityAMCStatus,
          }
          : ""
      );

      setHeavyEquipLessorLiabilityAMCMonths(
        dbData?.heavyEquipLessorLiabilityAMCMonths
      );
      setHeavyEquipAMCType(
        dbData?.heavyEquipAMCType !== null
          ? {
            label: dbData?.heavyEquipAMCType,
            value: dbData?.heavyEquipAMCType,
          }
          : ""
      );
      setHeavyEquipLastServiceReport(dbData?.heavyEquipLastServiceReport);
      const updatedAddedAssets = dbData?.addedAssets?.map((item) => {
        return (
          {
            ...item,
            warrantyPeriod: { label: item?.warrantyPeriod, value: item?.warrantyPeriod },
            amcStatus: { label: item?.amcStatus, value: item?.amcStatus },
            amcType: { label: item?.amcType, value: item?.amcType },
          }
        )
      })
      setAddedAssets(updatedAddedAssets)
    } else {
      setLiftLastServicedDate(null);
      setLiftAMCCopy([]);
      setLiftWarrantyPeriod("");
      setLiftPurchaseInvoice([]);
      setLiftPurchaseDate(null);
      setLiftLessorLiabilityAMCStatus("");
      setLiftLessorLiabilityAMCMonths("");
      setLiftAMCType("");
      setLiftLastServiceReport([]);

      setDgLastServicedDate(null);
      setDgAMCCopy([]);
      setDgWarrantyPeriod("");
      setDgPurchaseInvoice([]);
      setDgPurchaseDate(null);
      setDgLessorLiabilityAMCStatus("");
      setDgLessorLiabilityAMCMonths("");
      setDgAMCType("");
      setDgLastServiceReport([]);

      setHeatPumpGeysersLastServicedDate(null);
      setHeatPumpGeysersAMCCopy([]);
      setHeatPumpGeysersWarrantyPeriod("");
      setHeatPumpGeysersPurchaseInvoice([]);
      setHeatPumpGeysersPurchaseDate(null);
      setHeatPumpGeysersLessorLiabilityAMCStatus("");
      setHeatPumpGeysersLessorLiabilityAMCMonths("");
      setHeatPumpGeysersAMCType("");
      setHeatPumpGeysersLastServiceReport([]);

      setAirConditionersLastServicedDate(null);
      setAirConditionersAMCCopy([]);
      setAirConditionersWarrantyPeriod("");
      setAirConditionersPurchaseInvoice([]);
      setAirConditionersPurchaseDate(null);
      setAirConditionersLessorLiabilityAMCStatus("");
      setAirConditionersLessorLiabilityAMCMonths("");
      setAirConditionersAMCType("");
      setAirConditionersLastServiceReport([]);

      setKitchenEquipLastServicedDate(null);
      setKitchenEquipAMCCopy([]);
      setKitchenEquipWarrantyPeriod("");
      setKitchenEquipPurchaseInvoice([]);
      setKitchenEquipPurchaseDate(null);
      setKitchenEquipLessorLiabilityAMCStatus("");
      setKitchenEquipLessorLiabilityAMCMonths("");
      setKitchenEquipAMCType("");
      setKitchenEquipLastServiceReport([]);

      setStpLastServicedDate(null);
      setStpAMCCopy([]);
      setStpWarrantyPeriod("");
      setStpPurchaseInvoice([]);
      setStpPurchaseDate(null);
      setStpLessorLiabilityAMCStatus("");
      setStpLessorLiabilityAMCMonths("");
      setStpAMCType("");
      setStpLastServiceReport([]);

      setToiletFittingsLastServicedDate(null);
      setToiletFittingsAMCCopy([]);
      setToiletFittingsWarrantyPeriod("");
      setToiletFittingsPurchaseInvoice([]);
      setToiletFittingsPurchaseDate(null);
      setToiletFittingsLessorLiabilityAMCStatus("");
      setToiletFittingsLessorLiabilityAMCMonths("");
      setToiletFittingsAMCType("");
      setToiletFittingsLastServiceReport([]);

      setHeavyEquipLastServicedDate(null);
      setHeavyEquipAMCCopy([]);
      setHeavyEquipWarrantyPeriod("");
      setHeavyEquipPurchaseInvoice([]);
      setHeavyEquipPurchaseDate(null);
      setHeavyEquipLessorLiabilityAMCStatus("");
      setHeavyEquipLessorLiabilityAMCMonths("");
      setHeavyEquipAMCType("");
      setHeavyEquipLastServiceReport([]);
      setAddedAssets(null)
    }
  }, [dbData]);
  const submitHandler = (e) => {
    e.preventDefault();
    const formattedAssetsList = addedAssets?.map((item) => {
      return {
        ...item,
        amcStatus: item.amcStatus?.value,
        warrantyPeriod: item.warrantyPeriod?.value,
        amcMonths: item?.amcMonths !== null ? Number(item?.amcMonths) : null,
        amcType: item?.amcType?.value,
      };
    });
    let data = {
      liftPresent: liftPresent?.value,
      liftPurchaseInvoice,
      liftPurchaseDate,
      liftWarrantyPeriod: liftWarrantyPeriod?.value,
      liftLessorLiabilityAMCStatus: liftLessorLiabilityAMCStatus?.value,
      liftAMCStartDate,
      liftAMCEndDate,
      liftLessorLiabilityAMCMonths: liftLessorLiabilityAMCMonths ? Number(liftLessorLiabilityAMCMonths) : null,
      liftAMCType: liftAMCType?.value,
      liftAMCCopy,
      liftLastServicedDate,
      liftLastServiceReport,

      dgPresent: dgPresent?.value,
      dgPurchaseInvoice,
      dgPurchaseDate,
      dgWarrantyPeriod: dgWarrantyPeriod?.value,
      dgLessorLiabilityAMCStatus: dgLessorLiabilityAMCStatus?.value,
      dgAMCStartDate,
      dgAMCEndDate,
      dgLessorLiabilityAMCMonths: dgLessorLiabilityAMCMonths ? Number(dgLessorLiabilityAMCMonths) : null,
      dgAMCType: dgAMCType?.value,
      dgAMCCopy,
      dgLastServicedDate,
      dgLastServiceReport,

      heatPumpPresent: heatPumpPresent?.value,
      heatPumpGeysersPurchaseInvoice,
      heatPumpGeysersPurchaseDate,
      heatPumpGeysersWarrantyPeriod: heatPumpGeysersWarrantyPeriod?.value,
      heatPumpGeysersLessorLiabilityAMCStatus: heatPumpGeysersLessorLiabilityAMCStatus?.value,
      heatPumpAMCStartDate,
      heatPumpAMCEndDate,
      heatPumpGeysersLessorLiabilityAMCMonths: heatPumpGeysersLessorLiabilityAMCMonths ? Number(heatPumpGeysersLessorLiabilityAMCMonths) : null,
      heatPumpGeysersAMCType: heatPumpGeysersAMCType?.value,
      heatPumpGeysersAMCCopy,
      heatPumpGeysersLastServicedDate,
      heatPumpGeysersLastServiceReport,

      airConditionerPresent: airConditionerPresent?.value,
      AirConditionersPurchaseInvoice,
      AirConditionersPurchaseDate,
      AirConditionersWarrantyPeriod: AirConditionersWarrantyPeriod?.value,
      AirConditionersLessorLiabilityAMCStatus: AirConditionersLessorLiabilityAMCStatus?.value,
      airconditionerAMCStartDate,
      airconditionerAMCEndDate,
      AirConditionersLessorLiabilityAMCMonths: AirConditionersLessorLiabilityAMCMonths ? Number(AirConditionersLessorLiabilityAMCMonths) : null,
      AirConditionersAMCType: AirConditionersAMCType?.value,
      AirConditionersAMCCopy,
      AirConditionersLastServicedDate,
      AirConditionersLastServiceReport,

      kitchenEquipmentPresent: kitchenEquipmentPresent?.value,
      kitchenEquipPurchaseInvoice,
      kitchenEquipPurchaseDate,
      kitchenEquipWarrantyPeriod: kitchenEquipWarrantyPeriod?.value,
      kitchenEquipLessorLiabilityAMCStatus: kitchenEquipLessorLiabilityAMCStatus?.value,
      kitchenEquipAMCStartDate,
      kitchenEquipAMCEndDate,
      kitchenEquipLessorLiabilityAMCMonths: kitchenEquipLessorLiabilityAMCMonths ? Number(kitchenEquipLessorLiabilityAMCMonths) : null,
      kitchenEquipAMCType: kitchenEquipAMCType?.value,
      kitchenEquipAMCCopy,
      kitchenEquipLastServicedDate,
      kitchenEquipLastServiceReport,

      stpPresent: stpPresent?.value,
      stpPurchaseInvoice,
      stpPurchaseDate,
      stpWarrantyPeriod: stpWarrantyPeriod?.value,
      stpLessorLiabilityAMCStatus: stpLessorLiabilityAMCStatus?.value,
      stpAMCStartDate,
      stpAMCEndDate,
      stpLessorLiabilityAMCMonths: stpLessorLiabilityAMCMonths ? Number(stpLessorLiabilityAMCMonths) : null,
      stpAMCType: stpAMCType?.value,
      stpAMCCopy,
      stpLastServicedDate,
      stpLastServiceReport,

      toiletFittingPresent: toiletFittingPresent?.value,
      toiletFittingsPurchaseInvoice,
      toiletFittingsPurchaseDate,
      toiletFittingsWarrantyPeriod: toiletFittingsWarrantyPeriod?.value,
      toiletFittingsLessorLiabilityAMCStatus: toiletFittingsLessorLiabilityAMCStatus?.value,
      toiletFittingsAMCStartDate,
      toiletFittingsAMCEndDate,
      toiletFittingsLessorLiabilityAMCMonths: toiletFittingsLessorLiabilityAMCMonths ? Number(toiletFittingsLessorLiabilityAMCMonths) : null,
      toiletFittingsAMCType: toiletFittingsAMCType?.value,
      toiletFittingsAMCCopy,
      toiletFittingsLastServicedDate,
      toiletFittingsLastServiceReport,

      // heavyEquipPurchaseInvoice,
      // heavyEquipPurchaseDate,
      // heavyEquipWarrantyPeriod: heavyEquipWarrantyPeriod?.value,
      // heavyEquipLessorLiabilityAMCStatus:
      //   heavyEquipLessorLiabilityAMCStatus?.value,
      // heavyEquipLessorLiabilityAMCMonths: Number(
      //   heavyEquipLessorLiabilityAMCMonths
      // ),
      // heavyEquipAMCType: heavyEquipAMCType?.value,
      // heavyEquipAMCCopy,
      // heavyEquipLastServicedDate,
      // heavyEquipLastServiceReport,
      addedAssets: formattedAssetsList,
    };

    console.log(data)
    updateSupplyHandler(id, data);

    dispatch(
      tableDataHandler({
        ...state?.tableData,
        ...data,
      })
    );
  };
  console.log(addedAssets)
  const calculateRemainingFields = () => {
    let remainingCount = 0;
    const fields = [
      liftPresent,
      dgPresent,
      heatPumpPresent,
      airConditionerPresent,
      kitchenEquipmentPresent,
      stpPresent,
      toiletFittingPresent,
    ]

    if (liftPresent?.value === "Yes") fields.push(liftPurchaseDate, liftWarrantyPeriod, liftLessorLiabilityAMCStatus, liftLastServicedDate, liftPurchaseInvoice, liftLastServiceReport)
    if (dgPresent?.value === "Yes") fields.push(dgPurchaseDate, dgWarrantyPeriod, dgLessorLiabilityAMCStatus, dgLastServicedDate, dgPurchaseInvoice, dgLastServiceReport)
    if (heatPumpPresent?.value === "Yes") fields.push(heatPumpGeysersPurchaseDate, heatPumpGeysersWarrantyPeriod, heatPumpGeysersLessorLiabilityAMCStatus, heatPumpGeysersLastServicedDate, heatPumpGeysersPurchaseInvoice, heatPumpGeysersLastServiceReport)
    if (airConditionerPresent?.value === "Yes") fields.push(AirConditionersPurchaseDate, AirConditionersWarrantyPeriod, AirConditionersLessorLiabilityAMCStatus, AirConditionersLastServicedDate, AirConditionersPurchaseInvoice, AirConditionersLastServiceReport)
    if (kitchenEquipmentPresent?.value === "Yes") fields.push(kitchenEquipPurchaseDate, kitchenEquipWarrantyPeriod, kitchenEquipLessorLiabilityAMCStatus, kitchenEquipLastServicedDate, kitchenEquipPurchaseInvoice, kitchenEquipLastServiceReport)
    if (stpPresent?.value === "Yes") fields.push(stpPurchaseDate, stpWarrantyPeriod, stpLessorLiabilityAMCStatus, stpLastServicedDate, stpPurchaseInvoice, stpLastServiceReport)
    if (toiletFittingPresent?.value === "Yes") fields.push(toiletFittingsPurchaseDate, toiletFittingsWarrantyPeriod, toiletFittingsLessorLiabilityAMCStatus, toiletFittingsLastServicedDate, toiletFittingsPurchaseInvoice, toiletFittingsLastServiceReport)


    if (liftLessorLiabilityAMCStatus?.value === "Yes") fields?.push(liftAMCStartDate, liftAMCEndDate, liftLessorLiabilityAMCMonths, liftAMCType, liftAMCCopy)
    if (dgLessorLiabilityAMCStatus?.value === "Yes") fields?.push(dgAMCStartDate, dgAMCEndDate, dgLessorLiabilityAMCMonths, dgAMCType, dgAMCCopy)
    if (heatPumpGeysersLessorLiabilityAMCStatus?.value === "Yes") fields?.push(heatPumpAMCStartDate, heatPumpAMCEndDate, heatPumpGeysersLessorLiabilityAMCMonths, heatPumpGeysersAMCType, heatPumpGeysersAMCCopy)
    if (AirConditionersLessorLiabilityAMCStatus?.value === "Yes") fields?.push(airconditionerAMCStartDate, airconditionerAMCEndDate, AirConditionersLessorLiabilityAMCMonths, AirConditionersAMCType, AirConditionersAMCCopy)
    if (kitchenEquipLessorLiabilityAMCStatus?.value === "Yes") fields?.push(kitchenEquipAMCStartDate, kitchenEquipAMCEndDate, kitchenEquipLessorLiabilityAMCMonths, kitchenEquipAMCType, kitchenEquipAMCCopy)
    if (stpLessorLiabilityAMCStatus?.value === "Yes") fields?.push(stpAMCStartDate, stpAMCEndDate, stpLessorLiabilityAMCMonths, stpAMCType, stpAMCCopy)
    if (toiletFittingsLessorLiabilityAMCStatus?.value === "Yes") fields?.push(toiletFittingsAMCStartDate, toiletFittingsAMCEndDate, toiletFittingsLessorLiabilityAMCMonths, toiletFittingsAMCType, liftAMCCopy)
    fields.forEach(field => {
      if (typeof field === "object") {
        if (field?.[0]?.imageURL === null) {
          remainingCount++;
        } else if (field?.label === undefined && !field?.[0]?.imageURL) {
          if (!(field instanceof Date)) {
            remainingCount++;
          }
        }
      } else {
        if (field === null || field === "" || field === undefined || field.length === 0) {
          remainingCount++;
        }
      }

    });
    setRemainingCount(`(${remainingCount} fields pending / ${fields.length} )`)
    return `${remainingCount}/${fields.length}`;
  };
  calculateRemainingFields()

  const handleAddAsset = () => {

    const newAsset = {
      uuId: uuidv4(),
      assetName: "",
      uploadPurchaseInvoice: [],
      purchaseDate: null,
      warrantyPeriod: null,
      amcStatus: null,
      amcStartDate: null,
      amcEndDate: null,
      amcMonths: null,
      amcType: null,
      amcInvoice: [],
      lastServiceDate: null,
      lastServiceReport: []

    };

    setAddedAssets(prevAssets => [...prevAssets, newAsset]);
  };

  const handleRemoveAsset = (uuid) => {
    setAddedAssets(prevAssets => prevAssets.filter(asset => asset.uuId !== uuid));
  };


  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row >
        <Col sm="12">
          <FormGroup className="w-100">
            <Col>
              <Row>
                <Col sm="3">
                  <strong>
                    <Label className="form-label" for="liftPresent" >
                      Lift
                    </Label>
                  </strong>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="liftPresent"
                    name="liftPresent"
                    options={YesNoDropdownOptions}
                    value={liftPresent}
                    onChange={(value) => { setLiftPresent(value) }}
                    classNamePrefix="select"
                    isDisabled={!isEditableByUser("liftPresent")}
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !liftPresent ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </Col>
                {
                  liftPresent?.value === "Yes" &&
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="liftPurchaseDate">
                        Purchase date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white text-dark "
                        value={liftPurchaseDate}
                        onChange={(date) => {
                          isEditableByUser("liftPurchaseDate") &&
                            setLiftPurchaseDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="liftPurchaseDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !liftPurchaseDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>
                }
                {
                  liftPresent?.value === "Yes" &&
                  <Col sm="3">
                    <Label className="form-label" for="liftPurchaseInvoic">
                      Upload purchase invoice
                    </Label>
                    <ImageModal
                      imageList={liftPurchaseInvoice}
                      setImageList={setLiftPurchaseInvoice}
                      editData={liftPurchaseInvoiceImageDummyState}
                      uploadType={uploadLiftPurchaseInvoiceImageType}
                      canBeEdited={isEditableByUser("liftPurchaseInvoice")}
                      fieldName={"Upload purchase"}
                    />
                  </Col>
                }


              </Row>
            </Col>

            {
              liftPresent?.value == "Yes" &&
              <Col sm="12">
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="liftWarrantyPeriod ">
                        Warranty period
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="liftWarrantyPeriod "
                        name="liftWarrantyPeriod "
                        options={WarrantyPeriodTypeList}
                        value={liftWarrantyPeriod}
                        onChange={(value) => {
                          isEditableByUser("liftWarrantyPeriod") &&
                            setLiftWarrantyPeriod(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !liftWarrantyPeriod ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="liftLessorLiabilityAMCStatus "
                      >
                        AMC status
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="liftLessorLiabilityAMCStatus "
                        name="liftLessorLiabilityAMCStatus "
                        options={CafeList}
                        value={liftLessorLiabilityAMCStatus}
                        onChange={(value) => {
                          isEditableByUser("liftLessorLiabilityAMCStatus") &&
                            setLiftLessorLiabilityAMCStatus(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !liftLessorLiabilityAMCStatus ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {liftLessorLiabilityAMCStatus?.value === "Yes" && (
                    <>

                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="liftAMCStartDate">
                            AMC start date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={liftAMCStartDate}
                            onChange={(date) => {
                              isEditableByUser("liftAMCStartDate") &&
                                setLiftAMCStartDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="liftAMCStartDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !liftAMCStartDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="liftAMCEndDate">
                            AMC end date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={liftAMCEndDate}
                            onChange={(date) => {
                              isEditableByUser("liftAMCEndDate") &&
                                setLiftAMCEndDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="liftAMCEndDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !liftAMCEndDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label">AMC months</Label>
                          {/* </strong> */}
                          <Input
                            type="text"
                            value={liftLessorLiabilityAMCMonths}
                            id="liftLessorLiabilityAMCMonths"
                            name="liftLessorLiabilityAMCMonths"
                            //placeholder="Add lift lessor liability AMC months"
                            onChange={(e) => {
                              isEditableByUser(e.target.name) &&
                                setLiftLessorLiabilityAMCMonths(e.target.value);
                            }}
                            disabled={!userRole}
                            style={{
                              borderColor: !liftLessorLiabilityAMCMonths && 'red',
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label" for="liftAMCType ">
                            AMC type
                          </Label>
                          {/* </strong> */}
                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id="liftAMCType "
                            name="liftAMCType "
                            options={AMCTypeList}
                            value={liftAMCType}
                            onChange={(value) => {
                              isEditableByUser("liftAMCType") &&
                                setLiftAMCType(value);
                            }}
                            classNamePrefix="select"
                            placeholder=""
                            isDisabled={!userRole}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderColor: !liftAMCType ? 'red' : provided.borderColor,
                              }),
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <Label className="form-label" for="liftAMCCopy">
                          Upload AMC invoice
                        </Label>
                        <ImageModal
                          imageList={liftAMCCopy}
                          setImageList={setLiftAMCCopy}
                          editData={liftAMCCopyImageDummyState}
                          uploadType={uploadLiftAMCCopyImageType}
                          canBeEdited={isEditableByUser("liftAMCCopy")}
                          fieldName={"AMC invoice"}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="expiry date">
                        Last serviced date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={liftLastServicedDate}
                        onChange={(date) => {
                          isEditableByUser("liftLastServicedDate") &&
                            setLiftLastServicedDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="liftLastServicedDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !liftLastServicedDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    <Label className="form-label" for="liftLastServiceReport">
                      Last service report
                    </Label>
                    <ImageModal
                      imageList={liftLastServiceReport}
                      setImageList={setLiftLastServiceReport}
                      editData={liftLastServiceReportImageDummyState}
                      uploadType={uploadLiftLastServiceReportImageType}
                      canBeEdited={isEditableByUser("liftLastServiceReport")}
                      fieldName={"Last service report"}
                    />
                  </Col>
                </Row>
              </Col>
            }
          </FormGroup>
        </Col>
        <hr
          style={{
            borderTop: "2px solid #ffe700",
            width: "100%",
          }}
        />
        <Col sm="12">
          <FormGroup className="w-100">
            <Col>
              <Row>
                <Col sm="3">
                  <strong>
                    <Label className="form-label" for="dgPresent" >
                      DG
                    </Label>
                  </strong>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="dgPresent"
                    name="dgPresent"
                    options={YesNoDropdownOptions}
                    value={dgPresent}
                    onChange={(value) => { setDgPresent(value) }}
                    isDisabled={!isEditableByUser("dgPresent")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !dgPresent ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </Col>
                {
                  dgPresent?.value === "Yes" &&
                  <Col sm="3">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="dgPurchaseDate">
                        Purchase date
                      </Label>
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={dgPurchaseDate}
                        onChange={(date) => {
                          isEditableByUser("dgPurchaseDate") &&
                            setDgPurchaseDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="dgPurchaseDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !dgPurchaseDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>
                }
                {
                  dgPresent?.value === "Yes" &&
                  <Col sm="3">
                    <Label className="form-label" for="floodgPurchaseInvoicerPlans">
                      Upload purchase invoice
                    </Label>
                    <ImageModal
                      imageList={dgPurchaseInvoice}
                      setImageList={setDgPurchaseInvoice}
                      editData={dgPurchaseInvoiceImageDummyState}
                      uploadType={uploadDgPurchaseInvoiceImageType}
                      canBeEdited={isEditableByUser("dgPurchaseInvoice")}
                      fieldName={"Purchase invoice"}
                    />
                  </Col>
                }
              </Row>
            </Col>

            {
              dgPresent?.value === "Yes" &&
              <Col>
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="dgWarrantyPeriod ">
                        Warranty period
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="dgWarrantyPeriod "
                        name="dgWarrantyPeriod "
                        options={WarrantyPeriodTypeList}
                        value={dgWarrantyPeriod}
                        onChange={(value) => {
                          isEditableByUser("dgWarrantyPeriod") &&
                            setDgWarrantyPeriod(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !dgWarrantyPeriod ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="dgLessorLiabilityAMCStatus "
                      >
                        AMC status
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="dgLessorLiabilityAMCStatus "
                        name="dgLessorLiabilityAMCStatus "
                        options={CafeList}
                        value={dgLessorLiabilityAMCStatus}
                        onChange={(value) => {
                          isEditableByUser("dgLessorLiabilityAMCStatus") &&
                            setDgLessorLiabilityAMCStatus(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !dgLessorLiabilityAMCStatus ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {dgLessorLiabilityAMCStatus?.value === "Yes" && (
                    <>

                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="dgAMCStartDate">
                            AMC start date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={dgAMCStartDate}
                            onChange={(date) => {
                              isEditableByUser("dgAMCStartDate") &&
                                setDgAMCStartDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="dgAMCStartDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !dgAMCStartDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="dgAMCEndDate">
                            AMC end date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={dgAMCEndDate}
                            onChange={(date) => {
                              isEditableByUser("dgAMCEndDate") &&
                                setDgAMCEndDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="dgAMCEndDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !dgAMCEndDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3    ">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label">AMC months</Label>
                          {/* </strong> */}
                          <Input
                            type="text"
                            value={dgLessorLiabilityAMCMonths}
                            id="dgLessorLiabilityAMCMonths"
                            name="dgLessorLiabilityAMCMonths"
                            //placeholder="Add DG lessor liability AMC months"
                            onChange={(e) => {
                              isEditableByUser(e.target.name) &&
                                setDgLessorLiabilityAMCMonths(e.target.value);
                            }}
                            disabled={!userRole}
                            style={{
                              borderColor: !dgLessorLiabilityAMCMonths && 'red',
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label" for="dgAMCType ">
                            AMC type
                          </Label>
                          {/* </strong> */}
                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id="dgAMCType "
                            name="dgAMCType "
                            options={AMCTypeList}
                            value={dgAMCType}
                            onChange={(value) => {
                              isEditableByUser("dgAMCType") && setDgAMCType(value);
                            }}
                            classNamePrefix="select"
                            placeholder=""
                            isDisabled={!userRole}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderColor: !dgAMCType ? 'red' : provided.borderColor,
                              }),
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        {/* <strong> */}{" "}
                        <Label className="form-label" for="dgAMCCopy">
                          Upload AMC invoice
                        </Label>
                        {/* </strong> */}
                        <ImageModal
                          imageList={dgAMCCopy}
                          setImageList={setDgAMCCopy}
                          editData={dgAMCCopyImageDummyState}
                          uploadType={uploadDgAMCCopyImageType}
                          canBeEdited={isEditableByUser("dgAMCCopy")}
                          fieldName={"AMC invoice"}
                        />
                      </Col>
                    </>
                  )}

                </Row>
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="dgLastServicedDate">
                        Last serviced date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={dgLastServicedDate}
                        onChange={(date) => {
                          isEditableByUser("dgLastServicedDate") &&
                            setDgLastServicedDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="dgLastServicedDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !dgLastServicedDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="dgLastServiceReport">
                      Last service Report
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={dgLastServiceReport}
                      setImageList={setDgLastServiceReport}
                      editData={dgLastServiceReportImageDummyState}
                      uploadType={uploadDgLastServiceReportImageType}
                      canBeEdited={isEditableByUser("dgLastServiceReport")}
                      fieldName={"Last service Report"}
                    />
                  </Col>
                </Row>
              </Col>

            }
          </FormGroup>
        </Col>
        <hr
          style={{
            borderTop: "2px solid #ffe700",
            width: "100%",
          }}
        />
        <Col sm="12">
          <FormGroup className="w-100">
            <Col>
              <Row>
                <Col sm="3">
                  <strong>
                    <Label className="form-label" for="heatPumpPresent" >
                      Heatpump / Geysers
                    </Label>
                  </strong>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="heatPumpPresent"
                    name="heatPumpPresent"
                    options={YesNoDropdownOptions}
                    value={heatPumpPresent}
                    onChange={(value) => { setHeatPumpPresent(value) }}
                    isDisabled={!isEditableByUser("heatPumpPresent")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !heatPumpPresent ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </Col>
                {
                  heatPumpPresent?.value === "Yes" &&
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="heatPumpGeysersPurchaseDate"
                      >
                        Purchase date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={heatPumpGeysersPurchaseDate}
                        onChange={(date) => {
                          isEditableByUser("heatPumpGeysersPurchaseDate") &&
                            setHeatPumpGeysersPurchaseDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="heatPumpGeysersPurchaseDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !heatPumpGeysersPurchaseDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>
                }
                {
                  heatPumpPresent?.value === "Yes" &&
                  <Col sm="3">
                    {/* <strong> */}{" "}
                    <Label
                      className="form-label"
                      for="heatPumpGeysersPurchaseInvoice"
                    >
                      Upload purchase invoice
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={heatPumpGeysersPurchaseInvoice}
                      setImageList={setHeatPumpGeysersPurchaseInvoice}
                      editData={heatPumpGeysersPurchaseInvoiceImageDummyState}
                      uploadType={uploadHeatPumpGeysersPurchaseInvoiceImageType}
                      canBeEdited={isEditableByUser(
                        "heatPumpGeysersPurchaseInvoice"
                      )}
                      fieldName={"Purchase invoice"}
                    />
                  </Col>
                }
              </Row>
            </Col>

            {
              heatPumpPresent?.value === "Yes" &&
              <Col>
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="heatPumpGeysersWarrantyPeriod "
                      >
                        Warranty period
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="heatPumpGeysersWarrantyPeriod "
                        name="heatPumpGeysersWarrantyPeriod "
                        options={WarrantyPeriodTypeList}
                        value={heatPumpGeysersWarrantyPeriod}
                        onChange={(value) => {
                          isEditableByUser("heatPumpGeysersWarrantyPeriod") &&
                            setHeatPumpGeysersWarrantyPeriod(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !heatPumpGeysersWarrantyPeriod ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="heatPumpGeysersLessorLiabilityAMCStatus "
                      >
                        AMC status
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="heatPumpGeysersLessorLiabilityAMCStatus "
                        name="heatPumpGeysersLessorLiabilityAMCStatus "
                        options={CafeList}
                        value={heatPumpGeysersLessorLiabilityAMCStatus}
                        onChange={(value) => {
                          isEditableByUser(
                            "heatPumpGeysersLessorLiabilityAMCStatus"
                          ) && setHeatPumpGeysersLessorLiabilityAMCStatus(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !heatPumpGeysersLessorLiabilityAMCStatus ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {heatPumpGeysersLessorLiabilityAMCStatus?.value === "Yes" && (
                    <>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="heatPumpAMCStartDate">
                            AMC start date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={heatPumpAMCStartDate}
                            onChange={(date) => {
                              isEditableByUser("heatPumpAMCStartDate") &&
                                setHeatPumpAMCStartDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="heatPumpAMCStartDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !heatPumpAMCStartDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="heatPumpAMCEndDate">
                            AMC end date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={heatPumpAMCEndDate}
                            onChange={(date) => {
                              isEditableByUser("heatPumpAMCEndDate") &&
                                setHeatPumpAMCEndDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="heatPumpAMCEndDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !heatPumpAMCEndDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3    ">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label">AMC months</Label>
                          {/* </strong> */}
                          <Input
                            type="text"
                            value={heatPumpGeysersLessorLiabilityAMCMonths}
                            id="heatPumpGeysersLessorLiabilityAMCMonths"
                            name="heatPumpGeysersLessorLiabilityAMCMonths"
                            //placeholder="Add heatPumpGeysers AMC months"
                            onChange={(e) => {
                              isEditableByUser(e.target.name) &&
                                setHeatPumpGeysersLessorLiabilityAMCMonths(
                                  e.target.value
                                );
                            }}
                            disabled={!userRole}
                            style={{
                              borderColor: !heatPumpGeysersLessorLiabilityAMCMonths && 'red',
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label
                            className="form-label"
                            for="heatPumpGeysersAMCType "
                          >
                            AMC type*
                          </Label>
                          {/* </strong> */}
                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id="heatPumpGeysersAMCType "
                            name="heatPumpGeysersAMCType "
                            options={AMCTypeList}
                            value={heatPumpGeysersAMCType}
                            onChange={(value) => {
                              isEditableByUser("heatPumpGeysersAMCType") &&
                                setHeatPumpGeysersAMCType(value);
                            }}
                            classNamePrefix="select"
                            placeholder=""
                            isDisabled={!userRole}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderColor: !heatPumpGeysersAMCType ? 'red' : provided.borderColor,
                              }),
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        {/* <strong> */}{" "}
                        <Label className="form-label" for="heatPumpGeysersAMCCopy">
                          Upload AMC invoice
                        </Label>
                        {/* </strong> */}
                        <ImageModal
                          imageList={heatPumpGeysersAMCCopy}
                          setImageList={setHeatPumpGeysersAMCCopy}
                          editData={heatPumpGeysersAMCCopyImageDummyState}
                          uploadType={uploadHeatPumpGeysersAMCCopyImageType}
                          canBeEdited={isEditableByUser("heatPumpGeysersAMCCopy")}
                          fieldName={"AMC invoice"}
                        />
                      </Col>
                    </>
                  )}

                </Row>
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="heatPumpGeysersLastServicedDate"
                      >
                        Last serviced date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={heatPumpGeysersLastServicedDate}
                        onChange={(date) => {
                          isEditableByUser("heatPumpGeysersLastServicedDate") &&
                            setHeatPumpGeysersLastServicedDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="heatPumpGeysersLastServicedDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !heatPumpGeysersLastServicedDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    {/* <strong> */}{" "}
                    <Label
                      className="form-label"
                      for="heatPumpGeysersLastServiceReport"
                    >
                      Last service report
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={heatPumpGeysersLastServiceReport}
                      setImageList={setHeatPumpGeysersLastServiceReport}
                      editData={heatPumpGeysersLastServiceReportImageDummyState}
                      uploadType={uploadHeatPumpGeysersLastServiceReportImageType}
                      canBeEdited={isEditableByUser(
                        "heatPumpGeysersLastServiceReport"
                      )}
                      fieldName={"Last service report"}
                    />
                  </Col>
                </Row>
              </Col>

            }
          </FormGroup>
        </Col>
        <hr
          style={{
            borderTop: "2px solid #ffe700",
            width: "100%",
          }}
        />
        <Col sm="12">
          <FormGroup className="w-100">
            <Col>
              <Row>
                <Col sm="3">
                  <strong>
                    <Label className="form-label" for="airConditionerPresent" >
                      Air-conditioners
                    </Label>
                  </strong>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="airConditionerPresent"
                    name="airConditionerPresent"
                    options={YesNoDropdownOptions}
                    value={airConditionerPresent}
                    onChange={(value) => { setAirConditionerPresent(value) }}
                    isDisabled={!isEditableByUser("airConditionerPresent")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !airConditionerPresent ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </Col>
                {
                  airConditionerPresent?.value === "Yes" &&
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="AirConditionersPurchaseDate"
                      >
                        Purchase date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={AirConditionersPurchaseDate}
                        onChange={(date) => {
                          isEditableByUser("AirConditionersPurchaseDate") &&
                            setAirConditionersPurchaseDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="AirConditionersPurchaseDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !AirConditionersPurchaseDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>
                }
                {
                  airConditionerPresent?.value === "Yes" &&
                  <Col sm="3">
                    {/* <strong> */}{" "}
                    <Label
                      className="form-label"
                      for="AirConditionersPurchaseInvoice"
                    >
                      Upload purchase invoice
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={AirConditionersPurchaseInvoice}
                      setImageList={setAirConditionersPurchaseInvoice}
                      editData={AirConditionersPurchaseInvoiceImageDummyState}
                      uploadType={uploadAirConditionersPurchaseInvoiceImageType}
                      canBeEdited={isEditableByUser(
                        "AirConditionersPurchaseInvoice"
                      )}
                      fieldName={"Purchase invoice"}
                    />
                  </Col>
                }
              </Row>
            </Col>

            {
              airConditionerPresent?.value === "Yes" &&
              <Col>
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="AirConditionersWarrantyPeriod "
                      >
                        Warranty period
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="AirConditionersWarrantyPeriod "
                        name="AirConditionersWarrantyPeriod "
                        options={WarrantyPeriodTypeList}
                        value={AirConditionersWarrantyPeriod}
                        onChange={(value) => {
                          isEditableByUser("AirConditionersWarrantyPeriod") &&
                            setAirConditionersWarrantyPeriod(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !AirConditionersWarrantyPeriod ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="AirConditionersLessorLiabilityAMCStatus "
                      >
                        AMC status
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="AirConditionersLessorLiabilityAMCStatus "
                        name="AirConditionersLessorLiabilityAMCStatus "
                        options={CafeList}
                        value={AirConditionersLessorLiabilityAMCStatus}
                        onChange={(value) => {
                          isEditableByUser(
                            "AirConditionersLessorLiabilityAMCStatus"
                          ) && setAirConditionersLessorLiabilityAMCStatus(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !AirConditionersLessorLiabilityAMCStatus ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {AirConditionersLessorLiabilityAMCStatus?.value === "Yes" && (
                    <>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="airconditionerAMCStartDate">
                            AMC start date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={airconditionerAMCStartDate}
                            onChange={(date) => {
                              isEditableByUser("airconditionerAMCStartDate") &&
                                setAirConditionerAMCStartDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="airconditionerAMCStartDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !airconditionerAMCStartDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="airconditionerAMCEndDate">
                            AMC end date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={airconditionerAMCEndDate}
                            onChange={(date) => {
                              isEditableByUser("airconditionerAMCEndDate") &&
                                setAirConditionerAMCEndDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="airconditionerAMCEndDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !airconditionerAMCEndDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3    ">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label">AMC months</Label>
                          {/* </strong> */}
                          <Input
                            type="text"
                            value={AirConditionersLessorLiabilityAMCMonths}
                            id="AirConditionersLessorLiabilityAMCMonths"
                            name="AirConditionersLessorLiabilityAMCMonths"
                            //placeholder="Add Air Conditioners lessor liability AMC months"
                            onChange={(e) => {
                              isEditableByUser(e.target.name) &&
                                setAirConditionersLessorLiabilityAMCMonths(
                                  e.target.value
                                );
                            }}
                            disabled={!userRole}
                            style={{
                              borderColor: !AirConditionersLessorLiabilityAMCMonths && 'red',
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label
                            className="form-label"
                            for="AirConditionersAMCType "
                          >
                            AMC type*
                          </Label>
                          {/* </strong> */}
                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id="AirConditionersAMCType "
                            name="AirConditionersAMCType "
                            options={AMCTypeList}
                            value={AirConditionersAMCType}
                            onChange={(value) => {
                              isEditableByUser("AirConditionersAMCType") &&
                                setAirConditionersAMCType(value);
                            }}
                            classNamePrefix="select"
                            placeholder=""
                            isDisabled={!userRole}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderColor: !AirConditionersAMCType ? 'red' : provided.borderColor,
                              }),
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        {/* <strong> */}{" "}
                        <Label className="form-label" for="heatPumpGeysersAMCCopy">
                          Upload AMC invoice
                        </Label>
                        {/* </strong> */}
                        <ImageModal
                          imageList={AirConditionersAMCCopy}
                          setImageList={setAirConditionersAMCCopy}
                          editData={AirConditionersAMCCopyImageDummyState}
                          uploadType={uploadAirConditionersAMCCopyImageType}
                          canBeEdited={isEditableByUser("AirConditionersAMCCopy")}
                          fieldName={"AMC invoice"}
                        />
                      </Col>
                    </>
                  )}

                </Row>
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="AirConditionersLastServicedDate"
                      >
                        Last serviced date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={AirConditionersLastServicedDate}
                        onChange={(date) => {
                          isEditableByUser("AirConditionersLastServicedDate") &&
                            setAirConditionersLastServicedDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="AirConditionersLastServicedDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !AirConditionersLastServicedDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    {/* <strong> */}{" "}
                    <Label
                      className="form-label"
                      for="AirConditionersLastServiceReport"
                    >
                      Last service report
                    </Label>
                    {/* </strong> */}
                    <ImageModal
                      imageList={AirConditionersLastServiceReport}
                      setImageList={setAirConditionersLastServiceReport}
                      editData={AirConditionersLastServiceReportImageDummyState}
                      uploadType={uploadAirConditionersLastServiceReportImageType}
                      canBeEdited={isEditableByUser(
                        "AirConditionersLastServiceReport"
                      )}
                      fieldName={"Last service report"}
                    />
                  </Col>
                </Row>
              </Col>

            }

          </FormGroup>
        </Col>
        <hr
          style={{
            borderTop: "2px solid #ffe700",
            width: "100%",
          }}
        />
        <Col sm="12">
          <FormGroup className="w-100">
            <Col>
              <Row>
                <Col sm="3">
                  <strong>
                    <Label className="form-label" for="kitchenEquipmentPresent" >
                      Kitchen equipments
                    </Label>
                  </strong>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="kitchenEquipmentPresent"
                    name="kitchenEquipmentPresent"
                    options={YesNoDropdownOptions}
                    value={kitchenEquipmentPresent}
                    onChange={(value) => { setKitchenEquipmentPresent(value) }}
                    isDisabled={!isEditableByUser("kitchenEquipmentPresent")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !kitchenEquipmentPresent ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </Col>
                {
                  kitchenEquipmentPresent?.value === "Yes" &&
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="kitchenEquipPurchaseDate">
                        Purchase date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={kitchenEquipPurchaseDate}
                        onChange={(date) => {
                          isEditableByUser("kitchenEquipPurchaseDate") &&
                            setKitchenEquipPurchaseDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="kitchenEquipPurchaseDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !kitchenEquipPurchaseDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>
                }
                {
                  kitchenEquipmentPresent?.value === "Yes" &&
                  <Col sm="3">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="kitchenEquipPurchaseInvoice">
                      Upload purchase invoice
                    </Label>
                    {/* </strong> */}

                    <ImageModal
                      imageList={kitchenEquipPurchaseInvoice}
                      setImageList={setKitchenEquipPurchaseInvoice}
                      editData={kitchenEquipPurchaseInvoiceImageDummyState}
                      uploadType={uploadKitchenEquipPurchaseInvoiceImageType}
                      canBeEdited={isEditableByUser("kitchenEquipPurchaseInvoice")}
                      fieldName={"Purchase invoice"}
                    />
                  </Col>
                }
              </Row>
            </Col>

            {
              kitchenEquipmentPresent?.value === "Yes" &&
              <Col>
                <Row >
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="kitchenEquipWarrantyPeriod "
                      >
                        Warranty period
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="kitchenEquipWarrantyPeriod "
                        name="kitchenEquipWarrantyPeriod "
                        options={WarrantyPeriodTypeList}
                        value={kitchenEquipWarrantyPeriod}
                        onChange={(value) => {
                          isEditableByUser("kitchenEquipWarrantyPeriod") &&
                            setKitchenEquipWarrantyPeriod(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !kitchenEquipWarrantyPeriod ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="kitchenEquipLessorLiabilityAMCStatus "
                      >
                        AMC status
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="kitchenEquipLessorLiabilityAMCStatus "
                        name="kitchenEquipLessorLiabilityAMCStatus "
                        options={CafeList}
                        value={kitchenEquipLessorLiabilityAMCStatus}
                        onChange={(value) => {
                          isEditableByUser(
                            "kitchenEquipLessorLiabilityAMCStatus"
                          ) && setKitchenEquipLessorLiabilityAMCStatus(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !kitchenEquipLessorLiabilityAMCStatus ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {kitchenEquipLessorLiabilityAMCStatus?.value === "Yes" && (
                    <>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="kitchenEquipAMCStartDate">
                            AMC start date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={kitchenEquipAMCStartDate}
                            onChange={(date) => {
                              isEditableByUser("kitchenEquipAMCStartDate") &&
                                setKitchenEquipAMCStartDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="kitchenEquipAMCStartDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !kitchenEquipAMCStartDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="kitchenEquipAMCEndDate">
                            AMC end date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={kitchenEquipAMCEndDate}
                            onChange={(date) => {
                              isEditableByUser("kitchenEquipAMCEndDate") &&
                                setKitchenEquipAMCEndDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="kitchenEquipAMCEndDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !kitchenEquipAMCEndDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3    ">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label">AMC months</Label>
                          {/* </strong> */}
                          <Input
                            type="text"
                            value={kitchenEquipLessorLiabilityAMCMonths}
                            id="kitchenEquipLessorLiabilityAMCMonths"
                            name="kitchenEquipLessorLiabilityAMCMonths"
                            //placeholder="Add kitchen equipLessor liability AMC months"
                            onChange={(e) => {
                              isEditableByUser(e.target.name) &&
                                setKitchenEquipLessorLiabilityAMCMonths(
                                  e.target.value
                                );
                            }}
                            disabled={!userRole}
                            style={{
                              borderColor: !kitchenEquipLessorLiabilityAMCMonths && 'red',
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label" for="kitchenEquipAMCType ">
                            AMC type
                          </Label>
                          {/* </strong> */}
                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id="kitchenEquipAMCType "
                            name="kitchenEquipAMCType "
                            options={AMCTypeList}
                            value={kitchenEquipAMCType}
                            onChange={(value) => {
                              isEditableByUser("kitchenEquipAMCType") &&
                                setKitchenEquipAMCType(value);
                            }}
                            classNamePrefix="select"
                            placeholder=""
                            isDisabled={!userRole}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderColor: !kitchenEquipAMCType ? 'red' : provided.borderColor,
                              }),
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        {/* <strong> */}{" "}
                        <Label className="form-label" for="heatPumpGeysersAMCCopy">
                          Upload AMC invoice
                        </Label>

                        <ImageModal
                          imageList={kitchenEquipAMCCopy}
                          setImageList={setKitchenEquipAMCCopy}
                          editData={kitchenEquipAMCCopyImageDummyState}
                          uploadType={uploadKitchenEquipAMCCopyImageType}
                          canBeEdited={isEditableByUser("kitchenEquipAMCCopy")}
                          fieldName={"AMC invoice"}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="kitchenEquipLastServicedDate"
                      >
                        Last serviced date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={kitchenEquipLastServicedDate}
                        onChange={(date) => {
                          isEditableByUser("kitchenEquipLastServicedDate") &&
                            setKitchenEquipLastServicedDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="kitchenEquipLastServicedDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !kitchenEquipLastServicedDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    <Label
                      className="form-label"
                      for="kitchenEquipLastServiceReport"
                    >
                      Last service report
                    </Label>

                    <ImageModal
                      imageList={kitchenEquipLastServiceReport}
                      setImageList={setKitchenEquipLastServiceReport}
                      editData={kitchenEquipLastServiceReportImageDummyState}
                      uploadType={uploadKitchenEquipLastServiceReportImageType}
                      canBeEdited={isEditableByUser(
                        "kitchenEquipLastServiceReport"
                      )}
                      fieldName={"Last service report"}
                    />
                  </Col>
                </Row>
              </Col>

            }

          </FormGroup>
        </Col>
        <hr
          style={{
            borderTop: "2px solid #ffe700",
            width: "100%",
          }}
        />
        <Col sm="12">
          <FormGroup className="w-100">
            <Col>
              <Row>
                <Col sm="3">
                  <strong>
                    <Label className="form-label" for="stpPresent" >
                      STP plant
                    </Label>
                  </strong>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="stpPresent"
                    name="stpPresent"
                    options={YesNoDropdownOptions}
                    value={stpPresent}
                    onChange={(value) => { setStpPresent(value) }}
                    isDisabled={!isEditableByUser("stpPresent")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !stpPresent ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </Col>
                {
                  stpPresent?.value === "Yes" &&
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="stpPurchaseDate">
                        Purchase date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={stpPurchaseDate}
                        onChange={(date) => {
                          isEditableByUser("stpPurchaseDate") &&
                            setStpPurchaseDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="stpPurchaseDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !stpPurchaseDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>
                }
                {
                  stpPresent?.value === "Yes" &&
                  <Col sm="3">
                    <Label className="form-label" for="stpPurchaseInvoice">
                      Upload purchase invoice
                    </Label>
                    <ImageModal
                      imageList={stpPurchaseInvoice}
                      setImageList={setStpPurchaseInvoice}
                      editData={stpPurchaseInvoiceImageDummyState}
                      uploadType={uploadStpPurchaseInvoiceImageType}
                      canBeEdited={isEditableByUser("stpPurchaseInvoice")}
                      fieldName={"purchase invoice"}
                    />
                  </Col>
                }
              </Row>
            </Col>

            {
              stpPresent?.value === "Yes" &&
              <Col>
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="stpWarrantyPeriod ">
                        Warranty period
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="stpWarrantyPeriod "
                        name="stpWarrantyPeriod "
                        options={WarrantyPeriodTypeList}
                        value={stpWarrantyPeriod}
                        onChange={(value) => {
                          isEditableByUser("stpWarrantyPeriod") &&
                            setStpWarrantyPeriod(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !stpWarrantyPeriod ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="stpLessorLiabilityAMCStatus "
                      >
                        AMC status
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="stpLessorLiabilityAMCStatus "
                        name="stpLessorLiabilityAMCStatus "
                        options={CafeList}
                        value={stpLessorLiabilityAMCStatus}
                        onChange={(value) => {
                          isEditableByUser("stpLessorLiabilityAMCStatus") &&
                            setStpLessorLiabilityAMCStatus(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !stpLessorLiabilityAMCStatus ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {stpLessorLiabilityAMCStatus?.value === "Yes" && (
                    <>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="stpAMCStartDate">
                            AMC start date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={stpAMCStartDate}
                            onChange={(date) => {
                              isEditableByUser("stpAMCStartDate") &&
                                setStpAMCStartDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="stpAMCStartDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !stpAMCStartDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="stpAMCEndDate">
                            AMC end date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={stpAMCEndDate}
                            onChange={(date) => {
                              isEditableByUser("stpAMCEndDate") &&
                                setStpAMCEndDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="stpAMCEndDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !stpAMCEndDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3    ">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label">AMC months</Label>
                          {/* </strong> */}
                          <Input
                            type="text"
                            value={stpLessorLiabilityAMCMonths}
                            id="stpLessorLiabilityAMCMonths"
                            name="stpLessorLiabilityAMCMonths"
                            //placeholder="Add STP lessorLiability AMC months"
                            onChange={(e) => {
                              isEditableByUser(e.target.name) &&
                                setStpLessorLiabilityAMCMonths(e.target.value);
                            }}
                            disabled={!userRole}
                            style={{
                              borderColor: !stpLessorLiabilityAMCMonths && 'red',
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label" for="stpAMCType ">
                            AMC type
                          </Label>
                          {/* </strong> */}
                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id="stpAMCType "
                            name="stpAMCType "
                            options={AMCTypeList}
                            value={stpAMCType}
                            onChange={(value) => {
                              isEditableByUser("stpAMCType") &&
                                setStpAMCType(value);
                            }}
                            classNamePrefix="select"
                            placeholder=""
                            isDisabled={!userRole}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderColor: !stpAMCType ? 'red' : provided.borderColor,
                              }),
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        {/* <strong> */}{" "}
                        <Label className="form-label" for="heatPumpGeysersAMCCopy">
                          Upload AMC invoice
                        </Label>
                        <ImageModal
                          imageList={stpAMCCopy}
                          setImageList={setStpAMCCopy}
                          editData={stpAMCCopyImageDummyState}
                          uploadType={uploadStpAMCCopyImageType}
                          canBeEdited={isEditableByUser("stpAMCCopy")}
                          fieldName={"AMC invoice"}
                        />
                      </Col>
                    </>
                  )}

                </Row>
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label className="form-label" for="stpLastServicedDate">
                        Last serviced date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={stpLastServicedDate}
                        onChange={(date) => {
                          isEditableByUser("stpLastServicedDate") &&
                            setStpLastServicedDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="stpLastServicedDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !stpLastServicedDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    {/* <strong> */}{" "}
                    <Label className="form-label" for="stpLastServiceReport">
                      Last service report
                    </Label>
                    <ImageModal
                      imageList={stpLastServiceReport}
                      setImageList={setStpLastServiceReport}
                      editData={stpLastServiceReportImageDummyState}
                      uploadType={uploadStpLastServiceReportImageType}
                      canBeEdited={isEditableByUser("stpLastServiceReport")}
                      fieldName={"Last service report"}
                    />
                  </Col>
                </Row>
              </Col>

            }
          </FormGroup>
        </Col>
        <hr
          style={{
            borderTop: "2px solid #ffe700",
            width: "100%",
          }}
        />
        <Col sm="12">
          <FormGroup className="w-100">
            <Col>
              <Row>
                <Col sm="3">
                  <strong>
                    <Label className="form-label" for="toiletFittingPresent" >
                      Toilet fittings
                    </Label>
                  </strong>
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="toiletFittingPresent"
                    name="toiletFittingPresent"
                    options={YesNoDropdownOptions}
                    value={toiletFittingPresent}
                    onChange={(value) => { setToiletFittingPresent(value) }}
                    isDisabled={!isEditableByUser("toiletFittingPresent")}
                    classNamePrefix="select"
                    placeholder=""
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: !toiletFittingPresent ? 'red' : provided.borderColor,
                      }),
                    }}
                  />
                </Col>
                {
                  toiletFittingPresent?.value === "Yes" &&
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="toiletFittingsPurchaseDate"
                      >
                        Purchase date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={toiletFittingsPurchaseDate}
                        onChange={(date) => {
                          isEditableByUser("toiletFittingsPurchaseDate") &&
                            setToiletFittingsPurchaseDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="toiletFittingsPurchaseDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !toiletFittingsPurchaseDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>
                }
                {
                  toiletFittingPresent?.value === "Yes" &&
                  <Col sm="3">
                    {/* <strong> */}{" "}
                    <Label
                      className="form-label"
                      for="toiletFittingsPurchaseInvoice"
                    >
                      Upload purchase invoice
                    </Label>
                    <ImageModal
                      imageList={toiletFittingsPurchaseInvoice}
                      setImageList={setToiletFittingsPurchaseInvoice}
                      editData={toiletFittingsPurchaseInvoiceImageDummyState}
                      uploadType={uploadToiletFittingsPurchaseInvoiceImageType}
                      canBeEdited={isEditableByUser(
                        "toiletFittingsPurchaseInvoice"
                      )}
                      fieldName={"Upload purchase invoice"}
                    />
                  </Col>
                }
              </Row>
            </Col>

            {
              toiletFittingPresent?.value === "Yes" &&
              <Col>
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="toiletFittingsWarrantyPeriod "
                      >
                        Warranty period
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="toiletFittingsWarrantyPeriod "
                        name="toiletFittingsWarrantyPeriod "
                        options={WarrantyPeriodTypeList}
                        value={toiletFittingsWarrantyPeriod}
                        onChange={(value) => {
                          isEditableByUser("toiletFittingsWarrantyPeriod") &&
                            setToiletFittingsWarrantyPeriod(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !toiletFittingsWarrantyPeriod ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="toiletFittingsLessorLiabilityAMCStatus "
                      >
                        AMC status
                      </Label>
                      {/* </strong> */}
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="toiletFittingsLessorLiabilityAMCStatus "
                        name="toiletFittingsLessorLiabilityAMCStatus "
                        options={CafeList}
                        value={toiletFittingsLessorLiabilityAMCStatus}
                        onChange={(value) => {
                          isEditableByUser(
                            "toiletFittingsLessorLiabilityAMCStatus"
                          ) && setToiletFittingsLessorLiabilityAMCStatus(value);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !toiletFittingsLessorLiabilityAMCStatus ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {toiletFittingsLessorLiabilityAMCStatus?.value === "Yes" && (
                    <>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="toiletFittingsAMCStartDate">
                            AMC start date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={toiletFittingsAMCStartDate}
                            onChange={(date) => {
                              isEditableByUser("toiletFittingsAMCStartDate") &&
                                setToiletFittingAMCStartDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="toiletFittingsAMCStartDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !toiletFittingsAMCStartDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="toiletFittingsAMCEndDate">
                            AMC end date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={toiletFittingsAMCEndDate}
                            onChange={(date) => {
                              isEditableByUser("toiletFittingsAMCEndDate") &&
                                setToiletFittingAMCEndDate(
                                  new Date(dateFormatter(new Date(date)))
                                );
                            }}
                            id="toiletFittingsAMCEndDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !toiletFittingsAMCEndDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3    ">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label">AMC months</Label>
                          {/* </strong> */}
                          <Input
                            type="text"
                            value={heavyEquipLessorLiabilityAMCMonths}
                            id="heavyEquipLessorLiabilityAMCMonths"
                            name="heavyEquipLessorLiabilityAMCMonths"
                            //placeholder="Add lift lessor liability AMC months"
                            onChange={(e) => {
                              isEditableByUser(e.target.name) &&
                                setHeavyEquipLessorLiabilityAMCMonths(
                                  e.target.value
                                );
                            }}
                            disabled={!userRole}
                            style={{
                              borderColor: !heavyEquipLessorLiabilityAMCMonths && 'red',
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          {/* <strong> */}
                          <Label className="form-label" for="heavyEquipAMCType ">
                            AMC type
                          </Label>
                          {/* </strong> */}
                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id="heavyEquipAMCType "
                            name="heavyEquipAMCType "
                            options={AMCTypeList}
                            value={heavyEquipAMCType}
                            onChange={(value) => {
                              isEditableByUser("heavyEquipAMCType") &&
                                setHeavyEquipAMCType(value);
                            }}
                            classNamePrefix="select"
                            placeholder=""
                            isDisabled={!userRole}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderColor: !heavyEquipAMCType ? 'red' : provided.borderColor,
                              }),
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        {/* <strong> */}{" "}
                        <Label className="form-label" for="heatPumpGeysersAMCCopy">
                          Upload AMC invoice
                        </Label>
                        <ImageModal
                          imageList={toiletFittingsAMCCopy}
                          setImageList={setToiletFittingsAMCCopy}
                          editData={toiletFittingsAMCCopyImageDummyState}
                          uploadType={uploadToiletFittingsAMCCopyImageType}
                          canBeEdited={isEditableByUser("toiletFittingsAMCCopy")}
                          fieldName={"AMC invoice"}
                        />
                      </Col>
                    </>
                  )}

                </Row>
                <Row>
                  <Col sm="3">
                    <FormGroup className="w-100">
                      {/* <strong> */}
                      <Label
                        className="form-label"
                        for="toiletFittingsLastServicedDate"
                      >
                        Last serviced date
                      </Label>
                      {/* </strong> */}
                      <Flatpickr
                        className="form-control bg-white  text-dark"
                        value={toiletFittingsLastServicedDate}
                        onChange={(date) => {
                          isEditableByUser("toiletFittingsLastServicedDate") &&
                            setToiletFittingsLastServicedDate(
                              new Date(dateFormatter(new Date(date)))
                            );
                        }}
                        id="toiletFittingsLastServicedDate"
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !toiletFittingsLastServicedDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                    {/* <strong> */}{" "}
                    <Label
                      className="form-label"
                      for="toiletFittingsLastServiceReport"
                    >
                      Last service report
                    </Label>
                    <ImageModal
                      imageList={toiletFittingsLastServiceReport}
                      setImageList={setToiletFittingsLastServiceReport}
                      editData={toiletFittingsLastServiceReportImageDummyState}
                      uploadType={uploadToiletFittingsLastServiceReportImageType}
                      canBeEdited={isEditableByUser(
                        "toiletFittingsLastServiceReport"
                      )}
                      fieldName={"Last service report"}
                    />
                  </Col>
                </Row>
              </Col>

            }
          </FormGroup>
        </Col>
        <hr
          style={{
            borderTop: "2px solid #ffe700",
            width: "100%",
          }}
        />

        {
          addedAssets?.map((item, index) => {
            console.log(item)
            console.log(item?.uploadPurchaseInvoice)
            return (
              <Col sm="12" key={index}>
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col>
                    <Row>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label">Equipment name</Label>
                          <Input
                            type="text"
                            value={item.assetName}
                            id={`heavyEquipLessorLiabilityAMCMonths-${index}`}
                            name={`heavyEquipLessorLiabilityAMCMonths-${index}`}
                            onChange={(e) => {
                              const updatedAssets = [...addedAssets];
                              updatedAssets[index].assetName = e.target.value;
                              setAddedAssets(updatedAssets);
                            }}
                            disabled={!userRole}
                          />
                        </FormGroup>
                      </Col>
                      {/* Purchase Date */}
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for={`toiletFittingsPurchaseDate-${index}`}>
                            Purchase date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white text-dark"
                            value={item.purchaseDate}
                            onChange={(date) => {
                              const updatedAssets = [...addedAssets];
                              updatedAssets[index].purchaseDate = new Date(dateFormatter(new Date(date)));
                              setAddedAssets(updatedAssets);
                            }}
                            id={`toiletFittingsPurchaseDate-${index}`}
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !item.purchaseDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <Label className="form-label" for={`purchaseInvoice-${index}`}>
                          Upload purchase invoice
                        </Label>
                        <ImageModal
                          imageList={item.uploadPurchaseInvoice}
                          setImageList={(newImageList) => {
                            const updatedAssets = [...addedAssets];
                            updatedAssets[index].uploadPurchaseInvoice = newImageList;
                            setAddedAssets(updatedAssets);
                          }}
                          editData={toiletFittingsPurchaseInvoiceImageDummyState}
                          uploadType={uploadToiletFittingsPurchaseInvoiceImageType}
                          canBeEdited={isEditableByUser("toiletFittingsPurchaseInvoice")}
                          fieldName={"Upload purchase invoice"}
                          isDynamic={true}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <div onClick={() => { handleRemoveAsset(item?.uuId) }}><XCircle color="red" /></div>
                </Row>
                <Row>
                  {/* Warranty Period */}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      <Label className="form-label" for={`toiletFittingsWarrantyPeriod-${index}`}>
                        Warranty period
                      </Label>
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id={`toiletFittingsWarrantyPeriod-${index}`}
                        name={`toiletFittingsWarrantyPeriod-${index}`}
                        options={WarrantyPeriodTypeList}
                        value={item.warrantyPeriod}
                        onChange={(value) => {
                          // Create a new array with the updated warrantyPeriod for the item at the current index
                          const updatedAssets = addedAssets.map((asset, i) =>
                            i === index ? { ...asset, warrantyPeriod: value } : asset
                          );
                          setAddedAssets(updatedAssets);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !item.warrantyPeriod ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>



                  {/* </Row>
                <Row> */}

                  {/* AMC Status */}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      <Label className="form-label" for={`toiletFittingsLessorLiabilityAMCStatus-${index}`}>
                        AMC status
                      </Label>
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id={`toiletFittingsLessorLiabilityAMCStatus-${index}`}
                        name={`toiletFittingsLessorLiabilityAMCStatus-${index}`}
                        options={CafeList}
                        value={item.amcStatus}
                        onChange={(value) => {
                          const updatedAssets = addedAssets.map((asset, i) =>
                            i === index ? { ...asset, amcStatus: value } : asset
                          );
                          setAddedAssets(updatedAssets);
                        }}
                        classNamePrefix="select"
                        placeholder=""
                        isDisabled={!userRole}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: !item.amcStatus ? 'red' : provided.borderColor,
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>


                  {/* Show AMC fields if AMC status is "Yes" */}
                  {item.amcStatus?.value === "Yes" && (
                    <>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="liftAMCStartDate">
                            AMC start date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={item?.amcStartDate}
                            onChange={(date) => {
                              const updatedAssets = [...addedAssets];
                              updatedAssets[index].amcStartDate = new Date(dateFormatter(new Date(date)));
                              setAddedAssets(updatedAssets);
                            }}
                            id="liftAMCStartDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !item?.amcStartDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for="liftAMCEndDate">
                            AMC end date
                          </Label>
                          <Flatpickr
                            className="form-control bg-white  text-dark"
                            value={item?.amcEndDate}
                            onChange={(date) => {
                              const updatedAssets = [...addedAssets];
                              updatedAssets[index].amcEndDate = new Date(dateFormatter(new Date(date)));
                              setAddedAssets(updatedAssets);
                            }}
                            id="liftAMCEndDate"
                            required
                            disabled={!userRole}
                            style={{
                              backgroundColor: !userRole ? "#e9ecef" : "white",
                              cursor: !userRole ? "not-allowed" : "pointer",
                              borderColor: !item?.amcEndDate && "red",
                              opacity: 1
                            }}
                          />
                        </FormGroup>
                      </Col>
                      {/* AMC Months */}
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label">AMC months</Label>
                          <Input
                            type="text"
                            value={item.amcMonths}
                            id={`heavyEquipLessorLiabilityAMCMonths-${index}`}
                            name={`heavyEquipLessorLiabilityAMCMonths-${index}`}
                            onChange={(e) => {
                              const updatedAssets = [...addedAssets];
                              updatedAssets[index].amcMonths = e.target.value;
                              setAddedAssets(updatedAssets);
                            }}
                            disabled={!userRole}
                            style={{
                              borderColor: !item.amcMonths && 'red',
                            }}
                          />
                        </FormGroup>
                      </Col>

                      {/* AMC Type */}
                      <Col sm="3">
                        <FormGroup className="w-100">
                          <Label className="form-label" for={`heavyEquipAMCType-${index}`}>
                            AMC type
                          </Label>
                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id={`heavyEquipAMCType-${index}`}
                            name={`heavyEquipAMCType-${index}`}
                            options={AMCTypeList}
                            value={item.amcType}
                            onChange={(value) => {
                              const updatedAssets = [...addedAssets];
                              updatedAssets[index].amcType = value;
                              setAddedAssets(updatedAssets);
                            }}
                            classNamePrefix="select"
                            placeholder=""
                            isDisabled={!userRole}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderColor: !item.amcType ? 'red' : provided.borderColor,
                              }),
                            }}
                          />
                        </FormGroup>
                      </Col>

                      {/* Upload AMC Invoice */}
                      <Col sm="3">
                        <Label className="form-label" for={`heatPumpGeysersAMCCopy-${index}`}>
                          Upload AMC invoice
                        </Label>
                        <ImageModal
                          imageList={item.amcInvoice}
                          setImageList={(newImageList) => {
                            const updatedAssets = [...addedAssets];
                            updatedAssets[index].amcInvoice = newImageList;
                            setAddedAssets(updatedAssets);
                          }}
                          editData={toiletFittingsAMCCopyImageDummyState}
                          uploadType={uploadToiletFittingsAMCCopyImageType}
                          canBeEdited={isEditableByUser("toiletFittingsAMCCopy")}
                          fieldName={"AMC invoice"}
                          isDynamic={true}
                        />
                      </Col>
                    </>
                  )}

                </Row>
                <Row>
                  {/* Last serviced date */}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      <Label className="form-label" for={`toiletFittingsLastServicedDate-${index}`}>
                        Last serviced date
                      </Label>
                      <Flatpickr
                        className="form-control bg-white text-dark"
                        value={item.lastServiceDate}
                        onChange={(date) => {
                          const updatedAssets = [...addedAssets];
                          updatedAssets[index].lastServiceDate = new Date(dateFormatter(new Date(date)));
                          setAddedAssets(updatedAssets);
                        }}
                        id={`toiletFittingsLastServicedDate-${index}`}
                        required
                        disabled={!userRole}
                        style={{
                          backgroundColor: !userRole ? "#e9ecef" : "white",
                          cursor: !userRole ? "not-allowed" : "pointer",
                          borderColor: !item.lastServiceDate && "red",
                          opacity: 1
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/* Last service report */}
                  <Col sm="3">
                    <Label className="form-label" for={`toiletFittingsLastServiceReport-${index}`}>
                      Last service report
                    </Label>
                    <ImageModal
                      imageList={item.lastServiceReport}
                      setImageList={(newImageList) => {
                        const updatedAssets = [...addedAssets];
                        updatedAssets[index].lastServiceReport = newImageList;
                        setAddedAssets(updatedAssets);
                      }}
                      editData={toiletFittingsLastServiceReportImageDummyState}
                      uploadType={uploadToiletFittingsLastServiceReportImageType}
                      canBeEdited={isEditableByUser("toiletFittingsLastServiceReport")}
                      fieldName={"Last service report"}
                      isDynamic={true}
                    />
                  </Col>
                </Row>
                <hr
                  style={{
                    borderTop: "2px solid #ffe700",
                    width: "100%",
                  }}
                />
              </Col>
            )
          })
        }

      </Row>
      {
        (localStorage.getItem("department") === "Supply" || localStorage.getItem("role") === "CEO") &&
        <Row style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "5px"
        }}>
          <Button
            style={{
              height: "39px",
              marginRight: "10px"
            }}
            onClick={() => { handleAddAsset() }}
          >
            Add equipment
          </Button>
          <SaveDiscardBtnGroup />
        </Row>
      }

    </form >
  );
};

export default WarrantyAMCDocs;
