import React, { useEffect, useState } from "react";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateHostel, useGetHostelById } from "../../../../api/dataSource";
import { Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import ImageSection from "./ImageSection";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const UploadType = [
  {
    value: "general",
    label: "General",
  },
  // {
  //   value: "outdoor",
  //   label: "Outdoor",
  // },
  // {
  //   value: "room",
  //   label: "Room",
  // },
  // {
  //   value: "mobile",
  //   label: "Mobile",
  // },
];

const PhotosForm = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);

  const [
    updateHostelHandler,
    { data: updateHostel, reset: updateHostelReset },
  ] = useUpdateHostel();

  const dispatch = useDispatch();

  useEffect(() => {
    if (imageList.length === 0) {
      state?.tableData?.images?.length &&
        setImageList(state?.tableData?.images);
    }
  }, [imageList.length, state?.tableData?.images]);

  useEffect(() => {
    let arrProcessedImages = [];
    imageList.forEach((i) => {
      let imageObj = {
        type: i.type,
        image: i.image,
        key: i.key,
        imageCaption: i.imageCaption,
        displayOrder: i.displayOrder,
        imageType: i.imageType,
      };
      arrProcessedImages.push(imageObj);
    });

    setSortedImages(
      arrProcessedImages.sort((a, b) => a.displayOrder - b.displayOrder)
    );
  }, [imageList]);

  useEffect(() => {
    if (updateHostel?.updateHostel?.name) {
      // toaster("success", "Photos updated successfully");
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          images: imageList,
        })
      );
    }
  }, [dispatch, updateHostel?.updateHostel?.name, updateHostelReset]);
  const { data, refetch } = useGetHostelById(id);

  const handleSubmit = (e) => {
    e.preventDefault();

    const images = [];
    imageList.map((image) =>
      images.push({
        type: image?.type,
        image: image?.image,
        key: image?.key,
        imageCaption: image?.imageCaption,
        displayOrder: image?.displayOrder,
        imageType: image?.imageType,
      })
    );

    updateHostelHandler(id, { images, sectionName: "Photos" });

    toaster("success", "Submitted successfully");
  };
  useEffect(() => {
    if (updateHostel) {
      refetch();
    }
  }, [updateHostel]);

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <ImageSection
            imageList={sortedImages}
            setImageList={setImageList}
            uploadType={UploadType}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default PhotosForm;
