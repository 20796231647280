import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import SupplyTable from "./SupplyTable";
import AddEditModal from "./AddEditModal";
import { useGetPaginatedSupply } from "../../../api/Supply/Queries";
import TablePagination from "@components/common/TablePagination";
import { Col, Row, Input, FormGroup, Label, Button, Form } from "reactstrap";
import Select from "react-select";
import {
  HostelRegionList,
  OperationalModelTypeList,
  MonthlyRentTypeList,
  LeaseDeedTypeList,
  LicensesObtainanceOrRenewalResponsibilityTypeList,
} from "./selectOptionsList";

const Supply = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [hostelName, setHostelName] = useState("");
  const [hostelRegion, setHostelRegion] = useState("");
  const [numOfTotalBeds, setNumOfTotalBeds] = useState("");
  const [totalSanctionedElectricalLoad, setTotalSanctionedElectricalLoad] =
    useState("");
  const [
    totalSanctionedWaterSupplyPerDay,
    setTotalSanctionedWaterSupplyPerDay,
  ] = useState("");
  const [operationalModel, setOperationalModel] = useState("");
  const [ageOfHostel, setAgeOfHostel] = useState("");
  const [monthlyRentType, setMonthlyRentType] = useState("");
  const [leaseDeedType, setLeaseDeedType] = useState("");
  const [licensesResponsibility, setLicensesResponsibility] = useState("");
  const [filteredData, setFilteredData] = useState({});

  const { data, loading, refetch } = useGetPaginatedSupply(
    limit,
    page,
    filteredData
  );

  const history = useHistory();

  const paginatedSupplyData = data?.getPaginatedSupply?.data;
  const count = data?.getPaginatedSupply?.count;

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    if (paginatedSupplyData?.length > 0) {
      setTableData(paginatedSupplyData);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(count / limit));
  }, [count, paginatedSupplyData, limit]);

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/kyh/edit/supply/${id}`);
      // history.push(`/kyh/edit/supply/test`);
    } else {
      setId(id);
    }
  };

  const handleCreateUpdateSupply = (id) => {
    window.open(`/kyh/edit/supply/${id}`, '_blank');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      hostelName,
      hostelRegion: hostelRegion?.value,
      numOfTotalBeds: Number(numOfTotalBeds),
      totalSanctionedElectricalLoad: Number(totalSanctionedElectricalLoad),
      totalSanctionedWaterSupplyPerDay: Number(
        totalSanctionedWaterSupplyPerDay
      ),
      operationalModel: operationalModel?.value,
      ageOfHostel: Number(ageOfHostel),
      monthlyRentType: monthlyRentType?.value,
      leaseDeedType: leaseDeedType?.value,
      licensesResponsibility: licensesResponsibility?.value,
    };
    setFilteredData(data);
    setPage(1);
  };

  const handleReset = () => {
    setHostelName("");
    setHostelRegion("");
    setNumOfTotalBeds("");
    setTotalSanctionedElectricalLoad("");
    setTotalSanctionedWaterSupplyPerDay("");
    setOperationalModel("");
    setAgeOfHostel("");
    setMonthlyRentType("");
    setLeaseDeedType("");
    setLicensesResponsibility("");
    setFilteredData({});
    setPage(1);
  };
  return (
    localStorage.getItem("modules")?.includes("KYH") && (
      <div>
        <Form onSubmit={handleSubmit}>
          <Row>
            {/* //! Hostel name */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="hostelName">
                    Hostel name
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={hostelName}
                  id="hostelName"
                  name="hostelName"
                  onChange={(e) => setHostelName(e.target.value)}
                />
              </FormGroup>
            </Col>

            {/* //! Hostel region */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="hostelRegion">
                    Hostel region
                  </Label>
                </strong>

                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="hostelRegion"
                  name="hostelRegion"
                  options={HostelRegionList}
                  value={hostelRegion}
                  onChange={(value) => setHostelRegion(value)}
                  classNamePrefix="select"
                  placeholder=""
                />
              </FormGroup>
            </Col>

            {/* //! No. of total beds */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="numOfTotalBeds">
                    No. of total beds
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={numOfTotalBeds}
                  id="numOfTotalBeds"
                  name="numOfTotalBeds"
                  onChange={(e) => setNumOfTotalBeds(e.target.value)}
                />
              </FormGroup>
            </Col>

            {/*//! Total sanctioned electrical load (in KWH) */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label
                    className="form-label"
                    for="totalSanctionedElectricalLoad"
                  >
                    Total sanctioned electrical load (in KWH)
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={totalSanctionedElectricalLoad}
                  id="totalSanctionedElectricalLoad"
                  name="totalSanctionedElectricalLoad"
                  onChange={(e) =>
                    setTotalSanctionedElectricalLoad(e.target.value)
                  }
                />
              </FormGroup>
            </Col>

            {/*//! Total sanctioned water supply/day (in ltr) */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label
                    className="form-label"
                    for="totalSanctionedWaterSupplyPerDay"
                  >
                    Total sanctioned water supply/day (in ltr)
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={totalSanctionedWaterSupplyPerDay}
                  id="totalSanctionedWaterSupplyPerDay"
                  name="totalSanctionedWaterSupplyPerDay"
                  onChange={(e) =>
                    setTotalSanctionedWaterSupplyPerDay(e.target.value)
                  }
                />
              </FormGroup>
            </Col>

            {/* //! Operational model */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="operationalModel">
                    Operational model
                  </Label>
                </strong>

                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="operationalModel"
                  name="operationalModel"
                  options={OperationalModelTypeList}
                  value={operationalModel}
                  onChange={(value) => setOperationalModel(value)}
                  classNamePrefix="select"
                  placeholder=""
                />
              </FormGroup>
            </Col>

            {/* //! Age of the hostel (in months)  */}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label">
                    Age of the hostel (in months)
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={ageOfHostel}
                  id="ageOfHostel"
                  name="ageOfHostel"
                  onChange={(e) => setAgeOfHostel(e.target.value)}
                />
              </FormGroup>
            </Col>

            {/* //! Monthly rent type*/}
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label">Monthly rent type</Label>
                </strong>

                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="monthlyRentType"
                  name="monthlyRentType"
                  options={MonthlyRentTypeList}
                  value={monthlyRentType}
                  onChange={(value) => setMonthlyRentType(value)}
                  classNamePrefix="select"
                  placeholder=""
                />
              </FormGroup>
            </Col>

            {/* //! Lease deed type */}
            <Col sm="3    ">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="leaseDeedType">
                    Lease deed type
                  </Label>
                </strong>

                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="leaseDeedType"
                  name="leaseDeedType"
                  options={LeaseDeedTypeList}
                  value={leaseDeedType}
                  classNamePrefix="select"
                  placeholder=""
                  onChange={(value) => setLeaseDeedType(value)}
                />
              </FormGroup>
            </Col>

            {/* //! Licenses obtainance/renewal responsibility */}
            <Col sm="3    ">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="licensesResponsibility">
                    Licenses obtainance/renewal responsibility
                  </Label>
                </strong>

                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="licensesResponsibility"
                  name="licensesResponsibility"
                  options={LicensesObtainanceOrRenewalResponsibilityTypeList}
                  value={licensesResponsibility}
                  onChange={(value) => setLicensesResponsibility(value)}
                  classNamePrefix="select"
                  placeholder=""
                />
              </FormGroup>
            </Col>

            <Col sm="1">
              <Button.Ripple
                color="light"
                type="submit"
                style={{ marginTop: "21px" }}
              >
                Search
              </Button.Ripple>
            </Col>

            <Col sm="1">
              <Button.Ripple
                color="danger"
                type="button"
                style={{ marginTop: "21px" }}
                onClick={() => {
                  handleReset();
                }}
              >
                Reset
              </Button.Ripple>
            </Col>
          </Row>
        </Form>
        <SupplyTable
          setId={setId}
          id={id}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          count={count}
          handleEdit={handleEdit}
          handleCreateUpdateSupply={handleCreateUpdateSupply}
          refetch={refetch}
          loading={loading}
        />{" "}
        <TablePagination
          setPage={setPage}
          page={page}
          totalPage={totalPage}
          loading={loading}
        />
        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
          setId={setId}
          loading={loading}
        />
      </div>
    )
  );
};

export default Supply;
