import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_HOUSEKEEPING_ROOMS = gql`
  query ($hostelId: ID!) {
    getHouseKeepingRooms(hostelId: $hostelId) {
      Dormitory {
        roomName
        roomNo
      }
      PrivateRoom {
        roomName
        roomNo
      }
    }
  }
`;

export const useGetHouseKeepingRooms = (hostelId) => {
  const { loading, error, data, refetch } = useQuery(GET_HOUSEKEEPING_ROOMS, {
    variables: { hostelId },
    skip: !hostelId, 
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const GET_HOUSE_KEEPING_DATA = gql`
  query ($hostelId: ID!, $cleaningDate: String!) {
    getHouseKeepingData(hostelId: $hostelId, cleaningDate: $cleaningDate) {
      _id
      hostelId {
        _id
        name
      }
        roomName
        roomNo
        cleaningDate
        cleaningTitle
        cleaningTime
        remark
        logs
    }
  }
`;

export const useGetHouseKeepingData = (hostelId, cleaningDate) => {
  const { loading, error, data, refetch } = useQuery(GET_HOUSE_KEEPING_DATA, 
    {
      variables: { hostelId, cleaningDate },
      skip: !hostelId || !cleaningDate, 
    }
  );
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};