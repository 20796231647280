import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, FormGroup, Label, Row, Button, Input } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Trash, Camera } from "react-feather";
import Select from "react-select";
import { useUpdateDestination, useUploadImage } from "@api";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { tableDataHandler } from "@store/Cms/cms.action";
import Avatar from "@components/common/avatar";
import toaster from "@components/common/Toaster";
import confirmDelete from "@components/common/confirmDelete";

const EventsToDo = () => {
  const { id } = useParams();
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();
  const [eventsToDo, setEventsToDo] = useState([
    {
      uuId: uuidv4(),
      thingsTodoCategory: "",
      title: "",
      description: "",
      images: [],
    },
  ]);
  const [index, setIndex] = useState("");
  const [updateDestinationHandler, { data }] = useUpdateDestination();
  const [ThingsTodoList, setThingsTodoList] = useState([]);

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!
  const [uploadImageHandler, { data: imageData }] = useUploadImage();
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!

  const handleChangeSelectedToDoCategory = (thingsTodoCategory, uuId) => {
    setEventsToDo(
      eventsToDo?.map((item) => {
        return item?.uuId === uuId
          ? {
              ...item,
              thingsTodoCategory: thingsTodoCategory?.value,
            }
          : item;
      })
    );
  };

  const handleChangeTitle = (title, uuId) => {
    setEventsToDo(
      eventsToDo?.map((item) => {
        return item?.uuId === uuId ? { ...item, title: title } : item;
      })
    );
  };

  const handleChangeDescription = (description, uuId) => {
    setEventsToDo(
      eventsToDo?.map((item) => {
        return item?.uuId === uuId
          ? { ...item, description: description }
          : item;
      })
    );
  };

  const handleAddEventsToDo = () => {
    setEventsToDo([
      ...eventsToDo,
      {
        uuId: uuidv4(),
        thingsTodoCategory: "",
        title: "",
        description: "",
        images: [],
      },
    ]);
  };

  const handleRemoveEventsToDo = (uuId) =>
    confirmDelete(() =>
      setEventsToDo(eventsToDo?.filter((item) => item?.uuId !== uuId))
    );

  useEffect(() => {
    let ThingsTodoList = state?.tableData?.thingsTodo?.map((item) => {
      return { label: item?.categoryTitle, value: item?.categoryTitle };
    });
    let ResponseEventToDo = state?.tableData?.eventsToDo?.map((item) => {
      return {
        uuId: uuidv4(),
        thingsTodoCategory: item?.thingsTodoCategory,
        title: item?.title,
        description: item?.description,
        images: item?.images,
      };
    });
    setThingsTodoList(ThingsTodoList);
    setEventsToDo(ResponseEventToDo);
  }, [state]);

  useEffect(() => {
    if (data?.updateDestination?.destination) {
      toaster("success", "Events to do updated successfully");
    }
  }, [data]);

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      let images = [];
      setEventsToDo(
        eventsToDo?.map((item) => {
          if (item?.uuId === index) {
            // let a = [...item.images];
            return {
              ...item,
              images: [...item?.images, imageData?.uploadImage?.url],

              // images.push({
              //   images: image1Data?.uploadImage?.url|| image2Data?.uploadImage?.url|| image3Data?.uploadImage?.url
              // }),
            };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);

  const fileUploader = (e, uuId, index) => {
    if (!e.target?.files[0]) return;
    setIndex(uuId);

    // const fileName = e.target.files[0]?.name;

    // const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    // if (specialCharsRegex.test(fileName)) {
    //   console.error("File name:", fileName);
    //   toaster(
    //     "error",
    //     "File name contains special characters. Please provide a valid file name."
    //   );

    //   return;
    // }

    uploadImageHandler(
      e.target?.files[0],
      `${state?.tableData?.destination?.toLowerCase()?.replaceAll(" ", "-")}` +
        `/DESTINATION`
    );

    toaster("success", "Events to do image uploaded successfully");
  };

  // const fileUploader2 = (e, uuId) => {
  //   if (!e.target.files[0]) return;
  //   setIndex(uuId);
  //   uploadImageHandler(
  //     e.target.files[0],
  //     `${state.tableData?.destination
  //       ?.toLowerCase()
  //       ?.replaceAll(" ", "-")}/DESTINATION}`,
  //   );
  // };

  // const fileUploader3 = (e, uuId) => {
  //   if (!e.target.files[0]) return;
  //   setIndex(uuId);
  //   uploadImageHandler(
  //     e.target.files[0],
  //     `${state.tableData?.destination
  //       ?.toLowerCase()
  //       ?.replaceAll(" ", "-")}/DESTINATION}`,
  //   );
  // };

  const submitDataHandler = () => {
    let isValid = true;
    let eventsToDoClone = JSON.parse(JSON.stringify(eventsToDo));

    eventsToDoClone?.forEach((item) => {
      delete item?.uuId;
    });

    let eventsToDoData = {
      eventsToDo: eventsToDoClone,
      sectionName: "Events to do",
    };

    eventsToDoClone?.forEach((item) => {
      isValid &&
        (item?.thingsTodoCategory && item?.title && item?.description
          ? (isValid = true)
          : (isValid = false));
    });
    if (isValid) {
      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...eventsToDoData,
        })
      );
      updateDestinationHandler(id, eventsToDoData);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitDataHandler();
      }}
    >
      <Row className="mt-2">
        {eventsToDo?.map(
          ({ uuId, thingsTodoCategory, title, description, images }, index) => (
            <Col className="d-flex align-items-center" sm="12" key={uuId}>
              <FormGroup className="w-100">
                <Label className="form-label" htmlFor={"reviews" + uuId}>
                  Events to do {index + 1}
                </Label>

                <div style={{ display: "flex" }}>
                  {/* <div className="mt-2 d-flex align-items-center">
                    <div className="mb-1 mr-2">
                      {<Avatar img={images && images[0]} alt={images} />}
                    </div>

                    <label htmlFor={`myFile${uuId}`}>
                      <p className="btn btn-sm btn-light">
                        <Camera size={15} />
                      </p>
                      <input
                        name={`myFile${uuId}`}
                        id={`myFile${uuId}`}
                        className="d-none"
                        type="file"
                        onChange={(e) => fileUploader(e, uuId,index)}
                      />
                    </label>
                  </div>

                  <div className="mt-2 d-flex align-items-center ml-2">
                    <div className="mb-1 mr-2">
                      {<Avatar img={images && images[1]} alt={images} />}
                    </div>

                    <label htmlFor={`myFile${uuId}`}>
                      <p className="btn btn-sm btn-light">
                        <Camera size={15} />
                      </p>
                      <input
                        name={`myFile${uuId}`}
                        id={`myFile${uuId}`}
                        className="d-none"
                        type="file"
                        onChange={(e) => fileUploader(e, uuId)}
                      />
                    </label>
                  </div> */}

                  <div className="mt-2 d-flex align-items-center">
                    <div className="mb-1 mr-2 flex flex-column">
                      <div>
                        {images?.map((image, index) => (
                          <Avatar
                            key={index}
                            img={image}
                            alt={image}
                            className=""
                            imgHeight={100}
                            imgWidth={100}
                            size="xl"
                          />
                        ))}
                      </div>
                      <span>1200 X 800</span>
                    </div>

                    <label htmlFor={`myFile${uuId}`}>
                      <p className="btn btn-sm btn-light">
                        <Camera size={15} />
                      </p>
                      <input
                        name={`myFile${uuId}`}
                        id={`myFile${uuId}`}
                        className="d-none"
                        type="file"
                        onChange={(e) => fileUploader(e, uuId)}
                      />
                    </label>
                  </div>
                </div>

                <Row>
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Select
                        // isClearable={false}
                        isSearchable={true}
                        id={uuId}
                        name="thingsTodoCategory"
                        value={{
                          label: thingsTodoCategory,
                          value: thingsTodoCategory,
                        }}
                        options={ThingsTodoList}
                        onChange={(value) => {
                          handleChangeSelectedToDoCategory(value, uuId);
                        }}
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <Label className="form-label" for="title">
                      Title*
                    </Label>
                    <Input
                      type="text"
                      value={title}
                      id="title"
                      name="title"
                      placeholder="Add Title"
                      required
                      onChange={(e) =>
                        handleChangeTitle(e?.target?.value, uuId)
                      }
                    />
                  </Col>

                  <Col sm="12">
                    <Label className="form-label" for="description">
                      Description*
                    </Label>
                    <Input
                      type="textarea"
                      value={description}
                      id="description"
                      name="description"
                      placeholder="Add Description"
                      required
                      onChange={(e) =>
                        handleChangeDescription(e?.target?.value, uuId)
                      }
                    />
                  </Col>
                </Row>
              </FormGroup>
              {eventsToDo?.length > 1 ? (
                <>
                  <Button.Ripple
                    className="btn-icon rounded-circle ml-1"
                    color="light"
                    type="button"
                    size="sm"
                    onClick={() => handleRemoveEventsToDo(uuId)}
                  >
                    <Trash size={14} />
                  </Button.Ripple>
                </>
              ) : null}
            </Col>
          )
        )}
      </Row>

      <SaveDiscardBtnGroup addAction={handleAddEventsToDo} />
    </form>
  );
};

export default EventsToDo;
