// ! Coded by Ashwin

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_PAGINATED_SUPPLY = gql`
  query getPaginatedSupply($supplyFilter: supplyFilter) {
    getPaginatedSupply(supplyFilter: $supplyFilter) {
      count
      page
      limit
      data {
        _id
        hostelPrefix
        localityName
        hostelCity
        hostelName
        hostelState
        hostelRegion
        hostelCompleteAddress
        googleMapsPin
        numOfDormRooms
        numOfPrivateRooms
        dormBeds
        numOfTotalRooms
        numOfTotalBeds
        numOfStaffRooms
        numOfInventoryRooms
        dealStage
        furnishingType
        propertyType
        hostelTotalArea
        constructionAge
        cafe
        cafeScale
        electricalService {
          electricalConnectionType
          sanctionedElectricalLoad
        }
        totalSanctionedElectricalLoad
        waterService {
          waterConnectionType
          sanctionedWaterSupply
        }
        totalSanctionedWaterSupplyPerDay

        dealSource
        brokerName
        brokerageAmount
        operationalModel
        omcBrandFees
        omcPlatformFees
        floorPlans {
          imageURL
        }
        cadDrawings {
          imageURL
        }
        picturesVideosDriveLink
        titleDocuments {
          imageURL
        }
        ocCCCertificate {
          imageURL
        }
        titleSearchReport {
          imageURL
        }
        propertyPreSigningAuditReport {
          imageURL
        }
        signedMOU {
          imageURL
        }
        agreementExecutionDate
        lessorHandoverDate
        lessorHandoverDateLogs
        transformationHandoverDate
        staffJoiningDate
        photoshootDate
        goLiveDate
        goLiveDateLogs
        rentStartDate
        rentStartDateLogs
        rentEndDate
        rentEndDateLogs
        fitOutPeriod
        lessorLockInPeriod
        lesseeLockInPeriod
        zeroPenaltyGracePeriod
        penaltyPerDay
        terminationNotice
        exitingDuration
        ageOfHostel
        advanceRent
        advanceRentRepaymentTimeline
        transformationPreLiquidatedAmount
        sublettingRights
        majorRMResponsibility
        minorRMResponsibility
        majorRMCurePeriod
        majorRMCurePeriodPenaltyMultiplier
        forceMajeure1to3
        forceMajeure4to6
        forceMajeure6plus
        lesseeEODPeriod
        lessorEODPeriod
        monthlyRentType
        monthlyFixedRent
        monthlyRevenueShare
        rentPayableDate
        rentalIncrementFreq
        rentalPaymentFreq
        rentalIncrementPercent
        rentPerRoom
        rentPerBed
        leaseDuration
        securityDepositToken
        securityDepositRegistration
        securityDepositHandover
        securityDepositRentStart
        totalSecurityDeposit

        lessorEntityType
        lessorEntityFullName
        llpRegistrationNumber
        llpRegistrationCertificate {
          imageURL
        }
        llpRegisteredAddress
        llpRepresentativeName
        llpRepresentativeAddress
        llpRepresentativeAadharNumber
        llpRepresentativeAadharCard {
          imageURL
        }
        llpRepresentativePANNumber
        llpRepresentativePANCard {
          imageURL
        }
        llpAuthorizedAttorney {
          imageURL
        }
        lessorGstinNumber
        lessorGstinCertificate {
          imageURL
        }
        bankName
        bankAccountType
        bankBeneficiaryName
        bankAccountNumber
        bankIfscCode
        cancelledCheque {
          imageURL
        }

        lessorIndividualMultiple {
          lessorName
          lessorAge
          lessorFatherName
          lessorCompleteAddress
          lessorAadharNumber
          lessorAadharCard
          lessorPANNumber
          lessorPANCard
          lessorGstinNumber
          lessorGstinCertificate
          lessorPartnershipPercent
          bankName
          bankAccountType
          bankBeneficiaryName
          bankAccountNumber
          bankIfscCode
          cancelledCheque
        }

        lessorPartnership {
          lessorName
          lessorAge
          lessorFatherName
          lessorCompleteAddress
          lessorAadharNumber
          lessorAadharCard
          lessorPANNumber
          lessorPANCard
          lessorGstinNumber
          lessorGstinCertificate
          lessorPartnershipPercent
        }

        pocFullName
        pocMobileNumber
        pocEmail

        leaseDeedType
        leaseDeed {
          imageURL
        }
        legalJurisdictionCity
        legalJurisdictionState
        leaseDeedBearer
        stampDutyPercent
        stampDutyCharges
        registeredLicensesEntity
        licensesResponsibility
        licensesFeesResponsibility

        municipalityTradeValid
        municipalityTradeValidUpto
        municipalityTradeDoc {
          imageURL
        }

        healthSanitaryValid
        healthSanitaryValidUpto
        healthSanitaryDoc {
          imageURL
        }

        labourValid
        labourValidUpto
        labourDoc {
          imageURL
        }

        pcbCTOValid
        pcbCTOValidUpto
        pcbCTODoc {
          imageURL
        }

        fireNOCValid
        fireNOCValidUpto
        fireNOCDoc {
          imageURL
        }

        tourismValid
        tourismValidUpto
        tourismDoc {
          imageURL
        }

        pccNOCValid
        pccNOCValidUpto
        pccNOCDoc {
          imageURL
        }

        exciseBarValid
        exciseBarValidUpto
        exciseBarDoc {
          imageURL
        }

        shopEstabValid
        shopEstabValidUpto
        shopEstabDoc {
          imageURL
        }

        fssaiValid
        fssaiValidUpto
        fssaiDoc {
          imageURL
        }

        gstValid
        gstValidUpto
        gstDoc {
          imageURL
        }

        liftClearValid
        liftClearValidUpto
        liftClearDoc {
          imageURL
        }

        musicNOCValid
        musicNOCValidUpto
        musicNOCDoc {
          imageURL
        }

        sinageNOCValid
        sinageNOCValidUpto
        sinageNOCDoc {
          imageURL
        }

        liftPurchaseInvoice {
          imageURL
        }
        liftPurchaseDate
        liftWarrantyPeriod
        liftLessorLiabilityAMCStatus
        liftLessorLiabilityAMCMonths
        liftAMCType
        liftAMCCopy {
          imageURL
        }
        liftLastServicedDate
        liftLastServiceReport {
          imageURL
        }

        dgPurchaseInvoice {
          imageURL
        }
        dgPurchaseDate
        dgWarrantyPeriod
        dgLessorLiabilityAMCStatus
        dgLessorLiabilityAMCMonths
        dgAMCType
        dgAMCCopy {
          imageURL
        }
        dgLastServicedDate
        dgLastServiceReport {
          imageURL
        }

        heatPumpGeysersPurchaseInvoice {
          imageURL
        }
        heatPumpGeysersPurchaseDate
        heatPumpGeysersWarrantyPeriod
        heatPumpGeysersLessorLiabilityAMCStatus
        heatPumpGeysersLessorLiabilityAMCMonths
        heatPumpGeysersAMCType
        heatPumpGeysersAMCCopy {
          imageURL
        }
        heatPumpGeysersLastServicedDate
        heatPumpGeysersLastServiceReport {
          imageURL
        }

        AirConditionersPurchaseInvoice {
          imageURL
        }
        AirConditionersPurchaseDate
        AirConditionersWarrantyPeriod
        AirConditionersLessorLiabilityAMCStatus
        AirConditionersLessorLiabilityAMCMonths
        AirConditionersAMCType
        AirConditionersAMCCopy {
          imageURL
        }
        AirConditionersLastServicedDate
        AirConditionersLastServiceReport {
          imageURL
        }

        kitchenEquipPurchaseInvoice {
          imageURL
        }
        kitchenEquipPurchaseDate
        kitchenEquipWarrantyPeriod
        kitchenEquipLessorLiabilityAMCStatus
        kitchenEquipLessorLiabilityAMCMonths
        kitchenEquipAMCType
        kitchenEquipAMCCopy {
          imageURL
        }
        kitchenEquipLastServicedDate
        kitchenEquipLastServiceReport {
          imageURL
        }

        stpPurchaseInvoice {
          imageURL
        }
        stpPurchaseDate
        stpWarrantyPeriod
        stpLessorLiabilityAMCStatus
        stpLessorLiabilityAMCMonths
        stpAMCType
        stpAMCCopy {
          imageURL
        }
        stpLastServicedDate
        stpLastServiceReport {
          imageURL
        }

        toiletFittingsPurchaseInvoice {
          imageURL
        }
        toiletFittingsPurchaseDate
        toiletFittingsWarrantyPeriod
        toiletFittingsLessorLiabilityAMCStatus
        toiletFittingsLessorLiabilityAMCMonths
        toiletFittingsAMCType
        toiletFittingsAMCCopy {
          imageURL
        }
        toiletFittingsLastServicedDate
        toiletFittingsLastServiceReport {
          imageURL
        }

        heavyEquipPurchaseInvoice {
          imageURL
        }
        heavyEquipPurchaseDate
        heavyEquipWarrantyPeriod
        heavyEquipLessorLiabilityAMCStatus
        heavyEquipLessorLiabilityAMCMonths
        heavyEquipAMCType
        heavyEquipAMCCopy {
          imageURL
        }
        heavyEquipLastServicedDate
        heavyEquipLastServiceReport {
          imageURL
        }

        logs
      }
    }
  }
`;

export const useGetPaginatedSupply = (limit, page, filteredData) => {
  const { loading, error, data, refetch } = useQuery(GET_PAGINATED_SUPPLY, {
    variables: {
      supplyFilter: {
        limit,
        page,
        ...filteredData,
      },
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_SUPPLY_BY_ID = gql`
  query getSupplyById($id: ID) {
    getSupplyById(id: $id) {
      _id
      hostelPrefix
      localityNamePresent
      localityName
      hostelCity
      hostelName
      hostelState
      hostelRegion
      hostelCompleteAddress
      googleMapsPin
      numOfDormRooms
      numOfPrivateRooms
      dormBeds
      numOfTotalRooms
      numOfTotalBeds
      numOfStaffRooms
      numOfInventoryRooms
      dealStage
      furnishingType
      propertyType
      hostelTotalArea
      constructionAge
      cafe
      cafeScale
      electricalService {
        electricalConnectionType
        sanctionedElectricalLoad
      }
      totalSanctionedElectricalLoad
      waterService {
        waterConnectionType
        sanctionedWaterSupply
      }
      totalSanctionedWaterSupplyPerDay

      dealSource
      brokerName
      brokerageAmount
      operationalModel
      omcBrandFees
      omcPhotographyFees
      omcDesignFees
      omcPlatformFees
      floorPlans {
        imageURL
        imageName
      }
      cadDrawings {
        imageURL
        imageName
      }
      picturesVideosDriveLink
      titleDocuments {
        imageURL
        imageName
      }
      ocCCCertificate {
        imageURL
        imageName
      }
      titleSearchReport {
        imageURL
        imageName
      }
      propertyPreSigningAuditReport {
        imageURL
        imageName
      }
      signedMOU {
        imageURL
        imageName
      }
      agreementExecutionDate
      lessorHandoverDate
      lessorHandoverDateLogs
      transformationHandoverDate
      staffJoiningDate
      photoshootDate
      goLiveDate
      zeroRentPeriodInDays
      goLiveDateLogs
      rentStartDate
      rentStartDateLogs
      rentEndDate
      rentEndDateLogs
      fitOutPeriod
      lessorLockInPeriod
      lesseeLockInPeriod
      zeroPenaltyGracePeriod
      penaltyPerDay
      terminationNotice
      exitingDuration
      ageOfHostel
      advanceRent
      advanceRentRepaymentTimeline
      transformationPreLiquidatedAmount
      sublettingRights
      majorRMResponsibility
      minorRMResponsibility
      majorRMCurePeriod
      majorRMCurePeriodPenaltyMultiplier
      forceMajeure1to3
      forceMajeure4to6
      forceMajeure6plus
      lesseeEODPeriod
      lessorEODPeriod
      monthlyRentType
      monthlyFixedRent
      monthlyRevenueShare
      monthlyFNBRevenueShare
      monthlyAncillaryRevenueShare
      rentPayableDate
      rentalIncrementFreq
      rentalPaymentFreq
      rentalIncrementPercent
      rentPerRoom
      rentPerBed
      leaseDuration
      securityDepositToken
      securityDepositRegistration
      securityDepositHandover
      securityDepositRentStart
      totalSecurityDeposit

      lessorEntityType
      lessorEntityFullName
      llpRegistrationNumber
      llpRegistrationCertificate {
        imageURL
        imageName
      }
      llpRegisteredAddress
      llpRepresentativeName
      llpRepresentativeAddress
      llpRepresentativeAadharNumber
      llpRepresentativeAadharCard {
        imageURL
        imageName
      }
      llpRepresentativePANNumber
      llpRepresentativePANCard {
        imageURL
        imageName
      }
      llpAuthorizedAttorney {
        imageURL
        imageName
      }
      lessorGstinNumber
      lessorGstinCertificate {
        imageURL
        imageName
      }
      bankName
      bankAccountType
      bankBeneficiaryName
      bankAccountNumber
      bankIfscCode
      cancelledCheque {
        imageURL
        imageName
      }

      lessorIndividualMultiple {
        lessorName
        lessorAge
        lessorFatherName
        lessorCompleteAddress
        lessorAadharNumber
        lessorAadharCard
        lessorPANNumber
        lessorPANCard
        lessorGstinNumber
        lessorGstinCertificate
        lessorPartnershipPercent
        bankName
        bankAccountType
        bankBeneficiaryName
        bankAccountNumber
        bankIfscCode
        cancelledCheque
      }

      lessorPartnership {
        lessorName
        lessorAge
        lessorFatherName
        lessorCompleteAddress
        lessorAadharNumber
        lessorAadharCard
        lessorPANNumber
        lessorPANCard
        lessorGstinNumber
        lessorGstinCertificate
        lessorPartnershipPercent
      }

      pocFullName
      pocMobileNumber
      pocEmail

      leaseDeedType
      leaseDeed {
        imageURL
        imageName
      }
      legalJurisdictionCity
      legalJurisdictionState
      leaseDeedBearer
      stampDutyPercent
      stampDutyCharges
      registeredLicensesEntity
      licensesResponsibility
      licensesFeesResponsibility

      municipalityTradeValid
      municipalityTradeValidUpto
      municipalityTradeDoc {
        imageURL
        imageName
      }

      healthSanitaryValid
      healthSanitaryValidUpto
      healthSanitaryDoc {
        imageURL
        imageName
      }

      labourValid
      labourValidUpto
      labourDoc {
        imageURL
        imageName
      }

      pcbCTOValid
      pcbCTOValidUpto
      pcbCTODoc {
        imageURL
        imageName
      }

      fireNOCValid
      fireNOCValidUpto
      fireNOCDoc {
        imageURL
        imageName
      }

      tourismValid
      tourismValidUpto
      tourismDoc {
        imageURL
        imageName
      }

      pccNOCValid
      pccNOCValidUpto
      pccNOCDoc {
        imageURL
        imageName
      }

      exciseBarValid
      exciseBarValidUpto
      exciseBarDoc {
        imageURL
        imageName
      }

      shopEstabValid
      shopEstabValidUpto
      shopEstabDoc {
        imageURL
        imageName
      }

      fssaiValid
      fssaiValidUpto
      fssaiDoc {
        imageURL
        imageName
      }

      gstValid
      gstValidUpto
      gstDoc {
        imageURL
        imageName
      }

      liftClearValid
      liftClearValidUpto
      liftClearDoc {
        imageURL
        imageName
      }

      musicNOCValid
      musicNOCValidUpto
      musicNOCDoc {
        imageURL
        imageName
      }

      sinageNOCValid
      sinageNOCValidUpto
      sinageNOCDoc {
        imageURL
        imageName
      }

      liftPresent
      liftPurchaseInvoice {
        imageURL
        imageName
      }
      liftPurchaseDate
      liftWarrantyPeriod
      liftLessorLiabilityAMCStatus
            liftAMCStartDate,
      liftAMCEndDate,
      liftLessorLiabilityAMCMonths
      liftAMCType
      liftAMCCopy {
        imageURL
        imageName
      }
      liftLastServicedDate
      liftLastServiceReport {
        imageURL
        imageName
      }

      dgPresent
      dgPurchaseInvoice {
        imageURL
        imageName
      }
      dgPurchaseDate
      dgWarrantyPeriod
      dgLessorLiabilityAMCStatus
            dgAMCStartDate,
      dgAMCEndDate,
      dgLessorLiabilityAMCMonths
      dgAMCType
      dgAMCCopy {
        imageURL
        imageName
      }
      dgLastServicedDate
      dgLastServiceReport {
        imageURL
        imageName
      }

      heatPumpPresent
      heatPumpGeysersPurchaseInvoice {
        imageURL
        imageName
      }
      heatPumpGeysersPurchaseDate
      heatPumpGeysersWarrantyPeriod
      heatPumpGeysersLessorLiabilityAMCStatus
            heatPumpAMCStartDate,
      heatPumpAMCEndDate,
      heatPumpGeysersLessorLiabilityAMCMonths
      heatPumpGeysersAMCType
      heatPumpGeysersAMCCopy {
        imageURL
        imageName
      }
      heatPumpGeysersLastServicedDate
      heatPumpGeysersLastServiceReport {
        imageURL
        imageName
      }

      airConditionerPresent
      AirConditionersPurchaseInvoice {
        imageURL
        imageName
      }
      AirConditionersPurchaseDate
      AirConditionersWarrantyPeriod
      AirConditionersLessorLiabilityAMCStatus
            airconditionerAMCStartDate,
      airconditionerAMCEndDate,
      AirConditionersLessorLiabilityAMCMonths
      AirConditionersAMCType
      AirConditionersAMCCopy {
        imageURL
        imageName
      }
      AirConditionersLastServicedDate
      AirConditionersLastServiceReport {
        imageURL
        imageName
      }

      kitchenEquipmentPresent
      kitchenEquipPurchaseInvoice {
        imageURL
        imageName
      }
      kitchenEquipPurchaseDate
      kitchenEquipWarrantyPeriod
      kitchenEquipLessorLiabilityAMCStatus
            kitchenEquipAMCStartDate,
      kitchenEquipAMCEndDate,
      kitchenEquipLessorLiabilityAMCMonths
      kitchenEquipAMCType
      kitchenEquipAMCCopy {
        imageURL
        imageName
      }
      kitchenEquipLastServicedDate
      kitchenEquipLastServiceReport {
        imageURL
        imageName
      }

      stpPresent
      stpPurchaseInvoice {
        imageURL
        imageName
      }
      stpPurchaseDate
      stpWarrantyPeriod
      stpLessorLiabilityAMCStatus
            stpAMCStartDate,
      stpAMCEndDate,
      stpLessorLiabilityAMCMonths
      stpAMCType
      stpAMCCopy {
        imageURL
        imageName
      }
      stpLastServicedDate
      stpLastServiceReport {
        imageURL
        imageName
      }

      toiletFittingPresent
      toiletFittingsPurchaseInvoice {
        imageURL
        imageName
      }
      toiletFittingsPurchaseDate
      toiletFittingsWarrantyPeriod
      toiletFittingsLessorLiabilityAMCStatus
            toiletFittingsAMCStartDate,
      toiletFittingsAMCEndDate,
      toiletFittingsLessorLiabilityAMCMonths
      toiletFittingsAMCType
      toiletFittingsAMCCopy {
        imageURL
        imageName
      }
      toiletFittingsLastServicedDate
      toiletFittingsLastServiceReport {
        imageURL
        imageName
      }

      heavyEquipPurchaseInvoice {
        imageURL
        imageName
      }
      heavyEquipPurchaseDate
      heavyEquipWarrantyPeriod
      heavyEquipLessorLiabilityAMCStatus
      heavyEquipLessorLiabilityAMCMonths
      heavyEquipAMCType
      heavyEquipAMCCopy {
        imageURL
        imageName
      }
      heavyEquipLastServicedDate
      heavyEquipLastServiceReport {
        imageURL
        imageName
      }
      addedAssets{
          uuId
          assetName
          uploadPurchaseInvoice{
            imageURL
            imageName
          }
          purchaseDate
          warrantyPeriod
                    lastServiceReport{
            imageURL
            imageName
          }
          amcStatus
          amcStartDate
          amcEndDate
          amcMonths
          amcType
          amcInvoice{
            imageURL
            imageName
          }
          lastServiceDate
          lastServiceReport{
            imageURL
            imageName
          }
      }
        timelineDates{
          fitOutPeriod
          agreementExecutionDate
          fitOutPeriod
          lessorHandoverDate
          transformationHandoverDate
          staffJoiningDate
          photoshootDate
          goLiveDate
          createdAt
        }
          hostelStage

      logs
    }
  }
`;

export const useGetSupplyById = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_SUPPLY_BY_ID, {
    variables: {
      id: id,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
