// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateHostel } from "../../../../api/dataSource";
import { useParams } from "react-router";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

const TimingInformation = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);

  const [checkin, setCheckin] = useState("");
  const [checkout, setCheckout] = useState("");
  const [guestVisit, setGuestVisit] = useState("");
  const [cafe, setCafe] = useState("");
  const [reception, setReception] = useState("");
  const [other, setOther] = useState("");
  const [silentHours, setSilentHours] = useState("");
  const [breakfast, setBreakfast] = useState("");
  const [lunch, setLunch] = useState("");
  const [dinner, setDinner] = useState("");
  const [pool, setPool] = useState("");
  const [hotWater, setHotWater] = useState("");
  const [chatSupport, setChatSupport] = useState("");

  const dispatch = useDispatch();

  const [updateHostelHandler, { data: updatdedHostelData, error }] =
    useUpdateHostel();

  useEffect(() => {
    let prePopulateData;

    if (state.length) {
      tableDataHandler({
        ...state?.tableData,
        cardData: {
          checkin,
          checkout,
          guestVisit,
          cafe,
          reception,
          other,
        },
      });
    }

    if (prePopulateData) {
      setCheckin(prePopulateData?.timingInformation.checkin);
      setCheckout(prePopulateData?.timingInformation.checkout);
      setGuestVisit(prePopulateData?.timingInformation.guestVisit);
      setCafe(prePopulateData?.timingInformation.cafe);
      setReception(prePopulateData?.timingInformation.reception);
      setOther(prePopulateData?.timingInformation.other);
    } else {
      setCheckin("");
      setCheckout("");
      setGuestVisit("");
      setCafe("");
      setReception("");
      setOther("");
    }
  }, [state]);

  useEffect(() => {
    if (state.tableData) {
      setCheckin(state.tableData?.timingInformation?.checkin);
      setCheckout(state.tableData?.timingInformation?.checkout);
      setGuestVisit(state.tableData?.timingInformation?.guestVisit);
      setCafe(state.tableData?.timingInformation?.cafe);
      setReception(state.tableData?.timingInformation?.reception);
      setOther(state.tableData?.timingInformation?.other);
      setSilentHours(state.tableData?.timingInformation?.silentHours);
      setBreakfast(state.tableData?.timingInformation?.breakfast);
      setLunch(state.tableData?.timingInformation?.lunch);
      setDinner(state.tableData?.timingInformation?.dinner);
      setHotWater(state.tableData?.timingInformation?.hotWater);
      setPool(state.tableData?.timingInformation?.pool);
      setChatSupport(state.tableData?.timingInformation?.chatSupport);
    } else {
      setCheckin("");
      setCheckout("");
      setGuestVisit("");
      setCafe("");
      setReception("");
      setOther("");
      setSilentHours("");
      setBreakfast("");
      setLunch("");
      setDinner("");
      setHotWater("");
      setPool("");
      setChatSupport("");
    }
  }, [state.tableData]);

  const submitHandler = (e) => {
    // let isValid = false;
    e.preventDefault();

    const data = {
      checkin,
      checkout,
      guestVisit,
      cafe,
      reception,
      other,
      silentHours,
      breakfast,
      lunch,
      dinner,
      pool,
      chatSupport,
      hotWater,
    };

    if (id) {
      updateHostelHandler(id, {
        timingInformation: data,
        sectionName: "Timing Information",
      });

      toaster("success", "Timing information updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...{ timingInformation: data },
        })
      );
    } else {
      toaster("error", "Please fill the details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Check In
            </Label>

            <Input
              type="text"
              value={checkin}
              id="checkin"
              name="checkin"
              placeholder="Check In"
              onChange={(e) => setCheckin(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Check Out
            </Label>

            <Input
              type="text"
              value={checkout}
              id="checkout"
              name="checkout"
              placeholder="Check Out"
              onChange={(e) => setCheckout(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Guest Visit
            </Label>

            <Input
              type="text"
              value={guestVisit}
              id="guestVisit"
              name="guestVisit"
              placeholder="Guest Visit"
              onChange={(e) => setGuestVisit(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Cafe (not in use)
            </Label>

            <Input
              type="text"
              value={cafe}
              id="cafe"
              name="cafe"
              placeholder="Cafe"
              onChange={(e) => setCafe(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Reception
            </Label>

            <Input
              type="text"
              value={reception}
              id="reception"
              name="reception"
              placeholder="Reception"
              onChange={(e) => setReception(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="newDetailsState">
              Common Area
            </Label>

            <Input
              type="text"
              value={other}
              id="other"
              name="other"
              placeholder="Common Area"
              onChange={(e) => setOther(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="silentHours">
              Silent hours
            </Label>

            <Input
              type="text"
              value={silentHours}
              id="silentHours"
              name="silentHours"
              placeholder="Silent hours"
              onChange={(e) => setSilentHours(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="breakfast">
              Breakfast
            </Label>

            <Input
              type="text"
              value={breakfast}
              id="breakfast"
              name="breakfast"
              placeholder="Breakfast"
              onChange={(e) => setBreakfast(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="lunch">
              Lunch
            </Label>

            <Input
              type="text"
              value={lunch}
              id="lunch"
              name="lunch"
              placeholder="Lunch"
              onChange={(e) => setLunch(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="dinner">
              Dinner
            </Label>

            <Input
              type="text"
              value={dinner}
              id="dinner"
              name="dinner"
              placeholder="Dinner"
              onChange={(e) => setDinner(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="pool">
              Pool
            </Label>

            <Input
              type="text"
              value={pool}
              id="pool"
              name="pool"
              placeholder="Pool"
              onChange={(e) => setPool(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="hotWater">
              Hot water
            </Label>

            <Input
              type="text"
              value={hotWater}
              id="hotWater"
              name="hotWater"
              placeholder="Hot water"
              onChange={(e) => setHotWater(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="chatSupport">
              Chat support
            </Label>

            <Input
              type="text"
              value={chatSupport}
              id="chatSupport"
              name="chatSupport"
              placeholder="Chat support"
              onChange={(e) => setChatSupport(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default TimingInformation;
