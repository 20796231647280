import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useLoadingHandler } from "../../dataSource";

export const UPDATE_EZEE_MEALS = gql`
  mutation ($id: ID, $ezeeMealUpdate: EzeeMealInput) {
    updateEzeeMeal(id: $id, ezeeMealUpdate: $ezeeMealUpdate) {
      status
      message
    }
  }
`;


//added by ganesh for updating mobile & email
export const UPDATE_EZEE_BOOKING = gql`
   mutation updateEzeeBooking($uniqueId:String,$updateParams:updateParams){
        updateEzeeBooking(uniqueId:$uniqueId,updateParams:$updateParams){
          Email
        }
   }
`


export const useUpdateEzeeBookingEmail = () => {
  const [updateBooking, { loading, error, data, refetch }] = useMutation(UPDATE_EZEE_BOOKING)

  useLoadingHandler(loading)

  const updateHandler = (uniqueId, updateParams) => {
    updateBooking({
      variables: {
        uniqueId: uniqueId, updateParams: updateParams
      }
    })
  }

  return [updateHandler, { data, error, refetch }]
}