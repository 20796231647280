import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateHostel } from "../../../../api/dataSource";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

const Automation = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [smartLockId, setSmartLockId] = useState("");
  const [wifiPassword, setWifiPassword] = useState("");

  const [updateHostelHandler] = useUpdateHostel();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.tableData?._id) {
      setSmartLockId(state?.tableData?.smartLockId);
      setWifiPassword(state?.tableData?.wifiPassword);
    } else {
      setSmartLockId("");
      setWifiPassword("");
    }
  }, [state]);

  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      smartLockId,
      wifiPassword,
      sectionName: "Automation",
    };

    // if (id && data?.smartLockId) {
    updateHostelHandler(id, data);

    toaster("success", "Automation details updated successfully");
    dispatch(
      tableDataHandler({
        ...state?.tableData,
        smartLockId,
        wifiPassword,
      })
    );
    // } else {
    //   toaster("error", "Please fill the details");
    // }
  };

  return (
    <form className="mt-2" onSubmit={submitHandler}>
      <Row>
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="smartLockId">
              Smart Lock Id
            </Label>

            <Input
              type="text"
              value={smartLockId}
              id="smartLockId"
              name="smartLockId"
              placeholder="Smart Lock Id"
              onChange={(e) => setSmartLockId(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" htmlFor="wifiPassword">
              Wifi Password
            </Label>

            <Input
              type="text"
              value={wifiPassword}
              id="wifiPassword"
              name="wifiPassword"
              placeholder="Wifi Password"
              onChange={(e) => setWifiPassword(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default Automation;
