import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_LAUNDRY_MONTHLY_DATA_BY_HOSTELID = gql`
  query ($hostelId: ID!, $year: Int!, $month: Int!) {
    getLaundryMonthlyDataByHostelId(hostelId: $hostelId, year: $year, month: $month) {
      _id
      hostelId {
        _id
        name
    }
        laundryVendorId
        itemName
        year
        month
        monthlyTotalQuantity
        actualTotalQuantity
        difference
        logs
    }
  }
`;

export const useGetLaundryMonthlyDataByHostelId = (hostelId, year, month) => {
  const { loading, error, data, refetch } = useQuery(GET_LAUNDRY_MONTHLY_DATA_BY_HOSTELID, 
    {
        variables: { hostelId, year, month },
        skip: !hostelId || !year || !month, 
    }
    );
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};