//  Added by Nitish

import React, { useState, useEffect } from "react";
import ServiceConfigTable from "./ServiceConfigTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "../../../@core/components/common/TablePagination";
import {
  useGetPaginatedServiceList,
  useGetHostelsNameandId,
} from "../../../api/dataSource";
import { Col, Row, FormGroup, Label, Button, Input } from "reactstrap";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const statusOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];
const ServiceConfig = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const limit = 20;
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [hostelIdArray, setHostelIdArray] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const { data: allHostelsData } = useGetHostelsNameandId();
  const [filterParams, setFilterParams] = useState(null);
  const [category, setCategory] = useState(null);
  const [title, setTitle] = useState("");
  const [categoryDropdownList, setCategoryDropdownList] = useState(null);
  const [status, setStatus] = useState(null);

  const filter = {
    limit,
    page,
    hostelIdArray:
      filterParams?.hostelIdArray?.length > 0
        ? filterParams.hostelIdArray.map((hostel) => hostel.value)
        : JSON.parse(localStorage.getItem("hostels")),
    category: filterParams?.category ? filterParams.category.value : null,
    title: filterParams?.title?.length ? filterParams?.title : null,
    status: filterParams?.status ? filterParams?.status?.value : null,
  };

  const { data, refetch } = useGetPaginatedServiceList(filter);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const submitHandler = () => {
    setFilterParams({
      hostelIdArray,
      category,
      title,
      status,
    });
    setPage(1);
  };

  const refreshHandler = () => {
    try {
      setHostelIdArray([]);
      setCategory(null);
      setFilterParams(null);
      setTitle("");
      setPage(1);
      setStatus(null);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const allowedHostel = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelsNameandId?.length) {
      const filteredHostels =
        allowedHostel !== null && allowedHostel?.length !== 0
          ? allowedHostel?.map((hostelID) => {
            return allHostelsData?.getHostelsNameandId?.find(
              (item) => item._id === hostelID
            );
          })
          : allHostelsData?.getHostelsNameandId;
      let HostelList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });

      setHostelsList(HostelList);
    }
  }, [allHostelsData?.getHostelsNameandId, data?.getHostelsNameandId]);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data?.getPaginatedServiceList?.data?.length > 0) {
      setTableData(data.getPaginatedServiceList?.data);
      setTotalPage(Math.ceil(data.getPaginatedServiceList.count / limit));
    } else {
      setTableData([]);
      setTotalPage(1);
    }
  }, [
    data?.getPaginatedServiceList?.data,
    data?.getPaginatedServiceList?.count,
    limit,
  ]);

  useEffect(() => {
    if (data?.getPaginatedServiceList?.data.length) {
      let editData = data?.getPaginatedServiceList?.data?.find(
        (item) => item._id === id
      );
      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedServiceList?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    const categoryDropdownList = [
      { label: "Add-ons", value: "Add-ons" },
      { label: "Rentals", value: "Rentals" },
      { label: "Transfers", value: "Transfers" },
      { label: "Hostel Essentials", value: "Hostel Essentials" },
      { label: "Local Activities", value: "Local Activities" },
      { label: "Vehicle Rentals", value: "Vehicle Rentals" },
      { label: "Pickup", value: "Pickup" },
      { label: "Drop", value: "Drop" },
      { label: "Sightseeing", value: "Sightseeing" },
      { label: "Car Transfers", value: "Car Transfers" },
    ];

    if (data?.getPaginatedServiceList?.data?.length !== 0) {
      setCategoryDropdownList(categoryDropdownList);
    }
  }, [data?.getPaginatedServiceList?.data]);

  return (
    (localStorage.getItem("modules")?.includes("Glu Configuration") || localStorage.getItem("modules")?.includes("HE Inventory")) && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                <strong>Hostel Name</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostelIdArray}
                options={HostelsList}
                onChange={(value) => {
                  setHostelIdArray(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                <strong>Service type</strong>
              </Label>
              <Select
                isClearable={true}
                id="product"
                name="product"
                value={category}
                options={categoryDropdownList}
                onChange={(value) => {
                  setCategory(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="title">
                <strong>Title</strong>
              </Label>
              <Input
                type="text"
                id="title"
                name="title"
                placeholder="Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="status">
                <strong>Status</strong>
              </Label>
              <Select
                isClearable={true}
                id="status"
                name="status"
                value={status}
                options={statusOptions}
                onChange={(value) => {
                  setStatus(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                submitHandler();
              }}
            >
              Search
            </Button>

            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                refreshHandler();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        <ServiceConfigTable
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          editData={editData}
          refetch={refetch}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
      </div>
    )
  );
};

export default ServiceConfig;
