// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { useCreateSupply } from "../../../api/Supply/Mutation";
import toaster from "@components/common/Toaster";
import SlideModal from "./slide-modal/index";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { dateFormatter } from "@utils";
import { indianStateList } from "../../../utility/Utils";
import AttachmentComponent from "./AttachmentComponent";
import {
  HostelPrefixList,
  HostelRegionList,
  DealStageList,
  FurnishingTypeList,
  CafeList,
  CafeScaleList,
  ElectricalConnectionTypeList,
  WaterConnectionTypeList,
  PropertyTypeList,
  DealSourceTypeList,
  OperationalModelTypeList,
  LessorEntityTypeList,
  BankAccountTypeList,
  LeaseDeedTypeList,
  LicensesObtainanceOrRenewalResponsibilityTypeList,
  LicensesOfficialFeesResponsibilityTypeList,
  ValidTypeList,
  MonthlyRentTypeList,
  RentalIncrementFrequencyTypeList,
  RentalPaymentFrequencyTypeList,
  SublettingRightsTypeList,
  YesNoDropdownOptions,
} from "./selectOptionsList";
import { useUploadImage } from "../../../api/dataSource";
import Flatpickr from "react-flatpickr";
import { v4 as uuidv4 } from "uuid";
import AddButton from "./addButton";
import { Image, PlusCircle, Upload } from "react-feather";
import ImageModal from "./ImageModal";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [hostelPrefix, setHostelPrefix] = useState("");
  const [localityNamePresent, setLocalityNamePresent] = useState(null)
  const [localityName, setLocalityName] = useState(null);
  const [hostelName, setHostelName] = useState(null);
  const [hostelCity, setHostelCity] = useState(null);
  const [hostelState, setHostelState] = useState(null);
  const [hostelRegion, setHostelRegion] = useState(null);
  const [hostelCompleteAddress, setHostelCompleteAddress] = useState(null);
  const [googleMapsPin, setGoogleMapsPin] = useState(null);
  const [numOfDormRooms, setNumOfDormRooms] = useState(null);
  const [numOfPrivateRooms, setNumOfPrivateRooms] = useState(null);
  const [dormBeds, setDormBeds] = useState(null);
  const [numOfTotalRooms, setNumOfTotalRooms] = useState(null);
  const [numOfTotalBeds, setNumOfTotalBeds] = useState(null);
  const [numOfStaffRooms, setNumOfStaffRooms] = useState(null);
  const [numOfInventoryRooms, setNumOfInventoryRooms] = useState(null);
  const [dealStage, setDealStage] = useState(null);
  const [furnishingType, setFurnishingType] = useState(null);
  const [hostelTotalArea, setHostelTotalArea] = useState(null);
  const [constructionAge, setConstructionAge] = useState(null);
  const [cafe, setCafe] = useState(null);
  const [cafeScale, setCafeScale] = useState(null);
  // const [electricalConnectionType, setElectricalConnectionType] = useState("");
  // const [sanctionedElectricalLoad, setSanctionedElectricalLoad] = useState("");
  // const [waterConnectionType, setWaterConnectionType] = useState("");
  // const [sanctionedWaterSupply, setSanctionedWaterSupply] = useState("");
  const [propertyType, setPropertyType] = useState(null);
  const [floorPlans, setFloorPlans] = useState([]);
  const [cadDrawings, setCADDrawings] = useState([]);
  const [picturesVideosDriveLink, setPicturesVideosDriveLink] = useState(null);
  // const [pictures, setPictures] = useState([]);
  // const [videos, setVideos] = useState([]);
  const [titleDocuments, setTitleDocuments] = useState([]);
  const [ocCCCertificate, setOCCCCertificate] = useState([]);

  const [titleSearchReport, setTitleSearchReport] = useState([]);
  const [propertyPreSigningAuditReport, setPropertyPreSigningAuditReport] =
    useState([]);
  const [agreementExecutionDate, setAgreementExecutionDate] = useState(null);
  const [lessorHandoverDate, setLessorHandoverDate] = useState(null);

  const [transformationHandoverDate, setTransformationHandoverDate] =
    useState(null);
  const [staffJoiningDate, setStaffJoiningDate] = useState(null);
  const [photoshootDate, setPhotoshootDate] = useState(null);
  const [goLiveDate, setGoLiveDate] = useState(null);
  const [rentStartDate, setRentStartDate] = useState(null);
  const [rentEndDate, setRentEndDate] = useState(null);

  const [fitOutPeriod, setFitOutPeriod] = useState(null);
  const [lessorLockInPeriod, setLessorLockInPeriod] = useState(null);
  const [lesseeLockInPeriod, setLesseeLockInPeriod] = useState(null);
  const [zeroPenaltyGracePeriod, setZeroPenaltyGracePeriod] = useState(null);
  const [penaltyPerDay, setPenaltyPerDay] = useState(null);
  const [terminationNotice, setTerminationNotice] = useState(null);
  const [exitingDuration, setExitingDuration] = useState(null);
  const [ageOfHostel, setAgeOfHostel] = useState(null);
  const [advanceRent, setAdvanceRent] = useState(null);
  const [advanceRentRepaymentTimeline, setAdvanceRentRepaymentTimeline] = useState(null);
  const [
    transformationPreLiquidatedAmount,
    setTransformationPreLiquidatedAmount,
  ] = useState(null);
  const [sublettingRights, setSublettingRights] = useState(null);

  const [majorRMResponsibility, setMajorRMResponsibility] = useState(null);
  const [minorRMResponsibility, setMinorRMResponsibility] = useState(null);
  const [majorRMCurePeriod, setMajorRMCurePeriod] = useState(null);
  const [
    majorRMCurePeriodPenaltyMultiplier,
    setMajorRMCurePeriodPenaltyMultiplier,
  ] = useState(null);
  const [forceMajeure1to3, setForceMajeure1to3] = useState(null);
  const [forceMajeure4to6, setForceMajeure4to6] = useState(null);
  const [forceMajeure6plus, setForceMajeure6plus] = useState(null);
  const [lesseeEODPeriod, setLesseeEODPeriod] = useState(null);
  const [lessorEODPeriod, setLessorEODPeriod] = useState(null);
  const [lesseeEODCure, setLesseeEODCure] = useState(null);
  const [lessorEODCure, setLessorEODCure] = useState(null);
  const [monthlyRentType, setMonthlyRentType] = useState(null);
  const [monthlyFixedRent, setMonthlyFixedRent] = useState(null);
  const [monthlyRevenueShare, setMonthlyRevenueShare] = useState(null);
  const [monthlyFNBRevenueShare, setMontlyFNBRevenueShare] = useState(null)
  const [monthlyAncillaryRevenueShare, setMonthlyAncillaryRevenueShare] = useState(null);
  const [rentPayableDate, setRentPayableDate] = useState(null);
  const [rentalIncrementFreq, setRentalIncrementFreq] = useState(null);
  const [rentalPaymentFreq, setRentalPaymentFreq] = useState(null);
  const [rentalIncrementPercent, setRentalIncrementPercent] = useState(null);
  const [rentPerRoom, setRentPerRoom] = useState(null);
  const [rentPerBed, setRentPerBed] = useState(null);

  const [dealSource, setDealSource] = useState(null);
  const [brokerName, setBrokerName] = useState(null);
  const [brokerageAmount, setBrokerageAmount] = useState(null);
  const [operationalModel, setOperationalModel] = useState(null);
  const [omcBrandFees, setOMCBrandFees] = useState(null);
  const [omcPhotographyfees, setOMCPhotographyfees] = useState(null);
  const [omcDesignFees, setOMCDesignFees] = useState(null);
  const [omcPlatformFees, setOMCPlatformFees] = useState(null);
  const [lessorEntityType, setLessorEntityType] = useState(null);
  const [lessorEntityFullName, setLessorEntityFullName] = useState(null);
  // const [lessorAge, setLessorAge] = useState("");
  const [lessorFatherName, setLessorFatherName] = useState(null);
  const [lessorCompleteAddress, setLessorCompleteAddress] = useState(null);
  const [lessorAadharNumber, setLessorAadharNumber] = useState(null);
  const [lessorAadharCard, setLessorAadharCard] = useState([]);
  const [lessorPANNumber, setLessorPANNumber] = useState(null);
  const [lessorPANCard, setLessorPANCard] = useState([]);
  const [lessorPartnershipPercent, setLessorPartnershipPercent] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [bankAccountType, setBankAccountType] = useState(null);
  const [bankBeneficiaryName, setBankBeneficiaryName] = useState(null);
  const [bankAccountNumber, setBankAccountNumber] = useState(null);
  const [bankIfscCode, setBankIfscCode] = useState(null);
  const [cancelledCheque, setCancelledCheque] = useState([]);
  const [llpRegistrationNumber, setLlpRegistrationNumber] = useState(null);
  const [llpRegistrationCertificate, setLlpRegistrationCertificate] = useState(
    []
  );
  const [llpRegisteredAddress, setLlpRegisteredAddress] = useState(null);
  const [llpRepresentativeName, setLlpRepresentativeName] = useState(null);
  const [llpRepresentativeAddress, setLlpRepresentativeAddress] = useState(null);
  const [llpRepresentativeAadharNumber, setLlpRepresentativeAadharNumber] = useState(null);
  const [llpRepresentativeAadharCard, setLlpRepresentativeAadharCard] = useState([]);

  const [llpRepresentativePANNumber, setLlpRepresentativePANNumber] = useState(null);
  const [llpRepresentativePANCard, setLlpRepresentativePANCard] = useState([]);

  const [pocFullName, setPOCFullName] = useState(null);
  const [pocMobileNumber, setPOCMobileNumber] = useState(null);
  const [pocEmail, setPOCEmail] = useState(null);
  const [pocAadharNumber, setPOCAadharNumber] = useState(null);
  const [pocAadharCard, setPOCAadharCard] = useState([]);
  const [leaseDeedType, setLeaseDeedType] = useState(null);
  const [registeredLicensesEntity, setRegisteredLicensesEntity] = useState(null);
  const [licensesResponsibility, setLicensesResponsibility] = useState(null);
  const [licensesFeesResponsibility, setLicensesFeesResponsibility] =
    useState(null);
  const [monthlyRent, setMonthlyRent] = useState(null);
  const [leaseDuration, setLeaseDuration] = useState(null);
  const [securityDepositToken, setSecurityDepositToken] = useState(null);
  const [securityDepositRegistration, setSecurityDepositRegistration] =
    useState(null);
  const [securityDepositHandover, setSecurityDepositHandover] = useState(null);
  const [securityDepositRentStart, setSecurityDepositRentStart] = useState(null);
  const [totalSecurityDeposit, setTotalSecurityDeposit] = useState(null);
  const [ddReport, setDDReport] = useState([]);
  const [auditReport, setAuditReport] = useState([]);
  const [signedMOU, setSignedMOU] = useState([]);
  const [llpAuthorizedAttorney, setLlpAuthorizedAttorney] = useState([]);
  const [lessorGstinNumber, setLessorGstinNumber] = useState(null);
  const [lessorGstinCertificate, setLessorGstinCertificate] = useState([]);
  const [electricalService, setElectricalService] = useState([
    {
      id: uuidv4(),
      electricalConnectionType: "",
      sanctionedElectricalLoad: "",
    },
  ]);
  const [totalSanctionedElectricalLoad, setTotalSanctionedElectricalLoad] =
    useState(null);
  const [waterService, setWaterService] = useState([
    {
      id: uuidv4(),
      waterConnectionType: "",
      sanctionedWaterSupply: "",
    },
  ]);
  const [
    totalSanctionedWaterSupplyPerDay,
    setTotalSanctionedWaterSupplyPerDay,
  ] = useState(null);

  const [lessorIndividualMultiple, setLessorIndividualMultiple] = useState([
    {
      id: uuidv4(),
      lessorName: "",
      lessorAge: "",
      lessorFatherName: "",
      lessorCompleteAddress: "",
      lessorAadharNumber: "",
      // lessorAadharCard: [],
      lessorAadharCard: null,
      lessorPANNumber: "",
      // lessorPANCard: [],
      lessorPANCard: null,
      lessorGstinNumber: "",
      lessorGstinCertificate: null,
      lessorPartnershipPercent: "",
      bankName: "",
      bankAccountType: "",
      bankBeneficiaryName: "",
      bankAccountNumber: "",
      bankIfscCode: "",
      // cancelledCheque: [],
      cancelledCheque: null,
    },
  ]);

  const [lessorLLPPvtLtd, setLessorLLPPvtLtd] = useState([
    {
      id: uuidv4(),
      lessorEntityFullName: "",
      lessorName: "",
      lessorCompleteAddress: "",
      lessorAadharNumber: "",
      lessorAadharCard: [],
      lessorDINNumber: "",
      bankName: "",
      bankAccountType: "",
      bankBeneficiaryName: "",
      bankAccountNumber: "",
      bankIfscCode: "",
      cancelledCheque: [],
    },
  ]);

  const [lessorPartnership, setLessorPartnership] = useState([
    {
      id: uuidv4(),
      // lessorEntityFullName: "",
      lessorName: "",
      lessorAge: "",
      lessorFatherName: "",
      lessorCompleteAddress: "",
      lessorAadharNumber: "",
      // lessorAadharCard: [],
      lessorAadharCard: null,
      lessorPANNumber: "",
      // lessorPANCard: [],
      lessorPANCard: null,
      lessorGstinNumber: "",
      lessorGstinCertificate: null,
      lessorPartnershipPercent: "",
    },
  ]);
  const [zeroRentPeriodInDays, setZeroRentPeriodInDays] = useState(null)


  //!Images state
  const [floorPlansImageDummyState] = useState(null);
  const [CADDrawingsImageDummyState] = useState(null);
  const [picturesImageDummyState] = useState(null);
  const [videosImageDummyState] = useState(null);
  const [titleDocumentsImageDummyState] = useState(null);
  const [OCCCCertificateImageDummyState] = useState(null);

  const [TitleSearchReportImageDummyState] = useState(null);
  const [PropertyPreSigningAuditReportImageDummyState] = useState(null);
  const [lessorAadharCardImageDummyState] = useState(null);
  const [lessorPANCardImageDummyState] = useState(null);
  const [cancelledChequeImageDummyState] = useState(null);
  const [llpRegistrationCertificateImageDummyState] = useState(null);
  const [llpRepresentativeAadharCardImageDummyState] = useState(null);
  const [llpRepresentativePANCardImageDummyState] = useState(null);
  const [POCAadharCardImageDummyState] = useState(null);
  const [ddReportImageDummyState] = useState(null);
  const [auditReportImageDummyState] = useState(null);
  const [signedMOUImageDummyState] = useState(null);

  const [llpAuthorizedAttorneyImageDummyState] = useState(null);
  const [lessorGstinCertificateImageDummyState] = useState(null);

  const [uploadingImageType, setUploadingImageType] = useState(null);

  const uploadFloorPlansImageType = [
    { label: "imageURL", value: "floorPlansImage" },
  ];
  const uploadCADDrawingsImageType = [
    { label: "imageURL", value: "CADDrawingsImage" },
  ];
  const uploadPicturesImageType = [
    { label: "imageURL", value: "picturesImage" },
  ];
  const uploadVideosImageType = [{ label: "imageURL", value: "videosImage" }];
  const uploadTitleDocumentsImageType = [
    { label: "imageURL", value: "titleDocumentsImage" },
  ];
  const uploadOCCCCertificateImageType = [
    { label: "imageURL", value: "OCCCCertificateImage" },
  ];

  const uploadTitleSearchReportImageType = [
    { label: "imageURL", value: "TitleSearchReportImage" },
  ];
  const uploadPropertyPreSigningAuditReportImageType = [
    { label: "imageURL", value: "PropertyPreSigningAuditReportImage" },
  ];
  const uploadLessorAadharCardImageType = [
    { label: "imageURL", value: "lessorAadharCardImage" },
  ];
  const uploadLessorPANCardImageType = [
    { label: "imageURL", value: "lessorPANCardImage" },
  ];
  const uploadCancelledChequeImageType = [
    { label: "imageURL", value: "cancelledChequeImage" },
  ];
  const uploadLlpRegistrationCertificateImageType = [
    { label: "imageURL", value: "llpRegistrationCertificateImage" },
  ];
  const uploadLlpRepresentativeAadharCardImageType = [
    { label: "imageURL", value: "LlpRepresentativeAadharCardImage" },
  ];
  const uploadLlpRepresentativePANCardImageType = [
    { label: "imageURL", value: "LlpRepresentativePANCardImage" },
  ];
  const uploadPOCAadharCardImageType = [
    { label: "imageURL", value: "pocAadharCardImage" },
  ];
  const uploadDDReportImageType = [
    { label: "imageURL", value: "ddReportImage" },
  ];
  const uploadAuditReportImageType = [
    { label: "imageURL", value: "auditReportImage" },
  ];
  const uploadSignedMOUImageType = [
    { label: "imageURL", value: "signedMOUImage" },
  ];

  const uploadLlpAuthorizedAttorneyImageType = [
    { label: "imageURL", value: "llpAuthorizedAttorney" },
  ];
  const uploadLessorGstinCertificateImageType = [
    { label: "imageURL", value: "lessorGstinCertificate" },
  ];

  const userRole = localStorage.getItem("role");

  const [createSupplyHandler, { data: createdSupplyData }] = useCreateSupply();

  const [uploadImageHandler, { data: imageData }] = useUploadImage();

  // useEffect(() => {
  //   if (imageData?.uploadImage?.url) {
  //     setLessorIndividualMultiple(
  //       lessorIndividualMultiple?.map((item) => {
  //         if (item.id) {
  //           return { ...item, lessorAadharCard: imageData?.uploadImage?.url };
  //         } else {
  //           return item;
  //         }
  //       })
  //     );
  //   }
  // }, [imageData?.uploadImage?.url]);

  // const fileUploader = (e, id) => {
  //   const file = e.target.files[0];
  //   if (!file) return;

  //   const maxFileSize = 2097152;

  //   if (file.size > maxFileSize) {
  //     toaster("error", "File size exceeds the maximum limit of 2 MB.");
  //     return;
  //   }

  //   uploadImageHandler(
  //     file,
  //     `${file?.name?.toLowerCase()?.replaceAll(" ", "-")}` +
  //       `/Operations/Supply/LessorAadharCardImage/Image`
  //   );

  //   toaster("success", "Item image uploaded successfully");
  // };

  useEffect(() => {
    if (
      lessorEntityType?.value === "Individual" ||
      lessorEntityType?.value === "Multiple individuals"
    ) {
      if (imageData?.uploadImage?.url && uploadingImageType?.lessorId) {
        setLessorIndividualMultiple((prevLessorList) =>
          prevLessorList.map((lessor) =>
            lessor.id === uploadingImageType.lessorId
              ? {
                ...lessor,
                lessorAadharCard:
                  uploadingImageType.type === "lessorAadharCardImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorAadharCard,
                lessorPANCard:
                  uploadingImageType.type === "lessorPANCardImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorPANCard,
                cancelledCheque:
                  uploadingImageType.type === "cancelledChequeImage"
                    ? imageData?.uploadImage?.url
                    : lessor.cancelledCheque,
                lessorGstinCertificate:
                  uploadingImageType.type === "lessorGstinCertificateImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorGstinCertificate,
              }
              : lessor
          )
        );

        // Reset state after processing the upload
        setUploadingImageType(null);
      }
    }

    if (lessorEntityType?.value === "Partnership") {
      if (imageData?.uploadImage?.url && uploadingImageType?.lessorId) {
        setLessorPartnership((prevLessorList) =>
          prevLessorList.map((lessor) =>
            lessor.id === uploadingImageType.lessorId
              ? {
                ...lessor,
                lessorAadharCard:
                  uploadingImageType.type === "lessorAadharCardImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorAadharCard,
                lessorPANCard:
                  uploadingImageType.type === "lessorPANCardImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorPANCard,
                lessorGstinCertificate:
                  uploadingImageType.type === "lessorGstinCertificateImage"
                    ? imageData?.uploadImage?.url
                    : lessor.lessorGstinCertificate,
              }
              : lessor
          )
        );

        // Reset state after processing the upload
        setUploadingImageType(null);
      }
    }
  }, [imageData?.uploadImage?.url, uploadingImageType]);

  const fileUploader = (e, lessorId, type) => {
    const file = e.target.files[0];
    if (!file) return;

    const maxFileSize = 2097152;
    if (file.size > maxFileSize) {
      toaster("error", "File size exceeds the maximum limit of 2 MB.");
      return;
    }

    // Upload Image
    uploadImageHandler(
      file,
      `${file?.name
        ?.toLowerCase()
        ?.replaceAll(" ", "-")}/Operations/Supply/${type}/Image`
    );

    // Store the lessorId & image type in state to update the correct lessor
    setUploadingImageType({ lessorId, type });

    toaster("success", "Image uploaded successfully");
  };

  useEffect(() => {
    if (hostelPrefix?.value && hostelCity) {
      let hostelName = hostelPrefix?.value + " " + hostelCity;
      if (localityName && localityNamePresent?.value === "Yes") {
        hostelName = hostelName + (`, ${localityName}`)
      }
      setHostelName(hostelName);
    }
  }, [hostelPrefix?.value, localityName, hostelCity, localityNamePresent]);

  useEffect(() => {
    if (numOfPrivateRooms && numOfDormRooms) {
      const numOfTotalRooms =
        Number(numOfPrivateRooms) + Number(numOfDormRooms);
      setNumOfTotalRooms(numOfTotalRooms);
    }
  }, [numOfPrivateRooms, numOfDormRooms]);

  useEffect(() => {
    if (numOfPrivateRooms && dormBeds) {
      const numOfTotalRooms = Number(numOfPrivateRooms) + Number(dormBeds);
      setNumOfTotalBeds(numOfTotalRooms);
    }
  }, [numOfPrivateRooms, dormBeds]);

  useEffect(() => {
    if (goLiveDate) {

      const currentMonth = new Date();
      const goLiveMonth = new Date(goLiveDate);
      console.log("executing for no reason", goLiveDate)
      function monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months >= 0 ? 0 : Math.abs(months);
      }
      setAgeOfHostel(monthDiff(currentMonth, goLiveMonth));
    }
  }, [goLiveDate]);

  useEffect(() => {
    // const leaseDuration =
    //   new Date(rentStartDate).getFullYear() -
    //   new Date(rentEndDate).getFullYear();
    const leaseDuration =
      new Date(rentEndDate).getFullYear() -
      new Date(rentStartDate).getFullYear();
    setLeaseDuration(leaseDuration);
  }, [rentStartDate, rentEndDate]);

  useEffect(() => {
    if (
      securityDepositToken &&
      securityDepositRegistration &&
      securityDepositHandover &&
      securityDepositRentStart
    ) {
      const totalSecurityDeposit =
        Number(securityDepositToken) +
        Number(securityDepositRegistration) +
        Number(securityDepositHandover) +
        Number(securityDepositRentStart);
      setTotalSecurityDeposit(totalSecurityDeposit);
    }
  }, [
    securityDepositToken,
    securityDepositRegistration,
    securityDepositHandover,
    securityDepositRentStart,
  ]);

  useEffect(() => {
    const totalLoad = electricalService?.map(
      (item) => item?.sanctionedElectricalLoad
    );
    setTotalSanctionedElectricalLoad(
      totalLoad.reduce((curr, acc) => Number(curr) + Number(acc), 0)
    );
  }, [electricalService]);

  useEffect(() => {
    const totalSupply = waterService?.map(
      (item) => item?.sanctionedWaterSupply
    );
    setTotalSanctionedWaterSupplyPerDay(
      totalSupply.reduce((curr, acc) => Number(curr) + Number(acc), 0)
    );
  }, [waterService]);
  // console.log(totalSanctionedElectricalLoad, "TOTAL LOAD");

  useEffect(() => {
    if (!lessorHandoverDate || !fitOutPeriod) return;

    const fitOutPeriodNum = Number(fitOutPeriod);

    const goLiveDate = new Date(lessorHandoverDate)
    goLiveDate.setDate(goLiveDate.getDate() + fitOutPeriodNum);
    setGoLiveDate(goLiveDate)

    const transformationHandoverDate = new Date(lessorHandoverDate);
    transformationHandoverDate.setDate(transformationHandoverDate.getDate() + fitOutPeriodNum - 10);
    setTransformationHandoverDate(transformationHandoverDate);

    const staffJoiningDate = new Date(transformationHandoverDate);
    staffJoiningDate.setDate(staffJoiningDate.getDate() + 1);
    setStaffJoiningDate(staffJoiningDate);

    const photoshootDate = new Date(transformationHandoverDate);
    photoshootDate.setDate(photoshootDate.getDate() + 3);
    setPhotoshootDate(photoshootDate);

  }, [lessorHandoverDate, fitOutPeriod]);


  const handleAddElectricalService = () =>
    setElectricalService([
      ...electricalService,
      {
        id: uuidv4(),
        electricalConnectionType: "",
        sanctionedElectricalLoad: "",
      },
    ]);

  const handleAddWaterService = () =>
    setWaterService([
      ...waterService,
      {
        id: uuidv4(),
        waterConnectionType: "",
        sanctionedWaterSupply: "",
      },
    ]);

  const handleAddLessorIndividualMultiple = () => {
    setLessorIndividualMultiple([
      ...lessorIndividualMultiple,
      {
        id: uuidv4(),
        lessorName: "",
        lessorAge: "",
        lessorFatherName: "",
        lessorCompleteAddress: "",
        lessorAadharNumber: "",
        // lessorAadharCard: [],
        lessorAadharCard: null,
        lessorPANNumber: "",
        // lessorPANCard: [],
        lessorPANCard: null,
        lessorGstinNumber: "",
        lessorGstinCertificate: null,
        lessorPartnershipPercent: "",
        bankName: "",
        bankAccountType: "",
        bankBeneficiaryName: "",
        bankAccountNumber: "",
        bankIfscCode: "",
        // cancelledCheque: [],
        cancelledCheque: null,
      },
    ]);
  };

  const handleAddLessorLLPPvtLtd = () => {
    setLessorLLPPvtLtd([
      ...lessorLLPPvtLtd,
      {
        id: uuidv4(),
        lessorEntityFullName: "",
        lessorName: "",
        lessorCompleteAddress: "",
        lessorAadharNumber: "",
        lessorAadharCard: [],
        lessorDINNumber: "",
        bankName: "",
        bankAccountType: "",
        bankBeneficiaryName: "",
        bankAccountNumber: "",
        bankIfscCode: "",
        cancelledCheque: [],
      },
    ]);
  };

  const handleAddLessorPartnership = () => {
    setLessorPartnership([
      ...lessorPartnership,
      {
        id: uuidv4(),
        // lessorEntityFullName: "",
        lessorName: "",
        lessorAge: "",
        lessorFatherName: "",
        lessorCompleteAddress: "",
        lessorAadharNumber: "",
        // lessorAadharCard: [],
        lessorAadharCard: null,
        lessorPANNumber: "",
        // lessorPANCard: [],
        lessorPANCard: null,
        lessorGstinNumber: "",
        lessorGstinCertificate: null,
        lessorPartnershipPercent: "",
      },
    ]);
  };

  const handleElectricalConnectionType = (val, id, key) => {
    setElectricalService(
      electricalService.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };
  const handleSanctionedElectricalLoad = (val, id, key) => {
    setElectricalService(
      electricalService.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };
  const handleWaterConnectionType = (val, id, key) => {
    setWaterService(
      waterService.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };
  const handleSanctionedWaterLoad = (val, id, key) => {
    setWaterService(
      waterService.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };
  const handleText = (val, id, key) => {
    setLessorIndividualMultiple(
      lessorIndividualMultiple.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };

  const handleNumber = (val, id, key) => {
    setLessorIndividualMultiple(
      lessorIndividualMultiple.map((item) =>
        item.id === id ? { ...item, [key]: Number(val) } : item
      )
    );
  };
  const handlePartnershipText = (val, id, key) => {
    setLessorPartnership(
      lessorPartnership.map((item) =>
        item.id === id ? { ...item, [key]: val } : item
      )
    );
  };

  const handlePartnershipNumber = (val, id, key) => {
    setLessorPartnership(
      lessorPartnership.map((item) =>
        item.id === id ? { ...item, [key]: Number(val) } : item
      )
    );
  };

  useEffect(() => {
    if (createdSupplyData?.createSupply?.status === true) {
      toaster("success", "Hostel details added successfully");
      handleToggle();
      refetch();
    } else if (createdSupplyData?.createSupply?.status === false) {
      toaster("error", "Error: Hostel details update failed");
    }
  }, [createdSupplyData]);

  const onSubmitHandler = () => {
    const electricalServiceData = electricalService?.map((item) => ({
      electricalConnectionType: item?.electricalConnectionType?.value,
      sanctionedElectricalLoad: item?.sanctionedElectricalLoad ? Number(item?.sanctionedElectricalLoad) : undefined,
    })).filter(item => item.electricalConnectionType && item.sanctionedElectricalLoad !== undefined);

    const waterServiceData = waterService?.map((item) => ({
      waterConnectionType: item?.waterConnectionType?.value,
      sanctionedWaterSupply: item?.sanctionedWaterSupply ? Number(item?.sanctionedWaterSupply) : undefined,
    })).filter(item => item.waterConnectionType && item.sanctionedWaterSupply !== undefined);

    const lessorIndividualMultipleData = lessorIndividualMultiple?.map((item) => ({
      lessorName: item?.lessorName,
      lessorAge: item?.lessorAge ? Number(item?.lessorAge) : undefined,
      lessorFatherName: item?.lessorFatherName,
      lessorCompleteAddress: item?.lessorCompleteAddress,
      lessorAadharNumber: item?.lessorAadharNumber,
      lessorAadharCard: item?.lessorAadharCard,
      lessorPANNumber: item?.lessorPANNumber,
      lessorPANCard: item?.lessorPANCard,
      lessorGstinNumber: item?.lessorGstinNumber,
      lessorGstinCertificate: item?.lessorGstinCertificate,
      lessorPartnershipPercent: item?.lessorPartnershipPercent ? Number(item?.lessorPartnershipPercent) : undefined,
      bankName: item?.bankName,
      bankAccountType: item?.bankAccountType?.value,
      bankBeneficiaryName: item?.bankBeneficiaryName,
      bankAccountNumber: item?.bankAccountNumber,
      bankIfscCode: item?.bankIfscCode,
      cancelledCheque: item?.cancelledCheque,
    })).filter(item => item.lessorName && item.lessorAge !== undefined);

    const lessorPartnershipData = lessorPartnership?.map((item) => ({
      lessorName: item?.lessorName,
      lessorAge: item?.lessorAge ? Number(item?.lessorAge) : undefined,
      lessorFatherName: item?.lessorFatherName,
      lessorCompleteAddress: item?.lessorCompleteAddress,
      lessorAadharNumber: item?.lessorAadharNumber,
      lessorAadharCard: item?.lessorAadharCard,
      lessorPANNumber: item?.lessorPANNumber,
      lessorPANCard: item?.lessorPANCard,
      lessorGstinNumber: item?.lessorGstinNumber,
      lessorGstinCertificate: item?.lessorGstinCertificate,
      lessorPartnershipPercent: item?.lessorPartnershipPercent ? Number(item?.lessorPartnershipPercent) : undefined,
    })).filter(item => item.lessorName && item.lessorAge !== undefined);

    const data = {
      hostelPrefix: hostelPrefix?.value,
      localityNamePresent: localityNamePresent?.value,
      localityName,
      hostelCity,
      hostelName,
      hostelState: hostelState?.value,
      hostelRegion: hostelRegion?.value,
      hostelCompleteAddress,
      googleMapsPin,
      numOfDormRooms: numOfDormRooms ? Number(numOfDormRooms) : undefined,
      numOfPrivateRooms: numOfPrivateRooms ? Number(numOfPrivateRooms) : undefined,
      dormBeds: dormBeds ? Number(dormBeds) : undefined,
      numOfTotalRooms: numOfTotalRooms ? Number(numOfTotalRooms) : undefined,
      numOfTotalBeds: numOfTotalBeds ? Number(numOfTotalBeds) : undefined,
      numOfStaffRooms: numOfStaffRooms ? Number(numOfStaffRooms) : undefined,
      numOfInventoryRooms: numOfInventoryRooms ? Number(numOfInventoryRooms) : undefined,
      dealStage: dealStage?.value,
      furnishingType: furnishingType?.value,
      propertyType: propertyType?.value,
      hostelTotalArea: hostelTotalArea ? Number(hostelTotalArea) : undefined,
      constructionAge: constructionAge ? Number(constructionAge) : undefined,
      cafe: cafe?.value,
      cafeScale: cafeScale?.value,
      electricalService: electricalServiceData.length > 0 ? electricalServiceData : undefined,
      totalSanctionedElectricalLoad: totalSanctionedElectricalLoad ? Number(totalSanctionedElectricalLoad) : undefined,
      waterService: waterServiceData.length > 0 ? waterServiceData : undefined,
      totalSanctionedWaterSupplyPerDay: totalSanctionedWaterSupplyPerDay ? Number(totalSanctionedWaterSupplyPerDay) : undefined,
      dealSource: dealSource?.value,
      brokerName,
      brokerageAmount: brokerageAmount ? Number(brokerageAmount) : undefined,
      operationalModel: operationalModel?.value,
      omcBrandFees: omcBrandFees ? Number(omcBrandFees) : undefined,
      omcPlatformFees: omcPlatformFees ? Number(omcPlatformFees) : undefined,
      floorPlans,
      cadDrawings,
      picturesVideosDriveLink,
      titleDocuments,
      ocCCCertificate,
      titleSearchReport,
      propertyPreSigningAuditReport,
      signedMOU,
      agreementExecutionDate,
      lessorHandoverDate,
      transformationHandoverDate,
      staffJoiningDate,
      photoshootDate,
      goLiveDate,
      zeroRentPeriodInDays: zeroRentPeriodInDays ? Number(zeroRentPeriodInDays) : null,
      rentStartDate,
      rentEndDate,
      fitOutPeriod: fitOutPeriod ? Number(fitOutPeriod) : null,
      lessorLockInPeriod: lessorLockInPeriod ? Number(lessorLockInPeriod) : null,
      lesseeLockInPeriod: lesseeLockInPeriod ? Number(lesseeLockInPeriod) : null,
      zeroPenaltyGracePeriod: zeroPenaltyGracePeriod ? Number(zeroPenaltyGracePeriod) : null,
      penaltyPerDay: penaltyPerDay ? Number(penaltyPerDay) : null,
      terminationNotice: terminationNotice ? Number(terminationNotice) : null,
      exitingDuration: exitingDuration ? Number(exitingDuration) : null,
      ageOfHostel: ageOfHostel ? Number(ageOfHostel) : null,
      advanceRent: advanceRent ? Number(advanceRent) : null,
      advanceRentRepaymentTimeline: advanceRentRepaymentTimeline ? Number(advanceRentRepaymentTimeline) : null,
      transformationPreLiquidatedAmount: transformationPreLiquidatedAmount ? Number(transformationPreLiquidatedAmount) : null,
      sublettingRights: sublettingRights?.value,
      majorRMResponsibility: majorRMResponsibility?.value,
      minorRMResponsibility: minorRMResponsibility?.value,
      majorRMCurePeriod: majorRMCurePeriod ? Number(majorRMCurePeriod) : null,
      majorRMCurePeriodPenaltyMultiplier: majorRMCurePeriodPenaltyMultiplier ? Number(majorRMCurePeriodPenaltyMultiplier) : null,
      forceMajeure1to3: forceMajeure1to3 ? Number(forceMajeure1to3) : null,
      forceMajeure4to6: forceMajeure4to6 ? Number(forceMajeure4to6) : null,
      forceMajeure6plus: forceMajeure6plus ? Number(forceMajeure6plus) : null,
      lesseeEODPeriod: lesseeEODPeriod ? Number(lesseeEODPeriod) : null,
      lessorEODPeriod: lessorEODPeriod ? Number(lessorEODPeriod) : null,
      monthlyRentType: monthlyRentType?.value,
      monthlyFixedRent: monthlyFixedRent ? Number(monthlyFixedRent) : null,
      monthlyRevenueShare: monthlyRevenueShare ? Number(monthlyRevenueShare) : null,
      monthlyFNBRevenueShare: monthlyFNBRevenueShare ? Number(monthlyFNBRevenueShare) : null,
      monthlyAncillaryRevenueShare: monthlyAncillaryRevenueShare ? Number(monthlyAncillaryRevenueShare) : null,
      rentPayableDate: rentPayableDate ? Number(rentPayableDate) : null,
      rentalIncrementFreq: rentalIncrementFreq?.value,
      rentalPaymentFreq: rentalPaymentFreq?.value,
      rentalIncrementPercent: rentalIncrementPercent ? Number(rentalIncrementPercent) : null,
      rentPerRoom: rentPerRoom ? Number(rentPerRoom) : null,
      rentPerBed: rentPerBed ? Number(rentPerBed) : null,
      leaseDuration: leaseDuration ? Number(leaseDuration) : null,
      securityDepositToken: securityDepositToken ? Number(securityDepositToken) : null,
      securityDepositRegistration: securityDepositRegistration ? Number(securityDepositRegistration) : null,
      securityDepositHandover: securityDepositHandover ? Number(securityDepositHandover) : null,
      securityDepositRentStart: securityDepositRentStart ? Number(securityDepositRentStart) : null,
      totalSecurityDeposit: totalSecurityDeposit ? Number(totalSecurityDeposit) : null,

      lessorEntityType: lessorEntityType?.value,
      pocFullName,
      pocMobileNumber,
      pocEmail,
    };

    if (lessorEntityType?.value === "Individual" || lessorEntityType?.value === "Multiple individuals") {
      if (lessorIndividualMultipleData.length > 0) {
        data.lessorIndividualMultiple = lessorIndividualMultipleData;
      }
    }

    if (lessorEntityType?.value === "LLP" || lessorEntityType?.value === "Pvt. ltd.") {
      data.lessorEntityFullName = lessorEntityFullName;
      data.llpRegistrationCertificate = llpRegistrationCertificate;
      data.llpRegistrationNumber = llpRegistrationNumber;
      data.llpRegisteredAddress = llpRegisteredAddress;
      data.llpRepresentativeName = llpRepresentativeName;
      data.llpRepresentativeAddress = llpRepresentativeAddress;
      data.llpRepresentativeAadharNumber = llpRepresentativeAadharNumber;
      data.llpRepresentativeAadharCard = llpRepresentativeAadharCard;
      data.llpRepresentativePANNumber = llpRepresentativePANNumber;
      data.llpRepresentativePANCard = llpRepresentativePANCard;
      data.llpAuthorizedAttorney = llpAuthorizedAttorney;
      data.lessorGstinNumber = lessorGstinNumber;
      data.lessorGstinCertificate = lessorGstinCertificate;
      data.bankName = bankName;
      data.bankAccountType = bankAccountType?.value;
      data.bankBeneficiaryName = bankBeneficiaryName;
      data.bankAccountNumber = bankAccountNumber;
      data.bankIfscCode = bankIfscCode;
      data.cancelledCheque = cancelledCheque;
    }

    if (lessorEntityType?.value === "Partnership") {
      data.lessorEntityFullName = lessorEntityFullName;
      data.llpRegisteredAddress = llpRegisteredAddress;
      data.llpAuthorizedAttorney = llpAuthorizedAttorney;
      data.lessorGstinNumber = lessorGstinNumber;
      data.lessorGstinCertificate = lessorGstinCertificate;
      data.bankName = bankName;
      data.bankAccountType = bankAccountType?.value;
      data.bankBeneficiaryName = bankBeneficiaryName;
      data.bankAccountNumber = bankAccountNumber;
      data.bankIfscCode = bankIfscCode;
      data.cancelledCheque = cancelledCheque;
      data.lessorPartnership = lessorPartnershipData;
    }

    // console.log(data);
    createSupplyHandler(data);
  };


  useEffect(() => {
    if (numOfTotalRooms && monthlyFixedRent) {
      setRentPerRoom((monthlyFixedRent / numOfTotalRooms).toFixed(2))
    }
    if (numOfTotalBeds && monthlyFixedRent) {
      setRentPerBed((monthlyFixedRent / numOfTotalBeds).toFixed(2))
    }
  }, [monthlyFixedRent])

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
      userRole={userRole}
    >
      <form onSubmit={() => console.log()}>
        {/* <hr
          style={{
            borderTop: "3px solid #fc6c85",
            width: "100%",
          }}
        /> */}
        <h4>Deal Basics</h4>
        <Row>
          {/* //! Hostel prefix */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostelPrefix">
                  Hostel prefix
                </Label>
              </strong>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostelPrefix"
                name="hostelPrefix"
                options={HostelPrefixList}
                value={hostelPrefix}
                onChange={(value) => setHostelPrefix(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="locality" >
                  Locality name present
                </Label>
              </strong>
              <Select
                isClearable={false}
                isSearchable={true}
                id="locality"
                name="locality"
                options={YesNoDropdownOptions}
                value={localityNamePresent}
                onChange={(value) => { setLocalityNamePresent(value) }}
                classNamePrefix="select"
                placeholder=""
              />

            </FormGroup>
          </Col>
          {
            localityNamePresent?.value === "Yes" &&
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="localityName">
                    Locality name
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={localityName}
                  id="localityName"
                  name="localityName"
                  //placeholder="Add locality name"
                  onChange={(e) => setLocalityName(e.target.value)}
                />
              </FormGroup>
            </Col>
          }
          {/* //! Hostel city */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostelCity">
                  Hostel city
                </Label>
              </strong>
              <Input
                type="text"
                value={hostelCity}
                id="hostelCity"
                name="hostelCity"
                //placeholder="Add hostel city"
                onChange={(e) => setHostelCity(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Hostel name */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostelName">
                  Hostel name
                </Label>
              </strong>
              <Input
                type="text"
                value={hostelName}
                id="hostelName"
                name="hostelName"
                //placeholder="Add hostel name"
                disabled
                title={hostelName}
              // onChange={(e) => handleHostelName()}
              />
            </FormGroup>
          </Col>
          {/* //! Hostel state */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostelState">
                  Hostel state
                </Label>
              </strong>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostelState"
                name="hostelState"
                options={indianStateList}
                value={hostelState}
                onChange={(value) => setHostelState(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Hostel region */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostelRegion">
                  Hostel region
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="hostelRegion"
                name="hostelRegion"
                options={HostelRegionList}
                value={hostelRegion}
                onChange={(value) => setHostelRegion(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Hostel complete address */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostelCompleteAddress">
                  Hostel complete address
                </Label>
              </strong>
              <Input
                type="text"
                value={hostelCompleteAddress}
                id="hostelCompleteAddress"
                name="hostelCompleteAddress"
                //placeholder="Add hostel complete address"
                onChange={(e) => setHostelCompleteAddress(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Google Maps pin */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="googleMapsPin">
                  Google Maps pin
                </Label>
              </strong>
              <Input
                type="text"
                value={googleMapsPin}
                id="googleMapsPin"
                name="googleMapsPin"
                //placeholder="Add Google Maps pin"
                onChange={(e) => setGoogleMapsPin(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Operational model */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="operationalModel">
                  Operational model
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="operationalModel"
                name="operationalModel"
                options={OperationalModelTypeList}
                value={operationalModel}
                onChange={(value) => setOperationalModel(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>

          {/* //! OMC brand fees (one-time) */}
          {operationalModel?.value === "OMC" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="omcBrandFees">
                    OMC brand fees (one-time)
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={omcBrandFees}
                  id="omcBrandFees"
                  name="omcBrandFees"
                  //placeholder="Add OMC brand fees (one-time)"
                  onChange={(e) => setOMCBrandFees(e.target.value)}
                />
                {
                  omcBrandFees !== null &&
                  <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                    {
                      (omcBrandFees < 250000) && "OMC brand fees is less than the company mandated OMC guidelines."
                    }
                  </Label>
                }
              </FormGroup>
            </Col>
          )}
          {operationalModel?.value === "OMC" && (
            <>
              {
                <Col sm="3">
                  <FormGroup className="w-100">
                    <strong>
                      <Label className="form-label" for="omcPhotographyfees">
                        OMC photography fees (one-time)
                      </Label>
                    </strong>
                    <Input
                      type="text"
                      value={omcPhotographyfees}
                      id="omcPhotographyfees"
                      name="omcPhotographyfees"
                      onChange={(e) => setOMCPhotographyfees(e.target.value)}
                    />
                    {
                      omcPhotographyfees !== null &&
                      <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                        {
                          (omcPhotographyfees < 100000) && "OMC photography fees is less than the company mandated OMC guidelines."
                        }
                      </Label>
                    }
                  </FormGroup>
                </Col>
              }
            </>
          )}
          {operationalModel?.value === "OMC" && (
            <>
              {
                <Col sm="3">
                  <FormGroup className="w-100">
                    <strong>
                      <Label className="form-label" for="omcDesignFees">
                        OMC design fees (one-time)
                      </Label>
                    </strong>
                    <Input
                      type="text"
                      value={omcDesignFees}
                      id="omcDesignFees"
                      name="omcDesignFees"
                      onChange={(e) => setOMCDesignFees(e.target.value)}
                    />
                    {
                      omcDesignFees !== null &&
                      <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                        {
                          (omcDesignFees < 150000) && "OMC brand fees is less than the company mandated OMC guidelines."
                        }
                      </Label>
                    }
                  </FormGroup>
                </Col>
              }
            </>
          )}
          {/* //! OMC platform fees (monthly) */}
          {operationalModel?.value === "OMC" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="omcPlatformFees">
                    OMC platform fees (monthly)
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={omcPlatformFees}
                  id="omcPlatformFees"
                  name="omcPlatformFees"
                  //placeholder="Add OMC platform fees (monthly)"
                  onChange={(e) => setOMCPlatformFees(e.target.value)}
                />
                {
                  omcPlatformFees &&
                  <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                    {
                      (omcPlatformFees < 12500) && " OMC platform monthly fees is less than the company mandated OMC guidelines."
                    }
                  </Label>
                }
              </FormGroup>
            </Col>
          )}
          {/* //! No. of dormitory rooms */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="numOfDormRooms">
                  No. of dormitory rooms
                </Label>
              </strong>
              <Input
                type="text"
                value={numOfDormRooms}
                id="numOfDormRooms"
                name="numOfDormRooms"
                //placeholder="Add No. of dormitory rooms"
                onChange={(e) => setNumOfDormRooms(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! No. of private rooms */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="numOfPrivateRooms">
                  No. of private rooms
                </Label>
              </strong>
              <Input
                type="text"
                value={numOfPrivateRooms}
                id="numOfPrivateRooms"
                name="numOfPrivateRooms"
                //placeholder="Add No. of private rooms"
                onChange={(e) => setNumOfPrivateRooms(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! No. of dorm beds */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="dormBeds">
                  No. of dorm beds
                </Label>
              </strong>
              <Input
                type="text"
                value={dormBeds}
                id="dormBeds"
                name="dormBeds"
                //placeholder="No. of dorm beds"
                onChange={(e) => setDormBeds(e.target.value)}
              />
              {
                (dormBeds && numOfDormRooms) &&
                <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                  Avg dorm beds/room = {(dormBeds / numOfDormRooms).toFixed(2)}
                </Label>
              }
            </FormGroup>
          </Col>
          {/* //! No. of total rooms */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="numOfTotalRooms">
                  No. of total rooms
                </Label>
              </strong>
              <Input
                type="text"
                value={numOfTotalRooms}
                id="numOfTotalRooms"
                name="numOfTotalRooms"
                disabled
              //placeholder="Add No. of total rooms"
              // onChange={(e) => setNumOfTotalRooms(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! No. of total beds */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="numOfTotalBeds">
                  No. of total beds
                </Label>
              </strong>
              <Input
                type="text"
                value={numOfTotalBeds}
                id="numOfTotalBeds"
                name="numOfTotalBeds"
                disabled
              //placeholder="Add No. of total beds"
              // onChange={(e) => setNumOfTotalBeds(e.target.value)}
              />
              {
                (numOfTotalBeds && numOfTotalRooms) &&
                <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                  Avg number of beds/room = {(numOfTotalBeds / numOfTotalRooms).toFixed(2)}
                </Label>
              }
            </FormGroup>
          </Col>
          {/* //! No. of staff rooms */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="numOfStaffRooms">
                  No. of staff rooms
                </Label>
              </strong>
              <Input
                type="text"
                value={numOfStaffRooms}
                id="numOfStaffRooms"
                name="numOfStaffRooms"
                //placeholder="Add No. of staff rooms"
                onChange={(e) => setNumOfStaffRooms(e.target.value)}
              />
              {
                numOfStaffRooms &&
                <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                  {
                    numOfStaffRooms < ((numOfTotalRooms * .65) / 4) ? "There are fewer staff rooms than required" : " There are more staff rooms than required"
                  }
                </Label>
              }
            </FormGroup>
          </Col>
          {/* //! No. of inventory rooms */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="numOfInventoryRooms">
                  No. of inventory rooms
                </Label>
              </strong>
              <Input
                type="text"
                value={numOfInventoryRooms}
                id="numOfInventoryRooms"
                name="numOfInventoryRooms"
                //placeholder="Add No. of inventory rooms"
                onChange={(e) => setNumOfInventoryRooms(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Deal stage */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="dealStage">
                  Deal stage
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="dealStage"
                name="dealStage"
                options={DealStageList}
                value={dealStage}
                onChange={(value) => setDealStage(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Furnishing type */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="furnishingType">
                  Furnishing type
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="furnishingType"
                name="furnishingType"
                options={FurnishingTypeList}
                value={furnishingType}
                onChange={(value) => setFurnishingType(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Property usage type */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="propertyType">
                  Property usage type
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="propertyType"
                name="propertyType"
                options={PropertyTypeList}
                value={propertyType}
                onChange={(value) => setPropertyType(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Hostel total area (in sq.ft.) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostelTotalArea">
                  Hostel total area (in sq.ft.)
                </Label>
              </strong>
              <Input
                type="text"
                value={hostelTotalArea}
                id="hostelTotalArea"
                name="hostelTotalArea"
                //placeholder="Add hostel total area (in sq.ft.)"
                onChange={(e) => setHostelTotalArea(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Construction age (in years) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="constructionAge">
                  Construction age (in years)
                </Label>
              </strong>
              <Input
                type="text"
                value={constructionAge}
                id="constructionAge"
                name="constructionAge"
                //placeholder="Add Construction age (in years)"
                onChange={(e) => setConstructionAge(e.target.value)}
              />
              {
                constructionAge &&
                <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                  {constructionAge > 5 && "Higher R&M costs shall be expected"}

                </Label>
              }
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {/* //! Cafe */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="cafe">
                  Cafe
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="cafe"
                name="cafe"
                options={CafeList}
                value={cafe}
                onChange={(value) => setCafe(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Cafe scale */}
          {cafe?.value === "Yes" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="cafeScale">
                    Cafe scale
                  </Label>
                </strong>

                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="cafeScale"
                  name="cafeScale"
                  options={CafeScaleList}
                  value={cafeScale}
                  onChange={(value) => setCafeScale(value)}
                  classNamePrefix="select"
                  placeholder=""
                />
              </FormGroup>
            </Col>
          )}
          <Col sm="12" className={"d-flex flex-column"} >
            {electricalService?.map(
              (
                { id, electricalConnectionType, sanctionedElectricalLoad },
                index
              ) => (
                <Row key={id}>
                  {/* //! Electrical connection type */}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="electricalConnectionType"
                        >
                          Electrical connection type {index + 1}
                        </Label>
                      </strong>
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="electricalConnectionType"
                        name="electricalConnectionType"
                        options={ElectricalConnectionTypeList}
                        value={electricalConnectionType}
                        // onChange={(value) =>
                        //   setElectricalConnectionType(value)
                        // }
                        onChange={(value) =>
                          handleElectricalConnectionType(
                            value,
                            id,
                            "electricalConnectionType"
                          )
                        }
                        classNamePrefix="select"
                        placeholder=""
                      />
                      <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                        {
                          electricalConnectionType?.value === "Residential" && " Unforseen penalties shall be expected due to commercial usage"
                        }
                      </Label>
                    </FormGroup>
                  </Col>

                  {/* //! Sanctioned electrical load (in KWH) */}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="sanctionedElectricalLoad"
                        >
                          Sanctioned electrical load (in KWH) {index + 1}
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={sanctionedElectricalLoad}
                        id="sanctionedElectricalLoad"
                        name="sanctionedElectricalLoad"
                        // onChange={(e) =>
                        //   setSanctionedElectricalLoad(e.target.value)
                        // }
                        onChange={(e) =>
                          handleSanctionedElectricalLoad(
                            e.target.value,
                            id,
                            "sanctionedElectricalLoad"
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                  </Col>
                </Row>
              )
            )}
            <Row>

              <Col sm="3">
                <Row>
                  <Col>
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="totalSanctionedElectricalLoad"
                        >
                          Total sanctioned electrical load (in KWH)
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={totalSanctionedElectricalLoad}
                        id="totalSanctionedElectricalLoad"
                        name="totalSanctionedElectricalLoad"
                        disabled
                      />
                      {
                        (numOfTotalRooms && totalSanctionedElectricalLoad) !== null && totalSanctionedElectricalLoad > 0 &&
                        <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                          {totalSanctionedElectricalLoad < (2.5 * numOfTotalRooms) && "There's a need to increase the total sanctioned electrical load"}
                        </Label>
                      }
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col sm="3" style={{ marginTop: "26px" }}>
                <PlusCircle size={30} onClick={() => { handleAddElectricalService() }} title="Add" style={{ cursor: "pointer" }} />

              </Col>
            </Row>
          </Col>


          <Col sm="12" className={"d-flex flex-column"}>
            {waterService?.map(
              ({ id, waterConnectionType, sanctionedWaterSupply }, index) => (
                <Row key={id}>
                  {/* //! Water connection type */}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="waterConnectionType"
                        >
                          Water connection type {index + 1}
                        </Label>
                      </strong>

                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="waterConnectionType"
                        name="waterConnectionType"
                        options={WaterConnectionTypeList}
                        value={waterConnectionType}
                        // onChange={(value) => setWaterConnectionType(value)}
                        onChange={(value) =>
                          handleWaterConnectionType(
                            value,
                            id,
                            "waterConnectionType"
                          )
                        }
                        classNamePrefix="select"
                        placeholder=""
                      />
                      <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                        {
                          waterConnectionType?.value === "Residential" && "Unforseen penalties shall be expected due to commercial usage"
                        }
                      </Label>
                    </FormGroup>
                  </Col>

                  {/* //! Sanctioned water supply/day (in ltr) */}
                  <Col sm="3">
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="sanctionedWaterSupply"
                        >
                          Sanctioned water supply/day (in ltr) {index + 1}
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={sanctionedWaterSupply}
                        id="sanctionedWaterSupply"
                        name="sanctionedWaterSupply"
                        //placeholder="Add Sanctioned water supply/day (in ltr)"
                        // onChange={(e) =>
                        //   setSanctionedWaterSupply(e.target.value)
                        // }
                        onChange={(e) =>
                          handleSanctionedWaterLoad(
                            e.target.value,
                            id,
                            "sanctionedWaterSupply"
                          )
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6">
                  </Col>
                </Row>
              )
            )}
            <Row>
              <Col sm="3">
                <Row>
                  <Col>
                    <FormGroup className="w-100">
                      <strong>
                        <Label
                          className="form-label"
                          for="totalSanctionedWaterSupplyPerDay"
                        >
                          Total sanctioned water supply/day (in ltr)
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        value={totalSanctionedWaterSupplyPerDay}
                        id="totalSanctionedWaterSupplyPerDay"
                        name="totalSanctionedWaterSupplyPerDay"
                        disabled
                      />
                      {
                        ((totalSanctionedWaterSupplyPerDay && numOfTotalBeds) !== null) && totalSanctionedWaterSupplyPerDay > 0 &&
                        <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                          {totalSanctionedWaterSupplyPerDay < (250 * numOfTotalBeds) && "There's a need to increase the total sanctioned water supply/day"}
                        </Label>
                      }
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col sm="3" style={{ marginTop: "26px" }}>
                <PlusCircle size={30} onClick={() => { handleAddWaterService() }} style={{ cursor: "pointer" }} />
              </Col>
            </Row>
          </Col>


        </Row>
        <Row>
          {/* //! Deal source */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="dealSource">
                  Deal source
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="dealSource"
                name="dealSource"
                options={DealSourceTypeList}
                value={dealSource}
                onChange={(value) => setDealSource(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Broker name */}
          {dealSource?.value === "Broker" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="brokerName">
                    Broker name
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={brokerName}
                  id="brokerName"
                  name="brokerName"
                  //placeholder="Add broker name"
                  onChange={(e) => setBrokerName(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}
          {/* //! Brokerage amount (exc. GST) */}
          {dealSource?.value === "Broker" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="brokerageAmount">
                    Brokerage amount (exc. GST)
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={brokerageAmount}
                  id="brokerageAmount"
                  name="brokerageAmount"
                  //placeholder="Add brokerage amount"
                  onChange={(e) => setBrokerageAmount(e.target.value)}
                />
                {
                  ((brokerageAmount && monthlyFixedRent) !== null) &&
                  <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                    {((brokerageAmount / monthlyFixedRent) * 30).toFixed(2)} days brokerage was given in this deal.
                  </Label>
                }
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          {/* //! Agreement execution date */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="agreementExecutionDate">
                  Agreement execution date
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={agreementExecutionDate}
                onChange={(date) =>
                  setAgreementExecutionDate(
                    new Date(dateFormatter(new Date(date)))
                  )
                }
                id="agreementExecutionDate"
                // placeholder="Add agreement execution date"
                required
              />
            </FormGroup>
          </Col>
          {/* //! Fit out period (in days) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Fit out period (in days)</Label>
              </strong>
              <Input
                type="text"
                value={fitOutPeriod}
                id="fitOutPeriod"
                name="fitOutPeriod"
                //placeholder="Add fit out period"
                onChange={(e) => setFitOutPeriod(e.target.value)}
              />
              {
                (dealStage && fitOutPeriod) &&
                <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                  {
                    (dealStage == "brownfield" && fitOutPeriod < 45) && "Fit out period for this deal is less than the company mandated guidelines."
                  }
                </Label>
              }
            </FormGroup>
          </Col>
          {/* //! Lessor's handover date */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="lessorHandoverDate">
                  Lessor's handover date
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={lessorHandoverDate}
                onChange={(date) =>
                  setLessorHandoverDate(new Date(dateFormatter(new Date(date))))
                }
                id="lessorHandoverDate"
                //  placeholder="Add lessor's handover date"
                required
              />
            </FormGroup>
          </Col>
          {/* //! Transformation handover date */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="transformationHandoverDate">
                  Transformation handover date
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={transformationHandoverDate}
                // onChange={(date) =>
                //   setLessorHandoverDate(new Date(dateFormatter(new Date(date))))
                // }
                id="transformationHandoverDate"
                // placeholder="Add transformation handover date"
                required
                disabled
              />
            </FormGroup>
          </Col>
          {/* //! Staff joining date */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="staffJoiningDate">
                  Staff joining date
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={staffJoiningDate}
                // onChange={(date) =>
                //   setLessorHandoverDate(new Date(dateFormatter(new Date(date))))
                // }
                id="staffJoiningDate"
                // placeholder="Add staff joining date"
                required
                disabled
              />
            </FormGroup>
          </Col>
          {/* //! Photoshoot date */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="photoshootDate">
                  Photoshoot date
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={photoshootDate}
                // onChange={(date) =>
                //   setLessorHandoverDate(new Date(dateFormatter(new Date(date))))
                // }
                id="photoshootDate"
                // placeholder="Add photoshoot date"
                required
                disabled
              />
            </FormGroup>
          </Col>
          {/* //! Go live date */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="goLiveDate">
                  Go live date
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={goLiveDate}
                onChange={(date) =>
                  setGoLiveDate(new Date(dateFormatter(new Date(date))))
                }
                id="goLiveDate"
                // placeholder="Add go live date"
                // required
                disabled
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Zero rent period (in days)</Label>
              </strong>
              <Input
                type="number"
                value={zeroRentPeriodInDays}
                id="fitOutPeriod"
                name="fitOutPeriod"
                //placeholder="Add fit out period"
                // onChange={(e) => setFitOutPeriod(e.target.value)}
                onChange={(e) => { setZeroRentPeriodInDays(e.target.value) }}
                // disabled={!isEditableByuser("fitOutPeriod")}
                onWheel={(e) => e.target.blur()}
              />
            </FormGroup>
          </Col>
          {/*//! Rent start date */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="rentStartDate">
                  Rent start date
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={rentStartDate}
                onChange={(date) =>
                  setRentStartDate(new Date(dateFormatter(new Date(date))))
                }
                options={{
                  minDate: goLiveDate
                }}
                id="rentStartDate"
                // placeholder="Add rent start date"
                required
              />
            </FormGroup>
          </Col>
          {/*//! Rent end date */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="rentEndDate">
                  Rent end date
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={rentEndDate}
                onChange={(date) =>
                  setRentEndDate(new Date(dateFormatter(new Date(date))))
                }
                id="rentEndDate"
                // placeholder="Add rent end date"
                required
              />
            </FormGroup>
          </Col>

          {/* //! Lessor's lock-in period (in years) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessor's lock-in period (in years)
                </Label>
              </strong>
              <Input
                type="text"
                value={lessorLockInPeriod}
                id="lessorLockInPeriod"
                name="lessorLockInPeriod"
                //placeholder="Add lessor's lock-in period"
                onChange={(e) => setLessorLockInPeriod(e.target.value)}
              />
              {
                (lessorLockInPeriod && leaseDuration) &&
                <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                  {
                    lessorLockInPeriod < leaseDuration && "Lessor isn't locked-in this deal for 100% term"
                  }
                </Label>
              }
            </FormGroup>
          </Col>
          {/* //! Lessee's lock-in period (in years) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessee's lock-in period (in years)
                </Label>
              </strong>
              <Input
                type="text"
                value={lesseeLockInPeriod}
                id="lesseeLockInPeriod"
                name="lesseeLockInPeriod"
                //placeholder="Add lessee's lock-in period"
                onChange={(e) => setLesseeLockInPeriod(e.target.value)}
              />
              {
                (lesseeLockInPeriod && leaseDuration) &&
                <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                  {
                    (lesseeLockInPeriod > (0.33 * leaseDuration)) && " Lessee is locked-in this deal for greater than 33% of the term"
                  }
                </Label>
              }
            </FormGroup>
          </Col>
          {/* //! "Zero penalty grace period for Lessor's handover (in days)" */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Zero penalty grace period for Lessor's handover (in days)
                </Label>
              </strong>
              <Input
                type="text"
                value={zeroPenaltyGracePeriod}
                id="zeroPenaltyGracePeriod"
                name="zeroPenaltyGracePeriod"
                //placeholder="Add zero penalty grace period for Lessor's handover"
                onChange={(e) => setZeroPenaltyGracePeriod(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Penalty/day on Lessor for handover delay */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Penalty/day on Lessor for handover delay
                </Label>
              </strong>
              <Input
                type="text"
                value={penaltyPerDay}
                id="penaltyPerDay"
                name="penaltyPerDay"
                //placeholder="Add penalty/day on Lessor for handover delay"
                onChange={(e) => setPenaltyPerDay(e.target.value)}
              />
              {
                (penaltyPerDay && monthlyFixedRent) &&
                <Label style={{ backgroundColor: "white", color: "blue", padding: "0 5px" }}>
                  {
                    (penaltyPerDay < (monthlyFixedRent / 30)) && "Penalty agreed is lesser than per day rent"
                  }
                </Label>
              }
            </FormGroup>
          </Col>
          {/* //! Termination notice period (in days) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Termination notice period (in days)
                </Label>
              </strong>
              <Input
                type="text"
                value={terminationNotice}
                id="terminationNotice"
                name="terminationNotice"
                //placeholder="Add termination notice period"
                onChange={(e) => setTerminationNotice(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* // ! States NA */}
          {/* //! Exiting duration (in days) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Exiting duration (in days)
                </Label>
              </strong>
              <Input
                type="text"
                value={exitingDuration}
                id="exitingDuration"
                name="exitingDuration"
                //placeholder="Add exiting duration"
                onChange={(e) => setExitingDuration(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Age of the hostel (in months)  */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Age of the hostel (in months)
                </Label>
              </strong>
              <Input
                type="text"
                value={ageOfHostel}
                id="ageOfHostel"
                name="ageOfHostel"
                //placeholder="Age of the hostel"
                disabled
              // onChange={(e) => setAgeOfHostel(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Advance rent  */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Advance rent</Label>
              </strong>
              <Input
                type="text"
                value={advanceRent}
                id="advanceRent"
                name="advanceRent"
                //placeholder="Advance rent"
                onChange={(e) => setAdvanceRent(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Advance rent repayment timeline (in months)  */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Advance rent repayment timeline (in months)
                </Label>
              </strong>
              <Input
                type="text"
                value={advanceRentRepaymentTimeline}
                id="advanceRentRepaymentTimeline"
                name="advanceRentRepaymentTimeline"
                //placeholder="Advance rent"
                onChange={(e) =>
                  setAdvanceRentRepaymentTimeline(e.target.value)
                }
              />
            </FormGroup>
          </Col>
          {/* //! Transformation/pre-liquidated amount  */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Transformation/pre-liquidated amount
                </Label>
              </strong>
              <Input
                type="text"
                value={transformationPreLiquidatedAmount}
                id="transformationPreLiquidatedAmount"
                name="transformationPreLiquidatedAmount"
                //placeholder="Advance rent"
                onChange={(e) =>
                  setTransformationPreLiquidatedAmount(e.target.value)
                }
              />
            </FormGroup>
          </Col>
          {/* //! Subletting rights? */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Subletting rights?</Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="sublettingRights"
                name="sublettingRights"
                options={SublettingRightsTypeList}
                value={sublettingRights}
                onChange={(value) => setSublettingRights(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>

          {/* //! Major R&M responsibility*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Major R&M responsibility</Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="majorRMResponsibility"
                name="majorRMResponsibility"
                options={LicensesOfficialFeesResponsibilityTypeList}
                value={majorRMResponsibility}
                onChange={(value) => setMajorRMResponsibility(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Minor R&M responsibility*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Minor R&M responsibility</Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="minorRMResponsibility"
                name="minorRMResponsibility"
                options={LicensesOfficialFeesResponsibilityTypeList}
                value={minorRMResponsibility}
                onChange={(value) => setMinorRMResponsibility(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Major R&M cure period (in days) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Major R&M cure period (in days)
                </Label>
              </strong>
              <Input
                type="text"
                value={majorRMCurePeriod}
                id="majorRMCurePeriod"
                name="majorRMCurePeriod"
                //placeholder="Major R&M cure period"
                onChange={(e) => setMajorRMCurePeriod(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Major R&M post cure period penalty multiplier */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Major R&M post cure period penalty multiplier
                </Label>
              </strong>
              <Input
                type="text"
                value={majorRMCurePeriodPenaltyMultiplier}
                id="majorRMCurePeriodPenaltyMultiplier"
                name="majorRMCurePeriodPenaltyMultiplier"
                //placeholder="Major R&M post cure period penalty multiplier"
                onChange={(e) =>
                  setMajorRMCurePeriodPenaltyMultiplier(e.target.value)
                }
              />
            </FormGroup>
          </Col>
          {/* //! Force Majeure rent (1st - 3rd month) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Force Majeure monthly rent (1st - 3rd month)
                </Label>
              </strong>
              <Input
                type="text"
                value={forceMajeure1to3}
                id="forceMajeure1to3"
                name="forceMajeure1to3"
                //placeholder="Force Majeure rent (1st - 3rd month)"
                onChange={(e) => setForceMajeure1to3(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Force Majeure rent (4th - 6th month) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Force Majeure monthly rent (4th - 6th month)
                </Label>
              </strong>
              <Input
                type="text"
                value={forceMajeure4to6}
                id="forceMajeure4to6"
                name="forceMajeure4to6"
                //placeholder="Force Majeure rent (4th - 6th month)"
                onChange={(e) => setForceMajeure4to6(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Force Majeure rent (6+ months) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Force Majeure monthly rent (6+ months)
                </Label>
              </strong>

              <Input
                type="text"
                value={forceMajeure6plus}
                id="forceMajeure6plus"
                name="forceMajeure6plus"
                //placeholder="Force Majeure rent (6+ months)"
                onChange={(e) => setForceMajeure6plus(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Lessee's default of rent period (in days) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessee's default of rent period (in days)
                </Label>
              </strong>
              <Input
                type="text"
                value={lesseeEODPeriod}
                id="lesseeEODPeriod"
                name="lesseeEODPeriod"
                //placeholder="Lessee's event of default period"
                onChange={(e) => setLesseeEODPeriod(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Lessor's default period for license renewal (in days) */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessor's default period for license renewal (in days)
                </Label>
              </strong>
              <Input
                type="text"
                value={lessorEODPeriod}
                id="lessorEODPeriod"
                name="lessorEODPeriod"
                //placeholder="Lessor's default period for license renewal (in days)"
                onChange={(e) => setLessorEODPeriod(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Lessee's EOD cure period (in days) */}
          {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessee's EOD cure period (in days)*
                </Label>
              </strong>

              <Input
                type="text"
                value={lesseeEODCure}
                id="lesseeEODCure"
                name="lesseeEODCure"
                // placeholder="Lessee's EOD cure period"
                onChange={(e) => setLesseeEODCure(e.target.value)}
              />
            </FormGroup>
          </Col> */}
          {/* //! Lessor's EOD cure period (in days)*/}
          {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessor's EOD cure period (in days)*
                </Label>
              </strong>

              <Input
                type="text"
                value={lessorEODCure}
                id="lessorEODCure"
                name="lessorEODCure"
                // placeholder="Lessor's EOD cure period"
                onChange={(e) => setLessorEODCure(e.target.value)}
              />
            </FormGroup>
          </Col> */}
          {/* //! Monthly rent type*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly rent type</Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="monthlyRentType"
                name="monthlyRentType"
                options={MonthlyRentTypeList}
                value={monthlyRentType}
                onChange={(value) => setMonthlyRentType(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Monthly fixed rent */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly fixed rent</Label>
              </strong>

              <Input
                type="text"
                value={monthlyFixedRent}
                id="monthlyFixedRent"
                name="monthlyFixedRent"
                // placeholder="Monthly fixed rent "
                onChange={(e) => setMonthlyFixedRent(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Monthly revenue share  % */}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly bed revenue share %</Label>
              </strong>

              <Input
                type="text"
                value={monthlyRevenueShare}
                id="monthlyRevenueShare"
                name="monthlyRevenueShare"
                // placeholder="Monthly fixed rent  %"
                onChange={(e) => setMonthlyRevenueShare(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly F&B revenue share %</Label>
              </strong>

              <Input
                type="number"
                value={monthlyFNBRevenueShare}
                id="monthlyFNBRevenueShare"
                name="monthlyFNBRevenueShare"
                onChange={(e) => { setMontlyFNBRevenueShare(e.target.value) }}
                onWheel={(e) => e.target.blur()}
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly ancillary revenue share %</Label>
              </strong>

              <Input
                type="number"
                value={monthlyAncillaryRevenueShare}
                id="monthlyAncillaryRevenueShare"
                name="monthlyAncillaryRevenueShare"
                onChange={(e) => { setMonthlyAncillaryRevenueShare(e.target.value) }}
                onWheel={(e) => e.target.blur()}
              />
            </FormGroup>
          </Col>
          {/* //! Rent payable day*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Rent payable day</Label>
              </strong>

              <Input
                type="text"
                value={rentPayableDate}
                id="rentPayableDate"
                name="rentPayableDate"
                // placeholder="Rent payable day"
                onChange={(e) => setRentPayableDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Rental increment frequency*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Rental increment frequency
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="rentalIncrementFreq"
                name="  "
                options={RentalIncrementFrequencyTypeList}
                value={rentalIncrementFreq}
                onChange={(value) => setRentalIncrementFreq(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Rental payment frequency*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Rental payment frequency</Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="rentalPaymentFreq"
                name="rentalPaymentFreq"
                options={RentalPaymentFrequencyTypeList}
                value={rentalPaymentFreq}
                onChange={(value) => setRentalPaymentFreq(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col>
          {/* //! Rental increment %*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Rental increment %</Label>
              </strong>
              <Input
                type="text"
                value={rentalIncrementPercent}
                id="rentalIncrementPercent"
                name="rentalIncrementPercent"
                // placeholder="Rental increment %"
                onChange={(e) => setRentalIncrementPercent(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Rent per room*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly fixed rent/room</Label>
              </strong>

              <Input
                type="text"
                value={rentPerRoom}
                id="rentPerRoom"
                name="rentPerRoom"
                // placeholder="Rent per room"
                // onChange={(e) => setRentPerRoom(e.target.value)}
                disabled
              />
            </FormGroup>
          </Col>
          {/* //! Rent per bed*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Monthly fixed rent/bed</Label>
              </strong>

              <Input
                type="text"
                value={rentPerBed}
                id="rentPerBed"
                name="rentPerBed"
                // placeholder="Rent per bed"
                // onChange={(e) => setRentPerBed(e.target.value)}
                disabled
              />
            </FormGroup>
          </Col>
          {/* //!  Lease duration (in years)*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="leaseDuration">
                  Lease duration (in years)
                </Label>
              </strong>
              <Input
                type="text"
                value={leaseDuration}
                id="leaseDuration"
                name="leaseDuration"
                disabled
              //placeholder="Add lease duration (in years)"
              // onChange={(e) => setLeaseDuration(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //!  Security deposit payable (during token)*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositToken">
                  Security deposit payable (during token)
                </Label>
              </strong>
              <Input
                type="text"
                value={securityDepositToken}
                id="securityDepositToken"
                name="securityDepositToken"
                //placeholder="Add security deposit payable (during token)"
                onChange={(e) => setSecurityDepositToken(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //!  Security deposit payable (during registration)*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositRegistration">
                  Security deposit payable (during registration)
                </Label>
              </strong>
              <Input
                type="text"
                value={securityDepositRegistration}
                id="securityDepositRegistration"
                name="securityDepositRegistration"
                //placeholder="Add security deposit payable (during registration)"
                onChange={(e) => setSecurityDepositRegistration(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //!  Security deposit payable (during handover)*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositHandover">
                  Security deposit payable (during handover)
                </Label>
              </strong>
              <Input
                type="text"
                value={securityDepositHandover}
                id="securityDepositHandover"
                name="securityDepositHandover"
                //placeholder="Add Security deposit payable (during handover)"
                onChange={(e) => setSecurityDepositHandover(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //!  Security deposit payable (at rent start date)*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositRentStart">
                  Security deposit payable (at rent start date)
                </Label>
              </strong>
              <Input
                type="text"
                value={securityDepositRentStart}
                id="securityDepositRentStart"
                name="securityDepositRentStart"
                //placeholder="Add Security deposit payable (at rent start date)"
                onChange={(e) => setSecurityDepositRentStart(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //!  Total security deposit*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="totalSecurityDeposit">
                  Total security deposit
                </Label>
              </strong>
              <Input
                type="text"
                value={totalSecurityDeposit}
                id="totalSecurityDeposit"
                name="totalSecurityDeposit"
                disabled
              //placeholder="Add total security deposit"
              // onChange={(e) => setTotalSecurityDeposit(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* //! Pictures/videos drive link */}
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="picturesVideosDriveLink">
                  Pictures/videos drive link
                </Label>
              </strong>
              <Input
                type="text"
                value={picturesVideosDriveLink}
                id="picturesVideosDriveLink"
                name="picturesVideosDriveLink"
                //placeholder="Add pictures/videos drive link"
                onChange={(e) => setPicturesVideosDriveLink(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/*//! Floor plans */}
          <Col sm="6">
            <strong>
              <Label className="form-label" for="floorPlans">
                Floor plans
              </Label>
            </strong>
            <ImageModal
              imageList={floorPlans}
              setImageList={setFloorPlans}
              editData={floorPlansImageDummyState}
              uploadType={uploadFloorPlansImageType}
              canBeEdited={true}
              fieldName={"Floor plan"}
            />
          </Col>
          {/*//! CAD Drawings */}
          <Col sm="6">
            <strong>
              <Label className="form-label" for="cadDrawings">
                CAD Drawings
              </Label>
            </strong>
            <ImageModal
              imageList={cadDrawings}
              setImageList={setCADDrawings}
              editData={CADDrawingsImageDummyState}
              uploadType={uploadCADDrawingsImageType}
              canBeEdited={true}
              fieldName={"CAD Drawings"}
            />
          </Col>
          {/*//! Title documents */}
          <Col sm="6">
            <strong>
              <Label className="form-label" for="titleDocuments">
                Title documents
              </Label>
            </strong>
            <ImageModal
              imageList={titleDocuments}
              setImageList={setTitleDocuments}
              editData={titleDocumentsImageDummyState}
              uploadType={uploadTitleDocumentsImageType}
              canBeEdited={true}
              fieldName={"Title documents"}
            />
          </Col>
          {/*//! OC/CC certificate */}
          <Col sm="6">
            <strong>
              <Label className="form-label" for="ocCCCertificate">
                OC/CC certificate
              </Label>
            </strong>
            <ImageModal
              imageList={ocCCCertificate}
              setImageList={setOCCCCertificate}
              editData={OCCCCertificateImageDummyState}
              uploadType={uploadOCCCCertificateImageType}
              canBeEdited={true}
              fieldName={"OC/CC certificate"}
            />
          </Col>
          {/*//! Title search report  */}
          <Col sm="6">
            <strong>
              <Label className="form-label" for="titleSearchReport">
                Title search report
              </Label>
            </strong>
            <ImageModal
              imageList={titleSearchReport}
              setImageList={setTitleSearchReport}
              editData={TitleSearchReportImageDummyState}
              uploadType={uploadTitleSearchReportImageType}
              canBeEdited={true}
              fieldName={" Title search report"}
            />
          </Col>
          {/*//! Property pre-signing audit report  */}
          <Col sm="6">
            <strong>
              <Label className="form-label" for="propertyPreSigningAuditReport">
                Property pre-signing audit report
              </Label>
            </strong>
            <ImageModal
              imageList={propertyPreSigningAuditReport}
              setImageList={setPropertyPreSigningAuditReport}
              editData={PropertyPreSigningAuditReportImageDummyState}
              uploadType={uploadPropertyPreSigningAuditReportImageType}
              canBeEdited={true}
              fieldName={"Property pre-signing audit report"}
            />
          </Col>

          {/*//! Signed MOU */}
          {/* <Col sm="6">
            <strong>
              <Label className="form-label" for="signedMOU">
                Signed MOU
              </Label>
            </strong>
            <ImageModal
              imageList={signedMOU}
              setImageList={setSignedMOU}
              editData={signedMOUImageDummyState}
              uploadType={uploadSignedMOUImageType}
              canBeEdited={true}
              fieldName={"Signed MOU"}
            />
          </Col> */}
          {/*//! DD Report*/}
          {/* <Col sm="3">
            <strong>
              <Label className="form-label" for="ddReport">
                DD Report*
              </Label>
            </strong>
            <AttachmentComponent
              imageList={ddReport}
              setImageList={setDDReport}
              editData={ddReportImageDummyState}
              uploadType={uploadDDReportImageType}
            />
          </Col> */}
          {/*//! Audit Report*/}
          {/* <Col sm="3">
            <strong>
              <Label className="form-label" for="auditReport">
                Audit Report*
              </Label>
            </strong>
            <AttachmentComponent
              imageList={auditReport}
              setImageList={setAuditReport}
              editData={auditReportImageDummyState}
              uploadType={uploadAuditReportImageType}
            />
          </Col> */}
        </Row>
        <hr
          style={{
            borderTop: "3px solid #fc6c85",
            width: "100%",
          }}
        />
        <h4>Deal ownership</h4>
        <Row>
          {/* //! Lessor's entity type*/}
          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="lessorEntityType">
                  Lessor's entity type
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="lessorEntityType"
                name="lessorEntityType"
                options={LessorEntityTypeList}
                value={lessorEntityType}
                onChange={(value) => setLessorEntityType(value)}
                classNamePrefix="select"
                placeholder=""
                // menuShouldScrollIntoView={false}
                menuPlacement="auto"
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: 1050,
                    // position: "absolute",
                    // width: "100%",
                    // maxWidth: "300px",
                  }),
                }}
              />
            </FormGroup>
          </Col>

          {/* //! Lessor's entity full name*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd." ||
            lessorEntityType?.value === "Partnership") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="lessorEntityFullName">
                      Lessor's entity full name
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={lessorEntityFullName}
                    id="lessorEntityFullName"
                    name="lessorEntityFullName"
                    //placeholder="Add lessor's entity full name"
                    onChange={(e) => setLessorEntityFullName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}

          {/* //!  Lessor's LLP registration number*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="llpRegistrationNumber">
                      Lessor's LLP registration number
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={llpRegistrationNumber}
                    id="llpRegistrationNumber"
                    name="llpRegistrationNumber"
                    //placeholder="Add lessor's LLP registration number"
                    onChange={(e) => setLlpRegistrationNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
          {/*//! Lessor's LLP registration certificate*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") && (
              <Col sm="3">
                <strong>
                  <Label className="form-label" for="llpRegistrationCertificate">
                    Lessor's LLP registration certificate
                  </Label>
                </strong>
                {/* <AttachmentComponent
                  imageList={llpRegistrationCertificate}
                  setImageList={setLlpRegistrationCertificate}
                  editData={llpRegistrationCertificateImageDummyState}
                  uploadType={uploadLlpRegistrationCertificateImageType}
                /> */}
                <ImageModal
                  imageList={llpRegistrationCertificate}
                  setImageList={setLlpRegistrationCertificate}
                  editData={llpRegistrationCertificateImageDummyState}
                  uploadType={uploadLlpRegistrationCertificateImageType}
                  canBeEdited={true}
                  fieldName={"Lessor's LLP registration certificate"}
                />
              </Col>
            )}
          {/* //!  Lessor's LLP registered address*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd." ||
            lessorEntityType?.value === "Partnership") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="llpRegisteredAddress">
                      Lessor's LLP registered address
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={llpRegisteredAddress}
                    id="llpRegisteredAddress"
                    name="llpRegisteredAddress"
                    //placeholder="Add lessor's LLP registered address"
                    onChange={(e) => setLlpRegisteredAddress(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}

          {/* //!  Lessor's LLP authorised representative's full name*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="llpRepresentativeName">
                      Lessor's LLP authorised representative's full name
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={llpRepresentativeName}
                    id="llpRepresentativeName"
                    name="llpRepresentativeName"
                    //placeholder="Add lessor's LLP authorised representative's full name"
                    onChange={(e) => setLlpRepresentativeName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
          {/* //!  Lessor's LLP authorised representative's complete address*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="llpRepresentativeAddress">
                      Lessor's LLP authorised representative's complete address
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={llpRepresentativeAddress}
                    id="llpRepresentativeAddress"
                    name="llpRepresentativeAddress"
                    //placeholder="Add lessor's LLP authorised representative's complete address"
                    onChange={(e) => setLlpRepresentativeAddress(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
          {/* //!  Lessor's LLP authorised representative's aadhar number*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label
                      className="form-label"
                      for="llpRepresentativeAadharNumber"
                    >
                      Lessor's LLP authorised representative's aadhar number*
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={llpRepresentativeAadharNumber}
                    id="llpRepresentativeAadharNumber"
                    name="llpRepresentativeAadharNumber"
                    //placeholder="Add lessor's LLP authorised representative's aadhar number"
                    onChange={(e) =>
                      setLlpRepresentativeAadharNumber(e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            )}
          {/*//! Lessor's LLP authorised representative's aadhar card*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") && (
              <Col sm="3">
                <strong>
                  <Label className="form-label" for="llpRepresentativeAadharCard">
                    Lessor's LLP authorised representative's aadhar card
                  </Label>
                </strong>
                {/* <AttachmentComponent
                  imageList={llpRepresentativeAadharCard}
                  setImageList={setLlpRepresentativeAadharCard}
                  editData={llpRepresentativeAadharCardImageDummyState}
                  uploadType={uploadLlpRepresentativeAadharCardImageType}
                /> */}
                <ImageModal
                  imageList={llpRepresentativeAadharCard}
                  setImageList={setLlpRepresentativeAadharCard}
                  editData={llpRepresentativeAadharCardImageDummyState}
                  uploadType={uploadLlpRepresentativeAadharCardImageType}
                  canBeEdited={true}
                  fieldName={"Lessor's LLP authorised representative's aadhar card"}
                />
              </Col>
            )}

          {/* //!  Lessor's LLP authorised representative's PAN Number*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label
                      className="form-label"
                      for="llpRepresentativePANNumber"
                    >
                      Lessor's LLP authorised representative's PAN Number*
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={llpRepresentativePANNumber}
                    id="llpRepresentativePANNumber"
                    name="llpRepresentativePANNumber"
                    //placeholder="Add Lessor's LLP authorised representative's PAN Number"
                    onChange={(e) =>
                      setLlpRepresentativePANNumber(e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            )}
          {/*//! Lessor's LLP authorised representative's PAN Card*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") && (
              <Col sm="3">
                <strong>
                  <Label className="form-label" for="llpRepresentativePANCard">
                    Lessor's LLP authorised representative's PAN Card
                  </Label>
                </strong>
                {/* <AttachmentComponent
                  imageList={llpRepresentativePANCard}
                  setImageList={setLlpRepresentativePANCard}
                  editData={llpRepresentativePANCardImageDummyState}
                  uploadType={uploadLlpRepresentativePANCardImageType}
                /> */}
                <ImageModal
                  imageList={llpRepresentativePANCard}
                  setImageList={setLlpRepresentativePANCard}
                  editData={llpRepresentativePANCardImageDummyState}
                  uploadType={uploadLlpRepresentativePANCardImageType}
                  canBeEdited={true}
                  fieldName={"Lessor's LLP authorised representative's PAN Card"}
                />
              </Col>
            )}

          {/*//! Lessor's LLP authorised representative BR or Power of Attorney for signing */}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd." ||
            lessorEntityType?.value === "Partnership") && (
              <Col sm="3">
                <strong>
                  <Label className="form-label" for="floorPlans">
                    Lessor's LLP authorised representative BR or Power of Attorney
                    for signing
                  </Label>
                </strong>
                {/* <AttachmentComponent
                  imageList={llpAuthorizedAttorney}
                  setImageList={setLlpAuthorizedAttorney}
                  editData={llpAuthorizedAttorneyImageDummyState}
                  uploadType={uploadLlpAuthorizedAttorneyImageType}
                /> */}
                <ImageModal
                  imageList={llpAuthorizedAttorney}
                  setImageList={setLlpAuthorizedAttorney}
                  editData={llpAuthorizedAttorneyImageDummyState}
                  uploadType={uploadLlpAuthorizedAttorneyImageType}
                  canBeEdited={true}
                  fieldName={"Lessor's LLP authorised representative BR or Power of Attorney for signing"}
                />
              </Col>
            )}
          {/* //!  Lessor's GSTIN number*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd." ||
            lessorEntityType?.value === "Partnership") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="lessorGstinNumber">
                      Lessor's GSTIN number
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={lessorGstinNumber}
                    id="lessorGstinNumber"
                    name="lessorGstinNumber"
                    //placeholder="Add lessor's GSTIN number"
                    onChange={(e) => setLessorGstinNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
          {/*//! Lessor's GSTIN certificate*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd." ||
            lessorEntityType?.value === "Partnership") && (
              <Col sm="3">
                <strong>
                  <Label className="form-label" for="lessorGstinCertificate">
                    Lessor's GSTIN certificate
                  </Label>
                </strong>
                {/* <AttachmentComponent
                  imageList={lessorGstinCertificate}
                  setImageList={setLessorGstinCertificate}
                  editData={lessorGstinCertificateImageDummyState}
                  uploadType={uploadLessorGstinCertificateImageType}
                /> */}
                <ImageModal
                  imageList={lessorGstinCertificate}
                  setImageList={setLessorGstinCertificate}
                  editData={lessorGstinCertificateImageDummyState}
                  uploadType={uploadLessorGstinCertificateImageType}
                  canBeEdited={true}
                  fieldName={"Lessor's GSTIN certificate"}
                />
              </Col>
            )}

          {/* //! Bank name*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd." ||
            lessorEntityType?.value === "Partnership") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="bankName">
                      Bank name
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={bankName}
                    id="bankName"
                    name="bankName"
                    //placeholder="Add bank name"
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
          {/* //! Bank account type*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd." ||
            lessorEntityType?.value === "Partnership") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="bankAccountType">
                      Bank account type
                    </Label>
                  </strong>

                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="bankAccountType"
                    name="bankAccountType"
                    options={BankAccountTypeList}
                    value={bankAccountType}
                    onChange={(value) => setBankAccountType(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            )}
          {/* //! Bank account beneficiary name*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd." ||
            lessorEntityType?.value === "Partnership") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="bankBeneficiaryName">
                      Bank account beneficiary name
                    </Label>
                  </strong>

                  {/* <Select
                  isClearable={false}
                  isSearchable={true}
                  id="bankBeneficiaryName"
                  name="bankBeneficiaryName"
                  options={BankAccountTypeList}
                  value={bankBeneficiaryName}
                  onChange={(value) => setBankBeneficiaryName(value)}
                  classNamePrefix="select"
                  placeholder=""
                /> */}

                  <Input
                    type="text"
                    value={bankBeneficiaryName}
                    id="bankBeneficiaryName"
                    name="bankBeneficiaryName"
                    //placeholder="Add bank account beneficiary name"
                    onChange={(e) => setBankBeneficiaryName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
          {/* //!  Bank account number*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd." ||
            lessorEntityType?.value === "Partnership") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="bankAccountNumber">
                      Bank account number
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={bankAccountNumber}
                    id="bankAccountNumber"
                    name="bankAccountNumber"
                    //placeholder="Add bank account number"
                    onChange={(e) => setBankAccountNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
          {/* //!  Bank IFSC code*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd." ||
            lessorEntityType?.value === "Partnership") && (
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label" for="bankIfscCode">
                      Bank IFSC code
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={bankIfscCode}
                    id="bankIfscCode"
                    name="bankIfscCode"
                    //placeholder="Add bank IFSC code"
                    onChange={(e) => setBankIfscCode(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
          {/*//! Cancelled cheque*/}
          {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd." ||
            lessorEntityType?.value === "Partnership") && (
              <Col sm="3">
                <strong>
                  <Label className="form-label" for="cancelledCheque">
                    Cancelled cheque
                  </Label>
                </strong>
                {/* <AttachmentComponent
                  imageList={cancelledCheque}
                  setImageList={setCancelledCheque}
                  editData={cancelledChequeImageDummyState}
                  uploadType={uploadCancelledChequeImageType}
                /> */}
                <ImageModal
                  imageList={cancelledCheque}
                  setImageList={setCancelledCheque}
                  editData={cancelledChequeImageDummyState}
                  uploadType={uploadCancelledChequeImageType}
                  canBeEdited={true}
                  fieldName={"Cancelled cheque"}
                />
              </Col>
            )}

          {/* //! Lessor's age*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorAge">
                    Lessor's age*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorAge}
                  id="lessorAge"
                  name="lessorAge"
                  //placeholder="Add lessor's age"
                  onChange={(e) => setLessorAge(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
          {/* //! Lessor's father name*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorFatherName">
                    Lessor's father name*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorFatherName}
                  id="lessorFatherName"
                  name="lessorFatherName"
                  //placeholder="Add lessor's father name"
                  onChange={(e) => setLessorFatherName(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
          {/* //! Lessor's complete address*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorCompleteAddress">
                    Lessor's complete address*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorCompleteAddress}
                  id="lessorCompleteAddress"
                  name="lessorCompleteAddress"
                  //placeholder="Add lessor's complete address"
                  onChange={(e) => setLessorCompleteAddress(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
          {/* //! Lessor's aadhar number*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorAadharNumber">
                    Lessor's aadhar number*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorAadharNumber}
                  id="lessorAadharNumber"
                  name="lessorAadharNumber"
                  //placeholder="Add lessor's aadhar number"
                  onChange={(e) => setLessorAadharNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
          {/*//! Lessor's aadhar card*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="lessorAadharCard">
                  Lessor's aadhar card*
                </Label>
              </strong>
              <AttachmentComponent
                imageList={lessorAadharCard}
                setImageList={setLessorAadharCard}
                editData={lessorAadharCardImageDummyState}
                uploadType={uploadLessorAadharCardImageType}
              />
            </Col>
          )}*/}
          {/* //! Lessor's PAN number*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorPANNumber">
                    Lessor's PAN number*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorPANNumber}
                  id="lessorPANNumber"
                  name="lessorPANNumber"
                  //placeholder="Add lessor's PAN number"
                  onChange={(e) => setLessorPANNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
          {/*//! Lessor's PAN card*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="lessorPANCard">
                  Lessor's PAN card*
                </Label>
              </strong>
              <AttachmentComponent
                imageList={lessorPANCard}
                setImageList={setLessorPANCard}
                editData={lessorPANCardImageDummyState}
                uploadType={uploadLessorPANCardImageType}
              />
            </Col>
          )}*/}
          {/* //! Lessor's partnership %*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="lessorPartnershipPercent">
                    Lessor's partnership %*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={lessorPartnershipPercent}
                  id="lessorPartnershipPercent"
                  name="lessorPartnershipPercent"
                  //placeholder="Add lessor's partnership %"
                  onChange={(e) => setLessorPartnershipPercent(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
          {/* //! Bank name*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankName">
                    Bank name*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={bankName}
                  id="bankName"
                  name="bankName"
                  //placeholder="Add bank name"
                  onChange={(e) => setBankName(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
          {/* //! Bank account type*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankAccountType">
                    Bank account type*
                  </Label>
                </strong>

                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="bankAccountType"
                  name="bankAccountType"
                  options={BankAccountTypeList}
                  value={bankAccountType}
                  onChange={(value) => setBankAccountType(value)}
                  classNamePrefix="select"
                  placeholder=""
                />
              </FormGroup>
            </Col>
          )}*/}
          {/* //! Bank account beneficiary name*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankBeneficiaryName">
                    Bank account beneficiary name*
                  </Label>
                </strong>

                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="bankBeneficiaryName"
                  name="bankBeneficiaryName"
                  options={BankAccountTypeList}
                  value={bankBeneficiaryName}
                  onChange={(value) => setBankBeneficiaryName(value)}
                  classNamePrefix="select"
                  placeholder=""
                />
              </FormGroup>
            </Col>
          )}*/}
          {/* //!  Bank account number*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankAccountNumber">
                    Bank account number*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={bankAccountNumber}
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  //placeholder="Add bank account number"
                  onChange={(e) => setBankAccountNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
          {/* //!  Bank IFSC code*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="bankIfscCode">
                    Bank IFSC code*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={bankIfscCode}
                  id="bankIfscCode"
                  name="bankIfscCode"
                  //placeholder="Add bank IFSC code"
                  onChange={(e) => setBankIfscCode(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}*/}
          {/*//! Cancelled cheque*/}
          {/* {(lessorEntityType?.value === "Individual" ||
            lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="cancelledCheque">
                  Cancelled cheque*
                </Label>
              </strong>
              <AttachmentComponent
                imageList={cancelledCheque}
                setImageList={setCancelledCheque}
                editData={cancelledChequeImageDummyState}
                uploadType={uploadCancelledChequeImageType}
              />
            </Col>
          )}*/}

          {lessorIndividualMultiple?.map(
            (
              {
                id,
                lessorName,
                lessorAge,
                lessorFatherName,
                lessorCompleteAddress,
                lessorAadharNumber,
                lessorAadharCard,
                lessorPANNumber,
                lessorPANCard,
                lessorGstinNumber,
                lessorGstinCertificate,
                lessorPartnershipPercent,
                bankName,
                bankAccountType,
                bankBeneficiaryName,
                bankAccountNumber,
                bankIfscCode,
                cancelledCheque,
              },
              index
            ) => (
              <React.Fragment key={id}>
                {(lessorEntityType?.value === "Individual" ||
                  lessorEntityType?.value === "Multiple individuals") && (
                    <hr
                      style={{
                        borderTop: "2px solid #ffe700",
                        width: "100%",
                      }}
                    />
                  )}
                {(lessorEntityType?.value === "Individual" ||
                  lessorEntityType?.value === "Multiple individuals") && (
                    <h5>
                      <Col sm="12">Lessor's {index + 1} details</Col>
                    </h5>
                  )}
                <Col sm="12">
                  <Row>
                    {/* //! Lessor's Name*/}
                    {(lessorEntityType?.value === "Individual" ||
                      lessorEntityType?.value === "Multiple individuals") && (
                        <>
                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label className="form-label" for="lessorName">
                                  Lessor's name
                                </Label>
                              </strong>
                              <Input
                                type="text"
                                value={lessorName}
                                id="lessorName"
                                name="lessorName"
                                //placeholder="Add lessor's name"
                                // onChange={(e) => setLessorName(e.target.value)}
                                onChange={(e) =>
                                  handleText(e.target.value, id, "lessorName")
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/* //! Lessor's age*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label className="form-label" for="lessorAge">
                                  Lessor's age
                                </Label>
                              </strong>
                              <Input
                                type="text"
                                value={lessorAge}
                                id="lessorAge"
                                name="lessorAge"
                                //placeholder="Add lessor's age"
                                // onChange={(e) => setLessorAge(e.target.value)}
                                onChange={(e) =>
                                  handleNumber(e.target.value, id, "lessorAge")
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/* //! Lessor's father name*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label
                                  className="form-label"
                                  for="lessorFatherName"
                                >
                                  Lessor's father name*
                                </Label>
                              </strong>
                              <Input
                                type="text"
                                value={lessorFatherName}
                                id="lessorFatherName"
                                name="lessorFatherName"
                                //placeholder="Add lessor's father name"
                                // onChange={(e) =>
                                //   setLessorFatherName(e.target.value)
                                // }
                                onChange={(e) =>
                                  handleText(
                                    e.target.value,
                                    id,
                                    "lessorFatherName"
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/* //! Lessor's complete address*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label
                                  className="form-label"
                                  for="lessorCompleteAddress"
                                >
                                  Lessor's complete address*
                                </Label>
                              </strong>
                              <Input
                                type="text"
                                value={lessorCompleteAddress}
                                id="lessorCompleteAddress"
                                name="lessorCompleteAddress"
                                //placeholder="Add lessor's complete address"
                                // onChange={(e) =>
                                //   setLessorCompleteAddress(e.target.value)
                                // }
                                onChange={(e) =>
                                  handleText(
                                    e.target.value,
                                    id,
                                    "lessorCompleteAddress"
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/* //! Lessor's aadhar number*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label
                                  className="form-label"
                                  for="lessorAadharNumber"
                                >
                                  Lessor's aadhar number*
                                </Label>
                              </strong>
                              <Input
                                type="text"
                                value={lessorAadharNumber}
                                id="lessorAadharNumber"
                                name="lessorAadharNumber"
                                //placeholder="Add lessor's aadhar number"
                                // onChange={(e) =>
                                //   setLessorAadharNumber(e.target.value)
                                // }
                                onChange={(e) =>
                                  handleText(
                                    e.target.value,
                                    id,
                                    "lessorAadharNumber"
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/*//! Lessor's aadhar card*/}

                          <Col sm="3">
                            <strong>
                              <Label
                                className="form-label"
                                for="lessorAadharCard"
                              >
                                Lessor's Aadhar Card*
                              </Label>
                            </strong>
                            <div className="mt-2 d-flex align-items-center gap-2">
                              {lessorAadharCard !== null ? (
                                lessorAadharCard?.includes(".pdf") ? (
                                  <Image
                                    onClick={() =>
                                      window.open(lessorAadharCard, "_blank")
                                    }
                                    size={45}
                                    style={{
                                      marginBottom: "20px"
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="imageSection__image"
                                    src={lessorAadharCard}
                                    alt="Attachment"
                                    style={{
                                      width: "6rem",
                                      height: "6rem",
                                      objectFit: "cover",
                                      cursor: "pointer",
                                      marginLeft: "1.7rem",
                                    }}
                                    onClick={() =>
                                      window.open(lessorAadharCard, "_blank")
                                    }
                                  />
                                )
                              ) : null}

                              <label htmlFor={`aadharFile${id}`}>
                                <p className="btn btn-sm">
                                  <Upload size={30} />
                                </p>
                                <input
                                  name={`aadharFile${id}`}
                                  id={`aadharFile${id}`}
                                  className="d-none"
                                  type="file"
                                  onChange={(e) =>
                                    fileUploader(e, id, "lessorAadharCardImage")
                                  }
                                />
                              </label>
                            </div>
                          </Col>
                          {/* //! Lessor's PAN number*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label
                                  className="form-label"
                                  for="lessorPANNumber"
                                >
                                  Lessor's PAN number*
                                </Label>
                              </strong>
                              <Input
                                type="text"
                                value={lessorPANNumber}
                                id="lessorPANNumber"
                                name="lessorPANNumber"
                                //placeholder="Add lessor's PAN number"
                                // onChange={(e) =>
                                //   setLessorPANNumber(e.target.value)
                                // }
                                onChange={(e) =>
                                  handleText(
                                    e.target.value,
                                    id,
                                    "lessorPANNumber"
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/*//! Lessor's PAN card*/}

                          {/* <Col sm="3">
                          <strong>
                            <Label className="form-label" for="lessorPANCard">
                              Lessor's PAN card*
                            </Label>
                          </strong>
                          <AttachmentComponent
                            imageList={lessorPANCard}
                            setImageList={setLessorPANCard}
                            editData={lessorPANCardImageDummyState}
                            uploadType={uploadLessorPANCardImageType}
                          />
                        </Col> */}

                          <Col sm="3">
                            <strong>
                              <Label className="form-label" for="lessorPANCard">
                                Lessor's PAN card
                              </Label>
                            </strong>
                            <div className="mt-2 d-flex align-items-center gap-2">
                              {lessorPANCard !== null ? (
                                lessorPANCard?.includes(".pdf") ? (
                                  <Image
                                    onClick={() =>
                                      window.open(
                                        lessorPANCard,
                                        "_blank"
                                      )
                                    }
                                    size={45}
                                    style={{
                                      marginBottom: "20px"
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="imageSection__image"
                                    src={lessorPANCard}
                                    alt="Attachment"
                                    style={{
                                      width: "6rem",
                                      height: "6rem",
                                      objectFit: "cover",
                                      cursor: "pointer",
                                      marginLeft: "1.7rem",
                                    }}
                                    onClick={() =>
                                      window.open(lessorPANCard, "_blank")
                                    }
                                  />
                                )
                              ) : null}

                              <label htmlFor={`panFile${id}`}>
                                <p className="btn btn-sm">
                                  <Upload size={30} />
                                </p>
                                <input
                                  name={`panFile${id}`}
                                  id={`panFile${id}`}
                                  className="d-none"
                                  type="file"
                                  onChange={(e) =>
                                    fileUploader(e, id, "lessorPANCardImage")
                                  }
                                />
                              </label>
                            </div>
                          </Col>

                          {/* //! Lessor's GSTIN number*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label
                                  className="form-label"
                                  for="lessorGstinNumber"
                                >
                                  Lessor's GSTIN number*
                                </Label>
                              </strong>
                              <Input
                                type="text"
                                value={lessorGstinNumber}
                                id="lessorGstinNumber"
                                name="lessorGstinNumber"
                                //placeholder="Add lessor's GSTIN number"
                                // onChange={(e) =>
                                //   setLessorAadharNumber(e.target.value)
                                // }
                                onChange={(e) =>
                                  handleText(
                                    e.target.value,
                                    id,
                                    "lessorGstinNumber"
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/*//! Lessor's GSTIN certificate*/}

                          <Col sm="3">
                            <strong>
                              <Label
                                className="form-label"
                                for="lessorGstinCertificate"
                              >
                                Lessor's GSTIN certificate*
                              </Label>
                            </strong>
                            <div className="mt-2 d-flex align-items-center gap-2">
                              {lessorGstinCertificate !== null ? (
                                lessorGstinCertificate?.includes(".pdf") ? (
                                  <Image
                                    onClick={() =>
                                      window.open(
                                        lessorGstinCertificate,
                                        "_blank"
                                      )
                                    }
                                    size={45}
                                    style={{
                                      marginBottom: "20px"
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="imageSection__image"
                                    src={lessorGstinCertificate}
                                    alt="Attachment"
                                    style={{
                                      width: "6rem",
                                      height: "6rem",
                                      objectFit: "cover",
                                      cursor: "pointer",
                                      marginLeft: "1.7rem",
                                    }}
                                    onClick={() =>
                                      window.open(
                                        lessorGstinCertificate,
                                        "_blank"
                                      )
                                    }
                                  />
                                )
                              ) : null}

                              <label htmlFor={`gstFile${id}`}>
                                <p className="btn btn-sm">
                                  <Upload size={30} />
                                </p>
                                <input
                                  name={`gstFile${id}`}
                                  id={`gstFile${id}`}
                                  className="d-none"
                                  type="file"
                                  onChange={(e) =>
                                    fileUploader(
                                      e,
                                      id,
                                      "lessorGstinCertificateImage"
                                    )
                                  }
                                />
                              </label>
                            </div>
                          </Col>

                          {/* //! Lessor's partnership %*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label
                                  className="form-label"
                                  for="lessorPartnershipPercent"
                                >
                                  Lessor's partnership %*
                                </Label>
                              </strong>
                              <Input
                                type="text"
                                value={lessorPartnershipPercent}
                                id="lessorPartnershipPercent"
                                name="lessorPartnershipPercent"
                                //placeholder="Add lessor's partnership %"
                                // onChange={(e) =>
                                //   setLessorPartnershipPercent(e.target.value)
                                // }
                                onChange={(e) =>
                                  handleNumber(
                                    e.target.value,
                                    id,
                                    "lessorPartnershipPercent"
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/* //! Bank name*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label className="form-label" for="bankName">
                                  Bank name
                                </Label>
                              </strong>
                              <Input
                                type="text"
                                value={bankName}
                                id="bankName"
                                name="bankName"
                                //placeholder="Add bank name"
                                // onChange={(e) => setBankName(e.target.value)}
                                onChange={(e) =>
                                  handleText(e.target.value, id, "bankName")
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/* //! Bank account type*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label
                                  className="form-label"
                                  for="bankAccountType"
                                >
                                  Bank account type*
                                </Label>
                              </strong>

                              <Select
                                isClearable={false}
                                isSearchable={true}
                                id="bankAccountType"
                                name="bankAccountType"
                                options={BankAccountTypeList}
                                value={bankAccountType}
                                // onChange={(value) => setBankAccountType(value)}
                                onChange={(value) =>
                                  handleText(value, id, "bankAccountType")
                                }
                                classNamePrefix="select"
                                placeholder=""
                              />
                            </FormGroup>
                          </Col>

                          {/* //! Bank account beneficiary name*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label
                                  className="form-label"
                                  for="bankBeneficiaryName"
                                >
                                  Bank account beneficiary name*
                                </Label>
                              </strong>
                              {/* 
                            <Select
                              isClearable={false}
                              isSearchable={true}
                              id="bankBeneficiaryName"
                              name="bankBeneficiaryName"
                              options={BankAccountTypeList}
                              value={bankBeneficiaryName}
                              onChange={(value) =>
                                setBankBeneficiaryName(value)
                              }
                              classNamePrefix="select"
                              placeholder=""
                            /> */}
                              <Input
                                type="text"
                                value={bankBeneficiaryName}
                                id="bankBeneficiaryName"
                                name="bankBeneficiaryName"
                                //placeholder="Add bank account beneficiary name"
                                // onChange={(e) =>
                                //   setBankBeneficiaryName(e.target.value)
                                // }
                                onChange={(e) =>
                                  handleText(
                                    e.target.value,
                                    id,
                                    "bankBeneficiaryName"
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/* //!  Bank account number*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label
                                  className="form-label"
                                  for="bankAccountNumber"
                                >
                                  Bank account number*
                                </Label>
                              </strong>
                              <Input
                                type="text"
                                value={bankAccountNumber}
                                id="bankAccountNumber"
                                name="bankAccountNumber"
                                //placeholder="Add bank account number"
                                // onChange={(e) =>
                                //   setBankAccountNumber(e.target.value)
                                // }
                                onChange={(e) =>
                                  handleText(
                                    e.target.value,
                                    id,
                                    "bankAccountNumber"
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/* //!  Bank IFSC code*/}

                          <Col sm="3">
                            <FormGroup className="w-100">
                              <strong>
                                <Label className="form-label" for="bankIfscCode">
                                  Bank IFSC code
                                </Label>
                              </strong>
                              <Input
                                type="text"
                                value={bankIfscCode}
                                id="bankIfscCode"
                                name="bankIfscCode"
                                //placeholder="Add bank IFSC code"
                                // onChange={(e) => setBankIfscCode(e.target.value)}
                                onChange={(e) =>
                                  handleText(e.target.value, id, "bankIfscCode")
                                }
                              />
                            </FormGroup>
                          </Col>

                          {/*//! Cancelled cheque*/}

                          {/* <Col sm="3">
                          <strong>
                            <Label className="form-label" for="cancelledCheque">
                              Cancelled cheque*
                            </Label>
                          </strong>
                          <AttachmentComponent
                            imageList={cancelledCheque}
                            setImageList={setCancelledCheque}
                            editData={cancelledChequeImageDummyState}
                            uploadType={uploadCancelledChequeImageType}
                          />
                        </Col> */}

                          <Col sm="3">
                            <strong>
                              <Label className="form-label" for="cancelledCheque">
                                Cancelled cheque
                              </Label>
                            </strong>
                            <div className="mt-2 d-flex align-items-center gap-2">
                              {cancelledCheque !== null ? (
                                cancelledCheque?.includes(".pdf") ? (
                                  <Image
                                    onClick={() =>
                                      window.open(
                                        cancelledCheque,
                                        "_blank"
                                      )
                                    }
                                    size={45}
                                    style={{
                                      marginBottom: "20px"
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="imageSection__image"
                                    src={cancelledCheque}
                                    alt="Attachment"
                                    style={{
                                      width: "6rem",
                                      height: "6rem",
                                      objectFit: "cover",
                                      cursor: "pointer",
                                      marginLeft: "1.7rem",
                                    }}
                                    onClick={() =>
                                      window.open(cancelledCheque, "_blank")
                                    }
                                  />
                                )
                              ) : null}

                              <label htmlFor={`chequeFile${id}`}>
                                <p className="btn btn-sm">
                                  <Upload size={30} />
                                </p>
                                <input
                                  name={`chequeFile${id}`}
                                  id={`chequeFile${id}`}
                                  className="d-none"
                                  type="file"
                                  onChange={(e) =>
                                    fileUploader(e, id, "cancelledChequeImage")
                                  }
                                />
                              </label>
                            </div>
                          </Col>
                        </>
                      )}
                  </Row>
                </Col>
              </React.Fragment>
            )
          )}
          {(lessorEntityType?.value === "Multiple individuals") && (
            <Col sm="12" className="mb-2">
              <AddButton
                addAction={handleAddLessorIndividualMultiple}
                buttonName={"lessor's info"}
              />
            </Col>
          )}

          {/* //!  Lessor's LLP authorised representative's full name*/}
          {/* {lessorEntityType?.value === "LLP" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="llpRepresentativeName">
                    Lessor's LLP authorised representative's full name*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativeName}
                  id="llpRepresentativeName"
                  name="llpRepresentativeName"
                  //placeholder="Add lessor's LLP authorised representative's full name"
                  onChange={(e) => setLlpRepresentativeName(e.target.value)}
                />
              </FormGroup>
            </Col>
          )} */}
          {/* //!  Lessor's LLP authorised representative's complete address*/}
          {/* {lessorEntityType?.value === "LLP" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="llpRepresentativeAddress">
                    Lessor's LLP authorised representative's complete address*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativeAddress}
                  id="llpRepresentativeAddress"
                  name="llpRepresentativeAddress"
                  //placeholder="Add lessor's LLP authorised representative's complete address"
                  onChange={(e) => setLlpRepresentativeAddress(e.target.value)}
                />
              </FormGroup>
            </Col>
          )} */}
          {/* //!  Lessor's LLP authorised representative's aadhar number*/}
          {/* {lessorEntityType?.value === "LLP" && (
            <Col sm="3">
              <FormGroup className="w-100">
                <strong>
                  <Label
                    className="form-label"
                    for="llpRepresentativeAadharNumber"
                  >
                    Lessor's LLP authorised representative's aadhar number*
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={llpRepresentativeAadharNumber}
                  id="llpRepresentativeAadharNumber"
                  name="llpRepresentativeAadharNumber"
                  //placeholder="Add lessor's LLP authorised representative's aadhar number"
                  onChange={(e) =>
                    setLlpRepresentativeAadharNumber(e.target.value)
                  }
                />
              </FormGroup>
            </Col>
          )} */}
          {/*//! Lessor's LLP authorised representative's aadhar card*/}
          {/* {lessorEntityType?.value === "LLP" && (
            <Col sm="3">
              <strong>
                <Label className="form-label" for="llpRepresentativeAadharCard">
                  Lessor's LLP authorised representative's aadhar card*
                </Label>
              </strong>
              <AttachmentComponent
                imageList={llpRepresentativeAadharCard}
                setImageList={setLlpRepresentativeAadharCard}
                editData={llpRepresentativeAadharCardImageDummyState}
                uploadType={uploadLlpRepresentativeAadharCardImageType}
              />
            </Col>
          )} */}

          {/* //! Lessor's POC's full name*/}
          {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Lessor's POC's full name*</Label>
              </strong>
              <Input
                type="text"
                value={pocFullName}
                id="pocFullName"
                name="pocFullName"
                //placeholder="Add lessor's LLP authorised representative's DIN number"
                onChange={(e) => setPOCFullName(e.target.value)}
              />
            </FormGroup>
          </Col> */}
          {/* //! Lessor's POC's mobile number*/}
          {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessor's POC's mobile number*
                </Label>
              </strong>
              <Input
                type="text"
                value={pocMobileNumber}
                id="pocMobileNumber"
                name="pocMobileNumber"
                //placeholder="Add lessor's POC's mobile number"
                onChange={(e) => setPOCMobileNumber(e.target.value)}
              />
            </FormGroup>
          </Col> */}
          {/* //! Lessor's POC's email*/}
          {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">Lessor's POC's email*</Label>
              </strong>
              <Input
                type="text"
                value={pocEmail}
                id="pocEmail"
                name="pocEmail"
                //placeholder="Add lessor's POC's email"
                onChange={(e) => setPOCEmail(e.target.value)}
              />
            </FormGroup>
          </Col> */}
          {/* //! Lessor's POC's aadhar number*/}
          {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label">
                  Lessor's POC's aadhar number*
                </Label>
              </strong>
              <Input
                type="text"
                value={pocAadharNumber}
                id="pocAadharNumber"
                name="pocAadharNumber"
                //placeholder="Add lessor's POC's aadhar number"
                onChange={(e) => setPOCAadharNumber(e.target.value)}
              />
            </FormGroup>
          </Col> */}
          {/*//! Lessor's POC's aadhar card*/}
          {/* <Col sm="3">
            <strong>
              <Label className="form-label" for="llpRepresentativeAadharCard">
                Lessor's POC's aadhar card*
              </Label>
            </strong>
            <AttachmentComponent
              imageList={pocAadharCard}
              setImageList={setPOCAadharCard}
              editData={POCAadharCardImageDummyState}
              uploadType={uploadPOCAadharCardImageType}
            />
          </Col> */}
          {/* {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") &&
            lessorLLPPvtLtd?.map(
              (
                {
                  id,
                  lessorEntityFullName,
                  lessorName,
                  lessorCompleteAddress,
                  lessorAadharNumber,
                  lessorAadharCard,
                  lessorDINNumber,
                  bankName,
                  bankAccountType,
                  bankBeneficiaryName,
                  bankAccountNumber,
                  bankIfscCode,
                  cancelledCheque,
                },
                index
              ) => (
                <React.Fragment key={id}>
                  <hr
                    style={{
                      borderTop: "2px solid #ffe700",
                      width: "100%",
                    }}
                  />

                  <Col sm="12">
                    <Row> */}
          {/* //! Lessor's entity full name*/}
          {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label
                              className="form-label"
                              for="lessorEntityFullName"
                            >
                              Lessor's entity full name {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={lessorEntityFullName}
                            id="lessorEntityFullName"
                            name="lessorEntityFullName"
                            //placeholder="Add lessor's entity full name"
                            onChange={(e) =>
                              setLessorEntityFullName(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col> */}
          {/* //!  Lessor's LLP authorised representative's full name**/}
          {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label className="form-label" for="lessorName">
                              Lessor's LLP authorised representative's full name{" "}
                              {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={lessorName}
                            id="lessorName"
                            name="lessorName"
                            //placeholder="Add  Lessor's LLP authorised representative's full name*"
                            // onChange={(e) => setLessorName(e.target.value)}
                            onChange={(e) =>
                              handleLessorName(e.target.value, id, "lessorName")
                            }
                          />
                        </FormGroup>
                      </Col> */}
          {/* //! Lessor's LLP authorised representative's complete address*/}
          {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label
                              className="form-label"
                              for="lessorCompleteAddress"
                            >
                              Lessor's LLP authorised representative's complete
                              address {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={lessorCompleteAddress}
                            id="lessorCompleteAddress"
                            name="lessorCompleteAddress"
                            //placeholder="Add lessor's LLP authorised representative's complete address"
                            onChange={(e) =>
                              setLessorCompleteAddress(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col> */}
          {/* //! Lessor's LLP authorised representative's aadhar number*/}
          {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label
                              className="form-label"
                              for="lessorAadharNumber"
                            >
                              Lessor's LLP authorised representative's aadhar
                              number {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={lessorAadharNumber}
                            id="lessorAadharNumber"
                            name="lessorAadharNumber"
                            //placeholder="Add lessor's LLP authorised representative's aadhar number"
                            onChange={(e) =>
                              setLessorAadharNumber(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col> */}
          {/*//! Lessor's LLP authorised representative's aadhar card*/}
          {/* <Col sm="3">
                        <strong>
                          <Label className="form-label" for="lessorAadharCard">
                            Lessor's LLP authorised representative's aadhar card{" "}
                            {index + 1}*
                          </Label>
                        </strong>
                        <AttachmentComponent
                          imageList={lessorAadharCard}
                          setImageList={setLessorAadharCard}
                          editData={lessorAadharCardImageDummyState}
                          uploadType={uploadLessorAadharCardImageType}
                        />
                      </Col> */}
          {/* //! Lessor's LLP authorised representative's DIN number*/}
          {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label className="form-label" for="lessorDINNumber">
                              Lessor's LLP authorised representative's DIN
                              number {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={lessorDINNumber}
                            id="lessorDINNumber"
                            name="lessorDINNumber"
                            //placeholder="Add lessor's LLP authorised representative's DIN number"
                            // onChange={(e) => setLessorName(e.target.value)}
                            onChange={(e) =>
                              handleLessorName(
                                e.target.value,
                                id,
                                "lessorDINNumber"
                              )
                            }
                          />
                        </FormGroup>
                      </Col> */}
          {/* //! Bank name*/}
          {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label className="form-label" for="bankName">
                              Bank name {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={bankName}
                            id="bankName"
                            name="bankName"
                            //placeholder="Add bank name"
                            onChange={(e) => setBankName(e.target.value)}
                          />
                        </FormGroup>
                      </Col> */}
          {/* //! Bank account type*/}
          {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label className="form-label" for="bankAccountType">
                              Bank account type {index + 1}*
                            </Label>
                          </strong>

                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id="bankAccountType"
                            name="bankAccountType"
                            options={BankAccountTypeList}
                            value={bankAccountType}
                            onChange={(value) => setBankAccountType(value)}
                            classNamePrefix="select"
                            placeholder=""
                          />
                        </FormGroup>
                      </Col> */}
          {/* //! Bank account beneficiary name*/}
          {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label
                              className="form-label"
                              for="bankBeneficiaryName"
                            >
                              Bank account beneficiary name {index + 1}*
                            </Label>
                          </strong>

                          <Select
                            isClearable={false}
                            isSearchable={true}
                            id="bankBeneficiaryName"
                            name="bankBeneficiaryName"
                            options={BankAccountTypeList}
                            value={bankBeneficiaryName}
                            onChange={(value) => setBankBeneficiaryName(value)}
                            classNamePrefix="select"
                            placeholder=""
                          />
                        </FormGroup>
                      </Col> */}
          {/* //!  Bank account number*/}
          {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label
                              className="form-label"
                              for="bankAccountNumber"
                            >
                              Bank account number {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={bankAccountNumber}
                            id="bankAccountNumber"
                            name="bankAccountNumber"
                            //placeholder="Add bank account number"
                            onChange={(e) =>
                              setBankAccountNumber(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col> */}
          {/* //!  Bank IFSC code*/}
          {/* <Col sm="3">
                        <FormGroup className="w-100">
                          <strong>
                            <Label className="form-label" for="bankIfscCode">
                              Bank IFSC code {index + 1}*
                            </Label>
                          </strong>
                          <Input
                            type="text"
                            value={bankIfscCode}
                            id="bankIfscCode"
                            name="bankIfscCode"
                            //placeholder="Add bank IFSC code"
                            onChange={(e) => setBankIfscCode(e.target.value)}
                          />
                        </FormGroup>
                      </Col> */}
          {/*//! Cancelled cheque*/}
          {/* <Col sm="3">
                        <strong>
                          <Label className="form-label" for="cancelledCheque">
                            Cancelled cheque {index + 1}*
                          </Label>
                        </strong>
                        <AttachmentComponent
                          imageList={cancelledCheque}
                          setImageList={setCancelledCheque}
                          editData={cancelledChequeImageDummyState}
                          uploadType={uploadCancelledChequeImageType}
                        />
                      </Col>
                    </Row>
                  </Col>
                </React.Fragment>
              )
            )} */}
          {/* {(lessorEntityType?.value === "LLP" ||
            lessorEntityType?.value === "Pvt. ltd.") && (
            <Col sm="12" className="mb-2">
              <AddButton
                addAction={handleAddLessorLLPPvtLtd}
                buttonName={"lessor's info"}
              />
            </Col>
          )} */}
        </Row>

        {lessorEntityType?.value === "Partnership" &&
          lessorPartnership?.map(
            (
              {
                id,
                lessorEntityFullName,
                lessorName,
                lessorAge,
                lessorFatherName,
                lessorCompleteAddress,
                lessorAadharNumber,
                lessorAadharCard,
                lessorPANNumber,
                lessorPANCard,
                lessorGstinNumber,
                lessorGstinCertificate,
                lessorPartnershipPercent,
              },
              index
            ) => (
              <React.Fragment key={id}>
                <hr
                  style={{
                    borderTop: "2px solid #ffe700",
                    width: "100%",
                  }}
                />
                {
                  <h5>
                    <Col sm="12">Lessor's {index + 1} details</Col>
                  </h5>
                }
                <Col sm="12">
                  <Row>
                    {/* //! Lessor's entity full name*/}
                    {/* <Col sm="3">
                      <FormGroup className="w-100">
                        <strong>
                          <Label
                            className="form-label"
                            for="lessorEntityFullName"
                          >
                            Lessor's entity full name {index + 1}*
                          </Label>
                        </strong>
                        <Input
                          type="text"
                          value={lessorEntityFullName}
                          id="lessorEntityFullName"
                          name="lessorEntityFullName"
                          //placeholder="Add lessor's entity full name"
                          onChange={(e) =>
                            setLessorEntityFullName(e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col> */}

                    <Col sm="3">
                      <FormGroup className="w-100">
                        <strong>
                          <Label className="form-label" for="lessorName">
                            Lessor's name
                          </Label>
                        </strong>
                        <Input
                          type="text"
                          value={lessorName}
                          id="lessorName"
                          name="lessorName"
                          //placeholder="Add lessor's name"
                          // onChange={(e) => setLessorName(e.target.value)}
                          onChange={(e) =>
                            handlePartnershipText(
                              e.target.value,
                              id,
                              "lessorName"
                            )
                          }
                        />
                      </FormGroup>
                    </Col>

                    {/* //! Lessor's age*/}

                    <Col sm="3">
                      <FormGroup className="w-100">
                        <strong>
                          <Label className="form-label" for="lessorAge">
                            Lessor's age
                          </Label>
                        </strong>
                        <Input
                          type="text"
                          value={lessorAge}
                          id="lessorAge"
                          name="lessorAge"
                          //placeholder="Add lessor's age"
                          // onChange={(e) => setLessorAge(e.target.value)}
                          onChange={(e) =>
                            handlePartnershipNumber(
                              e.target.value,
                              id,
                              "lessorAge"
                            )
                          }
                        />
                      </FormGroup>
                    </Col>

                    {/* //! Lessor's father name*/}

                    <Col sm="3">
                      <FormGroup className="w-100">
                        <strong>
                          <Label className="form-label" for="lessorFatherName">
                            Lessor's father name
                          </Label>
                        </strong>
                        <Input
                          type="text"
                          value={lessorFatherName}
                          id="lessorFatherName"
                          name="lessorFatherName"
                          //placeholder="Add lessor's father name"
                          // onChange={(e) => setLessorFatherName(e.target.value)}
                          onChange={(e) =>
                            handlePartnershipText(
                              e.target.value,
                              id,
                              "lessorFatherName"
                            )
                          }
                        />
                      </FormGroup>
                    </Col>

                    {/* //! Lessor's complete address*/}

                    <Col sm="3">
                      <FormGroup className="w-100">
                        <strong>
                          <Label
                            className="form-label"
                            for="lessorCompleteAddress"
                          >
                            Lessor's complete address*
                          </Label>
                        </strong>
                        <Input
                          type="text"
                          value={lessorCompleteAddress}
                          id="lessorCompleteAddress"
                          name="lessorCompleteAddress"
                          //placeholder="Add lessor's complete address"
                          // onChange={(e) =>
                          //   setLessorCompleteAddress(e.target.value)
                          // }
                          onChange={(e) =>
                            handlePartnershipText(
                              e.target.value,
                              id,
                              "lessorCompleteAddress"
                            )
                          }
                        />
                      </FormGroup>
                    </Col>

                    {/* //! Lessor's aadhar number*/}

                    <Col sm="3">
                      <FormGroup className="w-100">
                        <strong>
                          <Label
                            className="form-label"
                            for="lessorAadharNumber"
                          >
                            Lessor's aadhar number*
                          </Label>
                        </strong>
                        <Input
                          type="text"
                          value={lessorAadharNumber}
                          id="lessorAadharNumber"
                          name="lessorAadharNumber"
                          //placeholder="Add lessor's aadhar number"
                          // onChange={(e) =>
                          //   setLessorAadharNumber(e.target.value)
                          // }
                          onChange={(e) =>
                            handlePartnershipText(
                              e.target.value,
                              id,
                              "lessorAadharNumber"
                            )
                          }
                        />
                      </FormGroup>
                    </Col>

                    {/*//! Lessor's aadhar card*/}

                    {/* <Col sm="3">
                      <strong>
                        <Label className="form-label" for="lessorAadharCard">
                          Lessor's aadhar card*
                        </Label>
                      </strong>
                      <AttachmentComponent
                        imageList={lessorAadharCard}
                        setImageList={setLessorAadharCard}
                        editData={lessorAadharCardImageDummyState}
                        uploadType={uploadLessorAadharCardImageType}
                      />
                    </Col> */}

                    <Col sm="3">
                      <strong>
                        <Label className="form-label" for="lessorAadharCard">
                          Lessor's Aadhar Card
                        </Label>
                      </strong>
                      <div className="mt-2 d-flex align-items-center gap-2">
                        {lessorAadharCard !== null ? (
                          lessorAadharCard?.includes(".pdf") ? (
                            <Image
                              onClick={() =>
                                window.open(
                                  lessorAadharCard,
                                  "_blank"
                                )
                              }
                              size={45}
                              style={{
                                marginBottom: "20px"
                              }}
                            />
                          ) : (
                            <img
                              className="imageSection__image"
                              src={lessorAadharCard}
                              alt="Attachment"
                              style={{
                                width: "6rem",
                                height: "6rem",
                                objectFit: "cover",
                                cursor: "pointer",
                                marginLeft: "1.7rem",
                              }}
                              onClick={() =>
                                window.open(lessorAadharCard, "_blank")
                              }
                            />
                          )
                        ) : null}

                        <label htmlFor={`aadharFile${id}`}>
                          <p className="btn btn-sm">
                            <Upload size={30} />
                          </p>
                          <input
                            name={`aadharFile${id}`}
                            id={`aadharFile${id}`}
                            className="d-none"
                            type="file"
                            onChange={(e) =>
                              fileUploader(e, id, "lessorAadharCardImage")
                            }
                          />
                        </label>
                      </div>
                    </Col>

                    {/* //! Lessor's PAN number*/}

                    <Col sm="3">
                      <FormGroup className="w-100">
                        <strong>
                          <Label className="form-label" for="lessorPANNumber">
                            Lessor's PAN number
                          </Label>
                        </strong>
                        <Input
                          type="text"
                          value={lessorPANNumber}
                          id="lessorPANNumber"
                          name="lessorPANNumber"
                          //placeholder="Add lessor's PAN number"
                          // onChange={(e) => setLessorPANNumber(e.target.value)}
                          onChange={(e) =>
                            handlePartnershipText(
                              e.target.value,
                              id,
                              "lessorPANNumber"
                            )
                          }
                        />
                      </FormGroup>
                    </Col>

                    {/*//! Lessor's PAN card*/}

                    {/* <Col sm="3">
                      <strong>
                        <Label className="form-label" for="lessorPANCard">
                          Lessor's PAN card*
                        </Label>
                      </strong>
                      <AttachmentComponent
                        imageList={lessorPANCard}
                        setImageList={setLessorPANCard}
                        editData={lessorPANCardImageDummyState}
                        uploadType={uploadLessorPANCardImageType}
                      />
                    </Col> */}

                    <Col sm="3">
                      <strong>
                        <Label className="form-label" for="lessorPANCard">
                          Lessor's PAN card
                        </Label>
                      </strong>
                      <div className="mt-2 d-flex align-items-center gap-2">
                        {lessorPANCard !== null ? (
                          lessorPANCard?.includes(".pdf") ? (
                            <Image
                              onClick={() =>
                                window.open(
                                  lessorPANCard,
                                  "_blank"
                                )
                              }
                              size={45}
                              style={{
                                marginBottom: "20px"
                              }}
                            />
                          ) : (
                            <img
                              className="imageSection__image"
                              src={lessorPANCard}
                              alt="Attachment"
                              style={{
                                width: "6rem",
                                height: "6rem",
                                objectFit: "cover",
                                cursor: "pointer",
                                marginLeft: "1.7rem",
                              }}
                              onClick={() =>
                                window.open(lessorPANCard, "_blank")
                              }
                            />
                          )
                        ) : null}

                        <label htmlFor={`panFile${id}`}>
                          <p className="btn btn-sm">
                            <Upload size={30} />
                          </p>
                          <input
                            name={`panFile${id}`}
                            id={`panFile${id}`}
                            className="d-none"
                            type="file"
                            onChange={(e) =>
                              fileUploader(e, id, "lessorPANCardImage")
                            }
                          />
                        </label>
                      </div>
                    </Col>

                    {/* //! Lessor's GSTIN number*/}

                    <Col sm="3">
                      <FormGroup className="w-100">
                        <strong>
                          <Label className="form-label" for="lessorGstinNumber">
                            Lessor's GSTIN number
                          </Label>
                        </strong>
                        <Input
                          type="text"
                          value={lessorGstinNumber}
                          id="lessorGstinNumber"
                          name="lessorGstinNumber"
                          //placeholder="Add lessor's GSTIN number"
                          // onChange={(e) =>
                          //   setLessorAadharNumber(e.target.value)
                          // }
                          onChange={(e) =>
                            handlePartnershipText(
                              e.target.value,
                              id,
                              "lessorGstinNumber"
                            )
                          }
                        />
                      </FormGroup>
                    </Col>

                    {/*//! Lessor's GSTIN certificate*/}

                    <Col sm="3">
                      <strong>
                        <Label
                          className="form-label"
                          for="lessorGstinCertificate"
                        >
                          Lessor's GSTIN certificate*
                        </Label>
                      </strong>
                      <div className="mt-2 d-flex align-items-center gap-2">
                        {lessorGstinCertificate !== null ? (
                          lessorGstinCertificate?.includes(".pdf") ? (
                            <Image
                              onClick={() =>
                                window.open(
                                  lessorGstinCertificate,
                                  "_blank"
                                )
                              }
                              size={45}
                              style={{
                                marginBottom: "20px"
                              }}
                            />
                          ) : (
                            <img
                              className="imageSection__image"
                              src={lessorGstinCertificate}
                              alt="Attachment"
                              style={{
                                width: "6rem",
                                height: "6rem",
                                objectFit: "cover",
                                cursor: "pointer",
                                marginLeft: "1.7rem",
                              }}
                              onClick={() =>
                                window.open(lessorGstinCertificate, "_blank")
                              }
                            />
                          )
                        ) : null}

                        <label htmlFor={`gstFile${id}`}>
                          <p className="btn btn-sm">
                            <Upload size={30} />
                          </p>
                          <input
                            name={`gstFile${id}`}
                            id={`gstFile${id}`}
                            className="d-none"
                            type="file"
                            onChange={(e) =>
                              fileUploader(e, id, "lessorGstinCertificateImage")
                            }
                          />
                        </label>
                      </div>
                    </Col>

                    {/* //! Lessor's partnership %*/}

                    <Col sm="3">
                      <FormGroup className="w-100">
                        <strong>
                          <Label
                            className="form-label"
                            for="lessorPartnershipPercent"
                          >
                            Lessor's partnership %*
                          </Label>
                        </strong>
                        <Input
                          type="text"
                          value={lessorPartnershipPercent}
                          id="lessorPartnershipPercent"
                          name="lessorPartnershipPercent"
                          //placeholder="Add lessor's partnership %"
                          // onChange={(e) =>
                          //   setLessorPartnershipPercent(e.target.value)
                          // }
                          onChange={(e) =>
                            handlePartnershipNumber(
                              e.target.value,
                              id,
                              "lessorPartnershipPercent"
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </React.Fragment>
            )
          )}

        {lessorEntityType?.value === "Partnership" && (
          <Col sm="12" className="mb-2">
            <AddButton
              addAction={handleAddLessorPartnership}
              buttonName={"lessor's info"}
            />
          </Col>
        )}

        {lessorEntityType?.value?.length > 0 && (
          <>
            <hr
              style={{
                borderTop: "2px solid #ffe700",
                width: "100%",
              }}
            />
            <Row>
              {" "}
              {/* //! Lessor's POC's full name*/}
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label">
                      Lessor's POC's full name
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={pocFullName}
                    id="pocFullName"
                    name="pocFullName"
                    //placeholder="Add lessor's POC's full name"
                    onChange={(e) => setPOCFullName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              {/* //! Lessor's POC's mobile number*/}
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label">
                      Lessor's POC's mobile number
                    </Label>
                  </strong>
                  <Input
                    type="text"
                    value={pocMobileNumber}
                    id="pocMobileNumber"
                    name="pocMobileNumber"
                    //placeholder="Add lessor's POC's mobile number"
                    onChange={(e) => setPOCMobileNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              {/* //! Lessor's POC's email*/}
              <Col sm="3">
                <FormGroup className="w-100">
                  <strong>
                    <Label className="form-label">Lessor's POC's email</Label>
                  </strong>
                  <Input
                    type="text"
                    value={pocEmail}
                    id="pocEmail"
                    name="pocEmail"
                    //placeholder="Add lessor's POC's email"
                    onChange={(e) => setPOCEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        {/* <hr
          style={{
            borderTop: "2px solid #ffe700",
            width: "100%",
          }}
        />*/}
        {/* <h4>Deal registration & licenses</h4> */}
        {/* <Row> */}
        {/* //! Lease deed type */}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="leaseDeedType">
                  Lease deed type*
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="leaseDeedType"
                name="leaseDeedType"
                options={LeaseDeedTypeList}
                value={leaseDeedType}
                onChange={(value) => setLeaseDeedType(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col> */}

        {/* //!  Registered licenses entity */}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="registeredLicensesEntity">
                  Registered licenses entity*
                </Label>
              </strong>
              <Input
                type="text"
                value={registeredLicensesEntity}
                id="registeredLicensesEntity"
                name="registeredLicensesEntity"
                //placeholder="Add registered licenses entity"
                onChange={(e) => setRegisteredLicensesEntity(e.target.value)}
              />
            </FormGroup>
          </Col> */}

        {/* //! Licenses obtainance/renewal responsibility */}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesResponsibility">
                  Licenses obtainance/renewal responsibility*
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="licensesResponsibility"
                name="licensesResponsibility"
                options={LicensesObtainanceOrRenewalResponsibilityTypeList}
                value={licensesResponsibility}
                onChange={(value) => setLicensesResponsibility(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col> */}

        {/* //! Licenses official fees responsibility */}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  Licenses official fees responsibility*
                </Label>
              </strong>

              <Select
                isClearable={false}
                isSearchable={true}
                id="licensesFeesResponsibility"
                name="licensesFeesResponsibility"
                options={LicensesOfficialFeesResponsibilityTypeList}
                value={licensesFeesResponsibility}
                onChange={(value) => setLicensesFeesResponsibility(value)}
                classNamePrefix="select"
                placeholder=""
              />
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  Municipality health trade license*
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>

                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  PCB CTO certificate*
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>

                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  Fire NOC*
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>
                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  Tourism*
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>

                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  PCC NOC*
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>

                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  Excise & bar*
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>

                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  Shop & establishment *
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>

                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  FSSAI *
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>

                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  GST certificate *
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>

                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  Lift clearance *
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>

                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  Signage NOC*
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>

                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}

        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="licensesFeesResponsibility">
                  Music NOC *
                </Label>
              </strong>

              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label
                      className="form-label"
                      for="licensesFeesResponsibility"
                    >
                      Valid*
                    </Label>

                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="licensesFeesResponsibility"
                      name="licensesFeesResponsibility"
                      options={ValidTypeList}
                      value={licensesFeesResponsibility}
                      onChange={(value) => setLicensesFeesResponsibility(value)}
                      classNamePrefix="select"
                      placeholder=""
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="expiry date">
                      Valid upto*
                    </Label>

                    <Flatpickr
                      className="form-control bg-white border-dark text-dark"
                      // value={expirationDate}
                      // onChange={(date) =>
                      //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                      // }
                      id="expirationDate"
                      // placeholder="Add Expiry Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="form-label" for="floorPlans">
                    Upload*
                  </Label>

                  <AttachmentComponent
                    imageList={floorPlans}
                    setImageList={setFloorPlans}
                    editData={floorPlansImageDummyState}
                    uploadType={uploadFloorPlansImageType}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col> */}
        {/* </Row> */}

        {/* <hr
          style={{
            borderTop: "2px solid #ffe700",
            width: "100%",
          }}
        /> */}
        {/* <h4>Deal rent & security deposit</h4> */}
        {/* <Row> */}
        {/* //!  Monthly rent */}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="monthlyRent">
                  Monthly rent*
                </Label>
              </strong>
              <Input
                type="text"
                value={monthlyRent}
                id="monthlyRent"
                name="monthlyRent"
                //placeholder="Add monthly rent"
                onChange={(e) => setMonthlyRent(e.target.value)}
              />
            </FormGroup>
          </Col> */}

        {/* //!  Lease duration (in years) */}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="leaseDuration">
                  Lease duration (in years)*
                </Label>
              </strong>
              <Input
                type="text"
                value={leaseDuration}
                id="leaseDuration"
                name="leaseDuration"
                //placeholder="Add lease duration (in years)"
                onChange={(e) => setLeaseDuration(e.target.value)}
              />
            </FormGroup>
          </Col> */}

        {/* //!  Security deposit payable (during token) */}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositToken">
                  Security deposit payable (during token)*
                </Label>
              </strong>
              <Input
                type="text"
                value={securityDepositToken}
                id="securityDepositToken"
                name="securityDepositToken"
                //placeholder="Add security deposit payable (during token)"
                onChange={(e) => setSecurityDepositToken(e.target.value)}
              />
            </FormGroup>
          </Col> */}

        {/* //!  Security deposit payable (during registration) */}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositRegistration">
                  Security deposit payable (during registration)*
                </Label>
              </strong>
              <Input
                type="text"
                value={securityDepositRegistration}
                id="securityDepositRegistration"
                name="securityDepositRegistration"
                //placeholder="Add security deposit payable (during registration)"
                onChange={(e) => setSecurityDepositRegistration(e.target.value)}
              />
            </FormGroup>
          </Col> */}

        {/* //!  Security deposit payable (during handover) */}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositHandover">
                  Security deposit payable (during handover)*
                </Label>
              </strong>
              <Input
                type="text"
                value={securityDepositHandover}
                id="securityDepositHandover"
                name="securityDepositHandover"
                //placeholder="Add Security deposit payable (during handover)"
                onChange={(e) => setSecurityDepositHandover(e.target.value)}
              />
            </FormGroup>
          </Col> */}

        {/* //!  Security deposit payable (at rent start date) */}
        {/* <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="securityDepositRentStart">
                  Security deposit payable (at rent start date)*
                </Label>
              </strong>
              <Input
                type="text"
                value={securityDepositRentStart}
                id="securityDepositRentStart"
                name="securityDepositRentStart"
                //placeholder="Add Security deposit payable (at rent start date)"
                onChange={(e) => setSecurityDepositRentStart(e.target.value)}
              />
            </FormGroup>
          </Col> */}
        {/* </Row> */}
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
