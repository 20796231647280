import { gql, useLazyQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_OTA_RATING_MONTHLY_REPORT = gql`
  query getOtaRatingMonthlyReport(
    $monthStart: Int!
    $monthEnd: Int!
    $yearStart: Int!
    $yearEnd: Int!
    $bookingSource: String!
  ) {
    getOtaRatingMonthlyReport(
      monthStart: $monthStart
      monthEnd: $monthEnd
      yearStart: $yearStart
      yearEnd: $yearEnd
      bookingSource: $bookingSource
    ) {
      _id
      name
      rating
    }
  }
`;

export const useGetOtaRatingMonthlyReport = (
  monthStart,
  monthEnd,
  yearStart,
  yearEnd,
  bookingSource
) => {
  const [fetchReport, { loading, error, data }] = useLazyQuery(
    GET_OTA_RATING_MONTHLY_REPORT,
    {
      variables: {
        monthStart,
        monthEnd,
        yearStart,
        yearEnd,
        bookingSource,
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, fetchReport };
};


export const GET_BRANDLEVEL_AVG_RATING = gql`
query ($yearArray: [Int!]!, $monthArray: [Int!]! ) {
    brandLevelAvgRatings (yearArray: $yearArray monthArray: $monthArray) {
        _id
        data
    }
}
`;

export const useGetBrandlevelAvgRating = (
  yearArray,
  monthArray,
) => {
  const [fetchReport, { loading, error, data }] = useLazyQuery(
    GET_BRANDLEVEL_AVG_RATING,
    {
      variables: {
        yearArray,
        monthArray,
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, fetchReport };
};

export const GET_PROPERTYWISE_MONTHLY_ORM = gql`
query ($yearArray: [Int!]!, $monthArray: [Int!]! ) {
    propertyWiseMonthlyORM (yearArray: $yearArray monthArray: $monthArray) {
        _id
        hostelName
        data
    
    }
}
`;

export const useGetPropertywiseMonthlyORM = (
  yearArray,
  monthArray,
) => {
  const [fetchReport, { loading, error, data }] = useLazyQuery(
    GET_PROPERTYWISE_MONTHLY_ORM,
    {
      variables: {
        yearArray,
        monthArray,
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, fetchReport };
};

export const GET_BRAND_LEVEL_ANALYTICS = gql`
query ($yearArray: [Int!]!, $monthArray: [Int!]! ) {
    brandLevelAnalytics (yearArray: $yearArray monthArray: $monthArray) {
        field
        data
    
    }
}
`;

export const useGetBrandLevelAnalytics = (
  yearArray,
  monthArray,
) => {
  const [fetchReport, { loading, error, data }] = useLazyQuery(
    GET_BRAND_LEVEL_ANALYTICS,
    {
      variables: {
        yearArray,
        monthArray,
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, fetchReport };
};

export const GET_POSITIVE_CATEGORY_COUNT = gql`
query ($hostelId: [ID]! $fromDate: String! $toDate: String! $bookingSource:[String]! $teams: [String]! ) {
        getPositiveCategoriesCount (hostelId: $hostelId fromDate:$fromDate 
         toDate: $toDate bookingSource: $bookingSource teams: $teams)
      }
`;

export const useGetPositiveCategoryCount = (
  hostelId, fromDate, toDate, bookingSource, teams
) => {
  const [fetchReport, { loading, error, data }] = useLazyQuery(
    GET_POSITIVE_CATEGORY_COUNT,
    {
      variables: {
        hostelId, fromDate, toDate, bookingSource, teams
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, fetchReport };
};

export const GET_NEGATIVE_CATEGORY_COUNT = gql`
query ($hostelId: [ID]! $fromDate: String! $toDate: String! $bookingSource:[String]! $teams: [String]! ) {
        getNegativeCategoriesCount (hostelId: $hostelId fromDate:$fromDate 
         toDate: $toDate bookingSource: $bookingSource teams: $teams)
      }
`;

export const useGetNegativeCategoryCount = (
  hostelId, fromDate, toDate, bookingSource, teams
) => {
  const [fetchReport, { loading, error, data }] = useLazyQuery(
    GET_NEGATIVE_CATEGORY_COUNT,
    {
      variables: {
        hostelId, fromDate, toDate, bookingSource, teams
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, fetchReport };
};

export const GET_TEAMS_COUNT = gql`
query ($hostelId: [ID]! $fromDate: String! $toDate: String! $bookingSource:[String]! $teams: [String]! ) {
        getTeamsCount (hostelId: $hostelId fromDate:$fromDate 
         toDate: $toDate bookingSource: $bookingSource teams: $teams)
      }
`;

export const useGetTeamsCount = (
  hostelId, fromDate, toDate, bookingSource, teams
) => {
  const [fetchReport, { loading, error, data }] = useLazyQuery(
    GET_TEAMS_COUNT,
    {
      variables: {
        hostelId, fromDate, toDate, bookingSource, teams
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, fetchReport };
};