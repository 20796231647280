import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  FormGroup,
  CustomInput,
  Table,
  CardBody,
} from "reactstrap";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import GluSendLink from "./GluSendLink";

const UserProfile = () => {
  const [disablePopup, setDisablePopup] = useState(false);
  const [disableSound, setDisableSound] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    const storedDisablePopup = localStorage.getItem("disablePopup") === "true";
    const storedDisableSound = localStorage.getItem("disableSound") === "true";
    const storedName = localStorage.getItem("name") || "";
    const storedEmail = localStorage.getItem("email") || "";
    const storedDepartment = localStorage.getItem("department") || "";
    const storedRole = localStorage.getItem("role") || "";

    setDisablePopup(storedDisablePopup);
    setDisableSound(storedDisableSound);
    setName(storedName);
    setEmail(storedEmail);
    setDepartment(storedDepartment);
    setRole(storedRole);
  }, []);

  const handleSaveSettings = () => {
    localStorage.setItem("disablePopup", disablePopup);
    localStorage.setItem("disableSound", disableSound);
    toast.success("Settings saved successfully!");
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>User Info</CardTitle>
          </CardHeader>
          <Table responsive>
            <tbody>
              <tr>
                <td>
                  <strong>Name: </strong>
                </td>
                <td>{name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Email: </strong>
                </td>
                <td>{email}</td>
              </tr>
              <tr>
                <td>
                  <strong>Department: </strong>
                </td>
                <td>{department}</td>
              </tr>
              <tr>
                <td>
                  <strong>Role: </strong>
                </td>
                <td>{role}</td>
              </tr>
            </tbody>
          </Table>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>
              <strong>Notification Settings</strong>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <CustomInput
                type="switch"
                id="disablePopup"
                label={
                  <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                    Notification Popup (Turn off to disable)
                  </span>
                }
                checked={!disablePopup}
                onChange={(e) => setDisablePopup(!e.target.checked)}
              />
            </FormGroup>
            <FormGroup>
              <CustomInput
                type="switch"
                id="disableSound"
                label={
                  <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                    Notification Sound (Turn off to disable)
                  </span>
                }
                checked={!disableSound}
                onChange={(e) => setDisableSound(!e.target.checked)}
              />
            </FormGroup>
            <Button color="success" size="sm" onClick={handleSaveSettings}>
              Save Settings
            </Button>
          </CardBody>
        </Card>
        <GluSendLink />
      </Col>
    </Row>
  );
};

export default UserProfile;
