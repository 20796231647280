//Added By Gaurav
import gql from "graphql-tag";

// export const WEBSITE_HOURLY_SALES_REPORT = gql`
// query {
//     websiteHourlySalesReport {
//         Hour
//         bookingCount
//     }
// }
// `;

export const WEBSITE_HOURLY_SALES_REPORT = gql`
query websiteHourlySalesReport($DateRange: DateRange, $hostelIds: [ID]){
    websiteHourlySalesReport(DateRange:$DateRange, hostelIds: $hostelIds) {
        Hour
        bookingCount
    }
}
`;

//Added by Pruthvi
export const WEBSITE_DAY_SALES_REPORT = gql`
query websiteDaySalesReport($DateRange: DateRange, $hostelIds: [ID]){
    websiteDaySalesReport(DateRange:$DateRange, hostelIds: $hostelIds) {
        Day
        bookingCount
    }
}
`;

//Added by Pruthvi
export const EZZEBOOKING_HOURLY_SALES_REPORT = gql`
query ezzeBookingHourlySalesReport($DateRange: DateRange, $hostelIds: [ID]){
    ezzeBookingHourlySalesReport(DateRange:$DateRange, hostelIds: $hostelIds) {
        Hour
        bookingCount
    }
}
`;

//Added by Pruthvi
export const EZZEBOOKING_DAY_SALES_REPORT = gql`
query ezzeBookingDaySalesReport($DateRange: DateRange, $hostelIds: [ID]){
    ezzeBookingDaySalesReport(DateRange:$DateRange, hostelIds: $hostelIds) {
        Day
        bookingCount
    }
}
`;
