import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useMutation, useQuery } from "@apollo/client";

export const GET_BUSBOOKING_XLSX_REPORT = gql`
    mutation getBusBookingExport($filter:redBusFilters){
    getBusBookingExport(filter:$filter)
    }
`
export const useGetBusbookingExport = () => {
    const [getBusbookingXlsxExport, { loading, error, data, refetch }] =
        useMutation(GET_BUSBOOKING_XLSX_REPORT);
    useLoadingHandler(loading);
    const getBusbookingXlsxExportHandler = (filter) => {
        getBusbookingXlsxExport({
            variables: {
                filter,
            },
        });
    };
    return [getBusbookingXlsxExportHandler, { data, refetch, loading, error }];
};