// ! Added by Ashwin

import gql from "graphql-tag";

export const GET_MESSAGE_TEMPLATE_LIST = gql`
  query getAllMessageTemplateList {
    getAllMessageTemplateList {
      _id
      TemplateType
      TemplateName
      TemplateMessage
      TemplateCode
      UrlParameter
      templateUrlCode
      logs
    }
  }
`;

export const GET_PAGINATED_MESSAGE_CONFIGURATION = gql`
  query getPaginatedMessageConfigurationList($filter: filter) {
    getPaginatedMessageConfigurationList(filter: $filter) {
      count
      page
      limit
      data {
        _id
        template
        hostels
        guestStatus
        fromDate
        toDate
        timeToSend
        gender
        groupSize
        residingCountry
        residingState
        stayedWithTheHostellerBefore
        whatWouldBestDescribeYou
        modeOfTransport
        campaignName
        minimumNoOfNights
        bookingFrequency
        lastStayed
        age
        bookingSource
        stayDuration
        daysToSend
        toCheckinDate
        fromCheckinDate
        frequencyStartDate
        frequencyEndDate
        stayFrequency
        membershipStatus
        lastStayedStartDate
        lastStayedEndDate
        notStayedAfterDate
        notStayedAfterMonth
        logs
      }
    }
  }
`;

export const GET_MESSAGE_TEMPLATE_BY_NAME = gql`
  query getMessageTemplateByName($templateName: String) {
    getMessageTemplateByName(templateName: $templateName) {
      _id
      TemplateType
      TemplateName
      TemplateMessage
      TemplateCode
      UrlParameter
      templateUrlCode
    }
  }
`;

