//  Added by Nitish
import React, { useState, useEffect } from "react";
import ReviewsTable from "./ReviewsTable";
import AddEditModal from "./AddEditModal";
import Select from "react-select";
import { Col, Row, FormGroup, Label, Button, Input } from "reactstrap";
import TablePagination from "../../@core/components/common/TablePagination";
import {
  useGetPaginatedOtaReviews,
  useHostelList,
  useDeleteOrmReview,
  useGenerateORMExport,
} from "../../api/dataSource";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { selectThemeColors, dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import toaster from "../../@core/components/common/Toaster";
import AddReviewModal from "./AddReviewModal";

const ORMReviews = () => {
  const [openModal, setOpenModal] = useState(false);
  const [limit, setLimit] = useState(20);
  const [exportTableData, setExportTableData] = useState([]);
  const [bookingSource, setBookingSource] = useState("");
  const [reviewDateTo, setReviewDateTo] = useState(null);
  const [reviewDateFrom, setReviewDateFrom] = useState(null);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState([]);
  const { data: allHostelsData } = useHostelList();
  const [filterFields, setFilterFields] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });
  const [reviewReply, setReviewReply] = useState("");
  const [reviewRating, setReviewRating] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [sentiment, setSentiment] = useState("");
  const [guestName, setGuestName] = useState("");
  const [cxTicketNumber, setCXTicketNumber] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  const [fakeReply, SetFakeReply] = useState("");
  const [dataCount, setDataCount] = useState(0);
  const [justified, setJustified] = useState("");
  const [sentForRemoval, setSentForRemoval] = useState("");
  const [deleteOrmReviewHandler, { data: deletedOrmReview }] =
    useDeleteOrmReview();

  const [repliedBy, setRepliedBy] = useState(null);

  const [resolution, setResolution] = useState(null);

  const [filtersExpanded, setFiltersExpanded] = useState(true);

  const { data, refetch } = useGetPaginatedOtaReviews({
    limit,
    page,
    filterFields,
  });

  const exportInputFilter = {
    filterFields,
  };
  const [ReviewModalOpen, setReviewModalOpen] = useState(false);

  const openAddReviewModal = () => {
    setReviewModalOpen(true);
  };

  const closeAddReviewModal = () => {
    setReviewModalOpen(false);
  };

  const [generateORMExportHandler, { data: ormDataForExport }] =
    useGenerateORMExport();

  useEffect(() => {
    if (ormDataForExport?.generateORMExport?.data) {
      if (ormDataForExport?.generateORMExport?.data.length > 0) {
        const byteArray = new Uint8Array(
          ormDataForExport?.generateORMExport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `ORM_Reviews_Export.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [ormDataForExport]);

  useEffect(() => {
    if (deletedOrmReview?.deleteOrmReview) {
      refetch();
      toaster("success", "Review deleted");
    }
  }, [deletedOrmReview?.deleteOrmReview, refetch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

  useEffect(() => {
    if (data?.getPaginatedOtaReviews?.data.length > 0) {
      setTableData(data?.getPaginatedOtaReviews?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getPaginatedOtaReviews?.count / limit));
  }, [
    data?.getPaginatedOtaReviews?.count,
    data?.getPaginatedOtaReviews?.data,
    limit,
  ]);

  useEffect(() => {
    if (
      data?.getPaginatedOtaReviews?.data &&
      data.getPaginatedOtaReviews.data.length > 0
    ) {
      let editData = data.getPaginatedOtaReviews.data.find(
        (item) => item._id === id
      );

      if (editData) {
        setEditData(editData);
      } else {
        setId(null);
        setEditData(null);
      }
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedOtaReviews?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    setDataCount(data?.getPaginatedOtaReviews?.count);
  }, [data?.getPaginatedOtaReviews?.count]);

  const bookingSourceDropDownList = [
    { label: "Booking.com", value: "Booking.com" },
    { label: "Goibibo", value: "Goibibo" },
    { label: "HostelWorld", value: "HostelWorld" },
    { label: "MakeMyTrip", value: "MakeMyTrip" },
    { label: "Tripadvisor", value: "Tripadvisor" },
    { label: "Google", value: "Google" },
    { label: "Airbnb", value: "Airbnb" },
    { label: "Agoda", value: "Agoda" },
  ];

  const sentimentDropDownList = [
    { label: "Positive", value: "Positive" },
    { label: "Negative", value: "Negative" },
    { label: "Neutral", value: "Neutral" },
  ];

  const repliedByDropDown = [
    { label: "Aryan", value: "Aryan" },
    { label: "Dhriti", value: "Dhriti" },
    { label: "Joanne", value: "Joanne" },
    { label: "Raj", value: "Raj" },
    { label: "Aanchal", value: "Aanchal" },
  ];

  const justifiedDropDownList = [
    { label: "Justified", value: "Justified" },
    { label: "Unjustified", value: "Unjustified" },
    { label: "Partially", value: "Partially" },
    { label: "Can't say", value: "Can't say" },
  ];

  const fakeDropDownList = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const reviewReplyDropdown = [
    { label: "Replied", value: "Replied" },
    { label: "Not Replied", value: "Not Replied" },
    { label: "Can't Reply", value: "Can't Reply" },
  ];

  const ratingListDropdown = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
  ];

  const sentForRemovalOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const ticketDropdownOptions = [
    { label: "Ticket Not Created", value: "ticketNotCreated" },
    { label: "Ticket Created", value: "ticketCreated" },
    { label: "Ticket Closed - GO", value: "ticketClosedGO" },
    { label: "Ticket Closed - CX", value: "ticketClosedByCX" },
  ];

  const resolutionDropdown = [
    { label: "N/A", value: "N/A" },
    { label: "Apology mail", value: "apologyMail" },
    { label: "Coupon", value: "Coupon" },
    {
      label: "Communication not established",
      value: "communicationNotEstablished",
    },
    { label: "Refund", value: "Refund" },
    { label: "Amendment Mail", value: "amendmentMail" },
    { label: "Follow-up Mail", value: "followUpMail" },
  ];

  const handleSearch = () => {
    const filterFields = {
      hostelIdArray:
        hostel.length > 0
          ? hostel?.map((hostel) => hostel.value)
          : JSON.parse(localStorage.getItem("hostels")),
      bookingSource: bookingSource?.value,
      reviewDateFrom,
      reviewDateTo,
      reviewReply: reviewReply?.value,
      reviewRating: reviewRating?.value,
      tickets: tickets?.value,
      sentiment: sentiment?.value,
      guestName,
      reservationId,
      cxTicketNumber,
      searchKeyword,
      fakeReply: fakeReply?.value,
      justified: justified?.value,
      repliedBy: repliedBy?.value,
      sentForRemoval: sentForRemoval?.value,
      resolution: resolution?.value,
    };
    setPage(1);
    setFilterFields(filterFields);
  };

  const handleReset = () => {
    setFilterFields({
      hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
    });
    setReviewDateFrom(null);
    setReviewDateTo(null);
    setBookingSource("");
    setReviewRating("");
    setReviewReply("");
    setHostel([]);
    setSentiment("");
    setGuestName("");
    setReservationId("");
    setTickets("");
    setCXTicketNumber("");
    setSearchKeyword("");
    SetFakeReply("");
    setJustified("");
    setRepliedBy(null);
    setSentForRemoval("");
    setResolution(null);
  };

  return (
    localStorage.getItem("modules")?.includes("ORM Reports") && (
      <div>
        <Button
          style={{
            fontSize: "12px",
            padding: "6px 12px",
            marginBottom: "20px",
            transition:
              "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
            cursor: "pointer",
            outline: "none",
            backgroundImage:
              "linear-gradient(to right, #ff6ec4 0%, #7873f5 100%)",
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
          onClick={() => setFiltersExpanded(!filtersExpanded)}
        >
          {filtersExpanded ? "Collapse Filters" : "Expand Filters"}
        </Button>

        {filtersExpanded && (
          <div>
            {" "}
            <Row>
              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="url">
                    <strong>Hostel</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    theme={selectThemeColors}
                    isMulti
                    id="hostel"
                    name="hostel"
                    value={hostel}
                    closeMenuOnSelect={false}
                    options={hostelsList}
                    onChange={(value) => {
                      setHostel(value);
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="url">
                    <strong>Date Range</strong>
                  </Label>
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    id="reviewDate"
                    placeholder="Review Date Range"
                    options={{
                      mode: "range",
                    }}
                    value={[reviewDateFrom, reviewDateTo]}
                    onChange={(date) => {
                      setReviewDateFrom(dateFormatter(date[0]));
                      setReviewDateTo(dateFormatter(date[1]));
                    }}
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="ticket">
                    <strong>Ticket</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="ticket"
                    name="ticket"
                    value={tickets}
                    options={ticketDropdownOptions}
                    onChange={(value) => {
                      setTickets(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="bookingSource">
                    <strong>Channels</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="bookingSource"
                    name="bookingSource"
                    value={bookingSource}
                    options={bookingSourceDropDownList}
                    onChange={(value) => {
                      setBookingSource(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="name">
                    <strong>Guest Name</strong>
                  </Label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Guest Name"
                    value={guestName}
                    onChange={(e) => {
                      setGuestName(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="searchKeyword">
                    <strong>Search Keyword</strong>
                  </Label>
                  <Input
                    type="text"
                    id="searchKeyword"
                    name="searchKeyword"
                    placeholder="Search Keyword"
                    value={searchKeyword}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="cxTicketNumber">
                    <strong>Ticket Number</strong>
                  </Label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Search Ticket Number: CXTHXX"
                    value={cxTicketNumber}
                    onChange={(e) => {
                      setCXTicketNumber(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="reservationId">
                    <strong>Reservation ID</strong>
                  </Label>
                  <Input
                    type="text"
                    id="reservationId"
                    name="reservationId"
                    placeholder="Reservation ID"
                    value={reservationId}
                    onChange={(e) => {
                      setReservationId(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="sentiment">
                    <strong>Sentiment</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="sentiment"
                    name="sentiment"
                    value={sentiment}
                    options={sentimentDropDownList}
                    onChange={(value) => {
                      setSentiment(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="justified">
                    <strong>Justification</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="justified"
                    name="justified"
                    value={justified}
                    options={justifiedDropDownList}
                    onChange={(value) => {
                      setJustified(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="sentForRemoval">
                    <strong>Sent for Removal</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="sentForRemoval"
                    name="sentForRemoval"
                    value={sentForRemoval}
                    options={sentForRemovalOptions}
                    onChange={(value) => {
                      setSentForRemoval(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="reviewReply">
                    <strong>Responsiveness</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="reviewReply"
                    name="reviewReply"
                    value={reviewReply}
                    options={reviewReplyDropdown}
                    onChange={(value) => {
                      setReviewReply(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="rating">
                    <strong>Rating</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="reviewRating"
                    name="reviewRating"
                    value={reviewRating}
                    options={ratingListDropdown}
                    onChange={(value) => {
                      setReviewRating(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="fakeReply">
                    <strong>Fake Review</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="fakeReply"
                    name="fakeReply"
                    value={fakeReply}
                    options={fakeDropDownList}
                    onChange={(value) => {
                      SetFakeReply(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="repliedBy">
                    <strong>Investigated By</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="repliedBy"
                    name="repliedBy"
                    value={repliedBy}
                    options={repliedByDropDown}
                    onChange={(value) => {
                      setRepliedBy(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="2">
                <FormGroup className="w-100">
                  <Label className="form-label" for="resolution">
                    <strong>Resolution</strong>
                  </Label>
                  <Select
                    isClearable={true}
                    id="resolution"
                    name="resolution"
                    value={resolution}
                    options={resolutionDropdown}
                    onChange={(value) => {
                      setResolution(value);
                    }}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Row
                style={{
                  marginLeft: "auto",
                }}
              >
                <Col className="mb-2">
                  <Button
                    style={{
                      backgroundColor: "#ffe700",
                      height: "40px",
                      marginTop: "22px",
                      borderRadius: "5px",
                      padding: "10px 40px",
                      outline: "none",
                      border: "0",
                    }}
                    color="white"
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      handleSearch();
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    className="pull-right"
                    style={{
                      backgroundColor: "#ff9999",
                      height: "40px",
                      marginTop: "22px",
                      marginLeft: "40px",
                      borderRadius: "5px",
                      padding: "10px 40px",
                      outline: "none",
                      border: "0",
                    }}
                    color="danger"
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      handleReset();
                    }}
                  >
                    Reset
                  </Button>

                  <Button
                    style={{
                      backgroundColor: "#ff9999",
                      height: "40px",
                      marginTop: "22px",
                      marginLeft: "40px",
                      borderRadius: "5px",
                      padding: "10px 40px",
                      outline: "none",
                      border: "0",
                    }}
                    color="success"
                    appearance="success"
                    type="button"
                    onClick={() => generateORMExportHandler(exportInputFilter)}
                  >
                    Export
                  </Button>
                </Col>
              </Row>
            </Row>
          </div>
        )}

        <ReviewsTable
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          openAddReviewModal={openAddReviewModal}
          refetch={refetch}
          data={tableData}
          exportData={exportTableData}
          dataCount={dataCount}
          deleteOrmReviewHandler={deleteOrmReviewHandler}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
        <AddReviewModal
          open={ReviewModalOpen}
          handleToggle={closeAddReviewModal}
          refetch={refetch}
        />
      </div>
    )
  );
};

export default ORMReviews;
