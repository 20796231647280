import React from "react";
import { useState, useEffect } from "react";
import { Col, Row, Input, FormGroup, Label, CustomInput } from "reactstrap";
import Select from "react-select";
import toaster from "@components/common/Toaster";
import SlideModal from "@components/common/slide-modal";
import { useCreateMembership } from "../../../api/Memberships/Mutation";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { dateValidator } from "../../../utility/Utils";
import SwitchIcon from "@components/common/switch-icon";

const membershipTypeList = [
  { label: "EXPLORER", value: "EXPLORER" },
  { label: "VOYAGER", value: "VOYAGER" },
  { label: "WANDERER", value: "WANDERER" },
];

export default function AddEditModal({
  open,
  editData,
  handleToggle,
  refetch,
}) {
  const [email, setEmail] = useState("");
  const [createUserAccount, setCreateUserAccount] = useState(false);
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [startDate, setStartDate] = useState(dateFormatter(new Date()));
  const [endDate, setEndDate] = useState(dateFormatter(new Date()));
  const [membershipId, setMembershipId] = useState("");
  const [membershipType, setMembershipType] = useState(null);
  const [membershipAmount, setMembershipAmount] = useState("");
  const [membershipTax, setMembershipTax] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [cashbackPercentage, setCashbackPercentage] = useState("");
  const [reservationNo, setReservationNo] = useState("");
  const [travelKitSend, setTravelKitSend] = useState(false);

  const [createMembershipHandler, { data: createdMembershipData }] =
    useCreateMembership();
  // const [updateMembershipHandler, { data: updatedPartnerNewData }] =
  //   useUpdateMemberships();

  const onSubmitHandler = () => {
    const data = {
      email: email.toLowerCase(),
      createUserAccount,
      fullName,
      mobile,
      // membershipId,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      membershipType: membershipType?.value,
      membershipAmount: Number(membershipAmount),
      membershipTax: Number(membershipTax),
      totalAmount: Number(totalAmount),
      discountPercentage: Number(discountPercentage),
      cashbackPercentage: Number(cashbackPercentage),
      // reservationNo,
      travelKitSend,
    };

    console.log(data, "CHECK DATA");

    if (
      email &&
      startDate &&
      endDate &&
      membershipType &&
      membershipAmount &&
      membershipTax &&
      totalAmount &&
      discountPercentage &&
      cashbackPercentage
    ) {
      createMembershipHandler(data);
    }

    // if (membershipId) {
    //   if (editData?._id) {
    //     const _id = editData._id;
    //     updateMembershipHandler(_id, data);
    //   } else {
    //     //   createPartnerNewHandler(data);
    //   }

    //   toaster("success", "Partner added successfully");

    //   refetch();
    // } else {
    //   toaster("error", "Please fill all required details");
    // }
  };

  // console.log(startDate, endDate, "START , END");

  // const handleDiscountPercentage =()=>
  // }

  useEffect(() => {
    const start = new Date(startDate);
    const end = new Date(start);
    end.setFullYear(end.getFullYear() + 1);
    end.setDate(end.getDate() - 1);
    setEndDate(dateFormatter(end));
  }, [startDate]);

  useEffect(() => {
    if (membershipType?.value === "EXPLORER") {
      setDiscountPercentage(5);
      setCashbackPercentage(150);
      setMembershipAmount(4999);
      setMembershipTax(((4999 * 18) / 100).toFixed(2));
    }
    if (membershipType?.value === "VOYAGER") {
      setDiscountPercentage(10);
      setCashbackPercentage(200);
      setMembershipAmount(9999);
      setMembershipTax(((9999 * 18) / 100).toFixed(2));
    }
    if (membershipType?.value === "WANDERER") {
      setDiscountPercentage(15);
      setCashbackPercentage(250);
      setMembershipAmount(14999);
      setMembershipTax(((14999 * 18) / 100).toFixed(2));
    }
  }, [membershipType]);

  useEffect(() => {
    const total = Number(membershipAmount) + Number(membershipTax);
    setTotalAmount(total.toFixed(2));
  }, [membershipAmount, membershipTax]);

  useEffect(() => {
    if (createdMembershipData?.createMembership?.membershipType) {
      toaster("success", "Membership created successfully");
      refetch();
      handleToggle();
    }
  }, [createdMembershipData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="email">
                Email*
              </Label>
              <Input
                type="email"
                value={email}
                id="email"
                name="email"
                //placeholder="Add Email"
                onChange={(e) => setEmail(e.target.value)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <CustomInput
                type="checkbox"
                id="createUserAccount"
                name="user account"
                value={createUserAccount}
                label="Create User Account"
                className="custom-control-Primary"
                onChange={(e) => setCreateUserAccount(e.target.checked)}
                disabled={editData?._id}
              />
            </FormGroup>
          </Col>

          {createUserAccount && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="fullName">
                  Full Name*
                </Label>
                <Input
                  type="text"
                  value={fullName}
                  id="fullName"
                  name="fullName"
                  //placeholder="Add Full Name"
                  onChange={(e) => setFullName(e.target.value)}
                  isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}
          {createUserAccount && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="mobile">
                  Mobile Number*
                </Label>
                <Input
                  type="mobile"
                  value={mobile}
                  id="mobile"
                  name="mobile"
                  //placeholder="Add Mobile Number"
                  onChange={(e) => setMobile(e.target.value)}
                  isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="startDate">
                Start date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={startDate}
                onChange={(date) => setStartDate(dateFormatter(new Date(date)))}
                id="startDate"
                options={{
                  minDate: "today",
                }}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="endDate">
                End date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={endDate}
                // onChange={(date) => setEndDate(dateFormatter(new Date(date)))}
                id="endDate"
                disabled
              />
            </FormGroup>
          </Col>

          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="membershipId">
                Membership Id
              </Label>
              <Input
                type="text"
                value={membershipId}
                id="membershipId"
                name="membershipId"
                //placeholder="Membership Id"
                onChange={(e) => setMembershipId(e.target.value)}
              />
            </FormGroup>
          </Col> */}

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="membershipType">
                  Membership type
                </Label>
              </strong>
              <Select
                isSearchable={true}
                isClearable={false}
                id="membershipType"
                name="membershipType"
                value={membershipType}
                options={membershipTypeList}
                onChange={(value) => setMembershipType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="membershipAmount">
                Membership Amount
              </Label>
              <Input
                type="text"
                value={membershipAmount}
                id="membershipAmount"
                name="membershipAmount"
                //placeholder="Membership amount"
                // disabled
                onChange={(e) => setMembershipAmount(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="membershipTax">
                Membership tax
              </Label>
              <Input
                type="text"
                value={membershipTax}
                id="membershipTax"
                name="membershipTax"
                //placeholder="Membership tax"
                // disabled
                onChange={(e) => setMembershipTax(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="totalAmount">
                Total amount
              </Label>
              <Input
                type="text"
                value={totalAmount}
                id="totalAmount"
                name="totalAmount"
                //placeholder="Total amount"
                // onChange={(e) => setTotalAmount(e.target.value)}
                disabled
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="discountPercentage">
                Discount percentage
              </Label>
              <Input
                type="text"
                value={discountPercentage}
                id="discountPercentage"
                name="discountPercentage"
                //placeholder="Discount percentage"
                disabled
                // onChange={() => handleDiscountPercentage}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="cashbackPercentage">
                Cashback percentage
              </Label>
              <Input
                type="text"
                value={cashbackPercentage}
                id="cashbackPercentage"
                name="cashbackPercentage"
                //placeholder="cashback percentage"
                disabled
                // onChange={() => handleDiscountPercentage}
              />
            </FormGroup>
          </Col>

          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="reservationNo">
                Reservation number
              </Label>
              <Input
                type="text"
                value={reservationNo}
                id="reservationNo"
                name="reservationNo"
                //placeholder="Reservation number"
                onChange={(e) => setReservationNo(e.target.value)}
              />
            </FormGroup>
          </Col> */}
          <Col sm="12">
            <FormGroup className="w-100">
              {/* <Label className="form-label" for="reservationNo">
                Reservation number
              </Label> */}

              <SwitchIcon
                checked={travelKitSend}
                id="travelKitSend"
                name="travelKitSend"
                label="Travel kit send ?"
                handleChecked={(value) => setTravelKitSend(value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
}
