import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";



export const CREATE_AND_UPDATE_TRACKER = gql`
   mutation createAndUpdateTracker($meterTrackerInput:[meterTrackerInput]){
    createAndUpdateTracker(meterTrackerInput:$meterTrackerInput){
        data
    }
   }
`


export const useGetCreateAndUpdate = () => {
    const [updateTracker, { loading, error, data, refetch }] = useMutation(CREATE_AND_UPDATE_TRACKER)

    useLoadingHandler(loading)


    const updateTrackerHandler = (meterTrackerInput) => {
        updateTracker({
            variables: {
                meterTrackerInput
            }
        })
    }

    return [updateTrackerHandler, { data, error, refetch }]
}