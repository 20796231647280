import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, CardHeader, CardTitle, Table, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { Edit2 } from "react-feather";
import LogsModal from './LogsModal';
import toaster from "@components/common/Toaster";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";
import { useSaveHouseKeepingData } from '../../../api/HouseKeepingTracker/Mutation';
import { useGetHouseKeepingData } from '../../../api/HouseKeepingTracker/Queries';

const HouseKeepingTrackerTable = ({roomsData, id, date}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const [cleaningTimes, setCleaningTimes] = useState({});
  const [houseKeepingData, setHouseKeepingData] = useState(null)
  const [notes, setNotes] = useState({})
  const [selectedRoomNo, setSelectedRoomNo] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [savedTimes, setSavedTimes] = useState({});
  const [ saveHouseKeepingDataHandler ] = useSaveHouseKeepingData()
  const { data: houseKeeping, refetch: refetchHouseKeeping } = useGetHouseKeepingData(id, date);

  useEffect(() => {
    setCleaningTimes({})
    setNotes({})
    if (houseKeeping?.getHouseKeepingData) {
      setHouseKeepingData(houseKeeping?.getHouseKeepingData);
    } else if (id && date) {
      setHouseKeepingData(null);
      setCleaningTimes({});
      setNotes({});
    }
  }, [houseKeeping, id, date]);

  const dormRooms = roomsData?.Dormitory || [];
  const privateRooms = roomsData?.PrivateRoom || [];

  const dormsRoomNo = dormRooms.map((room) => room.roomNo)
  const privateRoomNo = privateRooms.map((room) => room.roomNo)

  const allRoomsNo = [...dormsRoomNo, ...privateRoomNo]

  const headerStyle = {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  };

  const timeArray = [
      { label: "Cleaning Time 1", key: "ct1" },
      { label: "Cleaning Time 2", key: "ct2" },
      { label: "Cleaning Time 3", key: "ct3" },
      { label: "Cleaning Time 4", key: "ct4" },
      { label: "Cleaning Time 5", key: "ct5" },
      { label: "Cleaning Time 6", key: "ct6" },
  ]

  const handleEditClick = (roomNo, title) => {
    setSelectedRoomNo(roomNo);
    setSelectedTitle(title);
    setIsModalOpen(true);
  };

  const formatTimestampToDate = (timestampString) => {
    const timestamp = Number(timestampString);
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0]; 
  };

  const getInitialTime = (hostelId, roomName, roomNo, cleaningTitle, date) => {
    if (!Array.isArray(houseKeepingData)) {
      return '';
    }
    const matchedData = houseKeepingData.find((room) => {
      const isHostelMatched = room.hostelId._id === hostelId;
      const isItemNameMatched = room.roomName === roomName;
      const isItemNoMatched = room.roomNo === roomNo;
      const isItemTitleMatched = room.cleaningTitle === cleaningTitle;
      
      const formattedDate = formatTimestampToDate(room.cleaningDate);
      const isDateMatched = formattedDate === date;
  
      return isHostelMatched && isItemNameMatched && isItemNoMatched && isItemTitleMatched && isDateMatched;
    });
    return matchedData ? matchedData.cleaningTime : '';
  };

  const handleTimeChange = (room, timeKey, value) => {
    setCleaningTimes((prev) => ({
      ...prev,
      [`${room.roomNo}-${timeKey}`]: value,
    }));
  };

  const handleSave = async () => {
    const allRooms = [...(dormRooms || []), ...(privateRooms || [])];

    if (!allRooms.length) {
      console.error("No item available to save.");
      return;
    }

    try {
      await refetchHouseKeeping();
  
      const saveData = allRooms.flatMap((room) => 
        timeArray.map((time) => {
          const timeKey = time.key;
          const cleaningTime = cleaningTimes[`${room.roomNo}-${timeKey}`] !== undefined
            ? cleaningTimes[`${room.roomNo}-${timeKey}`] 
            : getInitialTime(id, room.roomName, room.roomNo, time.label, date);
          const remark = notes[`${room.roomNo}-${time.label}`] || getInitialNotes(room.roomNo, time.label) || "";
  
          if (remark.trim() !== "" && !cleaningTime) {
            toaster("error", "Please enter time before adding a note.");
            return null;
          }
          if (!cleaningTime && !remark) return null;
  
          return {
            hostelId: id,
            roomName: room?.roomName,
            roomNo: room?.roomNo,
            cleaningDate: date,
            cleaningTitle: time.label,
            cleaningTime,
            remark,
          };
        })
      ).filter((data) => data !== null);
  
      if (saveData.length > 0) {
        await saveHouseKeepingDataHandler(saveData);
        toaster("success", "Housekeeping data saved successfully");
        await refetchHouseKeeping();
      } else {
        console.log("No valid data to save.");
      }

      setSavedTimes(prev => ({
        ...prev,
        ...Object.keys(cleaningTimes).reduce((acc, key) => {
          acc[key] = true;  
          return acc;
        }, {})
      }));
    } catch (error) {
      console.error("Error saving housekeeping data:", error);
      toaster("error", "Failed to save housekeeping data");
    }
  };

  const handleNotesChange = (e) => {
    const value = e.target.value;
    setNotes((prevData) => ({
      ...prevData,
      [`${selectedRoomNo}-${selectedTitle}`]: value
    }));
  };

  const handleNotesSave = () => {
    setNotes((prevNotes) => ({
      ...prevNotes,
      [`${selectedRoomNo}-${selectedTitle}`]: notes[`${selectedRoomNo}-${selectedTitle}`]
    }));
    setIsModalOpen(false);
  };

  const getInitialNotes = (roomNo, title) => {
    if (!Array.isArray(houseKeepingData)) return '';

    const matchedDataRemark = houseKeepingData.find((room) => {
      const isItemRoomNoMatched = room.roomNo === roomNo;
      const isItemTitleMatched = room.cleaningTitle === title;
      return isItemRoomNoMatched && isItemTitleMatched;
    });

    return matchedDataRemark ? matchedDataRemark.remark : '';
  };

  return (
    <Row>
      <Col>
        <Card  style={{marginBottom: "100px"}}>
          <CardHeader>
            <CardTitle>Room/Washroom cleaning tracker</CardTitle>

            {privateRooms?.length > 0 && (
              <tr>
                <td style={{ textAlign: "center", padding: "10px" }}>
                  {(() => {
                    const today = new Date();
                    const formatDate = (dateObj) => {
                      const year = dateObj.getFullYear();
                      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
                      const day = String(dateObj.getDate()).padStart(2, "0");
                      return `${year}-${month}-${day}`;
                    };

                    const todayLabel = formatDate(today);
                    const isDisabled = date !== todayLabel;

                    return (
                      <Button.Ripple
                        type="button"
                        color="success"
                        onClick={() => handleSave()}
                        style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                        disabled={isDisabled}
                      >
                        Save
                      </Button.Ripple>
                    );
                  })()}
                </td>
                <td>
                  <Button.Ripple
                    type="button"
                    onClick={() => {
                      setOpenLogs(true); 
                    }}
                    style={{bgColor: "grey"}}
                  >
                    Logs
                  </Button.Ripple>
                </td>
              </tr>
            )}
          </CardHeader>

        {id && roomsData && (
          <div>
            <Table size="sm" responsive hover>
              {dormRooms.length > 0 && (
                <thead>
                  <tr>
                      <th style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                        Dorm washroom
                    </th>

                    <th style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                        Room no
                    </th>
                    {timeArray.map((item, index) => (
                    <th key={index} style={headerStyle}>
                        {item.label}
                    </th>
                    ))}
                  </tr>
                </thead>
              )}

              <tbody>
                {dormRooms?.map((room, index) => (
                  <tr key={index}>
                    <td style={{ position: "sticky", zIndex: "2", fontSize: '12px', whiteSpace: 'nowrap' }}>{room.roomName}</td>
                    <td style={{ position: "sticky", zIndex: "2", fontSize: '12px', whiteSpace: 'nowrap'}}>{room.roomNo}</td>
                    {timeArray.map((time, index) => {
                      const today = new Date()
                      const formatDate = (dateObj) => {
                        const year = dateObj.getFullYear();
                        const month = String(dateObj.getMonth() + 1).padStart(2, "0"); 
                        const day = String(dateObj.getDate()).padStart(2, "0"); 
                        return `${year}-${month}-${day}`;
                      };

                      const todayLabel = formatDate(today);
                      const timeSaved = savedTimes[`${room.roomNo}-${time.key}`]||
                        getInitialTime(id, room.roomName, room.roomNo, time.label, date)
                      const isDisabled = date !== todayLabel || timeSaved ;
                      
                      return(
                      <td key={index} style={headerStyle}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", gap: "5px"}}>
                          <input
                            type="time"
                            id="time"
                            name="time"
                            title= {`${getInitialNotes(room.roomNo, time.label) === null ? " " : getInitialNotes(room.roomNo, time.label)}`}
                            value={
                              cleaningTimes[`${room.roomNo}-${time.key}`] ||
                              getInitialTime(id, room.roomName, room.roomNo, time.label, date) || ""
                            }
                            onChange={(e) => {
                              if(!isDisabled){
                                handleTimeChange(room, time.key, e.target.value)
                              }
                            }}
                            required
                            disabled={isDisabled}
                          />
                          <Edit2
                            color={getInitialNotes(room.roomNo, time.label) ? "red" : reactFeatherIcon.iconColor}
                            style={{ height: "12px", width: "12px", cursor: "pointer" }}
                            onClick={() => { 
                              // if(!isDisabled){
                                handleEditClick(room.roomNo, time.label) 
                            // }
                          }}
                          />
                        </div>
                      </td>
                      )
                    })}
                  </tr>
                ))}
              </tbody>

              {privateRooms.length > 0 && (
                <thead>
                  <tr>
                    <th style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                      Private room
                    </th>

                    {dormRooms.length === 0 && (
                      <>
                        <th style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                          Room no
                        </th>

                        {timeArray.map((item, index) => (
                          <th key={index} style={headerStyle}>
                            {item.label}
                          </th>
                        ))}
                      </>
                    )}
                  </tr>
                </thead>
              )}

              <tbody>
                {privateRooms?.map((room, index) => (
                  <tr key={index}>
                    <td style={{ position: "sticky", zIndex: "2", fontSize: '12px', whiteSpace: 'nowrap' }}>{room.roomName}</td>
                    <td style={{ position: "sticky", zIndex: "2", fontSize: '12px', whiteSpace: 'nowrap', }}>{room.roomNo}</td>
                    {timeArray.map((time, index) => {
                      const today = new Date()
                      const formatDate = (dateObj) => {
                        const year = dateObj.getFullYear();
                        const month = String(dateObj.getMonth() + 1).padStart(2, "0"); 
                        const day = String(dateObj.getDate()).padStart(2, "0"); 
                        return `${year}-${month}-${day}`;
                      };

                      const todayLabel = formatDate(today);
                      const timeSaved = savedTimes[`${room.roomNo}-${time.key}`]||
                      getInitialTime(id, room.roomName, room.roomNo, time.label, date)
                      const isDisabled = date !== todayLabel || timeSaved ;

                      return(
                        <td key={index} style={headerStyle}>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", gap: "5px"}}>
                            <input
                              type="time"
                              id="time"
                              name="time"
                              title= {`${getInitialNotes(room.roomNo, time.label) === null ? " " : getInitialNotes(room.roomNo, time.label)}`}
                              value={
                                cleaningTimes[`${room.roomNo}-${time.key}`] ||
                                getInitialTime(id, room.roomName, room.roomNo, time.label, date) || ""
                              }
                              onChange={(e) => {
                                if(!isDisabled){
                                  handleTimeChange(room, time.key, e.target.value)
                              }}}
                              required
                              disabled={isDisabled}
                            />
                            <Edit2
                              color={getInitialNotes(room.roomNo, time.label) ? "red" : reactFeatherIcon.iconColor}
                              style={{ height: "12px", width: "12px", cursor: "pointer" }}
                              onClick={() => { 
                                // if(!isDisabled){
                                  handleEditClick(room.roomNo, time.label)
                              // }
                            }}
                            />
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                ))}
              </tbody>

              <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} centered>
                <ModalHeader toggle={() => setIsModalOpen(false)}>{`${selectedRoomNo} ${selectedTitle} Notes`}</ModalHeader>
                <ModalBody>
                  <Input
                    type="textarea"
                    rows={7}
                    placeholder="Add a note..."
                    value={
                      notes[`${selectedRoomNo}-${selectedTitle}`] ?? getInitialNotes(selectedRoomNo, selectedTitle)
                    }
                    onChange={handleNotesChange}
                    style={{ margin: "20px 0" }}
                  />
                  <Button color="primary" 
                    onClick={handleNotesSave} 
                    style={{ margin: "20px 0" }}>
                    Save
                  </Button>
                </ModalBody>
              </Modal>
            </Table>
          </div>
        )}
        </Card>
      </Col>

      <LogsModal
        showModal={openLogs}
        data={houseKeepingData}
        date={date}
        onClose={setOpenLogs}
        allRoomsNo={allRoomsNo}
      />
    </Row>
  );
}

export default HouseKeepingTrackerTable



