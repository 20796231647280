import { Edit } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Hostel Name",
  "Vendor Name",
  "Mobile Number",
  "Address",
  "Bill Due Date",
  "Cycle",
  "Amount Per Dorm Night",
  "Amount Per Private Room Night",
];

const LaundryMasterTable = ({ openModal, setId, data, refetch, count }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2> Laundry Vendor Data - ({count} data)</h2>
              </div>
            </CardTitle>
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
                setId(null);
              }}
            >
              Add Laundry
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((item) => (
                  <tr key={uuidv4()}>
                    <td style={{ width: "10px" }}>
                      <Button
                        className="rounded-circle btn-icon"
                        color="none"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(item._id);
                          openModal();
                        }}
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                    <td>{item?.hostelId?.name}</td>
                    <td>{item?.laundryVendorName}</td>
                    <td>{item?.mobileNumber}</td>
                    <td>{item?.address}</td>
                    <td>{item?.billPaymentDueDay}</td>
                    <td>{item?.laundryCycle}</td>
                    <td>{item?.laundryAmountPerDorm}</td>
                    <td>{item?.laundryAmountPerPrivateRoom}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={tableHeaderData.length} style={{ textAlign: "center", fontSize: "30px", padding: "50px", fontWeight: "900" }}>
                    No data found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default LaundryMasterTable;
