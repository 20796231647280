import React, { useEffect, useState } from "react";
import { Edit, Image, Info } from "react-feather";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Row,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { formatCreatedAtDate } from "@utils";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "request ID",
  "Request date",
  "Hostel name",
  "Request head",
  "Request sub-head",
  "Sub-head Type",
  "Severity",
  "Total Amount",
  "Requested amount",
  "Due date",
  "Payment date",
  "Status",
  "Invoice",
  "PI/Estimate",
  "TAT (in hours)",
];

const GOEntryTable = ({
  openModal,
  setId,
  data,
  count,
  totalAmountSum,
  totalPaidSum,
  refetch,
}) => {
  const role = localStorage.getItem("role");

  const [tableData, setTableData] = useState(data);
  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const calculateHourDifference = (createdAt, paymentDate) => {
    const diffInMs = new Date(paymentDate) - new Date(createdAt);
    const diffInHours = diffInMs / (1000 * 60 * 60);
    return diffInHours.toFixed(2);
  };

  const calculateTAT = (createdAt, paymentDueTime, paymentDetails, status) => {
    const approvalTAT =
      createdAt && paymentDueTime
        ? calculateHourDifference(createdAt, paymentDueTime)
        : "-";
    const paymentTAT =
      paymentDueTime && paymentDetails?.length > 0 && status === "Payment Done"
        ? calculateHourDifference(
          paymentDueTime,
          paymentDetails[paymentDetails.length - 1].paymentDate
        )
        : "-";
    const totalTAT =
      approvalTAT !== "-" && paymentTAT !== "-"
        ? (parseFloat(approvalTAT) + parseFloat(paymentTAT)).toFixed(2)
        : "-";

    return { approvalTAT, paymentTAT, totalTAT };
  };

  const handleImagePopup = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      images.forEach((image) => {
        window.open(
          image.imageURL,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=200,width=600,height=400"
        );
      });
    } else if (typeof images === "string") {
      window.open(
        images,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=200,width=600,height=400"
      );
    }
  };

  const handlePOImagePopup = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      images.forEach((image) => {
        window.open(
          image.imageURL,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=200,width=600,height=400"
        );
      });
    } else if (typeof images === "string") {
      window.open(
        images,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=200,width=600,height=400"
      );
    }
  };

  const renderPaymentDetails = (paymentDetails, type) => {
    return paymentDetails.map((detail, index) => (
      <div key={index} style={{ whiteSpace: "nowrap" }}>
        {type === "amountToPay" && (
          <>
            {detail.paymentStatus === "Payment Due" && (
              <>
                {detail.amountToPay} {detail.paymentStatus}
              </>
            )}
            {detail.paymentStatus === "Payment Done" && (
              <>
                <>
                  {Number(detail.amountPaid) + Number(detail.tdsAmount)}{" "}
                  {detail.paymentStatus}
                </>
              </>
            )}
            {detail.paymentStatus === "Payment Rejected" && (
              <>
                {detail.amountToPay} {detail.paymentStatus}
              </>
            )}
          </>
        )}
        {type === "dueDate" && (
          <>{new Date(detail.dueDate).toLocaleDateString()}</>
        )}

        {type === "paymentDate" && (
          <>
            {detail.paymentStatus === "Payment Done" ? (
              <>{new Date(detail.paymentDate).toLocaleDateString()}</>
            ) : detail.paymentStatus === "Payment Rejected" ? (
              <>-</>
            ) : null}
          </>
        )}
      </div>
    ));
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center px-3">
            <div className="d-flex align-items-center">
              <CardTitle>
                OPEX Request{" "}
                {count ? (
                  <>
                    <span className="ms-2">({count} Requests)</span>
                    {" | "}
                    <span className="ms-2">
                      Total Amount (₹{totalAmountSum.toFixed(0)})
                    </span>
                    {" | "}
                    <span className="ms-2">
                      Paid (₹{totalPaidSum.toFixed(0)})
                    </span>
                  </>
                ) : (
                  <>
                    <span className="ms-2">(0 Requests)</span>
                    {" | "}
                    <span className="ms-2">Total Amount (₹0)</span>
                    {" | "}
                    <span className="ms-2">Paid (₹0)</span>
                  </>
                )}
              </CardTitle>
            </div>

            {role === "Ground Staff" && (
              <Button.Ripple
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              >
                Add Payment Request
              </Button.Ripple>
            )}
          </CardHeader>

          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.length > 0 ? (
                tableData.map((element) => {
                  const { approvalTAT, paymentTAT, totalTAT } = calculateTAT(
                    element?.createdAt,
                    element?.paymentDueTime,
                    element?.paymentDetails,
                    element?.status
                  );

                  return (
                    <tr key={uuidv4()}>
                      <td style={{ width: "5%" }}>
                        <Button
                          className="rounded-circle btn-icon"
                          color="none"
                          onClick={(e) => {
                            e.preventDefault();
                            setId(element._id);
                            openModal();
                          }}
                        >
                          <Edit
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                        </Button>
                      </td>
                      <td>
                        <Badge color={"light-info"}>{element?.requestID}</Badge>
                      </td>
                      <td>
                        {element?.createdAt
                          ? formatCreatedAtDate(element.createdAt)
                          : null}
                      </td>
                      <td>{element?.hostel?.name}</td>
                      <td>{element?.head}</td>
                      <td>{element?.subHead}</td>
                      <td>{element?.subFields?.subHeadType}</td>
                      <td>
                        <Badge
                          color={
                            element?.severity === "Low"
                              ? "success"
                              : element?.severity === "Medium"
                                ? "warning"
                                : "danger"
                          }
                        >
                          {element?.severity}
                        </Badge>
                      </td>
                      <td>{element?.totalAmount.toFixed(0)}</td>
                      <td>
                        {element?.paymentDetails &&
                          renderPaymentDetails(
                            element.paymentDetails,
                            "amountToPay"
                          )}
                      </td>
                      <td>
                        {element?.paymentDetails &&
                          renderPaymentDetails(
                            element.paymentDetails,
                            "dueDate"
                          )}
                      </td>
                      <td>
                        {element?.paymentDetails &&
                          renderPaymentDetails(
                            element.paymentDetails,
                            "paymentDate"
                          )}
                      </td>

                      <td>
                        <Badge
                          color={
                            element?.status?.includes("Approved") ||
                              element?.status?.includes("Done")
                              ? "success"
                              : element?.status?.includes("Rejected")
                                ? "danger"
                                : "warning"
                          }
                        >
                          {element?.status}
                        </Badge>
                      </td>

                      <td style={{ textAlign: "center" }}>
                        {element?.invoiceAttachment?.length > 0 ? (
                          <Image
                            size={24}
                            onClick={() =>
                              handleImagePopup(element.invoiceAttachment)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {element?.poAttachment?.length > 0 ? (
                          <Image
                            size={24}
                            onClick={() =>
                              handlePOImagePopup(element.poAttachment)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {approvalTAT !== "-" ||
                          paymentTAT !== "-" ||
                          totalTAT !== "-" ? (
                          <div>
                            {approvalTAT !== "-" && (
                              <>
                                <strong>Approval:</strong> {approvalTAT}{" "}
                              </>
                            )}
                            <br />
                            {element?.status === "Payment Done" &&
                              paymentTAT !== "-" && (
                                <>
                                  <strong>Payment:</strong> {paymentTAT}{" "}
                                </>
                              )}
                            <br />
                            {element?.status === "Payment Done" &&
                              totalTAT !== "-" && (
                                <>
                                  <strong>Total:</strong> {totalTAT}
                                </>
                              )}
                          </div>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan="15"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      padding: "50px 0",
                    }}
                  >
                    No requests to show.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default GOEntryTable;
