import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useQuery } from "@apollo/client";

export const GET_TRIPS_PACKAGES = gql`
  query getPaginatedTripList($filter: filter) {
    getPaginatedTripList(filter: $filter) {
      data {
        _id
        name
        displayName
        shortDesc
        description
        heroImage {
          url
          key
        }
        services {
          name
          icon
        }
        durationDays
        durationNights
        paxGroup
        minAge
        altitude
        level
        eventSlug
        amenities {
          _id
          name
          icon
          key
        }
        fitnessLevel
        location {
          region
          timeToVisit
          startPoint
          endPoint
        }
        taxInPercentage
        startPoint {
          name
          price
        }
        amount
        status
        overview {
          heading
          content
        }
        images {
          url
          key
          type
        }
        videos {
          url
          key
        }
        itinerary {
          heading
          content
          image {
            url
            key
            position
            metatext
          }
        }
        itineraryDetails {
          url
          key
        }
        otherInfo {
          heading
          icon
          key
          textlist
        }
        cancellationPolicy {
          heading
        }
        frequentQuestions {
          question
          answer
        }
        reviews {
          userName
          dateTime
          avatar
          content
        }
        tripMap
        rating
        totalReviews
        createdAt
      }
      count
      page
      limit
    }
  }
`;

export const GET_TRIP_BY_ID = gql`
  query getTrip($id: ID!) {
    getTrip(id: $id) {
      _id
      name
      displayName
      shortDesc
      description
      heroImage {
        url
        key
      }
      services {
        name
        icon
      }
      durationDays
      durationNights
      paxGroup
      minAge
      altitude
      level
      fitnessLevel
      location {
        region
        timeToVisit
        startPoint
        endPoint
      }
      taxInPercentage
      startPoint {
        name
        price
      }
      amount
      status
      overview {
        heading
        content
      }
      images {
        url
        key
        type
      }
      videos {
        url
        key
      }
      recommendations {
        _id
        name
      }
      itinerary {
        heading
        content
        image {
          url
          key
          position
          metatext
        }
      }
      itineraryDetails {
        url
        key
      }
      otherInfo {
        heading
        icon
        key
        textlist
      }
      cancellationPolicy {
        heading
      }
      frequentQuestions {
        question
        answer
      }
      reviews {
        userName
        dateTime
        avatar
        content
      }
      tripMap
      rating
      totalReviews
      createdAt
    }
  }
`;

export const GET_HOSTEL_PACKAGES = gql`
  query getPaginatedHostelList($filter: filter) {
    getPaginatedHostelList(filter: $filter) {
      data {
        _id
        name
        email
        phone
        amenities {
          amenity {
            _id
            name
            icon
            key
          }
          status
        }
        locationUrl
        reviewUrl
        status
        ezeeConfig {
          hotelCode
          authCode
        }
        reviewUrlList {
          one
          two
        }
        isWorkation
        address {
          addressLine1
          addressLine2
          city
          state
          country
          pincode
        }
        location {
          latitude
          longitude
        }
        brand
        createdAt
        shortName
        reviewsLinks {
          booking
          tripAdvisor
          hostelWorld
          goibibo
          mmt
          google
          bookingAvgRatingLink
          tripAdvisorAvgRatingLink
          hostelWorldAvgRatingLink
          goibiboAvgRatingLink
          mmtAvgRatingLink
          googleAvgRatingLink
          bookingUfiCode
          bookingHotelCode
          agodaReviewLink
          agodaRatingLink
        }
        RegistrationDetails
        seo {
          metaTitle
          metaDesc
          keywords
        }
        businessType
        activateGlu
        isWorkation
        coLiving
      }
      count
      page
      limit
    }
  }
`;

export const GET_WORKATION_PACKAGES = gql`
  query getAllWorkations($filter: filter) {
    getAllWorkations(filter: $filter) {
      data {
        _id
        discountPercentage
        room {
          roomName
          roomId
        }
        baseAmount
        property {
          name
          id
        }
        workationFaqs {
          question
          answer
        }
        totalAmount
        minStay
        maxStay
        status
        createdAt
      }
      count
      page
      limit
    }
  }
`;

export const GET_RECOMMENDATION_BY_ID = gql`
  query getRecommendationByProductTypeAndId(
    $id: ID
    $productType: productTypeEnum
  ) {
    getRecommendationByProductTypeAndId(id: $id, productType: $productType) {
      _id
      name
      shortDesc
      duration
      paxNumber
      price
      type
      hostelId
      tripId
      workationId
      productType
      tag
      image {
        url
        key
      }
    }
  }
`;

export const GET_RECOMMENDATION_BY_PRODUCT_TYPE = gql`
  query getRecommendationByProductType($productType: productTypeEnum) {
    getRecommendationByProductType(productType: $productType) {
      _id
      name
    }
  }
`;

export const GET_LANDINGPAGE_DETAILS = gql`
  query getLandingPageDetails($slug: SlugEnum!) {
    getLandingPageDetails(slug: $slug) {
      _id
      heroImage {
        link
        type
        key
        url
      }
      mainSection {
        type
        title
        heading
        description
        recommendations {
          _id
          title
          cards {
            _id
            name
            shortDesc
            image {
              key
              url
            }
            duration
            paxNumber
            price
            type
            hostelId
            tripId
            productType
            genericText
          }
        }
      }
    }
  }
`;

export const GET_AMENITIES = gql`
  query getAllAminities($filter: filter) {
    getAllAminities(filter: $filter) {
      data {
        _id
        name
        icon
        key
        logs
      }
      count
      page
      limit
    }
  }
`;

export const GET_HOSTEL_BY_ID = gql`
  query getHostelById($hostelId: ID) {
    getHostelById(hostelId: $hostelId) {
      _id
      name
      shortDesc
      heroImage {
        image
        key
      }
      reviewUrlList {
        one
        two
      }
      ezeeConfig {
        hotelCode
        authCode
      }
      hostelFaqs {
        question
        answer
      }
      workationFaqs {
        question
        answer
      }
      roomsBasePrice
      dormsBasePrice
      totalRooms
      totalDorms
      description {
        heading
        content
      }
      otherInfo {
        heading
        content
        avatarIcon
        key
      }
      aminities {
        name
        icon
        key
      }
      images {
        image
        key
        type
        imageCaption
        displayOrder
        imageType
      }
      policy {
        bookingPolicy
        hostelPolicy
        cancellationPolicy
      }
      hostelPolicy {
        general
        pet
        covid
      }
      recommendations {
        _id
        name
      }
      workationRecommendations {
        _id
        name
      }
      tripRecommendations {
        _id
        name
      }
      phone
      email
      location {
        latitude
        longitude
      }
      locationUrl
      reviewUrl
      deleted
      status
      reviews {
        userName
        dateTime
        avatar
        content
        userType
      }
      rating
      totalReviews
      thingsToKnow {
        heading
        textlist
      }
      howToReach {
        heading
        textlist
      }
      address {
        addressLine1
        addressLine2
        city
        state
        country
        pincode
      }
      cityInfo
      hostelDetail
      propertyManager
      areaManager
      createdAt
      imagePortraitCard
      imageLandscapeCard
      state
      interest
      tagline
      timingInformation {
        checkin
        checkout
        guestVisit
        cafe
        reception
        other
        silentHours
        breakfast
        lunch
        dinner
        pool
        hotWater
        chatSupport
      }
      reviewsCategory {
        category
        rating
      }
      view360DesktopUrl
      view360MobileUrl
      videoUrl
      foodOrderUrl
      gmapUrl
      shortName
      url
      seo {
        metaTitle
        metaDesc
        keywords
      }
      similarHostels {
        _id
        name
      }
      blogs {
        _id
        blogTitle
      }
      stateCode
      gstNumber
      gluAnnouncement
      isWorkation
      smartLockId
      wifiPassword
      activateGlu
      amenities {
        amenity {
          _id
          name
          icon
          key
        }
        status
      }
      ezeeConfig {
        hotelCode
        authCode
      }
      reviewUrlList {
        one
        two
      }
      address {
        addressLine1
        addressLine2
        city
        state
        country
        pincode
      }
      location {
        latitude
        longitude
      }
      brand
      reviewsLinks {
        booking
        tripAdvisor
        hostelWorld
        goibibo
        mmt
        google
        bookingAvgRatingLink
        tripAdvisorAvgRatingLink
        hostelWorldAvgRatingLink
        goibiboAvgRatingLink
        mmtAvgRatingLink
        googleAvgRatingLink
        bookingUfiCode
        bookingHotelCode
      }
      RegistrationDetails
      seo {
        metaTitle
        metaDesc
        keywords
      }
      businessType
      potraitImageArray {
        key
        image
        displayOrder
        type
      }
      coLiving
    }
  }
`;

export const GET_ALL_ROOMTYPE = gql`
  query getAllRooms($hostelId: ID!) {
    getAllRooms(hostelId: $hostelId) {
      Name
      RoomTypeID
    }
  }
`;

// ! getPaginatedDestinationList added by Ashwin
export const GET_DESTINATION_PACKAGES = gql`
  query getPaginatedDestinationList($filter: filter) {
    getPaginatedDestinationList(filter: $filter) {
      data {
        _id
        destination
        destinationType
        state
        description
        interest
        status
        createdAt
        url
        seo {
          metaTitle
          metaDesc
          keywords
        }
      }
      count
      page
      limit
    }
  }
`;

// ! getDestinationById added by Ashwin
export const GET_DESTINATION_BY_ID = gql`
  query getDestinationById($destinationId: ID) {
    getDestinationById(destinationId: $destinationId) {
      _id
      destination
      destinationType
      state
      description
      interest
      status
      hostels {
        _id
        name
      }
      nearByHostel {
        _id
        name
      }
      blogs {
        _id
        blogTitle
      }
      landscapeImage
      portraitImage
      images {
        image
        key
        type
        imageCaption
        displayOrder
        imageType
      }
      videoUrl
      url
      seo {
        metaTitle
        metaDesc
        keywords
      }
      famousPlaces {
        image
        imageTitle
        displayOrder
      }
      locationUrl
      howToReach {
        howToReachTitle
        howToReachDesc
      }
      altitude
      population
      itinerary {
        time
        itineraryImage
        title
        description
        displayOrder
      }
      monthWiseTemperature {
        monthNo
        monthName
        averageTemperature
      }
      thingsTodo {
        categoryTitle
        categoryImage
        displayOrder
      }
      eventsToDo {
        thingsTodoCategory
        title
        description
        images
      }
      createdAt
    }
  }
`;

// ! getHomePageDetails added by Ashwin
export const GET_HOMEPAGE_DETAILS = gql`
  query getHomePageDetails($id: ID) {
    getHomePageDetails(id: $id) {
      _id
      slug
      images {
        link
        type
        key
        image
        displayOrder
      }
      hostels {
        _id
        name
      }
      destinations {
        _id
        destination
      }
      workations {
        _id
        name
      }
      blogs {
        _id
        blogTitle
      }
      travellerGallery {
        type
        key
        userName
        image
      }
      events {
        _id
        title
        description
        eventColor
        eventDate
        image
        hostel {
          _id
          name
        }
      }
      seo {
        metaTitle
        metaDesc
        keywords
      }
      newDestinations {
        destination {
          _id
          destination
        }
        icon
        displayOrder
      }
      newImages {
        link
        type
        key
        image
        displayOrder
        deviceType
      }
      newTagline {
        name
        icon
      }
      logs
    }
  }
`;

// ! getWorkationLandingPageDetails added by Ashwin
export const GET_WORKATION_LANDINGPAGE_DETAILS = gql`
  query getWorkationLandingPageDetails($slug: String) {
    getWorkationLandingPageDetails(slug: $slug) {
      _id
      workation1 {
        location
        hostels {
          _id
          name
        }
      }
      workation2 {
        location
        hostels {
          _id
          name
        }
      }
      workation3 {
        location
        hostels {
          _id
          name
        }
      }
      workation4 {
        location
        hostels {
          _id
          name
        }
      }
      workation5 {
        location
        hostels {
          _id
          name
        }
      }
      images {
        type
        key
        image
        imageCaption
        displayOrder
        imageType
      }
      reviews {
        userName
        content
        userType
        avatar
      }
      faqs {
        question
        answer
      }
      workationOfWeek {
        title
        description
        hostel {
          _id
          name
        }
      }
      logs
    }
  }
`;

export const GET_ALL_TRAVEL_PACKAGES = gql`
  query getAllTravelPackages($filter: filter) {
    getAllTravelPackages(filter: $filter) {
      data {
        _id
        memberShipType
        hostelId
        roomId
        roomName
        checkInDate
        checkOutDate
        meal
        isActive
        logs
        createdAt
      }
      count
      page
      limit
    }
  }
`;

export const GET_PAGINATED_HOMEPAGE_LIST = gql`
  query getPaginatedHomePageList($filter: filter) {
    getPaginatedHomePageList(filter: $filter) {
      data {
        _id
        slug
        images {
          link
          type
          key
          image
          displayOrder
        }
        hostels {
          _id
          name
        }
        destinations {
          _id
          destination
        }
        workations {
          _id
          name
        }
        blogs {
          _id
          blogTitle
        }
        travellerGallery {
          type
          key
          userName
          image
        }
        events {
          _id
          title
          description
          eventColor
          eventDate
          image
          hostel {
            _id
            name
          }
        }
        seo {
          metaTitle
          metaDesc
          keywords
          metaImage
        }
      }
      count
      page
      limit
    }
  }
`;

export const GET_HOSTEL_LOGS_BY_ID = gql`
  query getHostelLogsById($hostelId: ID) {
    getHostelLogsById(hostelId: $hostelId) {
      _id
      name
      logs
    }
  }
`;

export const useGetHostelLogsById = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_HOSTEL_LOGS_BY_ID, {
    variables: {
      hostelId: id,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_DESTINATION_LOGS_BY_ID = gql`
  query getDestinationLogsById($destinationId: ID) {
    getDestinationLogsById(destinationId: $destinationId) {
      _id
      destination
      logs
    }
  }
`;

export const useGetDestinationLogsById = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_DESTINATION_LOGS_BY_ID,
    {
      variables: {
        destinationId: id,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
