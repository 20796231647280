// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import {
  useUpdateDestination,
  useGetDestinationById,
} from "../../../../api/dataSource";
import { Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import ImageSection from "./ImageSection";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const UploadType = [
  {
    value: "general",
    label: "General",
  },
];

const PhotosForm = () => {
  const { id } = useParams();

  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);

  const [updateDestinationHandler, { data: updatedDestinationData }] =
    useUpdateDestination();

  const dispatch = useDispatch();

  useEffect(() => {
    if (imageList.length === 0) {
      state?.tableData?.images?.length &&
        setImageList(state?.tableData?.images);
    }
  }, [imageList.length, state?.tableData?.images]);

  useEffect(() => {
    let arrProcessedImages = [];
    imageList.forEach((i) => {
      let imageObj = {
        displayOrder: i.displayOrder,
        image: i.image,
        imageCaption: i.imageCaption,
        imageType: i.imageType,
        key: i.key,
        type: i.type,
      };
      arrProcessedImages.push(imageObj);
    });

    setSortedImages(
      arrProcessedImages.sort((a, b) => a.displayOrder - b.displayOrder)
    );
  }, [imageList]);

  useEffect(() => {
    if (updatedDestinationData?.updateDestination?.destination) {
      toaster("success", "Photos updated successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          images: imageList,
        })
      );

      // updateDestinationReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updatedDestinationData?.updateDestination?.destination]);
  const { data, refetch } = useGetDestinationById(id);
  useEffect(() => {
    if (data?.getDestinationById?.images?.length > 0) {
      setImageList(data?.getDestinationById?.images);
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const images = imageList.map((image) => ({
      type: image.type,
      image: image.image,
      key: image.key,
      imageCaption: image?.imageCaption,
      displayOrder: image?.displayOrder,
      imageType: image?.imageType,
    }));

    if (images?.length > 0) {
      await updateDestinationHandler(id, {
        images,
        sectionName: "Photos",
      });
    } else {
      toaster("error", "Please select and upload photo");
    }
  };

  useEffect(() => {
    if (updatedDestinationData) {
      refetch();
    }
  }, [updatedDestinationData]);
  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <ImageSection
            imageList={sortedImages}
            setImageList={setImageList}
            uploadType={UploadType}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default PhotosForm;
