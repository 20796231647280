import React, { useEffect, useState } from "react";
import HourlySalesChart from "./HourlySalesChart";
import DaySalesChart from  './DaySalesChart'
import EzzeHourlySalesChart from './EzzeHourlySalesChart'
import EzzeDaySalesChart from './EzzeDaySalesChart'
import { useWebsiteHourlySalesReport, useWebsiteDaySalesReport, useHostelList, useEzzeBookingHourlySalesReport, useEzzeBookingDaySalesReport } from "../../../api/dataSource";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import { CSVLink } from "react-csv";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { dateValidator } from "../../../utility/Utils";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import { selectThemeColors } from "@utils";

// const tableHeaderData = [
//   { label: "id", key: "hotelCenterId" },
//   { label: "name", key: "name" },
//   { label: "addr1", key: "addr1"},
//   { label: "addr2", key: "addr2"},
//   { label: "city", key: "city"},
//   { label: "province", key: "province" },
//   { label: "postal_code", key: "postal_code" },
//   { label: "country", key: "country" },
//   { label: "latitude", key: "latitude" },
//   { label: "longitude" ,key: "longitude" },
//   { label: "category", key: "category" },
//   { label: "hotel_brand", key: "hotel_brand" },
//   { label: "phone", key: "phone" },
//   { label: "star_rating", key: "star_rating"},
//   {label: "alternate_hotel_id", key: "alternate_hotel_id"}
// ];

const HourlySales = () => {
  const [hostel, setHostel] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const [hostelIds, setHostelIds] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [dayTableData, setDayTableData] = useState([]);
  const [fromDate, setFromDate] = useState(dateFormatter(new Date()));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [searchFromDate, setSearchFromDate] = useState(null);
  const [searchToDate, setSearchToDate] = useState(null);
  const [ezzeHourlyTableData, setEzzeHourlyTableData] = useState([]);
  const [ezzeDaySalesTableData, setEzzeDaySalesTableData] = useState([]);
  const { data: hourlyData, refetch: hourlyRefetch } = useWebsiteHourlySalesReport(hostelIds, searchFromDate, searchToDate);
  const { data: daySalesData, refetch: daySalesRefetch } = useWebsiteDaySalesReport(hostelIds, searchFromDate, searchToDate);
  const { data: ezzeHourlyData, refetch: ezzeHourlyRefetch } = useEzzeBookingHourlySalesReport(hostelIds, searchFromDate, searchToDate);
  const { data: ezzeDaySalesData, refetch: ezzeDaySalesRefetch } = useEzzeBookingDaySalesReport(hostelIds, searchFromDate, searchToDate);
  const { data: allHostelsData } = useHostelList();

  useEffect(() => {
    if (hourlyData?.websiteHourlySalesReport) {
      setTableData(hourlyData.websiteHourlySalesReport);
    } else {
      setTableData([]);
    }
  }, [hourlyData]);

  useEffect(() => {
    if (daySalesData?.websiteDaySalesReport) {
      setDayTableData(daySalesData.websiteDaySalesReport);
    } else {
      setDayTableData([]);
    }
  }, [daySalesData]);

  useEffect(() => {
    if (ezzeHourlyData?.ezzeBookingHourlySalesReport) {
      setEzzeHourlyTableData(ezzeHourlyData.ezzeBookingHourlySalesReport);
    } else {
      setEzzeHourlyTableData([]);
    }
  }, [ezzeHourlyData]);

  useEffect(() => {
    if (ezzeDaySalesData?.ezzeBookingDaySalesReport) {
      setEzzeDaySalesTableData(ezzeDaySalesData.ezzeBookingDaySalesReport);
    } else {
      setEzzeDaySalesTableData([]);
    }
  }, [ezzeDaySalesData]);

   useEffect(() => {
      let HostelsList = [];
      if (allHostelsData?.getHostelList?.length) {
        allHostelsData?.getHostelList?.map((item) => {
          HostelsList.push({ label: item?.name, value: item?._id });
          setHostelsList(HostelsList);
        });
      }
    }, [allHostelsData?.getHostelList]);

  const submitHandler = () => {
    const selectedHostelIds = hostel?.length > 0 ? hostel.map((h) => h.value) : null;
  
    if (dateValidator(fromDate, toDate)) {
      setHostelIds(selectedHostelIds)
      setSearchFromDate(fromDate);
      setSearchToDate(toDate);
    } else {
      toaster("error", "Invalid Dates");
    }
  };

  const handleResetData = ()=>{
    setHostel(null);
    setTableData(null)
    setDayTableData(null)
    setEzzeHourlyTableData(null)
    setEzzeDaySalesTableData(null)
    setSearchFromDate(dateFormatter(new Date()))
    setSearchToDate(dateFormatter(new Date()))
    setFromDate(dateFormatter(new Date()))
    setToDate(dateFormatter(new Date()))
  }

  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        {/* <Row> */}
        {/* <Col sm="2">
          <CSVLink
            headers={tableHeaderData}
            data={tableData}
            filename={"Google_Hotel_Center_hotelList.csv"}
          >
            <Button.Ripple
              className="btn-icon mb-2 ml-1"
              color="light"
              type="button"
              size="sm"
            >
              Export
            </Button.Ripple>
          </CSVLink>
        </Col> */}
        {/* </Row> */}
        <Row style={{marginBottom:"40px"}}>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                From Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
              />
            </FormGroup>
          </Col>

          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                To Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="fromDate"
              />
            </FormGroup>
          </Col>
          <Col sm="4" style={{marginTop:"22px"}}>
          <Button.Ripple
                onClick={submitHandler}
                className="ml-2 w-25"
                color="primary"
              >
                Search
              </Button.Ripple>

              <Button.Ripple
                onClick={handleResetData}
                className="ml-2 w-25"
                color="danger"
              >
                Reset
              </Button.Ripple>
          </Col>
        </Row>
        {tableData?.length > 0 ? (
          <HourlySalesChart data={tableData} refetch={hourlyRefetch} />
        ) : (
          <h2 style={{ marginTop: "150px", textAlign: "center" }}>No Record Found</h2>
        )}

        {dayTableData?.length > 0 ? (
          <DaySalesChart data={dayTableData} refetch={daySalesRefetch} />
        ) : (
          <h2 style={{ marginTop: "150px", textAlign: "center" }}>No Record Found</h2>
        )} 

        {ezzeHourlyTableData?.length > 0 ? (
          <EzzeHourlySalesChart data={ezzeHourlyTableData} refetch={ezzeHourlyRefetch} />
        ) : (
          <h2 style={{ marginTop: "150px", textAlign: "center" }}>No Record Found</h2>
        )}

        {ezzeDaySalesTableData?.length > 0 ? (
          <EzzeDaySalesChart data={ezzeDaySalesTableData} refetch={ezzeDaySalesRefetch} />
        ) : (
          <h2 style={{ marginTop: "150px", textAlign: "center" }}>No Record Found</h2>
        )}
      </div>
    )
  );
};

export default HourlySales;