import React, { useEffect, useState } from "react";
import { Edit, Link } from "react-feather";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeader = [
  "Action",
  "Hostel",
  "Provider Name",
  "Entity Name",
  "Meter Number",
  "Sanctioned Load",
  "Per Unit Rate",
  "Multiplied Factor",
  "Billing Start Date",
  "Billing End Date",
  "Bill Due Date",
  "Mobile Number",
  "Email",
  "Avg. Units/Room per day",
];
const MeterMasterTable = ({ openModal, data, setId }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);
  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>Meter Master</CardTitle>

              <Button.Ripple
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              >
                Add Meter
              </Button.Ripple>
            </CardHeader>
            {tableData?.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    {tableHeader?.map((item) => {
                      return <th key={uuidv4()}>{item}</th>;
                    })}
                  </tr>
                </thead>

                <tbody>
                  {tableData?.map((element, i) => (
                    <tr key={uuidv4()}>
                      <td style={{ width: "5%" }}>
                        <Button
                          className="rounded-circle btn-icon"
                          color="none"
                          onClick={(e) => {
                            e.preventDefault();
                            setId(element?._id);
                            openModal();
                          }}
                        >
                          <Edit
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                        </Button>
                      </td>
                      <td>{element?.hostel?.name}</td>
                      <td>{element?.electricityProviderName}</td>
                      <td>{element?.entityName}</td>
                      <td>{element?.meter_CA_Number}</td>
                      <td>{element?.sanctionedLoad}</td>
                      <td>{element?.perUnitRate}</td>
                      <td>{element?.multipliedFactor}</td>
                      <td>{element?.billingPeriodStartDate}</td>
                      <td>{element?.billingPeriodEndDate}</td>
                      <td>{element?.billPaymentDueDate}</td>
                      <td>{element?.mobileNumber}</td>
                      <td>{element?.email}</td>
                      <td>{element?.avgUnitPerRoomPerDay}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <h2>No Record Found</h2>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MeterMasterTable;
