import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_PL_STATEMENT = gql`
  mutation createPLStatement($plInput: PLStatementInput!) {
    createPLStatement(plInput: $plInput) {
      _id
      month
      year
      hostelName
      hostelCode
      operationalModel
      areaManager
      overallRemarks
      remarksOnOps
      remarksOnCX
      remarksOnPosSales
      actionsFollowups
      fields {
        fieldName
        fieldValue
        isCritical
        isProvisional
        remark
        breakup
      }
      createdAt
      updatedAt
    }
  }
`;

export const useCreatePLStatement = () => {
  const [createPLStatement, { loading, error, data, refetch }] =
    useMutation(CREATE_PL_STATEMENT);

  useLoadingHandler(loading);

  const createPLStatementHandler = async ({ plInput }) => {
    try {
      const response = await createPLStatement({
        variables: { plInput },
      });
      return response;
    } catch (err) {
      console.error("Error creating P&L Statement:", err);
      throw err;
    }
  };

  return [createPLStatementHandler, { loading, error, data, refetch }];
};

const UPDATE_PL_STATEMENT = gql`
  mutation updatePLStatement($id: ID!, $plInput: PLStatementInput!) {
    updatePLStatement(id: $id, plInput: $plInput) {
      _id
      month
      year
      hostelId
      hostelName
      hostelCode
      operationalModel
      areaManager
      overallRemarks
      remarksOnOps
      remarksOnCX
      remarksOnPosSales
      actionsFollowups
      fields {
        fieldName
        fieldValue
        isCritical
        isProvisional
        remark
        breakup
      }
      createdAt
      updatedAt
    }
  }
`;

export const useUpdatePLStatement = () => {
  const [updatePLStatement, { data, error, loading }] =
    useMutation(UPDATE_PL_STATEMENT);

  return [
    async ({ id, plInput }) => {
      const response = await updatePLStatement({
        variables: { id, plInput },
      });

      if (response.data && response.data.updatePLStatement) {
        return response;
      } else {
        throw new Error("Update failed: Response is missing data.");
      }
    },
    { data, error, loading },
  ];
};

export const UPLOAD_PL_STATEMENTS = gql`
  mutation uploadPLStatements($file: Upload!, $month: Int!, $year: Int!) {
    uploadPLStatements(file: $file, month: $month, year: $year) {
      success
      message
    }
  }
`;

export const useUploadPLStatements = () => {
  const [uploadPLStatements, { loading, error, data, refetch }] =
    useMutation(UPLOAD_PL_STATEMENTS);

  useLoadingHandler(loading);

  const uploadPLStatementsHandler = ({ file, month, year }) => {
    uploadPLStatements({
      variables: {
        file,
        month,
        year,
      },
    });
  };

  return [uploadPLStatementsHandler, { data, refetch, loading, error }];
};

//Investor data
export const UPLOAD_INVESTOR_DATA = gql`
  mutation uploadInvestorData($file: Upload!) {
    uploadInvestorData(file: $file) {
      success
      message
    }
  }
`;

export const useUploadInvestorData = () => {
  const [uploadInvestorData, { loading, error, data, refetch }] =
    useMutation(UPLOAD_INVESTOR_DATA);

  useLoadingHandler(loading);

  const uploadInvestorDataHandler = ({ file }) => {
    uploadInvestorData({
      variables: {
        file,
      },
    });
  };

  return [uploadInvestorDataHandler, { data, refetch, loading, error }];
};

export const EXPORT_PL_STATEMENT = gql`
  mutation exportPLStatement($filter: PLStatementFilter) {
    exportPLStatement(filter: $filter)
  }
`;

export const useExportPLStatement = () => {
  const [exportPLStatement, { loading, error, data, refetch }] =
    useMutation(EXPORT_PL_STATEMENT);
  useLoadingHandler(loading);

  const exportPLStatementHandler = (filter) => {
    // Default the filter to an empty object if it is not provided
    const filterToUse = filter || {};

    exportPLStatement({
      variables: {
        filter: filterToUse,
      },
    });
  };

  return [exportPLStatementHandler, { data, refetch, loading, error }];
};
