import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Label,
    Row,
    Table,
} from "reactstrap";
import Select from "react-select";
import { useGetBrandLevelAnalytics, useGetBrandlevelAvgRating } from '../../api/ORMComparativeRatings/Queries';

const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
];

const yearOptions = [
    { value: 2023, label: "2023" },
    { value: 2024, label: "2024" },
    { value: 2025, label: "2025" },
];
const ORMBrandLevelRating = () => {
    const [month, setMonth] = useState([])
    const [year, setYear] = useState([])
    const [monthArray, setMonthArray] = useState([])
    const [yearArray, setYearArray] = useState([])
    const [brandLevelRating, setBrandLevelRating] = useState([])
    const [newYearArray, setNewYearArray] = useState([])
    const [newMonthArray, setNewMonthArry] = useState([])

    const { data, fetchReport } = useGetBrandLevelAnalytics(newYearArray, newMonthArray)
    useEffect(() => {
        const months = month?.map((mon) => mon.value);
        setMonthArray(months);

        const yearList = year?.map((yr) => yr.value);
        setYearArray(yearList);
    }, [month, year]);

    const handleSearch = () => {
        fetchReport({
            yearArray,
            monthArray
        });
        setNewMonthArry(monthArray)
        setNewYearArray(yearArray)
    }

    useEffect(() => {
        if (data?.brandLevelAnalytics?.length) {
            setBrandLevelRating(data?.brandLevelAnalytics);
        }
    }, [data]);


    const generateHeaderLabels = () => {
        const headers = [];
        yearArray.forEach((year) => {
            monthArray.forEach((month, index) => {
                const label = `${monthOptions.find(m => m.value === month)?.label?.toLowerCase()}, ${year.toString()}`;
                headers.push(label);
            });
        });

        return headers;
    };



    const handleReset = () => {
        setMonth([])
        setNewMonthArry([])
        setYear([])
        setNewYearArray([])
        setMonthArray([])
        setYearArray([])
    }

    const setSortedMonths = (option) => {
        const sortedmonth = option.sort((a, b) => a.value - b.value)
        setMonth(sortedmonth)
    }

    const setSortedYear = (option) => {
        const sortedYear = option.sort((a, b) => a.value - b.value)
        setYear(sortedYear)
    }

    return (
        <Card>
            <CardHeader>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <CardTitle>Brand level analytics</CardTitle>
                </div>
            </CardHeader>
            <Row form style={{ padding: "16px", gap: "16px" }}>
                <Col md={3}>
                    <FormGroup>
                        <Label for="fromDate">Month</Label>
                        <div style={{ display: "flex", gap: "8px" }}>
                            <Select
                                id="month"
                                options={monthOptions}
                                value={month}
                                onChange={(option) => setSortedMonths(option)}
                                isMulti
                                closeMenuOnSelect={false}
                                isSearchable={false}
                                placeholder="Month"
                                styles={{ container: (base) => ({ ...base, flex: 1 }) }}
                            />
                        </div>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="fromDate">Year</Label>
                        <div style={{ display: "flex", gap: "8px" }}>
                            <Select
                                id="year"
                                options={yearOptions}
                                value={year}
                                onChange={(option) => setSortedYear(option)}
                                isMulti
                                closeMenuOnSelect={false}
                                isSearchable={false}
                                placeholder="Year"
                                styles={{ container: (base) => ({ ...base, flex: 1 }) }}
                            />
                        </div>
                    </FormGroup>
                </Col>
                <Col>
                    <Button
                        style={{
                            backgroundColor: "#ffe700",
                            height: "40px",
                            marginTop: "22px",
                            borderRadius: "5px",
                            marginRight: "16px",
                            outline: "none",
                            border: "0",
                        }}
                        color="white"
                        appearance="primary"
                        type="button"
                        onClick={handleSearch}
                    >
                        Search
                    </Button>

                    <Button
                        style={{
                            backgroundColor: "#ff9999",
                            height: "40px",
                            marginTop: "22px",
                            marginRight: "16px",
                            borderRadius: "5px",
                            outline: "none",
                            border: "0",
                        }}
                        color="danger"
                        appearance="primary"
                        type="button"
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                </Col>
            </Row>
            <CardBody>
                {
                    brandLevelRating.length > 0 && <Table hover responsive>
                        <thead>
                            <tr>
                                <th>Criteria</th>
                                {generateHeaderLabels().map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {brandLevelRating?.map((rating, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{rating.field}</td>
                                        {generateHeaderLabels().map((header, index) => {

                                            const [monthLabel, yearLabel] = header.split(', ');
                                            const monthIndex = monthOptions.findIndex(m => m.label.toLowerCase() === monthLabel.toLowerCase());
                                            const yearIndex = yearArray.indexOf(parseInt(yearLabel));
                                            const currentMonth = monthOptions[monthIndex].value;
                                            const currentYear = yearArray[yearIndex];
                                            const rate = rating.data.find(
                                                (rate) => rate.month === currentMonth && rate.year === currentYear
                                            );

                                            return (
                                                <td key={index}>
                                                    {rate
                                                        ? (rating.field === "Total Reviews"
                                                            ? rate.value
                                                            : ["Review Rate", "Positive Rate", "Negative Rate", "Neutral Rate"].includes(rating.field)
                                                                ? `${rate.value.toFixed(2)}%`
                                                                : rate.value.toFixed(2))
                                                        : '--'}
                                                </td>

                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>



                    </Table>
                }

            </CardBody>
        </Card>
    )
}

export default ORMBrandLevelRating