import React from "react";
import { useSelector } from "react-redux";

const LogsDetails = ({ setRemainingCount }) => {
  setRemainingCount("")
  const state = useSelector((state) => state?.cms);

  const logs = state?.tableData?.logs;
  const lessorHandoverDateLogs = state?.tableData?.lessorHandoverDateLogs;
  const goLiveDateLogs = state?.tableData?.goLiveDateLogs;
  const rentStartDateLogs = state?.tableData?.rentStartDateLogs;
  const rentEndDateLogs = state?.tableData?.rentEndDateLogs;

  return (
    <>
      {/* {lessorHandoverDateLogs?.length > 0 && (
        <>
          <p>
            {" "}
            <strong> Lessor handover date logs</strong>
          </p>

          <ul>
            {lessorHandoverDateLogs
              .map((log, index) => <li key={index}>{log}</li>)
              .reverse()}
          </ul>
        </>
      )}
      {goLiveDateLogs?.length > 0 && (
        <>
          <p>
            {" "}
            <strong> Go live date logs</strong>
          </p>

          <ul>
            {goLiveDateLogs
              .map((log, index) => <li key={index}>{log}</li>)
              .reverse()}
          </ul>
        </>
      )}
      {rentStartDateLogs?.length > 0 && (
        <>
          <p>
            {" "}
            <strong> Rent start date logs</strong>
          </p>

          <ul>
            {rentStartDateLogs
              .map((log, index) => <li key={index}>{log}</li>)
              .reverse()}
          </ul>
        </>
      )}
      {rentEndDateLogs?.length > 0 && (
        <>
          <p>
            {" "}
            <strong> Rent end date logs</strong>
          </p>

          <ul>
            {rentEndDateLogs
              .map((log, index) => <li key={index}>{log}</li>)
              .reverse()}
          </ul>
        </>
      )} */}
      {logs?.length > 0 && (
        <>
          {/* <p>
            {" "}
            <strong> Logs</strong>
          </p> */}

          <ul>
            {logs.map((log, index) => <li key={index}>{log}</li>).reverse()}
          </ul>
        </>
      )}
    </>
  );
};

export default LogsDetails;
