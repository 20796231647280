import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_LAUNDRY_MONTHLY_DATA = gql`
    mutation createLaundryMonthlyData($LaundryMonthlyDataInput:LaundryMonthlyDataInput) {
        createLaundryMonthlyData(LaundryMonthlyDataInput:$LaundryMonthlyDataInput) {
          hostelId {
            _id
            name
        }
            laundryVendorId
            itemName
            year
            month
            monthlyTotalQuantity
            actualTotalQuantity
            difference
            logs
        
        }
    }
`;

export const useCreateLaundryMonthlyData = () => {
    const [createLaundryMonthlyData, { loading, error, data, refetch }] =
        useMutation(CREATE_LAUNDRY_MONTHLY_DATA);

    useLoadingHandler(loading);

    const createLaundryMonthlyDataHandler = async (LaundryMonthlyDataInput) => {
        const response =  await createLaundryMonthlyData({
            variables: { LaundryMonthlyDataInput },
        });
        return response;
    };
    return [createLaundryMonthlyDataHandler, { loading, error, data, refetch }];
};

export const UPDATE_LAUNDRY_MONTHLY_DATA = gql`
  mutation updateLaundryMonthlyData($LaundryMonthlyDataUpdate: [LaundryMonthlyDataInput]!) {
    updateLaundryMonthlyData(LaundryMonthlyDataUpdate: $LaundryMonthlyDataUpdate) {
      acknowledged
    }
  }
`;

export const useUpdateLaundryMonthlyData = () => {
  const [updateLaundryMonthlyData, { loading, error, data, refetch }] = useMutation(
    UPDATE_LAUNDRY_MONTHLY_DATA
  );

  useLoadingHandler(loading);

  const updateLaundryMonthlyDataHandler = async ( LaundryMonthlyDataUpdate) => {
    return await updateLaundryMonthlyData({
      variables: { LaundryMonthlyDataUpdate },
    });
  };

  return [updateLaundryMonthlyDataHandler, { loading, error, data, refetch }];
};