import React from "react";
import { useParams } from "react-router";
import { useGetHostelLogsById } from "../../../../api/Packages/Queries";
import { useGetLogsById } from "../../../../api/Logs/Queries";

const LogsSection = () => {
  const { id } = useParams();

  const { data } = useGetHostelLogsById(id);
  const { data: sectionLogs } = useGetLogsById({ id, module: "hostel" });

  const logs = data?.getHostelLogsById?.logs;

  const importantDetailsLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Important Details"
  );
  const overviewLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Overview"
  );
  const photosLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Photos"
  );
  const howToReachLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "How to Reach"
  );
  const reviewsLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Reviews"
  );
  const reviewsCategoryLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Reviews Category"
  );
  const faqLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "FAQ"
  );
  const landingPolicyLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Landing Policy"
  );
  const guestInfoPolicyLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Guest Info Policy"
  );
  const timingInformationLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Timing Information"
  );
  const seoLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "SEO"
  );
  const similarHostelsLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Similar Hostels"
  );
  const blogsLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Blogs"
  );
  const gstLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "GST"
  );
  const automationLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Automation"
  );
  const gluLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Glu"
  );
  const portraitImageSectionLogs = sectionLogs?.getLogsById?.filter(
    (item) => item?.sectionName === "Portrait Image Section"
  );
  const newLogs = sectionLogs?.getLogsById?.filter(
    (item) => !item?.sectionName || item?.sectionName.trim() === ""
  );

  return (
    <>
      {importantDetailsLogs?.length > 0 && (
        <>
          <p>
            <strong> Important details logs</strong>
          </p>

          <ul>
            {
              importantDetailsLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {overviewLogs?.length > 0 && (
        <>
          <p>
            <strong> Overview Logs</strong>
          </p>

          <ul>
            {
              overviewLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {photosLogs?.length > 0 && (
        <>
          <p>
            <strong> Photos logs</strong>
          </p>

          <ul>
            {
              photosLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {howToReachLogs?.length > 0 && (
        <>
          <p>
            <strong> How to reach logs</strong>
          </p>

          <ul>
            {
              howToReachLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {reviewsLogs?.length > 0 && (
        <>
          <p>
            <strong> Review logs</strong>
          </p>

          <ul>
            {
              reviewsLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {reviewsCategoryLogs?.length > 0 && (
        <>
          <p>
            <strong> Review category logs</strong>
          </p>

          <ul>
            {
              reviewsCategoryLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {faqLogs?.length > 0 && (
        <>
          <p>
            <strong> FAQ logs</strong>
          </p>

          <ul>
            {
              faqLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {landingPolicyLogs?.length > 0 && (
        <>
          <p>
            <strong> Landing policy logs</strong>
          </p>

          <ul>
            {
              landingPolicyLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {guestInfoPolicyLogs?.length > 0 && (
        <>
          <p>
            <strong> Guest info policy logs</strong>
          </p>

          <ul>
            {
              guestInfoPolicyLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {timingInformationLogs?.length > 0 && (
        <>
          <p>
            <strong> Timing information logs</strong>
          </p>

          <ul>
            {
              timingInformationLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {seoLogs?.length > 0 && (
        <>
          <p>
            <strong> SEO logs</strong>
          </p>

          <ul>
            {
              seoLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {similarHostelsLogs?.length > 0 && (
        <>
          <p>
            <strong> Similar hostel logs</strong>
          </p>

          <ul>
            {
              similarHostelsLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {blogsLogs?.length > 0 && (
        <>
          <p>
            <strong> Blogs logs</strong>
          </p>

          <ul>
            {
              blogsLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {gstLogs?.length > 0 && (
        <>
          <p>
            <strong> GST logs</strong>
          </p>

          <ul>
            {
              gstLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {automationLogs?.length > 0 && (
        <>
          <p>
            <strong> Automation logs</strong>
          </p>

          <ul>
            {
              automationLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {gluLogs?.length > 0 && (
        <>
          <p>
            <strong> Glu logs</strong>
          </p>

          <ul>
            {
              gluLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {portraitImageSectionLogs?.length > 0 && (
        <>
          <p>
            <strong> Portrait image section logs</strong>
          </p>

          <ul>
            {
              portraitImageSectionLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {newLogs?.length > 0 && (
        <>
          <p>
            <strong> Logs</strong>
          </p>

          <ul>
            {
              newLogs?.map((item, index) => (
                <li key={index}>{item?.log}</li>
              ))
              //.reverse()
            }
          </ul>
        </>
      )}
      {logs?.length > 0 && (
        <>
          <p>
            <strong>Past logs</strong>
          </p>

          <ul>
            {
              logs?.map((log, index) => (
                <li key={index}>{log}</li>
              )) //.reverse()
            }
          </ul>
        </>
      )}
    </>
  );
};

export default LogsSection;

// import React from "react";
// import { useParams } from "react-router";
// import { useGetHostelLogsById } from "../../../../api/Packages/Queries";
// import { useGetLogsById } from "../../../../api/Logs/Queries";

// const LogsSection = () => {
//   const { id } = useParams();

//   const { data } = useGetHostelLogsById(id);
//   const { data: sectionLogs } = useGetLogsById({ id, module: "hostel" });

//   const logs = data?.getHostelLogsById?.logs;
//   const logsBySection = sectionLogs?.getLogsById || [];

//   // Define all section names
//   const sectionNames = [
//     "Important Details",
//     "Overview",
//     "Photos",
//     "How to Reach",
//     "Reviews",
//     "Reviews Category",
//     "FAQ",
//     "Landing Policy",
//     "Guest Info Policy",
//     "Timing Information",
//     "SEO",
//     "Similar Hostels",
//     "Blogs",
//     "GST",
//     "Automation",
//     "Glu",
//     "Portrait Image Section"
//   ];

//   // Create a mapping of section names to displayable titles
//   const sectionTitles = {
//     "Important Details": "Important details logs",
//     "Overview": "Overview Logs",
//     "Photos": "Photos logs",
//     "How to Reach": "How to reach logs",
//     "Reviews": "Review logs",
//     "Reviews Category": "Review category logs",
//     "FAQ": "FAQ logs",
//     "Landing Policy": "Landing policy logs",
//     "Guest Info Policy": "Guest info policy logs",
//     "Timing Information": "Timing information logs",
//     "SEO": "SEO logs",
//     "Similar Hostels": "Similar hostel logs",
//     "Blogs": "Blogs logs",
//     "GST": "GST logs",
//     "Automation": "Automation logs",
//     "Glu": "Glu logs",
//     "Portrait Image Section": "Portrait image section logs"
//   };

//   // Group logs by section
//   const groupedLogs = sectionNames.reduce((acc, sectionName) => {
//     acc[sectionName] = logsBySection.filter(
//       item => item?.sectionName === sectionName
//     );
//     return acc;
//   }, {});

//   // Render a single log section
//   const renderLogSection = (sectionName) => {
//     const sectionLogs = groupedLogs[sectionName];

//     if (!sectionLogs || sectionLogs.length === 0) return null;

//     return (
//       <React.Fragment key={sectionName}>
//         <p>
//           <strong>{sectionTitles[sectionName]}</strong>
//         </p>
//         <ul>
//           {sectionLogs
//             .map((item, index) => <li key={index}>{item?.log}</li>)
//             .reverse()}
//         </ul>
//       </React.Fragment>
//     );
//   };

//   return (
//     <>
//       {sectionNames.map(sectionName => renderLogSection(sectionName))}

//       {logs?.length > 0 && (
//         <>
//           <p>
//             <strong>Logs</strong>
//           </p>
//           <ul>
//             {logs?.map((log, index) => <li key={index}>{log}</li>).reverse()}
//           </ul>
//         </>
//       )}
//     </>
//   );
// };

// export default LogsSection;
