import { Edit, FileText } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Hostel name",
  "Hostel region",
  "No. of total beds",
  "Cafe scale",
  "Total sanctioned electrical load (in KWH)",
  "Total sanctioned water supply/day (in ltr)",
  "Deal source",
  "Operational model",
  "Age of the hostel (in months)",
  "Monthly rent type",
  "Lease deed type",
  "Licenses obtainance/renewal responsibility",
];

const SupplyTable = ({
  openModal,
  setId,
  data,
  count,
  handleCreateUpdateSupply,
  loading,
}) => {
  const allowedDepartments = ["Management", "Supply"];
  const userRole = allowedDepartments.includes(
    localStorage.getItem("department")
  );

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader style={{ display: "flex" }}>
            <CardTitle>
              <div>
                <h2> Hostels ({count ? count : 0} count)</h2>
              </div>
            </CardTitle>

            {userRole && (
              <Button.Ripple
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              >
                Add hostel info.
              </Button.Ripple>
            )}
          </CardHeader>
          {loading ? (
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              Please wait...
            </h2>
          ) : data.length > 0 ? (
            <>
              {" "}
              <Table responsive>
                <thead>
                  <tr>
                    {tableHeaderData.map((item) => {
                      return <th key={uuidv4()}>{item}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item) => (
                    <tr key={uuidv4()}>
                      {/* <td style={{ width: "10px" }}>
                  <Button
                    className="rounded-circle btn-icon"
                    color="none"
                    onClick={(e) => {
                      e.preventDefault();
                      // setId(item._id);
                      setId("test");
                      openModal();
                    }}
                  >
                    <Edit
                      color={reactFeatherIcon.iconColor}
                      size={reactFeatherIcon.iconSize}
                    />
                  </Button>
                </td> */}
                      <td>
                        <Button
                          className="rounded-circle btn-icon"
                          color="none"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCreateUpdateSupply(item?._id);
                            // handleCreateUpdateSupply("test");
                          }}
                        >
                          <FileText
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                        </Button>
                      </td>
                      <td>{item?.hostelName}</td>
                      <td>{item?.hostelRegion}</td>
                      <td>{item?.numOfTotalBeds}</td>
                      <td>{item?.cafeScale}</td>
                      <td>{item?.totalSanctionedElectricalLoad}</td>
                      <td>{item?.totalSanctionedWaterSupplyPerDay}</td>
                      <td>{item?.dealSource}</td>
                      <td>{item?.operationalModel}</td>
                      <td>{item?.ageOfHostel}</td>
                      <td>{item?.monthlyRentType}</td>
                      <td>{item?.leaseDeedType}</td>
                      <td>{item?.licensesResponsibility}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>{" "}
            </>
          ) : (
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              No data found
            </h2>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default SupplyTable;
