import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { X, Link, Upload, Image } from "react-feather";
import { useUploadImage } from "@api";
import confirmDelete from "@components/common/confirmDelete";
import "../../WebApp/UserRequest/StaffImagesSection/index.scss";
import { Col, Input, Label, Row } from "reactstrap";
import toaster from "@components/common/Toaster";

const AttachmentComponent = ({
  imageList,
  setImageList,
  editData,
  uploadType,
  userDepartment,
  data,
  canBeEdited,
  showInput,
  isDynamic,
}) => {
  const [imageLink, setImageLink] = useState([]);
  const [uploadingType, setUploadingType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadImageHandler, { data: imageData, reset }] = useUploadImage();
  const [editOptions, setEditOptions] = useState(true)

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: uploadType[0].value === "CADDrawingsImage" ? [".dwg"] : ["image/*", ".jpg", ".jpeg", ".png", ".pdf"],
      // maxFileSize: 2097152,
    },
    // onBeforeFileAdded: (currentFile, files) => {
    //   if (currentFile.size > 2097152) {
    //     toaster("error", "File size exceeds the maximum limit of 2 MB.");
    //     return false;
    //   }
    //   return true;
    // },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("complete", (result) => {
    result.successful.forEach((file) => {
      uppy.removeFile(file.id);

      setUploadingType(result?.successful[0]?.source, editData);

      result.successful[0]?.data &&
        uploadImageHandler(result?.successful[0]?.data, `SUPPLY`);

      toaster("success", "Image / PDF uploaded successfully");
    });
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading image: ${error.message}`);
  });

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      const newImage = uploadType
        ? {
          imageURL: imageData.uploadImage.url,
          imageName: "",
        }
        : {
          url: imageData.uploadImage.url,
          imageName: "",
        };

      if (!isDynamic) {
        setImageList((prev) => [...prev, newImage]);
      } else {
        const updatedImageList = [...imageList, newImage];
        setImageList(updatedImageList);
      }

      reset();
      setUploadingType(null);
    }
  }, [imageData, imageList, isDynamic]);


  const removeImages = (image) => {
    const newSet = imageList?.filter((item) => {
      const imgUrl = item?.imageURL ? item?.imageURL : item?.url;
      return imgUrl !== image;
    });
    setImageList(newSet);
  };

  const handleRemoveImage = (image) => {
    confirmDelete(() => removeImages(image));
  };

  const openModal = (index, link) => {
    setShowModal(true);
    setImageLink({ index, link });
  };

  const closeModal = () => {
    setShowModal(false);
    setImageLink({});
  };

  const addImageLink = (index, link) => {
    let newImageList = [...imageList];
    const imgWithLink = { ...newImageList[index], link };
    newImageList[index] = imgWithLink;
    setImageList(newImageList);
  };

  const handleUploadClick = () => {
    document.querySelector(`#file-input-${uploadType[0].value}`).click();
  };
  useEffect(() => {
    if (canBeEdited !== undefined) {
      setEditOptions(canBeEdited)
    }
  }, [canBeEdited])

  const handleChangeImageName = (name, index) => {
    if (!isDynamic) {
      setImageList((prev) =>
        prev.map((img, i) =>
          i === index ? { ...img, imageName: name } : img
        )
      );
    } else {
      const updatedImageList = [...imageList];
      updatedImageList[index] = { ...updatedImageList[index], imageName: name };
      setImageList(updatedImageList);
    }
  };
  return (
    <Row>
      <Col>
        <div className="imageSection__container">
          {uploadType?.map(({ value, label }) => (
            <div key={value}>
              <div className="" style={{ display: "flex", flexDirection: "column", justifyItems: "space-around", gap: "5px" }}>
                {imageList?.map((item, index) => {
                  const imageURL = item?.imageURL || item?.url;
                  return (
                    <div
                      key={index}
                      className=""
                      style={{
                        width: `${showInput ? "30em" : "auto"}`,
                        position: "relative",
                      }}
                    >
                      <div style={{ display: "flex", width: "", }} >
                        <div onClick={() => window.open(imageURL, "_blank")} >
                          {

                            showInput ?
                              <Image onClick={() => window.open(imageURL, "_blank")} style={{ marginTop: "10px", cursor: "pointer" }} />
                              :
                              <div style={{ cursor: "pointer", color: "blue", textDecoration: "underline", fontSize: "12px" }}>{item?.imageName}</div>
                          }

                        </div>
                        {
                          showInput &&
                          <Col >
                            <Input
                              id="Image name"
                              value={item?.imageName}
                              onChange={(e) => handleChangeImageName(e.target.value, index)}
                              placeholder="File name"
                            />
                          </Col>
                        }
                      </div>

                      {/* {
                        editOptions && <div
                          onClick={() => handleRemoveImage(imageURL)}
                          style={{
                            position: "absolute",
                            top: "20%",
                            right: "-5px",
                            backgroundColor: "#ffffff",
                            padding: "1px",
                            cursor: "pointer",
                          }}
                        >
                          <X size={16} color="black" />
                        </div>
                      } */}
                    </div>
                  );
                })}
                {
                  editOptions && <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "4em",
                      height: "4em",
                      cursor: "pointer",
                      margin: "2em",
                    }}
                    onClick={handleUploadClick}
                  >
                    <Upload size={24} />
                    <input
                      type="file"
                      id={`file-input-${uploadType[0].value}`}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          uppy.addFile({
                            name: file.name,
                            type: file.type,
                            data: file,
                          });
                        }
                      }}
                    />
                  </div>
                }

              </div>
              <br />
            </div>
          ))}
        </div>
      </Col>
    </Row >
  );
};

export default AttachmentComponent;
